import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

import React, { useEffect, useState } from "react";
import {
  Alert,
  Button,
  Col,
  Form,
  Modal,
  Row,
  Spinner,
  Table,
} from "react-bootstrap";
import PoSummaryTable from "./PoSummaryTable";
import Axios from "axios";
import Constants from "../../../Config";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useHistory } from "react-router-dom";
import moment from "moment";

const apiUrl = Constants.API_URL;
const loginedUserDetails = JSON.parse(localStorage.getItem("userData"));

const today = new Date();
const oneMonthDate = new Date(today);
oneMonthDate.setDate(today.getDate() + 30);

const PurchaseOrderDetail = () => {
  const history = useHistory();

  const [formValidate, setFormValidate] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const [wrong_phone, setWrongPhone] = useState(false);
  const [poStatus, setPoStatus] = useState("");
  const [poID, setPoID] = useState(0);
  const [quoteID, setQuoteID] = useState(0);
  const [showExistingOrder, setShowExistingOrder] = useState(false);
  const [existingOrders, setExistingOrders] = useState([]);
  const [poSummary, setPoSummary] = useState([]);
  const [isOpenNewAddress, openNewAddress] = useState(false);
  const [submitLoading, setSubmitLoading] = useState(false);
  const [showClosePO, setShowClosePO] = useState(false);
  const [customer_phone, setCustomerPhone] = useState("");
  const [companyName, setCompanyName] = useState("");
  const [customer_email, setCustomerEmail] = useState("");
  const [customer_name, setCustomerName] = useState("");
  const [agentId, setAgentId] = useState(0);
  const [validDate, setValidDate] = useState(oneMonthDate);
  const [closePOReason, setClosePOReason] = useState("");
  const [po_reference, setPoReference] = useState("");
  const [poDetails, setQuoteDetailInPO] = useState({}); //details coming from create PO from listQuote
  const [agentsArray, setAgentsArray] = useState([]);
  const [selectedFile, setSelectedFile] = useState("");
  const [delieveryAddresses, setDelieveryAddresses] = useState([]);
  const [isOnlyCreate, setIsOnlyCreate] = useState(false);
  const [is_redirect, setRedirect] = useState(false);
  const [showQuoteDetail, setShowQuoteDetail] = useState(false);
  const [quoteDetails, setQuoteDetail] = useState({});

  //   window.addEventListener("beforeunload", (event) => {
  //     if (event.currentTarget === window) {
  //       // Check if the tab is being closed
  //       localStorage.removeItem("poState");
  //       localStorage.removeItem("PO_Details");
  //     }
  //   });

  useEffect(() => {
    const poDetails = JSON.parse(localStorage.getItem("PO_Details")); //link from quote list
    if (poDetails) {
      setQuoteDetailInPO(poDetails);
      setIsOnlyCreate(true);
      getQuoteDetailById(poDetails?.quoteID);
      setQuoteID(poDetails?.quoteID);
      getPODetailByQuoteID(poDetails?.quoteID);
      // localStorage.removeItem('poState');
    }
    getAgents();
  }, []);
  const getPODetailByQuoteID = (quoteID) => {
    const requestOption = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${loginedUserDetails.token}`, // Replace with your authorization header
      },
      data: {
        is_po: true,
        quote_id: quoteID,
      }, // Provide any additional data here
    };

    Axios(`${apiUrl}/purchase-order-list`, requestOption)
      .then((res) => {
        // order_quantity: 0;
        // order_value: "0.00";
        // po_quantity: 20;
        // price: "475.0000";
        // productName: "Mortise Door Handle Set For Aluminium Casement Door - Series - McCoy DHM - White - McCoy 8M";
        // remaining_quantity: 0;
        const poDetails = res.data.data;
        setPoSummary(poDetails);
      })
      .catch((err) => {
        setErrorMessage(err.response.data.message);
        setTimeout(() => {
          setErrorMessage("");
        }, 3000);
      });
  };
  const getQuoteDetailById = async (QUOTEID) => {
    const token = loginedUserDetails.token;
    const params = {
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      data: {
        quoteID: QUOTEID,
      },
    };
    return await Axios(apiUrl + "/get-quotes", params)
      .then((data) => {
        const quoteData = data.data.data[0];
        // customer_email2: dataObject.email,
        //     customer_name2: dataObject.companyData.name,
        //     customer_phone2: dataObject.companyData.telephone,
        //     companyName: dataObject.companyData.companyName,
        setCustomerName(quoteData.companyData.name);
        setCustomerEmail(quoteData.email);
        setCustomerPhone(quoteData.companyData.telephone);
        setCompanyName(quoteData.companyData.company_name);
        setAgentId(quoteData.agent_id);
        setQuoteDetail(quoteData);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleDateChange = (date) => {
    setValidDate(date);
  };
  const openQuoteDetail = () => {
    setShowQuoteDetail(true);
  };
  const closeQuoteDetailModal = () => {
    setShowQuoteDetail(false);
  };
  const getPhoneValue = () => {};
  const getCurrentAgent = () => {};
  const closeAddressModal = (value) => {
    openNewAddress(value);
  };

  const validateFile = (file) => {
    var allowedExtension = [
      "image/jpeg",
      "image/jpg",
      "image/png",
      "application/pdf",
      "application/vnd.ms-excel",
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      "application/msword",
      "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
      "text/plain",
    ];
    if (file) {
      if (!allowedExtension.includes(file.type)) {
        alert("only allowed extension jpeg,jpg,png,pdf,excel file");
        return false;
      }
      if (file.size > 5000000) {
        alert("Please attached file less than 5 mb");
        return false;
      }
      return true;
    } else {
      alert("you did not attached file");
    }
  };
  const onFileChange = async (event) => {
    // Update the state
    const file = event.target.files[0];
    var allowedimage = validateFile(file);

    let formData = new FormData();
    formData.append("file", event.target.files[0]);
    Axios.post(apiUrl + "/rfq-attachment", formData, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${loginedUserDetails.token}`,
      },
    })
      .then((res) => {
        setSelectedFile(res.data.data.image_url);
      })
      .catch((err) => {
        console.log(err);
        setErrorMessage(err.response.data.message);
        setTimeout(() => {
          setErrorMessage("");
        }, 3000);
      });
  };
  const addNewAddress = () => {
    openNewAddress(true);
  };
  const createPOSubmit = (e) => {
    setSubmitLoading(true);
    //create POST api call will be executed below on clicktype
    e.preventDefault();
    const token = loginedUserDetails.token;
    const params = {
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      data: {
        po_reference: po_reference,
        status: poStatus,
        quote_id: poDetails?.quoteID,
        po_valid_upto: validDate,
        attachment: selectedFile
      },
    };
    return Axios(apiUrl + "/create-purchase-order", params)
      .then((data) => {
        setSuccessMessage("PO Created Successfully");
        setErrorMessage("");
        setSubmitLoading(false);
        setRedirect(true);
      })
      .then(() => {
        setTimeout(() => {
          history.push("/po-list");
        }, 2000);
      })
      .catch((err) => {
        console.log(err);
        setSubmitLoading(false);
        setErrorMessage(err.response.data.message);
        setTimeout(() => {
          setErrorMessage("");
        }, 3000);
      });
    // let submitType = e.target.id;
  };

  const openClosePO = () => {
    setShowClosePO(true);
  };
  const handleClosePO = (e) => {
    setClosePOReason(e.target.value);
  };
  const handleSubmitClosePO = (e) => {
    setShowClosePO(false);
    setClosePOReason(""); //resetting the value for close PO reason

    //create POST api call will be executed below on clicktype
    e.preventDefault();
    const token = loginedUserDetails.token;
    const params = {
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      data: {
        id: poID,
        status: poStatus,
        reject_reason: closePOReason,
      },
    };
    return Axios(apiUrl + "/update-purchase-order", params)
      .then((data) => {
        setSuccessMessage("PO Created Successfully");
        setErrorMessage("");
        setRedirect(true);
      })
      .catch((err) => {
        setErrorMessage(err.response.data.message);
        setTimeout(() => {
          setErrorMessage("");
        }, 3000);
      });
    // let submitType = e.target.id;
  };

  const getAgents = () => {
    const token = loginedUserDetails.token;
    const requestoption = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      data: {
        agent_list: "",
        id: [56, 54],
        showAll: "",
        slug: "createPO",
      },
    };
    return Axios(apiUrl + "/get-pre-sales-agents", requestoption)
      .then((data) => {
        setAgentsArray(data.data.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const addAddress = (addressString) => {
    const addressArray = delieveryAddresses;
    addressArray.push(addressString);
    setDelieveryAddresses(addressArray);
  };
  const removeAddress = (index, e) => {
    e.preventDefault();
    setDelieveryAddresses((prevAddresses) => {
      // Create a new array excluding the item at the specified index
      const newAddresses = prevAddresses.filter((address, i) => i !== index);
      return newAddresses;
    });
  };

  // useEffect(() => {

  //   // Replace '/new-route' with the path you want to navigate to
  // }, [is_redirect]);

  return (
    <Row>
      {errorMessage ? (
        <Alert
          className="displayMessageArea"
          variant={errorMessage ? "danger" : null}
        >
          {errorMessage}
        </Alert>
      ) : null}
      {successMessage ? (
        <Alert
          className="displayMessageArea"
          variant={successMessage ? "success" : null}
        >
          {successMessage}
        </Alert>
      ) : null}
      <Col md={12}>
        <Form noValidate validated={formValidate}>
          {/* // SECTION 1 ->  Customer DETAILS */}
          <div className="card">
            <div className="card-header justify-content-between">
              <h3>
                <b>PO Information</b>
              </h3>
              <div>
                <Button
                  variant="secondary"
                  size="md"
                  onClick={openQuoteDetail}
                  className="mr-5"
                >
                  {/* {`Quote ID : ${poDetails?.quoteID}`} */}
                  {`Quote ID : ${quoteID}`}
                </Button>
                <Modal
                  show={showQuoteDetail}
                  onHide={closeQuoteDetailModal}
                  dialogClassName="modal-90w"
                >
                  <Modal.Header closeButton></Modal.Header>
                  <Modal.Body>
                    <Form.Row>
                      <Col md="3">
                        <b>Quote ID</b>
                      </Col>
                      <Col md="3">
                        <b>Quote Type</b>
                      </Col>
                      <Col md="3">
                        <b>Quote Status</b>
                      </Col>
                      <Col md="3">
                        <b>Quote Validity</b>
                      </Col>
                    </Form.Row>
                    <div className="p-3 my-3 rounded border border-5 border-dark">
                      <Form.Row>
                        <Col md="3">
                          <b>{quoteDetails?.quote_id}</b>
                        </Col>
                        <Col md="3">
                          {quoteDetails?.quote_type == 1 ? "Normal" : "Magic"}
                        </Col>
                        <Col md="3">{quoteDetails?.quote_status}</Col>
                        <Col md="3">
                        {quoteDetails?.valid_upto ? moment(quoteDetails?.valid_upto).format(
                            "DD-MM-YYYY"
                          ) : ""}
                        </Col>
                      </Form.Row>
                    </div>
                  </Modal.Body>
                </Modal>
              </div>
            </div>

            <div className="card-body">
              <Form.Row>
                <Col md="3">
                <Form.Group controlId="exampleForm.SelectCustom">
                  <Form.Label>
                    <b>Customer Phone</b>
                  </Form.Label>

                  <Form.Control
                    type="number"
                    name="customer_phone"
                    placeholder="Customer Phone"
                    defaultValue={customer_phone}
                    disabled
                    onChange={getPhoneValue}
                    className={wrong_phone ? "error" : ""}
                    required
                  />
                </Form.Group>
                </Col>
                <Col md="3">
                <Form.Group
                  controlId="exampleForm.SelectCustom"
                  value="b2blandingpage"
                >
                  <Form.Label>
                    <b>
                      Account Name <span>(*)</span>
                    </b>
                  </Form.Label>
                  <Form.Control
                    type="text"
                    value={companyName}
                    name="companyName"
                    placeholder="Company Name"
                    disabled
                    // required
                  />
                </Form.Group>
                </Col>
                <Col md="3">
                <Form.Group controlId="exampleForm.SelectCustom">
                  <Form.Label>
                    <b>PO Owner</b>
                  </Form.Label>
                  <Form.Control
                    as="select"
                    custom
                    name="agentId"
                    required
                    onChange={(e) => setAgentId(e.target.value)}
                    disabled
                    value={agentId}
                    defaultValue={agentId}
                  >
                    <option value="">Please select agent</option>
                    {agentsArray &&
                      agentsArray.map((item) => {
                        var selected = agentId == item.id ? true : false;
                        return item.id ? (
                          <option
                            selected={selected}
                            key={item.id}
                            value={item.id}
                          >
                            {item.name}
                          </option>
                        ) : null;
                      })}
                  </Form.Control>
                  <Form.Control.Feedback type="invalid">
                    Please select quote owner.
                  </Form.Control.Feedback>
                </Form.Group>
                </Col>
                <Col md="3">
                <Form.Group controlId="formGridValidUpto">
                  <Form.Label>
                    <b>PO Expiry Date *</b>
                  </Form.Label>
                  <DatePicker
                    dateFormat="yyyy-MM-dd"
                    selected={validDate}
                    onChange={handleDateChange}
                    className="form-control"
                    locale="es"
                    minDate={new Date()}
                    required
                    //   value={validDate}
                  />
                </Form.Group>
                </Col>
                <Col md="3">
                <Form.Group controlId="customerName">
                  <Form.Label>
                    <b>Customer Name</b>{" "}
                  </Form.Label>
                  <Form.Control
                    type="text"
                    // value={this.state.customer_name}
                    value={customer_name}
                    name="customer_name"
                    placeholder="Customer Name"
                    disabled
                    required
                  />
                </Form.Group>
                </Col>
                <Col md="3">
                <Form.Group controlId="customerEmail">
                  <Form.Label>
                    <b>Customer Email </b>
                  </Form.Label>
                  <Form.Control
                    type="text"
                    // value={this.state.customer_email}
                    value={customer_email}
                    name="customer_email"
                    placeholder="Customer Email"
                    disabled
                    required
                  />
                </Form.Group>
                </Col>
              </Form.Row>
            </div>
          </div>
          {/* //SECTION 2 // */}
          <div className="card">
            <div className="card-body">
              {/* Section for Attachment , PO reference Number, Expiry date, See Existing Orders  */}
              <Form.Row>
                <Col md="9">
                  <Form.Group controlId="formFile">
                    <Form.Label>
                      {" "}
                      <b>Choose File</b>
                    </Form.Label>
                    <Form.Control
                      type="file"
                      name="attachment"
                      id="image"
                      onChange={onFileChange}
                    />
                  </Form.Group>
                </Col>
                <Col md="3">
                  <Form.Group>
                    <Form.Label>
                      <b>Add PO Reference No. </b>
                    </Form.Label>
                    <Form.Control
                      placeholder="Enter PO reference"
                      onChange={(e) => setPoReference(e.target.value)}
                      value={po_reference}
                      // disabled={po_reference}
                      required
                    />
                  </Form.Group>
                </Col>
              </Form.Row>
            </div>
            {existingOrders.length ? (
              <div className="p-3">
                <h4>
                  <b>Existing Orders</b>
                </h4>
                <Table striped bordered hover>
                  <thead>
                    <th>
                      <b>Order ID</b>
                    </th>
                    <th>
                      <b>Order Date</b>
                    </th>
                    <th>
                      <b>Order Status</b>
                    </th>
                    <th>
                      <b>Product Name</b>
                    </th>
                    <th>
                      <b>Quantity</b>
                    </th>
                    <th>
                      <b>Order value</b>
                    </th>
                  </thead>
                  <tbody>
                    {existingOrders &&
                      existingOrders.map((order) => (
                        <tr key={order.orderId}>
                          <td>{order.order_id}</td>
                          <td>{order.order_date}</td>
                          <td>{order.order_status}</td>
                          <td>{order.product_name}</td>
                          <td>{order.ordered_quantity}</td>
                          <td>{order.ordered_value}</td>
                        </tr>
                      ))}
                  </tbody>
                </Table>
              </div>
            ) : null}
          </div>
          {/* // SECTION 3  -> PO Summary (Edit , Create) */}
          <div className="card">
            {poSummary && poSummary.length > 0 ? (
              <>
                <div className="card-header">
                  <h4>
                    <b>PO Summary</b>
                  </h4>
                </div>
                <div className="p-3 mobile-table-scroll">
                  <PoSummaryTable
                    poSummary={poSummary}
                    isOnlyCreate={isOnlyCreate}
                  />
                </div>
              </>
            ) : null}
            {/* //Delievery address section */}
            {/* <div className="p-3">
              <>
                <h3>
                  <b>Delivery Addresses</b>
                </h3>
                {delieveryAddresses &&
                  delieveryAddresses.map((addr) => {
                    return (
                      <p>
                        {addr}
                        <br />
                      </p>
                    );
                  })}
              </>

              {!isOnlyCreate ? (
                <Button onClick={addNewAddress}>
                  Add New Delivery Address
                </Button>
              ) : null}
              {isOpenNewAddress ? (
                <NewAddressModal
                  closeAddressModal={closeAddressModal}
                  addAddress={addAddress}
                />
              ) : null}
            </div> */}
            {isOnlyCreate ? (
              <div className="d-flex justify-content-between w-100 p-3">
                <Button
                  variant="success"
                  type="submit"
                  id="createOnly"
                  onClick={createPOSubmit}
                  className="mr-3"
                >
                  {submitLoading ? (
                    <Spinner animation="border" role="status">
                      <span className="sr-only">Loading...</span>
                    </Spinner>
                  ) : (
                    "Create PO"
                  )}
                </Button>
              </div>
            ) : null}
          </div>
        </Form>
      </Col>
    </Row>
  );
};

export default PurchaseOrderDetail;
