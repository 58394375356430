import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useState } from "react";
import { useEffect } from "react";
import { Form, Table } from "react-bootstrap";
import "./PoSummary.css"

const PoSummaryTable = (props) => {
  const [poSummary, setPoSummary] = useState(props?.poSummary);
  const [totalObj, setTotalObject] = useState({});
  const [subTotal, setSubTotal] = useState(0);
  const [grandTotal, setGrandTotal] = useState(0);
  const [taxCharges, setTaxCharges] = useState(0);
  const [poShippingValue, setPoShippingValue] = useState('')

  useEffect(() => {
    if (!props.isOnlyCreate) {
      addOrderKeyInPoSummary();
    }
    calculateTotalObject();
  }, []);

  // Function to format a number to two decimal places
  const formatToTwoDecimal = (value) => {
    return Number(value).toFixed(2);
  };

  const handleShippingCharges = (e) => {
    const inputValue = parseInt(e.target.value, 10) || 0;
    let grandTotalPrice =
    parseFloat(taxCharges) + parseFloat(subTotal) + parseFloat(inputValue);
    props.setPoShippingCharges(inputValue);
    setSubTotal(subTotal);
    setTaxCharges(taxCharges);
    setGrandTotal(grandTotalPrice);
  };
  
  const calculateSum = () => {
    let taxCharges = 0;
    let subTotal = 0;
    let grandtotal = 0;
    let poQuantity = 0;
    let poTotalQuantity = 0;
    let allPoSum = 0;
    poSummary.map((product) => {
      poQuantity += Number(product.orderQty);
      poTotalQuantity += Number(product.po_quantity)
      subTotal += Number(product.price) * Number(product.orderQty);
      taxCharges +=
        (Number(product.tax) / 100) *
        Number(product.price) *
        Number(product.orderQty);
    });
    let calculatePoShipping = Number(props.poResponse.quote_shipping_charges / poTotalQuantity) * poQuantity;
    allPoSum = calculatePoShipping;
    grandtotal = taxCharges + subTotal + allPoSum;
    setSubTotal(subTotal);
    setTaxCharges(taxCharges);
    setGrandTotal(grandtotal);
    setPoShippingValue(formatToTwoDecimal(allPoSum));
    props.setPoShippingCharges(formatToTwoDecimal(allPoSum));
  }

  const addOrderKeyInPoSummary = () => {
    for (const obj of props?.poSummary) {
      obj["orderQty"] = Math.abs(obj.remaining_quantity);
    }
    calculateSum();
  };

  const calculateTotalObject = () => {
    let totalPrice = 0;
    let totalPOQuantity = 0;
    let totalOrderQuantity = 0;
    let totalRemainingQuantity = 0;
    let totalCurrentOrder = 0

    let totalObj = props?.poSummary.forEach((item) => {
      totalPrice += parseInt(item.price);
      totalPOQuantity += item.po_quantity;
      totalOrderQuantity += item.order_quantity;
      totalRemainingQuantity += Math.abs(item.remaining_quantity);
    });

    totalObj = {
      totalPrice,
      totalPOQuantity,
      totalOrderQuantity,
      totalRemainingQuantity,
    };
    setTotalObject(totalObj);
    if (totalObj?.totalRemainingQuantity == 0) {
      // props.getCreateOrderButtonState(true);
    }
  };
  const updateTotalValues = (poSummary) => {
    let subTotal = 0;
    let grandTotal = 0;
    let taxCharges = 0;
    poSummary.map((product) => {
      subTotal += Number(product.price) * Number(product.orderQty);
      grandTotal +=
        Number(product.price) * Number(product.orderQty) +
        (product.tax / 100) * Number(product.price) * Number(product.orderQty);
    });
    taxCharges = grandTotal - subTotal;
    setGrandTotal(grandTotal);
    if (grandTotal == 0) {
      props.getCreateOrderButtonState(true);
    } else {
      const shouldDisableButton = poSummary.some(
        (item) => item.orderQty > Math.abs(item.remaining_quantity)
      );
      props.getCreateOrderButtonState(shouldDisableButton);
    }
    setSubTotal(subTotal);
    setTaxCharges(taxCharges);
  };

  const increaseOrder = (ind) => {
    const updatedPoSummary = [...poSummary];
    if (
      updatedPoSummary[ind].orderQty <
      Math.abs(updatedPoSummary[ind].remaining_quantity) //check for the maximum value for order quantity out of remaining qty
    ) {
      updatedPoSummary[ind].orderQty++;
      props.createOrderLinkArray[ind][1] = updatedPoSummary[ind].orderQty;
    }
    updateTotalValues(updatedPoSummary);

    setPoSummary(updatedPoSummary);

    props.getProductIDQty(props.createOrderLinkArray); // parameter for passing in create order api and link eg.-> "["product_id", "orderQty"]"
    calculateSum();
  };
  const decreaseOrder = (ind) => {
    const updatedPoSummary = [...poSummary];
    if (updatedPoSummary[ind].orderQty > 0) {
      updatedPoSummary[ind].orderQty--;
      props.createOrderLinkArray[ind][1] = updatedPoSummary[ind].orderQty;
    }
    updateTotalValues(updatedPoSummary);

    setPoSummary(updatedPoSummary);
    // props.getCreateOrderButtonState(showCreateOrderButton);
    props.getProductIDQty(props.createOrderLinkArray);
    calculateSum();
  };
  const handleQtyChange = (e, ind) => {
    const updatedPoSummary = [...poSummary];
    updatedPoSummary[ind].orderQty = Math.abs(Number(e.target.value));
    props.createOrderLinkArray[ind][1] = Math.abs(Number(e.target.value));

    const shouldDisableButton = updatedPoSummary.some(
      (item) => item.orderQty > Math.abs(item.remaining_quantity)
    );
    props.getCreateOrderButtonState(shouldDisableButton);
    updateTotalValues(updatedPoSummary);
    props.getProductIDQty(props.createOrderLinkArray);
    calculateSum();
  };

  return (
    <div>
      <Table striped bordered hover>
        <thead>
          <tr>
            <th>
              <b>Product Name</b>
            </th>
            <th>
              <b>List Price(Per SKU)</b>
            </th>
            <th>
              <b>PO Qty</b>
            </th>
            <th>
              <b>Ordered Qty</b>
            </th>
            <th>
              <b>Remaining Qty</b>
            </th>
            <th>
              <b>TAX(%)</b>
            </th>
            {!props.isOnlyCreate ? (
              <th>
                <b>Create New Order</b>
              </th>
            ) : null}
            {/* <th>
              <b>System Delivery Rate</b>
            </th> */}
          </tr>
        </thead>
        <tbody>
          {poSummary &&
            poSummary.map((item, index) => (
              <tr key={index}>
                <td>{item.product_name}</td>
                <td className="text-center">
                  {parseFloat(item.price).toFixed(2)}
                </td>
                <td className="text-center">{item.po_quantity}</td>
                <td className="text-center">{item.order_quantity}</td>
                <td className="text-center">
                  {Math.abs(parseInt(item.remaining_quantity))}
                </td>
                <td className="text-center">{item?.tax}</td>
                {!props.isOnlyCreate ? (
                  !item.remaining_quantity ? (
                    <td className="text-center">-</td>
                  ) : (
                    <td className="text-center">
                      <div className="d-flex justify-content-around">
                        <div>
                          <FontAwesomeIcon
                            icon="minus"
                            onClick={() => decreaseOrder(index)}
                          />
                        </div>
                        <div>
                          <Form.Control
                            className={
                              parseInt(item.orderQty) >
                              Math.abs(item.remaining_quantity)
                                ? "error"
                                : ""
                            }
                            type="Number"
                            value={
                              parseInt(item.orderQty) < 0
                                ? -1 * parseInt(item.orderQty)
                                : parseInt(item.orderQty)
                            }
                            onChange={(e) => handleQtyChange(e, index)}
                            style={{ width: "50px" }}
                            onKeyDown={(e) => {
                              e.key === "Enter" && e.preventDefault();
                            }}
                          />
                        </div>
                        <div>
                          <FontAwesomeIcon
                            icon="plus"
                            onClick={() => increaseOrder(index)}
                          />
                        </div>
                      </div>
                    </td>
                  )
                ) : null}
              </tr>
            ))}
          <tr>
            <td>Total</td>
            {Object.values(totalObj).map((item) => {
              return <td className="text-center">{item}</td>;
            })}
          </tr>
        </tbody>
        {!props.isOnlyCreate ? (
          <tfoot>
            <tr>
              <td colSpan="14" align="right">
                <span className="pr-5">Sub Total</span>
                <span>{subTotal.toFixed(2)}</span>
              </td>
            </tr>
            <tr>
              <td colSpan="14" align="right">
                <span className="pr-5">GST charges</span>
                <span>{taxCharges.toFixed(2)}</span>
              </td>
            </tr>
            <tr>
              <td colSpan="14" align="right">
                <div className="d-flex align-items-center justify-content-between">
                  <span>
                    <b>
                      Quote Shipping Charges : Rs{" "}
                      {props.poResponse.quote_shipping_charges}
                    </b>
                  </span>
                  <div className="d-flex align-items-center justify-content-end">
                    <span className="pr-2">Shipping charges</span>
                    <Form.Group className="mb-0">
                      <Form.Control
                        // style={{
                        //   border:
                        //     touched &&
                        //     Number(storePoValue) < Number(systemDeliveryRate)
                        //       ? "2px solid red"
                        //       : "",
                        // }}
                        type="number"
                        name="shippingCharges"
                        defaultValue={poShippingValue}
                        onChange={handleShippingCharges}
                        className="shippingInputBox"
                        disabled
                      />
                    </Form.Group>
                  </div>
                </div>
              </td>
            </tr>
            <tr>
              <td colSpan="14" align="right">
                <span className="pr-5">Grand Total</span>
                <span>{grandTotal.toFixed(2)}</span>
              </td>
            </tr>
          </tfoot>
        ) : null}
      </Table>
      {props.isOnlyCreate ? (
        <span>
          <b>
            Quote Shipping Charges : Rs {poSummary[0].quote_shipping_charges}
          </b>
        </span>
      ) : null}
    </div>
  );
};

export default PoSummaryTable;
