import React, { useState } from 'react';
import { Button, Dialog, DialogTitle, DialogContent, DialogActions, Typography, Card, CardContent } from '@mui/material';
import ContactsIcon from '@mui/icons-material/Contacts';

function ContactDetailsDialog({ contactDetails }) {
  const [open, setOpen] = useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  // Separate contacts into primary and secondary
  const primaryContacts = contactDetails.filter(contact => contact.is_primary === 1);

  const secondaryContacts = contactDetails.filter(contact => contact.is_primary === 0);

  // Check if all primary contact details are empty
  const allPrimaryDetailsEmpty =
    primaryContacts.every(contact => !contact.name && !contact.phone && !contact.email);

  return (
    <div>
      <Button variant="outlined" onClick={handleOpen}>
        <ContactsIcon />
        {/* ({contactDetails.length}) */}
        ({allPrimaryDetailsEmpty ? contactDetails.length=0 : contactDetails.length})
      </Button>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle className='modal-title'>Contact Details</DialogTitle>
        <DialogContent>
          {!allPrimaryDetailsEmpty && primaryContacts.length > 0 && (
              <div>
                <Typography variant="h6">Primary Contacts</Typography>
                <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                  {primaryContacts.map(contact => (
                    <Card key={contact.email} style={{ margin: '8px', minWidth: '200px' }}>
                      <CardContent>
                        <Typography>
                          {contact.name && <div><strong>Name:</strong> {contact.name}</div>}
                          {contact.phone && <div><strong>Number:</strong> {contact.phone}</div>}
                          {contact.email && <div><strong>Email:</strong> {contact.email}</div>}
                        </Typography>
                      </CardContent>
                    </Card>
                  ))}
                </div>
              </div>
          )}

          {secondaryContacts.length > 0 && (
            <div>
              <Typography variant="h6">Secondary Contacts</Typography>
              <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                {secondaryContacts.map(contact => (
                  <Card key={contact.email} style={{ margin: '8px', minWidth: '200px' }}>
                    <CardContent>
                      <Typography>
                        {contact.name && <div><strong>Name:</strong> {contact.name}</div>}
                        {contact.phone && <div><strong>Number:</strong> {contact.phone}</div>}
                        {contact.email && <div><strong>Email:</strong> {contact.email}</div>}
                      </Typography>
                    </CardContent>
                  </Card>
                ))}
              </div>
            </div>
          )}

          {allPrimaryDetailsEmpty && secondaryContacts.length === 0 && (
            <Typography variant="body1" style={{ color: 'red' }}>
              No details found.
            </Typography>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default ContactDetailsDialog;
