// import React, { useState, useEffect } from "react";
import React, { Component } from "react";
import { getUserDetails } from "../../Login/getLoginnedUserDetails";
import axios from "axios";
import Daterangepicker from "../../Dashboard/Common/Daterangepicker";
import { Form } from "react-bootstrap";
import constants from "../../../Config";
import "./lead-table.css";

const apiUrl = constants.API_URL;
export class LeadData extends Component {
  constructor(props) {
    super(props);
    this.state = {
      date_from: "",
      date_to: "",
      leadStatusArray: [],
      leadTypeArray: [],
      agentsArray: [],
      count: 0,
      leadType: "",
      leadStatus: "",
      agent: "",
      rowValue: "status",
      columnValue: "",
      rowArray: [],
      columnArray: [],
      typeArray: [],
      resArray: [],
      rowItem: "",
      all_lead_source: [],
      lead_status_array: [],
      apiData: [],
      tempArray: [],
      objectArray: [],
      finalArray: [],
      totalArray: [],
      rowTotalArray: [],
      rowTotal: 0,
      totalLeads: 0,
      holder: {},
    };
  }
  componentDidMount() {
    this.getAllAgents();
    this.getLeadData();
    this.getAllSourceTypes();
    this.getLeadStatus();
  }
  getAllSourceTypes = () => {
    let loginedUserDetails = JSON.parse(localStorage.getItem("userData"));
    const options = {
      method: "POST",
      data: {
        meta_id: "21",
      },

      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${loginedUserDetails.token}`,
      },
    };
    return axios(apiUrl + "/meta-value", options)
      .then((data) => {
        this.setState(
          {
            all_lead_source: data.data.data,
          },
          () => {
            this.state.all_lead_source.map((i) => {
              this.state.leadTypeArray.push(i.meta_value);
            });
          }
        );
      })
      .catch((error) => console.log(error));
  };
  getLeadStatus = () => {
    let loginedUserDetails = JSON.parse(localStorage.getItem("userData"));

    const options = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${loginedUserDetails.token}`,
      },
    };
    return axios(apiUrl + "/get-lead-status", options)
      .then((data) => {
        this.setState({
          lead_status_array: data.data.data,
        });
        // this.getAllQuote();
      })
      .catch((error) => console.log(error));
  };
  getAllAgents = () => {
    let loginedUserDetails = JSON.parse(localStorage.getItem("userData"));
    const options = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${loginedUserDetails.token}`,
      },
      data: {
        agent_list: "true",
        id: [54],
        showAll: "true",
        slug: "",
      },

      // data: { logined_user_id: getUserDetails.user_id },
    };
    return axios(apiUrl + "/get-pre-sales-agents", options)
      .then((data) => {
        this.setState({
          agentsArray: data.data.data,
        });
      })
      .catch((error) => console.log(error));
  };
  getLeadData = () => {
    let loginedUserDetails = JSON.parse(localStorage.getItem("userData"));
    let typeArray = [];
    let resArray = [];
    let objectArray = [];
    let leadArray = [
      "boq",
      "ECOM_LCP",
      "Pro Pricing Request",
      "FB Lead",
      "LEAD_BULK",
      "LEAD_OOS",
      "TECH_QUERY",
      "Phone",
      "Email",
      "Bot",
      "RT-Email",
      "RT-Whatsapp",
      "Project_Product_List",
      "ECOM_CLP_BOQ",
      "ECOM_CLP",
      "ECOM_LEAD",
    ];
    let colArray = [];
    typeArray[0] = this.state.rowValue;
    typeArray[1] = this.state.columnValue;
    if (typeArray[0] == typeArray[1]) {
      typeArray.splice(0, 1);
    }
    let filterArray = typeArray.filter((item) => item);
    this.setState({
      typeArray: filterArray,
    });

    const options = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${loginedUserDetails.token}`,
      },

      data: {
        type: filterArray,
        date_from: this.state.date_from,
        date_to: this.state.date_to,
      },
    };
    return axios(apiUrl + "/widget-leads", options)
      .then((data) => {
        let value = data.data.data[0].leadSummary;
        let totalLeads = data.data.data[0].totalLeads;

        let resArray = value[0];
        let tempArray = [];
        if (
          this.state.rowValue == "agent" &&
          this.state.columnValue == "lead-type"
        ) {
          let obj = {};
          Object.keys(resArray).map((item, i) => {
            const wor = [];

            resArray[item].reduce(function (res, data) {
              if (!res[data.lead_type]) {
                res[data.lead_type] = {
                  agent_id: data.agent_id ? data.agent_id : null,
                  agent: data.agent,
                  lead_type: data.lead_type,
                  total: 0,
                };
                wor.push(res[data.lead_type]);
              }
              res[data.lead_type].total += data.total;
              return res;
            }, {});
            obj[item] = wor;
          });
          resArray = obj; //assignment of Obj to resarray

          // Extra item addition below
          Object.keys(resArray).map((data) => {
            let agent_id = 0;
            let agent = "";

            resArray[data].map((item, i) => {
              tempArray.push(item.lead_type);
              agent_id = item.agent_id;
              agent = item.agent;
            });
            // Coparing for difereent value in two arrays
            const output = leadArray.filter(function (obj) {
              return tempArray.indexOf(obj) === -1;
            });
            output.length &&
              output.map((x) => {
                resArray[data].splice(leadArray.indexOf(x), 0, {
                  total: 0,
                  lead_type: x,
                  agent_id: agent_id,
                  agent: agent,
                });
              });
            tempArray = [];
          });
        }

        let reduceArray = Object.values(resArray);

        let tableArray = [];

        switch (this.state.columnValue) {
          case "lead-type":
            Object.values(resArray)[0].map((item, ind) => {
              colArray.push(item.lead_type);
            });

            break;
          case "agent":
            let max = Math.max(
              ...Object.values(resArray).map((item) => item.length)
            );

            Object.values(resArray).map((item, ind) => {
              if (item.length == max && colArray.length < max) {
                item.map((data) => colArray.push(data.agent));
              }
            });

            break;
          case "status":
            Object.values(resArray)[0].map((item, ind) => {
              colArray.push(item.status);
            });

            break;

          default:
            break;
        }
        //code for agent against status starts here
        if (
          this.state.rowValue == "agent" &&
          this.state.columnValue == "status"
        ) {
          let obj = {};
          Object.keys(resArray).map((item, i) => {
            const wor = [];

            resArray[item].reduce(function (res, data) {
              if (!res[data.status]) {
                res[data.status] = {
                  agent_id: data.agent_id ? data.agent_id : null,
                  agent: data.agent,
                  status: data.status,
                  total: 0,
                };
                wor.push(res[data.status]);
              }
              res[data.status].total += data.total;
              return res;
            }, {});
            obj[item] = wor;
          });
          resArray = obj; //assignment of Obj to resarray

          // Extra item addition below
          Object.keys(resArray).map((data) => {
            let agent_id = 0;
            let agent = "";

            resArray[data].map((item, i) => {
              tempArray.push(item.status);
              agent_id = item.agent_id;
              agent = item.agent;
            });
            colArray = tempArray;
            // Coparing for difereent value in two arrays
            const output = colArray.filter(function (obj) {
              return tempArray.indexOf(obj) === -1;
            });
            output.length &&
              output.map((x) => {
                resArray[data].splice(colArray.indexOf(x), 0, {
                  total: 0,
                  status: x,
                  agent_id: agent_id,
                  agent: agent,
                });
              });
            tempArray = [];
          });
        }
       
        let totalArray = [];
        Object.keys(resArray).map((item, ind) => {
          let total = 0;

          resArray[item].map((data, index) => {
            total += data.total;
          });
          totalArray.push(total);
        });

        let rowTotal = 0;
        let rowTotalArray = [];
        let holder = {};

        
        if (
          this.state.rowValue != this.state.columnValue &&
          this.state.columnValue == "lead-type"
        ) {
    
          Object.values(resArray).map((item, i) => {
            item.sort((a, b) => a.lead_type.localeCompare(b.lead_type));
          });
        
          colArray.sort((a, b) => a.localeCompare(b));
          // Sum for every column code

          Object.values(resArray).map((item, i) => {
       
            item.map((d) => {
              if (holder.hasOwnProperty(d.lead_type)) {
                holder[d.lead_type] = holder[d.lead_type] + d.total;
              } else {
                holder[d.lead_type] = d.total;
              }
            });
          });
          // rowTotalArray.push(holder);

          Object.values(holder).map((item) => {
            rowTotal += item;
          });
          

          //SUM FOR EVERY COLUMN CODE
        }
        if (
          this.state.rowValue != this.state.columnValue &&
          this.state.columnValue == "status"
        ) {
          Object.values(resArray).map((item, i) => {
            item.map((d) => {
              if (holder.hasOwnProperty(d.status)) {
                holder[d.status] = holder[d.status] + d.total;
              } else {
                holder[d.status] = d.total;
              }
            });
          });
     
          // rowTotalArray.push(holder);

          Object.values(holder).map((item) => {
            rowTotal += item;
          });
          
        }
        if (
          this.state.rowValue != this.state.columnValue &&
          this.state.columnValue == "agent"
        ) {
          Object.values(resArray).map((item, i) => {
      
            item.map((d) => {
              if (holder.hasOwnProperty(d.agent)) {
                holder[d.agent] = holder[d.agent] + d.total;
              } else {
                holder[d.agent] = d.total;
              }
            });
          });

          Object.values(holder).map((item) => {
            rowTotal += item;
          });
         
        }

        colArray.push("Total");
        // Object.assign(resArray, { Total: rowTotalArray });
        // resArray["Total"] = rowTotalArray
       

        this.setState({
          resArray: resArray,
          apiData: value,
          columnArray: colArray,
          rowTotalArray,
          totalArray,
          totalLeads,
          rowTotal,
          holder,
        });
        colArray = [];
        totalArray = [];
        rowTotalArray = [];
        tableArray = [];
        reduceArray = [];
        holder = {};
      })
      .catch((err) => {
        console.log(err);
      });
  };
  filterByRow = (e) => {
    // if (e.target.value) {
    //   rowArray.push(e.target.value);
    // }
    // if (e.target.value == "lead-type") {
    //   rowArray = this.state.all_lead_source;
    // }
    // if (e.target.value == "status") {
    //   rowArray = this.state.lead_status_array;
    // }
    // if (e.target.value == "agent") {
    //   rowArray = this.state.agentsArray;
    // }

    this.setState(
      {
        rowValue: e.target.value,
        finalArray: [],
      },
      () => {
        this.getLeadData();
      }
    );
  };
  filterByColumn = (e) => {
    let columnArray = [];
    // if (e.target.value) {
    //   columnArray.push(e.target.value);
    // }
    // Object.keys(this.state.resArray).map((item, ind) => {
    //     this.state.resArray[0].map((data, index) => {
    //       columnArray.push(data.lead_type)
    //     });
    //   })

    // Object.values(obj)[0]

    if (e.target.value == "lead-type") {
      columnArray = this.state.resArray;
    }
    if (e.target.value == "status") {
      columnArray = this.state.lead_status_array;
    }
    if (e.target.value == "agent") {
      columnArray = this.state.agentsArray;
    }

    this.setState(
      {
        columnValue: e.target.value,
        // columnArray,
        finalArray: [],
      },
      () => {
        this.getLeadData();
      }
    );
  };

  //   practice = () => {
  //     let newArray = [];
  //     let temp = [];
  //     Object.keys(this.state.apiData).map((item, index) => {
  //       console.log("arrayitem", this.state.apiData[item], "item", item);
  //       this.state.apiData[item].map((k) => {
  //         temp.push(k.lead_type);
  //       });

  //       for (let i = 0; i < this.state.leadTypeArray.length; i++) {
  //         if (!temp.includes(this.state.leadTypeArray[i])) {
  //           console.log(this.state.leadTypeArray[i], "item");
  //           //   let item  = item
  //           newArray.push({
  //             total: 0,
  //             status: item,
  //             lead_type: this.state.leadTypeArray[i],
  //           });
  //         }
  //       }
  //       this.state.apiData[item].map((data) => {
  //         newArray.push(data);
  //       });
  //       console.log(newArray, "NewARRAY::::::::::");
  //       this.state.finalArray.push({ [item]: newArray });
  //       newArray = [];
  //       temp = [];
  //       console.log("break::::::::::::::");
  //     });
  //     console.log(this.state.finalArray, "FinalArray::::::::::");
  //   };

  render() {
    const setDateRange = (data) => {
      this.setState(
        {
          date_from: data.start,
          date_to: data.end,
          finalArray: [],
        },
        () => {
          this.getLeadData();
        }
      );
    };

    return (
      <>
        <div className="p-5 my-5 rounded border border-5 border-dark" id="table-padding-mobile">
          {/* filter section  */}
          <div className="row mb-3  justify-content-between">
            <div className="col-6 col-md-3">
              <div className="mb-2 quote_title_heading"
                style={{
                  fontWeight: "700",
                  fontSize: "17px",
                  backgroundColor: "#ffc107",
                  padding: "0.5rem",
                  borderRadius: "1rem",
                  textAlign: "center",
                  boxShadow: '0 5px 10px rgba(0, 0, 0, 0.8)'
                }}
              >
                Lead Summary Dashboard 
              </div>
            </div>
            <div className="col-6 col-md-3">
              <div 
                style={{
                  width: "100%",
                  background: "#fff",
                  cursor: "pointer",
                  border: "1px solid #ccc",
                  padding: "6px 10px 5px 17px",
                  margin: "auto",
                }}
              >
                <Daterangepicker
                  defaultDays="0"
                  setDateRange={setDateRange}
                ></Daterangepicker>
              </div>
            </div>

            <div className="col-6 col-md-3">
              <Form.Control
                as="select"
                // defaultValue={this.state.lead_type}
                custom
                onChange={this.filterByRow}
              >
                {/* <option value=""></option> */}
                <option value="status">Lead Status</option>
                <option value="lead-type">Lead Type</option>
                <option value="agent">Agent</option>
              </Form.Control>
              <div className="pt-1 text-sm">
                <h5 className="filters-title">Filter By Row</h5>
              </div>
            </div>
            <div className="col-6 col-md-3">
              <Form.Control
                as="select"
                // defaultValue={this.state.lead_type}
                custom
                onChange={this.filterByColumn}
              >
                <option value="">Select Column</option>
                <option value="lead-type">Lead Type</option>
                <option value="status">Lead Status</option>
                <option value="agent">Agent</option>
              </Form.Control>
              <div className="pt-1 text-sm">
                <h5 className="filters-title">Filter By Column</h5>
              </div>
            </div>
          </div>

          {/* table section starts from below */}
          <div className="row">
           <div className="mobile-table-scroll testing">
              <table className="rfqtable striped bordered hover">
                <thead>
                  <tr className="">
                    <th className="tabledef sticky-col first-col">{this.state.rowValue}</th>
                    {this.state.totalLeads &&
                    this.state.columnValue &&
                    this.state.columnValue != this.state.rowValue ? (
                      this.state.columnArray.map((item) => {
                        return (
                          <th className="tabledef bodyColor" value={item}>
                            {item}
                          </th>
                        );
                      })
                    ) : (
                      <th className="bodyColor tabledef">Lead Count</th>
                    )}
                  </tr>
                </thead>
                <tbody>
                  {this.state.totalLeads ? (
                    this.state.columnValue == this.state.rowValue ||
                    !this.state.columnValue ? (
                      <>
                        {Object.keys(this.state.resArray).map((item, ind) => {
                          return (
                            <tr>
                              <td className="tabledef">{item}</td>
                              <td className="tabledef">
                                {this.state.resArray[item][0].total}
                              </td>
                            </tr>
                          );
                        })}

                        <tr>
                          <td className="">Total</td>
                          <td className="tabledef bodyColor">
                            {this.state.totalLeads}
                          </td>
                        </tr>
                      </>
                    ) : (
                      <>
                        {Object.keys(this.state.resArray).map((item, ind) => {
                          let total = 0;
                          return (
                            <tr>
                              <td className="tabledef sticky-col first-col">{item}</td>
                              {this.state.resArray[item].map((data, index) => {
                                total += data.total;

                                return <td className="tabledef">{data.total}</td>;
                              })}
                              <td>{total}</td>
                            </tr>
                          );
                        })}
                        <tr>
                          <td className="tabledef sticky-col first-col">Total</td>

                          {Object.values(this.state.holder).map((item) => {
                            return <td className="tabledef">{item}</td>;
                          })}
                          <td className="tabledef">{this.state.rowTotal}</td>
                        </tr>
                      </>
                    )
                  ) : null}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default LeadData;
