// import React, { useState, useEffect } from "react";
import React, { Component } from "react";
// import { getUserDetails } from "../../Login/getLoginnedUserDetails";
import axios from "axios";
import Daterangepicker from "../../Dashboard/Common/Daterangepicker";
import { Form } from "react-bootstrap";
import { getUserDetails } from "../../Login/getLoginnedUserDetails";
import constants from "../../../Config";
import "./lead-table.css";
const apiUrl = constants.API_URL;
export class QuoteData extends Component {
  constructor(props) {
    super(props);
    this.state = {
      date_from: "",
      date_to: "",
      leadStatusArray: [],
      leadTypeArray: [],
      agentsArray: [],
      quote_type: "",

      //   leadStatus: "",
      agent: "",
      dateType: "created",
      apiData: [],
      holder: {},
    };
  }
  componentDidMount() {
    this.getAllAgents();
    this.getQuoteData();
  }
  getAllAgents = () => {
    let loginedUserDetails = JSON.parse(localStorage.getItem("userData"));
    const options = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${loginedUserDetails.token}`,
      },
      data: {
        agent_list: "true",
        id: [54],
        showAll: "true",
        slug: "",
      },


      // data: { logined_user_id: getUserDetails.user_id },
    };
    return axios(apiUrl + "/get-pre-sales-agents", options)
      .then((data) => {
        this.setState({
          agentsArray: data.data.data,
        });
      })
      .catch((error) => console.log(error));
  };
  getQuoteData = () => {
    let loginedUserDetails = JSON.parse(localStorage.getItem("userData"));
    const options = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${loginedUserDetails.token}`,
      },

      data: {
        type: this.state.dateType,
        key: this.state.quote_type,

        date_from: this.state.date_from,
        date_to: this.state.date_to,
      },
    };
    return axios(apiUrl + "/widget-quotes", options)
      .then((data) => {
        let apiData = data.data.data[0][0];
        Object.values(apiData).map((item) => {
          delete item[0].agent_id;
        });
        let holder = {};

        Object.values(apiData).map((item, i) => {
          Object.keys(item[0]).map((d) => {
            if (holder.hasOwnProperty(d)) {
              holder[d] = holder[d] + item[0][d];
            } else {
              holder[d] = item[0][d];
            }
          });
        });
        delete holder.agent;
        holder.conversionCount  =  (holder.closed_won/holder.totalQuotes)*100;
        holder.conversionValue =(holder.orderValue/holder.totalQuoteValue)*100
        console.log(holder);

        //setting states
        this.setState({
          apiData,
          holder,
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  filter = (e) => {
    this.setState({
      rowValue: e.target.value,
    });
  };
  handleDate = (e) => {
    this.setState(
      {
        dateType: e.target.value,
      },
      () => {
        this.getQuoteData();
      }
    );
  };
  filterByQuotetype = (e) => {
    this.setState(
      {
        quote_type: e.target.value,
      },
      () => {
        this.getQuoteData();
      }
    );
  };

  render() {
    const setDateRange = (data) => {
      this.setState(
        {
          date_from: data.start,
          date_to: data.end,
        },
        () => {
          this.getQuoteData();
        }
      );
    };

    return (
      <>
        <div className="p-5 my-5 rounded border border-dark" id="table-padding-mobile">
          {/* filter section  */}
          <div className="row mb-3 justify-content-between">
            <div className="col-6 col-md-3">
              <div className="mb-2 quote_title_heading"
                style={{
                  fontWeight: "700",
                  fontSize: "17px",
                  backgroundColor: "#28a745",
                  padding: "0.5rem",
                  borderRadius: "1rem",
                  textAlign: "center",
                  boxShadow: '0 5px 10px rgba(0, 0, 0, 0.8)'
                }}
              >
                Quote Dashboard
              </div>
            </div>
            <div className="col-6 col-md-3">
              <Form.Control as="select" custom onChange={this.handleDate}>
                <option value="created">Create Date</option>
                <option value="updated">Update Date</option>
              </Form.Control>
            </div>
            <div className="col-6 col-md-3">
              <div
                style={{
                  width: "100%",
                  background: "#fff",
                  cursor: "pointer",
                  border: "1px solid #ccc",
                  padding: "6px 10px 5px 17px",
                  marginLeft: "10px",
                }}
              >
                <Daterangepicker
                  defaultDays="29"
                  setDateRange={setDateRange}
                ></Daterangepicker>
              </div>
            </div>

            <div className="col-6 col-md-3">
              <Form.Control
                as="select"
                // defaultValue={this.state.lead_type}
                custom
                onChange={this.filterByQuotetype}
              >
                <option value="">All Quotes</option>
                <option value="normal">Manual Quote</option>
                <option value="magic">Magic Quote</option>
              </Form.Control>
            </div>
          </div>

          {/* table section starts from below */}
          <div className="mobile-table-scroll">
            <table className="rfqtable striped bordered hover">
              <thead>
                <tr className="">
                  <th className="tabledef sticky-col first-col">Agents</th>
                  <th className="bodyColor2">Draft</th>
                  <th className="bodyColor2">Shared</th>
                  <th className="bodyColor2">Closed-Won</th>
                  <th className="bodyColor2">Closed-Lost</th>
                  <th className="bodyColor2">Expired</th>
                  <th className="bodyColor2">Total Quotes (Count)</th>
                  <th className="bodyColor">Total Quotes (Value)</th>
                  <th className="bodyColor">Conversion % (Count)</th>
                  <th className="bodyColor">Order Value</th>
                  <th className="bodyColor">Conversion % (Value)</th>
                </tr>
                {Object.keys(this.state.apiData).map((item, i) => {
                  return (
                    <tr>
                      {Object.values(this.state.apiData[item][0]).map(
                        // (data, i) => {
                        //   return (
                        //     <td className="tabledef">
                        //       {data != null ? data : 0}
                        //     </td>
                        //   );
                        // }
                        (data, i) => {
                          if (i == 0) {
                            return (
                              <td className="tabledef sticky-col first-col">
                               {data !== null ? data : 0}
                              </td>
                            );
                          } else {
                            return <td className="tabledef">{data !== null ? data : 0}</td>;
                          }
                        
                        }
                      )}
                    </tr>
                  );
                })}
                <tr>
                  <td className="tabledef sticky-col first-col">Total</td>
                  {Object.values(this.state.holder).map((data) => {
                    return (
                      <td className="tabledef bodyColor">
                        {data - Math.floor(data) !== 0 ? data.toFixed(2) : data}
                      </td>
                    );
                  })}
                </tr>
              </thead>
            </table>
          </div>
        </div>
      </>
    );
  }
}

export default QuoteData;
