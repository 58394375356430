import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

import React, { useEffect, useState } from "react";
import {
  Alert,
  Button,
  Col,
  Form,
  Modal,
  Row,
  Spinner,
  Table,
} from "react-bootstrap";
import PoSummaryTable from "./PoSummaryTable";
import NewAddressModal from "./NewAddressModal";
import Axios from "axios";
import Constants from "../../../Config";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Redirect } from "react-router-dom";
import moment from "moment";
import { faDownload } from "@fortawesome/free-solid-svg-icons";
import { useHistory } from "react-router-dom";

const apiUrl = Constants.API_URL;
const loginedUserDetails = JSON.parse(localStorage.getItem("userData"));

const EditPO = () => {
  const history = useHistory();

  const [formValidate, setFormValidate] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const [wrong_phone, setWrongPhone] = useState(false);
  const [poStatus, setPoStatus] = useState("");
  const [stateArray, setStateArray] = useState([]);
  const [poResponse, setPoResponse] = useState({});
  const [poID, setPoID] = useState(0);
  const [quoteID, setQuoteID] = useState(0);
  const [showExistingOrder, setShowExistingOrder] = useState(false);
  const [existingOrders, setExistingOrders] = useState([]);
  const [poSummary, setPoSummary] = useState([]);
  const [isOpenNewAddress, openNewAddress] = useState(false);
  const [submitLoading, setSubmitLoading] = useState(false);
  const [showClosePO, setShowClosePO] = useState(false);
  const [customer_phone, setCustomerPhone] = useState("");
  const [companyName, setCompanyName] = useState("");
  const [customer_email, setCustomerEmail] = useState("");
  const [customer_name, setCustomerName] = useState("");
  const [agentId, setAgentId] = useState(0);
  const [addressArray, setAddresArray] = useState([]);
  const [validDate, setValidDate] = useState(new Date());
  const [closePOReason, setClosePOReason] = useState("");
  const [po_reference, setPoReference] = useState("");
  const [po_attachment, setPoAttachement] = useState("");
  const [poDetails, setQuoteDetailInPO] = useState({}); //details coming from create PO from listQuote
  const [agentsArray, setAgentsArray] = useState([]);
  const [selectedFile, setSelectedFile] = useState("");
  const [delieveryAddresses, setDelieveryAddresses] = useState("");
  const [isOnlyCreate, setIsOnlyCreate] = useState(false);
  const [is_redirect, setRedirect] = useState(false);
  const [showQuoteDetail, setShowQuoteDetail] = useState(false);
  const [quoteDetails, setQuoteDetail] = useState({});
  const [createOrderLinkPID, setCreateOrderLinkArray] = useState([]);
  const [createOrderLinkPIDString, setCreateOrderLinkString] = useState("");
  const [po_unique_id, setPoUniqueId] = useState("");
  const [hideCreateOrderButton, setCreateOrderButtonState] = useState(false);
  const [showQuoteIDButton, setShowQuoteIDButton] = useState(false);
  const [poShippingCharges, setPoShippingCharges] = useState(0);

  //   window.addEventListener("beforeunload", (event) => {
  //     if (event.currentTarget === window) {
  //       // Check if the tab is being closed
  //       localStorage.removeItem("poState");
  //       localStorage.removeItem("PO_Details");
  //     }
  //   });

  const poId = JSON.parse(localStorage.getItem("poState")); // link from PO list -> edit
  const quoteIdDetail = JSON.parse(localStorage.getItem("PO_Details")); // link from PO list -> edit
  useEffect(() => {
    if (poId) {
      setPoID(poId?.poID);
      getPODetailByID(poId?.poID);
      setIsOnlyCreate(false);
      getState();
      getQuoteDetailById(poId?.quoteId);
      getAgents();
      // localStorage.removeItem('PO_Details');
    }
  }, []);
  const getPODetailByID = (poID) => {
    const requestOption = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${loginedUserDetails.token}`, // Replace with your authorization header
      },
      data: {
        po_id: poID,
      }, // Provide any additional data here
    };

    Axios(`${apiUrl}/purchase-order-list`, requestOption)
      .then((res) => {
        let poDetailsObj = {};
        if (res.data.data.length > 0) {
          poDetailsObj = res.data.data[0];
          const addressParts = [];

          if (poDetailsObj.shipping_address_1) {
            addressParts.push(poDetailsObj.shipping_address_1);
          }
          if (poDetailsObj.shipping_address_2) {
            addressParts.push(poDetailsObj.shipping_address_2);
          }
          if (poDetailsObj.shipping_city) {
            addressParts.push(poDetailsObj.shipping_city);
          }
          if (poDetailsObj.shipping_zone) {
            addressParts.push(poDetailsObj.shipping_zone);
          }
          if (poDetailsObj.shipping_gst) {
            addressParts.push(poDetailsObj.shipping_gst);
          }
          if (poDetailsObj.shipping_postcode) {
            addressParts.push(poDetailsObj.shipping_postcode);
          }

          const poSummaryArray = poDetailsObj?.poSummary;
          let createOrderLinkArray = [];
          poSummaryArray.map((item) => {
            createOrderLinkArray.push([
              item.product_id,
              item.remaining_quantity,
            ]);
          });
          setCreateOrderLinkArray(createOrderLinkArray);

          const createOrderLink = createOrderLinkArray
            .map((subarray) => `[${subarray.join(",")}]`)
            .join(",");
          setCreateOrderLinkString(createOrderLink);

          const addressString = addressParts.join(", ");

          setPoResponse(poDetailsObj);
          setExistingOrders(poDetailsObj?.existingOrders);

          setDelieveryAddresses(addressString);
          if (poDetailsObj?.po_valid_date) {
            setValidDate(new Date(poDetailsObj?.po_valid_date));
          }
          setPoStatus(poDetailsObj?.po_current_status);
          setPoReference(poDetailsObj?.po_reference);
          setPoAttachement(poDetailsObj?.po_attachment);
          setPoSummary(poDetailsObj?.poSummary);
          setErrorMessage("");
          setQuoteID(poDetailsObj?.quote_id);
          setShowQuoteIDButton(true);
          setCustomerName(poDetailsObj?.customerName);
          setCustomerEmail(poDetailsObj?.customerEmail);
          setCustomerPhone(poDetailsObj?.customerPhone);
          setCompanyName(poDetailsObj?.company_name);
          setPoUniqueId(poDetailsObj?.quote_unique_id);
        }
      })
      .catch((err) => {
        setErrorMessage(err.response.data.message);
        setTimeout(() => {
          setErrorMessage("");
        }, 3000);
      });
  };
  const getQuoteDetailById = async (QUOTEID) => {
    const token = loginedUserDetails.token;
    const params = {
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      data: {
        quoteID: QUOTEID,
      },
    };
    return await Axios(apiUrl + "/get-quotes", params)
      .then((data) => {
        const quoteData = data.data.data[0];
        // customer_email2: dataObject.email,
        //     customer_name2: dataObject.companyData.name,
        //     customer_phone2: dataObject.companyData.telephone,
        //     companyName: dataObject.companyData.companyName,
        setCustomerName(quoteData.companyData.name);
        setCustomerEmail(quoteData.email);
        setCustomerPhone(quoteData.companyData.telephone);
        setCompanyName(quoteData.companyData.companyName);
        setQuoteDetail(quoteData);
        setAddresArray(quoteData.companyData?.address);
        setAgentId(quoteData?.agent_id);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleDateChange = (date) => {
    setValidDate(date);
  };
  const openQuote = () => {
  };
  const getPhoneValue = () => { };
  const getCurrentAgent = () => { };
  const closeAddressModal = (value) => {
    openNewAddress(value);
  };

  const validateFile = (file) => {
    var allowedExtension = [
      "image/jpeg",
      "image/jpg",
      "image/png",
      "application/pdf",
      "application/vnd.ms-excel",
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      "application/msword",
      "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
      "text/plain",
    ];
    if (file) {
      if (!allowedExtension.includes(file.type)) {
        alert("only allowed extension jpeg,jpg,png,pdf,excel file");
        return false;
      }
      if (file.size > 5000000) {
        alert("Please attached file less than 5 mb");
        return false;
      }
      return true;
    } else {
      alert("you did not attached file");
    }
  };
  const onFileChange = async (event) => {
    // Update the state
    const file = event.target.files[0];
    var allowedimage = validateFile(file);

    let formData = new FormData();
    formData.append("file", event.target.files[0]);
    Axios.post(apiUrl + "/rfq-attachment", formData, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${loginedUserDetails.token}`,
      },
    })
      .then((res) => {
        setSelectedFile(res.data.data.image_url);
      })
      .catch((err) => {
        console.log(err);
        setErrorMessage(err.response.data.message);
        setTimeout(() => {
          setErrorMessage("");
        }, 3000);
      });
  };
  const addNewAddress = () => {
    openNewAddress(true);
  };
  const getProductIDQty = (productIDQtyArray) => {
    const createOrderLink = productIDQtyArray
      .map((subarray) => `[${subarray.join(",")}]`)
      .join(",");

    setCreateOrderLinkString(createOrderLink);

    console.log(createOrderLink, "Array after PO");
  };
  const getCreateOrderButtonState = (createOrderButtonFlag) => {
    setCreateOrderButtonState(createOrderButtonFlag);
  };
  const createOrderLoad = (e) => {
    e.preventDefault();
    setSubmitLoading(true);
  };
  const createPOSubmit = (e) => {
    e.preventDefault()
    setSubmitLoading(true);
    const data = {
      po_id: poID,
      quote_id: quoteID,
      orders: createOrderLinkPID,
      po_shipping_charges: poShippingCharges,
    };
    Axios.post(apiUrl + "/create-po-orders", data, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${loginedUserDetails.token}`,
      },
    })
      .then((data) => {
        let orderUrl = "";
        setTimeout(() => {
          // if (
          //   process.env.NODE_ENV === "production" ||
          //   process.env.NODE_ENV === "Production"
          //   ) {
          //     orderUrl = `https://mccoymart.com/buy/view-quote/${po_unique_id}/${poID}`;
          //   } else {
          //     orderUrl = `https://uat.mccoymart.com/buy/view-quote/${po_unique_id}/${poID}`;
          //   }
          orderUrl = `${Constants.WEB_URL}/buy/view-quote/${po_unique_id}/${poID}`
          setSubmitLoading(false);
          window.open(orderUrl)
          history.push("/po-list");
          // return <Redirect to={orderUrl} />
        }, 3000);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const openClosePO = () => {
    setShowClosePO(true);
  };
  const handleClosePO = (e) => {
    setClosePOReason(e.target.value);
  };
  const handleSubmitClosePO = (e) => {
    // setShowClosePO(false);
    setSubmitLoading(true);
    // setClosePOReason(""); //resetting the value for close PO reason

    //create POST api call will be executed below on clicktype
    e.preventDefault();
    const token = loginedUserDetails.token;
    const params = {
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      data: {
        id: poID,
        status: "Closed",
        reject_reason: closePOReason,
      },
    };
    return Axios(apiUrl + "/update-purchase-order", params)
      .then((response) => {
        setSuccessMessage("PO Closed Successfully");
        setErrorMessage("");
      })
      .then(() => {
        setTimeout(() => {
          setSubmitLoading(false);
          history.push("/po-list");
        }, 2000);
      })
      .catch((err) => {
        setErrorMessage(err.response.data.message);
        setSubmitLoading(false);
        setTimeout(() => {
          setErrorMessage("");
        }, 3000);
      });
    // let submitType = e.target.id;
  };
  const getState = () => {
    let loginedUserDetails = JSON.parse(localStorage.getItem("userData"));
    const token = loginedUserDetails.token;
    const requestoption = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    };
    return Axios(apiUrl + "/get-all-states", requestoption)
      .then((data) => {
        setStateArray(data.data.data);
      })
      .catch((error) => console.log(error));
  };
  const getAgents = () => {
    const token = loginedUserDetails.token;
    const requestoption = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      data: {
        agent_list: "",
        id: [56, 54],
        showAll: "",
        slug: "editPO",
      },
    };
    return Axios(apiUrl + "/get-pre-sales-agents", requestoption)
      .then((data) => {
        setAgentsArray(data.data.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const openQuoteDetail = () => {
    setShowQuoteDetail(true);
  };
  const closeQuoteDetailModal = () => {
    setShowQuoteDetail(false);
  };
  const addAddress = (addressString) => {
    const addressArray = delieveryAddresses;
    addressArray.push(addressString);
    setDelieveryAddresses(addressArray);
  };

  // const handleShippingChargesValue = (event) => {
  //   // const inputValue = parseInt(e.target.value, 10) || 0;
  //   setPoShippingCharges(event.target.value);
  // }

  return (
    <Row>
      {errorMessage ? (
        <Alert
          className="displayMessageArea"
          variant={errorMessage ? "danger" : null}
        >
          {errorMessage}
        </Alert>
      ) : null}
      {successMessage ? (
        <Alert
          className="displayMessageArea"
          variant={successMessage ? "success" : null}
        >
          {successMessage}
        </Alert>
      ) : null}
      <Col md={12}>
        <Form noValidate validated={formValidate}>
          {/* // SECTION 1 ->  Customer DETAILS */}
          <div className="card">
            <div className="card-header justify-content-between">
              <h3>
                <b>PO Information</b>
              </h3>
              <div>
                {showQuoteIDButton ?
                  <Button
                    variant="secondary"
                    size="md"
                    onClick={openQuoteDetail}
                    className="mr-5"
                  >
                    {/* {`Quote ID : ${poDetails?.quoteID}`} */}
                    {`Quote ID : ${quoteID ? quoteID : quoteDetails?.quote_id}`}
                  </Button>
                  : null}
                <Modal
                  show={showQuoteDetail}
                  onHide={closeQuoteDetailModal}
                  dialogClassName="modal-90w"
                >
                  <Modal.Header closeButton></Modal.Header>
                  <Modal.Body>
                    <Form.Row>
                      <Col md="3">
                        <b>Quote ID</b>
                      </Col>
                      <Col md="3">
                        <b>Quote Type</b>
                      </Col>
                      <Col md="3">
                        <b>Quote Status</b>
                      </Col>
                      <Col md="3">
                        <b>Quote Validity</b>
                      </Col>
                    </Form.Row>
                    <div className="p-3 my-3 rounded border border-5 border-dark">
                      <Form.Row>
                        <Col md="3">
                          <b>{quoteDetails?.quote_id}</b>
                        </Col>
                        <Col md="3">
                          {quoteDetails?.quote_type == 1 ? "Normal" : "Magic"}
                        </Col>
                        <Col md="3">{quoteDetails?.quote_status}</Col>
                        <Col md="3">
                          {quoteDetails?.valid_upto
                            ? moment(quoteDetails?.valid_upto).format(
                              "DD-MM-YYYY"
                            )
                            : ""}
                        </Col>
                      </Form.Row>
                    </div>
                  </Modal.Body>
                </Modal>
              </div>
            </div>

            <div className="card-body">
              <Form.Row>
                <Col md="3">
                  <Form.Group controlId="exampleForm.SelectCustom">
                    <Form.Label>
                      <b>Customer Phone</b>
                    </Form.Label>

                    <Form.Control
                      type="number"
                      name="customer_phone"
                      placeholder="Customer Phone"
                      defaultValue={customer_phone}
                      disabled
                      onChange={getPhoneValue}
                      className={wrong_phone ? "error" : ""}
                      required
                    />
                  </Form.Group>
                </Col>
                <Col md="3">
                  <Form.Group
                    controlId="exampleForm.SelectCustom"
                    value="b2blandingpage"
                  >
                    <Form.Label>
                      <b>
                        Account Name <span>(*)</span>
                      </b>
                    </Form.Label>
                    <Form.Control
                      type="text"
                      value={companyName}
                      name="companyName"
                      placeholder="Company Name"
                      disabled
                    // required
                    />
                  </Form.Group>
                </Col>
                <Col md="3">
                  <Form.Group controlId="exampleForm.SelectCustom">
                    <Form.Label>
                      <b>PO Owner</b>
                    </Form.Label>
                    <Form.Control
                      as="select"
                      custom
                      name="agentId"
                      required
                      onChange={(e) => setAgentId(e.target.value)}
                      value={agentId}
                      disabled
                      defaultValue={agentId}
                    >
                      <option value="">Please select agent</option>
                      {agentsArray &&
                        agentsArray.map((item) => {
                          var selected = agentId == item.id ? true : false;
                          return item.id ? (
                            <option
                              selected={selected}
                              key={item.id}
                              value={item.id}
                            >
                              {item.name}
                            </option>
                          ) : null;
                        })}
                    </Form.Control>
                    <Form.Control.Feedback type="invalid">
                      Please select PO owner.
                    </Form.Control.Feedback>
                  </Form.Group>
                </Col>
                <Col md="3">
                  <Form.Group controlId="formGridValidUpto">
                    <Form.Label>
                      <b>PO Expiry Date *</b>
                    </Form.Label>
                    <DatePicker
                      dateFormat="yyyy-MM-dd"
                      selected={validDate}
                      onChange={handleDateChange}
                      className="form-control"
                      locale="es"
                      minDate={new Date()}
                      required
                    //   value={validDate}
                    />
                  </Form.Group>
                </Col>
                <Col md="3">
                  <Form.Group controlId="customerName">
                    <Form.Label>
                      <b>Customer Name</b>{" "}
                    </Form.Label>
                    <Form.Control
                      type="text"
                      // value={this.state.customer_name}
                      value={customer_name}
                      name="customer_name"
                      placeholder="Customer Name"
                      disabled
                      required
                    />
                  </Form.Group>
                </Col>
                <Col md="3">
                  <Form.Group controlId="customerEmail">
                    <Form.Label>
                      <b>Customer Email </b>
                    </Form.Label>
                    <Form.Control
                      type="text"
                      // value={this.state.customer_email}
                      value={customer_email}
                      name="customer_email"
                      placeholder="Customer Email"
                      disabled
                      required
                    />
                  </Form.Group>
                </Col>
              </Form.Row>
            </div>
          </div>
          {/* //SECTION 2 // */}
          <div className="card">
            <div className="card-body">
              {/* Section for Attachment , PO reference Number, Expiry date, See Existing Orders  */}
              <Row >
                <Col md="2">
                <Form.Group>
                  {po_attachment ? (
                    <>
                      <Form.Label>Attachment</Form.Label>{" "}
                      <FontAwesomeIcon
                        icon={faDownload}
                        onClick={() => window.open(po_attachment, "_blank")}
                      />
                    </>
                  ) : (
                    <Form.Group controlId="formFile">
                      <Form.Label>
                        {" "}
                        <b>Choose File</b>
                      </Form.Label>
                      <Form.Control
                        type="file"
                        name="attachment"
                        id="image"
                        onChange={onFileChange}
                      />
                    </Form.Group>
                  )}
                  </Form.Group>
                </Col>
                <Col md="2">
                  <Form.Group>
                    <Form.Label>
                      <b>PO Reference No. </b>
                    </Form.Label>
                    <Form.Control
                      placeholder="Enter PO reference"
                      onChange={(e) => setPoReference(e.target.value)}
                      value={po_reference}
                    //   disabled={po_reference}
                    />
                  </Form.Group>
                </Col>
              </Row>
            </div>
            {existingOrders.length ? (
              <div className="p-3">
                <h4>
                  <b>Existing Orders</b>
                </h4>
                <Table striped bordered hover>
                  <thead>
                    <th>
                      <b>Order ID</b>
                    </th>
                    <th>
                      <b>Order Date</b>
                    </th>
                    <th>
                      <b>Order Status</b>
                    </th>
                    <th>
                      <b>Product Name</b>
                    </th>
                    <th>
                      <b>Quantity</b>
                    </th>
                    <th>
                      <b>Order value</b>
                    </th>
                  </thead>
                  <tbody>
                    {existingOrders &&
                      existingOrders.map((order) => (
                        <tr key={order.orderId}>
                          <td>{order.order_id}</td>
                          <td>{order.order_date}</td>
                          <td>{order.order_status}</td>
                          <td>{order.product_name}</td>
                          <td>{order.ordered_quantity}</td>
                          <td>{order.ordered_value}</td>
                        </tr>
                      ))}
                  </tbody>
                </Table>
              </div>
            ) : null}
          </div>
          {/* // SECTION 3  -> PO Summary (Edit , Create) */}
          <div className="card">
            {poSummary.length > 0 ? (
              <>
                <div className="card-header">
                  <h4>
                    <b>PO Summary</b>
                  </h4>
                </div>
                <div className="p-3 mobile-table-scroll">
                  <PoSummaryTable
                    createPOSubmit={createPOSubmit}
                    poShippingCharges={poShippingCharges}
                    setPoShippingCharges={setPoShippingCharges}
                    // handleShippingChargesValue={handleShippingChargesValue}
                    poSummary={poSummary}
                    isOnlyCreate={isOnlyCreate}
                    createOrderLinkArray={createOrderLinkPID}
                    getProductIDQty={getProductIDQty}
                    poResponse={poResponse}
                    getCreateOrderButtonState={getCreateOrderButtonState}
                  />
                </div>
              </>
            ) : null}
            {/* //Delievery address section */}
            <div className="p-3">
              <>
                <h3>
                  <b>Delivery Addresses</b>
                </h3>
                <div>
                  <p>
                    {delieveryAddresses}
                    <br />
                  </p>
                </div>
              </>

              {/* {!isOnlyCreate ? (
                <Button onClick={addNewAddress}>
                  Add New Delivery Address
                </Button>
              ) : null}
              {isOpenNewAddress ? (
                <NewAddressModal
                  closeAddressModal={closeAddressModal}
                  addAddress={addAddress}
                  stateArray={stateArray}
                  addressArray={addressArray}
                />
              ) : null} */}
            </div>
            {isOnlyCreate ? null : (
              <div className="d-flex justify-content-between w-100 p-3">
                {/* Create Order Button Below */}

                {!["Closed", "Expired", "Fulfilled"].includes(poStatus) ? (
                  <Button
                    variant="success"
                    type="submit"
                    id="createOnly"
                    disabled={hideCreateOrderButton}
                    onClick={createPOSubmit}
                  >
                    {!hideCreateOrderButton ? (
                      // <a
                      //   href={
                      //     process.env.NODE_ENV === "production" ||
                      //     process.env.NODE_ENV === "Production"
                      //       ? `https://mccoymart.com/buy/view-purchase-order/${po_unique_id}/?po_id=${poID}&p_ids=${createOrderLinkPIDString}`
                      //       : `https://uat.mccoymart.com/buy/view-purchase-order/${po_unique_id}/?po_id=${poID}&p_ids=${createOrderLinkPIDString}`
                      //   }
                      //   className="text-white text-decoration-none"
                      //   rel="noopener noreferrer"
                      // >
                      submitLoading ? (
                        <Spinner animation="border" role="status">
                          <span className="sr-only">Loading...</span>
                        </Spinner>
                      ) : (
                        "Create Order"
                      )
                    ) : (
                      // </a>
                      "Create Order"
                    )}
                  </Button>
                ) : null}

                {/* // Close PO Button Below */}

                {!["Closed", "Expired", "Fulfilled"].includes(poStatus) ? (
                  showClosePO ? (
                    <div className="d-flex w-50 justify-content-between">
                      <Form.Control
                        placeholder="Write PO Closing Reason..."
                        onChange={handleClosePO}
                        value={closePOReason}
                        style={{ width: "500px" }}
                        onKeyDown={(e) => {
                          e.key === "Enter" && e.preventDefault();
                        }}
                        required
                      />
                      <Button onClick={handleSubmitClosePO}>
                        {submitLoading ? (
                          <Spinner animation="border" role="status">
                            <span className="sr-only">Loading...</span>
                          </Spinner>
                        ) : (
                          "Close PO"
                        )}
                      </Button>
                    </div>
                  ) : (
                    <Button variant="danger" onClick={openClosePO}>
                      Close PO Reason
                    </Button>
                  )
                ) : null}
              </div>
            )}
          </div>
        </Form>
      </Col>
    </Row>
  );
};

export default EditPO;
