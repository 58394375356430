import React, { Component } from "react";
import { Form, Col, Row, Alert, Button, Modal, Spinner } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import SelectSearch from "react-select-search";
import AsyncSelect from "react-select/async";
import { getUserDetails } from "../../Login/getLoginnedUserDetails";
import axios from "axios";
import Constants from "../../../Config";
import { components } from "react-select";
import { ReactComponent as SearchIcon } from "../../../asset/Images/search.svg";
import DeleteIcon from "../../../asset/Images/delete icon.svg";
import removeIcon from "../../../asset/Images/removeIcon.svg";
import classes from "../SourcingRequest/CreateSource.module.css";
import { ReactComponent as DownArrowIcon } from "../../../asset/Images/RightArrow.svg";

const CustomInput = (props) => (
  <div style={{ display: "flex", alignItems: "center", height: "100%" }}>
    <SearchIcon style={{ marginRight: "4px" }} />
    <components.Input {...props} />
  </div>
);

const customStyles = {
  control: (provided, state) => ({
    ...provided,
    backgroundColor: "transparent",
    border: "0",
    borderColor: state.isFocused ? "none" : "none",
    "&:hover": {
      borderColor: state.isFocused ? "none" : "none",
    },
    boxShadow: state.isFocused ? "none" : "none",
    minHeight: "auto",
    height: "100%",
  }),
  valueContainer: (provided) => ({
    ...provided,
    height: "100%",
    padding: "0",
  }),
  placeholder: (provided) => ({
    ...provided,
    color: "#99ABBE",
    fontSize: "13px",
  }),
  singleValue: (provided) => ({
    ...provided,
    color: "#1E293B",
    padding: "0 0 0 20px",
    fontSize: "13px",
  }),
  dropdownIndicator: (provided) => ({
    ...provided,
    display: "none",
  }),
  indicatorSeparator: (provided) => ({
    ...provided,
    display: "none",
  }),
};
const apiUrl = Constants.API_URL;

export class ProductAdd2 extends Component {
  state = {
    productArray: [],
    productList: [],
    categoryArray: [],
    categoryList: [],
    product_name: "",
    product_id: "",
    pack_quantity: "",
    product_quantity: "",
    category_quantity: "",
    count: "",
    category_name: "",
    type: "",
    //
    other: "",
    attachments: "",
    other_quantity: 1,
    typeSwitch: false,

    selectedValue: "product",
    itemQuantity: "",
    quantity_type: "",
    brand: "",

    isImageLoading: false,
    uploadedFiles: [],
    fileNames: [],
    selectedFile: null,
    attachmentStatus: false,
  };
  handleSelect = (i, e) => {
    this.setState(
      {
        selectedValue: e.target.value,
        typeSwitch: true,
      },
      () => {
        this.props.addDisplayArray(i, {
          name: "",
          quantity: 0,
          type: this.state.selectedValue,
          category_id: 0,
          product_id: 0,
        });
      }
    );
  };
  handleChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };
  handleChangeCategQuantity = (i, e) => {
    this.setState(
      {
        [e.target.name]: e.target.value,
      },
      () => {
        this.props.addDisplayArray(i, {
          name: this.state.category_name,
          quantity: this.state.category_quantity,
          type: "category",
          category_id: this.state.category_id,
          product_id: 0,
        });
      }
    );
  };
  handleChangeProductQuantity = (e, i, itemtype) => {
    this.setState(
      {
        [e.target.name]: e.target.value,
      },
      () => {
        this.props.addDisplayArray(i, {
          name:
            itemtype == "product"
              ? this.state.product_name
              : itemtype == "category"
              ? this.state.category_name
              : itemtype == "attachments"
              ? this.state.attachments
              : this.state.other,
          quantity: this.state.itemQuantity,
          type: itemtype,
          price: itemtype == "product" ? this.state.product_price : 0,
          product_id: itemtype == "product" ? this.state.product_id : 0,
          category_id: itemtype == "category" ? this.state.category_id : 0,
          quantity_type: this.state.quantity_type,
          brand: this.state.brand,
          brand_id:
            itemtype == "category" ||
            itemtype == "other" ||
            itemtype == "attachments"
              ? this.state.brand_id
              : 0,
        });

        // this.props.addDisplayArray(i, {
        //   name: this.state.product_name,
        //   quantity: this.state.product_quantity,
        //   type: this.state.selectedValue,
        //   price: this.state.product_price,
        //   product_id: this.state.product_id,
        //   category_id: 0,
        // });
      }
    );
  };
  getProductName = (i, e) => {
    let index = this.state.productList.filter((el) => el.name == e.value)[0];
    // let productId = index.product_id;
    this.setState(
      {
        product_id: index.product_id,
        product_name: index.name,
        //   availabe_stock: index.quantity,
        moq: index.moq,
        product_price: Number(index.price).toFixed(2),
        // product_quantity: index.moq,
        quantity_type: index.qty_type,
        brand: index.brand,
        brand_id: index.brand_id,
        count: index.moq,
        product_quantity: index.pack_quantity,
      },
      () => {
        this.props.addDisplayArray(i, {
          name: index.name,
          quantity: this.state.product_quantity.toString(),
          type: "product",
          product_id: index.product_id,
          category_id: 0,
          price: Number(index.price).toFixed(2),
          quantity_type: index.qty_type,
          brand: index.brand,
          brand_id: index.brand_id,
        });
      }
    );
  };

  getValue = (e, cb) => {
    // let loginedUserDetails = JSON.parse(localStorage.getItem("userData"));
    axios({
      method: "POST",
      url: apiUrl + "/get-products-by-keyword",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${getUserDetails.token}`,
      },
      data: { keyword: e, status: "2" },
    })
      .then((data) => {
        this.setState({
          productList: data.data.data,
          productArray: data.data.data.map((item) => {
            return {
              name: item.name,
              value: item.name,
            };
          }),
        });
        cb(
          data.data.data.map((i) => ({
            label: `${i.name}`,
            value: `${i.name}`,
          }))
        );
      })
      .catch((error) => console.log(error));
  };
  getCategory = (e, cb) => {
    axios({
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${getUserDetails.token}`,
      },
      url: apiUrl + "/get-product-category",
      data: {
        keyword: e,
      },
    })
      .then((data) => {
        this.setState({
          categoryList: data.data.data,
          categoryArray: data.data.data.map((item) => {
            return {
              name: item.name,
              value: item.name,
            };
          }),
        });
        cb(
          data.data.data.map((i) => ({
            label: `${i.name}`,
            value: `${i.name}`,
          }))
        );
      })
      .catch((error) => console.log(error));
  };

  getCategoryName = (i, e) => {
    let categ = this.state.categoryList.filter((el) => el.name == e.value)[0];
    categ["type"] = "category";
    // categ.push(type: "category")
    this.setState(
      {
        category_name: e.value,
        category_id: categ.category_id,
        type: categ.type,
        categSelect: true,
        brand_id: 0,
      },
      () => {
        this.props.addDisplayArray(i, {
          name: categ.name,
          quantity: this.state.category_quantity
            ? this.state.category_quantity
            : 1,
          type: "category",
          category_id: categ.category_id,
          product_id: 0,
          quantity_type: categ.quantity_type ? categ.quantity_type : "",
          brand: categ.brand ? categ.brand : "",
          brand_id: 0,
        });
      }
    );
  };
  getOtherName = (i) => {
    this.props.addDisplayArray(i, {
      name: this.state.other,
      quantity: 0,
      type: "other",
      category_id: 0,
      product_id: 0,
      quantity_type: "",
      brand: "",
      brand_id: 0,
    });
  };

  // addMoq = () => {
  //   let quantity =
  //     Number(this.state.product_quantity) + Number(this.state.pack_quantity);
  //   this.setState({ product_quantity: quantity });
  //   // this.props.addNewProduct({
  //   //   product_id: this.state.product_id,
  //   //   quantity: quantity ? quantity : this.state.product_quantity,
  //   //   name: this.state.product_name,
  //   // });
  // };

  validateFile = (file) => {
    var allowedExtension = [
      "image/jpeg",
      "image/jpg",
      "image/png",
      "application/pdf",
      "application/vnd.ms-excel",
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      "application/msword",
      "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
      "text/plain",
    ];
    if (file) {
      if (!allowedExtension.includes(file.type)) {
        alert("only allowed extension jpeg,jpg,png,pdf,excel file");
        return false;
      }
      if (file.size > 5000000) {
        alert("Please attached file less than 5 mb");
        return false;
      }
      return true;
    } else {
      alert("you did not attached file");
    }
  };

  getFileType = (filename) => {
    const fileExtension = filename.split(".").pop().toLowerCase();
    switch (fileExtension) {
      case "pdf":
        return "pdf";
      case "jpeg":
      case "jpg":
      case "png":
        return "image";
      case "doc":
      case "docx":
        return "doc";
      case "xls":
        return "xls";
      default:
        return "unknown";
    }
  };

  onFileChanges = async (i, event) => {
    const file = event.target.files[0];
    const allowedImage = this.validateFile(file);
    if (!allowedImage) {
      this.setState({ isImageLoading: false });
      return;
    }
    const fileType = this.getFileType(file.name);
    let formData = new FormData();
    formData.append("file", file);
    this.setState({ isImageLoading: true });
    axios
      .post(apiUrl + "/rfq-attachment", formData, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${getUserDetails.token}`,
        },
      })
      .then((res) => {
        const imageUrl = res.data.data.image_url;
        this.setState((prevState) => ({
          fileNames: [...prevState.fileNames, file.name],
          selectedFile: imageUrl,
          isImageLoading: false,
          attachmentStatus: true,
        }));
        this.props.addDisplayArray(i, {
          name: this.state.selectedFile,
          type: "attachments",
          category_id: 0,
          product_id: 0,
          quantity_type: "",
          brand: "",
          brand_id: 0,
        });
      });
  };

  render() {
    return (
      <>
        <Row
          className={`${classes.requirement_items} px-0`}
          key={this.props.index}
        >
          <Col lg={2} className={classes.verticalCenter}>
            <Form.Group
              controlId="formLeadTypeName"
              className={classes.formGroup}
            >
              <Form.Label className={classes.customLabel}>
                Type
                <span class={classes.mandatory}>*</span>
              </Form.Label>
              <div className={classes.customSelectCnt}>
                <Form.Control
                  className={classes.customSelect}
                  as="select"
                  custom
                  onChange={(e) => this.handleSelect(this.props.index, e)}
                  value={this.props.item.type}
                  //   disabled={this.props.categoryDisable} //the disable condition is for Add item modal of CreateQuote Component
                >
                  <option value="product">Catalogue</option>
                  <option value="category">Category</option>
                  <option value="other">Other</option>
                  <option value="attachments">Attachment</option>
                </Form.Control>
                <DownArrowIcon className={classes.selectDownArrow} />
              </div>
            </Form.Group>
            <hr className={classes.customeSeprator} />
          </Col>
          {this.props.item.type == "product" ? (
            <Col lg={4} className={classes.verticalCenter}>
              <Form.Group
                controlId="formLeadProductName"
                key={this.props.index}
                className={classes.formGroup}
              >
                <Form.Label className={classes.customLabel}>
                  Name
                  <span class={classes.mandatory}>*</span>
                </Form.Label>
                <AsyncSelect
                  components={{ Input: CustomInput }}
                  className={classes.customSelect}
                  styles={customStyles}
                  value={
                    this.props.item.name || this.state.typeSwitch
                      ? {
                          value: this.props.item.name,
                          label: this.props.item.name,
                        }
                      : {
                          value: this.state.product_name,
                          label: this.state.product_name,
                        }
                  }
                  loadOptions={this.getValue}
                  placeholder="Product name"
                  onChange={(e) => this.getProductName(this.props.index, e)}
                />
              </Form.Group>
              <hr className={classes.customeSeprator} />
            </Col>
          ) : null}
          {this.props.item.type == "category" ? (
            <Col lg={4} className={classes.verticalCenter}>
              <Form.Group
                controlId="formLeadProductName"
                key={this.props.index}
                className={classes.formGroup}
              >
                <Form.Label className={classes.customLabel}>
                  Name
                  <span class={classes.mandatory}>*</span>
                </Form.Label>
                <AsyncSelect
                  components={{ Input: CustomInput }}
                  className={classes.customSelect}
                  styles={customStyles}
                  value={
                    this.props.item.name || this.state.typeSwitch
                      ? {
                          value: this.props.item.name,
                          label: this.props.item.name,
                        }
                      : {
                          value: this.state.category_name,
                          label: this.state.category_name,
                        }
                  }
                  loadOptions={this.getCategory}
                  placeholder="Product name"
                  onChange={(e) => this.getCategoryName(this.props.index, e)}
                />
              </Form.Group>
              <hr className={classes.customeSeprator} />
            </Col>
          ) : null}
          {this.props.item.type == "other" ? (
            <Col lg={4} className={classes.verticalCenter}>
              <Form.Group className={classes.formGroup}>
                <Form.Label className={classes.customLabel}>
                  Name
                  <span class={classes.mandatory}>*</span>
                </Form.Label>
                <Form.Control
                  type="text"
                  name="other"
                  className={classes.customSelect}
                  defaultValue={this.props.item.name}
                  value={
                    this.props.item.name
                      ? this.props.item.name
                      : this.state.other
                  }
                  onChange={this.handleChange}
                  onBlur={() => this.getOtherName(this.props.index)}
                />
              </Form.Group>
              <hr className={classes.customeSeprator} />
            </Col>
          ) : null}

          {this.props.item.type == "attachments" ? (
            <Col lg={9} className={classes.verticalCenter}>
              <Form.Group
                controlId="formLeadProductName"
                key={this.props.index}
                className={classes.formGroup}
              >
                <Form.Label className={classes.customLabel}></Form.Label>
                <>
                  {this.state.attachmentStatus ? (
                    <Form.Group
                      className={`${classes.formGroup} ${classes.attachmenttextWrapper}`}
                    >
                      <a
                        href={this.props.item.name}
                        target="_blank"
                        className={classes.attachmenttext}
                      >
                        Attachment
                      </a>
                    </Form.Group>
                  ) : (
                    <Form.Group
                      controlId="formFile"
                      className={classes.att_choose_file_cnt}
                    >
                      {this.state.isImageLoading ? (
                        <Spinner animation="border" role="status">
                          <span className="sr-only">Loading...</span>
                        </Spinner>
                      ) : (
                        <div className={classes.att_choose_file_wrapper}>
                          <input
                            type="file"
                            name="attachments"
                            id="fileInput"
                            className="file-input"
                            accept="image/*,.pdf,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,application/vnd.ms-excel"
                            onChange={(e) =>
                              this.onFileChanges(this.props.index, e)
                            }
                          />
                          <label
                            htmlFor="fileInput"
                            className={classes.choose_file_label}
                            style={{ cursor: "pointer" }}
                          >
                            Choose File
                          </label>
                          <p className={classes.chhose_file_text}>
                            No file choosen
                          </p>
                        </div>
                      )}
                    </Form.Group>
                  )}
                </>
              </Form.Group>
              <hr className={classes.customeSeprator} />
            </Col>
          ) : null}
          {this.props.item.type != "attachments" ? (
            <Col lg={2} className={classes.verticalCenter}>
              <Form.Group
                controlId="formRfqPrice"
                key={this.props.index}
                className={classes.formGroup}
              >
                <Form.Label className={classes.customLabel}>Brand</Form.Label>
                <Form.Control
                  className={
                    this.props.item.type == "product"
                      ? classes.labelreadContent
                      : classes.customInput
                  }
                  name="brand"
                  value={
                    this.props.item.brand || this.state.typeSwitch
                      ? this.props.item.brand
                      : this.state.brand
                  }
                  onChange={(e) =>
                    this.handleChangeProductQuantity(
                      e,
                      this.props.index,
                      this.props.item.type
                    )
                  }
                  disabled={this.props.item.type == "product"}
                />
              </Form.Group>
              <hr className={classes.customeSeprator} />
            </Col>
          ) : null}
          {this.props.item.type != "attachments" ? (
            <Col lg={1} className={classes.verticalCenter}>
              <Form.Group className={classes.formGroup}>
                <Form.Label className={classes.customLabel}>
                  Quantity
                </Form.Label>
                <Form.Group
                  controlId="formLeadProductQuantity"
                  key={this.props.index}
                  className={classes.formGroup}
                >
                  <Form.Control
                    // className={this.state.quantity_error ? "error" : ""}
                    className={classes.customInput}
                    type="number"
                    min={1}
                    placeholder="Qty"
                    name="itemQuantity"
                    value={
                      this.props.item.quantity || this.state.typeSwitch
                        ? this.props.item.quantity
                        : this.state.itemQuantity
                    }
                    onChange={(e) =>
                      this.handleChangeProductQuantity(
                        e,
                        this.props.index,
                        this.props.item.type
                      )
                    }
                  />
                </Form.Group>
              </Form.Group>
              <hr className={classes.customeSeprator} />
            </Col>
          ) : null}
          {this.props.item.type != "attachments" ? (
            <Col lg={1} className={classes.verticalCenter}>
              <Form.Group
                controlId="formRfqPrice"
                key={this.props.index}
                className={classes.formGroup}
              >
                <Form.Label className={classes.customLabel}>Unit</Form.Label>
                <Form.Control
                  className={
                    this.props.item.type == "product"
                      ? classes.labelreadContent
                      : classes.customInput
                  }
                  name="quantity_type"
                  value={
                    this.props.item.quantity_type || this.state.typeSwitch
                      ? this.props.item.quantity_type
                      : this.state.quantity_type
                  }
                  onChange={(e) =>
                    this.handleChangeProductQuantity(
                      e,
                      this.props.index,
                      this.props.item.type
                    )
                  }
                  disabled={this.props.item.type == "product"}
                />
              </Form.Group>
              <hr className={classes.customeSeprator} />
            </Col>
          ) : null}
          {this.props.item.type != "attachments" ? (
            <Col lg={1} className={classes.verticalCenter}>
              <Form.Group
                controlId="formRfqPrice"
                key={this.props.index}
                className={classes.formGroup}
              >
                <Form.Label className={classes.customLabel}>Price</Form.Label>
                <Form.Control
                  className={classes.labelreadContent}
                  name="price"
                  value={this.props.item.price}
                  onChange={this.handleChange}
                  disabled
                />
              </Form.Group>
              <hr className={classes.customeSeprator} />
            </Col>
          ) : null}
          <Col lg={1} className={classes.centerAlign}>
            <img
              className={classes.deleteIcon}
              src={DeleteIcon}
              alt="deleteIcon"
              id={this.props.index}
              onClick={() => this.props.removeItem(this.props.index)}
            />
            <div className={classes.removeIconWrapper}>
              <img
                className={classes.image}
                src={removeIcon}
                alt="removeIcon"
                id={this.props.index}
                onClick={() => this.props.removeItem(this.props.index)}
              />
            </div>
          </Col>
        </Row>
        <hr className={classes.dottedBorder} />
      </>
    );
  }
}

export default ProductAdd2;
