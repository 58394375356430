import React, { useState } from "react";
import { Form } from "react-bootstrap";
import crossSmall from "../../../asset/Images/crossSmall.svg";
import searchIcon from "../../../asset/Images/searchIcon.svg";

const ProfessionalMobile = (props) => {
  const {
    showB2B,
    businessGroup,
    checkedBusinessGroup,
    handleBusinessGroup,
    others,
    businessType,
    checkedBusinessType,
    openIndex,
    selectedOption,
    subBusinessType,
    business_type_other,
    selected_category_array,
    keyword,
    show_business_category,
    business_category_array,
    removeCategory,
    handletypeOther,
    getBusinessCategoryBySearch,
    selectOption,
    handleCategoryVisibility,
    handleSubBusinessType,
    selectedBusinessTypesRadio,
    handleBusinessType,
    handleNextStep,
    handleBackStep,
    childState,
  } = props;

  const [showProfessionalCard, setProfessionalCard] = useState(childState);

  const handleNextPage = () => {
    setProfessionalCard(showProfessionalCard + 1);
    if (showProfessionalCard + 1 === 3) {
      handleNextStep();
    }
  };

  const handlePreviousPage = () => {
    setProfessionalCard(showProfessionalCard - 1);
    if (showProfessionalCard - 1 === -1) {
      handleBackStep();
    }
  };
  return (
    <>
      {showProfessionalCard === 0 ? (
        <>
          <div className="customCard addAddressCardMobile">
            <div className="formstepCnt">
              <span className="steps">4 of 7</span>
            </div>
            <h3 className="customCardheading">Profession Details</h3>

            {/* sub_business_type list start here */}
            {!showB2B ? (
              <>
                <div>
                  <Form.Group
                    controlId="formGridCustomerName"
                    className="customGroup"
                  >
                    <Form.Label className="customLabel businessGrouplabel">
                      Business Group
                      <span class="mandatoryIcon">*</span>
                    </Form.Label>
                    {businessGroup &&
                      businessGroup.map((item, index) => {
                        return (
                          <div
                            className={`customCheckboxWrapper  customRadio
                            ${
                              item.id == checkedBusinessGroup ? "selected" : ""
                            }`}
                          >
                            <Form.Check
                              type="radio"
                              inline
                              key={item.id + index}
                              checked={item.id == checkedBusinessGroup}
                              id={item.id}
                              name="business_group"
                              label={item.name}
                              onChange={handleBusinessGroup}
                              required
                              labelClass
                            />
                          </div>
                        );
                      })}
                  </Form.Group>
                </div>
                {/* sub_business_type list start here */}
              </>
            ) : null}
          </div>
          <div className="addAccountFooterCntMobile">
            <button
              type="button"
              className="steeperPrevBtn"
              onClick={handlePreviousPage}
            >
              Previous
            </button>
            <button
              // type="submit"
              className="steeperNextBtn"
              onClick={handleNextPage}
            >
              Next
            </button>
          </div>
        </>
      ) : null}
      {showProfessionalCard === 1 ? (
        <>
          <div className="customCard addAddressCardMobile">
            <div className="formstepCnt">
              <span className="steps">5 of 7</span>
            </div>
            <h3 className="customCardheading">Profession Details</h3>
            <Form.Group
              controlId="formGridCustomerName"
              style={{ marginTop: "24px" }}
            >
              <Form.Label className="customLabel businessGrouplabel">
                Business Type
              </Form.Label>

              {others === false ? (
                businessType.map((item, index) => {
                  // Check if the current item is selected
                  const isChecked = checkedBusinessType.includes(item.id);

                  // Check if the current item is selected as radio
                  const isRadioSelected =
                    item.id == selectedBusinessTypesRadio;

                  // Track if the current element is selected based on its index
                  const isOpen = openIndex == index;

                  return (
                    <>
                      <div
                        className={`selectedBusinessType 
              ${
                checkedBusinessGroup === 2
                  ? `customRadio ${isRadioSelected ? "selected" : ""}`
                  : `customCheckbox ${isChecked ? "selected" : ""}`
              }`}
                        style={{ marginTop: "16px" }}
                      >
                        <Form.Check
                          type={
                            checkedBusinessGroup == 2 ? "radio" : "checkbox"
                          }
                          inline
                          onChange={(e) => handleBusinessType(e, index)} // Pass index to handle the change
                          key={item.id + index}
                          checked={
                            checkedBusinessGroup == 2
                              ? isRadioSelected
                              : isChecked
                          }
                          id={item.id}
                          name="business_type"
                          label={item.name}
                          required
                        />
                        {/* Render sub-business type if the group is radio and open */}
                        {checkedBusinessGroup == 2 && isOpen ? (
                          <div className="subBussinessTypeCnt">
                            <Form.Group
                              controlId="formGridCustomerName"
                              className="subBussinessTypeWrapper"
                            >
                              {subBusinessType.map((subItem, subIndex) => {
                                // Check if sub-item is selected
                                const subChecked = checkedBusinessType.includes(
                                  subItem.id
                                );
                                return (
                                  <div
                                    className={`subBussinessType customCheckbox ${
                                      subChecked ? "selected" : ""
                                    }`}
                                  >
                                    <Form.Check
                                      type="checkbox"
                                      inline
                                      onChange={(e) =>
                                        handleSubBusinessType(e, subItem.id)
                                      } // Handle sub-business type changes
                                      key={subItem.id + subIndex}
                                      checked={subChecked} // Control the checked state
                                      id={subItem.id}
                                      name="sub_business_type"
                                      label={subItem.name}
                                      required
                                    />
                                  </div>
                                );
                              })}
                            </Form.Group>
                          </div>
                        ) : null}
                      </div>
                      <hr className="customSepratorAdd" />
                    </>
                  );
                })
              ) : (
                <Form.Control
                  placeholder="Others"
                  name="business_type_other"
                  defaultValue={business_type_other} // Set default value for "others"
                  onChange={handletypeOther} // Handle change for "others" input
                  className="customInput"
                  required
                />
              )}
            </Form.Group>
          </div>
          <div className="addAccountFooterCntMobile">
            <button
              type="button"
              className="steeperPrevBtn"
              onClick={handlePreviousPage}
            >
              Previous
            </button>
            <button
              type="submit"
              className="steeperNextBtn"
              onClick={handleNextPage}
            >
              Next
            </button>
          </div>
        </>
      ) : null}
      {showProfessionalCard === 2 ? (
        <>
          <div className="customCard addAddressCardMobile">
            <div className="formstepCnt">
              <span className="steps">6 of 7</span>
            </div>
            <h3 className="customCardheading" style={{ marginBottom: "24px" }}>
              Profession Details
            </h3>
            <div>
              {selected_category_array.length > 0 ? (
                <div className="slectcategoriesCnt">
                  {selected_category_array.map((item, index) => {
                    return (
                      <div
                        key={index}
                        id={item.value}
                        className="slectcategoriesItem"
                      >
                        {item.label}
                        <img
                          src={crossSmall}
                          alt="cross icon"
                          width="14px"
                          height="14px"
                          className="newcrossIcon"
                          name={item.label}
                          id={item.value}
                          onClick={removeCategory}
                        />
                      </div>
                    );
                  })}
                </div>
              ) : null}
            </div>

            <div style={{ position: "relative" }}>
              <Form.Group
                controlId="formBasicEmail"
                style={{ marginTop: "12px" }}
              >
                <Form.Label className="customLabel businessGrouplabel">
                  Add Categories
                </Form.Label>
                <div className="customSelectCnt addCategoriesCnt">
                  <Form.Control
                    placeholder="Select"
                    name="keyword"
                    value={keyword}
                    onChange={getBusinessCategoryBySearch}
                    onFocus={handleCategoryVisibility}
                    className="customInput"
                    autoComplete="off"
                  />
                  <img
                    src={searchIcon}
                    className="searchIcon"
                    width="20px"
                    height="20px"
                  />
                </div>
              </Form.Group>
              {show_business_category ? (
                <div className="selectOptions">
                  {business_category_array.map((item, index) => {
                    return (
                      <>
                        <p
                          className="options"
                          onClick={selectOption}
                          key={index}
                          id={item.id}
                          title={item.name}
                        >
                          {item.name}
                        </p>
                        <hr
                          className="customSepratorAdd"
                          style={{ margin: "8px 0" }}
                        />
                      </>
                    );
                  })}
                </div>
              ) : null}
            </div>
          </div>
          <div className="addAccountFooterCntMobile">
            <button
              type="button"
              className="steeperPrevBtn"
              onClick={handlePreviousPage}
            >
              Previous
            </button>
            <button
              type="submit"
              className="steeperNextBtn"
              onClick={handleNextPage}
            >
              Next
            </button>
          </div>
        </>
      ) : null}
    </>
  );
};

export default ProfessionalMobile;
