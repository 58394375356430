import React from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import Homepage from "./Component/Homepage/Homepage";
import Login from "./Component/Login/login";
import Dashboard from "./Component/Dashboard/Dashboard";
import history from "./helper/history";
import PrivateRoute from "./helper/privateRoute";
import Getdistance from "./Component/Distance/getdistance";

const Routes = () => (
  <Router history={history}>
    <Switch>
      <Route path="/" exact component={Login} />
      <Route path="/login" component={Login} />
      <PrivateRoute path="/:page_type/:param1?" component={Dashboard} />
    </Switch>
  </Router>
);

export default Routes;
