import React from "react";
import { getUserDetails } from "../../Login/getLoginnedUserDetails";
import { Redirect } from "react-router-dom";
import {
  Row,
  Col,
  Form,
  Button,
  Alert,
  Spinner,
  ThemeProvider,
  Table,
} from "react-bootstrap";
import axios from "axios";
import AsyncSelect from "react-select/async";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Constants from "../../../Config";
import Creatable from "react-select/creatable";
import Select from "react-select";
import SelectSearch from "react-select-search";
import ProductList from "../Quotes/ProductList";
import AddItem from "../Quotes/AddItem";
import "../Common/headerStyle.css";
import CancelIcon from "../../../asset/Images/cancel icon.svg";
import ExcelIcon from "../../../asset/Images/XLSThumbnail.svg";
import DocsIcon from "../../../asset/Images/DOCSThumbnail.svg";
import PdfIcon from "../../../asset/Images/PDFhumbnail.svg";
import classes from "../SourcingRequest/CreateSource.module.css";

const apiUrl = Constants.API_URL;
// b2b Api Url
const b2bApiUrl = Constants.B2B_API_URL;
// GOOGLE API URL
const googleApiUrl = Constants.GOOGLE_API_KEY;
// const priorityArray = [

//   {label: "Select Priority", value: "0"},
//   {label: "Highest", value: "Highest"},
//   {label: "High", value: "High"},
//   {label: "Medium", value: "Medium"},
//   {label: "Low", value: "Low"},
// ]

const customStyles = {
  control: (provided, state) => ({
    ...provided,
    backgroundColor: "transparent",
    border: "1px solid #ced4da",
    fontSize: "12px",
    borderColor: state.isFocused ? "none" : "none",
    "&:hover": {
      borderColor: state.isFocused ? "none" : "none",
    },
    boxShadow: state.isFocused ? "none" : "none",
    minHeight: "35px",
    height: "calc(1.5em + .75rem + 2px);",
  }),

  placeholder: (provided) => ({
    ...provided,
    color: "#99ABBE",
    fontSize: "13px",
  }),
  singleValue: (provided) => ({
    ...provided,
    color: "#1E293B",
    fontSize: "13px",
  }),
  dropdownIndicator: (provided) => ({
    ...provided,
  }),
  indicatorSeparator: (provided) => ({
    ...provided,
  }),
};

class CreateRfq extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      customer_id: "",
      customer_name: "",
      customer_mobile: "",
      customer_email: "",
      displayProductArray: [],
      message: "",
      attachment: "",
      rfq_type: "",
      bussiness_type: "",
      rfq_priority: "",
      details: "",
      is_loading: false,
      is_image_loading: false,
      is_error: false,
      is_redirect: false,
      getAllProductsWithDetailForList: [],
      selectedFile: "",
      phone2: "",
      customer_idB: "",
      city_name: "",
      cityList: [],
      cityArray: [],
      city_id: "",
      rfq_products: [],
      campaign: "",
      showCategory: true,
      categObj: [],
      categSelect: false,
      subTotal: 0,
      // type: "product"
      businessGroup: [],
      checkedBusinessGroup: 0,
      checkedBusinessType: [],
      businessType: [],
      business_type_other: "",
      businessType_required: false,
      showBusinesstype: false,
      is_multiselect_search: false,
      others: false,
      validated: false,
      all_rfq_types: [],
      wrong_phone: false,
      mediumArray: [],
      utmSourceArray: [],
      utmSource: "",
      utmMedium: "",
      customerTypeArray: [],
      customer_type: "",
      accountCompany: "",
      accountCompanyAgentName: "",
      customer_does_not_email: false,
      pincode: "",
      wrong_pincode: false,
      location: "",
      location_id: "",
      locationList: [],
      uploadedFiles: [],
      fileNames: [],
      searchAccount: "",
      accountSearchList: [],
      location: "",
      locationList: [],
      postalCode: "",
      validated: false,
      googleData: [],
      getRfqId: "",
      rfqIdResponse: "",
    };
    this.debouncedFetchLocationOptions = this.debounce(
      this.handleLocation,
      300
    ).bind(this);
  }

  componentDidMount() {
    this.getBusinessGroup();
    this.getRfqTypes();
    this.handleCustomerType();
    this.getUtmSource();
    this.getUtmMedium();
  }

  handleChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };

  updateProducts = (name, value) => {
    this.setState({
      [name]: value,
    });
  };

  debounce = (func, delay) => {
    let timeoutId;
    return (...args) => {
      if (timeoutId) {
        clearTimeout(timeoutId);
      }
      timeoutId = setTimeout(() => {
        func(...args);
      }, delay);
    };
  };

  // handle bar function customer does not have email start here
  handleBarCustomerEmailCheckbox = () => {
    this.setState((prevState) => ({
      customer_does_not_email: !prevState.customer_does_not_email,
    }));
  };
  // handle bar function customer does not have email ends here

  // pincode function start here
  handlePinCode = (e) => {
    this.setState(
      {
        pincode: e.target.value,
      },
      () => {
        if ([6, 0].includes(this.state.pincode.length)) {
          const requestoption = {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${this.props.getUserDetails.token}`,
            },
            data: {
              pincode: this.state.pincode,
            },
          };
          return axios(b2bApiUrl + "/search-by-pincode", requestoption)
            .then((data) => {
              this.setState({
                location: `${data.data.data[0].city},${data.data.data[0].state}`,
                location_id: data.data.data[0].city_id,
                wrong_pincode: false,
              });
            })
            .catch((err) => {
              console.log(err);
              this.setState({
                wrong_pincode: false,
              });
            });
        } else {
          this.setState({
            wrong_pincode: true,
            location: "",
          });
        }
      }
    );
  };
  // pincode function ends here

  validateFile = (file) => {
    var allowedExtension = [
      "image/jpeg",
      "image/jpg",
      "image/png",
      "application/pdf",
      "application/vnd.ms-excel",
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      "application/msword",
      "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
      "text/plain",
    ];
    if (file) {
      if (!allowedExtension.includes(file.type)) {
        alert("only allowed extension jpeg,jpg,png,pdf,excel file");
        return false;
      }
      if (file.size > 5000000) {
        alert("Please attached file less than 5 mb");
        return false;
      }
      return true;
    } else {
      alert("you did not attached file");
    }
  };

  getFileType = (filename) => {
    const fileExtension = filename.split(".").pop().toLowerCase();
    switch (fileExtension) {
      case "pdf":
        return "pdf";
      case "jpeg":
      case "jpg":
      case "png":
        return "image";
      case "doc":
      case "docx":
      case "docm":
      case "dotx":
      case "dotm":
      case "rtf":
      case "txt":
      case "odt":
      case "xml":
      case "dot":
        return "doc";
      case "xls":
      case "xlsx":
      case "xlsm":
      case "xlsb":
      case "xltx":
      case "xltm":
        return "xls";
      default:
        return "unknown";
    }
  };

  onFileChange = async (event) => {
    // Update the state
    const file = event.target.files[0];
    var allowedimage = this.validateFile(file);
    if (!allowedimage) {
      this.setState({ is_image_loading: false });
      return;
    }
    const fileType = this.getFileType(file.name);
    // if(allowedimage){
    // const base64 = await this.convertBase64(file)
    //    console.log("base64",file)
    //    console.log("filename",file.name)

    //    this.setState({
    //     //    selectedFile: base64
    //     selectedFile: file
    //     });

    // 'Content-Type': 'multipart/form-data'
    const { selectedFile } = this.state;
    let formData = new FormData();
    formData.append("file", event.target.files[0]);
    const totalFilesCount = this.state.uploadedFiles.length;
    if (totalFilesCount == 3) {
      alert("You can upload a maximum of three images");
      return;
    }
    this.setState({
      is_image_loading: true,
    });
    axios
      .post(apiUrl + "/rfq-attachment", formData, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${this.props.getUserDetails.token}`,
        },
      })
      .then((res) => {
        const imageUrl = res.data.data.image_url;
        this.setState({
          uploadedFiles: [
            ...this.state.uploadedFiles,
            {
              url: imageUrl,
              path: imageUrl,
              type: fileType,
            },
          ],
          fileNames: [...this.state.fileNames, file.name],
        });
        this.setState({
          selectedFile: res.data.data.image_url,
          is_image_loading: false,
        });
      });

    // axios.post( apiUrl + '/rfq-attachment',data)
    //     .then(res =>{
    // console.log('rfqresponse',res)
    // this.setState({
    //     message:'image attached!',
    //     is_error:false,
    //     is_loading : false,
    //     customer_mobile:''
    // },()=>{
    //     setTimeout(() => {
    //         this.setState({
    //             message:'',
    //             is_error:false,
    //             is_redirect:true
    //         })
    //     }, 3000);
    // })
    // })
    // .catch(error=>{
    //     this.setState({
    //         message:error.response.data.message,
    //         is_error:true,
    //         is_loading : false
    //     },()=>{
    //         setTimeout(() => {
    //             this.setState({
    //                 message : '',
    //                 is_error:false
    //             })
    //         }, 5000);
    //     })
    // })
    // }
  };
  deleteAttachment = (fileUrl, event) => {
    if (event) event.preventDefault();
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${this.props.getUserDetails.token}`,
      },
      data: { name: fileUrl.split("/").at(-1) },
    };
    axios(apiUrl + "/remove-attachment", requestOptions)
      .then((res) => {
        this.setState((prevState) => ({
          uploadedFiles: prevState.uploadedFiles.filter(
            (file) => file.url !== fileUrl
          ),
          fileNames: prevState.fileNames.filter(
            (fileName) =>
              fileName !==
              prevState.uploadedFiles.find((file) => file.url === fileUrl).name
          ),
        }));
      })
      .catch((error) => {
        console.error("Error deleting file:", error);
      });
  };
  convertBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      if (file) {
        fileReader.readAsDataURL(file);
      }
      // fileReader.readAsDataURL(file)
      fileReader.onload = () => {
        resolve(fileReader.result);
      };
      fileReader.onerror = (error) => {
        reject(error);
      };
    });
  };
  categoryObject = (object) => {
    // let object1 = object
    this.setState({
      categObj: object,
      categSelect: true,
    });
  };
  displayAllProducts = (e) => {

    // let listedProductsArray = e;
    this.setState(
      {
        getAllProductsWithDetailForList:
          this.state.getAllProductsWithDetailForList.concat(e),
      },
      () => {
        let allItems = null;

        console.log(
          this.state.getAllProductsWithDetailForList,
          "ItemArray before filtering"
        );
        allItems = this.state.getAllProductsWithDetailForList;
        const getLastElement = (name, allItems) => {
          const objUniq = allItems.reduce(
            (res, item) => ({ ...res, [item[name]]: item }),
            {}
          );
          return Object.keys(objUniq).map((item) => objUniq[item]);
        };
        const uniqAddress = getLastElement("name", allItems);
        const finalArray = uniqAddress.filter((item) => item.name != "");
        this.setState({
          getAllProductsWithDetailForList: finalArray,
        });
      }
    );

    // let products = [];

    // listedProductsArray.map((item) => {
    //   return products.push(item.product_id);
    // }) ;

    // const requestoption = {
    //   method: "POST",
    //   headers: {
    //     "Content-Type": "application/json",
    //     Authorization: `Bearer ${this.props.getUserDetails.token}`,
    //   },

    //   data: {
    //     product_id: products? products: 0 ,
    //     customer_id: this.state.customer_id,
    //     // customer_id: this.state.customer_id? this.state.customer_id: this.state.customer_idB
    //   },
    // };
    // return axios(apiUrl + "/get-product-detail-by-id", requestoption)
    //   .then((data) => {
    //     let products = data.data.data;
    //     let productsArray = [];
    //     let subTotal = 0;
    //     let shippingRate = 0;
    //     for (let i = 0; i < products.length; i++) {
    //       let index = listedProductsArray.filter(
    //         (el) => el.product_id == products[i].product_id
    //       )[0];
    //       let listPrice = products[i].list_price;
    //       let listPriceFinal = Number(listPrice).toFixed(2);
    //       let netAmount = listPriceFinal * index.quantity;
    //       let netAmountOrg = listPriceFinal * index.quantity;
    //       let netAmountFinal = Number(netAmount).toFixed(2);
    //       let netAmountOrgFinal = Number(netAmountOrg).toFixed(2);
    //       let tax_in_amount = (netAmount * products[i].tax) / 100;
    //       let tax_in_amount_final = parseFloat(tax_in_amount.toFixed(2));
    //       let total =
    //         parseFloat(tax_in_amount_final) + parseFloat(netAmountFinal);

    //       let tpInToQuantity = products[i].transfer_price * index.quantity;
    //       let tpInToQuantityR =
    //         (netAmountFinal - tpInToQuantity) / netAmountFinal;

    //       let grossMargin = parseFloat((tpInToQuantityR * 100).toFixed(2));
    //       subTotal = subTotal + total;

    //       productsArray.push({
    //         product_id: products[i].product_id,
    //         name: products[i].name,
    //         tax: products[i].tax,
    //         mrp: products[i].price,
    //         model: products[i].model,
    //         transfer_price: products[i].transfer_price,
    //         available_stock: products[i].available_stock,
    //         markup: products[i].markup,
    //         quantity: index.quantity,
    //         list_price: listPriceFinal,
    //         list_price_org: listPriceFinal,
    //         net_amount: netAmountFinal,
    //         net_amount_org: netAmountOrgFinal,
    //         tax_in_percentage: products[i].tax,
    //         tax_in_amount: tax_in_amount_final,
    //         total: Number(total).toFixed(2),
    //         pack_id: "0",
    //         gross_margin: grossMargin,
    //         type: 'products'
    //       });
    //     }

    //     console.log("product", productsArray)
    //     if (subTotal < 500) {
    //       shippingRate = 40;
    //     }
    //     this.setState({
    //       getAllProductsWithDetailForList: productsArray,
    //       subTotal: parseFloat(subTotal.toFixed(2)),
    //       shippingCharge: parseFloat(shippingRate.toFixed(2)),
    //     });
    //     this.setState({
    //       grandTotal: Number(
    //         this.state.subTotal + this.state.shippingCharge
    //       ).toFixed(2),
    //     });
    //   })
    //   .catch((error) => console.log(error));
  };
  getBusinessGroup = () => {
    this.setState(
      {
        isLoading: true,
      },
      () => {
        const options = {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${this.props.getUserDetails.token}`,
          },

          // data:{id:this.props.businessData.id}
          data: { id: 2105 },
        };
        return axios(apiUrl + "/get-business-group", options)
          .then((data) => {
            this.setState({
              businessGroup: data.data.data,
              isLoading: false,
            });
          })
          .catch((error) => console.log(error));
      }
    );
  };
  getUtmSource = () => {
    const options = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${this.props.getUserDetails.token}`,
      },
      data: {
        meta_id: 28,
      },
    };
    return axios(apiUrl + "/meta-value", options)
      .then((data) => {
        let utmSourceArray = data.data.data;
        this.setState({
          utmSourceArray,
        });
      })
      .catch((err) => console.log(err));
  };
  getUtmMedium = () => {
    const options = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${this.props.getUserDetails.token}`,
      },
      data: {
        meta_id: 29,
      },
    };
    return axios(apiUrl + "/meta-value", options)
      .then((data) => {
        let mediumArray = data.data.data;
        this.setState({
          mediumArray,
        });
      })
      .catch((err) => console.log(err));
  };
  handleCustomerType = (e) => {
    let loginedUserDetails = JSON.parse(localStorage.getItem("userData"));
    const token = loginedUserDetails.token;
    axios
      .post(
        apiUrl + "/meta-value",
        {
          meta_id: 4,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      )
      .then((data) => {
        this.setState({
          customerTypeArray: data.data.data,
        });
      })
      .catch((err) => console.log(err));
  };
  getRfqTypes = () => {
    const options = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${this.props.getUserDetails.token}`,
      },
      data: {
        meta_id: 23,
      },
    };
    return axios(apiUrl + "/meta-value", options)
      .then((data) => {
        let rfqTypes = [
          "Pro Pricing Request",

          "Sales Inbound Call",

          "Sales Outbound Call",

          "Sales Whatsapp",

          "Sales message",

          "Sales E-mail",

          "Sales Reference",

          "Non transacted Pro",
          "Other",
        ];
        this.setState({
          all_rfq_types: rfqTypes,
        });
      })
      .catch((error) => console.log(error));
  };
  handleBusinessGroup = (e) => {
    this.setState({
      showBusinesstype: true,
      checkedBusinessGroup: e.target.id,
      businessType_required: true,
    });
    // this.props.business_group2(e.target.id);
    let id = e.target.id;
    if (id == 100) {
      this.setState({
        others: true,
        checkedBusinessGroup: id,
        checkedBusinessType: [],
        is_multiselect_search: false,
        // required_state: false,
        agentid: "0",
      });
      // this.props.agent2(this.state.agentid));
      // this.props.business_group2(id)
    } else {
      if (id != 2) {
        this.setState({
          is_multiselect_search: false,
        });
      } else {
        this.setState({
          is_multiselect_search: true,
        });
      }
      this.setState(
        {
          others: false,
          checkedBusinessType: [],
          business_type_other: "",
        },
        () => {
          this.setState(
            {
              checkedBusinessGroup: id,
            },
            () => {
              let selectedBusinessGroupID = this.state.businessGroup.findIndex(
                (item) => {
                  return item.id == this.state.checkedBusinessGroup;
                }
              );
              // this.props.business_group2(selectedBusinessGroupID)
              let selectedBusinessType =
                this.state.businessGroup[selectedBusinessGroupID].business_type;
              this.setState({
                businessType: selectedBusinessType,
              });
              // this.props.business_type2(selectedBusinessType)
            }
          );
        }
      );
    }
  };
  handleBusinessType = (e) => {
    this.setState({
      businessType_required: false,
    });
    let selectedArray = this.state.checkedBusinessType;
    if (e.target.checked) {
      selectedArray.push(parseInt(e.target.id));
      this.setState({
        checkedBusinessType: selectedArray,
      });
      // this.props.business_type2(selectedArray);
    } else {
      let filteredArray = selectedArray.filter((item) => {
        return item != e.target.id;
      });
      if (filteredArray.length == 0) {
        this.setState({ businessType_required: true });
      }
      this.setState({
        checkedBusinessType: filteredArray,
      });
      // this.props.business_type2(filteredArray);
    }
  };
  handletypeOther = (e) => {
    this.setState({
      business_type_other: e.target.value,
    });
  };


  handleSubmit = (e) => {
    e.preventDefault();
    const submitType = e.target.id;
    this.setState({
      getRfqId: submitType,
      is_loading: true,
      validated: true,
    });
  
    const loginedUserDetails = JSON.parse(localStorage.getItem("userData"));
    const token = loginedUserDetails.token;
    const { 
      customer_id, customer_name, customer_email, customer_mobile, details, 
      getAllProductsWithDetailForList, uploadedFiles, total, subTotal, 
      shippingCharge, selectedFile, campaign, location, location_id, 
      pincode, customer_type, customer_does_not_email, searchAccount 
    } = this.state;
  
    // Remove price property from each product
    const productsWithoutPrice = getAllProductsWithDetailForList.map(({ price,details, ...rest }) => rest);
    const rfq_params = {
      customer_mobile: Number(customer_mobile),
      customer_email,
      rfq_type: "Sales Outbound Call",
      subTotal: Number(subTotal),
      source: "CRM",
      image_data: selectedFile,
      attachments: JSON.stringify(uploadedFiles),
      created_by: loginedUserDetails.user_id,
      campaign,
      utmSource: "sales",
      utmMedium: "outbound",
      isCustomerEmail: customer_does_not_email,
      shippingCharge,
      total,
    }
    const body_params = {
      customer_id,
      customer_name,
      products: [...productsWithoutPrice, ...uploadedFiles],
      location,
      location_id: location_id.toString(),
      pincode,
      role: customer_type,
      lead_id: 0,
    };
    axios.post(apiUrl + "/create-rfq", {...body_params,...rfq_params}, {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    })
    .then((res) => {
      let customerId = res.data.data.customer_id
      let RfqId = res.data.data.rfqId
      const sr_params = {
        mobile: customer_mobile,
        email:  customer_email,
        utm_source: "sales",
        utm_medium: "outbound",
        utm_campaign: campaign,
        utm_content: "",
        sales_remarks: "",
        account_name: searchAccount,
        customer_id:customerId,
        rfq_id: RfqId
      }
      if (submitType === "createRfqSr") {
        body_params.rfq_id = res.data.data.rfqId && body_params.customer_id == res.data.data.customer_id
        axios.post(apiUrl + "/create-source-request", {...body_params,...sr_params}, {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        })
        .then(() => {
          this.setState({
            message: "RFQ & SR Created Successfully!",
            is_error: false,
            is_loading: false,
            customer_mobile: "",
            rfqIdResponse: body_params.rfq_id,
            customer_id: customerId,
          }, () => {
            setTimeout(() => {
              this.setState({
                message: "",
                is_error: false,
                is_redirect: true,
              });
            }, 3000);
          });
        })
        .catch((error) => {
          this.setState({
            message: error.response.data.message,
            is_error: true,
            is_loading: false,
          }, () => {
            setTimeout(() => {
              this.setState({
                message: "",
                is_error: false,
              });
            }, 4000);
          });
        });
      } else {
        this.setState({
          message: "RFQ Created Successfully!",
          is_error: false,
          is_loading: false,
          customer_mobile: "",
          rfqIdResponse: res.data.data.rfqId,
          customer_id: res.data.data.customer_id,
        }, () => {
          // const srDetails = {
          //   requestQuoteID: this.state.rfqIdResponse,
          //   timestamp: new Date().getTime(),
          // };
          // localStorage.removeItem("SR_Details");
          // localStorage.setItem("SR_Details", JSON.stringify(srDetails));
  
          setTimeout(() => {
            this.setState({
              message: "",
              is_error: false,
              is_redirect: true,
            });
          }, 3000);
        });
      }
    })
    .catch((error) => {
      this.setState({
        message: error.response.data.message,
        is_error: true,
        is_loading: false,
      }, () => {
        setTimeout(() => {
          this.setState({
            message: "",
            is_error: false,
          });
        }, 4000);
      });
    });
  };
    
  gettingPrimaryAccountDetails(e) {
    axios({
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${this.props.getUserDetails.token}`,
      },

      url: apiUrl + "/get-primary-account",
      data: { phone: e.target.value },
    })
      .then((data) => {
        if (data.data.data.length > 0) {
          this.setState({
            accountCompany: data.data.data[0].accountName,
            accountCompanyAgentName: data.data.data[0].accountAgentName,
          });
        }
      })
      .catch((err) => {
        console.log(err);
        this.setState({
          accountCompany: "",
          accountCompanyAgentName: "",
        });
      });
  }

  getPhoneValue = (e) => {
    e.persist();
    if (e.target.value.length == 10) {
      this.gettingPrimaryAccountDetails(e); // api calling
      this.setState({
        phone2: e.target.value,
        customer_mobile: e.target.value,
        wrong_phone: false,
      });

      axios({
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${this.props.getUserDetails.token}`,
        },

        url: apiUrl + "/get-customer-detail-by-phone",
        data: { phone: e.target.value },
      })
        .then((data) => {
          if (data.data.data[0].customerName) {
            this.setState({
              existing_name: true,
            });
          }
          if (data.data.data[0].email) {
            this.setState({
              existing_email: true,
            });
          }
          if (data.data.data[0]) {
            this.setState({
              wrong_phone: false,
              // customer_name: data.data.data[0].name,
              customer_name: data.data.data[0].customerName,
              customer_email: data.data.data[0].email,
              customer_type: data.data.data[0].role,
              customer_id: data.data.data[0].customer_id,
              customer_mobile: data.data.data[0].telephone,
              customerCompany: data.data.data[0].customerCompany,
              agentName: data.data.data[0].agentName,
              agentID: data.data.data[0].agent_id,
            });
          } else {
            this.setState({
              customer_mobile: e.target.value,
            });
          }
        })

        .catch(
          (error) => {
            console.log("error", error);
            this.setState({
              customerCompany: "",
              agentName: "",
              // wrong_phone: true,
              customer_id: 0,
              customer_name: "",
              customer_email: "",
              customer_type: "",
              existing_name: false,
              existing_email: false,
              agentID: "",
              searchAccount: "", // empty state in case of search phone number
            });
            // ,typeof (this.state.customer_id)
            // if(this.state.customer_id===''){
            //     console.log("Error")
            //     this.getPhoneValueB2C()
            //   }
          }
          // this.setState({
          //         message: data.data.data[0].customerName,
          // })
        );
    } else {
      this.setState({
        customerCompany: "",
        agentName: "",
        wrong_phone: true,
        customer_id: 0,
        customer_name: "",
        customer_email: "",
        customer_type: "",
        existing_name: false,
        existing_email: false,
        agentID: "",
        searchAccount: "", // empty state in case of search phone number
        // is_error: true
      });
    }
  };

  getPhoneValueB2C() {
    axios({
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${this.props.getUserDetails.token}`,
      },

      url: apiUrl + "/get-customer-detail-by-phone",
      data: {
        phone: this.state.phone2,
        customer_name: this.state.customer_name,
        customer_email: this.state.customer_email,
      },
    })
      .then((data) => {
        this.setState({
          customer_idB2c: data.data.data[0].customer_id,
        });
      })
      .catch((error) => {
        console.log(error);
        this.setState({ customer_id: 0 });
      });
  }

  customer_idB2 = (value) => {
    this.setState({
      customer_id: value,
    });
    //   this.displayAllProducts(e)
  };
  customerMobile = (value) => {
    this.setState({
      customer_mobile: value,
    });
  };
  handleRFQTypeChange = (e) => {
    const type = e.target.value;
    this.setState({ rfq_type: type });
    if (
      [
        "Sales Outbound Call",
        "Sales Inbound Call",
        "Sales Whatsapp",
        "Sales message",
        "Sales E-mail",
        "Sales Reference",
      ].includes(type)
    ) {
      this.setState({ utmSource: "sales" });
    } else {
      this.setState({
        [e.target.name]: e.target.value,
        utmSource: "", // empty state when user select any type value
      });
    }
  };
  handleNoAccountSelect = (e) => {
    this.setState({
      searchAccount: e.target.value,
    });
  };

  handleAccountSearch = async (e, callback) => {
    if (!e) {
      return callback([]);
    }
    const config = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${this.props.getUserDetails.token}`,
      },
      data: {
        keyword: e,
      },
    };
    return await axios(apiUrl + "/get-suggested-Account", config)
      .then((data) => {
        this.setState(
          {
            accountSearchList: data.data.data,
          },
          () => {
            if (this.state.accountSearchList.length == 0) {
              this.setState({
                // location_id: 0,
              });
            }
          }
        );
        callback(
          data.data.data.map((i) => ({
            // label: `${i.company_name},  ${i.city_name},  ${i.name}`,
            label: (
              <p>
                <strong>{i.company_name}</strong>,
                <span className="m-1">{i.city_name}</span>,
                <span className="m-1">{i.name}</span>
              </p>
            ),
            value: `${i.company_name},${i.city_name},${i.name}`,
          }))
        );
      })
      .catch((err) => {
        console.log(err.response);
      });
  };
  getSearchAccount = (selectedOption) => {
    // let account = this.state.accountSearchList.filter(
    //   (el) => `${el.company_name},${el.city_name},${el.name}` == e.value
    // )[0];
    const account = this.state.accountSearchList.find(
      (el) => `${el.company_name},${el.city_name},${el.name}` === selectedOption.value
    );
    // console.log(account, "search account filter");
    this.setState({
      // set state here
      // name, email, mobile, serach account
      searchAccount: `${account.company_name ?? ""}`,
      customer_mobile: `${account.telephone ?? ""}`,
      customer_name: `${account.name ?? ""}`,
      customer_email: `${account.email ?? ""}`,
      customer_id: `${account.customer_id}`,
      customer_type: `${account.role}`,
    });
    // console.log(account, "ACCOUNT DATA");
  };
  handleNoSelect = (e) => {
    this.setState({
      location: e.target.value,
    });
  };
  handleLocation1 = async (e, callback) => {
    const config = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${this.props.getUserDetails.token}`,
      },
      data: {
        keyword: e,
      },
    };
    // return await axios(apiUrl + "/get-suggested-cities", config)
    return await axios(apiUrl + "/get-suggested-cities", config)
      .then((data) => {
        this.setState(
          {
            locationList: data.data.data,

            // locationArray: data.data.data.map((item) => {
            //   return {
            //     name: `${item.name},${item.state_name}`,
            //     value: `${item.name},${item.state_name}`,
            //   };
            // }),
          },
          () => {
            if (this.state.locationList.length == 0) {
              this.setState({
                location_id: 0,
              });
            }
          }
        );
        callback(
          data.data.data.map((i) => ({
            label: `${i.name},${i.state_name}`,
            value: `${i.name},${i.state_name}`,
          }))
        );
      })
      .catch((err) => console.log(err.response));
  };

  handleLocation = async (e, callback) => {
    const config = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${this.props.getUserDetails.token}`,
      },
      data: {
        query: e + " in India",
        key: "AIzaSyB7qkySF1gvl0jQ3Gfz3ju7wzxxp5SWVmc",
        components: "country:in",
      },
    };

    try {
      const response = await axios(apiUrl + "/google-text", config);
      const data = response.data.data;

      if (data.status === "OK") {
        const location = data.results[0].geometry.location;

        this.setState(
          {
            locationList: data.results,
          },
          () => {
            if (this.state.locationList.length === 0) {
              this.setState({
                location_id: 0,
              });
            }
          }
        );

        // another request with the lat and lng
        const geocodeConfig = {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${this.props.getUserDetails.token}`,
          },
          data: {
            latlng: `${location.lat},${location.lng}`,
            sensor: "true",
            key: "AIzaSyB7qkySF1gvl0jQ3Gfz3ju7wzxxp5SWVmc",
            regions: "locality",
          },
        };

        try {
          const geocodeResponse = await axios(
            apiUrl + "/google-lat-long",
            geocodeConfig
          );
          const geocodeData = geocodeResponse.data;
          let dataRes = [];
          // for (let each of geocodeData.data.results) {
          //   // let data = each.address_components.filter((value) =>
          //   //   value.types.includes("postal_code")
          //   // );
          //   let data = each.address_components.map((value) => value);
          //   if (data.length) {
          //     let obj = {
          //       addr: each.formatted_address,
          //       // pincode: data[0].long_name,
          //       pincode:
          //         data.find((comp) => comp.types.includes("postal_code"))
          //           ?.long_name || "",
          //     };
          //     dataRes = [...dataRes, obj];
          //   }
          // }
        const uniqueAddresses = new Set();

        for (let each of geocodeData.data.results) {
          let data = each.address_components.map((value) => value);
          if (data.length) {
            let formattedAddress = each.formatted_address;
            if (!uniqueAddresses.has(formattedAddress)) {
              uniqueAddresses.add(formattedAddress);
              let obj = {
                addr: formattedAddress,
                pincode:
                  data.find((comp) => comp.types.includes("postal_code"))
                    ?.long_name || "",
              };
              dataRes = [...dataRes, obj];
            }
          }
        }
          callback(
            dataRes.map((result) => ({
              label: result.addr,
              value: result.addr,
            }))
          );
          this.setState({
            googleData: dataRes,
          });
          // Handle the geocode data here
        } catch (geocodeError) {
          console.error("Geocode API error:");
        }
      } else {
        console.error("Geocode API error:");
        callback([]);
      }
    } catch (err) {
      console.error("Error:", err);
      callback([]);
    }
  };

  // debouncedFetchLocationOptions = React.useCallback(this.debounce(this.handleLocation, 300), []);

  getCities = (e) => {
    let city = this.state.googleData.filter((el) => `${el.addr}` == e.value)[0];
    // console.log(city, "acte::::::::", this.state.googleData, "googleData list");
    this.setState({
      location: `${city.addr}`,
      pincode: city.pincode ? city.pincode : "",
    });
  };

  getCity = (e) => {
    const config = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${this.props.getUserDetails.token}`,
      },
      data: {
        keyword: e,
      },
    };
    return axios(apiUrl + "/get-suggested-cities", config)
      .then((data) => {
        this.setState({
          cityList: data.data.data,
          cityArray: data.data.data.map((item) => {
            return {
              name: `${item.name},${item.state_name}`,
              value: `${item.name},${item.state_name}`,
            };
          }),
        });
      })
      .catch((err) => console.log(err.response));
  };

  // removeItemFromMainList = (e) => {
  //   let id = e;
  //   let products = this.state.getAllProductsWithDetailForList;
  //   products.splice(id, 1);

  //   let productsArray = [];
  //   let subTotal = 0;
  //   let taxTotal = 0;
  //   let shippingRate = 0;
  //   for (let i = 0; i < products.length; i++) {
  //     let subTotal1 = parseFloat(products[i].total);
  //     subTotal = subTotal + subTotal1;
  //     let taxTotal1 = parseFloat(products[i].tax_in_amount);
  //     taxTotal = taxTotal + taxTotal1;

  //     productsArray.push({
  //       product_id: products[i].product_id,
  //       name: products[i].name,
  //       tax: products[i].tax,
  //       mrp: products[i].mrp,
  //       model: products[i].model,
  //       transfer_price: products[i].transfer_price,
  //       markup: products[i].markup,
  //       quantity: products[i].quantity,
  //       list_price: products[i].list_price,
  //       available_stock: products[i].available_stock,
  //       list_price_org: products[i].list_price_org,
  //       net_amount: products[i].net_amount,
  //       net_amount_org: products[i].net_amount_org,
  //       tax_in_percentage: products[i].tax_in_amount,
  //       tax_in_amount: products[i].tax_in_amount,
  //       total: products[i].total,
  //       gross_margin: products[i].gross_margin,
  //       price: products[i].price,
  //     });
  //   }
  //   if (subTotal < 500) {
  //     shippingRate = 40;
  //   }
  //   let grandTotalPrice = parseFloat(subTotal) + parseFloat(shippingRate);
  //   this.setState({
  //     getAllProductsWithDetailForList: productsArray,
  //     subTotal: parseFloat(subTotal.toFixed(2)),
  //     shippingCharge: parseFloat(shippingRate.toFixed(2)),
  //     grandTotal: Number(grandTotalPrice).toFixed(2),
  //   });
  // };
  removeItemFromMainList = (e) => {
    let id = e;
    let products = this.state.getAllProductsWithDetailForList;
    products.splice(id, 1);
    this.setState({
      getAllProductsWithDetailForList: products,
      itemsRemoved: true,
    });
  };

  render() {
    const {
      customer_name,
      customer_mobile,
      product,
      price,
      is_redirect,
      customer_email,
    } = this.state;
    // if(this.state.customer_id>0){
    //     return true
    // }
    // else {
    //     this.getPhoneValueB2C()
    // }
    if (this.state.is_redirect && this.state.getRfqId === "createRfq") {
      return <Redirect to="/quote-requests" />;
    } else if (
      this.state.is_redirect &&
      this.state.getRfqId === "createRfqSr" &&
      this.state.rfqIdResponse
    ) {
      return <Redirect to="/quote-requests" />;
    }
    return (
      <div>
        {this.state.message.length > 0 ? (
          <Alert
            className="displayMessageArea"
            variant={this.state.is_error ? "danger" : "success"}
          >
            {this.state.message}
          </Alert>
        ) : null}
        <Form
          noValidate
          validated={this.state.validated}
          // onSubmit={this.handleSubmit}
          encType="multipart/form-data"
        >
          <div className={`${classes.requirement_detail_wrapper} mt-0`}>
            <div className="d-flex">
              <h3>ADD RFQ Information</h3>
              {this.state.agentName ? (
                <div style={{ flex: "1", textAlign: "center" }}>
                  Company name: <b>{this.state.accountCompany || ""}</b>
                  <br />
                  Agent Name: <b>{this.state.accountCompanyAgentName || ""}</b>
                </div>
              ) : null}
            </div>
            <hr className={classes.dottedBorder} />
            <div className="d-flex align-items-center justify-content-center mb-3">
              <h3
                className="mb-0 customer_searching"
                style={{
                  fontWeight: "normal",
                  borderBottom: "2px solid #ced4da",
                  display: "inline",
                }}
              >
                Add customer by searching account name or mobile number
              </h3>
            </div>
            <Row className="padding-mobile-view">
              {/* Search by account name component start here */}
              <Col md="3">
                <div className="customeAutoComplete px-0">
                  <Form.Group controlId="formCity" className="mb-3">
                    <div
                      className="customeAutoComplete"
                      style={{ padding: "initial" }}
                    >
                      <Form.Group controlId="formCity">
                        <Form.Label>
                          <strong>Search Account</strong>
                        </Form.Label>
                        {this.state.searchAccount.length == 0 ? (
                          <AsyncSelect
                            // options={this.state.cityArray}
                            styles={customStyles}
                            // styles={{
                            //   control: (baseStyles, state) => ({
                            //     ...baseStyles,
                            //     borderColor:
                            //       this.state.searchAccount ||
                            //         !this.state.validated
                            //         ? "grey"
                            //         : "red",
                            //   }),
                            // }}
                            value={this.state.searchAccount}
                            onChange={this.getSearchAccount}
                            onBlur={(e) => this.handleNoAccountSelect(e)}
                            loadOptions={this.handleAccountSearch}
                            placeholder="Search account"
                            onKeyPress={(e) => {
                              e.key === "Enter" && e.preventDefault();
                            }}
                          />
                        ) : (
                          <Form.Control
                            type="text"
                            name="searchAccount"
                            placeholder="Search account"
                            onChange={this.handleChange}
                            value={this.state.searchAccount}
                            onKeyDown={(e) => {
                              e.key === "Enter" && e.preventDefault();
                            }}
                          />
                        )}
                      </Form.Group>
                    </div>
                  </Form.Group>
                </div>
              </Col>
              {/* Search by account name component ends here */}
              <Col md="3">
                <Form.Group controlId="formGridCustomerMobile">
                  <Form.Label>
                    <b>Customer Mobile *</b>
                  </Form.Label>
                  <Form.Control
                    name="customer_id"
                    type="hidden"
                    onChange={this.handleChange}
                    value={this.state.customer_id}
                  />
                  <Form.Control
                    className={this.state.wrong_phone ? "error" : ""}
                    name="customer_mobile"
                    type="number"
                    min={0}
                    placeholder="Enter Mobile No."
                    onChange={this.getPhoneValue}
                    defaultValue={this.state.customer_mobile}
                    required
                    disabled={this.state.searchAccount}
                  />
                </Form.Group>
              </Col>
              <Col md="3">
                <div>
                  <Form.Group controlId="formGridCustomerName">
                    <Form.Label>
                      <b>Customer Name *</b>
                    </Form.Label>
                    <Form.Control
                      name="customer_name"
                      type="text"
                      placeholder="Enter Customer Name"
                      onChange={this.handleChange}
                      value={this.state.customer_name}
                      required
                      disabled={
                        this.state.existing_name || this.state.searchAccount
                      }
                      onKeyPress={(e) => {
                        e.key === "Enter" && e.preventDefault();
                      }}
                    />
                  </Form.Group>
                </div>
              </Col>
              <Col md="3">
                <div>
                  <Form.Group controlId="formGridCustomerName">
                    <Form.Label>
                      <b>Customer Email </b>
                    </Form.Label>
                    <Form.Control
                      name="customer_email"
                      type="email"
                      placeholder="Enter Customer Email"
                      onChange={this.handleChange}
                      value={this.state.customer_email}
                      disabled={
                        this.state.existing_email || this.state.searchAccount
                      }
                      className={
                        this.state.customer_email === "" ? "error" : ""
                      }
                      required={
                        !this.state.customer_does_not_email ? false : true
                      }
                    />
                  </Form.Group>
                  <Form.Check
                    className="mr-2 font-weight-bold"
                    inline
                    name="group1"
                    type="checkbox"
                    label={`Customer does not have email`}
                    onChange={this.handleBarCustomerEmailCheckbox}
                  />
                </div>
              </Col>
            </Row>
            <Row className="padding-mobile-view">
              {/* <Col md="4">
                   <div className="card-body">
                    <Form.Group controlId="formGridBusinessType">
                      <Form.Label>
                        <b>RFQ Type *</b>
                      </Form.Label>

                      <Form.Control
                        as="select"
                        name="rfq_type"
                        // defaultValue={this.state.selectedType}
                        value={this.state.rfq_type}
                        custom
                        // onChange={this.handleChange}
                        onChange={this.handleRFQTypeChange}
                        required
                      >
                        <option value="">Select Rfq Type</option>
                        {this.state.all_rfq_types.map((item, index) => {
                          return (
                            <option key={index} value={item}>
                              {item}
                            </option>
                          );
                        })}
                      </Form.Control>
                    </Form.Group>
                  </div> 
                </Col> */}
              {/* <Col md="4">
                  <div className="card-body">
                    <Form.Group controlId="formGridBuyingFor">
                      <Form.Label>Buying For</Form.Label>
                      <Form.Control
                        as="select"
                        custom
                        name="bussiness_type"
                        onChange={this.handleChange}
                        value={this.state.bussiness_type}
                      >
                        <option value="0">Select Buying For</option>
                        <option value="Project Use">Project Use</option>
                        <option value="Reselling">Reselling</option>
                        <option value="Own construction">
                          Own Construction
                        </option>
                      </Form.Control>
                    </Form.Group>
                  </div>
                </Col> */}
              {/* <Col md="4">
                  <div className="card-body">
                    <Form.Group controlId="formGridBuyingFor">
                      <Form.Label>
                        <b>Priority *</b>
                      </Form.Label>
                      <Form.Control
                        as="select"
                        custom
                        name="rfq_priority"
                        onChange={this.handleChange}
                        value={this.state.rfq_priority}
                        required
                      >
                        <option value="0">Select Priority</option>
                        <option value="Highest">Highest</option>
                        <option value="High">High</option>
                        <option value="Medium">Medium</option>
                        <option value="Low">Low</option>
                      </Form.Control>
                    </Form.Group>
                  </div>
                </Col> */}
              <Col md="3">
                <div>
                  <Form.Group controlId="formGridCustomerType">
                    <Form.Label>
                      <b>You Are a ?... *</b>
                    </Form.Label>
                    <Form.Control
                      as="select"
                      custom
                      onChange={this.handleChange}
                      name="customer_type"
                      required
                    >
                      {this.state.customer_type === "" ? (
                        <option value="">Select to Choose</option>
                      ) : (
                        <option value={this.state.customer_type}>
                          {this.state.customer_type}
                        </option>
                      )}
                      {this.state.customerTypeArray.map((item, index) => {
                        return (
                          <option key={index} value={item.meta_value}>
                            {item.meta_title}
                          </option>
                        );
                      })}
                    </Form.Control>
                  </Form.Group>
                </div>
              </Col>

              {/* <Col md="4">
                  <div className="card-body">
                    <Form.Group
                      className="mb-3"
                      controlId="exampleForm.ControlTextarea2"
                    >
                      <Form.Label>
                        <b>Campaign Details</b>
                      </Form.Label>
                      <Form.Control
                        name="campaign"
                        placeholder="Enter Campaign Details"
                        onChange={this.handleChange}
                        onKeyPress={(e) => {
                          e.key === "Enter" && e.preventDefault();
                        }}
                        // value={this.state.details}
                      />
                    </Form.Group>
                  </div>
                </Col> */}
              {/* <Col md={4}>
                    <div className="card-body">
                      <Form.Group controlId="formPinCode">
                        <Form.Label>
                          <b>PinCode *</b>
                        </Form.Label>
                        <Form.Control
                          className={this.state.wrong_pincode ? "error" : ""}
                          type="number"
                          name="pincode"
                          placeholder="PinCode"
                          onChange={this.handlePinCode}
                          // value={this.state.pincode}
                          onKeyPress={(e) => {
                            e.key === "Enter" && e.preventDefault();
                          }}
                          // required
                        />
                        <Form.Control.Feedback type="invalid">
                          Please enter Valid Pincode.
                        </Form.Control.Feedback>
                      </Form.Group>
                    </div>
                  </Col> */}
              <Col md="3">
                <div className="customeAutoComplete">
                  <Form.Group controlId="formCity" className="mb-3">
                    <Form.Label>
                      <b>Delivery Location</b>
                    </Form.Label>
                    {this.state.location.length == 0 ? (
                      <AsyncSelect
                        styles={customStyles}
                        // options={this.state.cityArray}
                        // styles={{
                        //   control: (baseStyles, state) => ({
                        //     ...baseStyles,
                        //     borderColor:
                        //       this.state.location || !this.state.validated
                        //         ? "grey"
                        //         : "red",
                        //   }),
                        // }}
                        value={this.state.location}
                        onChange={this.getCities}
                        onBlur={(e) => this.handleNoSelect(e)}
                        // loadOptions={this.debouncedFetchLocationOptions}
                        loadOptions={this.handleLocation}
                        placeholder="Location name"
                        // onInput={this.getCities}

                        onKeyPress={(e) => {
                          e.key === "Enter" && e.preventDefault();
                        }}
                        // required
                      />
                    ) : (
                      <Form.Control
                        type="text"
                        name="location"
                        placeholder="Location"
                        onChange={this.handleChange}
                        value={this.state.location}
                        onKeyPress={(e) => {
                          e.key === "Enter" && e.preventDefault();
                        }}
                        // required
                      />
                    )}
                    {/* <Form.Control
                        type="text"
                        onChange={this.getCities}
                        // value={this.state.cityArray}
                        placeHolder="Type City Name"
                      ></Form.Control> */}
                  </Form.Group>
                </div>
              </Col>
            </Row>
            <Row className="padding-mobile-view">
              {/* <Col md="4">
                  <div className="card-body">
                    <Form.Group controlId="formGridUTMSource">
                      <Form.Label>
                        <b>UTM SOURCE *</b>
                      </Form.Label>
                      <Form.Control
                      as="select"
                      custom
                      name="utmSource"
                      onChange={this.handleChange}
                      value={this.state.utmSource}
                      required
                      >
                        <option value="">Select Source</option>
                        {this.state.utmSourceArray.map((item, index) => {
                          return (
                            <option key={index} value={item.meta_value}>
                              {item.meta_title}
                            </option>
                          );
                        })}
                      </Form.Control>
                    </Form.Group>
                  </div>
                </Col> */}
              {/* <Col md="4">
                  {" "}
                  <div className="card-body">
                    <Form.Group controlId="formGridUTMMedium">
                      <Form.Label>
                        <b>UTM Medium *</b>
                      </Form.Label>
                      <Form.Control
                        as="select"
                        custom
                        name="utmMedium"
                        onChange={this.handleChange}
                        value={this.state.utmMedium}
                        required
                      >
                        <option value="">Select Medium</option>
                        {this.state.mediumArray.map((item, index) => {
                          return (
                            <option key={index} value={item.meta_value}>
                              {item.meta_title}
                            </option>
                          );
                        })}
                      </Form.Control>
                    </Form.Group>
                  </div>
                </Col> */}
            </Row>
            {/* <Row className="padding-mobile-view">
                <Col md="12">
                  <div>
                    <Form.Group
                      className="mb-3"
                      controlId="exampleForm.ControlTextarea1"
                    >
                      <Form.Label>
                        <b>Details</b>
                      </Form.Label>
                      <Form.Control
                        as="textarea"
                        name="details"
                        rows={3}
                        placeholder="Enter Your Details"
                        onChange={this.handleChange}
                        value={this.state.details}
                        onKeyPress={(e) => {
                          e.key === "Enter" && e.preventDefault();
                        }}
                      />
                    </Form.Group>
                  </div>
                </Col>
              </Row> */}
          </div>
          <div className={classes.requirement_detail_wrapper}>
            <h3 className={classes.requirement_detail_heading}>
              Requirement Details
            </h3>
            <hr className={classes.dottedBorder} />
            <div>
              {/* <Form.Group controlId="formFile" className="mb-3">
                    <Form.Label>
                    <b>Choose File</b>
                    </Form.Label>
                    <Form.Control
                    type="file"
                    name="attachment"
                    id="image"
                    multiple
                    onChange={this.onFileChange}
                    />
                    </Form.Group> */}
              {/* {this.state.fileNames.map((fileName, index) => (
                          <>
                          {console.log(fileName,"filename here")}
                          <p key={index} className="m-1">
                          {fileName}
                          </p>
                          </>
                          ))} */}

              {/* <div className="m-3 ml-3">
                    <Form.Group controlId="formFile" className="m-0">
                      <input
                        type="file"
                        name="attachment"
                        id="fileInput"
                        className="file-input"
                        // multiple
                        accept="image/*,.pdf,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,application/vnd.ms-excel"
                        onChange={this.onFileChange}
                      />
                      <label htmlFor="fileInput" className="file-label">
                        Choose File
                      </label>
                    </Form.Group>
                    {this.state.is_image_loading ? (
                      <Spinner animation="border" role="status">
                        <span className="sr-only">Loading...</span>
                      </Spinner>
                    ) : (
                      <div>
                          <div className="d-flex align-items-center">
                            {this.state.uploadedFiles.map((file, index) => (
                              <div className="upload-img-cnt mt-3" key={index}>
                                <div className="upload-img-wrapper">
                                   {file.type === 'image' ? (
                                      <a href={file.url} target="_blank"><img src={file.url} alt="Uploaded" width="100%" height="100%"/></a>
                                    ) : file.type === 'xls' ? (
                                      <a href={file.url} target="_blank"><img src={ExcelIcon} alt="excel" /></a>    
                                    ) : file.type === 'docx' ? (
                                      <a href={file.url} target="_blank"><img src={DocsIcon} alt="docx" /></a>    
                                    ) : file.type === 'pdf' ? (
                                      <a href={file.url} target="_blank"><img src={PdfIcon} alt="pdf" /></a>    
                                    )
                                    :null}
                                </div>
                                <button 
                                className="remove-img-btn"
                                  onClick={(event) =>
                                    this.deleteAttachment(file.url, event)
                                  }
                                >
                                  <img src={CancelIcon} alt="cancel" />
                                </button>
                              </div>
                            ))}
                          </div>
                      </div>
                    )}
                    </div> */}

              {/* {this.state.getAllProductsWithDetailForList.length > 0 ? (
                    <div className="mobile-table-scroll">
                      <Table striped bordered hover>
                        <thead>
                          <tr>
                            <th>#</th>
                            <th>Item Type</th>
                            <th>Item Detail</th>
                            <th>Quantity</th>
                            <th>Price</th>
                            <th>Remove</th>
                          </tr>
                        </thead>
                        <tbody>
                          {this.state.getAllProductsWithDetailForList.map(
                            (item, index) => {
                              return (
                                <tr key={item.index}>
                                  <td>{index + 1}</td>
                                  <td>{item.type ? item.type : "Product"}</td>
                                  <td>
                                    {item.type === "attachments" ? 
                                     <a
                                      href={item.name}
                                      target="_blank"
                                    >
                                     Attachment
                                    </a> :
                                    item.name ? item.name : item.category_name}
                                  </td>
                                  <td>{item.quantity}</td>
                                  <td>{item.price}</td>

                                  <td>
                                    <a>
                                      <FontAwesomeIcon
                                        icon="times-circle"
                                        className="editIcon"
                                        onClick={() =>
                                          this.removeItemFromMainList(index)
                                        }
                                      />
                                    </a>
                                  </td>
                                </tr>
                              );
                            }
                          )}
                        </tbody>
                      </Table>
                    </div>
                  ) : null} */}

              {/* // <div defaultValue={this.state.customer_id} disabled={!this.state.customer_id}> */}
              {/* {this.state.customer_id?  */}
              {/* {!this.state.customer_id? this.getPhoneValueB2C():true} */}

              <AddItem
                //  disabled={!this.state.customer_id}
                getRfqArray={this.displayAllProducts}
                getAllProductsWithDetailForList={
                  this.state.getAllProductsWithDetailForList
                }
                updateProducts={this.updateProducts}
                // removeItemFromMainList={this.removeItemFromMainList}
                displayProductArray={this.state.displayProductArray}
                custome_id={this.state.customer_id}
                //  custome_id={console.log("customerid",this.state.customer_id)}
                //  custome_id={if(this.state.customer_id===''){
                //      this.getPhoneValueB2C()
                //  }
                // }
                customer_name={this.state.customer_name}
                customer_email={this.state.customer_email}
                customer_mobile={this.state.phone2}
                customer_idB2={this.customer_idB2}
                customerMobile={this.customerMobile}
                // rfq_products={this.state.rfq_products}
                showCategory={this.state.showCategory}
                categoryObject={this.categoryObject}
                categObj={this.state.categObj}
                wrong_phone={this.state.wrong_phone}
              />
              {/* : "First fill Customer Mobile before adding products" }     */}
              {/* </div> */}
            </div>
          </div>
          <div className={classes.footer}>
            <div className="row">
              <Col lg={12}>
                <div className="d-flex align-items-center h-100 justify-content-end">
                  <Button
                    variant="primary"
                    type="submit"
                    id="createRfqSr"
                    className={classes.submit}
                    disabled={this.state.is_loading ? true : false}
                    onClick={this.handleSubmit}
                  >
                    {this.state.is_loading ? (
                      <Spinner animation="border" role="status">
                        <span className="sr-only">Loading...</span>
                      </Spinner>
                    ) : (
                      "Create RFQ & SR"
                    )}
                  </Button>
                  <Button
                    variant="primary"
                    type="submit"
                    id="createRfq"
                    className={classes.submit}
                    disabled={this.state.is_loading ? true : false}
                    onClick={this.handleSubmit}
                  >
                    {this.state.is_loading ? (
                      <Spinner animation="border" role="status">
                        <span className="sr-only">Loading...</span>
                      </Spinner>
                    ) : (
                      "Create RFQ"
                    )}
                  </Button>
                </div>
              </Col>
            </div>
          </div>
        </Form>
      </div>
    );
  }
}
export default CreateRfq;
