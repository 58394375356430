import React, { useState } from "react";
import "./sourcing_upload.css";
import ErrorMessage from "../../../asset/Images/crossError.svg";
import SuccessMessage from "../../../asset/Images/tickSuccess.svg";

const ErrorHandling = ({ message, type }) => {
  const [showToast, setShowToast] = useState(false);
  const handleCloseToast = () => {
    setShowToast(false);
  };
  return (
    <>
      {type === "ErrorMessage" ? (
        <div className={`tost-wrapper ${message==="Please upload an Excel file." ? "increaseZIndex":null}`}>
          <img src={ErrorMessage} alt="error icon" onClick={handleCloseToast} />
          <p className="content">{message}</p>
        </div>
      ) : null}
      {type === "SuccessMessage" ? (
        <div className="tost-wrapper">
          <img src={SuccessMessage} alt="success icon" />
          <p className="content">{message}</p>
        </div>
      ) : null}
    </>
  );
};

export default ErrorHandling;
