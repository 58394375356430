import React from "react";
import { Col, Form, Button } from "react-bootstrap";
import AsyncSelect from "react-select/async";
import DeleteIcon from "../../../asset/Images/delete icon.svg";
import removeIcon from "../../../asset/Images/removeIcon.svg";
import accorCSS from "./AccordionPage.module.css";
import classes from "./CreateSource.module.css";
import moment from "moment";
import { ReactComponent as SearchIcon } from "../../../asset/Images/search.svg";
import { components } from "react-select";
import UploadAttachment from "./UploadAttachment";
import { ReactComponent as DownArrowIcon } from "../../../asset/Images/RightArrow.svg";

const CustomInput = (props) => (
  <div style={{ display: "flex", alignItems: "center", height: "100%" }}>
    <SearchIcon style={{ marginRight: "4px" }} />
    <components.Input {...props} />
  </div>
);

const customStyles = {
  control: (provided, state) => ({
    ...provided,
    backgroundColor: "transparent",
    border: "0",
    borderColor: state.isFocused ? "none" : "none",
    "&:hover": {
      borderColor: state.isFocused ? "none" : "none",
    },
    boxShadow: state.isFocused ? "none" : "none",
    minHeight: "auto",
    height: "100%",
  }),
  valueContainer: (provided) => ({
    ...provided,
    height: "100%",
    padding: "0",
  }),
  placeholder: (provided) => ({
    ...provided,
    color: "#99ABBE",
    fontSize: "13px",
  }),
  singleValue: (provided) => ({
    ...provided,
    color: "#1E293B",
    padding: "0 0 0 20px",
    fontSize: "13px",
  }),
  dropdownIndicator: (provided) => ({
    ...provided,
    display: "none",
  }),
  indicatorSeparator: (provided) => ({
    ...provided,
    display: "none",
  }),
};

const SalesProductListing = (props) => {
  const {
    ListHeading,
    RemarksHeading,
    kamRemarks,
    accountManager,
    userLocation,
    dataSubmittedOn,
    status,
    products,
    productName,
    disabled,
    addItem,
    removeItem,
    getProductName,
    getCategoryName,
    handleProductList,
    handleCategoryList,
    handleOtherName,
    handleFormChange,
    handleChange,
    handleSelectChooseType,
    type,
    handleFileSelect,
    maxLength,
  } = props;

  return (
    <>
      <Form noValidate>
        <div className={`${classes.requirement_detail_wrapper}`}>
          <h3 className={classes.requirement_detail_heading}>{ListHeading}</h3>
          <div className={accorCSS.agentInfoCnt}>
            {status &&
            ["pending", "new", "completed", "reopen"].includes(status) ? (
              <>
                <p className={accorCSS.agentInfoCntContent}>
                  <span className={`${accorCSS.d_none_mobile}`}>by:&nbsp;</span>
                  <span>{accountManager}</span>
                </p>
                <hr className={accorCSS.divider} />
                <p className={accorCSS.agentInfoCntContent}>
                  {moment.utc(dataSubmittedOn).format("DD-MM-YYYY")} at&nbsp;
                  {moment.utc(dataSubmittedOn).format("hh:mm A")}
                </p>
                <hr
                  className={`${accorCSS.divider} ${accorCSS.d_none_mobile}`}
                />
                <p
                  className={`${accorCSS.agentInfoCntContent} ${accorCSS.d_none_mobile}`}
                >
                  {userLocation}
                </p>
              </>
            ) : null}
          </div>
          <hr className={classes.dottedBorder} />
          <>
            {products.map((item, index) => (
              <>
                <Form.Row key={index} className={classes.requirement_items}>
                  <Col lg={2} className={classes.verticalCenter}>
                    <Form.Group className={classes.formGroup}>
                      <Form.Label className={classes.customLabel}>
                        Type
                        {disabled ? null : (
                          <span class={classes.mandatory}>*</span>
                        )}
                      </Form.Label>
                      {disabled ? (
                        <Form.Group className={classes.formGroup}>
                          <p className={classes.customSelectReadable}>
                            {item.type === "product" ? "catalogue" : item.type}
                          </p>
                        </Form.Group>
                      ) : (
                        <div className={classes.customSelectCnt}>
                          <Form.Control
                            className={classes.customSelect}
                            as="select"
                            custom
                            name="type"
                            value={item.type}
                            onChange={(e) =>
                              handleSelectChooseType(index, e, type)
                            }
                          >
                            <option value="product">Catalogue</option>
                            <option value="category">Category</option>
                            <option value="other">Other</option>
                            <option value="attachments">Attachment</option>
                          </Form.Control>
                          <DownArrowIcon className={classes.selectDownArrow} />
                        </div>
                      )}
                    </Form.Group>
                    <hr className={classes.customeSeprator} />
                  </Col>
                  {item.type === "attachments" ? (
                    <UploadAttachment
                      item={item}
                      onFileSelect={(file) =>
                        handleFileSelect(file, props.type, index)
                      }
                      type={"product"}
                    />
                  ) : (
                    <>
                      <Col
                        lg={disabled ? 5 : 4}
                        className={classes.verticalCenter}
                      >
                        <Form.Group className={classes.formGroup}>
                          <Form.Label className={classes.customLabel}>
                            Name
                            {disabled ? null : (
                              <span className={classes.mandatory}>*</span>
                            )}
                          </Form.Label>
                          {disabled ? (
                            <Form.Group className={classes.formGroup}>
                              <p className={classes.labelreadContent}>
                                {item.name}
                              </p>
                            </Form.Group>
                          ) : (
                            <>
                              {!item.name && item.type != "other" ? (
                                <AsyncSelect
                                  components={{ Input: CustomInput }}
                                  className={classes.customSelect}
                                  styles={customStyles}
                                  value={{
                                    value: productName,
                                    label: item.name,
                                  }}
                                  onChange={(e) => {
                                    item.type == "product"
                                      ? getProductName(e, index, type)
                                      : getCategoryName(e, index, type);
                                  }}
                                  loadOptions={
                                    item.type == "product"
                                      ? handleProductList
                                      : handleCategoryList
                                  }
                                  placeholder="Enter Item"
                                />
                              ) : (
                                <Form.Control
                                  type="text"
                                  name="name"
                                  placeholder="Other"
                                  className={classes.customInput}
                                  onChange={(e) =>
                                    handleOtherName(index, e, type)
                                  }
                                  value={item.name}
                                  disabled={disabled}
                                />
                              )}
                            </>
                          )}
                        </Form.Group>
                        <hr className={classes.customeSeprator} />
                      </Col>
                      <Col lg={2} className={classes.verticalCenter}>
                        <Form.Group className={classes.formGroup}>
                          <Form.Label className={classes.customLabel}>
                            Brand
                            {disabled ? null : (
                              <span className={classes.mandatory}>*</span>
                            )}
                          </Form.Label>
                          {disabled ? (
                            <Form.Group className={classes.formGroup}>
                              <p className={classes.labelreadContent}>
                                {item.brand}
                              </p>
                            </Form.Group>
                          ) : (
                            <Form.Control
                              name="brand"
                              className={
                                item.type === "product"
                                  ? `${classes.labelreadContent} ${classes.inputDiabled}`
                                  : classes.customInput
                              }
                              value={item.brand}
                              disabled={item.type === "product"}
                              onChange={(e) => handleChange(e, index, type)}
                            />
                          )}
                        </Form.Group>
                        <hr className={classes.customeSeprator} />
                      </Col>
                      <Col lg={2} xs={6} className={classes.verticalCenter}>
                        <Form.Group className={classes.formGroup}>
                          <Form.Label className={classes.customLabel}>
                            Qty
                            {disabled ? null : (
                              <span className={classes.mandatory}>*</span>
                            )}
                          </Form.Label>
                          {disabled ? (
                            <Form.Group className={classes.formGroup}>
                              <p className={classes.labelreadContent}>
                                {item.quantity}
                              </p>
                            </Form.Group>
                          ) : (
                            <Form.Control
                              className={classes.customInput}
                              placeholder="Quantity"
                              name="quantity"
                              type="number"
                              value={item.quantity}
                              onChange={(e) => handleChange(e, index, type)}
                            />
                          )}
                        </Form.Group>
                        <hr className={classes.customeSeprator} />
                        {disabled ? (
                          <hr className={classes.customeSepratorMobile} />
                        ) : null}
                      </Col>
                      <Col lg={1} xs={6} className={classes.verticalCenter}>
                        <Form.Group className={classes.formGroup}>
                          <Form.Label className={classes.customLabel}>
                            Unit
                            {disabled ? null : (
                              <span className={classes.mandatory}>*</span>
                            )}
                          </Form.Label>
                          {disabled ? (
                            <Form.Group className={classes.formGroup}>
                              <p className={classes.labelreadContent}>
                                {item.quantity_type}
                              </p>
                            </Form.Group>
                          ) : (
                            <Form.Control
                              className={
                                item.type === "product"
                                  ? `${classes.labelreadContent} ${classes.inputDiabled}`
                                  : classes.customInput
                              }
                              name="quantity_type"
                              type="text"
                              value={item.quantity_type}
                              disabled={item.type === "product"}
                              onChange={(e) => handleChange(e, index, type)}
                            />
                          )}
                        </Form.Group>
                      </Col>
                    </>
                  )}

                  {!disabled ? (
                    <Col lg={1} className={classes.centerAlign}>
                      <img
                        className={classes.deleteIcon}
                        src={DeleteIcon}
                        alt="deleteIcon"
                        id={index}
                        onClick={() => removeItem(index, type)}
                      />
                      <div className={classes.removeIconWrapper}>
                        <img
                          className={classes.image}
                          src={removeIcon}
                          alt="removeIcon"
                          id={index}
                          onClick={() => removeItem(index, type)}
                        />
                      </div>
                    </Col>
                  ) : null}
                </Form.Row>
                <hr className={classes.dottedBorder} />
              </>
            ))}
          </>
          {!disabled ? (
            <div>
              <button
                className={`${classes.addRowBtn}`}
                type="button"
                onClick={() =>
                  addItem({
                    type: "product",
                    quantity: "",
                    brand: "",
                    brand_id: 0,
                    product_id: 0,
                    category_id: 0,
                  })
                }
              >
                Add Row
              </button>
              <hr className={classes.dottedBorder} />
            </div>
          ) : null}
          <Form.Group className={classes.formGroup}>
            {disabled ? (
              <Form.Group className={classes.formGroup}>
                <Form.Label className={classes.remarksLabel}>
                  {RemarksHeading}
                </Form.Label>
                <p className={classes.remarksReadableContent}>{kamRemarks}</p>
              </Form.Group>
            ) : (
              <Form.Group className="mb-0">
                <Form.Label className={`${classes.remarksLabel} pl-3`}>
                  {RemarksHeading}
                </Form.Label>
                <div>
                  <Form.Control
                    className={`${classes.textareaRemarks}`}
                    as="textarea"
                    rows={2}
                    name="kam_remarks"
                    value={kamRemarks}
                    onChange={handleFormChange}
                  />
                  <Form.Text className={classes.textCount}>
                    {kamRemarks.length}/{maxLength}
                  </Form.Text>
                </div>
              </Form.Group>
            )}
          </Form.Group>
        </div>
      </Form>
    </>
  );
};

export default SalesProductListing;
