import React, { useState, useEffect } from "react";
import { getUserDetails } from "../../Login/getLoginnedUserDetails";
import axios from "axios";
import Daterangepicker from "../../Dashboard/Common/Daterangepicker";
import "./RfqData.css";

import Constants from "../../../Config";
import { Row } from "react-bootstrap";
const apiUrl = Constants.API_URL;
// const webUrl = Constants.WEB_URL;

class RfqData extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      date_from: "",
      date_to: "",
      userData_array: [],
      summary: [],
      columnArray: [],
      rowArray: [],
      directTotal: 0,
      total: 0,
    };
  }

  componentDidMount() {
    this.getAllQuote();
    // this.getSummary();
  }

  getAllQuote = async () => {
    let loginedUserDetails = JSON.parse(localStorage.getItem("userData"));
    const options = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${loginedUserDetails.token}`,
      },
      data: {
        logined_user_id: loginedUserDetails.user_id,
        date_from: this.state.date_from,
        date_to: this.state.date_to,
      },
    };
    try {
      const data = await axios(apiUrl + "/rfq-widget", options);
      this.setState(
        {
          userData_array: data.data.data,
        },
        () => {
          if (
            this.state.userData_array.length &&
            this.state.userData_array[0].details
          ) {
            let example = this.state.userData_array
            let returned = example.map((item)=>{
              return item.details.filter((v,i,a)=>a.findIndex(v2=>(JSON.stringify(v2) === JSON.stringify(v)))===i)
              
            })
            for(let i =0;i< example.length ; i++){
              example[i].details = returned[i]

            };
            this.setState({
              userData_array : example,
              columnArray: this.state.userData_array[0].details,
            });
          }
        }
      );
      let holder = {};
      let rowArray = {};
      data.data.data.map((item, index) => {
        item.details &&
          item.details.map((d) => {
            if (holder.hasOwnProperty(d.status)) {
              holder[d.status] = holder[d.status] + d.count;
            } else {
              holder[d.status] = d.count;
            }
          });
      });
      let total = 0;
      let directTotal = 0;
      data.data.data.map((item, index) => {
        total += item.total;
        directTotal += item.totalDirectedRFQS;
      });
      rowArray = holder;
      this.setState({
        rowArray,
        total,
        directTotal,
      });
      rowArray = {};
      holder = {};
    } catch (error) {
      return console.log(error);
    }
  };
  render() {
    const setDateRange = (data) => {
      this.setState(
        {
          date_from: data.start,
          date_to: data.end,
        },
        () => {
          this.getAllQuote();
        }
      );
    };
    return (
      <div className="p-5 my-5 rounded border border-5 border-dark " id="table-padding-mobile">
        <div className="row mb-3 justify-content-start">
          <div className="col-6 col-md-3">
            <div className="quote_title_heading"
              style={{
                fontWeight: "700",
                fontSize: "17px",

                backgroundColor: "#17a2b8",
                padding: "0.5rem",
                borderRadius: "1rem",
                textAlign: "center",
                boxShadow: '0 5px 10px rgba(0, 0, 0, 0.8)'
              }}
            >
              RFQ Dashboard 
            </div>
          </div>
          <div className="col-6 col-md-3 ">
            <div className="date_rfq_test"
              style={{
                width:"100%",
                background: "#fff",
                cursor: "pointer",
                border: "1px solid #ccc",
                padding: "6px 10px 5px 17px",
                marginLeft: "460px",
              }}
            >
              <Daterangepicker
                defaultDays="0"
                setDateRange={setDateRange}
              ></Daterangepicker>
            </div>
          </div>
          {/* <div className="col-6"></div> */}
        </div>
        <div className="row mobile-table-scroll" >
          <div className="col-12 col-md-3">
            <table className="rfqtable striped bordered hover">
              <tr className="">
                <th className="bodyColor">Agent</th>
                {this.state.columnArray.map((item) => {
                  return <th className="bodyColor">{item.status}</th>;
                })}
                <th className="bodyColor">Total</th>
                <th className="tabledef">Direct RFQs</th>
                {/* <th className="bodyColor">Agent</th>
                  <th className="bodyColor">New</th>
                  <th className="bodyColor">Pending</th>
                  <th className="bodyColor">Followup</th>
                  <th className="bodyColor">Converted to Quote</th>
                  <th className="bodyColor">Closed-won</th>
                  <th className="bodyColor">Closed-Lost</th>
                  <th className="bodyColor">Total</th> */}
              </tr>
              {this.state.userData_array.map((item, index) => {
                return (
                  // <tr></tr>
                  <tr>
                    <td key={index} value={item.name} className="tabledef">
                      {/* {item.name} */}
                      {item.name === null ? "Un Assigned" : item.name}
                    </td>
                    {item.details &&
                      item.details.map((data, index) => (
                        <td className="tabledef">{data.count}</td>
                      ))}

                    <td className="tabledef">{item.total}</td>
                    <td className="tabledef">{item.totalDirectedRFQS}</td>
                  </tr>
                );
              })}
              <tr>
                <td>Total</td>
                {Object.values(this.state.rowArray).map((item) => {
                  return (
                    <>
                      <td className="tabledef">{item}</td>
                    </>
                  );
                })}
                <td className="tabledef">{this.state.total}</td>
                <td className="tabledef">{this.state.directTotal}</td>
              </tr>
            </table>
          </div>
        </div>
      </div>
    );
  }
}
export default RfqData;
