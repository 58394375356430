import React, { Component } from "react";
import { getUserDetails } from "../../Login/getLoginnedUserDetails";

import { Redirect, withRouter } from "react-router-dom";
import { Link } from "react-router-dom";
import {
  Modal,
  Button,
  Spinner,
  Form,
  Col,
  Row,
  ButtonGroup,
  DropdownButton,
  Dropdown,
  Alert,
  Table,
} from "react-bootstrap";
import axios from "axios";
import Constants from "../../../Config";
import SelectSearch from "react-select-search";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import CallAttemptedHistory from "../Quotes/CallAttemptedHistory";
import DatePicker from "react-datepicker";
import es from "date-fns/locale/es";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";
import AsyncSelect from "react-select/async";
import { faDownload } from "@fortawesome/free-solid-svg-icons";
import RfqEditProducts from "../Quotes/RfqEditProducts";
import CallAttemptedHistoryLeads from "./CallAttemptedHistoryLeads";

const apiUrl = Constants.API_URL;
const CallCount = 10;
const b2bApiUrl = Constants.B2B_API_URL;

export class ApproveLead extends Component {
  state = {
    isLoading: false,
    is_calling: false,
    isDisable: true,
    error: [],
    isCalling: false,
    isSaveLoading: false,
    customer_mobile: "",
    call_status_array: [],
    // agentsArrayParam: "",
    agentsArray: [],
    agent_id: "",
    callType: ["Knowlarity", "Mobile"],
    //Copied states
    message: "",
    lead_message: "",
    status: "",
    rfq_status: "",
    call_response: "",
    wrong_pincode: false,
    // callSchedularDisable: true,

    all_quote_types: [],
    call_reason: "",
    remarks: "",
    assigned_to: "",
    product_data: [],
    product_id: "",
    product_name: "",
    product_quantity: "",
    attachment: [],
    is_redirect: false,
    rfq_id: " ",
    lead_id: " ",
    phone: "",
    agent_mandatory: false,
    call_title: "",
    priority: "",
    prevlocation: "",
    pincode: "",
    location: "",
    locationArray: [],
    locationList: [],
    location_id: "",
    campaign: "",

    lead_status: "",
    initial_lead_status: "",
    selectedValue: "",
    category_name: "",
    category_id: "",
    type: "product",
    new_add: false,

    categoryList: [],
    categoryArray: [],
    itemArray: [],
    finalArray: [],

    id: 0,
    itemAdded: false,
    finalAdd: false,
    call_attempt_count: "",
    call_type: "",
    call_attempt_history: "",
    callback_hidden: true,
    called: false,
    call_successful_message: false,
    startDate: "",
    typeChanged: false,
    agentShow: false,
    validated: false,
    // business group states
    businessGroup: [],
    checkedBusinessGroup: 0,
    checkedBusinessType: [],
    businessType: [],
    business_type_other: "",
    businessType_required: false,
    showBusinesstype: false,
    is_multiselect_search: false,
    typeArray: [],
    customer_type: "",
    proAgentName: "",
    proBusinessName: "",
    business_group: 0,
    business_type: [],
    others: false,
    other_name: "",
    other_quantity: "",
    showMqButton: false,
    showMqButton2: false,
    showMqButton3: false,
    leadSuccessMessage: "",
    itemTypeArray: [],
    tempArray: [],
    productList: [],
    product_selected: false,
    dateDisable: false,
    minTime: this.calculateMinTime(new Date()),
    wrong_phone: "",
    customer_email: "",
    customer_email_preFilled: "",
    customer_does_not_email:false, // add new state customer does not have email
    utmSource: "",
    utmMedium: "",
    childLeads: [],
    duplicateLeads: [],
    duplicateProductsName: [],
    showDuplicateName: false,
    combinedProductArray: [],
    uploadedFiles : [],
    fileNames: [],
  };
  componentDidMount() {
    this.getLeadList();
    // this.getAgents();
    this.getBusinessGroup();
    this.handleCustomertype();
  }
  handleChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };
  // handle bar function customer does not have email start here
  handleBarCustomerEmailCheckbox = () => {
    this.setState((prevState) => ({ 
      customer_does_not_email: !prevState.customer_does_not_email, 
    }));
  } 
  // handle bar function customer does not have email ends here
  getLeadList = (e) => {
    this.setState(
      {
        isLoading: true,
      },
      () => {
        const options = {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${this.props.getUserDetails.token}`,
          },
          data: {
            lead_id: this.props.leadId,
            // logined_user_id: this.state.logined_user_id
            //   ? this.state.logined_user_id
            //   : this.props.getUserDetails.user_id,
            // quote_type: this.props.quote_type,
            selectedStatus: this.state.selectedStatus,
            selectedType: this.state.selectedType,
            total_item: this.state.total_item,
            current_page: this.state.current_page,
            // buying_for: this.state.selectedBuyingFor,
            date_from: this.state.date_from,
            date_to: this.state.date_to,
          },
        };
        return axios(apiUrl + "/lead-list", options)
          .then((data) => {
            var options1 = { year: "numeric", month: "long", day: "numeric" };
            let requestQuoteData = data.data.data[0];
            // requestQuoteData.product = requestQuoteData.product.map((item) => {
            //   return { ...item, type: this.state.type };
            // });
            this.rename(requestQuoteData.product);
            let callback_schedule;

            requestQuoteData.callback_schedule
              ? (callback_schedule = new Date(
                  requestQuoteData.callback_schedule
                ))
              : (callback_schedule = new Date(""));

            // let callback_schedule = new Date(
            //   ""
            // );
            let callBackDate =
              callback_schedule.toLocaleString("en", options1) +
              " " +
              callback_schedule.toLocaleTimeString();
            let showCallBackDate = requestQuoteData.callback_schedule
              ? callBackDate
              : "";

            let arr = requestQuoteData.business_type.split(",");

            let arr2 = arr.map((item) => Number(item));

            this.setState(
              {
                isLoading: false,
                customer_name: requestQuoteData.customerName,
                customer_mobile: requestQuoteData.mobile,
                customer_email: requestQuoteData.email,
                customer_email_preFilled: requestQuoteData.email,
                buying_for: requestQuoteData.buying_for,
                // agent_id:
                //   requestQuoteData.salesAgent == null
                //     ? ""
                //     : requestQuoteData.salesAgent,
                // lead_message: requestQuoteData.message,
                agent_id: requestQuoteData.supportAgentId,
                quote_type: requestQuoteData.lead_type,
                assigned_to: requestQuoteData.salesAgentName,
                startDateShow: showCallBackDate,
                product_data: requestQuoteData.product,
                attachment: requestQuoteData.attachments,
                priority: requestQuoteData.priority,
                pincode: requestQuoteData.pincode,
                location: requestQuoteData.location,
                location_id: requestQuoteData.location_id.toString(),
                campaign: requestQuoteData.campaign,
                lead_message: requestQuoteData.message,
                lead_status: requestQuoteData.leadStatus,
                initial_lead_status: requestQuoteData.leadStatus,
                proAgentName: requestQuoteData.proAgentName,
                proBusinessName: requestQuoteData.proBusinessName,
                customer_type: requestQuoteData.role,
                proAccountStatus: requestQuoteData.proAccountStatus,
                business_group: requestQuoteData.business_group,
                business_type: arr2,
                business_type_other: requestQuoteData.business_type_other,
                utmSource: requestQuoteData.utm_source,
                utmMedium: requestQuoteData.utm_medium,
                childLeads: requestQuoteData.childLeads,
              },
              () => {
                this.getCallAttemptHistory();
                this.handleCustomerOnLoad();
              }
            );
          })
          .catch((error) => console.log(error));
      }
    );
  };
  handleCustomertype = (e) => {
    let loginedUserDetails = JSON.parse(localStorage.getItem("userData"));
    const token = loginedUserDetails.token;
    axios
      .post(
        apiUrl + "/meta-value",
        {
          meta_id: 4,
        },

        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      )
      .then((data) => {
        this.setState({
          typeArray: data.data.data,
        });
      })
      .catch((err) => console.log(err));
  };
  getBusinessGroup = () => {
    this.setState(
      {
        isLoading: true,
        checkedBusinessGroup: this.state.business_group,
        checkedBusinessType: this.state.business_type,
        business_type_other: this.state.business_type_other,
      },
      () => {
        const options = {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${this.props.getUserDetails.token}`,
          },

          // data:{id:this.props.businessData.id}
          data: { id: 2105 },
        };
        return axios(apiUrl + "/get-business-group", options)
          .then((data) => {
            this.setState(
              {
                businessGroup: data.data.data,
                isLoading: false,
              },
              () => {
                if (this.state.business_group) {
                  let selectedBusinessGroupID =
                    this.state.businessGroup.findIndex((item) => {
                      return item.id == this.state.business_group;
                    });
                  // this.props.business_group2(selectedBusinessGroupID)
                  let selectedBusinessType =
                    this.state.businessGroup[selectedBusinessGroupID]
                      .business_type;
                  this.setState({
                    businessType: selectedBusinessType,
                  });
                  // this.props.business_type2(selectedBusinessType)
                }
              }
            );
          })
          .catch((error) => console.log(error));
      }
    );
  };
  handleBusinessGroup = (e) => {
    this.setState({
      showBusinesstype: true,
      checkedBusinessGroup: e.target.id,
      business_group: e.target.id,
      businessType_required: true,
    });
    // this.props.business_group2(e.target.id);
    let id = e.target.id;
    if (id == 100) {
      this.setState({
        others: true,
        checkedBusinessGroup: id,
        checkedBusinessType: [],
        business_group: id,
        business_type: [],
        is_multiselect_search: false,
        // required_state: false,
        agentid: "0",
      });
      // this.props.agent2(this.state.agentid));
      // this.props.business_group2(id)
    } else {
      if (id != 2) {
        this.setState({
          is_multiselect_search: false,
        });
      } else {
        this.setState({
          is_multiselect_search: true,
        });
      }
      this.setState(
        {
          others: false,
          checkedBusinessType: [],
          business_type_other: "",
        },
        () => {
          this.setState(
            {
              checkedBusinessGroup: id,
            },
            () => {
              let selectedBusinessGroupID = this.state.businessGroup.findIndex(
                (item) => {
                  return item.id == this.state.checkedBusinessGroup;
                }
              );
              // this.props.business_group2(selectedBusinessGroupID)
              let selectedBusinessType =
                this.state.businessGroup[selectedBusinessGroupID].business_type;
              this.setState({
                businessType: selectedBusinessType,
              });
              // this.props.business_type2(selectedBusinessType)
            }
          );
        }
      );
    }
  };
  handleBusinessType = (e) => {
    this.setState({
      businessType_required: false,
    });
    let selectedArray = this.state.checkedBusinessType;
    if (e.target.checked) {
      selectedArray.push(parseInt(e.target.id));
      this.setState({
        checkedBusinessType: selectedArray,
        business_type: selectedArray,
      });
      // this.props.business_type2(selectedArray);
    } else {
      let filteredArray = selectedArray.filter((item) => {
        return item != e.target.id;
      });
      if (filteredArray.length == 0) {
        this.setState({ businessType_required: true });
      }
      this.setState({
        checkedBusinessType: filteredArray,
      });
      // this.props.business_type2(filteredArray);
    }
  };
  handletypeOther = (e) => {
    this.setState({
      business_type_other: e.target.value,
    });
  };
  getAgents = () => {
    const options = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${this.props.getUserDetails.token}`,
      },
      data: {
        agent_list: "",
        id: [58],
        showAll: "",
        slug: "",
      },
    };
    return axios(apiUrl + "/get-pre-sales-agents", options)
      .then((data) => {
        this.setState({
          agentsArray: data.data.data,
          agent_id: "",
          agentShow: false,
        });
      })
      .catch((error) => console.log(error));
  };
  getAgents2 = () => {
    const options = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${this.props.getUserDetails.token}`,
      },
      data: {
        agent_list: "true",
        id: [56, 54],
        showAll: "true",
        slug: "",
      },
    };
    return axios(apiUrl + "/get-pre-sales-agents", options)
      .then((data) => {
        this.setState({
          agentsArray: data.data.data,
          agent_id: "",
          agentShow: true,
        });
      })
      .catch((error) => console.log(error));
  };
  getCall = () => {
    if (!this.state.customer_mobile) {
      this.setState({ wrong_phone: "Please Enter Correct Phone Number" });
    }
    let loginedUserDetails = JSON.parse(localStorage.getItem("userData"));
    this.setState(
      {
        is_calling: true,
      },
      () => {
        this.getCallAttemptCount()
          .then((data) => {
            if (data <= CallCount) {
              const options = {
                method: "POST",
                headers: {
                  "Content-Type": "application/json",
                  Authorization: `Bearer ${this.props.getUserDetails.token}`,
                },
                data: {
                  phone: this.state.customer_mobile,
                  agentID: loginedUserDetails.user_id,
                },
              };
              return axios(apiUrl + "/make-call", options)
                .then((data) => {
                  let callData = data.data.data;
                  setTimeout(() => {
                    this.setState({
                      is_calling: false,
                      call_id: callData?.success?.call_id,
                      message: callData?.success?.message,
                      status: callData?.success?.status,
                      call_response: JSON.stringify(callData),
                    });
                  }, 1500);
                })
                .catch((error) => {
                  this.setState({
                    wrong_phone: data.data.data.message,
                  });
                });
            } else {
              this.setState({
                is_calling: false,
                error: "You have crossed your limit to call!",
              });
            }
          })
          .catch((error) => console.log(error));
      }
    );
  };

  getCallAttemptCount = () => {
    return new Promise((resolve, reject) => {
      const options = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${this.props.getUserDetails.token}`,
        },
        data: {
          requestQuoteID: this.props.leadId,
          quote_type: "LEADS",
        },
      };
      return axios(apiUrl + "/call-count", options)
        .then((data) => {
          this.setState(
            {
              call_attempt_count: data.data.data.count,
              isShowRight: true,
              call_type:
                this.state.call_type == "Knowlarity" ? "Knowlarity" : "Manual",

              called: true,
            },
            () => {
              this.getCallStatus();
              
              resolve(this.state.call_attempt_count);
              if(this.state.call_attempt_count > CallCount){
                this.setState({
                  is_calling : false,
                  error: "You have crossed your limit to call"

                })
              }
            }
          );
        })
        .catch((error) => reject(error));
    });
  };
  getCallStatus = () => {
    const options = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${this.props.getUserDetails.token}`,
      },
      data: {
        quote_type: "LEADS",
        call_attempt_number: this.state.call_attempt_count,
      },
    };
    return axios(apiUrl + "/get-call-status", options)
      .then((data) => {
        this.setState(
          {
            call_status_array: data.data.data,
            call_successful_message: true,
          },
          () => {
            setTimeout(() => {
              this.setState({
                call_successful_message: false,
              });
            }, 2000);
          }
        );
      })
      .catch((error) => console.log(error));
  };
  getCallAttemptHistory = () => {
    if (this.state.business_group) {
      this.setState({
        showBusinesstype: true,
        checkedBusinessType: this.state.business_type,
      });
    }
    if (this.state.business_group == 100) {
      this.setState({
        others: true,
      });
    }
    const options = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${this.props.getUserDetails.token}`,
      },

      data: {
        requestQuoteID: this.props.leadId,
        quote_type: this.state.quote_type,
        key: "LEADS",
      },
    };
    return axios(apiUrl + "/call-history", options)
      .then((data) => {
        if (data.data.data.length > 0) {
          this.setState({
            call_attempt_history: data.data.data,
            isShowRight: true,
          });
        }
      })
      .catch((error) => console.log(error));
  };

  rename = (e) => {
    // initialization with array of type of items

    this.setState(
      {
        finalArray: e, // initiating value of product data to itemarray
      },
      () => {
        const itemTypeArray = this.state.finalArray.map((o) => o.type);
        this.setState({ itemTypeArray });
      }
    );
  };
  handleSelect = (i, e) => {
    let value = this.state.product_data;
    value[i].type = e.target.value;
    value[i].name = "";
    value[i].quantity = 1;
    value[i].price = 0;
    value[i].category_id = 0;
    value[i].product_id = 0;

    let itemTypeArray = [...this.state.itemTypeArray];

    itemTypeArray[i] = e.target.value;
    this.setState(
      {
        product_data: value,
        itemTypeArray,
      },
      () => {
        this.getConvertToMqButton();
      }
    );
    //   ,
    //   () => {
    //     if (this.state.itemTypeArray.includes("other")) {
    //       this.setState({
    //         showMqButton2: false,
    //       });
    //     } else {
    //       this.setState({
    //         showMqButton2: true,
    //       });
    //     }
    //   }
    // );
  };
  handleCallType = (e) => {
    this.setState(
      {
        call_type: "Knowlarity",
      },
      () => {
        this.getCall();
      }
    );
  };

  addItem = (e) => {
    let id = Number(this.state.id);

    let newId = id + 1;
    this.state.itemTypeArray.push("product");

    this.setState(
      {
        product_data: this.state.product_data.concat(e),
        // product_name: "",
        // finalArray: this.state.finalArray.concat(e),
        id: newId,
        new_add: true,
        finalAdd: true,
      },
      () => {
        setTimeout(() => {
          this.setState({
            finalAdd: false,
          });
        }, 2000);
      }
    );

    // this.state.product_data.push(e);
  };
  removeItem = (e) => {
    let id = e;
    let product_data = this.state.product_data;
    product_data.splice(id, 1);
    let itemTypeArray = this.state.itemTypeArray;
    itemTypeArray.splice(id, 1);
    this.setState({
      product_data,
      itemTypeArray,
    });
  };

  handleDateChange = (date) => {
    if (date) {
      this.setState({
        minTime: this.calculateMinTime(date),
      });
    }
    this.setState(
      {
        startDate: date,
      },
      () => {
        if (this.state.startDate == null) {
          this.setState({
            startDate: "",
          });
        } else {
          this.setState({
            startDate: date,
          });
        }
      }
    );
  };
  handlePinCode = (e) => {
    this.setState(
      {
        pincode: e.target.value,
      },
      () => {
        if (this.state.pincode.length == 6) {
          const requestoption = {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${this.props.getUserDetails.token}`,
            },
            data: {
              pincode: this.state.pincode,
            },
          };
          return axios(b2bApiUrl + "/search-by-pincode", requestoption)
            .then((data) => {
              this.setState({
                location: `${data.data.data[0].city},${data.data.data[0].state}`,
                location_id: data.data.data[0].city_id,
                wrong_pincode: false,
              });
            })
            .catch((err) =>
              this.setState(
                {
                  wrong_pincode: true,
                },
                () => {
                  setTimeout(() => {
                    this.setState({
                      wrong_pincode: false,
                    });
                  }, 5000);
                }
              )
            );
        } else {
          this.setState(
            {
              wrong_pincode: true,
            },
            () => {
              setTimeout(() => {
                this.setState({
                  wrong_pincode: false,
                });
              }, 5000);
            }
          );
        }
      }
    );
  };
  getLocation = (e) => {
    const config = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${this.props.getUserDetails.token}`,
      },
      data: {
        keyword: e,
      },
    };
    return axios(apiUrl + "/get-suggested-cities", config)
      .then((data) => {
        this.setState({
          locationList: data.data.data,
          locationArray: data.data.data.map((item) => {
            return {
              name: `${item.name},${item.state_name}`,
              value: `${item.name},${item.state_name}`,
            };
          }),
        });
      })
      .catch((err) => console.log(err.response));
  };
  getCities = (e) => {
    // let input = document.getElementsByClassName("select-search__input");

    let location = this.state.locationList.filter(
      (el) => `${el.name},${el.state_name}` == e
    )[0];
    this.setState({
      location: e,
      location_id: location.city_id.toString(),
    });
  };
  handleDisable = (e) => {
    this.state.isDisable
      ? this.setState({ isDisable: false })
      : this.setState({ isDisable: true });
  };
  handleChangeForCallStatus = (e) => {
    // this.setState({
    //   call_status: e.target.value,
    // });
    // if(this
    // if(e.target.value == 294){
    //   this.setState({

    //     callSchedularDisable: false
    //   })
    // }
    if (this.state.call_attempt_count >= 6) {
      this.setState({
        lead_status: "Dead-Call attempts",
        callback_hidden: true,
      });
    }
    if (e.target.value) {
      let call_status_array = this.state.call_status_array.filter((item) => {
        return item.id == e.target.value;
      });
      let leadStatus = call_status_array[0].rfq_status;
      let call_title = call_status_array[0].status;
      this.setState(
        {
          // current_call_status: e.target.value,
          call_status: e.target.value,
          lead_status: leadStatus,
          // call_title: call_title,
        },
        () => {
          this.handllLeadStatusChange();
        }
      );
    }
  };
  handllLeadStatusChange() {
    if (
      [
        "Converted to RFQ-PRO",
        "Converted to RFQ-Other B2B",
        "Converted to RFQ-B2C",
      ].includes(this.state.lead_status)
    ) {
      this.getAgents2();
      this.getConvertToMqButton();
    } else {
      this.getAgents();
      this.setState({
        showMqButton: false,
      });
    }
  }
  changeRFQ = (val) => {
    this.submitForm(val);
    this.setState({
      is_redirect: true,
      lead_id: this.props.leadId,
      // phone: this.state.customer_mobile
    });
  };
  submitFormForMq = (e) => {
    const names = this.state.finalArray.map((o) => o.name);
    const filteredArray = this.state.finalArray.filter(
      ({ name }, index) => !names.includes(name, index + 1)
    );
    let updatedProductValues = this.getDuplicateLeadProducts(); //function to track the duplicate lead and its product
    if (updatedProductValues?.duplicateProductsName.length > 0) {
      this.setState({
        duplicateProductsName: updatedProductValues?.duplicateProductsName,
        showDuplicateName: true,
      });
      return;
    }

    let buttonClickType = e.target.id;
    if (buttonClickType === "submit") {
      this.setState(
        {
          isSaveLoading: true,
          validated: true,
        },
        () => {
          if (this.state.called) {
            let loginedUserDetails = JSON.parse(
              localStorage.getItem("userData")
            );
            let params = {
              method: "POST",
              headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${this.props.getUserDetails.token}`,
              },

              data: {
                leadId: this.props.leadId,
                customer_id: this.state.customer_id,
                mobile: this.state.customer_mobile,
                leadStatus: this.state.lead_status,
                customer_name: this.state.customer_name,
                email: this.state.customer_email,
                // pincode: this.state.pincode,
                location: this.state.location,
                location_id: this.state.location_id,
                pincode: this.state.pincode,
                priority: this.state.priority,
                // buying_for: this.state.buying_for,
                source: "CRM",
                campaign: this.state.campaign,
                lead_type: this.state.quote_type,
                attachments: JSON.stringify([...this.state.attachment, ...this.state.uploadedFiles]),
                // details: this.state.details,     // to be mapped with lead_message
                agent_id:
                  this.state.agent_id == ""
                    ? ""
                    : this.state.agent_id.toString(),
                // business_type: this.state.business_type,
                // business_group: Number(this.state.business_group),
                // business_type_other: this.state.business_type_other,
                products: this.state.combinedProductArray,

                call_id: this.state.call_id,
                // message: this.state.message,
                details: this.state.lead_message,
                status: this.state.status,
                call_response: this.state.call_response,
                call_status: this.state.call_status,
                // call_title: this.state.call_title,
                remarks: this.state.remarks,
                // agent_id : loginedUserDetails.user_id,
                call_attempt_count: this.state.call_attempt_count,
                // rfq_status: this.state.rfq_status,
                // quote_type: this.state.quote_type,
                // assigned_to: this.state.assigned_to,
                callback_schedule: this.state.startDate
                  ? moment(this.state.startDate.toString()).format(
                      "YYYY-MM-DDTHH:mm:ss"
                    )
                  : "",
                calling_mode: this.state.call_type,
                role: this.state.customer_type,
                autoMqButton: true,
                isCustomerEmail: this.state.customer_does_not_email, // add new key
                // callback_schedule : new Date().toLocaleString(),
              },   
            };
            return axios(apiUrl + "/update-leads", params)
              .then((data) => {
                this.convertToMq();
                this.setState({
                  isSaveLoading: false,
                  isShowSuccessMsg: true,
                  error: [],
                });
                setTimeout(() => {
                  this.setState({
                    isShowSuccessMsg: false,
                  });
                  this.closeModal();
                  this.props.getLeadList(0);
                }, 3000);
              })
              .catch((error) => {
                this.setState({
                  isSaveLoading: false,
                  error: error.response.data.message,
                });
              });
          } else {
            this.setState({
              error: "Please have a call first!",
              isSaveLoading: false,
            });
          }
        }
      );
      setTimeout(() => {
        this.setState({
          error: [],
          isShowSuccessMsg: false,
        });
      }, 3000);
    }
  };
  checkDuplicateProducts = (combinedArray) => {
    let res = [];

    for (let i = 0; i < combinedArray.length - 1; i++) {
      for (let j = i + 1; j < combinedArray.length; j++) {
        if (
          combinedArray[i].type != "other" &&
          combinedArray[i].product_id == combinedArray[j].product_id &&
          combinedArray[i].category_id == combinedArray[j].category_id
        ) {
          res.push({
            item_name: combinedArray[i].name,
            product_id: combinedArray[i].product_id,
            category_id: combinedArray[i].category_id,
          }); //returning multiple item name
        }
        if (combinedArray[i].name == combinedArray[j].name) {
          res.push({ item_name: combinedArray[i].name }); //returning multiple item name
          //returning multiple item name for Other
        }
      }
    }
    let singleObjforDuplicateProducts =
      res &&
      res.filter(
        (object, index, self) =>
          object.hasOwnProperty("product_id") &&
          index ===
            self.findIndex((o) => JSON.stringify(o) === JSON.stringify(object))
      );

    return singleObjforDuplicateProducts;
  };
  getDuplicateLeadProducts = () => {
    let duplicateLeads = [];
    let finalProductData = [];
    this.state.childLeads.forEach((lead) => {
      if (lead.hasOwnProperty("checked") && lead.checked) {
        duplicateLeads.push(lead.leadId); // pushing Duplicate Lead in  Array

        lead.productDetails.map((product) => {
          if (product.hasOwnProperty("checked") && product.checked) {
            finalProductData.push(product); //adding(merging) checked product in Primary products
          }
        });
      }
    });

    let modifiedProducts = finalProductData.map((product) => {
      if (product.type === "product") {
        // Create a new object with the modified fields
        const modifiedFields = {
          ...product,
          product_id: product.id,
          category_id: 0,
        };

        // Remove the unwanted fields from the modified object
        delete modifiedFields.id;
        delete modifiedFields.checked;

        return modifiedFields;
      }
      if (product.type === "category") {
        // Create a new object with the modified fields
        const modifiedFields = {
          ...product,
          category_id: product.id,
          product_id: 0,
        };

        // Remove the unwanted fields from the modified object
        delete modifiedFields.id;
        delete modifiedFields.checked;

        return modifiedFields;
      }
      if (product.type === "other") {
        // Create a new object with the modified fields
        const modifiedFields = {
          ...product,
          category_id: product.id,
          product_id: product.id,
        };

        // Remove the unwanted fields from the modified object
        delete modifiedFields.id;
        delete modifiedFields.checked;

        return modifiedFields;
      }
    });
    const combinedArray = [...this.state.product_data, ...modifiedProducts];
    this.setState({
      combinedProductArray: combinedArray,
    });

    let duplicateProductsName = this.checkDuplicateProducts(combinedArray);

    return { duplicateLeads, modifiedProducts, duplicateProductsName };
  };
  submitForm = (e) => {
    let updatedProductValues = this.getDuplicateLeadProducts(); //function to track the duplicate lead and its product
    if (updatedProductValues?.duplicateProductsName.length > 0) {
      this.setState({
        duplicateProductsName: updatedProductValues?.duplicateProductsName,
        showDuplicateName: true,
      });
      return;
    } else {
      this.setState({ showDuplicateName: false });
    }

    let buttonClickType = e.target.id;
    if (buttonClickType === "submit") {
      this.setState(
        {
          isSaveLoading: true,
          validated: true,
        },
        () => {
          if (this.state.called) {
            let loginedUserDetails = JSON.parse(
              localStorage.getItem("userData")
            );
            let params = {
              method: "POST",
              headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${this.props.getUserDetails.token}`,
              },

              data: {
                leadId: this.props.leadId,
                customer_id: this.state.customer_id,
                mobile: this.state.customer_mobile,
                leadStatus: this.state.lead_status,
                customer_name: this.state.customer_name,
                email: this.state.customer_email,
                // pincode: this.state.pincode,
                location: this.state.location,
                location_id: this.state.location_id,
                pincode: this.state.pincode,
                priority: this.state.priority,
                // buying_for: this.state.buying_for,
                source: "CRM",
                campaign: this.state.campaign,
                lead_type: this.state.quote_type,
                attachments: JSON.stringify([...this.state.attachment, ...this.state.uploadedFiles]),
                // details: this.state.details,     // to be mapped with lead_message
                agent_id:
                  this.state.agent_id == ""
                    ? ""
                    : this.state.agent_id.toString(),
                // business_type: this.state.business_type,
                // business_group: Number(this.state.business_group),
                // business_type_other: this.state.business_type_other,
                products: this.state.combinedProductArray,

                call_id: this.state.call_id,
                // message: this.state.message,
                details: this.state.lead_message,
                status: this.state.status,
                call_response: this.state.call_response,
                call_status: this.state.call_status,
                // call_title: this.state.call_title,
                remarks: this.state.remarks,
                // agent_id : loginedUserDetails.user_id,
                call_attempt_count: this.state.call_attempt_count,
                // rfq_status: this.state.rfq_status,
                // quote_type: this.state.quote_type,
                // assigned_to: this.state.assigned_to,
                callback_schedule: this.state.startDate
                  ? moment(this.state.startDate.toString()).format(
                      "YYYY-MM-DDTHH:mm:ss"
                    )
                  : "",
                calling_mode: this.state.call_type,
                role: this.state.customer_type,
                autoMqButton: false,
                duplicateLeads: updatedProductValues?.duplicateLeads,
                isCustomerEmail: this.state.customer_does_not_email, // add new key
                // callback_schedule : new Date().toLocaleString(),
              },
            };
            return axios(apiUrl + "/update-leads", params)
              .then((data) => {
                this.setState({
                  isSaveLoading: false,
                  isShowSuccessMsg: true,
                  error: [],
                });
                setTimeout(() => {
                  this.setState({
                    isShowSuccessMsg: false,
                  });
                  this.closeModal();
                  this.props.getLeadList(0);
                }, 3000);
              })
              .catch((error) => {
                this.setState({
                  isSaveLoading: false,
                  error: error.response.data.message,
                });
              });
          } else {
            this.setState({
              error: "Please have a call first!",
              isSaveLoading: false,
            });
          }
        }
      );
      setTimeout(() => {
        this.setState({
          error: [],
          isShowSuccessMsg: false,
        });
      }, 3000);
    }
  };

  closeModal = () => {
    this.props.closeModal();
  };
  getConvertToMqButton = () => {
    let productData = this.state.product_data;
    let products = [];
    productData.forEach((obj) => {
      const picked = (({ type, product_id, category_id }) => ({
        type,
        product_id,
        category_id,
      }))(obj);
      products.push(picked);
    });
    products.forEach((obj) => {
      if (obj.product_id > 0) {
        obj["id"] = obj["product_id"];
        delete obj["category_id"];
        delete obj["product_id"];
      } else if (obj.category_id > 0) {
        obj["id"] = obj["category_id"];
        delete obj["product_id"];
        delete obj["category_id"];
      } else {
        obj["id"] = obj["category_id"];
        // delete obj["category_id"];
        delete obj["category_id"];
        delete obj["product_id"];
      }
    });
    const config = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${this.props.getUserDetails.token}`,
      },
      data: {
        lead_id: this.props.leadId.toString(),
        role: this.state.customer_type,
        products: products,
        lead_type: this.state.quote_type,
        status: this.state.lead_status,
      },
    };
    return axios(apiUrl + "/show-mq-button", config)
      .then((data) => {
        let buttonShow = data.data.data[0].isButtonShow;
        const names = this.state.itemTypeArray;

        // if (names.includes("other")) {
        //   this.setState({
        //     showMqButton3: false,
        //   });
        // } else {
        //   this.setState({
        //     showMqButton3: true,
        //   });
        // }

        this.setState({
          showMqButton: buttonShow,
        });
      })
      .catch((err) => console.log(err.response));
  };
  handleCustomer = (e) => {
    let productData = this.state.product_data;
    let products = [];
    productData.forEach((obj) => {
      const picked = (({ type, product_id, category_id }) => ({
        type,
        product_id,
        category_id,
      }))(obj);
      products.push(picked);
    });
    products.forEach((obj) => {
      if (obj.product_id > 0) {
        obj["id"] = obj["product_id"];
        delete obj["category_id"];
        delete obj["product_id"];
      } else if (obj.category_id > 0) {
        obj["id"] = obj["category_id"];
        delete obj["product_id"];
        delete obj["category_id"];
      } else {
        obj["id"] = obj["category_id"];
        // delete obj["category_id"];
        delete obj["category_id"];
        delete obj["product_id"];
      }
    });

    this.setState(
      {
        customer_type: e.target.value,
      },
      () => {
        const config = {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${this.props.getUserDetails.token}`,
          },
          data: {
            lead_id: this.props.leadId.toString(),
            role: this.state.customer_type,
            products: products,
            lead_type: this.state.quote_type,
            status: this.state.lead_status,
          },
        };
        return axios(apiUrl + "/show-mq-button", config)
          .then((data) => {
            let buttonShow = data.data.data[0].isButtonShow;
            this.setState({
              showMqButton: buttonShow,
            });
          })
          .catch((err) => console.log(err.response));
      }
    );
  };
  handleCustomerOnLoad = () => {
    let productData = this.state.product_data;
    let products = [];
    productData.forEach((obj) => {
      const picked = (({ type, product_id, category_id }) => ({
        type,
        product_id,
        category_id,
      }))(obj);
      products.push(picked);
    });
    products.forEach((obj) => {
      if (obj.product_id > 0) {
        obj["id"] = obj["product_id"];
        delete obj["category_id"];
        delete obj["product_id"];
      } else if (obj.category_id > 0) {
        obj["id"] = obj["category_id"];
        delete obj["product_id"];
        delete obj["category_id"];
      } else {
        obj["id"] = obj["category_id"];
        // delete obj["category_id"];
        delete obj["category_id"];
        delete obj["product_id"];
      }
    });

    const config = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${this.props.getUserDetails.token}`,
      },
      data: {
        lead_id: this.props.leadId.toString(),
        role: this.state.customer_type,
        products: products,
        lead_type: this.state.quote_type,
        status: this.state.lead_status,
      },
    };
    return axios(apiUrl + "/show-mq-button", config)
      .then((data) => {
        let buttonShow = data.data.data[0].isButtonShow;
        this.setState({
          showMqButton: buttonShow,
        });
      })
      .catch((err) => console.log(err.response));
  };
  convertToMq = () => {
    // this.submitForm(e);
    const config = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${this.props.getUserDetails.token}`,
      },
      data: {
        lead_id: this.props.leadId.toString(),
      },
    };
    return axios(apiUrl + "/converted-to-mq", config)
      .then((data) => {
        this.setState(
          {
            leadSuccessMessage: data.data.message,
          },
          () => {
            setTimeout(() => {
              this.setState(
                {
                  leadSuccessMessage: "",
                },
                () => {
                  this.closeModal();
                }
              );
            }, 3000);
          }
        );
      })
      .catch((err) => console.log(err));
  };
  handleNoSelect = (e, i) => {
    // let value = [...this.state.product_data];
    // value[i].name = e.target.value;
    // value[i].product_id = 0;
    // value[i].quantity = 1;
    // value[i].category_id = 0;
    // value[i].type = "other";
    // value[i].price = 0;
    // console.log(value, "value")
    this.setState({
      product_name: e.target.value,
      //   product_data: value
    });
  };
  handleProduct = async (e, callback) => {
    const config = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${this.props.getUserDetails.token}`,
      },
      data: {
        keyword: e,
      },
    };
    return await axios(apiUrl + "/get-products-by-keyword", config)
      .then((data) => {
        this.setState({
          productList: data.data.data,
        });
        callback(
          data.data.data.map((i) => ({
            label: `${i.name}`,
            value: `${i.name}`,
          }))
        );
      })
      .catch((err) => console.log(err.response));
  };
  getProductName = (e, i) => {
    let index = this.state.productList.filter((el) => el.name == e.label)[0];
    let value = [...this.state.product_data];
    value[i].name = index.name;
    value[i].product_id = index.product_id;
    value[i].quantity = 1;
    value[i].category_id = 0;
    value[i].type = "product";
    value[i].price = index.price;
    this.setState(
      {
        product_data: value,
        product_name: index.name,
        product_selected: true,
        //   product_id: index.product_id,
        //   price: Number(index.price).toFixed(2),
        //   nameChange: true,
        // availabe_stock: index.quantity,
      },
      () => {
        this.getConvertToMqButton();
      }
    );
  };
  handleQuantity = (i, e) => {
    // e.persist()
    let value = [...this.state.product_data];
    value[i].quantity = e.target.value;
    this.setState({
      product_data: value,
    });
  };
  handleOtherName = (i, e) => {
    const values = [...this.state.product_data];
    values[i][e.target.name] = e.target.value;
    this.setState({
      product_data: values,
      // selectedValue: e.target.value,
    });
  };
  getCategoryName = (e, i) => {
    let index = this.state.categoryList.filter((el) => el.name == e.label)[0];
    let value = [...this.state.product_data];
    value[i].name = index.name;
    value[i].product_id = 0;
    value[i].quantity = 1;
    value[i].category_id = index.category_id;
    value[i].type = "category";
    value[i].price = index.price ? index.price : 0;
    this.setState(
      {
        product_data: value,
        product_name: index.name,
        product_selected: true,
        //   product_id: index.product_id,
        //   price: Number(index.price).toFixed(2),
        //   nameChange: true,
        // availabe_stock: index.quantity,
      },
      () => {
        this.getConvertToMqButton();
      }
    );
  };
  getOtherName = (e, i) => {
    let value = [...this.state.product_data];
    value[i].name = e.target.value;
    value[i].product_id = 0;
    value[i].quantity = 1;
    value[i].category_id = 0;
    value[i].type = "ohter";
    value[i].price = 0;
    this.setState(
      {
        product_data: value,
        product_name: e.target.value,
        product_selected: true,
        //   product_id: index.product_id,
        //   price: Number(index.price).toFixed(2),
        //   nameChange: true,
        // availabe_stock: index.quantity,
      },
      () => {
      }
    );
  };

  handleCategory = async (e, callback) => {
    const config = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${this.props.getUserDetails.token}`,
      },
      data: {
        keyword: e,
      },
    };
    return await axios(apiUrl + "/get-product-category", config)
      .then((data) => {
        this.setState({
          categoryList: data.data.data,
        });
        callback(
          data.data.data.map((i) => ({
            label: `${i.name}`,
            value: `${i.name}`,
          }))
        );
      })
      .catch((err) => console.log(err.response));
  };

  onFileChange = async (event) => {
    // Update the state
    const file = event.target.files[0];
    var allowedimage = this.validateFile(file);
    const fileType = this.getFileType(file.name);
    let formData = new FormData();
    formData.append("file", event.target.files[0]);
    const totalFilesCount = this.state.uploadedFiles.length;
    if(totalFilesCount == 3){
      alert("You can upload a maximum of three images");
      return;
    }
    axios
      .post(apiUrl + "/rfq-attachment", formData, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${this.props.getUserDetails.token}`,
        },
      })
      .then((res) => {
        this.setState({
          uploadedFiles:
          [...this.state.uploadedFiles,
            {
              url: res.data.data.image_url,
              type: fileType
            }
          ],
          fileNames:[...this.state.fileNames, file.name]
        })
      });
  };
  validateFile = (file) => {
    var allowedExtension = [
      "image/jpeg",
      "image/jpg",
      "image/png",
      "application/pdf",
      "application/vnd.ms-excel",
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      "application/msword",
      "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
      "text/plain",
    ];
    if (file) {
      if (!allowedExtension.includes(file.type)) {
        alert("only allowed extension jpeg,jpg,png,pdf,excel file");
        return false;
      }
      if (file.size > 5000000) {
        alert("Please attached file less than 5 mb");
        return false;
      }
      return true;
    } else {
      alert("you did not attached file");
    }
  };
  getFileType = (filename) => {
    const fileExtension = filename.split('.').pop().toLowerCase();
    switch(fileExtension){
      case 'pdf':
        return 'pdf'
      case 'jpeg':
      case 'jpg':
      case 'png':
        return 'image'
      case 'doc':
      case 'docx':  
        return 'doc'  
      default:
        return 'unknown'    
    }
  }  
  calculateMinTime(date) {
    let isToday = moment(date).isSame(moment(), "day");
    if (isToday) {
      let nowAddOneHour = moment(new Date()).add({ minutes: 1 }).toDate();
      return nowAddOneHour;
    }
    return moment().startOf("day").toDate();
  }

  handleProductDetailChange = (leadId, productId) => {
    this.setState(
      (prevChildLeads) => ({
        childLeads: prevChildLeads.childLeads.map((childLead) => {
          if (childLead.leadId === leadId) {
            const updatedProductDetails = childLead.productDetails.map(
              (productDetail) => {
                if (productDetail.id === productId) {
                  return { ...productDetail, checked: !productDetail.checked };
                }
                return productDetail;
              }
            );

            const someChecked = updatedProductDetails.some(
              (productDetail) => productDetail.checked
            );

            return {
              ...childLead,
              productDetails: updatedProductDetails,
              checked: someChecked,
            };
          }

          return childLead;
        }),
      }),
      () => {
        
      }
    );
  };

  handleLeadIdChange = (leadId) => {
    this.setState(
      (prevState) => ({
        childLeads: prevState.childLeads.map((childLead) => {
          if (childLead.leadId === leadId) {
            const checked = !childLead.checked;
            // const updatedProductDetails = childLead.productDetails.map(
            //   (productDetail) => ({
            //     ...productDetail,
            //     checked,
            //   })
            // );
            let updatedProductDetails = [];
            if (!checked) {
              updatedProductDetails = childLead.productDetails.map(
                (productDetail) => ({
                  ...productDetail,
                  checked,
                })
              );
            } else {
              updatedProductDetails = childLead.productDetails;
            }
            return {
              ...childLead,
              productDetails: updatedProductDetails,
              checked,
            };
          }
          return childLead;
        }),
      }),
      () => {
      }
    );
  };
  setShow = () => {
    this.setState({
      showDuplicateName: false,
    });
  };
  handleAttachmentClick(url) {
    window.open(url, "_blank"); // Open link in a new tab
  }
  render() {
    if (this.state.is_redirect === true) {
      //  let data ={phone: this.state.customer_mobile,rfqdata: this.props.requestQuoteID}
      return (
        <Redirect
          to={{
            pathname: "/all-leads",
            state: {
              rfqID: 0,
              leadId: this.props.leadId,
              phone: this.state.customer_mobile,
            },
            // state:{data}
            // state: {phone:this.state.customer_mobile }
          }}
        />
      );
    }
    // var listItems = this.state.product_data.map(function(item){
    //   return (

    //   )
    // })
    return (
      <>
        <Modal
        className="business_process_wrapper body-scroll"
        centered
        show={this.props.show}
        onHide={this.closeModal}
        dialogClassName="modal-90w"
        aria-labelledby="example-custom-modal-styling-title"
        >
          <Modal.Header closeButton>
            <div className="d-flex justify-content-around">
              {!this.state.proBusinessName ? (
                <b>
                  <a className="on_call_title" href="/BusinessProfile" target="_blank">
                    Add New Business profile 
                  </a>
                </b>
              ) : (
                <b>
                  {" "}
                  Business name: {this.state.proBusinessName},
                  &nbsp;&nbsp;&nbsp; Agent Name: {this.state.proAgentName} ,
                  &nbsp;&nbsp;&nbsp;Status: {this.state.proAccountStatus}
                </b>
              )}
            </div>
          </Modal.Header>
          {this.state.isLoading ? (
            <div
              style={{ textAlign: "center", marginTop: 20, marginBottom: 20 }}
            >
              <Spinner animation="border" />
            </div>
          ) : (
            <Modal.Body className="business_approve_process">
              {this.state.wrong_phone ? (
                <Alert
                  className="displayMessageArea position-static"
                  variant={this.state.wrong_phone ? "danger" : "success"}
                >
                  Please Enter valid Phone Number
                </Alert>
              ) : null}
              {this.state.wrong_pincode ? (
                <Alert
                  className="displayMessageArea position-static"
                  variant={this.state.wrong_pincode ? "danger" : "success"}
                >
                  Please Enter valid Pincode
                </Alert>
              ) : null}
              {this.state.showDuplicateName ? (
                <Alert
                  className="displayMessageArea position-static"
                  variant="danger"
                  dismissible
                  onClose={() => this.setShow()}
                >
                  <Alert.Heading>
                    You have chosen Multiple Items with Same Details, Please
                    Choose any one of them <br />
                  </Alert.Heading>
                  {this.state.duplicateProductsName.map((item) => (
                    <div className="d-flex justify-content-between">
                      <p className="font-weight-bold">{item.item_name}</p>
                      {"  "}
                      <p className="font-weight-bold">
                        {item.product_id
                          ? `Product ID:${item.product_id}`
                          : item.category_id
                          ? `Category ID:${item.category_id}`
                          : null}
                      </p>
                    </div>
                  ))}
                </Alert>
              ) : null}
              {this.state.error.length > 0 ? (
                <Alert
                  className="displayMessageArea position-static"
                  variant={this.state.error ? "danger" : "success"}
                >
                  {this.state.error}
                </Alert>
              ) : null}
              {this.state.leadSuccessMessage ? (
                <Alert className="displayMessageArea position-static" variant="success">
                  {this.state.leadSuccessMessage}
                </Alert>
              ) : null}
              {this.state.isShowSuccessMsg ? (
                <Alert className="displayMessageArea position-static" variant={"success"}>
                  You have successfully updated the status
                </Alert>
              ) : null}
              <Form
                noValidate
                validated={this.state.validated}
                encType="multipart/form-data"
              >
                {/* {this.state.call_successful_message ? (
                  <div className="alert alert-success">
                    Call successfully placed
                  </div>
                ) : null} */}

                <Form.Row>
                  <Col
                    sm={this.state.isShowRight ? "8" : "12"}
                    className={
                      this.state.isShowRight ? "quoteStatus8" : "quoteStatus12"
                    }
                  >
                    <h5 className="on_call_title">On Call Information</h5>

                    <Form.Row>
                      <Col sm={6}>
                        <Form.Group controlId="formBasicPhone">
                          <Form.Label>Mobile</Form.Label>
                          <br />
                          <Form.Control
                            className="mobileNumber"
                            placeholder="Mobile"
                            disabled
                            name="customer_mobile"
                            value={this.state.customer_mobile}
                            onChange={this.handleChange}
                          />

                          {/* <Button
                            variant="success"
                            className="callButton"
                            disabled={this.state.is_calling}
                            onClick={this.getCall}
                          > */}

                          {/* </Button> */}

                          {this.state.is_calling ? (
                            <Spinner
                              animation="border"
                              size="sm"
                              style={{ width: "1.3rem", height: "1.3rem" }}
                            />
                          ) : (
                            <>
                              <Button
                                onClick={this.getCallAttemptCount}
                                value="Manual"
                              >
                                Call Manually
                              </Button>
                            </>
                            // <ButtonGroup>
                            //   <Button
                            //     onClick={this.handleCallType}
                            //     // value="Knowlarity"
                            //   >
                            //     Call By Knowlarity
                            //   </Button>
                            //   <DropdownButton
                            //     as={ButtonGroup}
                            //     id="bg-nested-dropdown"
                            //   >
                            //     <Dropdown.Item eventKey="Manual">
                            //       <Button
                            //         onClick={this.getCallAttemptCount}
                            //         value="Manual"
                            //       >
                            //         Call Manually
                            //       </Button>
                            //     </Dropdown.Item>
                            //   </DropdownButton>
                            // </ButtonGroup>
                          )}
                        </Form.Group>
                      </Col>
                      <Col sm={3}>
                        <Form.Group controlId="formBasicEmail">
                          <Form.Label>Name</Form.Label>
                          <Form.Control
                            placeholder="Name"
                            disabled
                            name="customer_name"
                            value={this.state.customer_name}
                            onChange={this.handleChange}
                            required
                          />{" "}
                          <Form.Control.Feedback type="invalid">
                            Please enter billing first name.
                          </Form.Control.Feedback>
                        </Form.Group>
                      </Col>
                      <Col sm={3}>
                        <Form.Group controlId="formBasicEmail">
                          <Form.Label>Email</Form.Label>
                          <Form.Control
                            placeholder="Email"
                            disabled={this.state.customer_email_preFilled}
                            name="customer_email"
                            value={this.state.customer_email}
                            className={this.state.customer_email === '' ? 'error' : ''}
                            onChange={this.handleChange}
                            required={
                              [
                                "Pending",
                                "Dead",
                                "Dead-Call attempts",
                                "Follow-up",
                                "Duplicate",
                              ].includes(this.state.lead_status)
                                ? false
                                : true
                            }
                            // required={!(/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/.test(this.state.customer_email))}
                          />
                          <Form.Control.Feedback type="invalid">
                            Please enter Valid Email.
                          </Form.Control.Feedback>
                        </Form.Group>
                        {!this.state.customer_email_preFilled ? 
                        <Form.Check
                          className="mr-2 font-weight-bold"
                          inline
                          name="group1"
                          type="checkbox"
                          label={`Customer does not have email`}
                          onChange={this.handleBarCustomerEmailCheckbox}
                        />
                        : null}
                      </Col>

                      <Col sm={12} className="mt-3">
                        <Button
                          variant="light"
                          type="button"
                          onClick={this.handleDisable}
                        >
                          <FontAwesomeIcon icon="edit" />
                          {this.state.isDisable ? "Edit Products" : ""}
                        </Button>
                        {!this.state.product_data.length ? (
                          <Button
                            variant="light"
                            type="button"
                            id={0}
                            onClick={() =>
                              this.addItem({
                                name: "",

                                quantity: 1,
                                type: "product",

                                // name: this.state.product_name
                              })
                            }
                          >
                            <FontAwesomeIcon icon="plus" />
                            {"Add Item"}
                          </Button>
                        ) : null}
                      </Col>
                      {!this.state.isDisable ? (
                        <>
                          {this.state.product_data.map((item, ind) => {
                            return (
                              <>
                                <Col md={2}>
                                  <Form.Group controlId="formBasicTypeName">
                                    <Form.Label>Type</Form.Label>
                                    <Form.Control
                                      as="select"
                                      custom
                                      name="type"
                                      onChange={(e) =>
                                        this.handleSelect(ind, e)
                                      }
                                      value={item.type}
                                      //   disabled={this.props.categoryDisable} //the disable condition is for Add item modal of CreateQuote Component
                                    >
                                      <option value="product">Product</option>
                                      <option value="category">Category</option>
                                      <option value="other">Other</option>
                                    </Form.Control>
                                  </Form.Group>
                                </Col>

                                <Col md={6}>
                                  <Form.Group
                                    controlId="formBasicName"
                                    key={ind}
                                  >
                                    <Form.Label>Item</Form.Label>
                                    {(!item.name || this.state.new_add) &&
                                    item.type != "other" ? (
                                      <AsyncSelect
                                        // value={this.state.product_name}
                                        // value={this.state.product_name}
                                        // defaultOptions={item.name}
                                        // value={this.state.product_name}
                                        value={{
                                          value: this.state.product_name,
                                          label: item.name,
                                        }}
                                        onChange={(e) => {
                                          item.type == "product"
                                            ? this.getProductName(e, ind)
                                            : this.getCategoryName(e, ind);
                                        }}
                                        // onBlur={(e) =>
                                        //   this.handleNoSelect(e, ind)
                                        // }
                                        loadOptions={
                                          item.type == "product"
                                            ? this.handleProduct
                                            : this.handleCategory
                                        }
                                        placeholder="Enter Item"
                                      />
                                    ) : (
                                      <Form.Control
                                        type="text"
                                        name="name"
                                        placeholder="Location"
                                        // onChange={this.handleChange}
                                        onChange={(e) =>
                                          this.handleOtherName(ind, e)
                                        }
                                        value={item.name}
                                      />
                                    )}
                                  </Form.Group>
                                </Col>

                                <Col md={2}>
                                  <Form.Group controlId="formBasicQuantity">
                                    <Form.Label>Quantity</Form.Label>
                                    <Form.Control
                                      placeholder="Quantity"
                                      name="quantity"
                                      value={item.quantity}
                                      // defaultValue={data.quantity}
                                      // value={this.state.product_quantity}
                                      onChange={(e) =>
                                        this.handleQuantity(ind, e)
                                      }
                                      disabled={this.state.isDisable}
                                    />
                                  </Form.Group>
                                </Col>

                                <Col md={1}>
                                  <FontAwesomeIcon
                                    icon="minus"
                                    id={ind}
                                    onClick={() => this.removeItem(ind)}
                                    className="removeIcon"
                                  />
                                </Col>
                                <Col md={1}>
                                  <FontAwesomeIcon
                                    icon="plus"
                                    id={ind}
                                    onClick={() =>
                                      this.addItem({
                                        name: "",

                                        quantity: 1,
                                        type: "product",

                                        // name: this.state.product_name
                                      })
                                    }
                                    className="addIcon"
                                  />
                                </Col>
                              </>
                            );
                          })}
                        </>
                      ) : (
                        //Disable Active condition Below
                        <>
                          {this.state.product_data.map((data, ind) => (
                            <>
                              <Col sm={2} key={ind}>
                                <Form.Group
                                  controlId="formBasicTypeName"
                                  // key={ind}
                                >
                                  <Form.Label>Choose Type</Form.Label>
                                  <Form.Control
                                    as="select"
                                    custom
                                    onChange={this.handleSelect}
                                    value={data.type}
                                    disabled

                                    //   disabled={this.props.categoryDisable} //the disable condition is for Add item modal of CreateQuote Component
                                  >
                                    <option value="product">Product</option>
                                    <option value="category">Category</option>
                                    <option value="other">Other</option>
                                  </Form.Control>
                                </Form.Group>
                              </Col>
                              <Col sm={6}>
                                <Form.Group controlId="formBasicEmail">
                                  <Form.Label>Product {ind + 1}</Form.Label>
                                  <Form.Control
                                    placeholder="Product"
                                    name="product_name"
                                    value={data.name}
                                    onChange={this.handleChange}
                                    disabled={this.state.isDisable}
                                  />
                                </Form.Group>
                              </Col>
                              <Col sm={3}>
                                <Form.Group controlId="formBasicEmail">
                                  <Form.Label>Quantity</Form.Label>
                                  <Form.Control
                                    placeholder="Quantity"
                                    name="product_quantity"
                                    min={1}
                                    value={data.quantity}
                                    onChange={this.handleChange}
                                    disabled={this.state.isDisable}
                                  />
                                </Form.Group>
                              </Col>

                              {/* <Col sm={3}>
                                <Form.Group controlId="formBasicEmail">
                                  <Form.Label>Price</Form.Label>
                                  <Form.Control
                                    placeholder="Price"
                                    name="price"
                                    value={data.productPrice}
                                    onChange={this.handleChange}
                                    disabled={this.state.isDisable}
                                  />
                                </Form.Group>
                              </Col> */}
                            </>
                          ))}
                        </>
                      )}

                      {this.state.attachment ? (
                        <>
                          <Col sm={12}>
                            <ul
                              className="row"
                              style={{ listStyleType: "none" }}
                            >
                              {this.state.attachment.map(
                                (attachments, index) => (
                                  <li className="p-1" key={index}>
                                    <a
                                      style={{
                                        textDecoration: "underline",
                                        cursor: "pointer",
                                      }}
                                      href={attachments.url}
                                      target="_blank"
                                      onClick={() =>
                                        this.handleAttachmentClick(
                                          attachments.url
                                        )
                                      }
                                    >
                                      {`Attachment ${index + 1}`}
                                    </a>
                                  </li>
                                )
                              )}
                            </ul>
                          </Col>
                        </>
                      ) : null}

                      {/* {this.state.attachment ? (
                        <Col sm={12}>
                          <Form.Label>Attachment</Form.Label>{" "}
                          <FontAwesomeIcon
                            icon={faDownload}
                            onClick={() =>
                              window.open(this.state.attachment, "_blank")
                            }
                          />
                        </Col>
                      ) : (
                        ""
                      )} */}
                      <>
                      <Col sm={3}>
                        <Form.Group controlId="formFile">
                          <Form.Label>
                            {" "}
                            <b>Choose File..</b>
                          </Form.Label>
                          <Form.Control
                            type="file"
                            name="attachment"
                            id="image"
                            multiple
                            onChange={this.onFileChange}
                          />
                        </Form.Group>
                        <Col style={{ display:"flex", flexDirection:"row" }}>
                        {this.state.fileNames.map((fileName,index) => (
                          <p key={index} style={{ marginRight:"0.8rem" }}>{fileName}</p>
                        ))}
                        </Col>
                      </Col> 
                        <Col sm={3}>
                          <Form.Group controlId="formGridLeadStatus">
                            <Form.Label>Lead Status</Form.Label>
                            <Form.Control
                              name="lead_status"
                              onChange={this.handleChange}
                              value={this.state.lead_status}
                              disabled
                            />
                          </Form.Group>
                        </Col>

                        {/* location form dropdown in below column */}
                        <Col md={4}>
                          <Form.Group controlId="formPinCode">
                            <Form.Label>PinCode</Form.Label>
                            <Form.Control
                              type="number"
                              name="pincode"
                              placeholder="PinCode"
                              onChange={this.handlePinCode}
                              value={this.state.pincode}
                              required={
                                [
                                  "Disqualified-B2C",
                                  "Pending",
                                  "Dead",
                                  "Dead - Attempts",
                                  "Follow-up",
                                  "Duplicate",
                                ].includes(this.state.lead_status)
                                  ? false
                                  : true
                              }
                              // disabled={this.state.isDisable}
                            />
                          </Form.Group>
                        </Col>

                        {this.state.isDisable ? (
                          <Col md={4}>
                            <Form.Group controlId="formLocation">
                              <Form.Label>Location</Form.Label>
                              <Form.Control
                                name="location"
                                placeholder="Enter Location"
                                onChange={this.handleChange}
                                value={this.state.location}
                                // disabled={this.state.isDisable}
                              />
                            </Form.Group>
                          </Col>
                        ) : (
                          <Col md={4}>
                            <Form.Group controlId="formLocation">
                              <Form.Label>Location</Form.Label>
                              <Form.Control
                                type="text"
                                name="location"
                                placeholder="Location"
                                onChange={this.handleChange}
                                value={this.state.location}
                              />
                            </Form.Group>
                          </Col>
                        )}
                      </>
                      <Col md={3}>
                        <Form.Group controlId="formGridCustomerType">
                          <Form.Label>You Are a ?...</Form.Label>
                          <Form.Control
                            as="select"
                            custom
                            onChange={this.handleCustomer}
                            name="customer_type"
                            value={this.state.customer_type}
                            required={
                              [
                                "Converted to RFQ-PRO",
                                "Converted to RFQ-Other B2B",
                                "Converted to RFQ-B2C",
                              ].includes(this.state.lead_status)
                                ? true
                                : false
                            }
                          >
                            <option value="">Select to Choose</option>
                            {this.state.typeArray.map((item, index) => {
                              return (
                                <option key={index} value={item.meta_value}>
                                  {item.meta_title}
                                </option>
                              );
                            })}
                          </Form.Control>
                        </Form.Group>
                      </Col>

                      <Col md={4}>
                        <Form.Group controlId="formEditcampaign">
                          <Form.Label>Campaign Details</Form.Label>
                          <Form.Control
                            name="campaign"
                            placeholder="Enter Campaign Details"
                            onChange={this.handleChange}
                            value={this.state.campaign}
                            // disabled={this.state.isDisable}
                          />
                        </Form.Group>
                      </Col>
                      <Col md={2}>
                        <Form.Group controlId="formUtmSource">
                          <Form.Label>UTM Source</Form.Label>
                          <Form.Control
                            name="utmsource"
                            value={this.state.utmSource}
                            disabled
                            // disabled={this.state.isDisable}
                          />
                        </Form.Group>
                      </Col>
                      <Col md={2}>
                        <Form.Group controlId="formUtmSource">
                          <Form.Label>UTM Medium</Form.Label>
                          <Form.Control
                            name="utmmedium"
                            value={this.state.utmMedium}
                            disabled
                            // disabled={this.state.isDisable}
                          />
                        </Form.Group>
                      </Col>

                      <Col sm={12}>
                        <Form.Group controlId="formBasicEmail">
                          <Form.Label>Message From Customer</Form.Label>
                          <Form.Control
                            placeholder="Message"
                            name="lead_message"
                            value={this.state.lead_message}
                            as="textarea"
                            rows={2}
                            onChange={this.handleChange}

                            // disabled={this.state.isDisable}
                          />
                          {/* <Form.Control.Feedback type="invalid">
                            Please Enter Some Message first
                          </Form.Control.Feedback> */}
                        </Form.Group>
                      </Col>
                    </Form.Row>
                  </Col>

                  {this.state.isShowRight ? (
                    <Col sm={this.state.call_attempt_count > 0 ? "4" : ""}>
                      <h5>Call Disposal - Post Call</h5>
                      <Form.Group
                        controlId="formBasicEmail"
                        // hidden={this.state.callSchedularDisable}
                        // hidden={this.state.callback_hidden}
                      >
                        <Form.Label>
                          Callback
                          {/* {
                                        this.state.startDateShow
                                        ?
                                        <span> ({this.state.startDateShow})</span>
                                        :
                                        null
                                        } */}
                        </Form.Label>
                        <DatePicker
                          showTimeSelect
                          dateFormat="MMMM d, yyyy h:mm:ss aa"
                          selected={this.state.startDate}
                          onChange={this.handleDateChange}
                          className="form-control"
                          locale="es"
                          minDate={new Date()}
                          minTime={this.state.minTime}
                          maxTime={moment().endOf("day").toDate()}
                          value={this.state.startDate}
                          required={this.state.lead_status == "Follow-up"}
                        />
                      </Form.Group>
                      {this.state.call_attempt_history.length > 0 ? (
                        <CallAttemptedHistoryLeads
                          call_attempt_history={this.state.call_attempt_history}
                        />
                      ) : null}
                      {this.state.call_attempt_count > 0 &&
                      this.state.call_attempt_count <= CallCount ? (
                        <div>
                          <div
                            className={`Call_Attempt_${this.state.call_attempt_count}`}
                          >
                            <Form.Group controlId="call_attempt_count">
                              <Form.Label>
                                Call Status {this.state.call_attempt_count} *
                              </Form.Label>
                              <Form.Control
                                as="select"
                                custom
                                name="call_status"
                                onChange={this.handleChangeForCallStatus}
                                required
                              >
                                <option value="">Select Call Status</option>
                                {this.state.call_status_array.map(
                                  (item, index) => {
                                    return (
                                      <option key={index} value={item.id}>
                                        {item.status}
                                      </option>
                                    );
                                  }
                                )}
                              </Form.Control>
                              <Form.Control.Feedback type="invalid">
                                Please Select the Call Status.
                              </Form.Control.Feedback>
                            </Form.Group>
                          </div>
                          <Form.Group controlId="formBasicEmail">
                            <Form.Label>Remarks *</Form.Label>
                            <Form.Control
                              placeholder="Remarks"
                              name="remarks"
                              value={this.state.remarks}
                              onChange={this.handleChange}
                              as="textarea"
                              rows={2}
                              // required
                            />
                            {/* Form.Control.Feedback type="invalid">
                              Please Enter Remark first.
                            </Form.Control.Feedback>< */}
                          </Form.Group>
                          {/* <Form.Group controlId="formBasicBuyingfor">
                            <Form.Label>Buying For</Form.Label>
                            <Form.Control
                              as="select"
                              custom
                              name="buying_for"
                              onChange={this.handleChange}
                              value={this.state.buying_for}
                              // required
                            >
                              <option value="0">Select Buying For</option>
                              <option value="Project Use">Project Use</option>
                              <option value="Reselling">Reselling</option>
                              <option value="Own construction">
                                Own construction
                              </option>
                            </Form.Control>
                     
                          </Form.Group> */}
                          {this.state.agentShow ? (
                            <Form.Group controlId="formBasicEmail">
                              <Form.Label>Transfer Account</Form.Label>
                              <Form.Control
                                as="select"
                                name="agent_id"
                                autoFocus
                                required
                                onChange={this.handleChange}
                                // value="155"
                                defaultValue={this.state.agent_id}
                              >
                                <option value="">Select User</option>
                                {this.state.agentsArray.map((item, index) => {
                                  return (
                                    <option key={index} value={item.id}>
                                      {item.name}
                                    </option>
                                  );
                                })}
                              </Form.Control>
                            </Form.Group>
                          ) : null}
                        </div>
                      ) : null}
                    </Col>
                  ) : null}
                </Form.Row>
              </Form>
            </Modal.Body>
          )}

          {this.state.childLeads.length > 0 ? (
            <Modal.Footer>
              <div
                className="mt-3"
                style={{
                  background: "rgb(187 211 243 / 95%)",
                  border: "1px solid black",
                }}
              >
                {/* <b>Child Leads Section </b> */}

                <div>
                  <Table>
                    <thead>
                      <tr>
                        <th>Secondary Lead Id</th>
                        <th>Products</th>
                        <th>Quantity</th>
                      </tr>
                    </thead>
                    <tbody>
                      {this.state.childLeads.map((childLead) => (
                        <tr
                          key={childLead.leadId}
                          style={{ borderTop: "2px solid black" }}
                        >
                          <td>
                            <input
                              type="checkbox"
                              checked={childLead.checked}
                              onChange={() =>
                                this.handleLeadIdChange(childLead.leadId)
                              }
                            />{" "}
                            {"  "}
                            <b>{childLead.leadId}</b>
                          </td>
                          <td>
                            {childLead.productDetails.map((productDetail) => (
                              <div key={productDetail.id} className="my-3">
                                <input
                                  type="checkbox"
                                  checked={productDetail.checked}
                                  onChange={() =>
                                    this.handleProductDetailChange(
                                      childLead.leadId,
                                      productDetail.id
                                    )
                                  }
                                />
                                {"  "}
                                <b>{productDetail.name}</b>
                              </div>
                            ))}
                          </td>
                          <td>
                            {childLead.productDetails.map((productDetail) => (
                              <div key={productDetail.id} className="my-3">
                                <b>{productDetail.quantity}</b>
                              </div>
                            ))}
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                </div>
              </div>
            </Modal.Footer>
          ) : null}
          {[
            "Converted to RFQ-PRO",
            "Converted to RFQ-Other B2B",
            "Converted to RFQ-B2C",
            "Converted to MQ",
            "Disqualified-B2C",
            "Dead-Call attempts",
            "Dead",
            "Duplicate",
          ].includes(this.props.lead_status) ? null : (
            <div className="m-3">
              {[
                "Converted to RFQ-PRO",
                "Converted to RFQ-Other B2B",
                "Converted to RFQ-B2C",
              ].includes(this.state.lead_status) ? (
                <div>
                  <Button
                    style={{ width: "150px" }}
                    variant="primary"
                    disabled={this.state.isSaveLoading}
                    id="submit"
                    onClick={!this.state.isSaveLoading ? this.submitForm : null}
                  >
                    {this.state.isSaveLoading ? (
                      <Spinner
                        animation="border"
                        size="sm"
                        style={{ width: "1.3rem", height: "1.3rem" }}
                      />
                    ) : (
                      "Convert to RFQ"
                    )}
                  </Button>
                </div>
              ) : (
                <Button
                  style={{ width: "100px" }}
                  variant="primary"
                  disabled={this.state.isSaveLoading}
                  id="submit"
                  onClick={!this.state.isSaveLoading ? this.submitForm : null}
                >
                  {this.state.isSaveLoading ? (
                    <Spinner
                      animation="border"
                      size="sm"
                      style={{ width: "1.3rem", height: "1.3rem" }}
                    />
                  ) : (
                    "Submit"
                  )}
                </Button>
              )}
              {this.state.showMqButton ? ( // Condition to Show Auto Convert MQ BUtton
                <Button
                  style={{ width: "150px", marginTop: "1rem" }}
                  variant="primary"
                  onClick={this.submitFormForMq}
                  id="submit"
                >
                  Auto Convert to MQ
                </Button>
              ) : null}
            </div>
          )}
        </Modal>

        {/* Child leads Modal Section */}
      </>
    );
  }
}

export default ApproveLead;
