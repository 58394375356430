import React from "react";
import { getUserDetails } from "../../Login/getLoginnedUserDetails";
import {
  Row,
  Col,
  Form,
  Button,
  Alert,
  Select,
  Spinner,
} from "react-bootstrap";
import Modal from "react-bootstrap/Modal";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Constants from "../../../Config";
import axios from "axios";
import { Redirect } from "react-router-dom";
import BusinessProfile2 from "./BusinessProfile2";
import { findAllByDisplayValue } from "@testing-library/react";
const apiUrl = Constants.API_URL;
const b2bApiUrl = Constants.B2B_API_URL;

class BusinessProfile extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      business_type: [],
      business_group: "",
      customer_id: "",
      customer_name: "",
      customer_email: "",
      customer_company: "",
      slug: "",
      legalStatus: "",
      agentArray: "",
      pin_code: "",
      billing_city: "",
      billing_zone: "",
      billing_city_id: "",
      billing_state_id: "",
      zone_id: "",
      isLoading: false,
      checkedBusinessGroup: "",
      checkedBusinessType: [],
      checkedSubBusinessType: [], // add new state 
      businessType: [],
      is_multiselect_search: false,
      address_1: "",
      address_2: "",
      slug_exists: false,
      gstapplied: 0,
      legalStatus_id: "",
      gst_number: "",
      business_parent_category_array: [],
      sub_category: [],
      selected_category_array: [],
      business_category_array: [],
      business_category_array_org: [],
      keyword: "",
      show_business_category: false,
      others: false,
      category_array: [],
      business_type_other: "",
      is_error: false,
      is_redirect: false,
      message: "",
      gstin: "",
      gstError: false,
      gstin_applied: false,
      hide: true,
      showHideDemo1: false,
      showbutton: false,
      customerCompany: "",
      showbutton2: false,
      show: true,
      validButton: "",
      validInput: "",
      error: "",
      customer_group_id: "2",
      validated: false,
      pro_pricing_type: "",
      errorMessage: "",
      wrong_phone: false,
      profile_type: "",
      isCompanyExist: false,
      yrs_in_business: "",
      employee_count: "",
      // turnover: 0,
      turnover: "",
      credit_limit: 0,
      projects_done: 0,
      credit_period: 0,
      pan_card: '',
      // add new four keys
      utm_source: "",
      utm_medium: "",
      utm_campaign: "",
      utm_content: ""
      // businessgroupmsg:""
    };
  }

  componentDidMount() {
    this.getLegalStatus();
    this.getAgent();
    this.getBusinessGroup();
    this.getBusinessParentCategory();
    this.getBusinessCategory();
    this.getBusinessAccountDetail();
    this.selectCategoryArray();
    // this.checkGSTValidation();

  }

  closeModal = () => {
    this.setState({
      // showbutton: true,
      show: false,
    });
    window.location.reload();
  };

  LegalStatus = (e) => {
    this.setState({
      legalStatus_id: e.target.value,
    });
  };
  getAgentByID = (e) => {
    this.setState({
      agentid: e.target.value,
    });
  };

  addressChange1 = (e) => {
    this.setState({
      address_1: e.target.value,
    });
  };

  setCustomerName = (e) => {
    this.setState({
      customer_name: e.target.value,
    });
  };

  setCustomerEmail = (e) => {
    this.setState({
      customer_email: e.target.value,
    });
  };

  addressChange2 = (e) => {
    this.setState({
      address_2: e.target.value,
    });
  };

  // handleChange = (e) => {
  //     this.setState({
  //         [e.target.name]:e.target.value,
  //         gstapplied:false
  //     });
  //     this.validateGSTNumber(e.target.value);
  // }

  // gstappliedChange = (e) => {

  //     if(e.target.checked) {
  //         this.setState({
  //             gstin:'',
  //             gstError:true
  //         });
  //     }
  //     this.setState({
  //         gstapplied:e.target.checked,
  //     });
  // }

  selectCategoryArray = () => {
    let selectedCategory = [];
    let category_array = this.state.category_array;
    if (category_array.length > 0) {
      for (let i = 0; i < category_array.length; i++) {
        selectedCategory.push({
          label: category_array[i].name,
          value: category_array[i].id,
        });
      }
    }
    this.setState({
      selected_category_array: selectedCategory,
    });
  };

  getBusinessParentCategory = () => {
    const options = {
      method: "POST",
      data: { busines_type: 2 },
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${this.props.getUserDetails.token}`,
      },
    };
    return axios(apiUrl + "/get-parentcategory-by-businesstype", options)
      .then((data) => {
        this.setState({
          business_parent_category_array: data.data.data,
          is_multiselect_search: true,
        });
      })
      .catch((error) => console.log(error));
  };
  getSubCategory = (catID) => {
    const options = {
      method: "POST",
      data: { id: catID },
    };
    return axios(apiUrl + "/get-subcategory-by-parentid", options)
      .then((data) => {
        this.setState({
          sub_category: data.data.data,
          is_subcategory: true,
        });
      })
      .catch((error) => console.log(error));
  };

  getBusinessAccountDetail = () => {
    const options = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${this.props.getUserDetails.token}`,
      },

      data: { business_account_id: "1993" },
      //   data: { business_account_id: businessID },
    };
    return axios(apiUrl + "/business-account", options)
      .then((data) => {
        let businessData = data.data.data[0];
        this.setState({
          // businessData:businessData,
          category_array: businessData.category_array,
          // gstin: businessData.gstin,
          // gstin_applied:businessData.gstin_applied ? "1" : false,
          show: true,
        });
      })
      .catch((error) => console.log(error));
  };

  getBusinessCategory = () => {
    const options = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${this.props.getUserDetails.token}`,
      },

      data: { busines_type: 2, keyword: "" },
    };
    return axios(apiUrl + "/get-category-by-businesstype", options)
      .then((data) => {
        this.setState({
          business_category_array: data.data.data,
          business_category_array_org: data.data.data,
        });
      })
      .catch((error) => console.log(error));
  };

  selectOption = (e) => {
    let mainCategoryArray = this.state.business_category_array;
    let filteredCategory = mainCategoryArray.filter((item) => {
      return item.id != e.target.id;
    });
    let selectedCategory = this.state.selected_category_array;
    selectedCategory.push({
      label: e.target.title,
      value: e.target.id,
    });
    this.setState({
      selected_category_array: selectedCategory,
      business_category_array: filteredCategory,
    });
  };

  removeCategory = (e) => {
    let name = e.currentTarget.getAttribute("name");
    let mainCategoryArray = this.state.business_category_array;
    mainCategoryArray.push({
      name: name,
      id: e.currentTarget.id,
    });
    mainCategoryArray.sort((a, b) => a.name.localeCompare(b.name));
    let selectedCategoryArray = this.state.selected_category_array;
    let filteredCategory = selectedCategoryArray.filter((item) => {
      return item.value != e.currentTarget.id;
    });
    this.setState({
      selected_category_array: filteredCategory,
      business_category_array: mainCategoryArray,
    });
  };

  handleCategoryVisibility = () => {
    this.setState({
      show_business_category: true,
    });
  };

  hideCategoryVisibility = (e) => {
    if (
      e.target.className === "options" ||
      e.target.className === "form-control"
    ) {
    } else {
      this.setState({
        show_business_category: false,
      });
    }
  };

  getBusinessCategoryBySearch = (e) => {
    let keyword = e.target.value;
    this.setState({
      keyword: keyword,
    });
    const options = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${this.props.getUserDetails.token}`,
      },

      data: { busines_type: 2, keyword: keyword },
    };
    return axios(apiUrl + "/get-category-by-businesstype", options)
      .then((data) => {
        let result = data.data.data;
        let selectedCategory = this.state.selected_category_array;
        let selectArray = selectedCategory.map((selectedArray) => {
          return selectedArray.value;
        });
        let arrayAfterDeselect = [];
        result.map((item) => {
          if (selectArray.includes(item.id.toString()) === false) {
            arrayAfterDeselect.push({
              id: item.id,
              name: item.name,
            });
          }
        });
        this.setState({
          business_category_array: arrayAfterDeselect,
        });
      })
      .catch((error) => console.log(error));
  };

  selectCategory = (e) => {
    let parent_id = e.target.getAttribute("data-item");
    let selectedCategoryArray = this.state.selected_category_array;
    let checkedCondition = e.target.checked;
    if (checkedCondition === true) {
      if (parent_id) {
        let categoryIndex = this.state.business_category_array_org.filter(
          (el) => el.id == parent_id
        );
        let categoryName = categoryIndex[0].name;
        var indexOfStevie = selectedCategoryArray.findIndex(
          (i) => i.value === parent_id
        );
        if (indexOfStevie == -1) {
          selectedCategoryArray.push({
            label: categoryName,
            value: parent_id,
          });
        }
      }
      selectedCategoryArray.push({
        label: e.target.id,
        value: e.target.value,
      });
      this.setState({
        selected_category_array: selectedCategoryArray,
      });
    } else {
      let filteredCategory = selectedCategoryArray.filter((item) => {
        return item.value != e.target.value;
      });
      this.setState({
        selected_category_array: filteredCategory,
      });
    }
  };

  getBusinessGroup = () => {
    this.setState(
      {
        isLoading: true,
      },
      () => {
        const options = {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${this.props.getUserDetails.token}`,
          },

          // data:{id:this.props.businessData.id}
          data: { id: 1949 },
        };
        return axios(apiUrl + "/get-business-group", options)
          .then((data) => {
            this.setState(
              {
                businessGroup: data.data.data,
                isLoading: false,
              },
              () => {
                let selectedArray = this.state.businessGroup.filter((item) => {
                  return item.selected === true;
                });
                if (selectedArray.length > 0) {
                  if (selectedArray[0].business_type.length > 0) {
                    let selectedBusinessType =
                      selectedArray[0].business_type.filter((item) => {
                        return item.selected === true;
                      });
                    let selectedBusinessTypeID = selectedBusinessType.map(
                      (item) => item.id
                    );
                    this.setState({
                      checkedBusinessGroup: selectedArray[0].id,
                      businessType: selectedArray[0].business_type,
                      checkedBusinessType: selectedBusinessTypeID,
                    });
                  } else {
                    this.setState({
                      checkedBusinessGroup: selectedArray[0].id,
                      others: true,
                      business_type_other: selectedArray[0].value,
                    });
                  }
                } else {
                  this.setState({
                    is_multiselect_search: false,
                  });
                }
              }
            );
          })
          .catch((error) => console.log(error));
      }
    );
  };
  handleBusinessGroup = (e) => {
    let id = e.target.id;
    if (id == 100) {
      this.setState({
        others: true,
        checkedBusinessGroup: id,
        checkedBusinessType: [],
        is_multiselect_search: false,
      });
    } else {
      if (id != 2) {
        this.setState({
          is_multiselect_search: false,
        });
      } else {
        this.setState({
          is_multiselect_search: true,
        });
      }
      this.setState(
        {
          others: false,
          checkedBusinessType: [],
          business_type_other: "",
        },
        () => {
          this.setState(
            {
              checkedBusinessGroup: id,
            },
            () => {
              let selectedBusinessGroupID = this.state.businessGroup.findIndex(
                (item) => {
                  return item.id == this.state.checkedBusinessGroup;
                }
              );
              let selectedBusinessType =
                this.state.businessGroup[selectedBusinessGroupID].business_type;
              this.setState({
                businessType: selectedBusinessType,
              });
            }
          );
        }
      );
    }
  };

  handleBusinessType = (e) => {
    let selectedArray = this.state.checkedBusinessType;
    if (e.target.checked) {
      selectedArray.push(parseInt(e.target.id));
      this.setState({
        checkedBusinessType: selectedArray,
      });
    } else {
      let filteredArray = selectedArray.filter((item) => {
        return item != e.target.id;
      });
      this.setState({
        checkedBusinessType: filteredArray,
      });
    }
  };

  // add new handle bar sub_business_type
  handleSubBusinessType = (e) => {
    let selectedArray = this.state.checkedSubBusinessType;
    if (e.target.checked) {
      selectedArray.push(parseInt(e.target.id));
      this.setState({
        checkedSubBusinessType: selectedArray,
      });
    } else {
      let filteredArray = selectedArray.filter((item) => {
        return item != e.target.id;
      });
      this.setState({
        checkedSubBusinessType: filteredArray,
      });
    }
  }

  checkCompanyAlreadyExists = () => {
    const options = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${this.props.getUserDetails.token}`,
      },

      // data:{slug:this.state.slug}
      data: { slug: this.state.slug },
    };
    return axios(apiUrl + "/check-company-exits", options)
      .then((data) => {
        const result = data.data.data;
        const exists = result > 0 ? false : true;
        this.setState({
          slug_exists: exists,
        });
      })
      .catch((error) => {
        console.log(error);
      });
  };

  checkSlugExists = (e) => {
    this.setState(
      {
        slug: e.target.value,
      },
      () => {
        this.checkCompanyAlreadyExists();
      }
    );
  };

  getAgent = () => {
    const requestoption = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${this.props.getUserDetails.token}`,
      },

      data: {
        group_id: 56,
      },
    };
    return axios(apiUrl + "/get-agents-by-groupid", requestoption)
      .then((data) => {
        this.setState({
          agentArray: data.data.data,
        });
      })
      .catch((error) => console.log(error));
  };

  getLegalStatus = () => {
    const options = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${this.props.getUserDetails.token}`,
      },

      data: { meta_id: 16 },
    };
    return axios(apiUrl + "/meta-value", options)
      .then((data) => {
        this.setState({
          legalStatus: data.data.data,
        });
      })
      .catch((error) => console.log(error));
  };

  handleChangePincode = (e) => {
    this.setState(
      {
        pin_code: e.target.value,
      },
      () => {
        if (this.state.pin_code.length == 6) {
          const requestoption = {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${this.props.getUserDetails.token}`,
            },

            data: {
              pincode: this.state.pin_code,
            },
          };
          return axios(b2bApiUrl + "/search-by-pincode", requestoption)
            .then((data) => {
              this.setState({
                billing_city: data.data.data[0].city,
                billing_zone: data.data.data[0].state,
              });
              this.validateGSTNumber(this.state.gstin);
            })
            .catch((error) => console.log(error));
        }
      }
    );
  };

  checkGSTValidation = () => {
    if (!this.state.gstin_applied) {
      if (this.state.gstin.length > 0) {
        this.validateGSTNumber(this.state.gstin);
        this.setState({
          gstError: true,
        });
      }
    } else if (this.state.gstin_applied == 1) {
      this.setState({
        gstError: true,
      });
    }
  };

  validateGSTNumber = (gstin) => {
    if (gstin.length > 0) {
      const options = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${this.props.getUserDetails.token}`,
        },

        data: { gstin: gstin, state: this.state.billing_zone },
      };
      return axios(apiUrl + "/verifyGSTNumber", options)
        .then((data) => {
          if (data.data.message == "success") {
            this.setState({
              gstError: true,
            });
          } else {
            this.setState({
              gstError: false,
            });
          }
        })
        .catch((error) => {
          console.log(error);
          this.setState({
            gstError: false,
          });
        });
    }
  };
  // Function below is to get state_id, city_id, and shop_state_id from "child" businessProfile2
  getId = (state, city, shop) => {
    this.setState({
      billing_state_id: state,
      billing_city_id: city,
      zone_id: shop,
    });
  };
  getProfileType = (e, customerGroupId) => {
    this.setState(
      {
        profile_type: e,
        customer_group_id: customerGroupId,
      },
      () => {
        if (this.state.profile_type == "B2C") {
          this.setState({
            checkedBusinessGroup: "",
            checkedBusinessType: [],
            business_type_other: "",
          });
        }
      }
    );
  };
  handleSubmit = (e) => {
    e.preventDefault();
    this.setState({
      is_loading: true,
      validated: true,
    });
    const requestoption = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${this.props.getUserDetails.token}`,
      },
      data: {
        customer_id: this.state.customer_id,
        customer_mobile: this.state.customer_mobile,
        customer_name: this.state.customer_name,
        customer_email: this.state.customer_email.trim(),
        company_name: this.state.customer_company,
        legal_status: this.state.legalStatus_id,
        slug: this.state.slug,
        gstin: this.state.gst_number,
        gstin_applied: this.state.gstapplied,
        address1: this.state.address_1,
        address2: this.state.address_2,
        pin_code: this.state.pin_code,
        city: this.state.billing_city,
        state: this.state.billing_zone,
        state_id: this.state.billing_state_id.toString(),
        city_id: this.state.billing_city_id.toString(),
        zone_id: this.state.zone_id.toString(),
        country_id: "99",
        agent_id: this.state.agentid ? this.state.agentid : "",
        business_type: this.state.checkedBusinessType,
        // add new sub_business_type key
        sub_business_type: this.state.checkedSubBusinessType,
        business_group: this.state.checkedBusinessGroup,
        business_type_other: this.state.business_type_other,
        selected_category_array: this.state.selected_category_array,
        customer_group_id: this.state.customer_group_id,
        pro_pricing_type: this.state.pro_pricing_type,
        profile_type: this.state.profile_type,
        yrs_in_business: Number(this.state.yrs_in_business),
        employee_count: this.state.employee_count,
        turnover: this.state.turnover,
        credit_limit: Number(this.state.credit_limit),
        credit_period: Number(this.state.credit_period),
        projects_done: Number(this.state.projects_done),
        utm_source: this.state.utm_source,
        utm_medium: this.state.utm_medium,
        utm_campaign: this.state.utm_campaign,
        utm_content: this.state.utm_content,
        panNo: this.state.pan_card
      },
    };

    axios(apiUrl + "/add-business-profile", requestoption)
      .then((res) => {
        this.setState(
          {
            message: "Profile Created Successfully",
            is_error: false,
            is_loading: true,
            //
          },
          () => {
            setTimeout(() => {
              this.setState({
                message: "",
                is_error: false,
                is_redirect: true,
              });
            }, 3000);
          }
        );
      })
      .catch((error) => {
        this.setState(
          {
            message: error.response.data.message,
            is_error: true,
            is_loading: false,
          },
          () => {
            setTimeout(() => {
              this.setState({
                message: "",
                is_error: false,
              });
            }, 5000);
          }
        );
      });

  };

  getPhoneValue = (e) => {
    // e.persist();
    if (e.target.value.length === 10) {
      this.setState({
        customer_mobile: e.target.value,
        wrong_phone: false,
      });
      axios({
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${this.props.getUserDetails.token}`,
        },

        url: apiUrl + "/get-customer-detail-by-phone",
        data: { phone: e.target.value },
      })
        .then((data) => {
          if (data.data.data[0]) {
            this.setState({
              customer_name: data.data.data[0].customerName,
              customer_email: data.data.data[0].email,
              customer_id: data.data.data[0].customer_id,
              customer_mobile: data.data.data[0].telephone,
              customerCompany: data.data.data[0].customerCompany,
              isCompanyExist: data.data.data[0].isCompanyExist,
              wrong_phone: false,
            });
            if (!data.data.data[0].isCompanyExist) {
              this.setState({
                showHideDemo1: true,
                showbutton2: true,
              });
            }
          } else {
            this.setState({
              customer_mobile: e.target.value,
              wrong_phone: true,
            });
          }
        })

        .catch((error) => {
          this.setState({
            showbutton: true,
          });
        });
    } else {
      this.setState({
        wrong_phone: true,
      });
    }
  };

  addProfile = (e) => {
    e.preventDefault();
    axios({
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${this.props.getUserDetails.token}`,
      },

      url: apiUrl + "/get-customer-detail-by-phone",
      data: {
        phone: this.state.customer_mobile,
        customer_name: this.state.customer_name,
        customer_email: this.state.customer_email.trim(),
      },
    })
      .then((data) => {
        this.setState({
          customer_id: data.data.data[0].customer_id,
          showHideDemo1: true,
          showbutton: false,
          // showbutton2: true
        });
      })
      .catch((error) => {
        console.log(error);
        this.setState(
          {
            errorMessage: error.response.data.message,
          },
          () => {
            setTimeout(() => {
              this.setState({
                errorMessage: "",
              });
            }, 3000);
          }
        );
      });
    this.setState({
      showbutton2: true,
    });
  };

  message = () => {
    alert(
      "No." +
      " " +
      this.state.customer_mobile +
      " already exist by name " +
      this.state.customer_name +
      " and Company: " +
      this.state.customerCompany
    );
    window.location.reload();
    // return(
    //     <Modal show={this.state.show} onHide={this.closeModal}>
    //         <Modal.Header closeButton style={{padding:"0px 5px", border:"none"}}></Modal.Header>
    //         <Modal.Body className="business_approve_process">
    //             No. <b>{this.state.customer_mobile}</b> already exist by name: <b>{this.state.customer_name}</b> and Company: <b>{this.state.company}</b>
    //         </Modal.Body>
    //         <Modal.Footer>
    //             <Button onClick={this.closeModal}>Close</Button>
    //         </Modal.Footer>
    //     </Modal>
    // )
  };

  customer_address1 = (value) => {
    this.setState({
      address_1: value,
    });
  };
  customer_address2 = (value) => {
    this.setState({
      address_2: value,
    });
  };

  legal_status2 = (value) => {
    this.setState({
      legalStatus_id: value,
    });
  };

  slug2 = (value) => {
    this.setState({
      slug: value,
    });
  };

  gstnumber2 = (value) => {
    this.setState({
      gst_number: value,
    });
  };

  gstapplied2 = (value) => {
    this.setState({
      gstapplied: value,
    });
  };
  pincode2 = (value) => {
    this.setState({
      pin_code: value,
    });
  };

  city2 = (value) => {
    this.setState({
      billing_city: value,
    });
  };

  state2 = (value) => {
    this.setState({
      billing_zone: value,
    });
  };
  agent2 = (value) => {
    this.setState({
      agentid: value,
    });
  };
  business_type2 = (value) => {
    this.setState({
      checkedBusinessType: value,
    });
  };
  // checkedSubBusinessType
  sub_business_type2 = (value) => {
    this.setState({
      checkedSubBusinessType: value,
    });
  };
  business_group2 = (value) => {
    this.setState({
      checkedBusinessGroup: value,
    });
  };
  business_type_other2 = (value) => {
    this.setState({
      business_type_other: value,
    });
  };
  selected_category_array2 = (value) => {
    this.setState({
      selected_category_array: value,
    });
  };

  customer_company2 = (value) => {
    this.setState({
      customer_company: value,
    });
  };

  gstError2 = (value) => {
    this.setState({
      gstError: value,
    });
  };

  b2bpricing = (value) => {
    this.setState({
      b2bprice: value,
    });
  };
  getPricingtype = (pricing_type) => {
    this.setState({
      pro_pricing_type: pricing_type,
    });
  };
  getUTMSource = (utm_source_type) => {
    this.setState({
      utm_source: utm_source_type
    })
  }
  getUTMMedium = (utm_medium_type) => {
    this.setState({
      utm_medium: utm_medium_type
    })
  }
  handleProfile = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };
  getPancard = (pan_card) => {
    this.setState({
      pan_card
    })
  }
  handleYear = (date) => {
    this.setState({
      yrs_in_business: date,
    });
  };

  render() {
    const { is_redirect, showHideDemo1 } = this.state;
    if (this.state.is_redirect) {
      return <Redirect to="/business-accounts" />;
    }
    return (
      <Row>
        <Col md="12">
          {this.state.is_error.length > 0 ? (
            <Alert
              className="displayMessageArea"
              variant={this.state.is_error ? "danger" : "success"}
            >
              {this.state.is_error}
            </Alert>
          ) : null}
          {this.state.message.length > 0 ? (
            <Alert
              className="displayMessageArea"
              variant={this.state.is_error ? "danger" : "success"}
            >
              {this.state.message}
            </Alert>
          ) : null}

          <Form
            onClick={(e) => this.hideCategoryVisibility(e)}
            noValidate
            validated={this.state.validated}
            onSubmit={this.handleSubmit}
            enctype="multipart/form-data"
          >
            {this.state.errorMessage ? (
              <Alert
                className="displayMessageArea"
                variant={this.state.errorMessage ? "danger" : "success"}
              >
                {this.state.errorMessage}
              </Alert>
            ) : null}
            <div className="card">
              <div className="headingPadding">
                <h3 className="billingAdressHeader">Profile </h3>
                <Row>
                  <Col md="4">
                    <Form.Group controlId="formGridCustomerMobile" className="mb-0">
                      <Form.Label>Mobile</Form.Label>
                      <Form.Control
                        name="customer_mobile"
                        className="listingFontSizeColor"
                        type="hidden"
                        onChange={this.handleChange}
                        value={this.state.customer_id}
                        maxLength={10}
                      />
                      <Form.Control
                        className={this.state.wrong_phone ? "error" : "listingFontSizeColor"}
                        name="customer_mobile"
                        type="number"
                        maxLength={10}
                        placeholder="Customer Mobile"
                        onChange={this.getPhoneValue}
                        required
                      />
                    </Form.Group>
                  </Col>
                  <Col md="4">
                    <Form.Group controlId="formGridCustomerName" className="mb-0">
                      <Form.Label>User Name</Form.Label>
                      <Form.Control
                        name="customer_name"
                        className="listingFontSizeColor"
                        type="text"
                        placeholder="Customer Name"
                        defaultValue={this.state.customer_name}
                        onChange={this.setCustomerName}
                        required
                      />
                    </Form.Group>
                  </Col>
                  <Col md="4">
                    <Form.Group controlId="formGridCustomerName" className="mb-0">
                      <Form.Label>Email</Form.Label>
                      <Form.Control
                        name="customer_email"
                        className="listingFontSizeColor"
                        type="email"
                        placeholder="Customer Email"
                        defaultValue={this.state.customer_email}
                        onChange={this.setCustomerEmail}
                        required
                      />
                    </Form.Group>
                  </Col>
                </Row>
              </div>

              {
                this.state.isCompanyExist ? (
                  this.message()
                ) :
                  <Row
                    style={{ justifyContent: "right", margin:"16px"}}
                  >
                    {this.state.showbutton && !this.state.wrong_phone ? (
                      <button
                        className="btn btn-primary downLoadButton"
                        onClick={this.addProfile}
                      >
                        Add User
                      </button>
                    ) : null}
                  </Row>

                //     :null
                // }
              }
            </div>

            {showHideDemo1 && (
              <BusinessProfile2
                customer_address1={this.customer_address1}
                customer_address2={this.customer_address2}
                customer_company2={this.customer_company2}
                legal_status2={this.legal_status2}
                slug2={this.slug2}
                gstnumber2={this.gstnumber2}
                gstapplied2={this.gstapplied2}
                pincode2={this.pincode2}
                city2={this.city2}
                state2={this.state2}
                agent2={this.agent2}
                business_type2={this.business_type2}
                sub_business_type2={this.sub_business_type2}
                business_group2={this.business_group2}
                business_type_other2={this.business_type_other2}
                selected_category_array2={this.selected_category_array2}
                gstError2={this.gstError2}
                b2bpricing={this.b2bpricing}
                getId={this.getId}
                getPricingtype={this.getPricingtype}
                getProfileType={this.getProfileType}
                handleProfile={this.handleProfile}
                handleYear2={this.handleYear}
                getPancard={this.getPancard}
                getUTMSource={this.getUTMSource}
                getUTMMedium={this.getUTMMedium}
              />
            )}

            <div>
              <Row style={{ float: "right" }}>
                {this.state.showbutton2 ? (
                  <Button
                    variant="primary"
                    type="submit"
                    className="mr-3 mb-3 float-right"
                    disabled={this.state.is_loading ? true : false}
                  >
                    {this.state.is_loading ? (
                      <Spinner animation="border" role="status">
                        <span className="sr-only">Loading...</span>
                      </Spinner>
                    ) : (
                      "Create Profile"
                    )}
                  </Button>
                ) : null}
              </Row>
            </div>
          </Form>
        </Col>
      </Row>
    );
  }
}
export default BusinessProfile;
