import React, { Component } from "react";

import { getUserDetails } from "../../Login/getLoginnedUserDetails";
import axios from "axios";

import { Form } from "react-bootstrap";
import constants from "../../../Config";
// import Daterangepicker from "../../Dashboard/Common/Daterangepicker";
import DateRangeNew from "./DateRangeNew";
import "./lead-table.css";

const apiUrl = constants.API_URL;
export class Account extends Component {
  constructor(props) {
    super(props);
    this.state = {
      date_from: "",
      date_to: "",
      leadStatusArray: [],
      leadTypeArray: [],
      agentsArray: [],
      date_type: "",
      quote_type: "",
      apiData: [],

      //   leadStatus: "",
      agent: "",
      account_type: ["agent"],
      colArray: [],
      rowTotalArray: [],
    };
  }
  componentDidMount() {
    this.getAllAgents();
    this.getAccounts();
  }
  getAllAgents = () => {
    let loginedUserDetails = JSON.parse(localStorage.getItem("userData"));
    const options = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${loginedUserDetails.token}`,
      },
      data: {
        agent_list: "true",
        id: [54],
        showAll: "true",
        slug: "",
      },

      // data: { logined_user_id: getUserDetails.user_id },
    };
    return axios(apiUrl + "/get-pre-sales-agents", options)
      .then((data) => {
        this.setState({
          agentsArray: data.data.data,
        });
      })
      .catch((error) => console.log(error));
  };
  getAccounts = () => {
    let loginedUserDetails = JSON.parse(localStorage.getItem("userData"));
    const options = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${loginedUserDetails.token}`,
      },

      data: {
        type: this.state.account_type,
        // key: this.state.quote_type,

        date_from: this.state.date_from,
        date_to: this.state.date_to,
      },
    };
    return axios(apiUrl + "/widget-my-accounts", options)
      .then((data) => {
        let apiData = data.data.data[0];
        Object.values(apiData).map((item) => {
          delete item[0].agent_id;
        });
        // let columnData = [];
        let rowTotalArray = [];
        //   let colArray = [];
        //   columnData = Object.keys(Object.values(Object.values(apiData)[0])[0]);
        //   columnData.splice(0, 1);
        let holder = {};

        Object.values(apiData).map((item, i) => {
          Object.keys(item[0]).map((d) => {
            if (holder.hasOwnProperty(d)) {
              holder[d] = holder[d] + item[0][d];
            } else {
              holder[d] = item[0][d];
            }
          });
        });

        holder.agent = "Total"; //"assigning total key for row"
        rowTotalArray.push(holder);
        apiData["Total"] = rowTotalArray;

        this.setState({
          apiData,
          // rowTotalArray
          // colArray,
        });
        // colArray = [];
      })
      .catch((err) => {
        console.log(err);
      });
  };

  filter = (e) => {
    this.setState({
      rowValue: e.target.value,
    });
  };
  handleDate = (e) => {
    this.setState({
      date_type: e.target.value,
    });
  };
  filterByQuotetype = (e) => {
    this.setState({
      quote_type: e.target.value,
    });
  };

  render() {
    const setDateRange = (data) => {
      this.setState(
        {
          date_from: data.start,
          date_to: data.end,
        },
        () => {
          this.getAccounts();
        }
      );
    };

    return (
      <>
        <div className="p-5 my-5 rounded border border-5 border-dark" id="table-padding-mobile">
          {/* filter section  */}
          <div className="row mb-5 justify-content-between">
            <div className="col-6 col-md-3">
              <div className="quote_title_heading"
                style={{
                  fontWeight: "700",
                  fontSize: "17px",
                  padding: "0.5rem",
                  borderRadius: "1rem",
                  textAlign: "center",
                  backgroundColor: "#B0B0B0",
                  boxShadow: '0 5px 10px rgba(0, 0, 0, 0.8)'
                }}
              >
                Account Assigned Dashboard
              </div>
            </div>

            <div className="col-6 col-md-3" >
              <div
                style={{
                  width: "100%",
                  background: "#fff",
                  cursor: "pointer",
                  border: "1px solid #ccc",
                  padding: "6px 10px 5px 17px",
                  marginLeft: "20px",
                }}
              >
                <DateRangeNew
                  label={"ALL DATA"}
                  // allData={true}
                  setDateRange={setDateRange}
                ></DateRangeNew>
              </div>
            </div>
          </div>

          {/* table section starts from below */}
          <div className="mobile-table-scroll">
            <table className="rfqtable striped bordered hover">
              <thead className="">
                {/* {this.state.} */}
                <th className="tabledef  sticky-col first-col agent">Agents</th>
                <th className="bodyColor">Total Accounts</th>
                <th className="bodyColor">Professional</th>
                <th className="bodyColor">Building Product Re-seller</th>
                <th className="bodyColor">Corporate Buyer</th>
                <th className="bodyColor">Other B2B</th>
                <th className="bodyColor2">Transacted</th>
                <th className="bodyColor2">No Transaction</th>
              </thead>
              <tbody>
                {Object.keys(this.state.apiData).map((item, i) => {
                  return (
                    <tr>
                      {Object.values(this.state.apiData[item][0]).map(
                        (data, i) => {
                          // return (
                          //   <td className="tabledef">
                          //     {data != null ? data : "Unassigned"}
                          //   </td>
                          // );
                          if (i == 0) {
                            return (
                              <td className="tabledef sticky-col first-col">
                                {data != null ? data : "Unassigned"}
                              </td>
                            );
                          } else {
                            return <td className="tabledef"> {data != null ? data : "Unassigned"}</td>;
                          }
                        
                        }
                      )}
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        </div>
      </>
    );
  }
}

export default Account;
