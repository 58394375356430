import React,{useState} from 'react';
import DateRangePicker from 'react-bootstrap-daterangepicker';
import moment from 'moment';
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap-daterangepicker/daterangepicker.css';

const Daterangepicker =(props) =>{
    const {setDateRange} = props;
    const [state, setState] = useState({
        start: moment().subtract(props.defaultDays?props.defaultDays:29, 'days'),
        end: moment(),
    });
    const { start, end } = state;
    const handleCallback = (start, end) => {
        setState({ start, end });
        const start_date = moment(start).format("YYYY-M-D");
        const end_date = moment(end).format("YYYY-M-D");
        setDateRange({ start:start_date, end:end_date });
    };
    
    // const label =  moment(start).format('D MMM YYYY') + ' - ' + moment(end).format('D MMM YYYY');
    const label =  start.format('D MMM YYYY') + ' - ' + end.format('D MMM YYYY');
    return(
        <>
                    <DateRangePicker
                        initialSettings={{
                            startDate: start.toDate(),
                            endDate: end.toDate(),
                        ranges: {
                            Today: [moment().toDate(), moment().toDate()],
                            Yesterday: [
                            moment().subtract(1, 'days').toDate(),
                            moment().subtract(1, 'days').toDate(),
                            ],
                            'Last 7 Days': [
                            moment().subtract(6, 'days').toDate(),
                            moment().toDate(),
                            ],
                            'Last 30 Days': [
                            moment().subtract(29, 'days').toDate(),
                            moment().toDate(),
                            ],
                            'This Month': [
                            moment().startOf('month').toDate(),
                            moment().toDate(),
                            // moment().endOf('month').toDate(),
                            ],
                            'Last Month': [
                            moment().subtract(1, 'month').startOf('month').toDate(),
                            moment().subtract(1, 'month').endOf('month').toDate(),
                            ],
                        },
                        }}
                        onCallback={handleCallback}
                    >
                            <div>
                                <span className='headingLabel'>{label}</span>
                            </div>
                        
                    </DateRangePicker>
          
        </>
    );
}
export default Daterangepicker