import React from "react";
import { Navbar, Dropdown, Nav, NavDropdown, Row, Col } from "react-bootstrap";
import { Link } from "react-router-dom";
import axios from "axios";
import Constants from "../../../Config";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import history from "../../../helper/history";
import "./headerStyle.css";
import logoMobile from  "../../../asset/Images/logo-mobile.svg"
const apiUrl = Constants.API_URL;

class Header extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      leftNav: [],
      isExpanded: false,
      itemId: "",
      screenWidth: window.innerWidth
    };
    this.updateWidth = this.updateWidth.bind(this);
  }
  // componentDidMount() {
  //   this.getNavbar();
  // }
  componentDidMount() {
    this.getNavbar();
    window.addEventListener('resize', this.updateWidth);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateWidth);
  }

  updateWidth() {
    this.setState({
      screenWidth: window.innerWidth
    });
  }
  // removeCreatePO = (nav) => {
  //   nav.modules.map((he) => {

  //     const removeIndex = header.findIndex(
  //       (item) => item.module_name === "Add PO" && item.module_slug === "createPO"
  //       );

  //       // If the object is found, remove it from the array
  //       if (removeIndex !== -1) {
  //         header = header.filter((item, index) => index !== removeIndex);
  //       }
  //     })

  //   return header;
  // };
  // sortHeaders = (leftNav) => {
  //   let quoteIndex = -1;
  //   let toolsIndex = -1;

  //   for (let i = 0; i < leftNav.length; i++) {
  //     if (leftNav[i].group_name === "Quote") {
  //       quoteIndex = i;
  //     } else if (leftNav[i].group_name === "Tools") {
  //       toolsIndex = i;
  //     }
  //   }

  //   // Find the index of "PO" section
  //   const poIndex = leftNav.findIndex((item) => item.group_name === "PO");

  //   // If "Quote" and "Tools" are present and "PO" section is found, move "PO" after "Quote" and before "Tools"
  //   if (quoteIndex !== -1 && toolsIndex !== -1 && poIndex !== -1) {
  //     const poSection = leftNav.splice(poIndex, 1)[0];
  //     leftNav.splice(quoteIndex + 1, 0, poSection);
  //   }

  //   // const navBar = this.removeCreatePO(leftNav);
  //   // console.log(navBar , "navbar:::::")
  //   return leftNav;
  // };

  getNavbar = () => {
    const userData = JSON.parse(localStorage.getItem("userData"));
    const token = userData.token;
    const requestoption = {
      method: "GET",
      headers: {
        Authorization: "Bearer " + token,
      },
    };
    return axios(apiUrl + "/get-navigation", requestoption)
      .then((data) => {
        const leftNav = data.data.data;
        const headers = leftNav;

        this.setState({
          leftNav: headers,
        });
        const array = data.data.data;
        if (array.length === 0) {
          localStorage.removeItem("userData");
          window.location.reload();
          // windows.ref.location
        }
      })
      .catch((error) => console.log(error));
  };
  openExpand = (e) => {
    let id = e.currentTarget.id;
    this.setState({
      isExpanded: true,
      itemId: id,
    });
  };
  closeExpand = (e) => {
    let id = e.currentTarget.id;
    this.setState({
      isExpanded: false,
      itemId: id,
    });
  };
  // logout = () => {
  //   localStorage.removeItem("userData");
  //   window.location.reload();
  // };
  handleLogout = async () => {
    try {
      const userData = JSON.parse(localStorage.getItem("userData"));
      const token = userData.token;
      const requestoption = {
        method: "GET",
        headers: {
          Authorization: "Bearer " + token,
        },
      };
      const response = await axios(apiUrl + "/signout", requestoption);
      if (response.status === 200) {
        // Logout successful
        localStorage.removeItem("userData");
        window.location.reload();
      } else {
        // Logout failed
        console.log("Logout failed");
      }
    } catch (error) {
      console.log("Error during logout:", error);
    }
  };

  render() {
    return (
      <header className="header-top">
        <div className="container-fluid">
          <div className="justify-content-between">
            <Row>
              <Col col={8}>
                <div className="d-flex align-items-center">
                  <div>
                    <div>
                      <Navbar expand="lg">
                        <div className="d-flex align-items-center">

                          <Navbar.Toggle aria-controls="basic-navbar-nav">
                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                            <path d="M3 7H21" stroke="white" stroke-width="1.5" stroke-linecap="round"/>
                            <path d="M3.38086 12H17.3809" stroke="white" stroke-width="1.5" stroke-linecap="round"/>
                            <path d="M3.38086 17H12.3809" stroke="white" stroke-width="1.5" stroke-linecap="round"/>
                            </svg>
                          </Navbar.Toggle>
                          <div className="logo-img-desktop">
                            <Link to="/dashboard">
                              <img
                                src="https://mm.media-mccoymart.com/images/Mccoy-mart-logo.png"
                                className="header-brand-img"
                                alt="lavalite"
                              />
                            </Link>
                          </div>
                          <div className="logo-img-mobile">
                            <Link to="/dashboard">
                              <img src={logoMobile}
                                alt="lavalite"
                              />
                            </Link>
                          </div>
                        </div>
                        <Navbar.Collapse id="basic-navbar-nav" ref={this.navbarRef}>
                          <Nav className="mr-auto">
                            {/* <div className="dropdown nav-item">
                                    <a
                                      aria-haspopup="true"
                                      aria-expanded="false"
                                      href="/dashboard"
                                      className="nav-link"
                                    >
                                      Dashboard
                                    </a>
                                  </div> */}
                            {this.state.leftNav.map((item, index) => {
                              return item.group_slug == "dashboard" ? (
                                <div className="dropdown nav-item">
                                  <a
                                    aria-haspopup="true"
                                    aria-expanded="false"
                                    href="/dashboard"
                                    className="nav-link"
                                  >
                                    {/* Dashboard */}
                                  </a>
                                </div>
                              ) : item.group_slug == "tools" ? (
                                <div className="dropdown nav-item">
                                  <Link
                                    aria-haspopup="true"
                                    aria-expanded="false"
                                    to="/distance"
                                    className="nav-link"
                                  >
                                    Tools
                                  </Link>
                                </div>
                              ) : item.modules?.length !== 0 ? (
                                item.group_slug === "sourcing" &&
                                  item.modules[0].sub_module_name.length === 1 &&
                                  item.modules[0].sub_module_name[0] ===
                                  "add" ? null : (
                                  <NavDropdown
                                    title={item.group_name}
                                    id={index}
                                    key={item.group_name}
                                  >
                                    {item.modules.map((item) => {
                                      return (
                                        <>
                                          { this.state.screenWidth<=991 ?
                                            <Navbar.Toggle style={{ border: "0", padding: "0", margin: "0", width: "100%", textAlign: "left" }}>
                                              <NavDropdown.Item as={Link}
                                                to={item.module_slug}
                                                key={item.module_slug}
                                              >
                                                {item.module_name}
                                              </NavDropdown.Item>
                                            </Navbar.Toggle>:
                                          <NavDropdown.Item as={Link}
                                            to={item.module_slug}
                                            key={item.module_slug}
                                          >
                                            {item.module_name}
                                          </NavDropdown.Item>
                                    }
                                        </>

                                      );
                                    })}
                                  </NavDropdown>
                                )
                              ) : null;
                            })}
                          </Nav>
                        </Navbar.Collapse>
                      </Navbar>
                    </div>
                  </div>
                </div>
              </Col>
              {/* User Profile Section Below */}
              <Col col={4}>
                <Dropdown>
                  <Dropdown.Toggle id="dropdown-basic">
                    <FontAwesomeIcon icon="user" />
                  </Dropdown.Toggle>
                  <Dropdown.Menu className="dropdown-menu-right">
                    <Dropdown.Item href="/profile">Profile</Dropdown.Item>
                    <Dropdown.Item href="#/action-2">
                      Change Password
                    </Dropdown.Item>
                    <Dropdown.Item onClick={this.handleLogout}>
                      Logout
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              </Col>
            </Row>
          </div>
        </div>
      </header>
    );
  }
}

export default Header;
