import React, { useState, useEffect } from "react";
import { Button, Form } from "react-bootstrap";
import { ReactComponent as SearchIcon } from "../../../asset/Images/search.svg";
import building from "../../../asset/Images/building.svg";
import locationIcon from "../../../asset/Images/location.svg";
import editIcon from "../../../asset/Images/edit.svg";
import classes from "./CreateSource.module.css";
import AsyncSelect from "react-select/async";
import { components } from "react-select";
// import edit component for mobile view only
import EditLocation from "./EditLocation";

const CustomInput = (props) => (
  <div style={{ display: "flex", alignItems: "center", height: "100%" }}>
    <SearchIcon style={{ marginRight: "4px" }} />
    <components.Input {...props} />
  </div>
);

const customStyles = {
  control: (provided, state) => ({
    ...provided,
    backgroundColor: "transparent",
    border: "0",
    borderColor: state.isFocused ? "none" : "none",
    "&:hover": {
      borderColor: state.isFocused ? "none" : "none",
    },
    boxShadow: state.isFocused ? "none" : "none",
    minHeight: "auto",
    height: "100%",
  }),
  valueContainer: (provided) => ({
    ...provided,
    height: "100%",
    padding: "0",
  }),
  placeholder: (provided) => ({
    ...provided,
    color: "#99ABBE",
    display: "none",
    fontSize: "13px",
  }),
  singleValue: (provided) => ({
    ...provided,
    color: "#1E293B",
    padding: "0 0 0 20px",
    fontSize: "13px",
  }),
  dropdownIndicator: (provided) => ({
    ...provided,
    display: "none",
  }),
  indicatorSeparator: (provided) => ({
    ...provided,
    display: "none",
  }),
};

const UserData = (props) => {
  const {
    formValidate,
    sourcingId,
    status,
    customerName,
    accountName,
    deliverLocation,
    getCities,
    handleNoSelect,
    debouncedFetchLocationOptions,
    disabled,
    onData,
  } = props;

  const [location, setLocation] = useState({
    label: deliverLocation,
    value: deliverLocation,
  });
  const [isSelectVisible, setSelectVisible] = useState(false);
  const [openLocationModal, setOpenLocationModal] = useState(false);

  const showModalOpen = () => {
    setOpenLocationModal(true);
  };

  const showModalClose = () => {
    setOpenLocationModal(false);
  };

  useEffect(() => {
    setLocation({ label: deliverLocation || "", value: deliverLocation || "" });
  }, [deliverLocation]);

  const handleLocationChange = (e) => {
    const newValue = { label: e.target.value, value: e.target.value };
    setLocation(newValue);
    setSelectVisible(true);
  };

  const handleAsyncSelectChange = (selectedOption) => {
    setLocation(selectedOption);
    onData(selectedOption.label);
    getCities(selectedOption);
  };

  return (
    <>
      <Form noValidate validated={formValidate}>
        <div className={`${classes.requirement_detail_wrapper} ${classes.userDetailcnt}`}>
          <Form.Row>
            <Form.Group className="col-lg-4 col-md-4 mb-0">
              <div className={classes.main_card_item}>
                <div className={classes.srId_cusName_wrapper}>
                  {status &&
                    ["pending", "new", "reopen", "completed"].includes(
                      status
                    ) ? (
                    <button type="button" className={classes.srIdButton}>SR ID: {sourcingId}</button>
                  ) : null}
                  <div>
                    <Form.Label
                      className={`${classes.customer_title} ${classes.customer_title_mobile}`}
                    >
                      Customer Name
                    </Form.Label>
                    <div
                      className={`${classes.customer_para} ${classes.customer_name} ${classes.marginLeftZero}`}
                    >
                      {customerName}
                    </div>
                  </div>
                  <hr className={`${classes.dottedBorder} ${classes.d_none_desktop}`} />
                </div>
                <hr className={classes.divider} />
              </div>
            </Form.Group>

            <Form.Group className="col-lg-4 col-md-4 mb-0">
              <div className={classes.main_card_item}>
                <div className={`${classes.main_card_item_wrapper} m-0`}>
                  <Form.Label
                    className={`${classes.customer_title} ${classes.customer_title_mobile}`}
                  >
                    Account Name
                  </Form.Label>
                  <img src={building} alt="bank icon" />
                  <div className={classes.customer_para}> {accountName} </div>
                </div>
                <hr className={classes.divider} />
              </div>
            </Form.Group>

            <Form.Group
              className="col-lg-4 col-md-4 mb-0"
              value="b2blandingpage"
            >
              <div>
                <div className={classes.main_card_item_wrapper}>
                  <Form.Label
                    className={`${classes.customer_title} ${classes.customer_title_mobile}`}
                  >
                    Delivery Location
                  </Form.Label>
                  <img src={locationIcon} alt="location icon" />
                  {disabled ? (
                    <div className={classes.customer_para}>
                      {deliverLocation}
                    </div>
                  ) : (
                    <div className={classes.customer_para}>
                      <div className={classes.addLocationDesktop}>
                        {!isSelectVisible ? (
                          <Form.Control
                            type="text"
                            name="location"
                            onChange={handleLocationChange}
                            value={location.label}
                            className={classes.customInput}
                            onKeyPress={(e) => {
                              e.key === "Enter" && e.preventDefault();
                            }}
                          />
                        ) : (
                          <AsyncSelect
                            components={{ Input: CustomInput }}
                            className={classes.customSelect}
                            styles={customStyles}
                            value={location}
                            defaultOptionsx
                            loadOptions={debouncedFetchLocationOptions}
                            onChange={handleAsyncSelectChange}
                            onBlur={handleNoSelect}
                            onKeyPress={(e) => {
                              e.key === "Enter" && e.preventDefault();
                            }}
                            required
                          />
                        )}
                      </div>
                      <div className={classes.addLocationBtnCnt}>
                        <div className={classes.customer_para}>
                          {location.label}
                        </div>
                        <div className={classes.addLocationBtnWrapper}>
                          <button
                            type="button"
                            className={classes.addLocationbtn}
                            onClick={showModalOpen}
                          >
                            <img src={editIcon} alt="edit icon" />
                            Edit
                          </button>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </Form.Group>
          </Form.Row>
        </div>
      </Form>
      {openLocationModal && (
        <EditLocation
          show={showModalOpen}
          close={showModalClose}
          deliverLocation={deliverLocation}
          handleLocationChange={handleLocationChange}
          location={location}
          isSelectVisible={isSelectVisible}
          debouncedFetchLocationOptions={debouncedFetchLocationOptions}
          handleAsyncSelectChange={handleAsyncSelectChange}
          handleNoSelect={handleNoSelect}
          disabled={disabled}
        />
      )}
    </>
  );
};

export default UserData;
