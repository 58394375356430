import Axios from "axios";
import React, { Component } from "react";
import { Alert, Button, Col, Form, Modal, Row, Table } from "react-bootstrap";
import BootstrapTable from "react-bootstrap-table-next";
import constants from "../../../Config";
import { getUserDetails } from "../../Login/getLoginnedUserDetails";
import moment from "moment";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
const apiUrl = constants.API_URL;
export class RfqQuoteDetail extends Component {
  constructor(props) {
    super(props);
    this.state = {
      quoteDetails: [],
      show: false,
      QuoteData: [],
      CompanyData: "",
      Products: [],
      message: "",
      productArray: [],
      customer_id: "",
      showAccordian: false,
    };
  }
  componentDidMount() {
    this.getQuoteDetailByRfqId();
  }
  getQuoteDetailByRfqId = () => {
    const requestoption = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${getUserDetails.token}`,
      },
      data: {
        quoteID: 0,
        rfq_id: this.props.rfqID,
        // request_quote_id: this.props.rfqID,
        // isPopUp: true,
      },
    };
    return Axios(apiUrl + "/get-quotes", requestoption).then((data) => {
      let quoteDetails = data.data.data;
      let productArray = quoteDetails.products;
      let customer_id = quoteDetails.customer_id;

      this.setState({
        quoteDetails,
        customer_id,
        productArray,
      });
    });
  };
  openViewProductsPopup = (quoteID) => {
    this.setState(
      {
        showAccordian: !this.state.showAccordian,
      },
      () => {
        this.getQuoteDetailsByID(quoteID);
      }
    );
  };
  getQuoteDetailsByID = (quoteID) => {
    const params = {
      method: "POST",
      headers: {
        Authorization: `Bearer ${getUserDetails.token}`,
        "Content-Type": "application/json",
      },
      data: {
        quoteID: quoteID,
      },
    };
    return Axios(apiUrl + "/get-quotes", params)
      .then((data) => {
        this.setState(
          {
            QuoteData: data.data.data[0],
            CompanyData: data.data.data[0].companyData,
            Products: data.data.data[0].products,
          },
          () => {
          }
        );
      })
      .catch((error) => console.log(error));
  };
  sendQuoteLink = (quoteID) => {
    const params = {
      method: "POST",
      headers: {
        Authorization: `Bearer ${getUserDetails.token}`,
        "Content-Type": "application/json",
      },
      data: {
        quoteID: quoteID,
      },
    };
    return Axios(apiUrl + "/send-quotes-link", params)
      .then((data) => {
        this.setState({
          message: data.data.message,
        });
        setTimeout(() => {
          this.setState({
            message: "",
          });
        }, 3000);
      })
      .catch((error) => console.log(error));
  };
  setItemDuplicate = (QUOTEID, customerid, productsArr) => {
    var data = {
      quoteID: QUOTEID,
      customer_id: customerid,
      productsArr: productsArr,
    };
    localStorage.setItem("dataKey", JSON.stringify(data));
  };
  setItemEdit = (QUOTEID, QUOTETYPE, rfqID) => {
    var data = {
      quoteID: QUOTEID,
      quote_type: QUOTETYPE,
      rfqID: rfqID,
    };
    localStorage.removeItem("dataQuote2");
    localStorage.setItem("dataKeyEdit", JSON.stringify(data));
  };
  setItemLink = (QUOTEID) => {
    var data = {
      quoteID: QUOTEID,
    };
    localStorage.removeItem("dataQuote");
    localStorage.removeItem("dataKeyEdit");
    localStorage.setItem("dataQuote2", JSON.stringify(data));
  };

  closeModal = () => {
    this.setState({
      showAccordian: false,
    });
  };

  render() {
    return (
      <div>
        <Modal
          show={this.props.showQuoteDetail}
          onHide={this.props.closeModal}
          dialogClassName="modal-90w business_process_wrapper"
        >
          <Modal.Header closeButton>
            {this.state.message.length > 0 ? (
              <Alert className="displayMessageArea" variant="success">
                {this.state.message}
              </Alert>
            ) : null}
          </Modal.Header>
          <Modal.Body className="h-auto">
            <Form.Row>
              <Col md="2">
                <b>Quote ID</b>
              </Col>
              <Col md="2">
                <b>Quote Type</b>
              </Col>
              <Col md="2">
                <b>Quote Status</b>
              </Col>
              <Col md="2">
                <b>Quote Validity</b>
              </Col>
              <Col md="2">
                <b>Link To Quote</b>
              </Col>
              <Col md="2">
                <b>Actions</b>
              </Col>
            </Form.Row>

            {this.state.quoteDetails &&
              this.state.quoteDetails.map((item) => {
                return (
                  <div className="p-3 my-3 rounded border border-5 border-dark">
                    <Form.Row>
                      <Col md="2">
                        <b>{item.quote_id}</b>
                      </Col>
                      <Col md="2">{item.quote_type}</Col>
                      <Col md="2">{item.quote_status}</Col>
                      <Col md="2">
                        {moment(item.valid_upto).format("YYYY-MM-DD")}
                      </Col>
                      <Col md="2">
                        {
                          <b>
                            <a
                              href="/createQuote"
                              onClick={() => this.setItemLink(item.quote_id)}
                              target="_blank"
                            >
                              Quote Detail
                            </a>
                          </b>
                        }
                      </Col>
                      <Col md="2">
                        {
                          <div className="d-flex flex-column">
                            <div>
                              {(() => {
                                if (
                                  !["Closed Won", "Closed Lost"].includes(
                                    item.quote_status
                                  )
                                ) {
                                  return this.props.allowedSlugs.includes(
                                    "edit"
                                  ) ? (
                                    <Link
                                      to="/editQuote"
                                      onClick={() =>
                                        this.setItemEdit(
                                          item.quote_id,
                                          item.quote_type == "Magic"
                                            ? "2"
                                            : item.quote_type == "Normal"
                                            ? "1"
                                            : "",
                                          this.props.rfqID
                                        )
                                      }
                                      target="_blank"
                                    >
                                      <FontAwesomeIcon
                                        className="editIcon"
                                        icon="edit"
                                      />
                                    </Link>
                                  ) : null;
                                }
                              })()}
                              {!["Closed Won", "Closed Lost"].includes(
                                item.quote_status
                              ) ? (
                                <FontAwesomeIcon
                                  className="editIcon"
                                  icon="envelope"
                                  onClick={() =>
                                    this.sendQuoteLink(item.quote_id)
                                  }
                                />
                              ) : null}
                              <FontAwesomeIcon
                                className="editIcon"
                                icon="eye"
                                onClick={() =>
                                  this.openViewProductsPopup(item.quote_id)
                                }
                              />
                              {!["Closed Won", "Closed Lost"].includes(
                                item.quote_status
                              ) ? (
                                <>
                                  {item.quote_status ==
                                  "Draft" ? null : item.quote_type ==
                                    "Normal" ? (
                                    <a
                                      target="_blank"
                                      href={
                                        item.utm_medium
                                          ? `https://mccoymart.com/buy/view-quote/${item.quote_unique_id}?utm_source=${item.utm_source}&utm_medium=${item.utm_medium}`
                                          : item.utm_source
                                          ? `https://mccoymart.com/buy/view-quote/${item.quote_unique_id}?utm_source=${item.utm_source}`
                                          : `https://mccoymart.com/buy/view-quote/${item.quote_unique_id}`
                                      }
                                    >
                                      <FontAwesomeIcon
                                        className="editIcon"
                                        icon="external-link-alt"
                                      />
                                    </a>
                                  ) : (
                                    <a
                                      target="_blank"
                                      href={
                                        item.utm_medium
                                          ? `https://mccoymart.com/buy/magic-quote/${item.quote_unique_id}?utm_source=${item.utm_source}&utm_medium=${item.utm_medium}`
                                          : item.utm_source
                                          ? `https://mccoymart.com/buy/magic-quote/${item.quote_unique_id}?utm_source=${item.utm_source}`
                                          : `https://mccoymart.com/buy/magic-quote/${item.quote_unique_id}`
                                      }
                                    >
                                      <FontAwesomeIcon
                                        className="editIcon"
                                        icon="external-link-alt"
                                      />
                                    </a>
                                  )}
                                </>
                              ) : null}
                            </div>
                            {this.props.sourcingStatus === "reopen" ? "" : <>
                            {item.allowDuplicate ? (
                              <div>
                                <Button
                                  className="mx-auto mt-3 btn-sm"
                                  variant="primary"
                                  type="submit"
                                  id="updateAndSent"
                                  // onClick={(e) => this.duplicateQuote(e, item.quote_id)}
                                >
                                  <Link
                                    to="/duplicateQuote"
                                    onClick={() =>
                                      this.setItemDuplicate(
                                        item.quote_id,
                                        item.customer_id,
                                        item.products
                                      )
                                    }
                                    style={{
                                      textDecoration: "none",
                                      color: "white",
                                    }}
                                    target="_blank"
                                  >
                                    Duplicate
                                  </Link>
                                </Button>
                              </div>
                            ) : null}
                          </>}
                          </div>
                        }
                      </Col>
                    </Form.Row>
                  </div>
                );
              })}
          </Modal.Body>
        </Modal>
        <Modal
          show={this.state.showAccordian}
          onHide={this.closeModal}
          size="lg"
          className="business_process_wrapper"
        >
          <Modal.Header closeButton>
            <Modal.Title>
              Account: {this.state.CompanyData.company_name}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body className="h-auto">
            <Table striped bordered hover>
              <thead>
                <tr>
                  <th>#</th>
                  <th>Product Details</th>
                  <th>MRP</th>
                  <th>List Price(Per SKU)</th>
                  <th>Quantity</th>
                  <th>Net Amount</th>
                  <th>Tax Amount</th>
                  <th>Total</th>
                </tr>
              </thead>
              <tbody>
                {this.state.Products.map((product, index) => {
                  return (
                    <tr key={index}>
                      <td>{index + 1}</td>
                      <td>{product.name}</td>
                      <td>{product.mrp}</td>
                      <td>{product.list_price}</td>
                      <td>{product.quantity}</td>
                      <td>{product.net_amount_new}</td>
                      <td>{product.tax_in_amount}</td>
                      <td>{product.total}</td>
                    </tr>
                  );
                })}
              </tbody>
            </Table>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={this.closeModal}>
              Close
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    );
  }
}

export default RfqQuoteDetail;
