import React from "react";
import { Button } from "react-bootstrap";

const SourcingContent = (props) => {

  const { handleShowUploadModal, uploadHistoryList, srListCount } = props;
  const totalProductCount = uploadHistoryList.reduce((total, item) => total + item.productCount, 0);

  return (
    <>
      <div className="sourcing_table_wrapper">
        <div className="sourcing_table_hdr d-flex justify-content-between align-items-center">
          <div>
            <h2 className="new_product_title">Add Bulk Products</h2>
            <p className="no_of_products d-flex align-items-center mb-0">
              <span style={{ marginRight: "12px" }}>{totalProductCount} New Products added from {srListCount} Files
              </span>
            </p>
          </div>

          <Button
            className="Upload_btn"
            type="Button"
            onClick={handleShowUploadModal}
          >
            Upload File
          </Button>
        </div>
      </div>
    </>
  );
};

export default SourcingContent;
