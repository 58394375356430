import React, { useState, useEffect, useCallback } from "react";
import { Button, Col, Form, Row, Spinner } from "react-bootstrap";
import Axios from "axios";
import { useHistory } from "react-router-dom";
import Constants from "../../../Config";
import classes from "./CreateSource.module.css";
import ErrorHandling from "./ErrorHandling";
import SalesProductListing from "./SalesProductListing";
import { ReactComponent as SearchIcon } from "../../../asset/Images/search.svg";
import AsyncSelect from "react-select/async";
import { components } from "react-select";
import building from '../../../asset/Images/building.svg'
import locationIcon from '../../../asset/Images/location.svg'
import phoneIcon from '../../../asset/Images/phone.svg'
import emailIcon from '../../../asset/Images/email.svg'
import EditLocation from "./EditLocation";
import editIcon from '../../../asset/Images/edit.svg'

const apiUrl = Constants.API_URL;
const loginedUserDetails = JSON.parse(localStorage.getItem("userData"));

const CreateSourceRequest = (props) => {
  const history = useHistory();

  const [formValidate, setFormValidate] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const [customer_name, setCustomerName] = useState("");
  const [account_name, setAccountName] = useState("");
  const [delivery_loaction, setDeliveryLocation] = useState("");
  const [customer_mobile, setCustomerMobile] = useState("");
  const [customer_email, setCustomerEmail] = useState("");
  const [customer_role, setCustomerRole] = useState("");
  const [customer_id, setCustomerId] = useState("");
  const [customerLocationId, setCustomerLocationId] = useState("");
  const [customerpriority, setCustomerPriority] = useState("");
  const [customer_utm_source, setCustomerUtmSource] = useState("");
  const [customer_utm_medium, setCustomerUtmMedium] = useState("");
  const [customer_utm_content, setCustomerUtmContent] = useState("");
  const [customer_utm_campaign, setCustomerUtmCampaign] = useState("");
  const [customer_rfq_id, setCustomerRfqId] = useState("");
  const [customer_lead_id, setCustomerLeadId] = useState("");
  const [customer_pincode, setCustomerPinCode] = useState("");
  const [isSelectVisible, setSelectVisible] = useState(false);

  const [product_data, setProductData] = useState([]);
  const [itemTypeArray, setItemTypeArray] = useState([]);
  const [id, setId] = useState(0);
  const [newAdd, setNewAdd] = useState(false);
  const [finalAdd, setFinalAdd] = useState(false);
  const [formDataValue, setFormDataValue] = useState({
    kam_remarks: "",
  });

  const [product_list, setProductList] = useState([]);
  const [category_list, setCategoryList] = useState([]);
  const [productName, setProductName] = useState("");
  const [productSelected, setProductSelected] = useState(false);

  // for image upload state
  const [submitLoading, setSubmitLoading] = useState(false);
  const [is_redirect, setRedirect] = useState(false);
  const [attachments, setAttachments] = useState([]);
  const [access, setAccess] = useState(false);

  const [googleData, setGoogleData] = useState([]);
  const [pincode, setPincode] = useState("");
  const [location, setLocation] = useState("");
  const [locationId, setLocationId] = useState(null);
  const [selectedFile, setSelectedFile] = useState([]);
  const maxLength = 1000;
  const [openLocationModal, setOpenLocationModal] = useState(false);

  const handleFileSelect = (file, type, indexP) => {
    setSelectedFile(file);
    if (type == "product") {
      let data = product_data.map((item, index) => {
        if (index === indexP) {
          item = {
            brand: "",
            brand_id: 0,
            category_id: 0,
            product_id: 0,
            type: "attachments",
            name: file,
          };
        }
        return item;
      });

      setProductData(data);
    }
  };
  const showModalOpen = () => {
    setOpenLocationModal(true)
  };
  const showModalClose = () => {
    setOpenLocationModal(false)
  };
  // useEffect hook start here
  useEffect(() => {
    const createSrDetails = JSON.parse(localStorage.getItem("SR_Details")); //link from quote-request list
    if (createSrDetails) {
      getQuoteRequestDetailById(createSrDetails?.requestQuoteID);
    }
    setAccess(props.childSlugProp["sourcing-request"].includes("approve"));
  }, []);

  useEffect(() => {
    setLocation({
      label: delivery_loaction || "",
      value: delivery_loaction || "",
    });
  }, [delivery_loaction]);

  const getProductData = async (product_id, customer_id) => {
    try {
      const token = loginedUserDetails.token;
      const params = {
        method: "POST",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        data: {
          product_id,
          customer_id,
          status: "2"
        },
      };
      if (product_id.length === 0) {
        return [];
      }
      return await Axios(apiUrl + "/get-product-detail-by-id", params).then(
        (data) => {
          return data.data.data;
        }
      );
    } catch (e) {
      console.log(e);
    }
  };

  const getQuoteRequestDetailById = async (RequestQuoteId) => {
    try {
      const token = loginedUserDetails.token;
      const params = {
        method: "POST",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        data: {
          request_quote_id: RequestQuoteId,
        },
      };
      return await Axios(apiUrl + "/get-quote-request", params).then((data) => {
        const requestQuoteData = data.data.data[0];
        let attachment = requestQuoteData?.attachments.map(({ url }) => {
          return {
            name: url,
            product_id: 0,
            category_id: 0,
            brand: "",
            type: "attachments",
          };
        });
        let productIds = requestQuoteData?.product
          .filter((item) => item.type === "product")
          .map(({ product_id }) => {
            return product_id;
          });

        const dataObj = new Map();
        getProductData(productIds, requestQuoteData.customer_id).then(
          (data) => {
            for (let val of data) {
              dataObj.set(val.product_id, {
                brand: val.brand,
                brand_id: val.brand_id,
                transfer_price: val.transfer_price,
                quantity_type: val.quantity_type,
              });
            }
            let products = requestQuoteData?.product.map(
              ({
                productName,
                productQuantity,
                quantity_type,
                category_id,
                product_id,
                type,
                productPrice,
                brand,
                brand_id,
              }) => {
                if (type === "product") {
                  return {
                    name: productName,
                    quantity: productQuantity,
                    brand: dataObj.get(product_id).brand,
                    brand_id: dataObj.get(product_id).brand_id,
                    quantity_type: dataObj.get(product_id).quantity_type,
                    category_id: category_id ? category_id : 0,
                    product_id,
                    type,
                  };
                } else {
                  return {
                    name: productName,
                    quantity: productQuantity,
                    quantity_type: quantity_type,
                    brand: brand,
                    category_id: category_id ? category_id : 0,
                    product_id,
                    type,
                  };
                }
              }
            );
            setCustomerName(requestQuoteData.customer_name);
            setAccountName(requestQuoteData.accountName);
            setLocation(requestQuoteData.location);
            setDeliveryLocation(
              location ? location : requestQuoteData.location
            );
            setCustomerMobile(requestQuoteData.customer_mobile);
            setCustomerEmail(requestQuoteData.customer_email);
            setCustomerRole(requestQuoteData.role);
            setProductData([...products, ...attachment]);
            setCustomerId(requestQuoteData.customer_id);
            setCustomerLocationId(requestQuoteData.location_id.toString());
            setCustomerPriority(requestQuoteData.priority ?? "");
            setCustomerUtmSource(requestQuoteData.utm_source ?? "");
            setCustomerUtmMedium(requestQuoteData.utm_medium ?? "");
            setCustomerUtmCampaign(requestQuoteData.campaign ?? "");
            setCustomerUtmContent(requestQuoteData.utm_content ?? "");
            setCustomerRfqId(requestQuoteData.id ?? "");
            setCustomerLeadId(requestQuoteData.lead_id ?? 0);
            setCustomerPinCode(
              pincode ? pincode : requestQuoteData.pincode ?? ""
            );
          }
        );
      });
    } catch (err) {
      console.log(err);
    }
  };

  const handleNoSelect = (e) => {
    const value = e.target.value;
    // setLocation(value);
  };

  const debounce = (func, delay) => {
    let timeoutId;
    return (...args) => {
      if (timeoutId) {
        clearTimeout(timeoutId);
      }
      timeoutId = setTimeout(() => {
        func(...args);
      }, delay);
    };
  };
  const handleLocation = async (e, callback) => {
    const config = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${loginedUserDetails.token}`,
      },
      data: {
        query: `${e} in India`,
        key: "AIzaSyB7qkySF1gvl0jQ3Gfz3ju7wzxxp5SWVmc",
        components: "country:in",
      },
    };

    try {
      const response = await Axios(apiUrl + "/google-text", config);
      const data = response.data.data;

      if (data.status === "OK") {
        const location = data.results[0].geometry.location;

        if (data.results.length === 0) {
          setLocationId(0);
        }

        // Another request with the lat and lng
        const geocodeConfig = {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${loginedUserDetails.token}`,
          },
          data: {
            latlng: `${location.lat},${location.lng}`,
            sensor: "true",
            key: "AIzaSyB7qkySF1gvl0jQ3Gfz3ju7wzxxp5SWVmc",
            regions: "locality",
          },
        };

        try {
          const geocodeResponse = await Axios(
            apiUrl + "/google-lat-long",
            geocodeConfig
          );
          const geocodeData = geocodeResponse.data;
          let dataRes = [];

          for (let each of geocodeData.data.results) {
            let data = each.address_components.filter((value) =>
              value.types.includes("postal_code")
            );
            if (data.length) {
              let obj = {
                addr: each.formatted_address,
                pincode: data[0].long_name,
              };
              dataRes = [...dataRes, obj];
            }
          }

          callback(
            dataRes.map((result) => ({
              label: result.addr,
              value: result.addr,
            }))
          );

          setGoogleData(dataRes);
          // setSuccessMessage('Address is updated successfully');
          // setTimeout(() => {
          //   setSuccessMessage('');
          // }, 8000);

        } catch (geocodeError) {
          console.error("Geocode API error:", geocodeError);
        }
      } else {
        console.error("Geocode API error:", data.status);
        callback([]);
      }
    } catch (err) {
      console.error("Error:", err);
      callback([]);
    }
  };

  const debouncedFetchLocationOptions = useCallback(
    debounce(handleLocation, 300),
    []
  );

  const handleAsyncSelectChange = (selectedOption) => {
    setLocation(selectedOption);
    getCities(selectedOption);
    showModalClose(); // close modal
  };

  const CustomInput = (props) => (
    <div style={{ display: "flex", alignItems: "center", height: "100%" }}>
      <SearchIcon style={{ marginRight: "4px" }} />
      <components.Input {...props} />
    </div>
  );

  const customStyles = {
    control: (provided, state) => ({
      ...provided,
      backgroundColor: "transparent",
      border: "0",
      borderColor: state.isFocused ? "none" : "none",
      "&:hover": {
        borderColor: state.isFocused ? "none" : "none",
      },
      boxShadow: state.isFocused ? "none" : "none",
      minHeight: "auto",
      height: "100%"
    }),
    valueContainer: (provided) => ({
      ...provided,
      height: "100%",
      padding: "0"
    }),
    placeholder: (provided) => ({
      ...provided,
      color: "#99ABBE",
      display: "none",
      fontSize: "13px"
    }),
    singleValue: (provided) => ({
      ...provided,
      color: "#1E293B",
      padding: "0 0 0 20px",
      fontSize: "13px"
    }),
    dropdownIndicator: (provided) => ({
      ...provided,
      display: "none",
    }),
    indicatorSeparator: (provided) => ({
      ...provided,
      display: "none",
    }),
  };

  const handleProductList = async (e, callback) => {
    const token = loginedUserDetails.token;
    const hasAccess = access;

    const dataPayload = hasAccess
      ? { keyword: e, status: "2" }
      : { keyword: e };

    const params = {
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      data: dataPayload,
    };
    return await Axios(apiUrl + "/get-products-by-keyword", params)
      .then((data) => {
        setProductList(data.data.data);
        callback(
          data.data.data.map((i) => ({
            label: `${i.name}`,
            value: `${i.name}`,
          }))
        );
      })
      .catch((err) => console.log(err.response));
  };

  const handleCategoryList = async (e, callback) => {
    const token = loginedUserDetails.token;
    const params = {
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      data: {
        keyword: e,
      },
    };
    return await Axios(apiUrl + "/get-product-category", params)
      .then((data) => {
        setCategoryList(data.data.data);
        callback(
          data.data.data.map((i) => ({
            label: `${i.name}`,
            value: `${i.name}`,
          }))
        );
      })
      .catch((err) => console.log(err.response));
  };

  //create sourcing data api start here
  const createSourceRequestSubmit = async (e) => {
    if (submitLoading) return;
    setSubmitLoading(true);
    //create POST api call will be executed below on clicktype
    e.preventDefault();
    let loginedUserDetails = JSON.parse(localStorage.getItem("userData"));
    const token = loginedUserDetails.token;
    let product;
    product = product_data.map((data) => {
      if (data.type === "product") {
        return {
          name: data.name,
          quantity: data.quantity,
          brand: data.brand,
          brand_id: data.brand_id,
          quantity_type: data.quantity_type,
          category_id: data.category_id ? data.category_id : 0,
          product_id: data.product_id,
          type: data.type
        };
      }
      else if (data.type === "attachments") {
        return {
          name: data.name,
          brand: "",
          brand_id: data.brand_id ? data.brand_id : 0,
          quantity_type: data.quantity_type ? data.quantity_type :"",
          category_id: data.category_id ? data.category_id : 0,
          product_id: data.product_id ? data.product_id : 0,
          type: data.type
        };
      }
      return {
        name: data.name,
        quantity: data.quantity,
        brand: data.brand,
        brand_id: data.brand_id,
        quantity_type: data.quantity_type,
        category_id: data.category_id ? data.category_id : 0,
        product_id: data.product_id,
        type: data.type === null
          ? (data.product_id > 0
            ? "product"
            : (data.category_id > 0
              ? "category"
              : data.type))
          : data.type
      };
    })
    const params = {
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      data: {
        customer_id: customer_id.toString(),
        customer_name: customer_name,
        account_name: account_name,
        mobile: customer_mobile,
        email: customer_email,
        pincode: pincode ? pincode : customer_pincode.toString(),
        location: location.value
          ? location.value
          : location
            ? location
            : delivery_loaction,
        location_id: customerLocationId.toString(),
        role: customer_role,
        utm_source: customer_utm_source,
        utm_medium: customer_utm_medium,
        utm_campaign: customer_utm_campaign,
        utm_content: customer_utm_content,
        rfq_id: customer_rfq_id,
        lead_id: customer_lead_id ? customer_lead_id : 0,
        sales_remarks: formDataValue.kam_remarks,
        products: [...product, ...attachments],
        // body send to api ends here
      },
    };
    return await Axios(apiUrl + "/create-source-request", params)
      .then(() => {
        setSuccessMessage("Source Request Created Successfully");
        setErrorMessage("");
        setSubmitLoading(false);
        setRedirect(true);
      })
      .then(() => {
        if (access) {
          setTimeout(() => {
            history.push("/sourcing-request");
          }, 2000);
        } else {
          setTimeout(() => {
            history.push("/quote-requests");
          }, 2000);
        }
      })
      .catch((err) => {
        console.log(err);
        setSubmitLoading(false);
        setErrorMessage(err.response.data.message);
        setTimeout(() => {
          setErrorMessage("");
        }, 6000);
      });
  };
  // create sourcing data api ends here

  const handleChange = (e, ind) => {
    const { name, value } = e.target;
    const newData = [...product_data];
    newData[ind] = { ...newData[ind], [name]: value };
    setProductData(newData);
  };

  const handleCheckboxValue = (newValue, ind, name) => {
    const newData = [...product_data];
    newData[ind] = { ...newData[ind], [name]: newValue };
    setProductData(newData);
  };

  const handleSelectChooseType = (i, e, type) => {
    if (type === "product") {
      const newValue = [...product_data];
      const eventValue = e.target.value;
      newValue[i] = {
        ...newValue[i],
        type: eventValue,
        name: "",
        quantity: 0,
        brand:
          eventValue === "product" ||
            eventValue === "category" ||
            eventValue === "other"
            ? ""
            : newValue[i].brand,
        category_id: 0,
        product_id: 0,
      };
      const newItemTypeArray = [...itemTypeArray];
      newItemTypeArray[i] = e.target.value;
      setProductData(newValue);
      setItemTypeArray(newItemTypeArray);
    }
  };

  const addItem = (e) => {
    let newId = id + 1;
    setItemTypeArray((prev) => [...prev, "product"]);
    setProductData((prev) => [...prev, e]);
    setId(newId);
    setNewAdd(true);
    setFinalAdd(true);
    setTimeout(() => {
      setFinalAdd(false);
    }, 2000);
  };

  const removeItem = (index) => {
    const newProductData = product_data.filter((_, i) => i !== index);
    const newItemTypeArray = itemTypeArray.filter((_, i) => i !== index);
    // Update the state with the new arrays
    setProductData(newProductData);
    setItemTypeArray(newItemTypeArray);
  };

  const handleFormChange = (e) => {
    const { name, value } = e.target;
    if (value.length > maxLength) {
      alert("You have exceeded the maximum character limit");
    } else {
      setFormDataValue((prevFormData) => ({
        ...prevFormData,
        [name]: value,
      }));
    }
  };

  const getProductName = (event, index) => {
    const isProductAlreadyAdded = product_data.some(
      (product) => product.name === event.label
    );

    if (isProductAlreadyAdded) {
      setErrorMessage("Product is already added");
      setTimeout(() => {
        setErrorMessage("");
      }, 3000);
      return;
    } else {
      setErrorMessage("");
    }
    const foundProduct = product_list.find((el) => el.name === event.label);
    if (foundProduct) {
      const updatedProducts = [...product_data];
      displayAllProducts(foundProduct.product_id, customer_id).then((res) => {
        updatedProducts[index] = {
          ...updatedProducts[index],
          name: foundProduct.name,
          product_id: foundProduct.product_id,
          brand: res.brand,
          quantity_type: res.quantity_type,
          brand_id: res.brand_id ? res.brand_id : 0,
          quantity: 0,
          category_id: 0,
          type: "product",
        };
        setProductData(updatedProducts);
        setProductName(foundProduct.productName);
        setProductSelected(true);
      });
    }
  };

  const getCategoryName = (event, index) => {
    const isProductAlreadyAdded = product_data.some(
      (product) => product.name === event.label
    );

    if (isProductAlreadyAdded) {
      setErrorMessage("Category is already added");
      setTimeout(() => {
        setErrorMessage("");
      }, 3000);
      return;
    } else {
      setErrorMessage("");
    }
    let category = category_list.find((el) => el.name === event.label);
    if (category) {
      let updatedProductData = [...product_data];
      updatedProductData[index] = {
        ...updatedProductData[index],
        name: category.name,
        product_id: 0,
        category_id: 0,
        quantity: 0,
        type: "category",
        brand: "",
        brand_id: 0,
      };
      setProductData(updatedProductData);
      setProductName(category.productName);
      setProductSelected(true);
    }
  };

  const handleOtherName = (index, event) => {
    const values = [...product_data];
    values[index][event.target.name] = event.target.value;
    setProductData(values);
  };

  const handleAttachmentClick = (url) => {
    window.open(url, "_blank");
  };

  const displayAllProducts = async (product_id, customer_id) => {
    const requestoption = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${loginedUserDetails.token}`,
      },
      data: {
        product_id: product_id,
        customer_id: customer_id,
        status: "2"
      },
    };
    return await Axios(apiUrl + "/get-product-detail-by-id", requestoption)
      .then((data) => {
        return data.data.data[0];
      })
      .catch((err) => console.log(err));
  };

  const handleLocationChange = (e) => {
    const newValue = { label: e.target.value, value: e.target.value };
    setSelectVisible(true);
  };

  const getCities = (e) => {
    let city = googleData.filter((el) => `${el.addr}` == e.value)[0];
    const cityAddr = `${city.addr}`;
    const cityPincode = `${city.pincode}`;
    // setLocation(city.addr);
    setPincode(cityPincode);
  };

  return (
    <>
      {errorMessage ? (
        <ErrorHandling message={errorMessage} type={"danger"} />
      ) : null}
      {successMessage ? (
        <ErrorHandling message={successMessage} type={"success"} />
      ) : null}

      <div>
        <Form noValidate validated={formValidate}>
          <div className={`${classes.requirement_detail_wrapper} ${classes.userDetailcnt}`}>
            <Form.Row>
              <Col lg={4} xs={12}>
                <Form.Group className={classes.formGroup}>
                  <div className={classes.main_card_item}>
                    <div className="w-100">
                      <Form.Label className={`${classes.customer_title} ${classes.customer_title_mobile}`}>
                        Customer Name

                      </Form.Label>
                      <div className={`${classes.customer_para} ${classes.customer_name} ${classes.marginLeftZero}`}>{customer_name}</div>
                      <p className={classes.customerRoleMobile}>{customer_role}</p>
                      <hr className={`${classes.dottedBorder} ${classes.dottedBorderuserData}`} />
                    </div>
                    <hr className={classes.divider} />
                  </div>
                </Form.Group>
              </Col>
              <Col lg={4} xs={12}>
                <Form.Group className={classes.formGroup}>
                  <div className={classes.main_card_item}>
                    <div className={`${classes.main_card_item_wrapper} m-0`}>
                      <Form.Label className={`${classes.customer_title} ${classes.customer_title_mobile}`}>
                        Account Name
                      </Form.Label>
                      <img src={building} alt="bank icon" />
                      <div className={classes.customer_para}>
                        {account_name}
                      </div>
                    </div>
                    <hr className={classes.divider} />
                  </div>
                </Form.Group>
              </Col>
              <Col lg={4} xs={12}>
                <Form.Group className={classes.formGroup}>
                  <div className={classes.main_card_item}>
                    <div className={`${classes.main_card_item_wrapper}`}>
                      <Form.Label className={`${classes.customer_title} ${classes.customer_title_mobile}`}>
                        Mobile
                      </Form.Label>
                      <img src={phoneIcon} alt="bank icon" />
                      <div className={classes.customer_para}>
                        {customer_mobile}
                      </div>
                    </div>
                  </div>
                </Form.Group>
              </Col>
              <Col lg={4} xs={12}>
                <Form.Group className={classes.formGroup}>
                  <div className={classes.main_card_item}>
                    <div className={`${classes.main_card_item_wrapper}`}>
                      <Form.Label className={`${classes.customer_title} ${classes.customer_title_mobile}`}>
                        Email
                      </Form.Label>
                      <img src={emailIcon} alt="bank icon" />
                      <div className={classes.customer_para}>
                        {customer_email}
                      </div>
                    </div>
                    <hr className={classes.divider} />
                  </div>
                </Form.Group>
              </Col>
              <Col lg={4} xs={12} className={classes.customerRoleDesktop}>
                <Form.Group className={classes.formGroup}>
                  <div className={classes.main_card_item}>
                    <div className={`${classes.main_card_item_wrapper}`}>
                      <Form.Label className={`${classes.customer_title} ${classes.customer_title_mobile}`}>
                        Role
                      </Form.Label>
                      <div className={classes.customer_para}>
                        {customer_role}
                      </div>
                    </div>
                    <hr className={classes.divider} />
                  </div>
                </Form.Group>
              </Col>
              <Col lg={4} xs={12}>
                <Form.Group className={classes.formGroup}>
                  <div>
                    <div className={`${classes.main_card_item_wrapper}`}>
                      <Form.Label className={`${classes.customer_title} ${classes.customer_title_mobile}`}>
                        Delivery Location
                      </Form.Label>
                      <img src={locationIcon} alt="location icon" />
                      <div className={`${classes.customer_para} ${classes.addLocationDesktop}`}>
                        {!isSelectVisible ? (
                          <Form.Control
                            type="text"
                            name="location"
                            onChange={handleLocationChange}
                            value={location.label}
                            className={classes.customInput}
                          />
                        ) : (
                          <AsyncSelect
                            components={{ Input: CustomInput }}
                            className={classes.customSelect}
                            styles={customStyles}
                            value={location}
                            defaultOptions
                            loadOptions={debouncedFetchLocationOptions}
                            onChange={handleAsyncSelectChange}
                            onBlur={handleNoSelect}
                            onKeyPress={(e) => {
                              e.key === "Enter" && e.preventDefault();
                            }}
                            required
                          />
                        )}
                      </div>
                      <div className={classes.addLocationBtnCnt}>
                        <div className={classes.customer_para}>
                          {location.label}
                        </div>
                        <div className={classes.addLocationBtnWrapper}>
                          <button
                            type="button"
                            className={classes.addLocationbtn}
                            onClick={showModalOpen}
                          >
                            <img src={editIcon} alt="edit icon" />Edit
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </Form.Group>
              </Col>
            </Form.Row>
          </div>
          <SalesProductListing
            handleFileSelect={handleFileSelect}
            products={product_data}
            ListHeading={`Requirement Details`}
            RemarksHeading={`KAM Remarks`}
            maxLength={maxLength}
            addItem={addItem}
            removeItem={removeItem}
            kamRemarks={formDataValue.kam_remarks}
            handleAttachmentClick={handleAttachmentClick}
            productName
            disabled={false}
            handleSelectChooseType={handleSelectChooseType}
            getProductName={getProductName}
            getCategoryName={getCategoryName}
            handleProductList={handleProductList}
            handleCategoryList={handleCategoryList}
            handleChange={handleChange}
            handleCheckboxValue={handleCheckboxValue}
            handleOtherName={handleOtherName}
            handleFormChange={handleFormChange}
            type={"product"}
          />
        </Form>
      </div>
      <div className={classes.footer}>
        <div className="row">
          <Col lg={12} style={{ justifyContent: "end", display: "flex" }}>
            <Button
              className={classes.btn_blue}
              onClick={createSourceRequestSubmit}
              disabled={submitLoading}
            >
              {submitLoading ? (
                <Spinner animation="border" role="status">
                  <span className="sr-only">Loading...</span>
                </Spinner>
              ) : (
                "Submit"
              )}
            </Button>
          </Col>
        </div>
      </div>
      {openLocationModal &&
        <EditLocation
          show={showModalOpen}
          close={showModalClose}
          deliverLocation={""}
          handleLocationChange={handleLocationChange}
          location={location}
          isSelectVisible={isSelectVisible}
          debouncedFetchLocationOptions={debouncedFetchLocationOptions}
          handleAsyncSelectChange={handleAsyncSelectChange}
          handleNoSelect={handleNoSelect}
        />
      }
    </>
  );
};

export default CreateSourceRequest;
