import React, { Component } from "react";
import { getUserDetails } from "../../Login/getLoginnedUserDetails";
import { Row, Col, Form, Button, Alert, Select } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import SelectSearch from "react-select-search";
import axios from "axios";
import Constants from "../../../Config";
import AddProducts from "./AddProducts";
const apiUrl = Constants.API_URL;

export class AddItemInRfq extends Component {
  state = {
    category_name: "",
    categoryList: [],
    categoryArray: [],
    category_id: "",
    categSelect: false,
    type: "",
    availabe_stock: 0,
    selectedValue: "product",
    product_name: "",
    product_quantity: 0,

    productArray: [],
    productList: [],
    product_id: "",
    product_price: 0,
    category_price: 0,
    other_quantity: 0,
    other_name: "",
  };
  componentDidMount() {
    
  }

  handleChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });

    // this.props.addNewProduct({
    //   product_id: this.state.product_id,
    //   quantity: this.state.product_quantity,
    // });
  };
  handleSelect = (e) => {
    this.setState({
      selectedValue: e.target.value,
    });
  };

  getValue = (e) => {
    axios({
      method: "POST",
      url: apiUrl + "/get-products-by-keyword",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${getUserDetails.token}`,
      },
      data: { keyword: e },
    })
      .then((data) => {
        this.setState({
          productList: data.data.data,
          productArray: data.data.data.map((item) => {
            return {
              name: item.name,
              value: item.name,
            };
          }),
        });
      })
      .catch((error) => console.log(error));
  };
  getProductName = (e) => {
    let index = this.state.productList.filter((el) => el.name == e)[0];
    let productId = index.product_id;
    // this.props.getNew(productId)
    this.setState({
      product_id: productId,
      product_name: e,
      availabe_stock: index.quantity,
      product_price: Number(index.price).toFixed(2),

      // product_quantity: index.moq,

      product_quantity: Number(index.pack_quantity),
    });

    this.props.addNewItem({
      product_id: productId,
      category_id: 0,
      quantity: index.pack_quantity,
      name: index.name,
      type: "product",
      price: Number(index.price).toFixed(2),
    });
  };

  getCategory = (e) => {
    axios({
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${getUserDetails.token}`,
      },
      url: apiUrl + "/get-product-category",
      data: {
        keyword: e,
      },
    })
      .then((data) => {
        this.setState({
          categoryList: data.data.data,
          categoryArray: data.data.data.map((item) => {
            return {
              name: item.name,
              value: item.name,
            };
          }),
        });
      })
      .catch((error) => console.log(error));
  };

  getCategoryName = (e) => {
    let categ = this.state.categoryList.filter((el) => el.name == e)[0];
    categ["type"] = "category";
    // categ.push(type: "category")
    this.setState({
      category_name: e,
      category_id: categ.category_id,
      type: categ.type,
      categSelect: true,
      category_quantity: categ.quantity,
    });

    this.props.addNewItem({
      category_id: categ.category_id,
      product_id: 0,
      quantity: 1,
      name: e,
      type: "category",
      price: 0,
    });
  };

  render() {
    return (
      <>
        {this.state.selectedValue == "product" ? (
          <>
            <Row className="mb-2">
              <Col lg={2}>Choose Type</Col>
              <Col lg={3}>Product Detail</Col>
              <Col lg={2}>Stock</Col>
              <Col lg={2}>Quantity</Col>
              <Col lg={2}>Price</Col>
            </Row>
            <div
              key={"name" + this.props.item.itemName}
              className={"name" + this.props.item.itemName}
            >
              <div className="d-flex">
                <Col lg={2}>
                  <Form.Group
                    controlId="formBasicTypeName"
                    key={this.props.ind}
                  >
                    <Form.Control
                      as="select"
                      onChange={this.handleSelect}
                      value={this.state.selectedValue}
                      disabled={this.props.categoryDisable} //the disable condition is for Add item modal of CreateQuote Component
                    >
                      <option value="product">Product</option>
                      <option value="category">Category</option>
                      <option value="other">Other</option>
                    </Form.Control>
                  </Form.Group>
                </Col>

                {/* <AddProducts
                    index={this.props.ind}
                    itemDetailArray={this.state.itemDetailArray}
                    addNew={this.addNew}
                    removeItem={this.removeItem}
                    addNewProduct={this.addNewProduct}
                    // quantityDisable={this.quantityDisable}
                  /> */}
                <Col lg={3} className="customeAutoComplete">
                  <Form.Group
                    controlId="formBasicProductName"
                    key={this.props.index}
                  >
                    <SelectSearch
                      options={this.state.productArray}
                      getOptions={(query) => this.getValue(query)}
                      search
                      placeholder="Product name"
                      onChange={this.getProductName}
                      value={this.state.product_name}
                    />
                  </Form.Group>
                </Col>
                <Col lg={2}>
                  <Form.Group
                    controlId="formBasicProductStock"
                    key={this.props.index}
                  >
                    <Form.Control
                      type="text"
                      placeholder="Stock"
                      value={this.state.availabe_stock}
                      name="availabe_stock"
                      readOnly="readOnly"
                    />
                  </Form.Group>
                </Col>
                <Col lg={2}>
                  <Form.Group
                    controlId="formBasicProductQuantity"
                    key={this.props.index}
                    className="d-flex"
                    // style={{ display: "flex" }}
                  >
                    <Form.Control
                      className={this.state.quantity_error ? "error" : ""}
                      type="text"
                      placeholder="Quantity"
                      name="product_quantity"
                      value={this.state.product_quantity}
                      onChange={this.handleChange}
                      onBlur={() =>
                        this.props.addNewItem({
                          name: this.state.product_name,
                          product_id: this.state.product_id,
                          category_id: 0,
                          quantity: this.state.product_quantity,
                          type: "product",
                          price: this.state.product_price,
                        })
                      }
                      required
                    />
                  </Form.Group>
                </Col>
                <Col sm={2}>
                  <Form.Group controlId="formRfqPrice">
                    <Form.Control
                      placeholder="Price"
                      name="product_price"
                      value={this.state.product_price}
                      onChange={this.handleChange}
                      disabled
                    />
                  </Form.Group>
                </Col>
                <Col lg={1}>
                  {this.props.itemDetailArray.length != 1 ? (
                    <>
                      <FontAwesomeIcon
                        icon="minus"
                        id={this.props.ind}
                        onClick={() => this.props.removeItem(this.props.ind)}
                        className="removeIcon"
                      />
                      {this.props.itemDetailArray.length ==
                      this.props.ind + 1 ? (
                        <FontAwesomeIcon
                          icon="plus"
                          onClick={() =>
                            this.props.addNew({
                              product_id: this.state.product_id,
                              quantity: this.state.product_quantity,
                              // name: this.state.product_name
                            })
                          }
                          className="addIcon"
                        />
                      ) : null}
                    </>
                  ) : (
                    <FontAwesomeIcon
                      icon="plus"
                      onClick={() =>
                        this.props.addNew({
                          product_id: this.state.product_id,
                          quantity: this.state.product_quantity,
                          // name: this.state.product_name
                        })
                      }
                      className="addIcon"
                    />
                  )}
                </Col>
              </div>
            </div>
          </>
        ) : this.state.selectedValue == "category" ? (
          <>
            <Row className="mb-2">
              <Col lg={2}>Choose Type</Col>
              <Col lg={3}>Category detail</Col>
              <Col lg={3}>Quantity</Col>
              <Col lg={2}>Price</Col>
            </Row>

            <Row>
              <Col lg={2}>
                <Form.Group
                  controlId="formBasicTypeName"
                  // key={item.index}
                >
                  <Form.Control
                    as="select"
                    onChange={this.handleSelect}
                    value={this.state.selectedValue}
                  >
                    <option value="product">Product</option>
                    <option value="category">Category</option>
                    <option value="other">Other</option>
                  </Form.Control>
                </Form.Group>
              </Col>
              <Col lg={3} className="customeAutoComplete">
                <Form.Group controlId="formBasicCategoryName">
                  <SelectSearch
                    options={this.state.categoryArray}
                    getOptions={(query) => this.getCategory(query)}
                    search
                    placeholder="Category name"
                    onChange={this.getCategoryName}
                    value={this.state.category_name}
                  />
                </Form.Group>
              </Col>
              <Col lg={2}>
                <Form.Group
                  controlId="formBasicProductQuantity"
                  key={this.props.index}
                  className="d-flex"
                  // style={{ display: "flex" }}
                >
                  <Form.Control
                    className={this.state.quantity_error ? "error" : ""}
                    type="text"
                    placeholder="Quantity"
                    onBlur={() =>
                      this.props.addNewItem({
                        name: this.state.category_name,
                        category_id: this.state.category_id,
                        product_id: 0,
                        quantity: this.state.category_quantity,
                        type: "category",
                        price: 0,
                      })
                    }
                    name="category_quantity"
                    defaultValue={1}
                    value={this.state.category_quantity}
                    onChange={this.handleChange}
                    required
                  />
                </Form.Group>
              </Col>
              <Col sm={2}>
                <Form.Group controlId="formRfqPrice">
                  <Form.Control
                    placeholder="Price"
                    name="price"
                    // value={data.productPrice}
                    onChange={this.handleChange}
                    disabled
                  />
                </Form.Group>
              </Col>
              <Col lg={1}>
                {this.props.itemDetailArray.length != 1 ? (
                  <>
                    <FontAwesomeIcon
                      icon="minus"
                      id={this.props.ind}
                      onClick={() => this.props.removeItem(this.props.ind)}
                      className="removeIcon"
                    />
                    {this.props.itemDetailArray.length == this.props.ind + 1 ? (
                      <FontAwesomeIcon
                        icon="plus"
                        onClick={() =>
                          this.props.addNew({
                            product_id: this.state.category_id,
                            quantity: this.state.category_quantity,
                            // name: this.state.product_name
                          })
                        }
                        className="addIcon"
                      />
                    ) : null}
                  </>
                ) : (
                  <FontAwesomeIcon
                    icon="plus"
                    onClick={() =>
                      this.props.addNew({
                        product_id: this.state.category_id,
                        quantity: this.state.category_quantity,
                        // name: this.state.product_name
                      })
                    }
                    className="addIcon"
                  />
                )}
              </Col>
            </Row>
          </>
        ) : (
          <>
          
            <Row>
            <Col lg={2}>
                <Form.Group
                  controlId="formBasicTypeName"
                  // key={item.index}
                >
                  <Form.Label>Choose Type</Form.Label>
                  <Form.Control
                    as="select"
                    onChange={this.handleSelect}
                    value={this.state.selectedValue}
                  >
                    <option value="product">Product</option>
                    <option value="category">Category</option>
                    <option value="other">Other</option>
                  </Form.Control>
                </Form.Group>
              </Col>
            <Col sm={4}>
              <Form.Group controlId="formBasicEmail">
                <Form.Label>Other </Form.Label>
                <Form.Control
                  placeholder="Other"
                  name="other_name"
                  // defaultValue={data.name}
                  value={this.state.other_name}
                  onChange={this.handleChange}
                  disabled={this.state.isDisable}
                  // onBlur={() => {
                  //   this.props.addNewItem({
                  //     name: this.state.other_name,
                  //     product_id: 0,
                  //     category_id: 0,
                  //     type: "other",
                  //     quantity: this.state.other_quantity,
                  //     price: 0 ,
                  //   });
                  // }}
                />
              </Form.Group>
            </Col>
            <Col sm={2}>
              <Form.Group controlId="formBasicQuantity">
                <Form.Label>Quantity</Form.Label>
                <Form.Control
                  placeholder="Quantity"
                  name="other_quantity"
                  value={this.state.other_quantity}
                  onChange={this.handleChange}
                  onBlur={() => {
                    this.props.addNewItem({
                      name: this.state.other_name,
                      product_id: 0,
                      category_id: 0,
                      type: "other",
                      quantity: this.state.other_quantity,
                      price: 0 ,
                    });
                  }}
                  required
                  // disabled
                />
              </Form.Group>
            </Col>
            <Col sm={2}>
              <Form.Group controlId="formBasicEmail">
                <Form.Label>Price</Form.Label>
                <Form.Control
                  placeholder="Price"
                  name="price"
                    value={0}
                  // onChange={this.handleChange}
                  disabled
                />
              </Form.Group>
            </Col>
            <Col lg={1}>
                {this.props.itemDetailArray.length != 1 ? (
                  <>
                    <FontAwesomeIcon
                      icon="minus"
                      id={this.props.ind}
                      onClick={() => this.props.removeItem(this.props.ind)}
                      className="removeIcon"
                    />
                    {this.props.itemDetailArray.length == this.props.ind + 1 ? (
                      <FontAwesomeIcon
                        icon="plus"
                        onClick={() =>
                          this.props.addNew({
                            product_id: this.state.category_id,
                            quantity: this.state.category_quantity,
                            // name: this.state.product_name
                          })
                        }
                        className="addIcon"
                      />
                    ) : null}
                  </>
                ) : (
                  <FontAwesomeIcon
                    icon="plus"
                    onClick={() =>
                      this.props.addNew({
                        product_id: this.state.category_id,
                        quantity: this.state.category_quantity,
                        // name: this.state.product_name
                      })
                    }
                    className="addIcon"
                  />
                )}
              </Col>
            </Row>
          </>
        )}

        {/* <Col lg={1}>
          {this.props.itemDetailArray.length!= 1 ? (
            <>
              <FontAwesomeIcon
                icon="minus"
                id={this.props.ind}
                onClick={() => this.props.removeItem(this.props.ind)}
                className="removeIcon"
              />
              {this.props.itemDetailArray.length == this.props.ind + 1 ? (
                <FontAwesomeIcon
                  icon="plus"
                  onClick={() =>
                    this.props.addNew({
                      product_id: this.state.product_id,
                      quantity: this.state.product_quantity,
                      // name: this.state.product_name
                    })
                  }
                  className="addIcon"
                />
              ) : null}
            </>
          ) : (
            <FontAwesomeIcon
              icon="plus"
              onClick={() =>
                this.props.addNew({
                  product_id: this.state.product_id,
                  quantity: this.state.product_quantity,
                  // name: this.state.product_name
                })
              }
              className="addIcon"
            />
          )}
        </Col> */}
      </>
    );
  }
}

export default AddItemInRfq;
