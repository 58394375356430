import React, { useState } from "react";
import { Button, Collapse } from "react-bootstrap";
import DateRangePicker, {
  startOfDay,
  endOfDay,
} from "react-bootstrap-daterangepicker";
import moment from "moment";
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap-daterangepicker/daterangepicker.css";
const RfqDashBoard = (props) => {
  const { setDateRange } = props;
  const [open, setOpen] = useState(false);
  const [state, setState] = useState({
    start: moment().subtract(29, "days"),
    end: moment(),
  });
  const { start, end } = state;
  const handleCallback = (start, end) => {
    setState({ start, end });
    setDateRange({ start, end });
  };
  const label =
    start.format("MMMM D, YYYY") + " - " + end.format("MMMM D, YYYY");
  return (
    <>
      <Button
        onClick={() => setOpen(!open)}
        aria-controls="example-collapse-text"
        aria-expanded={open}
      >
        click
      </Button>
      <Collapse in={open}>
        <div
          className="card table-card quoteRequestPage"
          style={{ border: "none" }}
        >
          <table>
            <tr>
              <td>
                <DateRangePicker
                  initialSettings={{
                    startDate: start.toDate(),
                    endDate: end.toDate(),
                    ranges: {
                      Today: [moment().toDate(), moment().toDate()],
                      Yesterday: [
                        moment().subtract(1, "days").toDate(),
                        moment().subtract(1, "days").toDate(),
                      ],
                      "Last 7 Days": [
                        moment().subtract(6, "days").toDate(),
                        moment().toDate(),
                      ],
                      "Last 30 Days": [
                        moment().subtract(29, "days").toDate(),
                        moment().toDate(),
                      ],
                      "This Month": [
                        moment().startOf("month").toDate(),
                        moment().endOf("month").toDate(),
                      ],
                      "Last Month": [
                        moment().subtract(1, "month").startOf("month").toDate(),
                        moment().subtract(1, "month").endOf("month").toDate(),
                      ],
                    },
                  }}
                  onCallback={handleCallback}
                >
                  <div
                    id="reportrange"
                    className="col-4"
                    style={{
                      background: "#fff",
                      cursor: "pointer",
                      padding: "5px 10px",
                      border: "1px solid #ccc",
                      width: "100%",
                    }}
                  >
                    <i className="fa fa-calendar"></i>&nbsp;
                    <span>{label}</span> <i className="fa fa-caret-down"></i>
                  </div>
                </DateRangePicker>
              </td>
            </tr>
            <tr>
              <td>Agent</td>
              <td>New</td>
              <td>Follwup</td>
              <td>Convert Quote</td>
              <td>Close-Won</td>
              <td>Close-Lost</td>
              <td>Total</td>
            </tr>
          </table>
        </div>
      </Collapse>
    </>
  );
};
export default RfqDashBoard;
