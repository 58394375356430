import React from 'react';
import moment from 'moment';

class Footer extends React.Component {
    
    render() {
        return  <footer className="footer">
                <div className="w-100 clearfix">
                    <span className="text-center text-sm-left d-md-inline-block copy_right">Copyright {moment().year()} McCoyMart. All Rights Reserved.</span>                        
                </div>
            </footer>;
    }
}


export default Footer;