import React from "react";
import { useState } from "react";
import { getUserDetails } from "../../Login/getLoginnedUserDetails";
import { Link, Redirect, withRouter } from "react-router-dom";
import {
  Alert,
  Spinner,
  Button,
  Modal,
  Form,
  Row,
  Col,
  Collapse,
  Table,
  Dropdown,
} from "react-bootstrap";
import axios from "axios";
import { CSVLink, CSVDownload } from "react-csv";
import * as XLSX from "xlsx";
import Constants from "../../../Config";
import ApproveQuote from "./ApproveQuote";
import "./QuoteRequest.css";
import BootstrapTable from "react-bootstrap-table-next";
import Select, { components } from "react-select";
import AsyncSelect from "react-select/async";
import Pagination from "../Common/Pagination";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faDownload } from "@fortawesome/free-solid-svg-icons";
import { faDoorClosed } from "@fortawesome/free-solid-svg-icons";
import RfqDashBoard from "./RfqDashBoard";
import Daterangepicker from "../Common/Daterangepicker";
// import SelectOption from "./SelectOption";
import CreateQuote from "./CreateQuote";
import TicketRaise from "./TicketRaise";
import RfqQuoteDetail from "./RfqQuoteDetail";
import Axios from "axios";
import DateRangeNew from "../../DashBoardWidgets/NewWidgets.js/DateRangeNew";
// import exportFromJSON from 'export-from-json'

const apiUrl = Constants.API_URL;
const webUrl = Constants.WEB_URL;

const InputOption = ({
  getStyles,
  Icon,
  isDisabled,
  isFocused,
  isSelected,
  children,
  innerProps,
  ...rest
}) => {
  const [isActive, setIsActive] = useState(false);
  const onMouseDown = () => setIsActive(true);
  const onMouseUp = () => setIsActive(false);
  const onMouseLeave = () => setIsActive(false);

  // styles
  let bg = "transparent";
  if (isFocused) bg = "#bebebe";
  if (isActive) bg = "#B2D4FF";

  const style = {
    alignItems: "center",
    backgroundColor: bg,
    color: "inherit",
    display: "flex ",
    gap: "5px",
  };

  // prop assignment
  const props = {
    ...innerProps,
    onMouseDown,
    onMouseUp,
    onMouseLeave,
    style,
  };

  return (
    <components.Option
      {...rest}
      isDisabled={isDisabled}
      isFocused={isFocused}
      isSelected={isSelected}
      getStyles={getStyles}
      innerProps={props}
    >
      <input type="checkbox" checked={isSelected} /> {children}
    </components.Option>
  );
};

const headers = [
  { label: "RFQ ID", key: "id" },
  { label: "Lead ID", key: "lead_id" },
  { label: "Customer ID", key: "customer_id" },
  { label: "Account Name", key: "accountName" }, // add new account name
  { label: "Name", key: "customer_name" },
  { label: "City", key: "city_name" },
  { label: "Email", key: "customer_email" },
  { label: "Mobile", key: "customer_mobile" },
  { label: "Product Name || Model || Quantity || Price", key: "product" },
  { label: "Total Price", key: "total_price" },
  { label: "Message", key: "message" },
  { label: "Call Status", key: "call_status" },
  { label: "Call Remarks", key: "call_remarks" },
  { label: "RFQ Status", key: "status" },
  { label: "Assigned To", key: "assign_to" },
  { label: "Source", key: "source" },
  { label: "Attachment", key: "attachment" },
  { label: "Quote Type", key: "quote_type" },
  { label: "Campaign", key: "campaign" },
  { label: "Ticket ID", key: "ticket_id" },
  // { label: "Buying For", key: "buying_for" },
  { label: "Priority", key: "rfq_priority" },
  { label: "Date", key: "date_only" },
  { label: "Time", key: "time_only" },
  { label: "First call attempt", key: "firstCallAttempt_agent_name" },
  { label: "First call attempt", key: "firstCallAttempt_agent_note" },
  { label: "First call attempt", key: "firstCallAttempt_created_at" },
  { label: "First call attempt", key: "firstCallAttempt_id" },
  { label: "First call attempt", key: "firstCallAttempt_call_status" },

  { label: "Last call attempt", key: "lastCallAttempt_agent_name" },
  { label: "Last call attempt", key: "lastCallAttempt_agent_note" },
  { label: "Last call attempt", key: "lastCallAttempt_created_at" },
  { label: "Last call attempt", key: "lastCallAttempt_id" },
  { label: "Last call attempt", key: "lastCallAttempt_call_status" },
];

const status = [{ status2: "dublicate" }, { status2: "convertedToQuote" }];

// Custom debounce function
function debounce(func, wait) {
  let timeout;
  return function(...args) {
    clearTimeout(timeout);
    timeout = setTimeout(() => func.apply(this, args), wait);
  };
}

const CustomMultiValueRemove = () => null;

class Quote extends React.Component {
  constructor(props) {
    super(props);
    this.csvLinkEl = React.createRef();
    this.detailRef = React.createRef();
    this.filterByStatus = this.filterByStatus.bind(this);
    this.debouncedFilterByStatus = debounce(this.filterByStatus, 1000);
    this.filterByAssignTo = this.filterByAssignTo.bind(this);
    this.debouncedfilterByAssignTo = debounce(this.filterByAssignTo, 1000);
    this.filterByLastQuoteType = this.filterByLastQuoteType.bind(this);
    this.debouncedfilterByLastQuoteType = debounce(this.filterByLastQuoteType, 1000);
    this.filterByRole = this.filterByRole.bind(this);
    this.debouncedfilterByRole = debounce(this.filterByRole, 1000);
    this.filterByType = this.filterByType.bind(this);
    this.debouncedfilterByType = debounce(this.filterByType, 1000);
  }

  state = {
    QuoteList: [],
    QuoteListDownload: [],
    QuoteListProductDownload: [],
    requestQuoteData: [],
    message: false,
    isLoading: false,
    isDownloading: false,
    requestHeading: "",
    selectedStatus: [],
    selectedType: [],
    selectedDate: "",
    selecteTUserID: "",
    selectedBuyingFor: "",
    call_status_array: [],
    buying_for_array: [
      { name: "Project Use", value: "Project Use" },
      { name: "Reselling", value: "Reselling" },
      { name: "Own construction", value: "Own construction" },
    ],
    priorityArray: ["Highest", "High", "Medium", "Low"],

    all_rfq_type: [],
    all_quote_users: [],
    selectedRow: [],
    requestQuoteID: 0,
    sourcingStatus: "",
    show: false,
    showTicket: false,
    isPaginationLoading: 0,
    total_item: 25,
    current_page: 1,
    total_record: 0,
    showTypeFilter: 0,
    checkBoxList: [],
    allSelectInit: false,
    downloadAttachment: false,
    open: false,
    setOpen: false,
    date_from: "",
    date_to: "",
    logined_user_id: [],
    dublicate: [],
    convertedToQuote: [],
    options: "",
    options2: "",
    showDropOptions: null,
    Dropdown_value: "",
    status2: [],
    RFQ_id: "",
    status_array: [],
    redirect: false,
    id_New: "",
    id: "8",
    agent_id: "",
    ticket_id: "",
    rfq_priority: "",
    optionObject: {},
    success_message: "",
    dateType: "",
    qualificationType:"",
    mobile: "",
    accountNumber: "", // add new account number filter key
    ticketId: "", // add new filter key
    searchTicketId: [], // add new search ticked id
    sourcingId:"",
    searchSourcingId: [],
    showQuoteDetail: false,
    apiData: [],
    FollowupData: [],
    showFollowup: true,
    downloadOption: "",
    lastQuoteType: [],
    quoteStatus: [],
    agentOptions: [],
    allowedSlugs: [],
    allowedSlugsQuote: [],
    addSlugFlag: false,
    allAssignableAgents: [],
    businessGroupArray: [],
    roleArray: [],
    businessGroup: [],
    role: [],
    showProductDetailModal: false,
    productData: [],
    businessGroupChangeMessage: "",
    roleChangeMessage: "",
    showAttachments: false,
    attachment: [],
    errorMessage: "",
    sourcingStatus: "",
  };

  componentDidMount() {
    let loginedUserDetails = JSON.parse(localStorage.getItem("userData"));
    this.setState({
      showTypeFilter: 1,
      allowedSlugs: this.props.childSlugProp["quote-requests"],
      allowedSlugsQuote: this.props.childSlugProp["listQuote"],
      addSlugFlag: this.props.groupSlugRoutes.includes("createRfq"),
    });

    this.getFollowUp();
    this.getAllQuote();
    this.getTotalRecords();
    this.getAllQuoteStatus();
    this.getLastQuoteStatus();
    this.getAllQuoteTypes();
    this.getAllQuoteUsers();
    // this.getBusinessGroups();
    this.getRoles();
    // this.getAllAssignmentAgents();
    // this.getRFQID();
  }

  handleChange = (e, id) => {
    this.setState(
      {
        optionObject: {
          rfq_status: e.target.value,
          rfq_id: id,
        },
      },
      () => {
        this.getStatusSecond(id);
      }
    );
  };
  handleChange2 = (e, id) => {
    this.setState(
      {
        optionObject: {
          priority: e.target.value,
          rfq_id: id,
        },
      },
      () => {
        this.getStatusSecond(id);
      }
    );
  };
  setPOState = (POID, QUOTEID) => {
    const state = {
      poID: POID,
      quoteId: QUOTEID,
      timestamp: new Date().getTime(),
      // quote_type: item.quote_type,
    };

    localStorage.setItem("poState", JSON.stringify(state));
  };
  getFollowUp = () => {
    const requestoption = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${this.props.getUserDetails.token}`,
      },
    };
    return Axios(apiUrl + "/agents-work-data/rfq", requestoption)
      .then((data) => {
        let apiData = data.data.data;
        this.setState({
          apiData,
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  changeRFQ = (item) => (e) => {
    // if(this.state.options === "id"){
    this.setState({
      redirect: true,
      RFQ_id: item.id,
    });

    this.detailRef.current = {
      id: item.id,
      name: item.customer_name,
      mobile: item.customer_mobile,
      email: item.customer_email,
    };
  };
  // getBusinessGroups = () => {
  //   const options = {
  //     method: "GET",
  //     headers: {
  //       "Content-Type": "application/json",
  //       Authorization: `Bearer ${this.props.getUserDetails.token}`,
  //     },
  //   };
  //   return axios(apiUrl + "/rfq-business-groups", options)
  //     .then((data) => {
  //       let businessGroupArray2 = data.data.data;
  //       let businessGroupArray = businessGroupArray2.map((item) => {
  //         item["label"] = item["business_group"];
  //         item["value"] = item["business_id"];
  //         delete item["business_group"];
  //         delete item["business_id"];
  //         return item;
  //       });
  //       this.setState({ businessGroupArray });
  //     })
  //     .catch((err) => {
  //       console.log(err);
  //     });
  // };

  getRoles = () => {
    const options = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${this.props.getUserDetails.token}`,
      },
      data: {
        // quote_type: this.props.quote_type,
        // call_attempt_number: 0,
        // key: "rfq-status",
        meta_id: 4,
      },
    };
    return axios(apiUrl + "/meta-value", options)
      .then((data) => {
        let roleArray2 = data.data.data;
        let roleArray = roleArray2.map((item) => {
          item["label"] = item["meta_title"];
          item["value"] = item["meta_value"];
          delete item["meta_title"];
          delete item["meta_value"];
          return item;
        });
        this.setState({ roleArray });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  getAllQuoteStatus = () => {
    const options = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${this.props.getUserDetails.token}`,
      },
      data: {
        // quote_type: this.props.quote_type,
        // call_attempt_number: 0,
        // key: "rfq-status",
        meta_id: 27,
      },
    };
    return axios(apiUrl + "/meta-value", options)
      .then((data) => {
        let rfqstatus = data.data.data;
        let call_status_array = rfqstatus.map((item) => {
          item["label"] = item["meta_title"];
          item["value"] = item["meta_value"];
          delete item["meta_title"];
          delete item["meta_value"];
          return item;
        });
        this.setState({ call_status_array });

        // this.getAllQuote();
      })
      .catch((error) => console.log(error));
  };

  getStatusSecond = (id) => {
    // const userData = JSON.parse(localStorage.getItem('userData'));
    // const token = userData.token;

    const requestoption = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${this.props.getUserDetails.token}`,
      },
      data: this.state.optionObject,
      // rfq_status: this.state.options,
      // priority: "Medium",
      // rfq_id: this.state.RFQ_id,
    };
    return axios(apiUrl + "/rfq-status", requestoption)
      .then((data) => {
        this.setState(
          {
            status_array: data,
            success_message: data.data.message,
          },
          () => {
            setTimeout(() => {
              this.setState({
                success_message: "",
              });
            }, 3000);
          }
        );
        // window.location.reload();
      })
      .catch((error) => console.log(error));
  };

  getAllQuote = () => {
    this.setState(
      {
        isLoading: true,
      },
      () => {
        // let loginedUserDetails = JSON.parse(localStorage.getItem("userData"));
        const requestoption = {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${this.props.getUserDetails.token}`,
          },
          data: {
            quote_type: this.props.quote_type,
            phone: this.state.mobile,
            selectedStatus: this.state.selectedStatus.length
              ? this.state.selectedStatus
              : [],
            selectedType: this.state.selectedType.length
              ? this.state.selectedType
              : [],
            // logined_user_id: this.state.logined_user_id.length
            //   ? this.state.logined_user_id
            //   : this.props.getUserDetails.is_super
            //   ? []
            //   : [this.props.getUserDetails.user_id],
            logined_user_id: this.state.logined_user_id.length
              ? this.state.logined_user_id
              : [],
            total_item: this.state.total_item,
            current_page: this.state.current_page,

            date_from: this.state.date_from,
            date_to: this.state.date_to,
            type: this.state.dateType,
            qualification_type: this.state.qualificationType,
            quoteStatus: this.state.quoteStatus,
            business_group: this.state.businessGroup,
            role: this.state.role,
            ticket_id: this.state.ticketId, // add new ticket_id api key
            accountName: this.state.accountNumber ?? "", // add new account name api key
            sourcing_id: this.state.sourcingId, 
          },
        };
        return axios(apiUrl + "/get-quote-request", requestoption)
          .then((data) => {
            this.setState(
              {
                QuoteList: data.data.data,
                isLoading: false,
              },
              () => {
                const dataNew = this.state.QuoteList.map((item, index) => {
                  return {
                    sno: index + 1,
                    customer_name: item.customer_name,
                    id: item.id,
                    customer_email: item.customer_email,
                    customer_mobile: item.customer_mobile,
                    product_data: item.product,
                    // totalPrice: item.totalPrice,
                    // total_price: item.totalPrice,

                    ticket_id: item.kapture_ticket_id,
                    accountNumber: item.accountName, // add new account no
                    assign_to: item.assigned_to,
                    // attachment: item.filePath,
                    attachment: item.attachments,
                    message: item.message,
                    status: item.status,
                    // call_status: item.call_status,
                    call_count: item.call_count,
                    call_remarks: item.remarks,
                    agent_id: item.agent_id,
                    location: item.location,
                    campaign: item.campaign,
                    totalPrice: item.totalPrice,
                    source: item.source,
                    quote_type: item.quote_type,
                    business_type: item.business_type,
                    rfq_priority: item.priority,
                    lead_id: item.lead_id,
                    isQuote: item.quoteDetails.some((data) => {
                      return data.quote_id != 0;
                    }),
                    orderIDObject: item.orderData,
                    poStaus: item.po_status,
                    role: item.role,
                    business_group: item.business_group,
                    pro_business_group: item.pro_business_group, // add new key
                    pro_business_group: item.pro_business_group, // add new key
                    order_id: item.order_id,
                    order_status: item.order_status,
                    sourcing_status: item.sourcing_status,
                    sourcing_id: item.sourcing_id,
                    sourcing_agent: item.sourcing_agent,
                    // lastQuoteStatus : item.quoteDetails.reduce((acc, value) => {
                    //   return (acc = acc > value.quote_id ? acc : value.quote_status);
                    // }, 0),
                    lastQuoteStatus: item.lastQuoteStatus,
                    agent_note: item.lastCallAttempt
                      ? item.lastCallAttempt.agent_note
                      : "",
                    call_status: item.lastCallAttempt
                      ? item.lastCallAttempt.call_status
                      : "",

                    date_and_time: (
                      <span>
                        {item.date_only} {item.time_only}
                      </span>
                    ),
                    action: this.state.allowedSlugs.includes("edit") ? (
                      <>
                        <Button
                          variant="light"
                          type="button"
                          onClick={() =>
                            this.openViewDetailPopup(item.id, item.agent_id)
                          }
                          style={{
                            fontSize: 12,
                            border: "1px solid #cccccc",
                          }}
                        >
                          <FontAwesomeIcon icon="edit" />
                        </Button>
                      </>
                    ) : null,
                  };
                });
                this.setState(
                  {
                    QuoteList: dataNew,
                    isPaginationLoading: 0,
                  }
                  // () => {
                  //   this.getTotalRecords();

                  // }
                );
              }
            );
          })
          .catch((error) => {
            this.setState({
              isLoading: false,
            });
          });
      }
    );
  };

  exportsAllQuote = () => {
    return new Promise((resolve, reject) => {
      this.setState(
        {
          isDownloading: true,
        },
        () => {
          // let loginedUserDetails = JSON.parse(localStorage.getItem("userData"));
          const requestoption = {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${this.props.getUserDetails.token}`,
            },
            data: {
              quote_type: this.props.quote_type,
              selectedStatus: this.state.selectedStatus.length
                ? this.state.selectedStatus
                : [],

              // logined_user_id: this.state.logined_user_id.length
              //   ? this.state.logined_user_id
              //   : this.props.getUserDetails.is_super
              //   ? []
              //   : [this.props.getUserDetails.user_id],
              logined_user_id: this.state.logined_user_id.length
                ? this.state.logined_user_id
                : [],
              selectedType: this.state.selectedType.length
                ? this.state.selectedType
                : [],
              date_from: this.state.date_from,
              date_to: this.state.date_to,
              type: this.state.dateType,
              qualification_type: this.state.qualificationType,
              phone: this.state.mobile,
              quoteStatus: this.state.quoteStatus,
              business_group: this.state.businessGroup,
              ticket_id: this.state.ticketId, // add new ticket_id api key
              accountName: this.state.accountNumber, // add new account name api key
              sourcing_id: this.state.sourcingId
            },
          };
          return axios(apiUrl + "/get-quote-request-download", requestoption)
            .then((data) => {
              this.setState(
                {
                  QuoteListDownload: data.data.data,
                  isDownloading: false,
                },
                () => {
                  const dataNew = this.state.QuoteListDownload.map(
                    (item, index) => {
                      return {
                        // sno: index + 1,
                        "RFQ ID": item.id,
                        "Lead Reference ID": item.lead_id,
                        "Customer ID": item.customer_id,
                        "Account Name": item.accountName,
                        Name: item.customer_name,
                        City: item.location,
                        Email: item.customer_email,
                        Mobile: item.customer_mobile,

                        // product: item.product.map(
                        //   (role) =>
                        //     role.productName +
                        //     " || " +
                        //     role.model +
                        //     " || " +
                        //     role.productQuantity +
                        //     " || " +
                        //     role.productPrice +
                        //     " |~| " +
                        //     "\n"
                        // ),
                        // "Product Name": item.product_data,
                        "Total Price": item.totalPrice,

                        // total_price: item.totalPrice,

                        Priority: item.priority,

                        "SR ID": item.sourcing_id,
                        "SR status": item.sourcing_status,
                        "Sourcing Agent": item.sourcing_agent,
                        "Business Group": item.business_group,
                        Role: item.role,
                        "Assigned To": item.assigned_to,
                        Attachment: item.filePath,
                        Message: item.message,
                        "Rfq Status": item.status,

                        "Call Count": item.call_count,
                        // call_status: item.call_status,
                        // call_remarks:
                        //   item.remarks &&
                        //   item.remarks.map(
                        //     (data, index) =>
                        //       data.remarks + " || " + data.remarksDate + " \n "
                        //   ),
                        Source: item.source,
                        "Qualification Type": item.qualification_type,
                        "RFQ Type": item.quote_type,
                        "Business Type": item.business_type,
                        Date: item.date_only,
                        Time: item.time_only,

                        "First CallAttempt agent name":
                          item.firstCallAttempt.agent_name,
                        "First CallAttempt agent note":
                          item.firstCallAttempt.agent_note,
                        "First CallAttempt created at":
                          item.firstCallAttempt.created_at,
                        // firstCallAttempt_id: item.firstCallAttempt.id,
                        "First CallAttempt Call Status":
                          item.firstCallAttempt.call_status,

                        "Last CallAttempt agent name":
                          item.lastCallAttempt.agent_name,
                        "Last CallAttempt agent note":
                          item.lastCallAttempt.agent_note,
                        "Last CallAttempt created at":
                          item.lastCallAttempt.created_at,
                        // lastCallAttempt_id: item.lastCallAttempt.id,
                        "LastCallAttempt Call Status":
                          item.lastCallAttempt.call_status,
                        "Last Quote ID": item.lastQuoteStatusId,
                        "Last Quote Status": item.lastQuoteStatus,
                        "PO Status": item.po_status,
                        "Last Order Id": item.order_id,
                        "Last Order Status": item.order_status,
                        "UTM Content": item.utm_content,
                        "UTM Campaign": item.campaign,
                        "UTM Source": item.utm_source,
                        "UTM Medium": item.utm_medium,
                      };
                    }
                  );
                  resolve(dataNew);
                }
              );
            })
            .catch((error) => {
              this.setState({
                isDownloading: false,
              });
            });
        }
      );
    });
  };
  exportsAllProductQuote = () => {
    return new Promise((resolve, reject) => {
      this.setState(
        {
          isDownloading: true,
        },
        () => {
          // let loginedUserDetails = JSON.parse(localStorage.getItem("userData"));
          const requestoption = {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${this.props.getUserDetails.token}`,
            },
            data: {
              quote_type: this.props.quote_type,
              selectedStatus: this.state.selectedStatus.length
                ? this.state.selectedStatus
                : [],

              // logined_user_id: this.state.logined_user_id.length
              //   ? this.state.logined_user_id
              //   : this.props.getUserDetails.is_super
              //   ? []
              //   : [this.props.getUserDetails.user_id],
              logined_user_id: this.state.logined_user_id.length
                ? this.state.logined_user_id
                : [],
              selectedType: this.state.selectedType.length
                ? this.state.selectedType
                : [],
              date_from: this.state.date_from,
              date_to: this.state.date_to,
              type: this.state.dateType,
              qualification_type: this.state.qualificationType,
              phone: this.state.mobile,
              quoteStatus: this.state.quoteStatus,
              business_group: this.state.businessGroup,
              ticket_id: this.state.ticketId, // add new ticket_id api key
              accountName: this.state.accountNumber, // add new account name api key
              sourcing_id: this.state.sourcingId
            },
          };
          return axios(
            apiUrl + "/get-quote-request-product-download",
            requestoption
          )
            .then((data) => {
              this.setState(
                {
                  QuoteListProductDownload: data.data.data,
                  isDownloading: false,
                },
                () => {
                  const dataNew = this.state.QuoteListProductDownload.map(
                    (item, index) => {
                      return {
                        // sno: index + 1,
                        "RFQ ID": item.rfqId,
                        "Qualification Type": item.qualification_type,
                        "RFQ Type": item.rfq_type,
                        "Lead Reference ID": item.leadReferenceId,

                        Source: item.source,
                        "Customer ID": item.customer_id,
                        "Account Name": item.accountName,
                        Name: item.customerName,

                        Mobile: item.mobile,
                        Email: item.email,
                        City: item.location,
                        // "Created at": item.createdAt,

                        "Item Type": item.type,
                        "Product Name": item.name,
                        "Total Price": item.price,
                        Quantity: item.quantity,
                        "Product Amount": item.productAmount,
                        Brand: item.brand,
                        "Product Category":
                          item.type == "product"
                            ? item.productParent
                            : item.type == "category"
                              ? item.categoryParent
                              : "",
                        "L1 Category":
                          item.type == "product"
                            ? item.productL1
                            : item.type == "category"
                              ? item.categoryL1
                              : "",
                        Campaign: item.campaign,
                        "Business Group": item.business_group,
                        Role: item.role,
                        "Assigned To": item.assignedTo,
                        Attachment: item.attachment,
                        Message: item.message,
                        "Rfq Status": item.rfqStatus,
                        "Call Count": item.call_count,
                        Date: item.createdAt,
                        Time: item.time,
                        "First Call Attempt agent name":
                          item.firstCall.agent_name,
                        "First Call Attempt agent note":
                          item.firstCall.agent_note,
                        "First Call Attempt created at":
                          item.firstCall.created_at,
                        "First Call Attempt Call Status":
                          item.firstCall.call_status,
                        "Last Call Attempt agent name":
                          item.lastCall.agent_name,
                        "Last Call Attempt agent note":
                          item.lastCall.agent_note,
                        "Last Call Attempt created at":
                          item.lastCall.created_at,
                        "Last Call Attempt Call Status":
                          item.lastCall.call_status,
                        "Last Quote ID": item.lastQuoteId,
                        "Last Quote Status": item.lastQuoteStatus,
                        "UTM Campaign": item.utm_campaign,
                        "UTM Content": item.utm_content,
                        "UTM Medium": item.utm_medium,
                        "UTM Source": item.utm_source,
                        "SR ID": item.sourcing_id,
                        "SR status": item.sourcing_status,
                      };
                    }
                  );
                  resolve(dataNew);
                }
              );
            })
            .catch((error) => {
              this.setState({
                isDownloading: false,
              });
            });
        }
      );
    });
  };
  getTotalRecords = () => {
    this.setState(
      {
        isDownloading: true,
      },
      () => {
        // let loginedUserDetails = JSON.parse(localStorage.getItem("userData"));
        const requestoption = {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${this.props.getUserDetails.token}`,
          },

          data: {
            quote_type: this.props.quote_type,
            phone: this.state.mobile,
            selectedStatus: this.state.selectedStatus.length
              ? this.state.selectedStatus
              : [],
            // logined_user_id: this.state.logined_user_id.length
            //   ? this.state.logined_user_id
            //   : this.props.getUserDetails.is_super
            //   ? []
            //   : [this.props.getUserDetails.user_id],
            logined_user_id: this.state.logined_user_id.length
              ? this.state.logined_user_id
              : [],
            selectedType: this.state.selectedType.length
              ? this.state.selectedType
              : [],
            //selectedUserID:this.state.selectedUserID,
            date_from: this.state.date_from,
            date_to: this.state.date_to,
            key: "rfq",
            type: this.state.dateType,
            qualification_type: this.state.qualificationType,
            quoteStatus: this.state.quoteStatus,
            business_group: this.state.businessGroup,
            ticket_id: this.state.ticketId, // add new ticket_id api key
            accountName: this.state.accountNumber, // add new account name api key
          },
        };
        return axios(apiUrl + "/get-quote-count", requestoption)
          .then((data) => {
            this.setState({
              total_record: data.data.data,
              isDownloading: false,
            });
          })
          .catch((error) => {
            this.setState({
              isDownloading: false,
            });
          });
      }
    );
  };

  getSRStatus = (id) => {
    const requestoption = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${this.props.getUserDetails.token}`,
      },
      data: {
        rfq_id: id,
      },
    };

    return axios(apiUrl + "/sourcing-exist", requestoption)
      .then(() => {
        return true;
      })
      .catch((error) => {
        this.setState(
          { errorMessage: "SR is already created for this RFQ" },
          () => {
            setTimeout(() => {
              this.setState({ errorMessage: "" });
            }, 3000);
          }
        );
        return false;
      });
  };

  openViewDetailPopup = (id, agent_id) => {
    this.setState({
      requestQuoteID: id,
      show: true,
      agent_id,
    });
  };

  TicketDetailPopup = (id) => {
    this.setState({
      requestQuoteID: id,
      showTicket: true,
    });
  };

  getRedirectUrl = (item, redirect) => {
    if (item.sourcing_status) {
      return "/sourcingPersonRequest";
    } else if (redirect) {
      return "/createSalesPersonRequest";
    } else {
      return "/quote-requests";
    }
  };

  openCreateSRDetail = async (ID, leadID, srStatus, rfqStatus) => {
    if (srStatus === "") {
      const result = await this.getSRStatus(ID);
      var data = {
        requestQuoteID: ID,
        timestamp: new Date().getTime(),
        rfqStatus: rfqStatus
      };
      localStorage.removeItem("SR_Details");
      localStorage.setItem("SR_Details", JSON.stringify(data));
      return result;
    } else {
      var data = {
        requestQuoteID: ID,
        requestLeadID: leadID,
        timestamp: new Date().getTime(),
        rfqStatus: rfqStatus
      };
      let data1 = {
        srID: Number(leadID),
        timestamp: new Date().getTime(),
      };
      localStorage.removeItem("srState");
      localStorage.removeItem("SR_Details");
      localStorage.removeItem("rfqStatus");
      localStorage.setItem("srState", JSON.stringify(data1));
      localStorage.setItem("SR_Details", JSON.stringify(data));
      return true;
    }
  };

  closeModal2 = () => {
    this.setState({
      showTicket: false,
    });
  };

  closeModal = () => {
    this.setState({
      show: false,
    });
  };
  closeFollowupModal = () => {
    this.setState({
      showFollowup: false,
      showProductDetailModal: false,
    });
  };
  closeModal3 = () => {
    this.setState({
      showQuoteDetail: false,
    });
  };

  filterByStatus = (e) => {
    this.setState(
      {
        selectedStatus: e,
        current_page: 1,
        QuoteListDownload: [],
      },
      () => {
        this.getTotalRecords();
        this.paginationLoading();
        this.getAllQuote();
      }
    );
  };
  filterByRole = (e) => {
    this.setState(
      {
        role: e,
        current_page: 1,
        QuoteListDownload: [],
      },
      () => {
        this.getTotalRecords();
        this.paginationLoading();
        this.getAllQuote();
      }
    );
  };
  filterByLastQuoteType = (e) => {
    this.setState(
      {
        quoteStatus: e,
        current_page: 1,
        QuoteListDownload: [],
      },
      () => {
        this.getTotalRecords();
        this.paginationLoading();
        this.getAllQuote();
      }
    );
  };
  // filterBuyingFor = (e) => {
  //   this.setState(
  //     {
  //       selectedBuyingFor: e.target.value,
  //       current_page: 1,
  //       QuoteListDownload: [],
  //     },
  //     () => {
  //       this.getTotalRecords();
  //       this.paginationLoading();
  //       this.getAllQuote();
  //     }
  //   );
  // };

  filterByType = (e) => {
    this.setState(
      {
        selectedType: e,
        current_page: 1,
        QuoteListDownload: [],
      },
      () => {
        this.getTotalRecords();
        this.paginationLoading();
        this.getAllQuote();
      }
    );
  };
  filterByAssignTo = (e) => {
    this.setState(
      {
        logined_user_id: e,
        current_page: 1,
        QuoteListDownload: [],
      },
      () => {
        this.getTotalRecords();
        this.paginationLoading();
        this.getAllQuote();
      }
    );
  };

  convertQuoteToOther = (e) => {
    const { checkBoxList } = this.state;
    const selected_ids = checkBoxList.map((item) => item.id);
    this.setState(
      {
        selecteTUserID: e.target.value,
        current_page: 1,
      },
      () => {
        let loginedUserDetails = JSON.parse(localStorage.getItem("userData"));
        const options = {
          method: "POST",
          data: {
            selectedQuoteID: selected_ids,
            selecteTUserID: this.state.selecteTUserID,
            logined_user_id: loginedUserDetails.user_id,
          },
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${this.props.getUserDetails.token}`,
          },
        };
        return axios(apiUrl + "/convert-quotes", options)
          .then((data) => {
            if (data.data.data.affectedRows > 0) {
              this.paginationLoading();
              this.getAllQuote();
              //this.exportsAllQuote();
              this.setState({
                checkBoxList: [],
                selecteTUserID: "",
              });
            }
          })
          .catch((error) => console.log(error));
      }
    );
  };
  paginationLoading = () => {
    this.setState({
      isPaginationLoading: 1,
    });
  };
  getPaginationValue = (currentPage, displayItems) => {
    this.setState(
      {
        current_page: currentPage,
        total_item: displayItems,
      },
      () => {
        this.getAllQuote();
      }
    );
  };
  getAllQuoteTypes = () => {
    const options = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${this.props.getUserDetails.token}`,
      },
      data: {
        meta_id: [23,34],
        // meta_id: 23,
      },
    };
    return axios(apiUrl + "/meta-value", options)
      .then((data) => {
        let rfqType = data.data.data;
        let all_rfq_type = rfqType.map((item) => {
          item["label"] = item["meta_title"];
          item["value"] = item["meta_value"];
          delete item["meta_title"];
          delete item["meta_value"];
          return item;
        });
        this.setState({
          all_rfq_type,
        });
      })
      .catch((error) => console.log(error));
  };
  
  handleQualificationtype = (event) => {
    const selectedValue = event.target.value;
    this.setState({selectedType:[]})
    this.setState({ qualificationType: selectedValue}, () => {
      this.getTotalRecords();
      this.paginationLoading();
      this.getAllQuote();
      // Map for meta_id selection based on the selected value
      const metaIdMapping = {
        Marketing: 23,
        Sales: 34,
      };

      // Determine meta_id based on the selected value, defaulting to [23, 34] if empty
      const meta_id = selectedValue ? metaIdMapping[selectedValue] : [23, 34];
      
      this.getAllQualificationTypes(meta_id);
    });
  };
  
  getAllQualificationTypes = async (meta_id) => {
    const options = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${this.props.getUserDetails.token}`,
      },
      data: {
        meta_id: meta_id,
      },
    };
    return await axios(apiUrl + "/meta-value", options)
      .then((data) => {
        let rfqType = data.data.data;
        let all_rfq_type = rfqType.map((item) => {
          item["label"] = item["meta_title"];
          item["value"] = item["meta_value"];
          delete item["meta_title"];
          delete item["meta_value"];
          return item;
        });
        this.setState({
          all_rfq_type,
        });
      
      })
      .catch((error) => console.log(error));
  };
  getLastQuoteStatus = () => {
    const options = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${this.props.getUserDetails.token}`,
      },
      data: {
        meta_id: 25,
      },
    };
    return axios(apiUrl + "/meta-value", options)
      .then((data) => {
        // this.setState({
        //   lastQuoteType: data.data.data,
        // });
        let quoteType = data.data.data;
        let lastQuoteType = quoteType.map((item) => {
          item["label"] = item["meta_title"];
          item["value"] = item["meta_value"];
          delete item["meta_title"];
          delete item["meta_value"];
          return item;
        });
        this.setState({ lastQuoteType });
      })
      .catch((error) => console.log(error));
  };
  getAllQuoteUsers = () => {
    let loginedUserDetails = JSON.parse(localStorage.getItem("userData"));

    const options = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${this.props.getUserDetails.token}`,
      },

      // data: { logined_user_id: this.props.getUserDetails.user_id },
      data: {
        agent_list: "",
        id: [56],
        showAll: "",
        slug: "quote-requests",
        listType: "list",
      },
    };
    return axios(apiUrl + "/get-pre-sales-agents", options)
      .then((data) => {
        this.setState(
          {
            all_quote_users: data.data.data,
          },
          () => {
            let agentOptions1 = data.data.data;
            let agentOptions = agentOptions1.map((item) => {
              if (item.hasOwnProperty("name") && item.hasOwnProperty("id")) {
                item["label"] = item["name"];
                item["value"] = item["id"];
                delete item["name"];
                delete item["id"];
                return item;
              }
            });

            this.setState({ agentOptions });
          }
        );
      })
      .catch((error) => console.log(error));
  };
  getAllAssignmentAgents = () => {
    let loginedUserDetails = JSON.parse(localStorage.getItem("userData"));

    const options = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${this.props.getUserDetails.token}`,
      },

      // data: { logined_user_id: this.props.getUserDetails.user_id },
      data: {
        agent_list: "",
        id: [56],
        showAll: "true",
        slug: "quote-requests",
        listType: "",
      },
    };
    return axios(apiUrl + "/get-pre-sales-agents", options)
      .then((data) => {
        this.setState({
          allAssignableAgents: data.data.data,
        });
      })
      .catch((error) => console.log(error));
  };
  downloadReport = async () => {
    let result = null;
    if (this.state.downloadOption === "rfq_list") {
      result = await this.exportsAllQuote();
    } else {
      result = await this.exportsAllProductQuote();
    }
    if (result.length > 0) {
      this.setState({ QuoteListDownload: result }, () => {
        setTimeout(() => {
          this.getExcel();
          // this.csvLinkEl.current.click();
        });
      });
    }
  };

  exportFollowup = () => {
    let dataNew = this.state.apiData.map((item) => {
      return {
        Agent: item.agentName,
        "Rfq Id": item.id,
        "Rfq Status": item.status,
        Type: item.type,
        Name: item.name,
        Mobile: item.mobile,
        "Follow Up date": item.followup_date,
      };
    });
    this.setState({ FollowupData: dataNew }, () => {
      setTimeout(() => {
        this.getExcel2();
        // this.csvLinkEl.current.click();
      });
    });
  };
  getExcel2 = () => {
    const wb = XLSX.utils.book_new();
    const ws = XLSX.utils.json_to_sheet(
      this.state.FollowupData
      // { origin: "A2", skipHeader: true }
    );

    XLSX.utils.book_append_sheet(wb, ws, "followupListRfq");

    let buf = XLSX.write(wb, { bookType: "xls", type: "buffer" });
    XLSX.write(wb, { bookType: "xls", type: "binary" });
    XLSX.writeFile(wb, "followup-List-RFQ.xls");
  };
  getExcel = () => {
    // XLSX.utils.sheet_add_aoa(ws, [heading], { origin: "A1" });
    const wb = XLSX.utils.book_new();
    const ws = XLSX.utils.json_to_sheet(
      this.state.QuoteListDownload
      // { origin: "A2", skipHeader: true }
    );

    XLSX.utils.book_append_sheet(wb, ws, "rfqList");

    let buf = XLSX.write(wb, { bookType: "xls", type: "buffer" });
    XLSX.write(wb, { bookType: "xls", type: "binary" });
    XLSX.writeFile(
      wb,
      this.state.downloadOption == "rfq_list"
        ? "rfq-list.xls"
        : "RFQ-Product-list.xls"
    );
  };

  onSelect = (isSelect, row) => {
    const { checkBoxList } = this.state;
    if (checkBoxList.length > 0) {
      let flag = false;
      const updateList = [];
      checkBoxList.forEach((item) => {
        if (item.id === row) {
          flag = true;
        } else {
          updateList.push(item);
        }
      });
      if (flag === false) {
        updateList.push({ id: row });
      }
      this.setState({ checkBoxList: updateList });
    } else {
      this.getAllAssignmentAgents();
      this.setState({
        checkBoxList: [
          {
            id: row,
          },
        ],
      });
    }
  };

  allSelect = () => {
    const { allSelectInit } = this.state;
    if (allSelectInit) {
      this.setState({ allSelectInit: false, checkBoxList: [] });
    } else {
      this.getAllAssignmentAgents(); // get all list on select multiple checkbox
      const updateList = [];
      this.state.QuoteList.map((item, index) => {
        updateList.push({
          id: item.id,
        });
      });
      this.setState({ allSelectInit: true, checkBoxList: updateList });
    }
  };
  handleDatetype = (e) => {
    this.setState(
      {
        dateType: e.target.value,
      },
      () => {
        this.getTotalRecords();
        this.paginationLoading();
        this.getAllQuote();
      }
    );
  };
  debounce = (cb, delay = 500) => {
    let timeout;
    return (...args) => {
      clearTimeout(timeout);

      timeout = setTimeout(() => {
        cb(...args);
      }, delay);
    };
  };
  mobileFilter = (e) => {
    this.setState(
      {
        mobile: e,
        requestQuoteID: 0,
        current_page: 1,
      },
      () => {
        this.getTotalRecords();
        this.paginationLoading();
        this.getAllQuote();
      }
    );
  };
  updateMobile = this.debounce(this.mobileFilter);
  getMobile = (e) => {
    this.updateMobile(e.target.value);
  };

  // add new account number filter function handler start here
  accountNumberFilter = (e) => {
    this.setState(
      {
        accountNumber: e,
        requestQuoteID: 0,
        current_page: 1,
      },
      () => {
        this.getTotalRecords();
        this.paginationLoading();
        this.getAllQuote();
      }
    );
  };
  updateAccountNumber = this.debounce(this.accountNumberFilter);
  getAccountNumber = (e) => {
    this.updateAccountNumber(e.target.value);
  };
  // ends new account number filter function handler ends here

  // add new search ticket id function handler start here
  sourcingIdFilter = (e) => {
    this.setState(
      {
        sourcingId: e,
        requestQuoteID: 0,
        current_page: 1,
      },
      () => {
        if (this.state.sourcingId.length >= 3) {
          this.getTotalRecords();
          this.paginationLoading();
          this.getAllQuote();
        } else {
          this.setState({
            searchSourcingId: [],
          },()=> {
            this.getTotalRecords();
            this.paginationLoading();
            this.getAllQuote();
          });
        }
      }
    );
  };
  updateSourcingId = this.debounce(this.sourcingIdFilter);

  getSourcingId = (e) => {
    this.updateSourcingId(e.target.value);
  };
  // ends new search ticket id function handler ends here
  openQuoteDetailPopUp = (id, sourcingStatus) => {
    localStorage.setItem('sourcingStatus', sourcingStatus);
    this.setState({
      showQuoteDetail: true,
      requestQuoteID: id,
      sourcingStatus: sourcingStatus
    });
  };
  handleDownload = (e) => {
    this.setState(
      {
        // [e.target.name]: e.target.value,
        downloadOption: e.target.value,
      },
      () => {
        this.downloadReport(this.state.downloadOption);
      }
    );
  };
  displayStatus = (status) => {
    // this.setState({
    //   quote_status: status,
    // });
    if (status == "New") {
      return "#A24857";
    } else if (status == "Pending") {
      return "#ffc107";
    } else if (status == "Shared") {
      return "#ffc107";
    } else if (status == "Converted to MQ") {
      return "#ffc107";
    } else if (status == "Converted to Quote") {
      return "#ffc107";
    } else if (status == "Closed - Won") {
      return "#28a745";
    } else if (status == "Direct Order") {
      return "#99f1ad";
    } else if (status == "Closed - Lost") {
      return "#9FA6B2";
    } else if (status == "Duplicate") {
      return "#9FA6B2";
    } else if (status == "Quote Follow-up") {
      // add new filter "Quote Follow-up"
      return "#ffc107";
    } else {
      return "";
    }
  };
  displayStatusTextColor = (status) => {
    if (status == "New") {
      return "white";
    }
  };
  changeRole = (e, rfqID) => {
    const selectedRole = this.state.roleArray.filter(
      (item) => item.label == e.target.textContent
    );
    const roleID = selectedRole[0].value;
    const params = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${this.props.getUserDetails.token}`,
      },
      data: {
        role: roleID,
        rfq_id: rfqID,
      },
    };
    return axios(apiUrl + "/update-rfq-role", params)
      .then((response) => {
        this.getAllQuote();
        this.setState({ roleChangeMessage: response?.data?.message }, () => {
          setTimeout(() => {
            this.setState({
              roleChangeMessage: "",
            });
          }, 3000);
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };
  openViewProductsPopup = (productData) => {
    this.setState({
      showProductDetailModal: true,
      productData,
    });
  };
  handleModalOpen = () => {
    this.setState({
      showAttachments: true,
    });
  };
  handleModalClose = () => {
    this.setState({
      showAttachments: false,
    });
  };
  handleAttachmentClick(url) {
    window.open(url, "_blank"); // Open link in a new tab
  }
  DownloadIconPopup = (id) => {
    this.setState({
      requestQuoteID: id,
      showAttachments: true,
    });
  };

  getSourcingRfqStatus = (status) => {
    switch (status) {
      case "new":
        return "SR InProcess";
      case "pending":
        return "SR InProcess";
      case "completed":
        return "SR completed";
      case "reopen":
        return "SR Reopen";
      case "cancelled":
        return "SR Cancelled";
      default:
        return "";
    }
  };

  getdisplayRfqStatusTextColor = (status) => {
    if (status == "new") {
      return "#11aee5";
    } else if (status == "pending") {
      return "#11aee5";
    } else if (status == "completed") {
      return "#4caf50";
    } else if (status == "reopen") {
      return "#ffc107";
    } else if (status == "cancelled") {
      return "#9FA6B2";
    } else {
      return "";
    }
  };

  getSourcingStatusMessage = (status) => {
    if (!status) return "Create SR";
    switch (status) {
      case "new":
        return "SR InProcess";
      case "pending":
        return "SR InProcess";
      case "completed":
        return "SR completed";
      case "reopen":
        return "SR Reopen";
      case "cancelled":
          return "SR Cancelled";  
      default:
        return "";
    }
  };
  getdisplayStatusTextColor = (status) => {
    if (status == "new") {
      return "#11aee5";
    } else if (status == "pending") {
      return "#11aee5";
    } else if (status == "completed") {
      return "#4caf50";
    } else if (status == "reopen") {
      return "#ffc107";
    } else if (status == "cancelled") {
      return "#9FA6B2";
    } else {
      return "#000000";
    }
  };
  render() {
    let data = {
      RFQ_id: this.state.RFQ_id,
      name: this.state.cust_name,
      mobile: this.state.cust_mobile,
    };
    if (this.state.redirect === true) {
      return (
        <Redirect
          to={{
            pathname: "/createQuote",
            state: { rfqdata: this.state.RFQ_id },
          }}
        />
      );
    }

    const setDateRange = (data) => {
      this.setState(
        {
          date_from: data.start,
          date_to: data.end,
          current_page: 1,
        },
        () => {
          this.getTotalRecords();
          this.paginationLoading();
          this.getAllQuote();
        }
      );
    };
    const {
      checkBoxList,
      allSelectInit,
      downloadAttachment,
      setOpen,
      open,
      options,
    } = this.state;
    return (
      <>
        {this.state.success_message.length > 0 ? (
          <Alert className="displayMessageArea" variant="success">
            {this.state.success_message}
          </Alert>
        ) : null}
        {this.state.roleChangeMessage.length > 0 ? (
          <Alert className="displayMessageArea" variant="success">
            {this.state.roleChangeMessage}
          </Alert>
        ) : null}
        {this.state.errorMessage ? (
          <Alert className="displayMessageArea" variant="danger">
            {this.state.errorMessage}
          </Alert>
        ) : null}
        {this.state.showProductDetailModal ? (
          <Modal
            show={this.state.showProductDetailModal}
            onHide={this.closeFollowupModal}
            dialogClassName="modal-90w Ansari-Irshad1"
          >
            <Modal.Header closeButton></Modal.Header>
            <Modal.Body>
              <div>
                {this.state.productData &&
                  this.state.productData.map((data, index) => (
                    <p>
                      <b>({index + 1}) </b>
                      {data.type === "attachments" ? <a href={data.productName}>Attachment &nbsp;</a> : data.productName}
                      <b>| Model: </b> {data.model} <b>| Quantity: </b>
                      {data.productQuantity}
                      <b> | Price: </b> {data.productPrice}
                      {data.packQuantity
                        ? ` | Pack of: ${data.packQuantity}`
                        : ""}
                    </p>
                  ))}
              </div>
            </Modal.Body>
          </Modal>
        ) : null}
        {this.state.apiData.length ? (
          <Modal
            show={this.state.showFollowup}
            onHide={this.closeFollowupModal}
            dialogClassName="modal-90w business_process_wrapper"
          >
            <Modal.Header closeButton>
              {this.state.allowedSlugs.includes("download") ? (
                <div>
                  <Button
                    onClick={this.exportFollowup}
                    // variant="outline-success"
                    className="listingBtn"
                  >
                    Download
                  </Button>
                </div>
              ) : null}
            </Modal.Header>
            <div>
              <div className="mobile-table-scroll">
              <table className="table table-fixed new-table text-center">
                <thead style={{ position: "sticky", top: -1 }}>
                  <th>RFQ ID</th>
                  <th>RFQ Status</th>
                  <th>Agent</th>
                  <th>Type</th>
                  <th>Name</th>
                  <th>Mobile</th>
                  <th>FollowUp Date</th>
                  <th>Action</th>
                </thead>
                <tbody>
                  {this.state.apiData &&
                    this.state.apiData.map((item) => {
                      return (
                        <tr>
                          <td>
                            <span>{item.id}</span>
                          </td>
                          <td>
                            <span>{item.status}</span>
                          </td>
                          <td>
                            <span>{item.agentName}</span>
                          </td>
                          <td>
                            <span>{item.type}</span>
                          </td>
                          <td>
                            <span>{item.name}</span>
                          </td>
                          <td>
                            <span>{item.mobile}</span>
                          </td>
                          <td>
                            <span>{item.followup_date}</span>
                          </td>
                          {this.state.allowedSlugs.includes("edit") ? (
                            <td>
                              <Button
                                variant="light"
                                type="button"
                                size="sm"
                                onClick={() =>
                                  this.openViewDetailPopup(
                                    item.id,
                                    item.agent_id
                                  )
                                }
                                style={{
                                  fontSize: 12,
                                  border: "1px solid #cccccc",
                                  marginTop: "0.5rem",
                                }}
                              >
                                <FontAwesomeIcon icon="edit" /> View
                              </Button>
                            </td>
                          ) : null}
                        </tr>
                      );
                    })}
                </tbody>
              </table>
              </div>
            </div>
          </Modal>
        ) : null}

        <div
          className="card table-card quoteRequestPage">
          <div className="headingPadding">
              <h3 className="proAccountHeader mt-3">
                {this.state.allowedSlugs.includes("assignment") &&
                  checkBoxList.length > 0 ? (
                  <Form.Control
                    as="select"
                    custom
                    value={this.state.selecteTUserID}
                    onChange={this.convertQuoteToOther}
                  >
                    <option value="">Assign to ...</option>
                    {this.state.allAssignableAgents.map((item, index) => {
                      return item.id ? (
                        <option key={index} value={item.id}>
                          {item.name}
                        </option>
                      ) : null;
                    })}
                  </Form.Control>
                ) : (
                  <div>RFQ</div>
                )}
              </h3>
              <Form className="quoteRequestPageFilter">
                <div className="row">
                  <Col md="3" className="mt-3">
                    <Form.Control
                      className="listingFontSizeColor"
                      as="select"
                      custom
                      onChange={this.handleDatetype}
                      value={this.state.dateType}
                    >
                      <option value="">Date Type</option>
                      <option value="created">Create Date</option>
                      <option value="updated">Update Date</option>
                    </Form.Control>
                  </Col>
                  <Col md="3" className="mt-3">
                    <div className="customFilterCnt">
                      <div className="customFilter">
                      {/* <Daterangepicker
                          setDateRange={setDateRange}
                        ></Daterangepicker> */}

                      <DateRangeNew
                        label={"ALL DATA"}
                        // allData={true}
                        setDateRange={setDateRange}
                      ></DateRangeNew>
                      </div>
                    </div>
                  </Col>
                  <Col md="3" className="mt-3">
                  <Select
                    defaultValue={[]}
                    isMulti
                    name="rfqStatus"
                    closeMenuOnSelect={false}
                    hideSelectedOptions={false}
                    controlShouldRenderValue={false}
                    options={this.state.call_status_array}
                    className="basic-multi-select listingFontSizeColor"
                    classNamePrefix="select"
                    placeholder="RFQ Status"
                    onChange={(options) => {
                      if (Array.isArray(options)) {
                        this.setState(
                          {
                            selectedStatus: options.map((opt) => opt.value),
                          },
                          () => {
                            this.debouncedFilterByStatus(this.state.selectedStatus);
                          }
                        );
                      } else {
                        this.debouncedFilterByStatus([]);
                      }
                    }}
                    components={{
                      Option: InputOption,
                    }}
                  />
                  {/* <Form.Control
                      as="select"
                      defaultValue={this.state.selectedStatus}
                      custom
                      onChange={this.filterByStatus}
                    >
                      <option value="">Status</option>
                      {this.state.call_status_array.map((item, index) => {
                        return (
                          <option value={item.meta_value} key={index}>
                            {item.meta_title}
                          </option>
                        );
                      })}
                    </Form.Control> */}
                </Col>
                  <Col md="3" className="mt-3">
                    <Form.Control
                      className="listingFontSizeColor"
                      placeholder="Mobile"
                      onChange={(data) => this.getMobile(data)}
                    />
                  </Col>

                  {/* ADD NEW ACCOUNT Number FILTER COMPONENT START HERE */}
                  <Col md="3" className="mt-3">
                    <Form.Control
                    className="listingFontSizeColor"
                      placeholder="Account"
                      onChange={(data) => this.getAccountNumber(data)}
                    />
                  </Col>
                  {/* ENDS NEW ACCOUNT Number FILTER COMPONENT ENDS HERE */}

                  {this.state.agentOptions &&
                    this.state.agentOptions.length > 1 ? (
                    <Col md="3" className="mt-3">
                      <Select
                        defaultValue={[]}
                        isMulti
                        name="orderType"
                        closeMenuOnSelect={false}
                        hideSelectedOptions={false}
                        controlShouldRenderValue={false}
                        options={this.state.agentOptions}
                        className="basic-multi-select listingFontSizeColor"
                        classNamePrefix="select"
                        placeholder="Filter Agents"
                        onChange={(options) => {
                          
                          if (Array.isArray(options)) {

                            this.setState(
                              {
                                logined_user_id: options?.map(
                                  (opt) => opt.value
                                ),
                              },
                              () => {
                                this.debouncedfilterByAssignTo(
                                  this.state.logined_user_id
                                );
                              }
                            );
                          } else {
                            this.debouncedfilterByAssignTo([]);
                          }
                        }}
                        components={{
                          Option: InputOption,
                        }}
                      />
                    </Col>
                  ) : null}

                  <Col md="3" className="mt-3">
                    <Select
                      defaultValue={[]}
                      isMulti
                      name="lastQuoteType"
                      closeMenuOnSelect={false}
                      hideSelectedOptions={false}
                      controlShouldRenderValue={false}
                      options={this.state.lastQuoteType}
                      className="basic-multi-select listingFontSizeColor"
                      classNamePrefix="select"
                      placeholder="Last Quote Status"
                      onChange={(options) => {
                        if (Array.isArray(options)) {

                          this.setState(
                            {
                              quoteStatus: options?.map((opt) => opt.value),
                            },
                            () => {
                              this.debouncedfilterByLastQuoteType(
                                this.state.quoteStatus
                              );
                            }
                          );
                        } else {
                          this.debouncedfilterByLastQuoteType([]);
                        }
                      }}
                      components={{
                        Option: InputOption,
                      }}
                    />

                    {/* <Form.Control
                          as="select"
                          custom
                          // defaultValue={this.state.logined_user_id}
                          onChange={this.filterByLastQuoteType}
                        >
                          <option value="">Quote Status</option>
                          {this.state.lastQuoteType &&
                            this.state.lastQuoteType.map((item, index) => {
                              return (
                                <option key={index} value={item.meta_value}>
                                  {item.meta_title}
                                </option>
                              );
                            })}
                        </Form.Control> */}
                  </Col>
                  {/* <Col
                      //  xs={1}
                      >
                        <Link
                          headers={headers}
                          className="hidden"
                          filename={"rfq-list.xls"}
                          data={this.state.QuoteListDownload}
                          ref={this.csvLinkEl}
                        >
                          Export Data
                        </Link>
                      </Col> */}
                  <Col md="3" className="mt-3">
                    <Select
                      defaultValue={[]}
                      isMulti
                      name="rfqRole"
                      closeMenuOnSelect={false}
                      hideSelectedOptions={false}
                      controlShouldRenderValue={false}
                      options={this.state.roleArray}
                      className="basic-multi-select listingFontSizeColor"
                      classNamePrefix="select"
                      placeholder="Role"
                      onChange={(options) => {
                        if (Array.isArray(options)) {

                          this.setState(
                            {
                              role: options?.map((opt) => opt.value),
                            },
                            () => {
                              this.debouncedfilterByRole(this.state.role);
                            }
                          );
                        } else {
                          this.debouncedfilterByRole([]);
                        }
                      }}
                      components={{
                        Option: InputOption,
                      }}
                    />
                  </Col>
                  <Col md="3" className="mt-3">
                    <Form.Control
                      className="listingFontSizeColor"
                      as="select"
                      key="qualification"
                      custom
                      onChange={this.handleQualificationtype}
                      value={this.state.qualificationType}
                    >
                      <option value="">Qualification Type</option>
                      <option value="Marketing">Marketing RFQ</option>
                      <option value="Sales">Sales RFQ</option>
                    </Form.Control>
                  </Col>
                  {/* START HERE RFQ TYPE FILTER SEARCH BOX START HERE COMPONENT */}
                  {this.state.showTypeFilter > 0 ? (
                    <Col md="3" className="mt-3">
                     {this.state.qualificationType === "Marketing" ?
                       <Select
                       defaultValue={[]}
                       key="RFQ Type1"
                       isMulti
                       name="rfqStatus1"
                       closeMenuOnSelect={false}
                       hideSelectedOptions={false}
                       controlShouldRenderValue={false}
                       options={this.state.all_rfq_type}
                       className="basic-multi-select listingFontSizeColor"
                       classNamePrefix="select"
                       placeholder="RFQ Type"
                       onChange={(opt) => {
                         if (Array.isArray(opt)) {
                           this.setState(
                             {
                               selectedType: opt?.map(
                                 (opts) => opts.value
                               ),
                             },
                             () => {
                               this.debouncedfilterByType(this.state.selectedType);
                             }
                           );
                         } else {
                           this.debouncedfilterByType([]);
                         }
                       }}
                       components={{
                         Option: InputOption,
                         MultiValueRemove: CustomMultiValueRemove,
                       }}
                     />
                     : 
                     <Select
                     defaultValue={[]}
                     key="RFQ Type2"
                     isMulti
                     name="rfqStatus2"
                     closeMenuOnSelect={false}
                     hideSelectedOptions={false}
                     controlShouldRenderValue={false}
                     options={this.state.all_rfq_type}
                     className="basic-multi-select listingFontSizeColor"
                     classNamePrefix="select"
                     placeholder="RFQ Type"
                     onChange={(opt) => {
                       if (Array.isArray(opt)) {
                         this.setState(
                           {
                             selectedType: opt?.map(
                               (opts) => opts.value
                             ),
                           },
                           () => {
                             this.debouncedfilterByType(this.state.selectedType);
                           }
                         );
                       } else {
                         this.debouncedfilterByType([]);
                       }
                     }}
                     components={{
                       Option: InputOption,
                       MultiValueRemove: CustomMultiValueRemove,
                     }}
                   /> 
                    }
                      {/* <Form.Control
                            as="select"
                            defaultValue={this.state.selectedType}
                            custom
                            onChange={this.filterByType}
                          >
                            <option value="">Rfq Type</option>
                            {this.state.all_rfq_type.map((item, index) => {
                              return (
                                <option key={index} value={item.meta_value}>
                                  {item.meta_title}
                                </option>
                              );
                            })}
                          </Form.Control> */}
                    </Col>
                  ) : null}
                  {/* ENDS HERE RFQ TYPE FILTER SEARCH BOX ENDS HERE COMPONENT */}

                  {/* START HERE NEW FILTER COMPONENT FOR TICKET ID START HERE */}

                  <Col md="3" className="mt-3">
                    <Form.Control
                      className="listingFontSizeColor"
                      type="text"
                      placeholder="Sourcing Id"
                      onChange={(data) => this.getSourcingId(data)}
                    />
                  </Col>
                  </div>
                  <div className="row">
                  {this.state.allowedSlugs.includes("download") ? (
                    <Col md="3" className="mt-3">
                      {this.state.isDownloading ? (
                        <span
                          variant="primary"
                          className="btn btn-primary downLoadButton"
                        >
                          <Spinner animation="border" />
                        </span>
                      ) : (
                        <select
                          className="listingBtn"
                          // onChange={this.downloadReport}
                          onChangeCapture={this.handleDownload}
                          disabled={this.state.isDownloading}
                          value={0}
                        >
                          <option value="0">Download</option>
                          <option value="rfq_list">RFQ List</option>
                          <option value="rfq_product_list">
                            RFQ Product List
                          </option>
                        </select>
                      )}
                    </Col>
                  ) : null}
                  {this.state.allowedSlugs.includes("add") ? (
                    <Col md="3" className="mt-3">
                      <Link
                        to={{ pathname: "/createRfq" }}
                        className="listingBtn"
                      >
                        Add RFQ
                      </Link>
                    </Col>
                  ) : null}
                </div>
              </Form>
          </div>
          <div className="card-block">
            {this.state.isLoading ? (
              <div style={{ textAlign: "center", marginTop: 20 }}>
                <Spinner animation="border" />
              </div>
            ) : (
              // You can Add "responsive" class also for responsive horizontal class
              <div className="mobile-table-scroll">
                <Table striped bordered hover size="md">
                  <thead>
                    <tr>
                      {this.state.allowedSlugs.includes("assignment") ? (
                        <th
                          className="selection-cell-header"
                          data-row-selection="true"
                        >
                          <input
                            type="checkbox"
                            className="selection-input-4"
                            checked={allSelectInit}
                            onChange={this.allSelect}
                          />
                        </th>
                      ) : null}
                      <th tabindex="0">Rfq Id/Lead reference Id</th>
                      {/* <th tabindex="0">Lead reference Id</th> */}
                      <th tabindex="0">Details</th>
                      {/* <th tabindex="0">Location</th> */}
                      {/* <th tabindex="0">Mobile</th> */}
                      <th tabindex="0">Product</th>
                      <th tabindex="0">Total</th>
                      <th tabindex="0">RFQ Status</th>
                      <th tabindex="0">Last Quote Status</th>

                      {/* <th tabindex="0">Call Count</th> */}
                      <th tabindex="0">Business Group</th>
                      <th tabindex="0">Assigned To</th>

                      {/* <th tabindex="0">Priority</th> */}
                      {/* <th tabindex="0">Type</th> */}
                      {/* <th tabindex="0">Campaign</th> */}
                      {/* <th tabindex="0">Ticket ID</th> */}
                      <th
                        tabindex="0"
                        aria-label="Date sortable"
                        className="sortable"
                      >
                        Date<span className="order-4"></span>
                      </th>
                      <th tabindex="0">Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {this.state.QuoteList.map((item, index) => {
                      let checked = false;
                      if (checkBoxList.length > 0) {
                        checkBoxList.map((element, index) => {
                          if (element.id === item.id) {
                            checked = true;
                          }
                        });
                      }
                      return (
                        <tr key={this.state.QuoteList.id}>
                          {this.state.allowedSlugs.includes("assignment") ? (
                            <td>
                              <input
                                type="checkbox"
                                className="selection-input-4"
                                onChange={(e) => this.onSelect(e, item.id)}
                                checked={checked}
                              />
                            </td>
                          ) : null}
                          <td style={{ minWidth: "100px" }}>
                            <b>RFQ ID:</b> {item.id} <br />
                            <hr />
                            {item.lead_id ? (
                              <b>Lead ID: {item.lead_id}</b>
                            ) : null}{" "}
                          </td>
                          {/* <td></td> */}
                          <td>
                            <b>{item.accountNumber}</b> <br />
                            {""}
                            <span> {item.customer_name} </span>
                            <br /> {item.customer_mobile} <br />{" "}
                            {item.customer_email}
                          </td>
                          {/* <td>{item.location}</td> */}
                          {/* <td>{item.customer_mobile}</td> */}
                          <td style={{ width: "20rem" }}>
                            <table>
                              <tbody>
                                <div className="d-flex flex-column justify-content-between">
                                  <b>
                                    {item?.product_data && item?.product_data.length > 0 && item?.product_data[0]?.type && item?.product_data[0]?.type === "attachments"? 
                                      <a href={item?.product_data[0]?.productName}>attachment</a> :
                                     item?.product_data && item?.product_data.length > 0 && item?.product_data[0]?.productName?.slice(
                                        0,
                                        60
                                      )
                                      ? item?.product_data[0]?.productName?.slice(
                                        0,
                                        60
                                      ) + "..."
                                      : "No Product Added"}
                                  </b>
                                  <div>
                                    <FontAwesomeIcon
                                      className="editIcon"
                                      icon="eye"
                                      onClick={() =>
                                        this.openViewProductsPopup(
                                          item.product_data
                                        )
                                      }
                                    />
                                    <i>
                                      {`${item.product_data &&
                                        item.product_data.length
                                        } products`}
                                    </i>
                                  </div>
                                </div>
                              </tbody>
                              {/* <tbody>
                                {item.product_data &&
                                  item.product_data.map((data, index) => (
                                    <ul className="list-unstyled">
                                      <li key={index}>
                                        {data.productName}
                                        <br />
                                        <b>Model: </b>
                                        {data.model} | <b>Quantity: </b>
                                        {data.productQuantity} | <b>Price: </b>
                                        {data.productPrice} |
                                        {data.packQuantity ? (
                                          <b> Pack of: {data.packQuantity}</b>
                                        ) : (
                                          ""
                                        )}
                                      </li>
                                    </ul>
                                  ))}
                              </tbody> */}
                            </table>
                          </td>
                          <td>{item.totalPrice}</td>

                          <td>
                            <p
                              style={{
                                backgroundColor: `${this.displayStatus(
                                  item.status
                                )}`,
                                color: `${this.displayStatusTextColor(
                                  item.status
                                )}`,
                                padding: "2px",
                                textAlign: "center",
                                borderRadius: "0.2rem",
                                width:"max-content",
                                padding: ".25rem .5rem",
                                margin:"0"
                              }}
                            >
                              <b>{item.status}</b>
                            </p>

                            {item.poStaus ? (
                              <>
                                <span
                                  style={{
                                    fontSize: "11px",
                                  }}
                                >
                                  PO Status:
                                </span>
                                <a
                                  style={{
                                    fontSize: "13px",
                                    textDecoration: "none",
                                  }}
                                  href="/editPO"
                                  target="_blank"
                                  onClick={() =>
                                    this.setPOState(
                                      item?.orderIDObject?.po_id,
                                      item?.quoteDetails?.quote_id
                                    )
                                  }
                                >
                                  <b>{`(${item.poStaus})`}</b>
                                </a>
                                <br />
                              </>
                            ) : null}
                            {item.order_id ? (
                              <>
                                <span style={{ fontSize: "12px" }}>
                                  Order ID : <b>{item.order_id}</b>
                                </span>
                                <br />
                                <span style={{ fontSize: "12px" }}>
                                  Status : <b>{item.order_status}</b>{" "}
                                </span>
                              </>
                            ) : null}
                            {/* {item.orderIDObject?.order_id
                                ?<span  style={{
                                  fontSize: "11px",
                                  
                                }}>Order ID:<b>{item.orderIDObject?.order_id}</b>
                                </span>                                 
                                  : ""}
                              {item.order_status ? 
                                <span>Order Status:<b>{item.order_status ?? ""}</b></span>  
                          : null} */}
                          </td>
                          <td>
                            {item.lastQuoteStatus ? (
                              <b>{item.lastQuoteStatus}</b>
                            ) : null}
                            <br />
                            {item.isQuote ? (
                              <Button
                                className="btn-sm"
                                style={{
                                  width:"max-content"
                                }}
                                onClick={() =>
                                  this.openQuoteDetailPopUp(item.id, item.sourcing_status)
                                }
                              >
                                View Quotes
                              </Button>
                            ) : null}
                          </td>

                          {/* <td>{(item.status==this.state.dublicate)? <option>{item.status}</option>: null}
                            </td> */}

                          {/* <td>{status.map((item)=>{
                            return(
                            <div>
                            {item.status2}
                            </div>
                            )
                          })}</td> */}
                          {/* <td>
                            <b>
                              {item.orderIDObject?.order_id
                                ? item.orderIDObject?.order_id
                                  ? item.orderIDObject?.order_id
                                  : ""
                                : item.orderIDObject?.po_id
                                ? item.orderIDObject?.po_id
                                : ""}
                            </b>
                          </td> */}

                          <td>
                            {/* ADD NEW KEY -> PRO_BUSINESS_GROUP */}
                            {item.pro_business_group ? (
                              <p>
                                <b>{item.pro_business_group}</b>
                              </p>
                            ) : null}
                            <div>
                              <Dropdown>
                                <Dropdown.Toggle
                                  className="updateStatusButton"
                                  variant={"outline-secondary"}
                                  id={"dropDown" + item.id}
                                  key={item.id}
                                >
                                  <b>{item.role}</b>
                                  <Dropdown.Menu>
                                    {this.state.roleArray &&
                                      this.state.roleArray.map((business) => {
                                        return (
                                          <Dropdown.Item
                                            onClick={(e) =>
                                              this.changeRole(e, item.id)
                                            }
                                          >
                                            {business.label}
                                          </Dropdown.Item>
                                        );
                                      })}
                                    {/* <Dropdown.Item
                                      onClick={(e) =>
                                        this.changeBusinessGroup(e, item.quote_id)
                                      }
                                    >
                                      Warm
                                    </Dropdown.Item>
                                    <Dropdown.Item
                                      onClick={(e) =>
                                        this.changeBusinessGroup(e, item.quote_id)
                                      }
                                    >
                                      Cold
                                    </Dropdown.Item> */}
                                  </Dropdown.Menu>
                                </Dropdown.Toggle>
                              </Dropdown>
                            </div>
                          </td>

                          <td>
                            {item.assign_to}
                            <br />{" "}
                            <b>
                              {" "}
                              {item.call_count ? `(${item.call_count})` : null}
                            </b>
                            <br />
                            {item.call_status ? item.call_status : null}
                            <br />
                            {item.agent_note ? `"${item.agent_note}"` : null}
                          </td>

                          {/* <td>
                            <Col>
                              <Form.Control
                                as="select"
                                // size="md"
                                // defaultValue={this.state.selectedStatus}
                                // defaultValue={this.state.showOption}
                                custom
                                onChange={(e) => this.handleChange2(e, item.id)}
                                value={item.priority}
                                defaultValue={item.priority}
                              >
                                <option value="0"></option>
                                <option value={this.state.value}>Highest</option>
                                <option value={this.state.value}>High</option>
                                <option value={this.state.value}>Medium</option>
                                <option value={this.state.value}>Low</option>
                              </Form.Control>
                            </Col>
                          </td> */}
                          {/* <td>{item.quote_type}</td> */}
                          {/* <td>{item.campaign}</td> */}
                          {/* <td>{item.ticket_id}</td> */}
                          <td>{item.date_and_time}</td>
                          <td style={{ width: "125px" }}>
                            {item.action}
                            {item.attachment && item.attachment.length > 0 ? (
                              <>
                                <Button
                                  variant="light"
                                  type="button"
                                  style={{
                                    fontSize: 12,
                                    border: "1px solid #cccccc",
                                    marginLeft:"8px"
                                  }}
                                  // onClick={this.handleModalOpen}
                                  onClick={() =>
                                    this.DownloadIconPopup(item.id)
                                  }
                                >
                                  <FontAwesomeIcon
                                    icon={faDownload}
                                  // onClick={() =>
                                  //   window.open(item.attachment, "_blank")
                                  // }
                                  />
                                </Button>
                              </>
                            ) : (
                              ""
                            )}
                            <>
                              {/* <Button
                                variant="light"
                                type="button"
                                //  onClick={() => this.TicketDetailPopup(item.id)}
                                onClick={() => this.TicketDetailPopup(item.id)}
                                style={{
                                  fontSize: 12,
                                  border: "1px solid #cccccc",
                                }}
                              >
                                Ticket
                              </Button> */}
                              {["Duplicate", "Closed - Won", "Closed - Lost"].includes(item.status) 
                              ? (
                                <Button
                                variant=""
                                className="mt-3 btn-sm d-block p-0"
                              >
                                <Link
                                  to="#"
                                  onClick={async (e) => {
                                    e.preventDefault();
                                    const data = await this.openCreateSRDetail(
                                      item.id,
                                      item.sourcing_id,
                                      item.sourcing_status,
                                      item.status
                                    );
                                    const redirectUrl = this.getRedirectUrl(
                                      item,
                                      data
                                    );
                                    if (redirectUrl !== "/quote-requests") {
                                      window.open(redirectUrl, "_blank");
                                    } else {
                                      setTimeout(() => {
                                        window.location.reload();
                                      }, 3000);
                                    }
                                  }}
                                  style={{
                                    textDecoration: "none",
                                    backgroundColor: `${this.getdisplayRfqStatusTextColor(
                                      item.sourcing_status
                                    )}`,
                                    color: "#fff",
                                    padding: '10px',
                                    textWrap: 'nowrap',
                                    borderRadius: '8px'
                                  }}
                                >
                                  {this.getSourcingRfqStatus(
                                    item.sourcing_status
                                  )}
                                </Link>
                              </Button>
                              ) 
                              : (
                              <Button
                                variant=""
                                className="mt-3 btn-sm d-block p-0"
                              >
                                <Link
                                  to="#"
                                  onClick={async (e) => {
                                    e.preventDefault();
                                    const data = await this.openCreateSRDetail(
                                      item.id,
                                      item.sourcing_id,
                                      item.sourcing_status,
                                      item.status
                                    );
                                    const redirectUrl = this.getRedirectUrl(
                                      item,
                                      data
                                    );
                                    if (redirectUrl !== "/quote-requests") {
                                      window.open(redirectUrl, "_blank");
                                    } else {
                                      setTimeout(() => {
                                        window.location.reload();
                                      }, 3000);
                                    }
                                  }}
                                  style={{
                                    textDecoration: "none",
                                    backgroundColor: `${this.getdisplayStatusTextColor(
                                      item.sourcing_status
                                    )}`,
                                    color: "#fff",
                                    padding: '10px',
                                    textWrap: 'nowrap',
                                    borderRadius: '8px'
                                  }}
                                >
                                  {this.getSourcingStatusMessage(
                                    item.sourcing_status
                                  )}
                                </Link>
                              </Button>
                              )}
                            </>
                            <p className="mt-3">
                              {item.sourcing_id ? (
                                <b> # SR-{item.sourcing_id} </b>
                              ) : null}
                            </p>
                            <p className="mt-3">
                              {item.sourcing_agent ? (
                                <b>({item.sourcing_agent}) </b>
                              ) : null}
                            </p>
                            <p className="mt-3">
                              {item.ticket_id ? item.ticket_id : ""}
                            </p>
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </Table>
              </div>

              //   <BootstrapTable
              //   keyField="sno"
              //   data={this.state.QuoteList}
              //   columns={columns}
              //   striped
              //   hover
              //   bordered={false}
              //   bootstrap4
              //   noDataIndication="Data not found"
              // />
            )}
          </div>

          {/* // Attachment Modal Overlay Pop Up Start here */}
          <Modal
            className="modal_attchment_wrapper"
            show={this.state.showAttachments}
            onHide={this.handleModalClose}
          >
            <Modal.Header closeButton>
              <Modal.Title>Attachment</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <p>Download Attachment</p>
              <Col sm={12}>
                <ul className="row" style={{ listStyleType: "none" }}>
                  {this.state.QuoteList.filter(
                    (quote) => quote.id === this.state.requestQuoteID
                  ).length > 0 ? (
                    <>
                      {this.state.QuoteList.filter(
                        (quote) => quote.id === this.state.requestQuoteID
                      )[0].attachment?.map((attachments, index) => (
                        <li className="p-1" key={index}>
                          {/* <a 
                             href={item.imageUrl} 
                             target="_blank" 
                             rel="noopener noreferrer" 
                             onClick={() => this.handleClick(item.imageUrl)}>
                             {item.name}</a> */}
                          <a
                            style={{
                              textDecoration: "underline",
                              cursor: "pointer",
                            }}
                            href={attachments.url}
                            target="_blank"
                            onClick={() =>
                              this.handleAttachmentClick(attachments.url)
                            }
                          >
                            {`Attachment ${index + 1}`}
                          </a>
                        </li>
                      ))}
                    </>
                  ) : (
                    "NOT FOUND ANY ATTACHMENT"
                  )}
                </ul>
              </Col>
            </Modal.Body>
            <Modal.Footer>
              <Button onClick={this.handleModalClose}>Close</Button>
            </Modal.Footer>
          </Modal>
          {/* // Attachment Modal Overlay Pop Up Ends here */}

          <Pagination
            current_page={this.state.current_page}
            total_item={this.state.total_record}
            getPaginationValue={this.getPaginationValue}
            isPaginationLoading={this.state.isPaginationLoading}
          />
          {this.state.show ? (
            <ApproveQuote
              show={this.state.show}
              requestQuoteID={this.state.requestQuoteID}
              closeModal={this.closeModal}
              getAllQuote={this.getAllQuote}
              rfq_priority={this.state.rfq_priority}
              agent_id={this.state.agent_id}
              getUserDetails={this.props.getUserDetails}
            />
          ) : null}

          {this.state.showTicket ? (
            <TicketRaise
              requestQuoteID={this.state.requestQuoteID}
              showModal={this.state.showTicket}
              closeModal2={this.closeModal2}
              getAllQuote={this.getAllQuote}
            />
          ) : null}
          {this.state.showQuoteDetail ? (
            <RfqQuoteDetail
              showQuoteDetail={this.state.showQuoteDetail}
              rfqID={this.state.requestQuoteID}
              sourcingStatus={this.state.sourcingStatus}
              closeModal={this.closeModal3}
              allowedSlugs={this.state.allowedSlugsQuote}
            />
          ) : null}

          {/* {this.state.showTicket ? (
            <TicketRaise
            showModal={this.state.showTicket}
            closeModal2={this.closeModal2}
            />
          ) : null} */}

          {/* {
              this.state.showDropOptions ?
              <SelectOption onClick={this.openDropDown}
              />:'null'
          } */}
        </div>
      </>
    );
  }
}
export default withRouter(Quote);
