import React from "react";
import { getUserDetails } from "../../Login/getLoginnedUserDetails";
import { Link, Redirect, withRouter } from "react-router-dom";
import {
  Alert,
  Spinner,
  Button,
  Modal,
  Form,
  Row,
  Col,
  Collapse,
  InputGroup,
  Table,
} from "react-bootstrap";
import axios from "axios";
import { CSVLink, CSVDownload } from "react-csv";
import * as XLSX from "xlsx";
import Constants from "../../../Config";
// import ApproveQuote from "./ApproveQuote";

import BootstrapTable from "react-bootstrap-table-next";
import Pagination from "../Common/Pagination";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faDownload } from "@fortawesome/free-solid-svg-icons";
import { faDoorClosed } from "@fortawesome/free-solid-svg-icons";

import Daterangepicker from "../Common/Daterangepicker";
import ApproveLead from "./ApproveLead";
import DuplicateLead from "./DuplicateLead";
import TicketRaise from "../Quotes/TicketRaise";
import Axios from "axios";
import DateRangeNew from "../../DashBoardWidgets/NewWidgets.js/DateRangeNew";
// import { Select } from "@mui/material";
import Select, { components } from "react-select";
import { useState } from "react";
// import CustomPagination from "../Common/Pagination";
// import SelectOption from "./SelectOption";
// import CreateQuote from "./CreateQuote";

const apiUrl = Constants.API_URL;
const webUrl = Constants.WEB_URL;

const InputOption = ({
  getStyles,
  Icon,
  isDisabled,
  isFocused,
  isSelected,
  children,
  innerProps,
  ...rest
}) => {
  const [isActive, setIsActive] = useState(false);
  const onMouseDown = () => setIsActive(true);
  const onMouseUp = () => setIsActive(false);
  const onMouseLeave = () => setIsActive(false);

  // styles
  let bg = "transparent";
  if (isFocused) bg = "#bebebe";
  if (isActive) bg = "#B2D4FF";

  const style = {
    alignItems: "center",
    backgroundColor: bg,
    color: "inherit",
    display: "flex ",
    gap: "5px",
  };

  // prop assignment
  const props = {
    ...innerProps,
    onMouseDown,
    onMouseUp,
    onMouseLeave,
    style,
  };

  return (
    <components.Option
      {...rest}
      isDisabled={isDisabled}
      isFocused={isFocused}
      isSelected={isSelected}
      getStyles={getStyles}
      innerProps={props}
    >
      <input type="checkbox" checked={isSelected} /> {children}
    </components.Option>
  );
};

class AllLeads extends React.Component {
  constructor(props) {
    super(props);
    this.csvLinkEl = React.createRef();
    this.detailRef = React.createRef();
  }

  state = {
    agentsArray: [],
    allAssignableAgents: [],
    all_lead_source: [],
    selecteTUserID: "",
    selectedSource: "",
    date_from: "",
    date_to: "",
    current_page: 1,
    lead_status: [],
    lead_status_array: [],
    isLoading: false,
    priorityArray: ["Highest", "High", "Medium", "Low"],
    //
    LeadList: [],
    lead_id: 0,
    lead_type: [],
    customerName: "",
    email: "",
    mobile: "",
    priority: "",
    campaign: "",
    created_at: "",
    leadStatus: "",
    supportAgentId: 0,
    salesAgent: [],
    supportAgentName: "",
    total_leads: "",
    productArray: [],
    total_item: 25,
    show: false,
    duplicateShow: false,
    followup_date: "",

    status: "",

    location: "",
    location_id: "",
    isPaginationLoading: 0,
    total_record: 0,
    allSelectInit: false,
    checkBoxList: [],
    showTicket: false,
    fromLead: true,
    apiData: [],
    FollowupData: [],
    showFollowup: true,
    option: "",
    LeadProductListDownload: [],
    allowedSlugs: [],
    agentOptions: [],
    leadStatusArray: [],
    leadSourcesArray: [],
    propLeadStatus: ''
  };

  componentDidMount() {
    // this.getTotalRecords();
    this.getFollowUp();
    this.getTotalRecords();
    this.getLeadList();
    // this.paginationLoading();
    this.getAllSourceTypes();
    this.getAllAgents();
    // this.getAllAssignmentAgents();
    this.getLeadStatus();
    this.setState({
      allowedSlugs: this.props.childSlugProp["all-leads"],
    });
  }
  getFollowUp = () => {
    const requestoption = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${this.props.getUserDetails.token}`,
      },
    };
    return Axios(apiUrl + "/agents-work-data/lead", requestoption)
      .then((data) => {
        let apiData = data.data.data;
        this.setState({
          apiData,
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  getAllSourceTypes = () => {
    const options = {
      method: "POST",
      data: {
        meta_id: "21",
      },

      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${this.props.getUserDetails.token}`,
      },
    };
    return axios(apiUrl + "/meta-value", options)
      .then((data) => {
        this.setState(
          {
            all_lead_source: data.data.data,
          },
          () => {
            let leadSources = data.data.data;
            let leadSourcesArray = leadSources.map((item) => {
              item["label"] = item["meta_title"];
              item["value"] = item["meta_value"];
              delete item["meta_title"];
              delete item["meta_value"];
              return item;
            });
            // agentOptions.unshift({ label: "UnAssign", value: 0 });
            this.setState({ leadSourcesArray });
          }
        );
      })
      .catch((error) => console.log(error));
  };
  getLeadStatus = () => {
    const options = {
      method: "POST",
      data: {
        meta_id: "32",
      },
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${this.props.getUserDetails.token}`,
      },
    };
    // return axios(apiUrl + "/get-lead-status", options)
    return axios(apiUrl + "/meta-value", options)
      .then((data) => {
        this.setState(
          {
            lead_status_array: data.data.data,
          },
          () => {
            let leadStatus1 = data.data.data;
            let leadStatusArray = leadStatus1.map((item) => {
              item["label"] = item["meta_title"];
              item["value"] = item["meta_value"];
              delete item["meta_title"];
              delete item["meta_value"];
              return item;
            });
            // agentOptions.unshift({ label: "UnAssign", value: 0 });
            this.setState({ leadStatusArray });
          }
        );
        // this.getAllQuote();
      })
      .catch((error) => console.log(error));
  };
  getAllAgents = () => {
    let loginedUserDetails = JSON.parse(localStorage.getItem("userData"));
    const options = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${this.props.getUserDetails.token}`,
      },
      data: {
        agent_list: "",
        id: [54],
        showAll: "true",
        slug: "all-leads",
        listType: "list",
      },

      // data: { logined_user_id: this.props.getUserDetails.user_id },
    };
    return axios(apiUrl + "/get-pre-sales-agents", options)
      .then((data) => {
        this.setState(
          {
            agentsArray: data.data.data,
          },
          () => {
            let agentOptions1 = data.data.data;
            let agentOptions = agentOptions1.map((item) => {
              if (item.hasOwnProperty("name") && item.hasOwnProperty("id")) {
                item["label"] = item["name"];
                item["value"] = item["id"];
                delete item["name"];
                delete item["id"];
                return item;
              }
            });
            // agentOptions.unshift({ label: "UnAssign", value: 0 });
            this.setState({ agentOptions });
          }
        );
      })
      .catch((error) => console.log(error));
  };
  getAllAssignmentAgents = () => {
    let loginedUserDetails = JSON.parse(localStorage.getItem("userData"));
    const options = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${this.props.getUserDetails.token}`,
      },
      data: {
        agent_list: "",
        id: [54],
        showAll: "true",
        slug: "all-leads",
        listType: "",
      },

      // data: { logined_user_id: this.props.getUserDetails.user_id },
    };
    return axios(apiUrl + "/get-pre-sales-agents", options)
      .then((data) => {
        this.setState({
          allAssignableAgents: data.data.data,
        });
      })
      .catch((error) => console.log(error));
  };
  getLeadList = (e) => {
    this.setState(
      {
        isLoading: true,
      },
      () => {
        // let loginedUserDetails = JSON.parse(localStorage.getItem("userData"));
        const requestoption = {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${this.props.getUserDetails.token}`,
          },
          data: {
            lead_id: e === 0 ? e : this.state.lead_id,
            mobile: this.state.mobile,
            lead_type: this.state.lead_type.length ? this.state.lead_type : [],
            supportAgentId: this.state.salesAgent.length
              ? this.state.salesAgent
              : [],
            status: this.state.lead_status.length ? this.state.lead_status : [],
            date_from: this.state.date_from,
            date_to: this.state.date_to,
            verificationMode: "",
            priority: "",
            current_page: this.state.current_page,
            total_item: this.state.total_item,
          },
        };
        return axios(apiUrl + "/lead-list", requestoption)
          .then((data) => {
            this.setState(
              {
                LeadList: data.data.data,
                isLoading: false,
              },
              () => {
                const dataNew = this.state.LeadList.map((item, index) => {
                  return {
                    // sno: index + 1,
                    lead_id: item.leadId,
                    lead_type: item.lead_type,
                    ticket_id: item.kapture_ticket_id,
                    customerName: item.customer_name,
                    email: item.email,
                    mobile: item.mobile,
                    priority: item.priority,
                    campaign: item.campaign,
                    created_at: item.createdAt,
                    leadStatus: item.leadStatus,
                    supportAgentId: item.supportAgentId,
                    salesAgent: Number(item.salesAgent),
                    supportAgentName: item.supportAgentName + (item.supportAgent != '' ? `(${item.call_attempted})` : ''),
                    total_leads: item.totalLeads,
                    productArray: item.product,
                    // buying_for: item.buying_for,
                    verification_type: item.verification_mode,

                    status: item.status,

                    location: item.location,
                    location_id: item.location_id,
                    totalLeads: item.totalLeads,
                    totalPrice: item.totalPrice,
                    call_attempted: item.call_attempted,
                    duplicateLeadsCount: item.duplicateLeadsCount,

                    // date_and_time: (
                    //   <span>
                    //     {item.date_only} {item.time_only}
                    //   </span>
                    // ),
                    action: this.state.allowedSlugs.includes("edit") ? (
                      <>
                        {/* {item.status === "Converted to Quote" ? null : ( */}

                        <Button
                          variant="light"
                          type="button"
                          onClick={() => this.openViewDetailPopup(item.leadId, item.leadStatus)}
                          style={{
                            fontSize: 12,
                            border: "1px solid #cccccc",
                          }}
                        >
                          <FontAwesomeIcon icon="edit" />
                        </Button>

                        {/* )} */}
                      </>
                    ) : null,
                  };
                });
                this.setState(
                  {
                    LeadList: dataNew,
                    isPaginationLoading: 0,
                  }
                  // () => {
                  //   this.getTotalRecords();

                  // }
                );
              }
            );
          })
          .catch((error) => {
            this.setState({
              isLoading: false,
            });
          });
      }
    );
  };

  exportsAllLeads = () => {
    return new Promise((resolve, reject) => {
      this.setState(
        {
          isDownloading: true,
        },
        () => {
          // let loginedUserDetails = JSON.parse(localStorage.getItem("userData"));
          const requestoption = {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${this.props.getUserDetails.token}`,
            },
            data: {
              lead_id: 0,
              mobile: this.state.mobile,
              lead_type: this.state.lead_type.length
                ? this.state.lead_type
                : [],
              supportAgentId: this.state.salesAgent.length
                ? this.state.salesAgent
                : [],
              status: this.state.lead_status.length
                ? this.state.lead_status
                : [],
              date_from: this.state.date_from,
              date_to: this.state.date_to,
              verificationMode: "",
              priority: "",
            },
          };
          return axios(apiUrl + "/download-lead-list", requestoption)
            .then((data) => {
              this.setState(
                {
                  LeadListDownload: data.data.data,
                  isDownloading: false,
                },
                () => {
                  const dataNew = this.state.LeadListDownload.map(
                    (item, index) => {
                      return {
                        // sno: index + 1,
                        "Lead ID": item.leadId,
                        "Lead Source": item.lead_type,

                        Name: item.customerName,
                        Mobile: item.mobile,
                        Email: item.email,
                        Location: item.location,
                        Role: item.role,
                        Products: item.product_data,
                        Total: item.totalPrice,
                        "Created At": item.createdAt,
                        "Call Status": item.call_status,
                        "Call Count": item.call_attempted,
                        "Follow up on": item.follow_up,
                        "Lead Status": item.leadStatus,
                        "support Agent": item.supportAgentName,
                        // salesAgentName: item.salesAgentName,
                        "Verification Type": item.verification_mode,
                        // buying_for: item.buying_for,
                        Details: item.details,
                        "Followup Date": item.followup_date,
                        "First Call Status": item.firstCallAttempt.call_status,
                        "First Call Note": item.firstCallAttempt.agent_note,
                        "First Call Username": item.firstCallAttempt.agent_name,
                        "First Call Date": item.firstCallAttempt.created_at,
                        "Last call status": item.lastCallAttempt.call_status,
                        "Last call Note": item.lastCallAttempt.agent_note,
                        "Last call Username": item.lastCallAttempt.agent_name,
                        "Last call Date": item.lastCallAttempt.created_at,
                        "UTM Content": item.utm_content,
                        "UTM Campaign": item.campaign,
                        "UTM Source": item.utm_source,
                        "UTM Medium": item.utm_medium,
                      };
                    }
                  );
                  resolve(dataNew);
                  this.setState({
                    LeadListDownload: dataNew,
                  });
                }
              );
            })
            .catch((error) => {
              this.setState({
                isDownloading: false,
              });
            });
        }
      );
    });
  };
  exportsAllLeadProductList = () => {
    return new Promise((resolve, reject) => {
      this.setState(
        {
          isDownloading: true,
        },
        () => {
          // let loginedUserDetails = JSON.parse(localStorage.getItem("userData"));
          const requestoption = {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${this.props.getUserDetails.token}`,
            },
            data: {
              lead_id: 0,
              mobile: this.state.mobile,
              lead_type: this.state.lead_type.length
                ? this.state.lead_type
                : [],
              supportAgentId: this.state.salesAgent.length
                ? this.state.salesAgent
                : [],
              status: this.state.lead_status.length
                ? this.state.lead_status
                : [],
              date_from: this.state.date_from,
              date_to: this.state.date_to,
              verificationMode: "",
              priority: "",
            },
          };
          return axios(apiUrl + "/download-lead-product-list", requestoption)
            .then((data) => {
              this.setState(
                {
                  LeadProductListDownload: data.data.data,
                  isDownloading: false,
                },
                () => {
                  const dataNew = this.state.LeadProductListDownload.map(
                    (item, index) => {
                      return {
                        // sno: index + 1,
                        "Lead ID": item.leadId,
                        "Lead Source": item.lead_type,
                        "Created At": item.createdAt,

                        Name: item.customerName,
                        Mobile: item.mobile,
                        Email: item.email,
                        Location: item.location,
                        Role: item.role,
                        "Item Type": item.type,
                        Products: item.name,
                        Price: item.price,
                        Quantity: item.quantity,
                        "Product Amount": item.productAmount,
                        Brand: item.brand,
                        "Product Category":
                          item.type == "product"
                            ? item.productParent
                            : item.type == "category"
                              ? item.categoryParent
                              : "",
                        "L1 Category":
                          item.type == "product"
                            ? item.productL1
                            : item.type == "category"
                              ? item.categoryL1
                              : "",
                        Campaign: item.campaign,

                        "Call Status": item.callStatus,
                        "Follow up on": item.followup_date,
                        "Lead Status": item.leadStatus,
                        "support Agent": item.supportAgent,
                        "UTM Campaign": item.utm_campaign,
                        "UTM Content": item.utm_content,
                        "UTM Medium": item.utm_medium,
                        "UTM Source": item.utm_source,
                      };
                    }
                  );
                  resolve(dataNew);
                  this.setState({
                    LeadProductListDownload: dataNew,
                  });
                }
              );
            })
            .catch((error) => {
              this.setState({
                isDownloading: false,
              });
            });
        }
      );
    });
  };
  exportFollowup = () => {
    let dataNew = this.state.apiData.map((item) => {
      return {
        "Lead Id": item.id,
        Agent: item.agentName,
        Type: item.type,
        Name: item.name,
        Mobile: item.mobile,
        "Follow Up date": item.followup_date,
      };
    });
    this.setState({ FollowupData: dataNew }, () => {
      setTimeout(() => {
        this.getExcel2();
        // this.csvLinkEl.current.click();
      });
    });
  };
  getExcel2 = () => {
    const wb = XLSX.utils.book_new();
    const ws = XLSX.utils.json_to_sheet(
      this.state.FollowupData
      // { origin: "A2", skipHeader: true }
    );

    XLSX.utils.book_append_sheet(wb, ws, "followupListLeads");

    let buf = XLSX.write(wb, { bookType: "xls", type: "buffer" });
    XLSX.write(wb, { bookType: "xls", type: "binary" });
    XLSX.writeFile(wb, "followup-List-Lead.xls");
  };
  downloadReport = async (val) => {
    let result = null;
    if (this.state.option === "lead_list") {
      result = await this.exportsAllLeads();
    } else {
      result = await this.exportsAllLeadProductList();
    }
    if (result.length > 0) {
      this.setState({ LeadListDownload: result }, () => {
        setTimeout(() => {
          this.handleExport();
          // this.csvLinkEl.current.click();
        });
      });
    }
  };

  filterByAssignTo = (e) => {
    this.setState(
      {
        lead_id: 0,

        salesAgent: e,

        current_page: 1,
        // QuoteListDownload: [],
      },
      () => {
        this.getTotalRecords();
        this.paginationLoading();
        this.getLeadList();
      }
    );
  };
  debounce = (cb, delay = 1000) => {
    let timeout;
    return (...args) => {
      clearTimeout(timeout);

      timeout = setTimeout(() => {
        cb(...args);
      }, delay);
    };
  };

  mobileFilter = (e) => {
    this.setState(
      {
        mobile: e,
        lead_id: 0,
        current_page: 1,
      },
      () => {
        this.getTotalRecords();
        this.paginationLoading();
        this.getLeadList();
      }
    );
  };
  updateMobile = this.debounce(this.mobileFilter);
  getMobile = (e) => {
    this.updateMobile(e.target.value);
  };
  // filterByStatus = (e) => {
  //   this.setState(
  //     {
  //       lead_status: e.target.value,
  //       current_page: 1,
  //       // QuoteListDownload: [],
  //     },
  //     () => {
  //       this.paginationLoading();
  //       this.getLeadList();
  //     }
  //   );
  // };
  // convertQuoteToOther = (e) => {
  //   const { checkBoxList } = this.state;
  //   const selected_ids = checkBoxList.map((item) => item.id);
  //   this.setState(
  //     {
  //       selecteTUserID: e.target.value,
  //       current_page: 1,
  //     },
  //     () => {
  //       let loginedUserDetails = JSON.parse(localStorage.getItem("userData"));
  //       const options = {
  //         method: "POST",
  //         data: {
  //           selectedQuoteID: selected_ids,
  //           selecteTUserID: this.state.selecteTUserID,
  //           logined_user_id: loginedUserDetails.user_id,
  //         },
  //         headers: {
  //           "Content-Type": "application/json",
  //           Authorization: `Bearer ${this.props.getUserDetails.token}`,
  //         },
  //       };
  //       return axios(apiUrl + "/convert-quotes", options)
  //         .then((data) => {
  //           if (data.data.data.affectedRows > 0) {
  //             this.paginationLoading();
  //             this.getAllQuote();
  //             //this.exportsAllQuote();
  //             this.setState({
  //               checkBoxList: [],
  //               selecteTUserID: "",
  //             });
  //           }
  //         })
  //         .catch((error) => console.log(error));
  //     }
  //   );
  // };

  filterByLeadSource = (e) => {
    this.setState(
      {
        lead_type: e,
        // lead_id: e.target.id,
        current_page: 1,
        lead_id: 0,
        // QuoteListDownload: [],
      },
      () => {
        this.getTotalRecords();
        this.paginationLoading();
        this.getLeadList();
      }
    );
  };
  filterByLeadStatus = (e) => {
    this.setState(
      {
        lead_status: e,
        lead_id: 0,
        // lead_id: e.target.id,
        current_page: 1,
        // QuoteListDownload: [],
      },
      () => {
        this.getTotalRecords();
        this.paginationLoading();
        this.getLeadList();
      }
    );
  };
  getTotalRecords = () => {
    this.setState(
      {
        isLoading: true,
      },
      () => {
        // let loginedUserDetails = JSON.parse(localStorage.getItem("userData"));
        const requestoption = {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${this.props.getUserDetails.token}`,
          },

          data: {
            lead_id: 0,
            mobile: this.state.mobile,
            lead_type: this.state.lead_type.length ? this.state.lead_type : [],
            supportAgentId: this.state.salesAgent.length
              ? this.state.salesAgent
              : [],
            status: this.state.lead_status.length ? this.state.lead_status : [],
            date_from: this.state.date_from,
            date_to: this.state.date_to,
            verificationMode: "",
            priority: "",
            current_page: 1,
            total_item: this.state.total_item,
          },
        };
        return axios(apiUrl + "/leads-pagination", requestoption)
          .then((data) => {
            this.setState({
              total_record: data.data.data[0].count,
              // isDownloading: false,
            });
          })
          .catch((error) => {
            this.setState({
              isLoading: false,
            });
          });
      }
    );
  };
  handleExport = () => {
    var wb = XLSX.utils.book_new(),
      ws = XLSX.utils.json_to_sheet(this.state.LeadListDownload);

    XLSX.utils.book_append_sheet(wb, ws, "lead-list");
    XLSX.writeFile(
      wb,
      this.state.option === "lead_list"
        ? "Lead-List.xls"
        : "Lead-Product-list.xls"
    );
  };
  openViewDetailPopup = (id, leadStatus) => {
    this.setState({
      propLeadStatus: leadStatus,
      lead_id: id,
      show: true,
    });
  };
  TicketDetailPopup = (id) => {
    this.setState({
      lead_id: id,
      showTicket: true,
    });
  };
  closeModal2 = () => {
    this.setState({
      showTicket: false,
    });
  };
  closeFollowupModal = () => {
    this.setState({
      showFollowup: false,
    });
  };
  convertLeadToOther = (e) => {
    const { checkBoxList } = this.state;
    const selected_ids = checkBoxList.map((item) => item.id);
    this.setState(
      {
        selecteTUserID: e.target.value,
        current_page: 1,
      },
      () => {
        let loginedUserDetails = JSON.parse(localStorage.getItem("userData"));
        const options = {
          method: "PUT",
          data: {
            // selectedQuoteID: selected_ids,
            // selecteTUserID: this.state.selecteTUserID,
            // logined_user_id: loginedUserDetails.user_id,
            lead_id: selected_ids,
            supportAgentId: Number(this.state.selecteTUserID),
          },
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${this.props.getUserDetails.token}`,
          },
        };
        return axios(apiUrl + "/assigned-lead", options)
          .then((data) => {
            this.paginationLoading();
            this.getLeadList();
            //this.exportsAllQuote();
            this.setState({
              checkBoxList: [],
              selecteTUserID: "",
              lead_assigned_message: true,
            });
          })
          .catch((error) => console.log(error));
      }
    );
  };
  paginationLoading = () => {
    this.setState({
      isPaginationLoading: 1,
    });
  };

  closeModal = () => {
    this.setState({
      show: false,
      duplicateShow: false,
    });
  };
  getPaginationValue = (currentPage, displayItems) => {
    this.setState(
      {
        current_page: currentPage,
        total_item: displayItems,
        lead_id: 0,
      },
      () => {
        this.getLeadList();
      }
    );
  };
  openDuplicatePopup = (mobile) => {
    this.setState({
      duplicateShow: true,
      mobile: mobile,
    });
  };
  onSelect = (isSelect, row) => {
    const { checkBoxList } = this.state;
    if (checkBoxList.length > 0) {
      let flag = false;
      const updateList = [];
      checkBoxList.forEach((item) => {
        if (item.id === row) {
          flag = true;
        } else {
          updateList.push(item);
        }
      });
      if (flag === false) {
        updateList.push({ id: row });
      }
      this.setState({ checkBoxList: updateList });
    } else {
      this.getAllAssignmentAgents();
      this.setState({
        checkBoxList: [
          {
            id: row,
          },
        ],
      });
    }
  };
  allSelect = () => {
    const { allSelectInit } = this.state;
    if (allSelectInit) {
      this.setState({ allSelectInit: false, checkBoxList: [] });
    } else {
      const updateList = [];
      this.state.LeadList.map((item, index) => {
        updateList.push({
          id: item.lead_id,
        });
      });
      this.setState({ allSelectInit: true, checkBoxList: updateList });
    }
  };
  handleChange = (e) => {
    this.setState(
      {
        // [e.target.name]: e.target.value,
        option: e.target.value,
      },
      () => {
        this.downloadReport(this.state.option);
      }
    );
  };
  render() {
    const setDateRange = (data) => {
      this.setState(
        {
          date_from: data.start,
          date_to: data.end,
          lead_id: 0,
          current_page: 1,
        },
        () => {
          this.getTotalRecords();
          this.paginationLoading();
          this.getLeadList();
        }
      );
    };
    const {
      checkBoxList,
      allSelectInit,
      // downloadAttachment,
      // setOpen,
      // open,
      // options,
    } = this.state;
    return (
      <>
        {this.state.apiData.length ? (
          <Modal
            show={this.state.showFollowup}
            onHide={this.closeFollowupModal}
            dialogClassName="modal-90w business_process_wrapper"
            backgroundColor="#FCAE1E"
          
          >
            <Modal.Header closeButton>
              {this.state.allowedSlugs.includes("download") ? (
                <div>
                  <Button
                    onClick={this.exportFollowup}
                    // variant="outline-success"
                    className="listingBtn"
                  >
                    Download
                  </Button>
                </div>
              ) : null}
            </Modal.Header>
            <div>
              <div className="mobile-table-scroll">
                <table className="table table-fixed">
                  <thead style={{ position: "sticky", top: -1 }}>
                    <th>Lead ID</th>
                    <th>Agent</th>
                    <th>Type</th>
                    <th>Name</th>
                    <th>Mobile</th>
                    <th>FollowUp Date</th>
                    <th>Action</th>
                  </thead>
                  <tbody className="all-leadsbg-wrapper">
                    {this.state.apiData &&
                      this.state.apiData.map((item) => {
                        return (
                          <tr style={{ lineHeight: "10px" }} 
                          >
                            <td>{item.id}</td>
                            <td>{item.agentName}</td>
                            <td>{item.type}</td>
                            <td>{item.name}</td>
                            <td>{item.mobile}</td>
                            <td>{item.followup_date}</td>
                            {this.state.allowedSlugs.includes("edit") ? (
                              <td>
                                <Button
                                  variant="light"
                                  type="button"
                                  size="sm"
                                  onClick={() =>
                                    this.openViewDetailPopup(item.id, item.status)
                                  }
                                  style={{
                                    fontSize: 12,
                                    border: "1px solid #cccccc",
                                  }}
                                >
                                  <FontAwesomeIcon icon="edit" /> View
                                </Button>
                              </td>
                            ) : null}
                          </tr>
                        );
                      })}
                  </tbody>
                </table>
              </div>
            </div>
          </Modal>
        ) : null}

        <div className="card table-card quoteRequestPage">
          <div className="headingPadding">
            <div className="">
              {this.state.allowedSlugs.includes("assignment") &&
                checkBoxList.length > 0 ? (
                <Form.Control
                  className="mt-3"
                  as="select"
                  custom
                  defaultValue={this.state.selecteTUserID}
                  onChange={this.convertLeadToOther}
                >
                  <option value="">Assign to ...</option>
                  {this.state.allAssignableAgents.map((item, index) => {
                    return item.id ? (
                      <option key={index} value={item.id}>
                        {item.name}
                      </option>
                    ) : null;
                  })}
                </Form.Control>
              ) : (
                <h4 className="mb-0 mt-3">LEADS</h4>
              )}
            </div>
            <div className="">
              <Form className="quoteRequestPageFilter">
                <Row>
                  <Col md="3" className="mt-3">
                    <div className="customFilterCnt">
                      <div className="customFilter">
                        {/* <Daterangepicker
                      setDateRange={setDateRange}
                    ></Daterangepicker> */}
                        <DateRangeNew
                          label={"Date Filter"}
                          // allData={true}
                          setDateRange={setDateRange}
                        ></DateRangeNew>
                      </div>
                    </div>
                  </Col>
                  <Col md="3" className="mt-3">
                    <Select
                      defaultValue={[]}
                      isMulti
                      name="leadStatus"
                      closeMenuOnSelect={false}
                      hideSelectedOptions={false}
                      controlShouldRenderValue={false}
                      options={this.state.leadStatusArray}
                      className="basic-multi-select listingFontSizeColor"
                      classNamePrefix="select"
                      placeholder="Filter Status"
                      onChange={(options) => {
                        if (Array.isArray(options)) {
                          // this.setState(options?.map((opt) => opt.user_id));

                          this.setState(
                            {
                              lead_status: options?.map((opt) => opt.label),
                            },
                            () => {
                              this.filterByLeadStatus(
                                this.state.lead_status
                              );
                            }
                          );
                        } else {
                          this.filterByLeadStatus([]);
                        }
                      }}
                      components={{
                        Option: InputOption,
                      }}
                    />

                    {/* <Form.Control
                      as="select"
                      defaultValue={this.state.lead_status}
                      custom
                      onChange={this.filterByLeadStatus}
                    >
                      <option value="">Filter-Status</option>
                      {this.state.leadStatusArray.map((item, index) => {
                        return (
                          <option
                            key={index}
                            id={item.id}
                            value={item.status}
                          >
                            {item.status}
                          </option>
                        );
                      })}
                    </Form.Control> */}
                  </Col>
                  <Col md="3" className="mt-3">
                    <Form.Control
                      // className="mt-3"
                      className="listingFontSizeColor"
                      placeholder="Filter-Mobile"
                      onChange={(data) => this.getMobile(data)}
                    />
                  </Col>
                  <Col md="3" className="mt-3">
                    <Select
                      defaultValue={[]}
                      isMulti
                      name="leadType"
                      closeMenuOnSelect={false}
                      hideSelectedOptions={false}
                      controlShouldRenderValue={false}
                      options={this.state.leadSourcesArray}
                      className="basic-multi-select listingFontSizeColor"
                      classNamePrefix="select"
                      placeholder="Filter Type"
                      onChange={(options) => {
                        if (Array.isArray(options)) {
                          // this.setState(options?.map((opt) => opt.user_id));

                          this.setState(
                            {
                              lead_type: options?.map((opt) => opt.value),
                            },
                            () => {
                              this.filterByLeadSource(this.state.lead_type);
                            }
                          );
                        } else {
                          this.filterByLeadSource([]);
                        }
                      }}
                      components={{
                        Option: InputOption,
                      }}
                    />
                    {/* <Form.Control
                      as="select"
                      defaultValue={this.state.lead_type}
                      custom
                      onChange={this.filterByLeadSource}
                    >
                      <option value="">Filter-Type</option>
                      {this.state.all_lead_source.map((item, index) => {
                        return (
                          <option
                            key={index}
                            id={item.id}
                            value={item.meta_value}
                          >
                            {item.meta_title}
                          </option>
                        );
                      })}
                    </Form.Control> */}
                  </Col>
                  {this.state.agentOptions &&
                    this.state.agentOptions.length > 1 ? (
                    <Col md="3" className="mt-3">
                      <Select
                        defaultValue={[]}
                        isMulti
                        name="orderType"
                        closeMenuOnSelect={false}
                        hideSelectedOptions={false}
                        controlShouldRenderValue={false}
                        options={this.state.agentOptions}
                        className="basic-multi-select listingFontSizeColor"
                        classNamePrefix="select"
                        placeholder="Filter Agents"
                        onChange={(options) => {
                          if (Array.isArray(options)) {
                            // this.setState(options?.map((opt) => opt.user_id));

                            this.setState(
                              {
                                salesAgent: options?.map(
                                  (opt) => opt.value
                                ),
                              },
                              () => {
                                this.filterByAssignTo(
                                  this.state.salesAgent
                                );
                              }
                            );
                          } else {
                            this.filterByAssignTo([]);
                          }
                        }}
                        components={{
                          Option: InputOption,
                        }}
                      />
                    </Col>
                  ) : null}
                  {this.state.allowedSlugs.includes("download") ? (
                    <Col md="3" className="mt-3">
                      {this.state.isDownloading ? (
                        <span
                          variant="primary"
                          className="btn btn-primary downLoadButton"
                        >
                          <Spinner animation="border" />
                        </span>
                      ) : (
                        <select
                           className="listingBtn"
                          // onChange={this.downloadReport}
                          onChangeCapture={this.handleChange}
                          disabled={this.state.isDownloading}
                          value={0}
                        >
                          <option value="0">Download</option>
                          <option value="lead_list">Lead List</option>
                          <option value="lead_product_list">
                            Lead Product List
                          </option>
                        </select>
                      )}
                    </Col>
                  ) : null}
                  {this.state.allowedSlugs.includes("add") ? (
                    <Col md="3" className="mt-3">
                      <Link
                        to={{ pathname: "/createlead" }}
                         className="listingBtn"
                      >
                        Add Lead
                      </Link>
                    </Col>
                  ) : null}
                </Row>
              </Form>
            </div>
          </div>
          <div className="card-block">
            {this.state.isLoading ? (
              <div style={{ textAlign: "center", marginTop: 20 }}>
                <Spinner animation="border" />
              </div>
            ) : (
              <div className="mobile-table-scroll">
              <table className="table table-striped table-hover">
                <thead>
                  <tr>
                    {this.state.allowedSlugs.includes("assignment") ? (
                      <th
                        className="selection-cell-header"
                        data-row-selection="true"
                      >
                        <input
                          type="checkbox"
                          className="selection-input-4"
                          checked={allSelectInit}
                          onChange={this.allSelect}
                        />
                      </th>
                    ) : null}
                    <th tabindex="0">Lead Id</th>
                    <th tabindex="0">Lead source </th>
                    <th tabindex="0">Name</th>
                    {/* <th tabindex="0">Location</th> */}
                    <th tabindex="0">Mobile</th>
                    <th tabindex="0">Product Details</th>
                    <th tabindex="0">Total</th>
                    <th tabindex="0">Date</th>
                    <th tabindex="0">Status</th>
                    <th tabindex="0">Support Agent</th>
                    {/* <th tabindex="0">Sales Assigned to</th> */}
                    <th tabindex="0">Verification Type</th>
                    <th tabindex="0">Ticket ID</th>

                    {/* <th tabindex="0">Priority</th> */}
                    {/* <th tabindex="0">Type</th> */}
                    {/* <th tabindex="0">Campaign</th> */}
                    {/* <th tabindex="0">Ticket ID</th>
                    <th
                      tabindex="0"
                      aria-label="Date sortable"
                      className="sortable"
                    >
                      Date<span className="order-4"></span>
                    </th> */}
                    <th tabindex="0">Actions</th>
                  </tr>
                </thead>
                <tbody>
                  {this.state.LeadList.map((item, index) => {
                    let checked = false;
                    if (checkBoxList.length > 0) {
                      checkBoxList.map((element, index) => {
                        if (element.id === item.lead_id) {
                          checked = true;
                        }
                      });
                    }

                    return (
                      <tr key={item.lead_id}>
                        {this.state.allowedSlugs.includes("assignment") ? (
                          <td>
                            <input
                              type="checkbox"
                              className="selection-input-4"
                              onChange={(e) => this.onSelect(e, item.lead_id)}
                              checked={checked}
                            />
                          </td>
                        ) : null}
                        <td>{item.lead_id}</td>
                        <td>{item.lead_type}</td>
                        <td>{item.customerName}</td>
                        <td>{item.mobile}</td>
                        <td>
                          <table>
                            <tbody>
                              {item.productArray &&
                                item.productArray.map((data, ind) => (
                                  <ul className="list-unstyled">
                                    <li key={ind}>
                                      <b>
                                        {data.type.charAt(0).toUpperCase() +
                                          data.type.slice(1)}{" "}
                                        :{" "}
                                      </b>
                                      {data.name}
                                      {/* <br />
                                      <b>Quantity: </b>
                                      {data.quantity} | <b>Price: </b>
                                      {data.price} |
                                      <br />
                                      <b>Type: </b>
                                      {data.type} */}
                                    </li>
                                  </ul>
                                ))}
                            </tbody>
                          </table>
                        </td>
                        <td>{item.totalPrice}</td>
                        <td>{item.created_at}</td>
                        <td>
                          {item.leadStatus}{" "}
                          {["Follow-up", "Pending"].includes(item.leadStatus)
                            ? `(${item.call_attempted})`
                            : ""}
                        </td>
                        <td>{item.supportAgentName}</td>
                        {/* <td>{item.supportAgentId}</td> */}
                        <td>{item.verification_type}</td>
                        <td>{item.ticket_id}</td>

                        <td className="table_list_column_icons">
                          <div>
                            Total Leads:
                            {item.totalLeads}
                          </div>
                          {item.duplicateLeadsCount ?
                            <div>
                              <b> Duplicate Leads: {item.duplicateLeadsCount}</b>
                            </div>
                            : null}
                          {item.action}
                          <Button
                            variant="light"
                            type="button"
                            //  onClick={() => this.TicketDetailPopup(item.id)}
                            onClick={() => this.TicketDetailPopup(item.lead_id)}
                            style={{
                              fontSize: 12,
                              border: "1px solid #cccccc",
                              marginLeft: '5px',
                            }}
                          >
                            {/* <FontAwesomeIcon icon="faDoorClosed" /> */}
                            Ticket
                          </Button>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
              </div>
            )}
          </div>
          <Pagination
            current_page={this.state.current_page}
            total_item={this.state.total_record}
            getPaginationValue={this.getPaginationValue}
            isPaginationLoading={this.state.isPaginationLoading}
          />
          {this.state.show ? (
            <ApproveLead
              show={this.state.show}
              leadId={this.state.lead_id}
              closeModal={this.closeModal}
              getAllQuote={this.getAllQuote}
              rfq_priority={this.state.rfq_priority}
              getLeadList={this.getLeadList}
              lead_status={this.state.propLeadStatus}
              getUserDetails={this.props.getUserDetails}
            />
          ) : null}
          {this.state.showTicket ? (
            <TicketRaise
              leadId={this.state.lead_id}
              showModal={this.state.showTicket}
              closeModal2={this.closeModal2}
              getLeadList={this.getLeadList}
              fromLead={this.state.fromLead}
              getUserDetails={this.props.getUserDetails}
            />
          ) : null}
          {this.state.duplicateShow ? (
            <DuplicateLead
              leadId={this.state.lead_id}
              duplicateShow={this.state.duplicateShow}
              closeModal={this.closeModal}
              mobile={this.state.mobile}
            />
          ) : null}
        </div>
      </>
    );
  }
}

export default AllLeads;
