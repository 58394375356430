import React, { useState } from "react";
// import accordion c.s.s
import accorCSS from "./AccordionPage.module.css";
// import right arrow icon
import RightArrowIcon from "../../../asset/Images/RightArrow.svg";
// import down arrow icon
import DownArrowIcon from "../../../asset/Images/rightAccordionArrow.svg";
import { Col, Form } from "react-bootstrap";
// import moment for using date
import moment from "moment";
import classes from "./CreateSource.module.css";

const AccordionPage = ({ accordionData, handleAttachmentClick }) => {
  const [open, setOpen] = useState(false);
  return (
    <div className={accorCSS.accordion_wrapper}>
      <button className={`${accorCSS.accordion} ${open ? `${accorCSS.accorOpen}` : ""}`} onClick={() => setOpen(!open)}>
        <span className={accorCSS.response_title}>
          Response Ticket #{accordionData.history_count}
        </span>
        <span>
          {open ? (
            <img src={RightArrowIcon} alt="rightArrowIcon" />
          ) : (
            <img src={DownArrowIcon} alt="DownArrowIcon" />
          )}
        </span>
      </button>
      {open && (
        <>
          {/* sales response start here */}
          <div className={`${accorCSS.accordion_inner_child} ${classes.pt_24}`}>
            <div>
              <h3 className={classes.requirement_detail_heading}>
                Sales Request
              </h3>
              <div className={accorCSS.agentInfoCnt}>
                <p className={accorCSS.agentInfoCntContent}>
                  <span className={`${accorCSS.d_none_mobile}`}>by:&nbsp;</span><span>{accordionData.request.sales_agent}</span>
                </p>

                <hr className={accorCSS.divider} />
                <p className={accorCSS.agentInfoCntContent}>
                  {moment
                    .utc(accordionData.request.submitted_on)
                    .format("DD-MM-YYYY hh:mm A")}
                </p>
                <hr className={`${accorCSS.divider} ${accorCSS.d_none_mobile}`} />
                <p className={`${accorCSS.agentInfoCntContent} ${accorCSS.d_none_mobile}`}>
                  {accordionData.location}
                </p>
              </div>
              <hr className={classes.dottedBorder}></hr>
            </div>
            {accordionData.request.product.map((item, index) => (
              <React.Fragment key={index}>
                <Form.Row className="justify-content-between">
                  <Col lg={2} xs={12} className={classes.verticalCenter}>
                    <Form.Group className={classes.formGroup}>
                      <Form.Label className={classes.customLabel}>
                        Type
                      </Form.Label>
                      <p className={classes.labelreadContent}>{item.type}</p>
                    </Form.Group>
                    <hr className={classes.customeSeprator} />
                  </Col>
                  {item.type === "attachments" ? (
                    <Col lg={10} xs={12} className={classes.verticalCenter}>
                      <Form.Group className={classes.formGroup}>
                        <Form.Label className={classes.customLabel}>
                          Name
                        </Form.Label>
                        <ul style={{ listStyleType: "none", margin: "0" }}>
                          <li>
                            <a
                              className={classes.attachmenttext}
                              href={item.name}
                              target="_blank"
                              onClick={() => handleAttachmentClick(item.name)}
                            >
                              Attachment
                            </a>
                          </li>
                        </ul>
                      </Form.Group>
                    </Col>
                  ) : null}
                  {item.type !== "attachments" ? (
                    <>
                      <Col lg={6} xs={12} className={classes.verticalCenter}>
                        <Form.Group className={classes.formGroup}>
                          <Form.Label className={classes.customLabel}>
                            Name
                          </Form.Label>
                          <p className={classes.labelreadContent}>{item.name}</p>
                        </Form.Group>
                        <hr className={classes.customeSeprator} />
                      </Col>
                      <Col lg={2} xs={12} className={classes.verticalCenter}>
                        <Form.Group className={classes.formGroup}>
                          <Form.Label className={classes.customLabel}>
                            Brand
                          </Form.Label>
                          <p className={classes.labelreadContent}>{item.brand}</p>
                        </Form.Group>
                        <hr className={classes.customeSeprator} />
                      </Col>
                      <Col lg={1} xs={6} className={classes.verticalCenter}>
                        <Form.Group className={classes.formGroup}>
                          <Form.Label className={classes.customLabel}>
                            Quantity
                          </Form.Label>
                          <p className={classes.labelreadContent}>{item.quantity}</p>
                        </Form.Group>
                        <hr className={classes.customeSeprator} />
                        <hr className={classes.customeSepratorMobile} />
                      </Col>
                      <Col lg={1} xs={6} className={classes.verticalCenter}>
                        <Form.Group className={classes.formGroup}>
                          <Form.Label className={classes.customLabel}>
                            Unit
                          </Form.Label>
                          <p className={classes.labelreadContent}>{item.quantity_type}</p>
                        </Form.Group>
                      </Col>
                    </>
                  ) : null}
                </Form.Row>
                <hr className={classes.dottedBorder}></hr>
              </React.Fragment>
            ))}
            <Form.Group className={classes.formGroup}>
              <Form.Label className={classes.remarksLabel}>
                KAM Remarks
              </Form.Label>
              <p className={classes.remarksReadableContent}>
                {accordionData.request.sales_remarks}
              </p>
            </Form.Group>
          </div>
          <hr className={classes.section_seprator} />
          {/* sales response ends here */}

          {/*  sourcing response start here */}
          <div className={`${accorCSS.accordion_inner_child}`}>
            <div>
              <h3 className={classes.requirement_detail_heading}>
                Sourcing Response
              </h3>
              <div className={accorCSS.agentInfoCnt}>
                <p className={accorCSS.agentInfoCntContent}>
                  <span className={`${accorCSS.d_none_mobile}`}>by:&nbsp;</span><span>{accordionData.response.sourcing_agent}</span>
                </p>
                <hr className={accorCSS.divider} />
                <p className={accorCSS.agentInfoCntContent}>
                  {moment
                    .utc(accordionData.response.completed_on)
                    .format("DD-MM-YYYY hh:mm A")}
                </p>
                <hr className={`${accorCSS.divider} ${accorCSS.d_none_mobile}`} />
                <p className={`${accorCSS.agentInfoCntContent} ${accorCSS.d_none_mobile}`}>
                  {accordionData.location}
                </p>
              </div>
              <hr className={classes.dottedBorder}></hr>
            </div>

            {accordionData.response.product.map((item, index) => (
              <React.Fragment key={index}>
                <Form.Row className="justify-content-beteen mb-3">
                  <Col lg={4} xs={12} className={classes.verticalCenter}>
                    <Form.Group className={classes.formGroup}>
                      <Form.Label className={classes.customLabel}>
                        Name
                      </Form.Label>
                      <p className={classes.labelreadContent}>{item.name}</p>
                    </Form.Group>
                    <hr className={classes.customeSeprator} />
                  </Col>
                  <Col lg={1} xs={4} className={classes.verticalCenter}>
                    <Form.Group className={classes.formGroup}>
                      <Form.Label className={classes.customLabel}>
                        Quantity
                      </Form.Label>
                      <p className={classes.labelreadContent}>{item.quantity}</p>
                    </Form.Group>
                    <hr className={classes.customeSeprator} />
                    <hr className={classes.customeSepratorMobile} />
                  </Col>
                  <Col lg={1} xs={4} className={classes.verticalCenter}>
                    <Form.Group className={classes.formGroup}>
                      <Form.Label className={classes.customLabel}>
                        Unit
                      </Form.Label>
                      <p className={classes.labelreadContent}>{item.quantity_type}</p>
                    </Form.Group>
                    <hr className={classes.customeSeprator} />
                    <hr className={classes.customeSepratorMobile} />
                  </Col>
                  <Col lg={1} xs={4} className={classes.verticalCenter}>
                    <Form.Group className={classes.formGroup}>
                      <Form.Label className={classes.customLabel}>
                        T.P
                      </Form.Label>
                      <p className={classes.labelreadContent}>{item.sales_transfer_price}</p>
                    </Form.Group>
                    <hr className={classes.customeSeprator} />
                  </Col>
                  <Col lg={2} xs={12} className={classes.verticalCenter}>
                    <Form.Group className={classes.formGroup}>
                      <Form.Label className={classes.customLabel}>
                        Vendor Name
                      </Form.Label>
                      <p className={classes.labelreadContent}>{item.vendor_name}</p>
                    </Form.Group>
                    <hr className={classes.customeSeprator} />
                  </Col>
                  <Col lg={2} xs={6} className={classes.verticalCenter}>
                    <Form.Group className={classes.formGroup}>
                      <Form.Label className={classes.customLabel}>
                        Availability
                      </Form.Label>
                      <p className={classes.labelreadContent}>{item.availabilityType}</p>
                    </Form.Group>
                    <hr className={classes.customeSeprator} />
                    <hr className={classes.customeSepratorMobile} />
                  </Col>
                  <Col lg={1} xs={6} className={classes.verticalCenter}>
                    <Form.Group className={classes.formGroup}>
                      <Form.Label className={classes.customLabel}>
                        In Days
                      </Form.Label>
                      <p className={classes.labelreadContent}>{item.lead_time}</p>
                    </Form.Group>
                  </Col>
                </Form.Row>
                <hr className={classes.dottedBorder}></hr>
              </React.Fragment>
            ))}
            <Form.Group className={classes.formGroup}>
              <Form.Label className={classes.remarksLabel}>
                Sourcing Remarks
              </Form.Label>
              <p className={classes.remarksReadableContent}>
                {accordionData.response.sourcing_remarks}
              </p>
            </Form.Group>
          </div>
          {/* sourcing response ends here */}
        </>
      )}
    </div>
  );
};

export default AccordionPage;
