import React, { useEffect, useRef } from "react";
import { Modal } from "react-bootstrap";
import classes from "./CreateSource.module.css";
import { Button, Col, Form, Spinner } from "react-bootstrap";
import "../../../../node_modules/bootstrap/dist/css/bootstrap.min.css";
import { ReactComponent as SearchIcon } from "../../../asset/Images/search.svg";
import AsyncSelect from "react-select/async";
import { components } from "react-select";

const CustomInput = (props) => (
  <div style={{ display: "flex", alignItems: "center", height: "100%" }}>
    <SearchIcon style={{ marginRight: "4px" }} />
    <components.Input {...props} />
  </div>
);

const customStyles = {
  control: (provided, state) => ({
    ...provided,
    backgroundColor: "transparent",
    border: "0",
    borderColor: state.isFocused ? "none" : "none",
    "&:hover": {
      borderColor: state.isFocused ? "none" : "none",
    },
    boxShadow: state.isFocused ? "none" : "none",
    minHeight: "auto",
    height: "100%",
  }),
  valueContainer: (provided) => ({
    ...provided,
    height: "100%",
    padding: "0",
  }),
  placeholder: (provided) => ({
    ...provided,
    color: "#99ABBE",
    display: "none",
    fontSize: "13px",
  }),
  singleValue: (provided) => ({
    ...provided,
    color: "#1E293B",
    padding: "0 0 0 20px",
    fontSize: "13px",
  }),
  dropdownIndicator: (provided) => ({
    ...provided,
    display: "none",
  }),
  indicatorSeparator: (provided) => ({
    ...provided,
    display: "none",
  }),
  menu: (provided) => ({
    ...provided,
    left: "0",
  }),
};
const EditLocation = (props) => {
  const inputRef = useRef(null);

  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.focus();
    }
  }, []);

  const {
    show,
    close,
    debouncedFetchLocationOptions,
    handleAsyncSelectChange,
    handleNoSelect,
  } = props;

  return (
    <Modal
      contentClassName={`${classes.customModalBorder} ${classes.editLocationModal}`}
      show={show}
      onHide={close}
      dialogClassName={`${classes.abcd} ${classes.editLocationModalDialog}`}
    >
      <div className="wrapper_modal">
        <Modal.Header closeButton className={`${classes.modal_header} pb-0 `}>
          <Modal.Title className={classes.modal_title}>
            Delivery Location
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className={`${classes.modal_header} pt-0`}>
          <div className={classes.customer_para}>
            <AsyncSelect
              ref={inputRef}
              components={{ Input: CustomInput }}
              className={classes.customSelect}
              styles={customStyles}
              value={""}
              loadOptions={debouncedFetchLocationOptions}
              onChange={handleAsyncSelectChange}
              onBlur={handleNoSelect}
              onKeyPress={(e) => {
                e.key === "Enter" && e.preventDefault();
              }}
              required
            />
          </div>
        </Modal.Body>
        <Modal.Footer
          className={`${classes.modal_header} ${classes.modal_footer}`}
          style={{ boxShadow: "none", border: "0" }}
        ></Modal.Footer>
      </div>
    </Modal>
  );
};
export default EditLocation;
