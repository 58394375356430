import React,{Component} from 'react';
// import { hot } from 'react-hot-loader/root'
import Routes from './route';
import { library } from '@fortawesome/fontawesome-svg-core';
import { faCheckSquare, faUser,faArrowCircleRight,faArrowCircleDown, faPlus, faMinus, faTimesCircle, faAngleDown, faAngleUp, faEdit, faEnvelope, faEye, faExternalLinkAlt, faList, faHome, faCheck, faTimes ,faMagic, faArrowUp, faFileDownload } from '@fortawesome/free-solid-svg-icons';
 
library.add(faCheckSquare, faUser,faArrowCircleRight,faArrowCircleDown,faPlus,faMinus, faAngleDown, faAngleUp, faTimesCircle, faEdit, faEnvelope, faEye, faExternalLinkAlt, faList, faHome, faCheck, faTimes, faMagic,faArrowUp ,faFileDownload)

class App extends Component{
  
  render(){
    return (
        <Routes />
    );
  }
}

export default App;
