import React from "react";
import { Col, Form, Row } from "react-bootstrap";

const UserDetailsMobile = (props) => {
  const {
    handlePhoneChange,
    customer_mobile,
    customer_name,
    customer_email,
    isCompanyExistForMobile,
    showbutton,
    wrong_phone,
    setCustomerEmail,
    setCustomerName,
    mobileMessage,
    addProfile,
    customer_exist,
    handleNextStep
  } = props;
  return (
    <div className="customCard addAddressCardMobile">
      <div className="formstepCnt">
        <span className="steps">1 of 7</span>
      </div>
      <h3 className="customCardheading">User Details</h3>
      <Row>
        <Col md="4">
          <Form.Group
            controlId="formGridCustomerMobile"
            className="customGroup"
          >
            <Form.Label className="customLabel">
              Mobile
              <span class="mandatoryIcon">*</span>
            </Form.Label>
            <Form.Control
              className={"customInput"}
              name="customer_mobile"
              type="number"
              maxLength={10}
              placeholder=""
              onChange={handlePhoneChange}
              defaultValue={customer_mobile}
              required
            />
          </Form.Group>
        </Col>
        <Col md="4">
          <Form.Group controlId="formGridCustomerName" className="customGroup">
            <Form.Label className="customLabel">
              User Name
              <span class="mandatoryIcon">*</span>
            </Form.Label>
            <Form.Control
              name="customer_name"
              className="customInput"
              type="text"
              placeholder=""
              defaultValue={customer_name}
              onChange={setCustomerName}
              required
            />
          </Form.Group>
        </Col>
        <Col md="4">
          <Form.Group controlId="formGridCustomerName" className="customGroup">
            <Form.Label className="customLabel">
              Email
              <span class="mandatoryIcon">*</span>
            </Form.Label>
            <Form.Control
              name="customer_email"
              className="customInput"
              type="email"
              placeholder=""
              defaultValue={customer_email}
              onChange={setCustomerEmail}
              required
            />
          </Form.Group>
        </Col>
      </Row>
      <div className="addAccountFooterCntMobile">
        {isCompanyExistForMobile ? (
          mobileMessage()
        ) : (
          <>
            {customer_mobile && customer_mobile.length === 10 ? (
              showbutton && !wrong_phone ? (
                <button
                  // className="adduserBtn"
                  id="mobileProfile"
                  className="steeperNextBtn w-100"
                  onClick={addProfile}
                >
                  Add User
                </button>
              ) : (
                <button
                  // className="adduserBtn"
                  id="mobileProfile"
                  className="steeperNextBtn w-100"
                  onClick={handleNextStep}
                >
                  Next
                </button>
              )
            ) : null}
          </>
        )}
      </div>
    </div>
  );
};

export default UserDetailsMobile;
