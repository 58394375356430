import React, { useEffect, useState } from "react";
import "./sourcing_upload.css";
import "../../../../node_modules/bootstrap/dist/css/bootstrap.min.css";
import ErrorHandling from "./ErrorHandling";
import ViewIssueModal from "./ViewIssueModal";
import UploadFileModal from "./UploadFileModal";
import SourcingTable from "./SourcingTable";
import SourcingProductTable from "./SourcingProductTable";
import Constants from "../../../Config";
import Axios from "axios";
import ExcelJS from "exceljs";
import { saveAs } from "file-saver";
import * as XLSX from "xlsx";
import { useHistory } from "react-router-dom";

const apiUrl = Constants.API_URL;

const SourcingUplaodList = (props) => {
  const history = useHistory();
  const [showUpload, setShowUpload] = useState(false);
  const [showViewModal, setShowViewModal] = useState(false);
  const [retryModal, setRetryModal] = useState(false);

  const [categoryValue, setCategoryValue] = useState("");
  const [brandValue, setBrandValue] = useState("");
  const [hsnCodeValue, setHsnCodeValue] = useState();
  const [productIndex, setProductIndex] = useState();
  const [suggestionProduct, setSuggestionProduct] = useState([]);
  const [productErrorObj, setProductErrorObj] = useState({});
  const [product, setProduct] = useState({
    "SR ID": 0,
    Model: "",
    "Product Name": "",
    Brand: "",
    Category: "",
    MRP: 0,
    "HSN code": "",
    Unit: "",
  });
  const [addNewProductCategoryList, setAddNewProductCategoryList] = useState(
    []
  );
  const [addNewProductBrandList, setAddNewProductBrandList] = useState([]);
  const [addNewProductHsnCodeList, setAddNewProductHsnCodeList] = useState([]);
  const [selectedFile, setSelectedFile] = useState(null);
  const [uploadFailed, setUploadFailed] = useState(false);
  const [excelData, setExcelData] = useState([]);
  const [uploadStatus, setUploadStatus] = useState("");
  const [showErrorMessage, setShowErrorMessage] = useState("");
  const [showErrorMessage1, setShowErrorMessage1] = useState("");
  const [showSuccessMessage, setShowSuccessMessage] = useState("");
  const [submitLoading, setSubmitLoading] = useState(false);
  const [isDataUploaded, setIsDataUploaded] = useState(false);
  const [viewIssueCount, setViewIssueCount] = useState(0);
  const [viewIssueError, setViewIssueError] = useState([]);
  const [submitDownloadLoading, setSubmitDownloadLoading] = useState(false);
  const [submitAddSRLoading, setSubmitAddSRLoading] = useState(false);
  const [validateLoading, setValidateLoading] = useState(false);
  const [copySuccess, setCopySuccess] = useState("");
  const handleCloseViewModal = () => setShowViewModal(false);
  const handleShowViewModal = () => setShowViewModal(true);

  const handleCloseUploadModal = () => {
    setShowUpload(false);
    setRetryModal(false);
  };

  const handleShowUploadModal = () => {
    setUploadFailed(false); // Reset error state
    setRetryModal(false); // Reset retry state
    setShowUpload(true); // Show the upload modal again
    setSelectedFile(null);
  };

  // Function to handle copying the "Model" value
  const handleCopyModel = async (index) => {
    try {
      const modelToCopy = suggestionProduct[index].model;
      await navigator.clipboard.writeText(modelToCopy);
      console.log(modelToCopy, "copied data");
      setCopySuccess("Copied!");
    } catch (err) {
      console.error("Failed to copy: ", err);
      setCopySuccess("Failed to copy!");
    }
    setTimeout(() => setCopySuccess(""), 2000);
  };

  const handleUpdateNewProducts = (event) => {
    const { name, value } = event.target;

    // Update the product state dynamically
    setProduct((prevProduct) => {
      const updatedProduct = {
        ...prevProduct,
        [name]: value,
      };
      setHsnCodeValue(updatedProduct["HSN code"]);
      setBrandValue(updatedProduct["Brand"]);
      setCategoryValue(updatedProduct["Category"]);
      return updatedProduct;
    });
  };

  const handleCategoryList = async (e, callback) => {
    const token = props.getUserDetails.token;
    const params = {
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      data: {
        keyword: e,
      },
    };
    return await Axios(apiUrl + "/get-category-by-keyword", params)
      .then((data) => {
        setAddNewProductCategoryList(data.data.data);
        callback(
          data.data.data.map((i) => ({
            label: `${i.name}`,
            value: `${i.name}`,
          }))
        );
      })
      .catch((err) => console.log(err.response));
  };

  const getAddNewCategoryList = (selectedOption) => {
    const selectedCategory = addNewProductCategoryList.find(
      (el) => el.name === selectedOption.label
    );
    if (selectedCategory) {
      setProduct((prevProduct) => ({
        ...prevProduct,
        Category: selectedCategory.name,
      }));
      setCategoryValue(selectedCategory.name);
    }
  };

  const handleBrandList = async (e, callback) => {
    const token = props.getUserDetails.token;
    const params = {
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      data: {
        keyword: e,
      },
    };
    return await Axios(apiUrl + "/get-brand-by-keyword", params)
      .then((data) => {
        setAddNewProductBrandList(data.data.data);
        callback(
          data.data.data.map((i) => ({
            label: `${i.name}`,
            value: `${i.name}`,
          }))
        );
      })
      .catch((err) => console.log(err.response));
  };

  const getAddNewBrandList = (selectedOption) => {
    const selectedBrand = addNewProductBrandList.find(
      (el) => el.name === selectedOption.label
    );

    if (selectedBrand) {
      setProduct((prevProduct) => ({
        ...prevProduct,
        Brand: selectedBrand.name,
      }));
      setBrandValue(selectedBrand.name);
    }
  };

  const handleHsnCodeList = async (e, callback) => {
    const token = props.getUserDetails.token;
    const params = {
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      data: {
        keyword: e,
      },
    };
    return await Axios(apiUrl + "/get-hsn-by-keyword", params)
      .then((data) => {
        setAddNewProductHsnCodeList(data.data.data);
        callback(
          data.data.data.map((i) => ({
            label: `${i.hsn_code} (${Number(i.rate)}%)`,
            value: `${i.hsn_code}`,
          }))
        );
      })
      .catch((err) => console.log(err.response));
  };

  const getAddNewHsnCodeList = (selectedOption) => {
    const selectedHsn = addNewProductHsnCodeList.find(
      (item) => item.hsn_code == selectedOption.value
    );
    const hsn_code = selectedHsn.hsn_code;
    setProduct((prevProduct) => ({
      ...prevProduct,
      "HSN code": hsn_code, 
    }));
    setHsnCodeValue(`${hsn_code}`);
  };
  

  const handleDelete = () => {
    setSelectedFile(null);
  };

  const handleFileUpload = (event) => {
    const file = event.target.files[0];
    if (
      file &&
      (file.type === "application/vnd.ms-excel" ||
        file.type ===
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet")
    ) {
      setSelectedFile(file);
      setUploadFailed(false);
    } else { 
      setShowErrorMessage("Please upload an Excel file.");
      hideMessagesAfterDelay();
      setSelectedFile(null);
    }
    event.target.value = "";
  };

  const handleFileSubmit = async () => {
    if (!selectedFile) {
      setShowErrorMessage("Please select a file to upload.");
      hideMessagesAfterDelay();
      return;
    }

    setSubmitLoading(true);
    const reader = new FileReader();
    reader.onload = async (e) => {
      const data = new Uint8Array(e.target.result);
      const workbook = XLSX.read(data, { type: "array" });
      const worksheet = workbook.Sheets[workbook.SheetNames[0]];
      const jsonData = XLSX.utils.sheet_to_json(worksheet, { header: 1 });

      if (jsonData && jsonData.length > 1) {
        const formattedData = jsonData
          .slice(1)
          .map((row) => {
            if (row && row.length >= 8) {
              return {
                "SR ID": row[0],
                Model: row[1],
                "Product Name": row[2],
                Brand: row[3],
                Category: row[4],
                MRP: row[5],
                "HSN code": row[6],
                Unit: row[7],
              };
            } else {
              return null; // Handle the case where the row is not as expected
            }
          })
          .filter((item) => item !== null); // Remove any null items

        const formData = new FormData();
        formData.append("file", selectedFile);
        try {
          const response = await Axios.post(
            apiUrl + "/sourcing-bulk-upload",
            formData,
            {
              headers: {
                "Content-Type": "multipart/form-data",
                Authorization: `Bearer ${props.getUserDetails.token}`,
              },
            }
          );
          if (response.statusCode === 200 || response.status === 200) {
            // setUploadStatus("New Products added to Sourcing Requests");
            // hideMessagesAfterDelay();
            handleCloseUploadModal();
            setViewIssueError(response.data.data.errors);
            setViewIssueCount(response.data.data.count);
            setExcelData(formattedData);
            setIsDataUploaded(true);
          } else {
            handleUploadError(
              response.data.message || "File upload failed. Please try again."
            );
          }
        } catch (error) {
          console.error("Error uploading file:", error);
          const errorMessage =
            error.response?.data?.message ||
            "File upload failed. Please try again";
          handleUploadError(errorMessage);
        } finally {
          setSubmitLoading(false);
        }
      } else {
        console.error("Invalid or empty data format in Excel file.");
        handleUploadError("Invalid or empty data format in Excel file.");
      }
    };

    reader.readAsArrayBuffer(selectedFile);
  };

  // Helper function to handle upload errors
  const handleUploadError = (errorMessage) => {
    setUploadFailed(true); // Set uploadFailed to true on error
    // setShowErrorMessage(errorMessage); // Display the error message
    setShowErrorMessage1(errorMessage);
    hideMessagesAfterDelay();
    setRetryModal(false); // Reset retry state on failure
  };

  const handleDeleteRow = (indexToDelete) => {
    const updatedData = excelData.filter((_, index) => index !== indexToDelete);
    setExcelData(updatedData);
    setShowSuccessMessage("Row has been deleted Successfully");
    hideMessagesAfterDelay();
  };

  const handleDeleteProducts = () => {
    // Remove item from excelData
    setExcelData((prevData) =>
      prevData.filter((item, index) => index !== productIndex)
    );
    setShowSuccessMessage("Product has been deleted Successfully");
    hideMessagesAfterDelay();
    handleCloseViewModal();
  };

  const hideMessagesAfterDelay = () => {
    setTimeout(() => {
      setUploadStatus("");
      setShowErrorMessage("");
      setShowSuccessMessage("");
    }, 4000); // 4 seconds delay
  };

  const updateSourceRequestSubmit = async (index, e) => {
    if (submitLoading) return;
    let indexProduct = index;
    setSubmitLoading(true);
    e.preventDefault();
    let loginedUserDetails = JSON.parse(localStorage.getItem("userData"));
    const token = loginedUserDetails.token;
    let product = null;
    let obj = excelData[index];
    product = {
      "SR ID": Number(obj["SR ID"]),
      Model: obj.Model.toString(),
      "Product Name": obj["Product Name"].toString(),
      Brand: obj.Brand.toString(),
      Category: obj.Category.toString(),
      MRP: Number(obj.MRP),
      "HSN code": Number(obj["HSN code"]),
      Unit: obj.Unit.toString(),
    };
    const params = {
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      data: product,
    };
    return await Axios(apiUrl + "/sourcing-upload-validate", params)
      .then((response) => {
        if (response.status === 200) {
          setSuggestionProduct(response.data.data.errors.products);
          setProductErrorObj(response.data.data.errors);
        }
        // setUploadStatus("Updated Products Successfully");
        // hideMessagesAfterDelay();
        setShowErrorMessage("");
        setSubmitLoading(false);
        setProduct(obj);

        setProductIndex(indexProduct);
        handleShowViewModal();
      })
      .catch((err) => {
        console.log(err);
        setSubmitLoading(false);
        setShowErrorMessage(err.response.data.message);
        setShowErrorMessage("");
        hideMessagesAfterDelay();
      });
  };

  const addToSRProductSubmit = async (e) => {
    if (submitAddSRLoading) return;
    setSubmitAddSRLoading(true);
    e.preventDefault();
    let loginedUserDetails = JSON.parse(localStorage.getItem("userData"));
    const token = loginedUserDetails.token;
    let updatedData = [];
    updatedData = excelData.map((rows) => {
      return {
        "SR ID": Number(rows["SR ID"]),
        Model: rows.Model.toString(),
        "Product Name": rows["Product Name"].toString(),
        Brand: rows.Brand.toString(),
        Category: rows.Category.toString(),
        MRP: Number(rows.MRP),
        "HSN code": Number(rows["HSN code"]),
        Unit: rows.Unit.toString(),
      };
    });
    const params = {
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      data: {
        products: updatedData, // Send the array of products
        fileName: selectedFile.name,
      },
    };
    return await Axios(apiUrl + "/sourcing-add-product", params)
      .then(() => {
        setShowSuccessMessage("New Products added to Sourcing Requests");
        setShowErrorMessage("");
        setSubmitAddSRLoading(false);
        downloadExcelFile(excelData, selectedFile.name);
        setTimeout(() => {
          history.push("/sourcing-request");
        },2000)
        hideMessagesAfterDelay();
      })
      .catch((error) => {
        const errorMessage =
          error.response?.data?.message ||
          "Products validation failed. Please try again.";
        setShowErrorMessage(errorMessage);
        setSubmitAddSRLoading(false);
        hideMessagesAfterDelay();
      });
  };

  // Function to create and download Excel file
  const downloadExcelFile = (data, fileName) => {
    const workbook = XLSX.utils.book_new();
    const worksheet = XLSX.utils.json_to_sheet(data);
    XLSX.utils.book_append_sheet(workbook, worksheet, "Products");
  
    // Ensure the fileName ends with .xlsx only once
    const finalFileName = fileName && fileName.endsWith('.xlsx')
      ? fileName
      : `${fileName || "ExcelSheet"}.xlsx`;
  
    XLSX.writeFile(workbook, finalFileName);
  };

  const handleViewIssuesClick = async (index, e) => {
    e.preventDefault();
    await updateSourceRequestSubmit(index, e);
  };

  const generateExcel = async () => {
    try {
      setSubmitDownloadLoading(true);
      const options = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${props.getUserDetails.token}`,
        },
      };
      const response = await Axios.get(
        apiUrl + "/download-import-template",
        options
      );

      const unitData = [
        "box",
        "cu.m",
        "cubic meter",
        "gms",
        "kgs",
        "mtr",
        "pcs",
        "pair",
        "set",
        "ton",
        "sqft",
      ];

      // Extract data from the API response
      const categories =
        response.data.data.category.map((item) => item.name) || [];
      const hsnCodes =
        response.data.data.hsnCode.map((item) => item.hsn_code) || [];
      const brands = response.data.data.brand.map((item) => item.name) || [];
      const units = unitData.map((item) => item) || [];

      // Create a new Excel workbook and worksheets
      const workbook = new ExcelJS.Workbook();
      const sampleSheet = workbook.addWorksheet("Sample Sheet");
      const lookupSheet = workbook.addWorksheet("Lookup");

      // Populate the "Lookup" sheet with the fetched API data
      lookupSheet.addRow(["Categories", "HSN Codes", "Brands", "Units"]); // Add headers
      const maxRows = Math.max(
        categories.length,
        hsnCodes.length,
        brands.length,
        units.length
      );

      for (let i = 0; i < maxRows; i++) {
        lookupSheet.addRow([
          categories[i] || "",
          hsnCodes[i] || "",
          brands[i] || "",
          units[i] || "",
        ]);
      }

      // Set headings in the "Sample Sheet"
      sampleSheet.columns = [
        { header: "SR ID", key: "srId", width: 20 },
        { header: "Model", key: "model", width: 20 },
        { header: "Product Name", key: "productName", width: 25 },
        { header: "Brand", key: "brand", width: 20 },
        { header: "Category", key: "category", width: 20 },
        { header: "MRP", key: "mrp", width: 15 },
        { header: "HSN code", key: "hsnCode", width: 15 },
        { header: "Unit", key: "unit", width: 10 },
      ];

      // Create dynamic references for data validation
      const catLimit = "Lookup!$A$2:$A$" + (categories.length + 1);
      const hsnLimit = "Lookup!$B$2:$B$" + (hsnCodes.length + 1);
      const brandLimit = "Lookup!$C$2:$C$" + (brands.length + 1);
      const unitLimit = "Lookup!$D$2:$D$" + (units.length + 1);

      sampleSheet.getCell("E2").dataValidation = {
        type: "list",
        allowBlank: true,
        formulae: [catLimit], // Dropdown for "Category"
      };
      sampleSheet.getCell("D2").dataValidation = {
        type: "list",
        allowBlank: true,
        formulae: [brandLimit], // Dropdown for "Brands"
      };
      sampleSheet.getCell("G2").dataValidation = {
        type: "list",
        allowBlank: true,
        formulae: [hsnLimit], // Dropdown for "HSN Codes"
      };
      sampleSheet.getCell("H2").dataValidation = {
        type: "list",
        allowBlank: true,
        formulae: [unitLimit], // Dropdown for "Unit Codes"
      };

      // Apply dropdown validation for multiple rows (e.g., from row 2 to 100)
      for (let i = 2; i <= 100; i++) {
        sampleSheet.getCell(`D${i}`).dataValidation =
          sampleSheet.getCell("D2").dataValidation;
        sampleSheet.getCell(`E${i}`).dataValidation =
          sampleSheet.getCell("E2").dataValidation;
        sampleSheet.getCell(`G${i}`).dataValidation =
          sampleSheet.getCell("G2").dataValidation;
        sampleSheet.getCell(`H${i}`).dataValidation =
          sampleSheet.getCell("H2").dataValidation;
      }

      // Hide the "Lookup" sheet
      lookupSheet.state = "hidden";

      // Generate Excel file and save it
      const buffer = await workbook.xlsx.writeBuffer();
      const blob = new Blob([buffer], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });
      saveAs(blob, "Add_Bulk_Products.xlsx");
      // setShowSuccessMessage("Excel Sheet downlaod Successfully!");
      // hideMessagesAfterDelay();
    } catch (error) {
      console.error("Error generating Excel:", error);
      setShowErrorMessage(
        "Failed to fetch data or generate Excel. Please try again."
      );
      hideMessagesAfterDelay();
    } finally {
      setSubmitDownloadLoading(false);
    }
  };

  const validateData = async (excelData) => {
    try {
      setValidateLoading(true);
      let loginedUserDetails = JSON.parse(localStorage.getItem("userData"));
      const token = loginedUserDetails.token;
      let updatedData = [];
      updatedData = excelData.map((rows) => {
        return {
          "SR ID": Number(rows["SR ID"]),
          Model: rows.Model.toString(),
          "Product Name": rows["Product Name"].toString(),
          Brand: rows.Brand.toString(),
          Category: rows.Category.toString(),
          MRP: Number(rows.MRP),
          "HSN code": Number(rows["HSN code"]),
          Unit: rows.Unit.toString(),
        };
      });
      const params = {
        method: "POST",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        data: {
          products: updatedData,
        },
      };
      const response = await Axios(
        apiUrl + "/sourcing-upload-validate-products",
        params
      );
      if (response.status === 200) {
        setViewIssueCount(response.data.data.count);
        setViewIssueError(response.data.data.errors);
      }
    } catch (error) {
      console.error("Error validating data:", error);
      const errorMessage =
        error.response?.data?.message ||
        "File validate failed. Please try again";
      handleUploadError(errorMessage);
    } finally {
      setValidateLoading(false);
    }
  };

  useEffect(() => {
    if (excelData && excelData.length) {
      validateData(excelData);
    }
  }, [excelData]);

  return (
    <>
      {isDataUploaded ? (
        <SourcingProductTable
          excelData={excelData}
          viewIssueError={viewIssueError}
          viewIssueCount={viewIssueCount}
          submitAddSRLoading={submitAddSRLoading}
          validateLoading={validateLoading}
          handleDeleteRow={handleDeleteRow}
          handleViewIssuesClick={handleViewIssuesClick}
          addToSRProductSubmit={addToSRProductSubmit}
        />
      ) : (
        <SourcingTable handleShowUploadModal={handleShowUploadModal} />
      )}
      {showErrorMessage ? (
        <ErrorHandling message={showErrorMessage} type={"ErrorMessage"} />
      ) : null}
      {showSuccessMessage ? (
        <ErrorHandling message={showSuccessMessage} type={"SuccessMessage"} />
      ) : null}
      {uploadStatus ? (
        <ErrorHandling message={uploadStatus} type={"SuccessMessage"} />
      ) : null}
      <UploadFileModal
        retryModal={retryModal}
        showUpload={showUpload}
        selectedFile={selectedFile}
        uploadFailed={uploadFailed}
        submitLoading={submitLoading}
        submitDownloadLoading={submitDownloadLoading}
        isDataUploaded={isDataUploaded}
        showErrorMessage={showErrorMessage1}
        handleShowUploadModal={handleShowUploadModal}
        handleCloseUploadModal={handleCloseUploadModal}
        handleDelete={handleDelete}
        generateExcel={generateExcel}
        handleFileUpload={handleFileUpload}
        handleFileSubmit={handleFileSubmit}
        handleDeleteRow={handleDeleteRow}
      />
      <ViewIssueModal
        showViewModal={showViewModal}
        excelData={excelData}
        productIndex={productIndex}
        submitLoading={submitLoading}
        viewIssueError={viewIssueError}
        suggestionProduct={suggestionProduct}
        product={product}
        productErrorObj={productErrorObj}
        categoryValue={categoryValue}
        brandValue={brandValue}
        hsnCodeValue={hsnCodeValue}
        copySuccess={copySuccess}
        handleCopyModel={handleCopyModel}
        setUploadStatus={setUploadStatus}
        hideMessagesAfterDelay={hideMessagesAfterDelay}
        setExcelData={setExcelData}
        handleDeleteProducts={handleDeleteProducts}
        handleUpdateNewProducts={handleUpdateNewProducts}
        handleCloseViewModal={handleCloseViewModal}
        handleCategoryList={handleCategoryList}
        getAddNewCategoryList={getAddNewCategoryList}
        handleBrandList={handleBrandList}
        getAddNewBrandList={getAddNewBrandList}
        handleHsnCodeList={handleHsnCodeList}
        getAddNewHsnCodeList={getAddNewHsnCodeList}
        updateSourceRequestSubmit={updateSourceRequestSubmit}
      />
    </>
  );
};

export default SourcingUplaodList;
