import React from "react";
import { Alert } from "react-bootstrap";

const ErrorHandling = ({ message, type }) => {
  return (
    <Alert
      className="displayMessageArea"
      variant={type === "danger" ? "danger" : "success"}
    >
      {message}
    </Alert>
  );
};
export default ErrorHandling;
