import React from "react";
import "./sourcing_upload.css";
import Button from "react-bootstrap/Button";
import { Spinner } from "react-bootstrap";

const SourcingUploadFooter = (props) => {
  const { addToSRProductSubmit, submitAddSRLoading, excelData } = props;
  return (
    <>
      <tfoot>
        <div className="table_fix_button d-flex justify-content-between align-items-center">
          <Button 
            className="cancal_btn" 
            type="button" 
            onClick={() => window.location.reload()}>
            Cancel
          </Button>
          <Button className="add_to_sr" 
              type="button" 
              onClick={addToSRProductSubmit} 
              disabled={excelData.length <= 0}
          >
          {submitAddSRLoading ? (
              <Spinner animation="border" role="status" size="sm">
                <span className="sr-only">Loading...</span>
              </Spinner>
            ) : (
              "Add to SR"
            )}
          </Button>
        </div>
      </tfoot>
    </>
  );
};

export default SourcingUploadFooter;
