import React, { useEffect, useState } from "react";
import { Table } from "react-bootstrap";
import "./sourcing_upload.css";
import SourcingContent from "./SourcingContent";
import Pagination from "../Common/Pagination";
import Constants from "../../../Config";
import Axios from "axios";
import moment from "moment";

const SourcingTable = (props) => {
  const apiUrl = Constants.API_URL; // Replace with your API URL
  const getUserDetails = JSON.parse(localStorage.getItem("userData"));

  const { handleShowUploadModal } = props;
  const [currentPage, setCurrentPage] = useState(1);
  const [srListCount, setSrListCount] = useState(0);
  const [isPaginationLoading, setIsPaginationLoading] = useState(0);
  const [totalItem, setTotalItem] = useState(25);
  const [noDataFound, setNoDataFound] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const [uploadHistoryList, setUploadHistoryList] = useState([]);

  const getPaginationValue = (currentPage, displayItems) => {
    setCurrentPage(currentPage);
    setTotalItem(displayItems);
  };

  useEffect(() => {
    getAllPaginationData();
    getAllUploadHistoryData();
  }, [currentPage, totalItem]);

  const getAllUploadHistoryData = () => {
    const requestOption = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${getUserDetails.token}`,
      },
      data: {
        current_page: currentPage,
        total_item: totalItem,
      },
    };
    Axios(`${apiUrl}/get-upload-history`, requestOption)
      .then((response) => {
        const uploadHistoyLists = response.data.data;
        setUploadHistoryList(uploadHistoyLists);
        setLoading(false);
        setIsPaginationLoading(0);
      })
      .catch((error) => {
        setLoading(false);
      });
  };

  const getAllPaginationData = () => {
    const requestOption = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${getUserDetails.token}`,
      },
    };
    Axios(`${apiUrl}/get-upload-history-count`, requestOption)
      .then((response) => {
        const paginationCount = response.data.data;
        if (paginationCount == 0) {
          setNoDataFound(true);
        } else {
          setNoDataFound(false);
        }
        setSrListCount(paginationCount);
      })
      .catch((error) => {
        setLoading(false);
        console.log(error);
      });
  };

  return (
    <>
      <SourcingContent 
       handleShowUploadModal={handleShowUploadModal} 
       uploadHistoryList={uploadHistoryList}
       srListCount={srListCount}
       />
      <div className="table_wrapper">
        <Table className="sourcing_uploading_table">
          <thead>
            <tr>
              <th>Username</th>
              <th>Date-Time</th>
              <th>File Name </th>
              <th>SR IDs & Count </th>
              <th>Product Count</th>
            </tr>
          </thead>
          <tbody>
            {uploadHistoryList && uploadHistoryList.length > 0 ? (
              uploadHistoryList.map((item, index) => (
                <React.Fragment key={index}>
                  <tr>
                    <td>{item.userName}</td>
                    <td>
                      {item.dateTime
                        ? moment
                            .utc(item.dateTime)
                            .format("YYYY-MM-DD HH:mm:ss")
                        : ""}
                    </td>
                    <td>{item.fileName}</td>
                    <td>
                      {item.srId.join(", ")} <br />
                      (#{item.srId.length})
                    </td>
                    <td>#{item.productCount}</td>
                  </tr>
                </React.Fragment>
              ))
            ) : (
              <tr>
                <td colSpan="5" style={{ textAlign: "center" }}>
                  No data found
                </td>
              </tr>
            )}
          </tbody>
        </Table>
        <Pagination
          current_page={currentPage}
          total_item={srListCount}
          getPaginationValue={getPaginationValue}
          isPaginationLoading={isPaginationLoading}
        />
      </div>
    </>
  );
};

export default SourcingTable;
