import React from 'react';
import {Link} from 'react-router-dom';
import {Table, Modal, Button, Alert, Spinner, Form, Col, Row} from 'react-bootstrap';
import axios from 'axios';
import Constants from '../../../Config'; 
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import 'react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css';
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';
import './style.css';
const apiUrl = Constants.API_URL;
const webUrl = Constants.WEB_URL;

const { SearchBar } = Search;
const columns = [
    {
        dataField: 'sno',
        text: 'S.no'
    }, {
        dataField: 'id',
        text: 'Business ID'
    }, {    
        dataField: 'company_name',
        text: 'Company Name',
        sort: true
    }, {
        dataField: 'business_group',
        text: 'Group'
    }, {
        dataField: 'username',
        text: 'Name'    
    }, {
        dataField: 'useremail',
        text: 'Email'    
    }, {
        dataField: 'userphone',
        text: 'Phone'
    }
];

class MyAccount extends React.Component {

    state = {
        BusinessAccountsList:[],
        show : false,
        businessData:[],
        agentList:[],
        selected:[],
        isLoading:false
    }
    componentDidMount() {
        this.getMyAccount();
        this.getAgentList();
    }
    checkORUncheckedAll = () => {
        this.setState({
            checkedAll:true,
        });
    }
    
    handleOnSelect = (row, isSelect) => {
        let displayArray = this.state.selected;
        if (isSelect) {            
            displayArray.push(row.id);        
            this.setState({
                selected:displayArray
            });          
        } else { 
            displayArray.splice(row.id,1);
            this.setState({
                selected:displayArray
            });
        }       
    }
    handleOnSelectAll = (isSelect, rows) => {
        const ids = rows.map(r => r.id);
        if (isSelect) {
          this.setState(() => ({
            selected: ids
          }));
        } else {
          this.setState(() => ({
            selected: []
          }));
        }
    }
    
    getAgentList = () => {
        const options ={
            method : "GET"
        };
        return axios(apiUrl+'/get-agents',options)
            .then(data=>{
                this.setState({
                    agentList:data.data.data
                });
            })
            .catch(error=>{
                this.setState({
                    isLoading:false
                })
            })
    }
    
    getMyAccount = () => {
        this.setState({
            isLoading:true
        },() => {
            const options ={
                method : "POST",
                data: {agent_id: 0},
            };
            return axios(apiUrl+'/my-account',options)
            .then(data=>{
                this.setState({
                    BusinessAccountsList:data.data.data,
                    isLoading:false
                }, () => {
                    const dataNew = this.state.BusinessAccountsList.map((item, index) => {
                        const nameCapitalized = item.business_group.charAt(0).toUpperCase() + item.business_group.slice(1)
                        return {                            
                            sno:index+1,
                            id:item.id,
                            company_name:item.company_name,
                            business_group:nameCapitalized,
                            username:item.username,                         
                            userphone:item.userphone,                         
                            useremail:item.useremail
                        }
                    })
                    this.setState({
                        BusinessAccountsList:dataNew
                    });
                })
            })
            .catch(error=>{
                this.setState({
                    isLoading:false
                })
            })
        });        
    }
    
    render() {
        const selectRow = {
            mode: 'checkbox',
            clickToSelect: true,
            onSelect: this.handleOnSelect,
            onSelectAll: this.handleOnSelectAll
        };
        
        return <div className="card table-card">
                    <div className="card-header">
                        <Form style={{width:"100%"}}>
                            <Row>
                              <Col sm={4}>
                                <Form.Control as="select" custom>
                                    <option value=''>Select Agent to Assign Company</option>
                                    {
                                        this.state.agentList.map((item, index) => {
                                            return <option key={index} value={item.id}>{item.firstname} {item.lastname}</option>
                                        })
                                    }
                                </Form.Control>
                              </Col>
                              <Col style={{paddingLeft:"0px"}}>
                                <Button variant="primary">Assign</Button>
                              </Col>
                            </Row>
                          </Form>
                    </div>
                    <div className="card-block">
                        {
                            this.state.isLoading
                            ?
                            <div style={{textAlign:"center",marginTop:20}}><Spinner animation="border" /></div>
                            :
                            <BootstrapTable 
                                keyField='id' 
                                data={ this.state.BusinessAccountsList } 
                                columns={ columns } 
                                pagination={ paginationFactory() } 
                                striped
                                search
                                hover
                                bordered={ false }
                                bootstrap4
                                selectRow={ selectRow }
                                deleteRow
                            />  
                        }
                    </div>
                </div>;
  }
}

export default MyAccount;