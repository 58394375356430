import React from "react";
import { Modal } from "react-bootstrap";
import classes from "./CreateSource.module.css";
import { Button, Col, Form, Spinner } from "react-bootstrap";
import "../../../../node_modules/bootstrap/dist/css/bootstrap.min.css";
import AsyncSelect from "react-select/async";
import { ReactComponent as SearchIcon } from "../../../asset/Images/search.svg";
import { components } from "react-select";
import ErrorHandling from "./ErrorHandling";
import { ReactComponent as DownArrowIcon } from "../../../asset/Images/RightArrow.svg";

const CustomInput = (props) => (
  <div style={{ display: "flex", alignItems: "center", height:"100%" }}>
    <SearchIcon style={{ marginRight: "8px" }} />
    <components.Input {...props} />
  </div>
);

const customStyles = {
  control: (provided, state) => ({
    ...provided,
    backgroundColor: "transparent",
    border: "0",
    borderColor: state.isFocused ? "none" : "none",
    "&:hover": {
      borderColor: state.isFocused ? "none" : "none",
    },
    boxShadow: state.isFocused ? "none" : "none",
    minHeight: "auto",
    height:"100%"
  }),
  valueContainer:(provided)=>({
    ...provided,
    height:"100%",
    padding:"0"
  }),
  placeholder: (provided) => ({
    ...provided,
    color: "#99ABBE",
    fontSize:"13px"
  }),
  singleValue: (provided) => ({
    ...provided,
    color: "#1E293B",
    padding: "0 0 0 20px",
    fontSize:"13px"
  }),
  dropdownIndicator: (provided) => ({
    ...provided,
    display: "none",
  }),
  indicatorSeparator: (provided) => ({
    ...provided,
    display: "none",
  }),
};

const AddProduct = (props) => {
  const {
    show,
    submitLoading,
    brandValue,
    hsnCodeValue,
    categoryValue,
    handleHsnCodeList,
    handleBrandList,
    handleCategoryList,
    getAddNewBrandList,
    getAddNewHsnCodeList,
    getAddNewCategoryList,
    handleCancelAddNewProduct,
    handleAddNewProductChange,
    createAddNewProductSubmit,
    addNewProductFormData,
    type,
    modalSuccessMessage,
    modalErrorMessage,
    formValidate,
  } = props;

  const quantityTypes = [
    { value: "", label: "Select" },
    { value: "pcs", label: "pcs" },
    { value: "pair", label: "pair" },
    { value: "set", label: "set" },
    { value: "box", label: "box" },
    { value: "cubic", label: "cubic" },
    { value: "meter", label: "meter" },
    { value: "cu.m", label: "cu.m" },
    { value: "ton", label: "ton" },
    { value: "kgs", label: "kgs" },
    { value: "gms", label: "gms" },
    { value: "sqft", label: "sqft" },
    { value: "mtr", label: "mtr" },
  ];

  return (
    <Modal
      style={{padding:"0"}}
      contentClassName={`${classes.customModalBorder} ${classes.animateBottom}`}
      show={show}
      onHide={handleCancelAddNewProduct}
      dialogClassName={`${classes.abcd} ${classes.modal_bottom}`}
    >
      <div className="wrapper_modal">
        <Modal.Header closeButton className={`${classes.modal_header}`}>
          <Modal.Title className={classes.modal_title}>
            Add New Product
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className={classes.modal_header}>
          <Form.Row>
            <Col lg={4} xs={12} className={`${classes.verticalCenter} mt-0`}>
              <Form.Group className={classes.formGroup}>
                <Form.Label className={classes.customer_title}>
                  Product Name
                </Form.Label>
                <Form.Control
                  className={classes.customInput}
                  name="productName"
                  value={addNewProductFormData.productName}
                  onChange={handleAddNewProductChange}
                />
              </Form.Group>
            </Col>
            <Col lg={2} xs={12} className={classes.verticalCenter}>
              <Form.Group className={classes.formGroup}>
                <Form.Label className={classes.customer_title}>
                  Brand
                </Form.Label>
                <AsyncSelect
                  components={{ Input: CustomInput }}
                  styles={customStyles}
                  className={classes.customSelect}
                  value={{
                    value: brandValue,
                    label: brandValue,
                  }}
                  onChange={(e) => getAddNewBrandList(e)}
                  loadOptions={handleBrandList}
                  placeholder="Search Brand"
                />
              </Form.Group>
            </Col>
            <Col lg={2} xs={12} className={classes.verticalCenter}>
              <Form.Group className={classes.formGroup}>
                <Form.Label className={classes.customer_title}>
                  Category
                </Form.Label>
                <AsyncSelect
                  components={{ Input: CustomInput }}
                  styles={customStyles}
                  className={classes.customSelect}
                  name="category"
                  value={{
                    value: categoryValue,
                    label: categoryValue,
                  }}
                  onChange={(e) => getAddNewCategoryList(e)}
                  loadOptions={handleCategoryList}
                  placeholder="Category"
                />
              </Form.Group>
            </Col>
            <Col lg={1} xs={6} className={classes.verticalCenter}>
              <Form.Group className={classes.formGroup}>
                <Form.Label className={classes.customer_title}>
                  Model
                </Form.Label>
                <Form.Control
                  className={classes.customInput}
                  name="model"
                  value={addNewProductFormData.model}
                  onChange={handleAddNewProductChange}
                />
              </Form.Group>
            </Col>
            <Col lg={1} xs={6} className={classes.verticalCenter}>
              <Form.Group className={classes.formGroup}>
                <Form.Label className={classes.customer_title}>MRP</Form.Label>
                <Form.Control
                  className={classes.customInput}
                  type="number"
                  name="mrp"
                  min={1}
                  value={addNewProductFormData.mrp}
                  onChange={handleAddNewProductChange}
                />
              </Form.Group>
            </Col>
            <Col lg={1} xs={6} className={classes.verticalCenter}>
              <Form.Group className={classes.formGroup}>
                <Form.Label className={classes.customer_title}>
                  HSN Code
                </Form.Label>
                <AsyncSelect
                  components={{ Input: CustomInput }}
                  styles={customStyles}
                  className={classes.customSelect}
                  type="number"
                  value={{
                    value: hsnCodeValue,
                    label: hsnCodeValue,
                  }}
                  onChange={(e) => getAddNewHsnCodeList(e)}
                  loadOptions={handleHsnCodeList}
                  placeholder="HSN Code"
                />
              </Form.Group>
            </Col>
            <Col lg={1} xs={6} className={classes.verticalCenter}>
              <Form.Group className={classes.formGroup}>
                <Form.Label className={classes.customLabel}>
                  Unit
                </Form.Label>
                <div className={classes.customSelectCnt}>
                  <Form.Control
                    className={classes.customSelect}
                    as="select"
                    custom
                    name="quantity_type"
                    value={addNewProductFormData.quantity_type}
                    onChange={handleAddNewProductChange}
                  >
                    {quantityTypes.map((type) => (
                      <option key={type.value} value={type.value}>
                        {type.label}
                      </option>
                    ))}
                  </Form.Control>
                  <DownArrowIcon className={classes.selectDownArrow} />
                </div>
              </Form.Group>
            </Col>
          </Form.Row>
        </Modal.Body>
        {modalErrorMessage ? (
          <ErrorHandling message={modalErrorMessage} type={"danger"} />
        ) : null}
        {modalSuccessMessage ? (
          <ErrorHandling message={modalSuccessMessage} type={"success"} />
        ) : null}
        <Modal.Footer
          className={`${classes.modal_header} ${classes.modal_footer}`}
        >
          <Button
            className={`${classes.submit} ${classes.cancel}`}
            onClick={handleCancelAddNewProduct}
          >
            Cancel
          </Button>
          <Button
            className={`${classes.submit} ${classes.save}`}
            onClick={(e) => createAddNewProductSubmit(e, type)}
            disabled={submitLoading}
          >
            {submitLoading ? (
              <Spinner animation="border" role="status">
                <span className="sr-only">Loading...</span>
              </Spinner>
            ) : (
              "Save"
            )}
          </Button>
        </Modal.Footer>
      </div>
    </Modal>
  );
};
export default AddProduct;
