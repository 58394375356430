import React, { useEffect, useState } from "react";
import Axios from "axios";
import { Button, Col, Form, Row, Spinner, Table, Modal } from "react-bootstrap";
import Select, { components } from "react-select";
// import moment for using date
import moment from "moment";
// import xlsx for show excel sheet
import * as XLSX from "xlsx";
// import Date Range New component
import DateRangeNew from "../../DashBoardWidgets/NewWidgets.js/DateRangeNew";
// import Login getUser Details component
import { getUserDetails } from "../../Login/getLoginnedUserDetails";
// import pagination component
import Pagination from "../Common/Pagination";
// import API URL Config
import Constants from "../../../Config";
// import useHistory
import { useHistory } from "react-router-dom";
import classes from "./CreateSource.module.css";
import "react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css";

const InputOption = ({
  getStyles,
  Icon,
  isDisabled,
  isFocused,
  isSelected,
  children,
  innerProps,
  ...rest
}) => {
  const [isActive, setIsActive] = useState(false);
  const onMouseDown = () => setIsActive(true);
  const onMouseUp = () => setIsActive(false);
  const onMouseLeave = () => setIsActive(false);

  // styles
  let bg = "transparent";
  if (isFocused) bg = "#bebebe";
  if (isActive) bg = "#B2D4FF";

  const style = {
    alignItems: "center",
    backgroundColor: bg,
    color: "inherit",
    display: "flex ",
    gap: "5px",
  };

  // prop assignment
  const props = {
    ...innerProps,
    onMouseDown,
    onMouseUp,
    onMouseLeave,
    style,
  };

  return (
    <components.Option
      {...rest}
      isDisabled={isDisabled}
      isFocused={isFocused}
      isSelected={isSelected}
      getStyles={getStyles}
      innerProps={props}
    >
      <input type="checkbox" checked={isSelected} /> {children}
    </components.Option>
  );
};

const SourcingRequestList = (props) => {
  const history = useHistory();
  const apiUrl = Constants.API_URL; // Replace with your API URL
  const [sourceRequestList, setSourceRequestList] = useState([]);
  const [isLoading, setLoading] = useState(false);
  const [isPaginationLoading, setIsPaginationLoading] = useState(0);
  const [srNoDataFound, setSRNoDataFound] = useState(false);
  const [srListCount, setSrListCount] = useState(0);
  const [dateFrom, setDateFrom] = useState("");
  const [dateTo, setDateTo] = useState("");
  const [dateType, setDateType] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [totalItem, setTotalItem] = useState(25);
  const [srID, setSrID] = useState(0);
  const [srStatus, setSRStatus] = useState([]);
  const [mobile, setMobile] = useState("");
  const [searchId, setSearchId] = useState("");
  const [company_name, setCompanyName] = useState("");
  const [srStatusArray, setSRStatusArray] = useState([]);
  const [agentOptions, setagentOptions] = useState([]);
  const [sourcingOptions, setSourcingOptions] = useState([]);
  const [salesAgent, setsalesAgent] = useState([]);
  const [sourcingAgent, setSourcingAgent] = useState([]);
  const [agentArray, setagentsArray] = useState([]);
  const [isDownloading, setDownloading] = useState(false);
  const [srListDownload, setSrListDownload] = useState([]);
  const [show, setShow] = useState(false); // modal overlay state
  const [versionListById, setVersionListById] = useState([]);
  const [access, setAccess] = useState(false);
  const [allAssignableAgents, setAllAssignableAgents] = useState([]);
  const [checkBoxList, setCheckBoxList] = useState([]);
  const [assignmentAccess, setAssignmentAccess] = useState(false);
  const [selecteTUserID, setSelectedTUserID] = useState("");
  const [allSelectInit, setAllSelectInit] = useState(false);
  const [rfqStatus, setRfqStatus] = useState("");

  const handleCloseModal = () => setShow(false);

  useEffect(() => {
    setAccess(props.childSlugProp["sourcing-request"].includes("approve")); // provide access basis state
    setAssignmentAccess(
      props.childSlugProp["sourcing-request"].includes("assignment")
    );
    getAllSourceRequestData(); // calling an api for getting all source request data
    getTotalRecords(); // calling an pagination api here
    setLoading(true);
  }, [
    mobile,
    searchId,
    salesAgent,
    sourcingAgent,
    srStatus,
    dateTo,
    company_name,
    currentPage,
    totalItem,
  ]);

  useEffect(() => {
    getAllAgents(); // call an api get all agents
    getAllSourcingAgents(); // call an api to get all sourcing agents
    getAllSRStatus(); // call an api to get all status
    getAllAssignmentSourcingAgents();
  }, []);

  useEffect(() => {
    filterByAssignTo(salesAgent); // get sales agent
    filterBySourcingAssign(sourcingAgent); // get sourcing agent
    filterByStatus(srStatus); // get all status
  }, [salesAgent, sourcingAgent, srStatus]);

  const allSelect = () => {
    if (allSelectInit) {
      setAllSelectInit(false);
      setCheckBoxList([]);
    } else {
      getAllAssignmentSourcingAgents(); // Get all list on select multiple checkboxes
      const updateList = sourceRequestList.map((item) => ({
        id: item.sourcing_id,
      }));
      setAllSelectInit(true);
      setCheckBoxList(updateList);
    }
  };

  const getVendorStatusLabel = (status) => {
    switch (status) {
      case 0:
        return "Disabled";
      case 1:
        return "Enabled";
      case 2:
        return "Pending For Verification";
      case 3:
        return "Draft";
      case 4:
        return "Rejected";
      default:
        return "";
    }
  };

  const replaceAmpersand = (categoryName) => {
    return categoryName.replace(/&amp;/g, 'and') ;
  };

  // get all sourcing agents assignment api function
  const getAllAssignmentSourcingAgents = () => {
    const options = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${props.getUserDetails.token}`,
      },

      data: {
        agent_list: "",
        id: [66],
        slug: "sourcing-request",
        listType: "",
      },
    };
    return Axios(apiUrl + "/get-pre-sales-agents", options)
      .then((data) => {
        setAllAssignableAgents(data.data.data);
      })
      .catch((error) => console.log(error));
  };

  const getAllSourceRequestData = () => {
    const requestOption = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${props.getUserDetails.token}`,
      },
      data: {
        sourcingId: srID,
        current_page: currentPage,
        total_item: totalItem,
        date_from: dateFrom,
        date_to: dateTo,
        type: dateType,
        sourcing_status: srStatus,
        phone: mobile,
        id: searchId,
        account_name: company_name,
        sales_agent: salesAgent,
        sourcing_agent: sourcingAgent,
      },
    };
    Axios(`${apiUrl}/get-sourcing`, requestOption)
      .then((response) => {
        const sourceRequestList = response.data.data;
        setSourceRequestList(sourceRequestList);
        setLoading(false);
        setIsPaginationLoading(0);
      })
      .catch((error) => {
        setLoading(false);
      });
  };

  const getTotalRecords = () => {
    setLoading(true);
    const requestOption = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${props.getUserDetails.token}`,
      },
      data: {
        date_from: dateFrom,
        date_to: dateTo,
        type: dateType,
        phone: mobile,
        account_name: company_name,
        sourcing_status: srStatus,
        sales_agent: salesAgent,
        sourcing_agent: sourcingAgent,
      },
    };

    Axios(`${apiUrl}/get-sourcing-request-count`, requestOption)
      .then((response) => {
        // Handle the response data and update the state
        const srListCount = response.data.data;
        if (srListCount == 0) {
          setSRNoDataFound(true);
        } else {
          setSRNoDataFound(false);
        }
        setSrListCount(srListCount);
      })
      .catch((error) => {
        setLoading(false);
        console.log(error);
      });
  };

  // get all sales agents api start here
  const getAllAgents = () => {
    const options = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${props.getUserDetails.token}`,
      },
      data: {
        agent_list: "",
        id: [56],
        showAll: "",
        listType: "list",
        slug: "sourcing-request",
      },
    };
    return Axios(apiUrl + "/get-pre-sales-agents", options)
      .then((data) => {
        setagentsArray(data.data.data);
        let agentOptions1 = data.data.data;
        let agentOptions = agentOptions1.map((item) => {
          if (item.hasOwnProperty("name") && item.hasOwnProperty("id")) {
            item["label"] = item["name"];
            item["value"] = item["id"];
            delete item["name"];
            delete item["id"];
            return item;
          }
        });
        setagentOptions(agentOptions);
      })
      .catch((error) => console.log(error));
  };
  // get all sales agents api ends here

  // get all sourcing agents api start here
  const getAllSourcingAgents = () => {
    const options = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${props.getUserDetails.token}`,
      },
      data: {
        agent_list: "",
        id: [66],
        showAll: "",
        listType: "list",
        slug: "sourcing-request",
      },
    };
    return Axios(apiUrl + "/get-pre-sales-agents", options)
      .then((data) => {
        setagentsArray(data.data.data);
        let sourcingAgentOptions1 = data.data.data;
        let sourcingOptions = sourcingAgentOptions1.map((item) => {
          if (item.hasOwnProperty("name") && item.hasOwnProperty("id")) {
            item["label"] = item["name"];
            item["value"] = item["id"];
            delete item["name"];
            delete item["id"];
            return item;
          }
        });
        setSourcingOptions(sourcingOptions);
      })
      .catch((error) => console.log(error));
  };
  // get all sourcing agents api ends here

  // get all S.R status api start here
  const getAllSRStatus = () => {
    const options = {
      method: "POST",
      data: {
        meta_id: "33",
      },
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${props.getUserDetails.token}`,
      },
    };
    return Axios(apiUrl + "/meta-value", options)
      .then((data) => {
        const poStatusArrayNew = data.data.data;
        let poStatusArray = poStatusArrayNew.map((item) => {
          item["label"] = item["meta_title"];
          item["value"] = item["meta_value"];
          delete item["meta_title"];
          delete item["meta_value"];
          return item;
        });
        setSRStatusArray(poStatusArray);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  // get all SR status api ends here

  // download sourcing list function start here
  const exportsSourcingList = () => {
    return new Promise((resolve, reject) => {
      const requestOption = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${props.getUserDetails.token}`,
        },
        data: {
          date_from: dateFrom,
          date_to: dateTo,
          type: dateType,
          phone: mobile,
          account_name: company_name,
          sourcing_status: srStatus,
          sales_agent: salesAgent,
          sourcing_agent: sourcingAgent,
          sourcing_id: searchId,
        },
      };
      return Axios(apiUrl + "/get-source-request-download", requestOption)
        .then((data) => {
          setDownloading(false);
          const newData = data.data.data.map((item) => {
            return {
              "Sourcing Request ID": `SR-${item.sourcingId}`,
              "SR Version": item.version,
              "Reference RFQ ID": item.rfqId,
              "Account Name": item.accountName,
              "Customer Name": item.customerName,
              "Delivery Location": item.city,
              "Latest Request Status": item.status,
              KAM: item.kam,
              "Sourcing Manager": item.sourcingAgent,
              "Submission by Sales": item.creationDate
                ? moment.utc(item.creationDate).format("YYYY-MM-DD HH:mm:ss")
                : "",
              "Completion by Sourcing": item.CompletionDate
                ? moment.utc(item.CompletionDate).format("YYYY-MM-DD HH:mm:ss")
                : "",
              "UTM Content": item.utmContent,
              "UTM Campaign": item.utmCampaign,
              "UTM Source": item.utmSource,
              "UTM Medium": item.utmMedium,
              "Rfq Status": item.rfqStatus
            };
          });
          resolve(newData);
        })
        .catch((error) => {
          setDownloading(false);
        });
    });
  };
  // downlaod sourcing list function ends here

  // downlaod sourcing_product list function start here
  const exportsSourcingProductList = () => {
    return new Promise((resolve, reject) => {
      const requestOption = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${props.getUserDetails.token}`,
        },
        data: {
          date_from: dateFrom,
          date_to: dateTo,
          type: dateType,
          phone: mobile,
          account_name: company_name,
          sourcing_status: srStatus,
          sales_agent: salesAgent,
          sourcing_agent: sourcingAgent,
          sourcing_id: searchId,
        },
      };
      return Axios(
        apiUrl + "/get-source-request-product-download",
        requestOption
      )
        .then((data) => {
          setDownloading(false);
          const newData = data.data.data.map((item) => {
            return {
              "Sourcing Request ID": `SR-${item.sourcingId}`,
              "Reference RFQ ID": item.rfqId,
              "RFQ Status": item.rfq_status,
              "Account Name": item.accountName,
              "Customer Name": item.customerName,
              "Latest Request Status": item.status,
              KAM: item.kam,
              "Sourcing Manager": item.sourcingAgent,
              Version: item.version,
              "Submission By Sales": item.submissionBySales
              ? moment.utc(item.submissionBySales).format("YYYY-MM-DD HH:mm:ss")
              : "",
              "Submission By Sourcing": item.submissionBySourcing
              ? moment.utc(item.submissionBySourcing).format("YYYY-MM-DD HH:mm:ss")
              : "",
              "Submit By": item.submitBy,
              "Delivery Location": item.city,
              "Product ID": item.productId,
              Product: item.product,
              "L1 Category Name": replaceAmpersand(item.parentCategory),
              Category: item.category,
              Brand: item.brand,
              Qty: item.quantity,
              Unit: item.quantity_type,
              "Transfer Price": item.transferPrice,
              "Vendor Name": item.vendor_name,
              "Vendor Status": item.vendor_status
                ? getVendorStatusLabel(item.vendor_status)
                : "",
              Availability: item.availability,
              "Lead Time": item.lead_time,
            };
          });
          resolve(newData);
        })
        .catch((error) => {
          setDownloading(false);
        });
    });
  };
  // download sourcing_product list funciton ends here

  // export function start here
  const handleExport = (optionValue, srListDownlaod) => {
    var wb = XLSX.utils.book_new(),
      ws = XLSX.utils.json_to_sheet(srListDownlaod);
    XLSX.utils.book_append_sheet(wb, ws, "SR-List");
    XLSX.writeFile(
      wb,
      optionValue === "sourcing_list" ? "SR-List.xls" : "SR-Product-list.xls"
    );
  };
  // export function ends here

  // download report function start here
  const downloadReport = async (optionValue) => {
    setDownloading(true);
    let result = null;
    if (optionValue === "sourcing_list") {
      result = await exportsSourcingList();
    } else {
      result = await exportsSourcingProductList();
    }
    if (result.length > 0) {
      setSrListDownload(result);
      setTimeout(() => {
        handleExport(optionValue, result);
      }, 1000);
    }
  };
  // download report function ends here

  const convertQuoteToOther = (e) => {
    const selected_ids = checkBoxList.map((item) => item.id);
    setSelectedTUserID(e.target.value);
    setCurrentPage(1);

    const options = {
      method: "POST",
      data: {
        selectedSourcingID: selected_ids,
        selectedUserID: e.target.value,
      },
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${props.getUserDetails.token}`,
      },
    };

    Axios(apiUrl + "/assign-sourcing-request", options)
      .then((data) => {
        if (data.data.data.affectedRows > 0) {
          setIsPaginationLoading();
          getAllSourceRequestData();
          setCheckBoxList([]);
          setSelectedTUserID("");
        }
      })
      .catch((error) => console.log(error));
  };

  const onSelect = (isSelect, row) => {
    if (checkBoxList.length > 0) {
      let flag = false;
      const updateList = [];
      checkBoxList.forEach((item) => {
        if (item.id === row) {
          flag = true;
        } else {
          updateList.push(item);
        }
      });
      if (!flag) {
        updateList.push({ id: row });
      }
      setCheckBoxList(updateList);
    } else {
      getAllAssignmentSourcingAgents();
      setCheckBoxList([{ id: row }]);
    }
  };

  // handle download function  start here
  const handleDownloadSheet = (e) => {
    downloadReport(e.target.value);
  };
  // handle download funciton ends here

  const handleDatetype = (e) => {
    setDateType(e.target.value);
  };

  const setDateRange = (data) => {
    setDateFrom(data.start);
    setDateTo(data.end);
    setSrID(0);
    setCurrentPage(1);
    setIsPaginationLoading(1);
  };

  const debounce = (cb, delay = 1000) => {
    let timeout;
    return (...args) => {
      clearTimeout(timeout);

      timeout = setTimeout(() => {
        cb(...args);
      }, delay);
    };
  };

  const companyFilter = (e) => {
    setCompanyName(e);
    setSrID(0);
    setCurrentPage(1);
  };

  const mobileFilter = (e) => {
    setMobile(e);
    setSrID(0);
    setCurrentPage(1);
    setIsPaginationLoading(1);
  };

  const idFilter = (e) => {
    setSearchId(e);
    setSrID(0);
    setCurrentPage(1);
    setIsPaginationLoading(1);
  };

  // mobile filter start here
  const updateMobile = debounce(mobileFilter);
  const getMobile = (e) => {
    updateMobile(e.target.value);
  };
  // mobile filter ends here

  // search by account name start here
  const updateCompany = debounce(companyFilter);
  const getCompany = (e) => {
    updateCompany(e.target.value);
  };
  // search by account name ends here

  // serach by id function start here
  const updateById = debounce(idFilter);
  const getSearchById = (e) => {
    updateById(e.target.value);
  };
  // search by id function ends here

  // filter by status start here
  const filterByStatus = (e) => {
    setSrID(0);
    setSRStatus(e);
    setCurrentPage(1);
    setIsPaginationLoading(1);
  };
  // filter by status ends here

  // filter function for sales agent start here
  const filterByAssignTo = (e) => {
    setSrID(0);
    setsalesAgent(e);
    setCurrentPage(1);
    setIsPaginationLoading(1);
  };
  // filter function for sales agent ends here

  // filter function for sourcing agent start here
  const filterBySourcingAssign = (e) => {
    setSrID(0);
    setSourcingAgent(e);
    setCurrentPage(1);
    setIsPaginationLoading(1);
  };
  // filter function for sourcing agent ends here

  const getPaginationValue = (currentPage, displayItems) => {
    setCurrentPage(currentPage);
    setTotalItem(displayItems);
    setSrID(0);
  };

  const displayStatus = (status) => {
    if (status == "new") {
      return "#DC4C64";
    } else if (status == "pending") {
      return "#9FA6B2";
    } else if (status == "completed") {
      return "#28a745";
    } else if (status == "reopen") {
      return "#ffc107";
    } else if (status == "cancelled") {
      return "#9FA6B2";
    } else {
      return "";
    }
  };

  const displayRfqStatus = (status) => {
    if (status == "New") {
      return "#A24857";
    } else if (status == "Pending") {
      return "#ffc107";
    } else if (status == "Shared") {
      return "#ffc107";
    } else if (status == "Converted to MQ") {
      return "#ffc107";
    } else if (status == "Converted to Quote") {
      return "#ffc107";
    } else if (status == "Closed - Won") {
      return "#28a745";
    } else if (status == "Direct Order") {
      return "#28a745";
    } else if (status == "Closed - Lost") {
      return "#9FA6B2";
    } else if (status == "Duplicate") {
      return "#9FA6B2";
    } else if (status == "Quote Follow-up") {
      return "#ffc107";
    } else {
      return "";
    }
  };

  const setSRState = (SRID, rfqStatus) => {
    const state = {
      srID: SRID,
      rfq_status:rfqStatus,
      timestamp: new Date().getTime(),
    };
    setRfqStatus(rfqStatus) 
    history.push("/sourcingPersonRequest"); // re-direct to new page
    localStorage.setItem("srState", JSON.stringify(state));
  };

  // show versions data function start here
  const setVersionsState = (item) => {
    setVersionListById([item]); // call api get detail by versions id
    setShow(true); // mdoal true
  };
  // show versions data funciton ends here
  return (
    <>
      <div>
        <div className="page-header m-10">
          <div className="row">
            <div className="col-12 col-lg-2 px-0">
              <div className="container-fluid">
                <div className="page-header-title">
                  <h4 className="source_title_hdr">Sourcing Request Data.</h4>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <h3 className="proAccountHeader ">
        {assignmentAccess && checkBoxList.length > 0 ? (
          <Col xs={2} style={{ padding: "0px" }}>
            <Form.Control
              as="select"
              custom
              value={selecteTUserID}
              onChange={convertQuoteToOther}
            >
              <option value="">Assign to ...</option>
              {allAssignableAgents.map((item, index) => {
                return item.id ? (
                  <option key={index} value={item.id}>
                    {item.name}
                  </option>
                ) : null;
              })}
            </Form.Control>
          </Col>
        ) : (
          ""
        )}
      </h3>
      <div className="card table-card">
        <div className="quoteListPage">
          <div className="container-fluid">
            <Form className="quoteRequestPageFilter headingPadding">
              <Row>
                <Col md="3" className="mt-3">
                  <Form.Control
                    className={classes.listingFontSizeColor}
                    as="select"
                    custom
                    onChange={handleDatetype}
                    name={dateType}
                  >
                    <option value="">Date Type</option>
                    <option value="created">Create Date</option>
                    <option value="updated">Update Date</option>
                  </Form.Control>
                </Col>
                <Col md="3" className="mt-3">
                  <div className={classes.customFilterCnt}>
                    <div className={classes.customFilter}>
                      <DateRangeNew
                        label={"Date Filter"}
                        // allData={true}
                        setDateRange={setDateRange}
                      />
                    </div>
                  </div>
                </Col>
                <Col md="3" className="mt-3">
                  <Form.Control
                    className={classes.listingFontSizeColor}
                    placeholder="Mobile"
                    onChange={(data) => getMobile(data)}
                  />
                </Col>
                <Col md="3" className="mt-3">
                  <Form.Control
                    className={classes.listingFontSizeColor}
                    placeholder="Account Name"
                    onChange={(data) => getCompany(data)}
                  />
                </Col>
                {srStatusArray ? (
                  <Col md="3" className="mt-3">
                    <Select
                      defaultValue={[]}
                      isMulti
                      name="poStatus"
                      closeMenuOnSelect={false}
                      hideSelectedOptions={false}
                      controlShouldRenderValue={false}
                      options={srStatusArray}
                      className="basic-multi-select listingFontSizeColor"
                      classNamePrefix="select"
                      placeholder="Request status"
                      onChange={(options) => {
                        if (Array.isArray(options)) {
                          setSRStatus(options?.map((opt) => opt.value));
                        } else {
                          filterByStatus([]);
                        }
                      }}
                      components={{
                        Option: InputOption,
                      }}
                    />
                  </Col>
                ) : null}

                {!access && agentOptions ? (
                  <Col md="3" className="mt-3">
                    <Select
                      defaultValue={[]}
                      isMulti
                      name="orderType"
                      closeMenuOnSelect={false}
                      hideSelectedOptions={false}
                      controlShouldRenderValue={false}
                      options={agentOptions}
                      className="basic-multi-select"
                      classNamePrefix="select"
                      placeholder="Sales Agents"
                      onChange={(options) => {
                        if (Array.isArray(options)) {
                          setsalesAgent(options?.map((opt) => opt.value));
                        } else {
                          filterByAssignTo([]);
                        }
                      }}
                      components={{
                        Option: InputOption,
                      }}
                    />
                  </Col>
                ) : null}

                {sourcingOptions ? (
                  <Col md="3" className="mt-3">
                    <Select
                      defaultValue={[]}
                      isMulti
                      name="orderType"
                      closeMenuOnSelect={false}
                      hideSelectedOptions={false}
                      controlShouldRenderValue={false}
                      options={sourcingOptions}
                      className="basic-multi-select listingFontSizeColor"
                      classNamePrefix="select"
                      placeholder="Sourcing Agents"
                      onChange={(options) => {
                        if (Array.isArray(options)) {
                          setSourcingAgent(options?.map((opt) => opt.value));
                        } else {
                          filterBySourcingAssign([]);
                        }
                      }}
                      components={{
                        Option: InputOption,
                      }}
                    />
                  </Col>
                ) : null}
                <Col md="3" className="mt-3">
                  <Form.Control
                    className={classes.listingFontSizeColor}
                    placeholder="SR ID"
                    onChange={(data) => getSearchById(data)}
                  />
                </Col>
                <Col md="3" className="mt-3">
                  {isDownloading ? (
                    <span
                      variant="primary"
                      className="btn btn-primary downLoadButton"
                    >
                      <Spinner animation="border" />
                    </span>
                  ) : (
                    <Form.Control
                      as="select"
                      custom
                      className={classes.listingcustomSelect}
                      onChangeCapture={handleDownloadSheet}
                      disabled={isDownloading}
                      value={0}
                    >
                      <option value="0">Download</option>
                      <option value="sourcing_list">Sourcing List</option>
                      <option value="sourcing_product">Sourcing Product</option>
                    </Form.Control>
                  )}
                </Col>
              </Row>
            </Form>
          </div>
          {/* Table section start here */}
          <div className="card-block">
            {isLoading ? (
              <div style={{ textAlign: "center", marginTop: 20 }}>
                <Spinner animation="border" />
              </div>
            ) : (
              <div className="mobile-table-scroll">
                <Table striped bordered hover size="md">
                  <thead>
                    <tr>
                      {assignmentAccess ? (
                        <th
                          className="selection-cell-header"
                          data-row-selection="true"
                        >
                          <input
                            type="checkbox"
                            className="selection-input-4"
                            checked={allSelectInit}
                            onChange={allSelect}
                          />
                        </th>
                      ) : null}
                      <th tabindex="0">Sourcing Request/RFQ ID</th>
                      <th tabindex="0">SR Creation Date & Time</th>
                      <th tabindex="0">Account Name User</th>
                      <th tabindex="0">Last SR Status</th>
                      <th tabIndex="0">Rfq Status</th>
                      <th tabindex="0">KAM</th>
                      <th tabindex="0">Sourcing Manager</th>
                      <th tabindex="0">Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {sourceRequestList.map((item) => {
                      let checked = false;
                      if (checkBoxList.length > 0) {
                        checkBoxList.map((element, index) => {
                          if (element.id === item.sourcing_id) {
                            checked = true;
                          }
                        });
                      }
                      return (
                        <tr key={item.sourcing_id}>
                          {assignmentAccess ? (
                            <td>
                              <input
                                type="checkbox"
                                className="selection-input-4"
                                onChange={(e) => onSelect(e, item.sourcing_id)}
                                checked={checked}
                              />
                            </td>
                          ) : null}
                          <td style={{ minWidth: "100px" }}>
                            <p>
                              <b>SR</b>: {item.sourcing_id}
                              <br />
                              <b>RFQ</b>: {item.rfq_id}
                              <br />
                            </p>
                          </td>
                          <td style={{ minWidth: "100px" }}>
                            <p>
                              {moment.utc(item.created_at).format("DD-MM-YYYY")}
                              <br />
                              {moment.utc(item.created_at).format("hh:mm A")}
                            </p>
                          </td>
                          <td style={{ minWidth: "100px" }}>
                            <p>
                              <b>Account</b>: {item.account_name}
                              <br />
                              <b>User</b>: {item.customer_name}
                              <br />
                            </p>
                          </td>
                          <td>
                            <div>
                              <p
                                style={{
                                  backgroundColor: `${displayStatus(
                                    item.sourcing_status
                                  )}`,
                                  padding: "3px",
                                  textAlign: "center",
                                  borderRadius: "0.5rem",
                                  width: "100px",
                                }}
                              >
                                <b>{item.sourcing_status}</b>
                              </p>
                              {item.version ? (
                                <Button
                                  className={classes.view_version_btn}
                                  onClick={() => {
                                    setVersionsState(item);
                                  }}
                                >
                                  {item.version} Versions
                                </Button>
                              ) : null}
                            </div>
                          </td>
                          <td>
                            <p
                              style={{
                                color:`${displayRfqStatus(
                                  item.rfq_status
                                )}`,
                                padding: "2px",
                                textAlign: "center",
                                borderRadius: "0.2rem",
                                width: "max-content",
                                padding: ".25rem .5rem",
                                margin: "0",
                              }}
                            >
                              {item.rfq_status ? (
                                <strong>{item.rfq_status} </strong>
                              ) : null}
                              <br />
                            </p>
                            <p>
                              {item.quoteOrder ? (
                                <strong>Order Id: {item.quoteOrder}</strong>
                              ) : null}
                            </p>
                          </td>
                          <td>
                            <p>{item.keyAccountManager}</p>
                          </td>
                          <td>
                            <p>{item.sourcingManager}</p>
                          </td>
                          <td>
                            <Button
                              className="w-100"
                              onClick={() => setSRState(item.sourcing_id, item.rfq_status)}
                            >
                              View
                            </Button>
                          </td>
                        </tr>
                      );
                    })}
                    {srNoDataFound ? (
                      <span>
                        <h3>No Data Found</h3>
                      </span>
                    ) : null}
                  </tbody>
                </Table>
              </div>
            )}
          </div>
          {/* View Versions modal overlay pop up start here */}
          <Modal
            show={show}
            onHide={handleCloseModal}
            dialogClassName="modal_view_version modal-dialog-centered mb-0"
          >
            <Modal.Header closeButton>
              <Modal.Title className="source_title_hdr mb-0">
                View Versions
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div className="view_version_table">
                <Table striped bordered hover>
                  <thead>
                    <tr>
                      <th>SR Version</th>
                      <th>Submitted On</th>
                      <th> Completed On</th>
                    </tr>
                  </thead>
                  <tbody>
                    {versionListById &&
                      versionListById.map((item, index) => (
                        <React.Fragment key={index}>
                          {item.versionHistory.map((data) => (
                            <tr>
                              <td>{data.history_count}</td>
                              <td>
                                {moment
                                  .utc(data.request.submitted_on)
                                  .format("DD-MM-YYYY hh:mm A")}
                              </td>
                              <td>
                                {moment
                                  .utc(data.response.completed_on)
                                  .format("DD-MM-YYYY hh:mm A")}
                              </td>
                            </tr>
                          ))}
                        </React.Fragment>
                      ))}
                  </tbody>
                </Table>
              </div>
            </Modal.Body>
          </Modal>
          {/* View Versions modal overlay pop up ends here  */}
          {/* Pagination component start here */}
          <Pagination
            current_page={currentPage}
            total_item={srListCount}
            getPaginationValue={getPaginationValue}
            isPaginationLoading={isPaginationLoading}
          />
          {/* Pagination component ends here */}
          {/* Table section ends here */}
        </div>
      </div>
    </>
  );
};

export default SourcingRequestList;
