import React, { useState, useEffect, useCallback } from "react";
import Axios from "axios";
import Constants from "../../../Config";
import { useHistory } from "react-router-dom";
import AccordionPage from "./AccordionPage";
import UserData from "./UserData";
import SalesProductListing from "./SalesProductListing";
import AddProduct from "./AddProduct";
import Footer from "./Footer";
import ErrorHandling from "./ErrorHandling";
import SourcingProductListing from "./SourcingProductListing";
import { Redirect } from "react-router-dom";
import style from "./CreateSource.module.css";
import AddVendor from "./AddVendor";
import SourcingFooter from "./SourcingFooter";

const apiUrl = Constants.API_URL;
const getUserDetails = JSON.parse(localStorage.getItem("userData"));

const CreateSourcingPersonRequest = (props) => {
  const history = useHistory();
  const [formValidate, setFormValidate] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const [sourcingMessage, setSourcingMessage] = useState("");
  const [modalErrorMessage, setModalErrorMessage] = useState("");
  const [modalSuccessMessage, setModalSuccessMessage] = useState("");
  const [productName, setProductName] = useState("");

  // set form detials hook
  const [customer_name, setCustomerName] = useState("");
  const [rfqId, setRfqId] = useState("");
  const [account_name, setAccountName] = useState("");
  const [deliver_loaction, setDeliveryLocation] = useState("");
  const [customer_pincode, setCustomerPincode] = useState("");
  const [reopenData, setReopenData] = useState([]);
  const [product_data, setProductData] = useState([]); // get data
  const [sourcing_data, setSourcingData] = useState([]); // set data
  const [show_sourcing_data, setShowSourcingData] = useState([]); // show data
  const [product_list, setProductList] = useState([]);
  const [itemTypeArray, setItemTypeArray] = useState([]);
  const [id, setId] = useState(0);
  const [finalAdd, setFinalAdd] = useState(false);
  const [productId, setProductId] = useState("");
  const [customerId, setCustomerId] = useState("");
  const [customerMobile, setCustomerMobile] = useState("");

  const [formDataValue, setFormDataValue] = useState({
    sourcing_remarks: "",
    kam_remarks: "",
  });
  const [show, setShow] = useState(false);
  const [showReOpen, setShowReOpen] = useState(false);
  const [showVendor, setShowVendor] = useState(false);
  const [showModalSourcing, setShowModalSourcing] = useState(false);
  // add new product state
  const [addNewProductFormData, setAddNewProductFormData] = useState({
    productName: "",
    mrp: "",
    model: "",
    quantity_type: "",
  });
  // add new vendor state
  const [addNewVendorData, setAddNewVendorData] = useState({
    mobile: "",
    business_name: "",
    contact_name: "",
  });

  const [categoryValue, setCategoryValue] = useState("");
  const [brandValue, setBrandValue] = useState("");
  const [hsnCodeValue, setHsnCodeValue] = useState();
  const [submitLoading, setSubmitLoading] = useState(false);
  const [addNewProductCategoryList, setAddNewProductCategoryList] = useState(
    []
  );

  const [addNewProductBrandList, setAddNewProductBrandList] = useState([]);
  const [addNewProductHsnCodeList, setAddNewProductHsnCodeList] = useState([]);
  const [vendorlocationList, setVendorLocationList] = useState([]);
  const [vendorNameLocation, setVendorNameLocation] = useState("");
  const [is_redirect, setRedirect] = useState(false);
  const [accordionData, setAccordionData] = useState(null);
  const [access, setAccess] = useState(false);
  const [accessSales, setSalesAccess] = useState(false);
  const [status, setStatus] = useState("");
  const [showButtonStatus, setShowButtonStatus] = useState("");
  const [categoryId, setCategoryId] = useState("");
  const [manufactureId, setManufactureId] = useState("");
  const [location, setLocation] = useState("");
  const [pincode, setPincode] = useState("");
  const [locationList, setLocationList] = useState([]);
  const [locationId, setLocationId] = useState(null);
  const [googleData, setGoogleData] = useState([]);
  const [index, setIndex] = useState();
  const handleCloseModal = () => setShow(false); // close modal overlay pop up
  const handleShowModal = () => setShow(true); // open modal overlay pop up
  const handleCloseVendor = () => setShowVendor(false);
  const handleShowSourcingModal = () => setShowModalSourcing(false);
  const handleHideSourcingModal = () => setShowModalSourcing(true);
  const [dataFromChild, setDataFromChild] = useState("");
  const [selectedFile, setSelectedFile] = useState([]);
  const [sourcingId, setSourcingId] = useState("");
  const [accountManager, setAccountManager] = useState("");
  const [userLocation, setUserLocation] = useState("");
  const [dataSubmittedOn, setDataSubmittedOn] = useState("");
  const maxLength = 1000;

  const handleShowVendor = (e, index) => {
    setShowVendor(true);
    setIndex(index);
  };

  const handleShowFooter = () => {
    setShowReOpen(true);
    setFormDataValue((prevFormData) => ({
      ...prevFormData,
      kam_remarks: "",
    }));
  };
  const handleCloseFooter = () => setShowReOpen(false);

  const handleAttachmentClick = (url) => {
    window.open(url, "_blank"); // Open link in a new tab
  };

  const SourceRequestId = JSON.parse(localStorage.getItem("srState")); // link get from Sourcing Request list
  const rfqStatus = JSON.parse(localStorage.getItem("SR_Details"));
  const updateType = () => {
    return !showReOpen
      ? access && ["pending", "new", "reopen"].includes(status)
        ? show_sourcing_data && show_sourcing_data.length
          ? "show_sourcing"
          : "sourcing"
        : show_sourcing_data && show_sourcing_data.length
        ? "show_sourcing"
        : reopenData && reopenData.length
        ? "reopenData"
        : ""
      : "";
  };

  useEffect(() => {
    if (SourceRequestId) {
      getSourceRequestById(SourceRequestId?.srID);
    }
    setAccess(props.childSlugProp["sourcing-request"].includes("approve")); // provide access basis state
    if (props.childSlugProp["quote-requests"]) {
      setSalesAccess(props.childSlugProp["quote-requests"].includes("edit"));
    }
  }, []);

  const handleCreateQuote = () => {
    localStorage.setItem("QuoteFromSourcing", true);
    setRedirect(true);
  };

  // call an get api for Source Request By Id start here
  const getSourceRequestById = (srID) => {
    const requestOption = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${props.getUserDetails.token}`,
      },
    };
    Axios(`${apiUrl}/get-sourcing-request/${srID}`, requestOption)
      .then((response) => {
        const sourceRequestList = response.data.data[0];
        setAccordionData(sourceRequestList);
        setRfqId(sourceRequestList.rfq_id);
        setSourcingId(sourceRequestList.sourcing_id);
        setAccountManager(sourceRequestList.keyAccountManager);
        setUserLocation(sourceRequestList.location);
        setDataSubmittedOn(sourceRequestList.submitted_on);
        setStatus(sourceRequestList.sourcing_status); // get current status
        setShowButtonStatus(sourceRequestList.status);
        setErrorMessage("");
        setCustomerName(sourceRequestList.customer_name);
        setAccountName(sourceRequestList.account_name);
        setCustomerMobile(sourceRequestList.customer_mobile);
        // setDeliveryLocation(location ? location : sourceRequestList.location);
        setDeliveryLocation(
          dataFromChild ? dataFromChild : sourceRequestList.location
        );
        setCustomerPincode(pincode ? pincode : sourceRequestList.pincode);
        setProductData(
          sourceRequestList.version[sourceRequestList.version.length - 1]
            .products[0]
        );
        setReopenData(
          sourceRequestList.version[sourceRequestList.version.length - 1]
            .products[1]
        );
        setShowSourcingData(
          sourceRequestList.version[sourceRequestList.version.length - 1]
            .products[1]
        ); // request -> sourceRequestList.version[0].products[0]
        setSourcingData(
          sourceRequestList.version[
            sourceRequestList.version.length - 1
          ].products[0]
            .map(
              ({
                type,
                name,
                brand,
                quantity,
                sales_transfer_price,
                product_id,
                category_id,
                vendor_name,
                vendor_id,
                vendor_status,
                brand_id,
                quantity_type,
                availabilityType,
                lead_time,
              }) => {
                return {
                  type,
                  name,
                  brand,
                  brand_id,
                  quantity,
                  sales_transfer_price,
                  product_id,
                  category_id,
                  vendor_name,
                  vendor_id,
                  vendor_status,
                  quantity_type,
                  availabilityType,
                  lead_time,
                };
              }
            )
            .filter((item) => item.type === "product")
        );
        setFormDataValue({
          sourcing_remarks:
            sourceRequestList.sourcing_status != "reopen"
              ? sourceRequestList.sourcing_remarks
              : "",
          kam_remarks: sourceRequestList.sales_remarks,
        });
        setCustomerId(sourceRequestList.customer_id);
      })
      .catch((error) => {
        console.log(error);
        setErrorMessage(error.data.message);
        setTimeout(() => {
          setErrorMessage("");
        }, 3000);
      });
  };
  // call an get api for Source Request By Id ends here

  // submit add new product api function start here
  const createAddNewProductSubmit = async (e, type) => {
    if (submitLoading) return;
    setSubmitLoading(true);
    e.preventDefault();
    try {
      let getUserDetails = JSON.parse(localStorage.getItem("userData"));
      const token = props.getUserDetails.token;
  
      const params = {
        method: "POST",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        data: {
          name: addNewProductFormData.productName,
          manufacture_id: manufactureId,
          price: addNewProductFormData.mrp,
          category_id: categoryId,
          hsn_code: hsnCodeValue,
          model: addNewProductFormData.model,
          quantity_type: addNewProductFormData.quantity_type,
        },
      };
  
      const response = await Axios(apiUrl + "/add-product", params);
  
      setErrorMessage("");
      setSubmitLoading(false);
  
      // Clear form state after submission
      setAddNewProductFormData({
        productName: "",
        mrp: "",
        model: "",
        quantity_type: "",
      });
      setBrandValue("");
      setCategoryValue("");
      setHsnCodeValue("");
      handleCloseModal();
  
      setTimeout(() => {
        setModalSuccessMessage("");
      }, 2000);
  
      const addedProduct = response.data.data;
      const foundProduct = addedProduct.product_id;
      const customer_id = customerId;
      const displayResponse = await displayAllProducts(foundProduct, customer_id);
  
      if (displayResponse) {
        let product = {
          type: "product",
          name: displayResponse.name,
          brand: displayResponse.brand,
          product_id: displayResponse.product_id,
          brand_id: displayResponse.brand_id,
          quantity_type: displayResponse.quantity_type,
          category_id: 0,
        };
  
        // Always append the new product row
        const appendNewProduct = (prevData) => {
          return [...prevData, product]; // Just append the new product
        };
  
        // Update the respective state based on the type
        if (type === "sourcing") {
          setSourcingData((prevData) => appendNewProduct(prevData));
        } else if (type === "show_sourcing") {
          setShowSourcingData((prevData) => appendNewProduct(prevData));
        } else {
          setProductData((prevData) => appendNewProduct(prevData));
        }
      } else {
        throw new Error("Failed to display all products");
      }
    } catch (err) {
      console.log(err);
      setSubmitLoading(false);
      setFormValidate(true);
      setModalErrorMessage(err.response?.data?.message || err.message);
      setTimeout(() => {
        setModalErrorMessage("");
      }, 3000);
    }
  };
  // submit add new product api function ends here

  // submit add new product api function start here
  const createAddNewVendorSubmit = async (e, type, index) => {
    if (submitLoading) return;
    setSubmitLoading(true);
    let vendorIndex = index;
    e.preventDefault();
    try {
      let getUserDetails = JSON.parse(localStorage.getItem("userData"));
      const token = props.getUserDetails.token;
      const params = {
        method: "POST",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        data: {
          mobile: Number(addNewVendorData.mobile),
          business_name: addNewVendorData.business_name,
          contact_name: addNewVendorData.contact_name,
        },
      };
      const response = await Axios(apiUrl + "/add-vendors", params);

      setErrorMessage("");
      setSubmitLoading(false);
      setAddNewVendorData({
        mobile: "",
        business_name: "",
        contact_name: "",
      });
      handleCloseVendor();
      setTimeout(() => {
        setModalSuccessMessage("");
      }, 2000);

      const addedProduct = response.data.data;
      if (addedProduct) {
        let product = {
          vendor_name: addNewVendorData.business_name,
          vendor_id: addedProduct[0].vendor_id,
        };
        if (type === "sourcing") {
          let products = sourcing_data.map((data, index) => {
            if (index === vendorIndex) {
              data = {
                ...data,
                vendor_name: addedProduct[0].vendor_name,
                vendor_id: addedProduct[0].vendor_id,
                vendor_status: addedProduct[0].Status,
              };
            }
            return data;
          });
          setSourcingData(products);
        } else if (type === "show_sourcing") {
          let products = show_sourcing_data.map((data, index) => {
            if (index === vendorIndex) {
              data = {
                ...data,
                vendor_name: addedProduct[0].vendor_name,
                vendor_id: addedProduct[0].vendor_id,
                vendor_status: addedProduct[0].Status,
              };
            }
            return data;
          });
          setShowSourcingData(products);
        } else {
          setProductData((prevData) => {
            const newData = prevData.slice(0, -1);
            return [...newData, product];
          });
        }
      } else {
        throw new Error("Failed to display all products");
      }
    } catch (err) {
      console.log(err);
      setSubmitLoading(false);
      setFormValidate(true);
      setModalErrorMessage(err.response?.data?.message || err.message);
      setTimeout(() => {
        setModalErrorMessage("");
      }, 3000);
    }
  };
  // submit add new product api function ends here

  // update sourcing update api fucntion start here
  const UpdateSourcingRequest = async (e) => {
    if (submitLoading) return;
    e.preventDefault(); // Prevent default form submission behavior
    setSubmitLoading(true);
    const values = updateType();
    //create POST api call will be executed below on clicktype
    let products;
    let remarks;
    if (values === "show_sourcing") {
      products = show_sourcing_data.map((data) => {
        if (data.type === "attachments") {
          return {
            name: data.name,
            type: data.type,
            brand: data.brand,
            brand_id: data.brand_id,
            product_id: data.product_id,
            category_id: Number(data.category_id),
            quantity_type: "",
          };
        }
        return {
          name: data.name,
          type: data.type,
          brand: data.brand,
          brand_id: data.brand_id,
          lead_time: data.lead_time,
          quantity: Number(data.quantity),
          vendor_id: data.vendor_id ? data.vendor_id : 0,
          product_id: data.product_id,
          category_id: Number(data.category_id),
          vendor_name: data.vendor_name,
          vendor_status: Number(data.vendor_status)
            ? Number(data.vendor_status)
            : 0,
          availabilityType: data.availabilityType,
          sourcing_product_id: data.sourcing_product_id,
          sales_transfer_price: Number(data.sales_transfer_price)
            ? Number(data.sales_transfer_price)
            : 0,
          quantity_type: data.quantity_type,
        };
      });
      remarks = { sourcing_remarks: formDataValue.sourcing_remarks };
    } else if (values === "sourcing") {
      products = sourcing_data.map((data) => {
        if (data.type === "attachments") {
          return {
            name: data.name,
            type: data.type,
            brand: data.brand,
            brand_id: data.brand_id,
            product_id: data.product_id,
            category_id: Number(data.category_id),
            quantity_type: "",
          };
        }
        return {
          name: data.name,
          type: data.type,
          brand: data.brand,
          brand_id: data.brand_id,
          lead_time: data.lead_time,
          quantity: Number(data.quantity),
          vendor_id: data.vendor_id ? data.vendor_id : 0,
          product_id: data.product_id,
          category_id: Number(data.category_id),
          vendor_name: data.vendor_name,
          vendor_status: Number(data.vendor_status)
            ? Number(data.vendor_status)
            : 0,
          availabilityType: data.availabilityType,
          sourcing_product_id: data.sourcing_product_id,
          sales_transfer_price: Number(data.sales_transfer_price)
            ? Number(data.sales_transfer_price)
            : 0,
          quantity_type: data.quantity_type,
        };
      });
      remarks = { sourcing_remarks: formDataValue.sourcing_remarks };
    } else if (values === "product") {
      products = product_data.map((data) => {
        if (data.type === "attachments") {
          return {
            name: data.name,
            type: data.type,
            brand: data.brand,
            brand_id: data.brand_id,
            product_id: data.product_id,
            category_id: Number(data.category_id),
            quantity_type: "",
          };
        }
        return {
          name: data.name,
          type: data.type,
          brand: data.brand,
          brand_id: data.brand_id,
          quantity: Number(data.quantity),
          product_id: data.product_id,
          category_id: Number(data.category_id),
          quantity_type: data.quantity_type,
        };
      });
      remarks = { sales_remarks: formDataValue.kam_remarks };
    } else {
      products = reopenData.map((data) => {
        if (data.type === "attachments") {
          return {
            name: data.name,
            type: data.type,
            brand: data.brand,
            brand_id: data.brand_id,
            product_id: data.product_id,
            category_id: Number(data.category_id),
            quantity_type: "",
          };
        }
        return {
          name: data.name,
          type: data.type,
          brand: data.brand,
          brand_id: data.brand_id,
          lead_time: data.lead_time,
          quantity: Number(data.quantity),
          vendor_id: data.vendor_id ? data.vendor_id : 0,
          product_id: data.product_id,
          category_id: Number(data.category_id),
          vendor_name: data.vendor_name,
          vendor_status: Number(data.vendor_status)
            ? Number(data.vendor_status)
            : 0,
          availabilityType: data.availabilityType,
          sourcing_product_id: data.sourcing_product_id,
          sales_transfer_price: Number(data.sales_transfer_price)
            ? Number(data.sales_transfer_price)
            : 0,
          quantity_type: data.quantity_type,
        };
      });
      remarks = { sales_remarks: formDataValue.kam_remarks };
    }

    e.preventDefault();
    let submitType = e.target.id;
    let getUserDetails = JSON.parse(localStorage.getItem("userData"));
    let body = {
      ...remarks,
      sourcingId: SourceRequestId.srID,
      sourcing_type: submitType,
      pincode: pincode ? pincode : customer_pincode,
      // location: location.value ? location.value : deliver_loaction,
      location: dataFromChild ? dataFromChild : deliver_loaction,
      products: products,
    };
    let sourcingbody = {
      sourcingId: SourceRequestId.srID,
      sourcing_type: submitType,
      products: products,
    };
    const token = props.getUserDetails.token;
    const params = {
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      data: submitType === "reopen-sourcing" ? sourcingbody : body,
    };
    return await Axios(apiUrl + "/update-source-request", params)
      .then((data) => {
        setSuccessMessage(data.data.data);
        submitType === "reopen-sourcing"
          ? setSourcingMessage("")
          : setErrorMessage("");
        // setSubmitLoading(false);
      })
      .then(() => {
        if (access) {
          setTimeout(() => {
            submitType === "reopen-sourcing"
              ? window.location.reload()
              : history.push("/sourcing-request");
          }, 500);
        } else {
          setTimeout(() => {
            history.push("/quote-requests");
          }, 2000);
        }
      })
      .catch((err) => {
        console.log(err);
        setSubmitLoading(false);
        submitType === "reopen-sourcing"
          ? setSourcingMessage(err.response.data.message)
          : setErrorMessage(err.response.data.message);
        setTimeout(() => {
          submitType === "reopen-sourcing"
            ? setSourcingMessage("")
            : setErrorMessage("");
        }, 3000);
      });
  };
  // update sourcing update api function ends here

  // call an api to get handle category list for add new product
  const handleCategoryList = async (e, callback) => {
    const token = props.getUserDetails.token;
    const params = {
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      data: {
        keyword: e,
      },
    };
    return await Axios(apiUrl + "/get-category-by-keyword", params)
      .then((data) => {
        setAddNewProductCategoryList(data.data.data);
        callback(
          data.data.data.map((i) => ({
            label: `${i.name}`,
            value: `${i.name}`,
          }))
        );
      })
      .catch((err) => console.log(err.response));
  };
  // ends here

  // getCategory List function start here
  const getAddNewCategoryList = (event) => {
    let addCategoryList = addNewProductCategoryList.find(
      (el) => el.name === event.label
    );
    setCategoryValue(addCategoryList.name);
    setCategoryId(addCategoryList.category_id);
  };
  // getCategory List fiunction ends here

  // call an api to get brand list functions start here
  const handleBrandList = async (e, callback) => {
    const token = props.getUserDetails.token;
    const params = {
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      data: {
        keyword: e,
      },
    };
    return await Axios(apiUrl + "/get-brand-by-keyword", params)
      .then((data) => {
        setAddNewProductBrandList(data.data.data);
        callback(
          data.data.data.map((i) => ({
            label: `${i.name}`,
            value: `${i.name}`,
          }))
        );
      })
      .catch((err) => console.log(err.response));
  };
  // call an api to get brand list function ends here
  const getAddNewBrandList = (event) => {
    let addBrandList = addNewProductBrandList.find(
      (el) => el.name == event.label
    );
    setBrandValue(addBrandList.name);
    setManufactureId(addBrandList.manufacturer_id);
  };
  // get addNewBrand List function ends here

  // call an api to get hsn code list functions start here
  const handleHsnCodeList = async (e, callback) => {
    const token = props.getUserDetails.token;
    const params = {
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      data: {
        keyword: e,
      },
    };
    return await Axios(apiUrl + "/get-hsn-by-keyword", params)
      .then((data) => {
        setAddNewProductHsnCodeList(data.data.data);
        callback(
          data.data.data.map((i) => ({
            label: `${i.hsn_code}`,
            value: `${i.hsn_code}`,
          }))
        );
      })
      .catch((err) => console.log(err.response));
  };
  // call an api to get hsn code list function ends here

  // get addNew Hsn code List function start here
  const getAddNewHsnCodeList = (event) => {
    let addBrandList = addNewProductHsnCodeList.find(
      (el) => el.hsn_code == event.label
    );
    setHsnCodeValue(addBrandList.hsn_code);
  };
  // get addNew Hsn code List function ends here

  const getVendorStatusLabel = (status) => {
    switch (status) {
      case 0:
        return "Disabled";
      case 1:
        return "Enabled";
      case 2:
        return "Pending For Verification";
      case 3:
        return "Draft";
      case 4:
        return "Rejected";
      default:
        return "";
    }
  };

  // handle vendor location list
  const handleVendorLocation = async (e, callback, item) => {
    const token = props.getUserDetails.token;
    const params = {
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      data: {
        keyword: e,
        // productId: item.product_id,
      },
    };
    return await Axios(apiUrl + "/get-vendor-by-keyword", params)
      .then((data) => {
        setVendorLocationList(data.data.data);
        callback(
          data.data.data.map((i) => ({
            label: `${i.vendor_name} - ${getVendorStatusLabel(
              i.vendor_status
            )}`,
            value: `${i.vendor_name} - ${getVendorStatusLabel(
              i.vendor_status
            )}`,
          }))
        );
      })
      .catch((err) => console.log(err.response));
  };

  // get vendor location data function
  const getVendorLocation = (event, index, type) => {
    const foundProduct = vendorlocationList.find(
      (el) =>
        `${el.vendor_name} - ${getVendorStatusLabel(el.vendor_status)}` ==
        event.label
    );
    if (foundProduct) {
      if (type === "show_sourcing") {
        const updatedProducts = [...show_sourcing_data];
        updatedProducts[index] = {
          ...updatedProducts[index],
          vendor_name: foundProduct.vendor_name,
          vendor_id: foundProduct.vendor_id,
          vendor_status: foundProduct.vendor_status,
        };
        setShowSourcingData(updatedProducts);
      } else {
        const updatedProducts = [...sourcing_data];
        updatedProducts[index] = {
          ...updatedProducts[index],
          vendor_name: foundProduct.vendor_name,
          vendor_id: foundProduct.vendor_id,
          vendor_status: foundProduct.vendor_status,
        };
        setSourcingData(updatedProducts);
      }
      const vendor_info = `${foundProduct.vendor_name} - ${getVendorStatusLabel(
        foundProduct.vendor_status
      )}`;
      setVendorNameLocation(vendor_info);
    }
  };

  // call an api to get product list
  const handleProductList = async (e, callback) => {
    const token = props.getUserDetails.token;
    // const hasAccess = access;

    if (isNaN(e)) {
      const dataPayload = { keyword: e, status: "2" };

      const params = {
        method: "POST",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        data: dataPayload,
      };
      return await Axios(apiUrl + "/get-products-by-keyword", params)
        .then((data) => {
          setProductList(data.data.data);
          callback(
            data.data.data.map((i) => ({
              label: `${i.name}`,
              value: `${i.name}`,
            }))
          );
        })
        .catch((err) => console.log(err.response));
    } else {
      const dataPayload = {
        product_id: Number(e),
        customer_id: customerId,
        status: "2",
      };

      const params = {
        method: "POST",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        data: dataPayload,
      };
      return await Axios(apiUrl + "/get-product-detail-by-id", params)
        .then((data) => {
          setProductList(data.data.data);
          callback(
            data.data.data.map((i) => ({
              label: `${i.name}`,
              value: `${i.name}`,
            }))
          );
        })
        .catch((err) => console.log(err.response));
    }
  };

  const getProductName = (event, index, type) => {
    const checkType = type === "sourcing" ? sourcing_data : reopenData;
    const isProductAlreadyAdded = checkType.some(
      (product) => product.name === event.label
    );

    if (isProductAlreadyAdded) {
      setErrorMessage("Product is already added");
      setTimeout(() => {
        setErrorMessage("");
      }, 3000);
      return;
    } else {
      setErrorMessage("");
    }
    const foundProduct = product_list.find((el) => el.name === event.label);
    if (foundProduct) {
      let updatedProducts = [...sourcing_data];
      if (type === "sourcing") {
        updatedProducts = [...sourcing_data];
      } else if (type === "show_sourcing") {
        updatedProducts = [...show_sourcing_data];
      } else if (type === "product") {
        updatedProducts = [...product_data];
      } else {
        updatedProducts = [...reopenData];
      }
      displayAllProducts(foundProduct.product_id, customerId).then((res) => {
        updatedProducts[index] = {
          ...updatedProducts[index],
          name: foundProduct.name,
          product_id: foundProduct.product_id,
          availabilityType: "",
          lead_time: 0,
          quantity: 0,
          category_id: 0,
          type: "product",
          brand: res.brand,
          brand_id: res.brand_id ? res.brand_id : 0,
          quantity_type: res.quantity_type,
          // sales_transfer_price: res.transfer_price,
          sales_transfer_price: 0,
        };
        setProductId(foundProduct.product_id); // pass state data for prodct id api
        setProductName(foundProduct.productName);
        if (type === "sourcing") {
          setSourcingData(updatedProducts);
        } else if (type === "show_sourcing") {
          setShowSourcingData(updatedProducts);
        } else if (type === "product") {
          setProductData(updatedProducts);
        } else {
          setReopenData(updatedProducts);
        }
      });
    }
  };

  const handleChange = (e, ind, type) => {
    const { name, value } = e.target;
    let newData;
    if (type === "sourcing") {
      newData = [...sourcing_data];
      newData[ind] = { ...newData[ind], [name]: value };
      setSourcingData(newData);
    } else if (type === "show_sourcing") {
      newData = [...show_sourcing_data];
      newData[ind] = { ...newData[ind], [name]: value };
      setShowSourcingData(newData);
    } else if (type === "product") {
      newData = [...product_data];
      newData[ind] = { ...newData[ind], [name]: value };
      setProductData(newData);
    } else {
      newData = [...reopenData];
      newData[ind] = { ...newData[ind], [name]: value };
      setReopenData(newData);
    }
  };

  const handleSelectChooseType = (i, e, type) => {
    if (type === "sourcing") {
      const newValueSourcing = [...sourcing_data];
      const availabilityType = e.target.value || "";
      const brandType = e.target.value;
      newValueSourcing[i] = {
        ...newValueSourcing[i],
        availabilityType: availabilityType,
        lead_time:
          availabilityType === "Ready Stock" ||
          availabilityType === "Not Avl" ||
          availabilityType === ""
            ? 0
            : newValueSourcing[i].lead_time,
        brand:
          brandType === "product" ||
          brandType === "category" ||
          brandType === "other"
            ? ""
            : newValueSourcing[i].brand,
      };
      const newItemTypeArray = [...itemTypeArray];
      newItemTypeArray[i] = availabilityType;
      setItemTypeArray(newItemTypeArray);
      setSourcingData(newValueSourcing);
    } else if (type === "show_sourcing") {
      const newValueShowSourcing = [...show_sourcing_data];
      const availabilityType = e.target.value || "";
      const brandType = e.target.value;
      newValueShowSourcing[i] = {
        ...newValueShowSourcing[i],
        availabilityType: availabilityType,
        lead_time:
          availabilityType === "Ready Stock" ||
          availabilityType === "Not Avl" ||
          availabilityType === ""
            ? 0
            : newValueShowSourcing[i].lead_time,
        brand:
          brandType === "product" ||
          brandType === "category" ||
          brandType === "other"
            ? ""
            : newValueShowSourcing[i].brand,
      };
      const newItemTypeArray = [...itemTypeArray];
      newItemTypeArray[i] = availabilityType;
      setItemTypeArray(newItemTypeArray);
      setShowSourcingData(newValueShowSourcing);
    } else {
      const newValueProduct = [...product_data];
      const availabilityType = e.target.value || "";
      const brandType = e.target.value;
      newValueProduct[i] = {
        ...newValueProduct[i],
        availabilityType: availabilityType,
        lead_time:
          availabilityType === "Ready Stock" ||
          availabilityType === "Not Avl" ||
          availabilityType === ""
            ? 0
            : newValueProduct[i].lead_time,
        brand:
          brandType === "product" ||
          brandType === "category" ||
          brandType === "other"
            ? ""
            : newValueProduct[i].brand,
      };
      const newItemTypeArray = [...itemTypeArray];
      newItemTypeArray[i] = availabilityType;
      setItemTypeArray(newItemTypeArray);
      setProductData(newValueProduct);
    }
  };
  // functions ends here

  const addItem = (e) => {
    let newId = id + 1;
    let values = updateType();
    setItemTypeArray((prev) => [...prev, "product"]);
    e.type = "product";
    if (values === "sourcing") {
      setSourcingData((prev) => [...prev, e]);
    } else if (values === "show_sourcing") {
      setShowSourcingData((prev) => {
        return [...prev, e];
      });
    } else if (values === "product") {
      setProductData((prev) => {
        return [...prev, e];
      });
    } else {
      setReopenData((prev) => {
        return [...prev, e];
      });
    }
    setId(newId);
    setFinalAdd(true);
    setTimeout(() => {
      setFinalAdd(false);
    }, 2000);
  };

  const removeSalesProductItem = (index, type) => {
    const newItemTypeArray = itemTypeArray.filter((_, i) => i !== index);
    if (type === "show_sourcing") {
      const ShowSourcingProduct = show_sourcing_data.filter(
        (_, i) => i !== index
      );
      setShowSourcingData(ShowSourcingProduct);
    } else if (type === "sourcing") {
      const sourcingProduct = sourcing_data.filter((_, i) => i !== index);
      setSourcingData(sourcingProduct);
    } else if (type === "product") {
      const newProductData = product_data.filter((_, i) => i !== index);
      setProductData(newProductData);
    } else {
      const newProductData = reopenData.filter((_, i) => i !== index);
      setReopenData(newProductData);
    }
    setItemTypeArray(newItemTypeArray);
  };

  const removeItemShowSourcingData = (index, type) => {
    const newItemTypeArray = itemTypeArray.filter((_, i) => i !== index);
    if (type === "show_sourcing") {
      const ShowSourcingProduct = show_sourcing_data.filter(
        (_, i) => i !== index
      );
      setShowSourcingData(ShowSourcingProduct);
    } else if (type === "sourcing") {
      const sourcingProduct = sourcing_data.filter((_, i) => i !== index);
      setSourcingData(sourcingProduct);
    } else {
      const newProductData = product_data.filter((_, i) => i !== index);
      setProductData(newProductData);
    }
    setItemTypeArray(newItemTypeArray);
  };

  const handleFormChange = (e) => {
    const { name, value } = e.target;
    if (value.length > maxLength) {
      alert("You have exceeded the maximum character limit");
    } else {
      setFormDataValue((prevFormData) => ({
        ...prevFormData,
        [name]: value,
      }));
    }
  };

  // add new product handle form change function start here
  const handleAddNewProductChange = (event) => {
    const { name, value } = event.target;
    setAddNewProductFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };
  // add new product handle form change function ends here

  const handleCancelAddNewProduct = () => {
    setAddNewProductFormData({
      productName: "",
      mrp: "",
      model: "",
    });
    setBrandValue("");
    setCategoryValue("");
    setHsnCodeValue("");
    handleCloseModal();
  };

  // add vendor
  const handleAddNewVendorChange = (event) => {
    const { name, value } = event.target;
    setAddNewVendorData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };
  // add new product handle form change function ends here

  const handleCancelAddNewVendor = () => {
    setAddNewVendorData({
      mobile: "",
      business_name: "",
      contact_name: "",
    });
    handleCloseVendor(); // close modal overlay function
  };

  // editable function start here
  const handleProductSelectChooseType = (i, e, type) => {
    const brandType = e.target.value;
    if (type === "sourcing") {
      const newValue = [...sourcing_data];
      newValue[i] = {
        ...newValue[i],
        type: e.target.value,
        name: "",
        quantity: 0,
        sales_transfer_price: 0,
        category_id: 0,
        product_id: 0,
        brand:
          brandType === "product" ||
          brandType === "category" ||
          brandType === "other"
            ? ""
            : newValue[i].brand,
      };
      const newItemTypeArray = [...itemTypeArray];
      newItemTypeArray[i] = e.target.value;
      setProductData(newValue);
      setItemTypeArray(newItemTypeArray);
    } else if (type === "show_sourcing") {
      const newValue = [...show_sourcing_data];
      newValue[i] = {
        ...newValue[i],
        type: e.target.value,
        name: "",
        quantity: 0,
        sales_transfer_price: 0,
        category_id: 0,
        product_id: 0,
        brand:
          brandType === "product" ||
          brandType === "category" ||
          brandType === "other"
            ? ""
            : newValue[i].brand,
      };
      const newItemTypeArray = [...itemTypeArray];
      newItemTypeArray[i] = e.target.value;
      setShowSourcingData(newValue);
      setItemTypeArray(newItemTypeArray);
    } else if (type === "product") {
      const newValue = [...product_data];
      newValue[i] = {
        ...newValue[i],
        type: e.target.value,
        name: "",
        quantity: 0,
        sales_transfer_price: 0,
        category_id: 0,
        product_id: 0,
        brand:
          brandType === "product" ||
          brandType === "category" ||
          brandType === "other"
            ? ""
            : newValue[i].brand,
      };
      const newItemTypeArray = [...itemTypeArray];
      newItemTypeArray[i] = e.target.value;
      setProductData(newValue);
      setItemTypeArray(newItemTypeArray);
    } else {
      const newValue = [...reopenData];
      newValue[i] = {
        ...newValue[i],
        type: e.target.value,
        name: "",
        quantity: 0,
        sales_transfer_price: 0,
        category_id: 0,
        product_id: 0,
        brand:
          brandType === "product" ||
          brandType === "category" ||
          brandType === "other"
            ? ""
            : newValue[i].brand,
      };
      const newItemTypeArray = [...itemTypeArray];
      newItemTypeArray[i] = e.target.value;
      setReopenData(newValue);
      setItemTypeArray(newItemTypeArray);
    }
  };

  const getCategoryName = (event, index, type) => {
    const checkType = type === "sourcing" ? sourcing_data : reopenData;
    const isCategoryAlreadyAdded = checkType.some(
      (category) => category.name === event.label
    );

    if (isCategoryAlreadyAdded) {
      setErrorMessage("Category is already added");
      setTimeout(() => {
        setErrorMessage("");
      }, 3000);
      return;
    } else {
      setErrorMessage("");
    }
    const category = addNewProductCategoryList.find(
      (el) => el.name === event.label
    );
    if (category) {
      let updatedProducts = [...reopenData]; // Default to reopenData array
      if (type === "sourcing") {
        updatedProducts = [...sourcing_data];
      } else if (type === "show_sourcing") {
        updatedProducts = [...show_sourcing_data];
      } else if (type === "product") {
        updatedProducts = [...product_data];
      } else {
        updatedProducts = [...reopenData];
      }

      updatedProducts[index] = {
        ...updatedProducts[index],
        name: category.name,
        product_id: 0,
        category_id: 0,
        price: false,
        availability: false,
        quantity: 0,
        type: "category",
        brand: "",
        sales_transfer_price: category.productPrice ? category.productPrice : 0,
      };

      setProductName(category.productName);

      if (type === "sourcing") {
        setSourcingData(updatedProducts);
      } else if (type === "show_sourcing") {
        setShowSourcingData(updatedProducts);
      } else if (type === "product") {
        setProductData(updatedProducts);
      } else {
        setReopenData(updatedProducts);
      }
    }
  };

  const handleOtherName = (index, event, type) => {
    let updatedProducts;

    if (type === "sourcing") {
      updatedProducts = [...sourcing_data];
    } else if (type === "show_sourcing") {
      updatedProducts = [...show_sourcing_data];
    } else if (type === "product") {
      updatedProducts = [...product_data];
    } else {
      updatedProducts = [...reopenData];
    }

    updatedProducts[index] = {
      ...updatedProducts[index],
      [event.target.name]: event.target.value,
    };

    if (type === "sourcing") {
      setSourcingData(updatedProducts);
    } else if (type === "show_sourcing") {
      setShowSourcingData(updatedProducts);
    } else if (type === "product") {
      setProductData(updatedProducts);
    } else {
      setReopenData(updatedProducts);
    }
  };

  const handleProductChange = (index, event, type) => {
    let updatedProducts;

    if (type === "sourcing") {
      updatedProducts = [...sourcing_data];
    } else if (type === "show_sourcing") {
      updatedProducts = [...show_sourcing_data];
    } else if (type === "product") {
      updatedProducts = [...product_data];
    } else {
      updatedProducts = [...reopenData];
    }

    updatedProducts[index] = {
      ...updatedProducts[index],
      [event.target.name]: event.target.value,
    };

    if (type === "sourcing") {
      setSourcingData(updatedProducts);
    } else if (type === "show_sourcing") {
      setShowSourcingData(updatedProducts);
    } else if (type === "product") {
      setProductData(updatedProducts);
    } else {
      setReopenData(updatedProducts);
    }
  };

  const handleCheckboxValue = (newValue, ind, name) => {
    const newData = [...product_data];
    newData[ind] = { ...newData[ind], [name]: newValue };
    setProductData(newData);
  };

  // call an api to get product detail by id
  const displayAllProducts = async (product_id, customer_id) => {
    const hasAccess = access;

    const dataPayload = hasAccess
      ? { product_id: product_id, customer_id: customer_id, status: "2" }
      : {
          product_id: product_id,
          customer_id: customer_id,
        };

    const requestoption = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${props.getUserDetails.token}`,
      },
      data: dataPayload,
    };
    return await Axios(apiUrl + "/get-product-detail-by-id", requestoption)
      .then((data) => {
        return data.data.data[0];
      })
      .catch((err) => console.log(err));
  };

  const handleNoSelect = (e) => {
    const value = e.target.value;
    setLocation(value);
  };

  const handleLocation = async (e, callback) => {
    const config = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${props.getUserDetails.token}`,
      },
      data: {
        query: `${e} in India`,
        key: "AIzaSyB7qkySF1gvl0jQ3Gfz3ju7wzxxp5SWVmc",
        components: "country:in",
      },
    };

    try {
      const response = await Axios(apiUrl + "/google-text", config);
      const data = response.data.data;

      if (data.status === "OK") {
        const location = data.results[0].geometry.location;
        setLocationList(data.results);

        if (data.results.length === 0) {
          setLocationId(0);
        }

        // Another request with the lat and lng
        const geocodeConfig = {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${props.getUserDetails.token}`,
          },
          data: {
            latlng: `${location.lat},${location.lng}`,
            sensor: "true",
            key: "AIzaSyB7qkySF1gvl0jQ3Gfz3ju7wzxxp5SWVmc",
            regions: "locality",
          },
        };

        try {
          const geocodeResponse = await Axios(
            apiUrl + "/google-lat-long",
            geocodeConfig
          );
          const geocodeData = geocodeResponse.data;
          let dataRes = [];

          for (let each of geocodeData.data.results) {
            let data = each.address_components.filter((value) =>
              value.types.includes("postal_code")
            );
            if (data.length) {
              let obj = {
                addr: each.formatted_address,
                pincode: data[0].long_name,
              };
              dataRes = [...dataRes, obj];
            }
          }

          callback(
            dataRes.map((result) => ({
              label: result.addr,
              value: result.addr,
            }))
          );

          setGoogleData(dataRes);
        } catch (geocodeError) {
          console.error("Geocode API error:", geocodeError);
        }
      } else {
        console.error("Geocode API error:", data.status);
        callback([]);
      }
    } catch (err) {
      console.error("Error:", err);
      callback([]);
    }
  };

  const debounce = (func, delay) => {
    let timeoutId;
    return (...args) => {
      if (timeoutId) {
        clearTimeout(timeoutId);
      }
      timeoutId = setTimeout(() => {
        func(...args);
      }, delay);
    };
  };

  const debouncedFetchLocationOptions = useCallback(
    debounce(handleLocation, 300),
    []
  );

  const handleLocationChange = (e) => {
    const value = e.target.value;
    setLocation(value);
  };

  const handleDataFromChild = (childData) => {
    setDataFromChild(childData);
  };

  const getCities = (e) => {
    let city = googleData.filter((el) => `${el.addr}` == e.value)[0];
    const cityAddr = `${city.addr}`;
    const cityPincode = `${city.pincode}`;
    setDataFromChild(cityAddr);
    // setLocation(cityAddr);
    setPincode(cityPincode);
  };

  const handleFileSelect = (file, type, indexP) => {
    setSelectedFile(file);
    if (type == "product") {
      let data = product_data.map((item, index) => {
        if (index === indexP) {
          item = {
            brand: "",
            brand_id: 0,
            category_id: 0,
            product_id: 0,
            type: "attachments",
            name: file,
          };
        }
        return item;
      });
      setProductData(data);
    } else if (type === "sourcing") {
      let data = sourcing_data.map((item, index) => {
        if (index === indexP) {
          item = {
            brand: "",
            brand_id: 0,
            category_id: 0,
            product_id: 0,
            type: "attachments",
            name: file,
          };
        }
        return item;
      });
      setSourcingData(data);
    } else if (type === "show_sourcing") {
      let data = show_sourcing_data.map((item, index) => {
        if (index === indexP) {
          item = {
            brand: "",
            brand_id: 0,
            category_id: 0,
            product_id: 0,
            type: "attachments",
            name: file,
          };
        }
        return item;
      });
      setShowSourcingData(data);
    } else {
      let data = reopenData.map((item, index) => {
        if (index === indexP) {
          item = {
            brand: "",
            brand_id: 0,
            category_id: 0,
            product_id: 0,
            type: "attachments",
            name: file,
          };
        }
        return item;
      });
      setReopenData(data);
    }
  };

  if (is_redirect) {
    return (
      <Redirect
        to={{
          pathname: "/createQuote",
          state: {
            rfqID: rfqId,
            products: show_sourcing_data,
            phone: customerMobile,
          },
        }}
      />
    );
  }

  return (
    <>
      {errorMessage ? (
        <ErrorHandling message={errorMessage} type={"danger"} />
      ) : null}
      {successMessage ? (
        <ErrorHandling message={successMessage} type={"success"} />
      ) : null}
      <section className={style.userData_info_wrapper}>
        <>
          <UserData
            onData={handleDataFromChild}
            formValidate={formValidate}
            sourcingId={sourcingId}
            status={status}
            customerName={customer_name}
            accountName={account_name}
            deliverLocation={deliver_loaction}
            handleNoSelect={handleNoSelect}
            getCities={getCities}
            handleLocation={handleLocation}
            handleLocationChange={handleLocationChange}
            location={location}
            pincode={pincode}
            locationList={locationList}
            locationId={locationId}
            access={access}
            disabled={!showReOpen}
            debouncedFetchLocationOptions={debouncedFetchLocationOptions}
          />
          <div className={style.accordionCnt}>
            {accordionData?.versionHistory &&
            accordionData?.versionHistory.length &&
            !access
              ? accordionData?.versionHistory.map((item, index) => {
                  return accordionData.versionHistory.length - 1 === index ? (
                    status === "reopen" ? (
                      <div className={`${style.accordion_inner}`}>
                        <AccordionPage
                          accordionData={item}
                          handleAttachmentClick={handleAttachmentClick}
                        />
                      </div>
                    ) : null
                  ) : (
                    <div className={`${style.accordion_inner}`}>
                      <AccordionPage
                        accordionData={item}
                        handleAttachmentClick={handleAttachmentClick}
                      />
                    </div>
                  );
                })
              : null}
          </div>

          {!showReOpen ? (
            <SalesProductListing
              status={status}
              accountManager={accountManager}
              userLocation={userLocation}
              dataSubmittedOn={dataSubmittedOn}
              products={product_data}
              formValidate={formValidate}
              maxLength={maxLength}
              handleFileSelect={handleFileSelect}
              ListHeading={`Requirement Details`}
              RemarksHeading={`KAM Remarks`}
              kamRemarks={formDataValue.kam_remarks}
              handleAttachmentClick={handleAttachmentClick}
              disabled={!showReOpen}
            />
          ) : null}
        </>

        {!showReOpen ? (
          <>
            {access && ["pending", "new", "reopen"].includes(status) ? (
              <>
                {show_sourcing_data && show_sourcing_data.length ? (
                  <SourcingProductListing
                    ListHeading={"Sourcing Response"}
                    RemarksHeading={"Sourcing Remarks"}
                    sourcingRemarks={formDataValue.sourcing_remarks}
                    disabled={false}
                    handleFormChange={handleFormChange}
                    maxLength={maxLength}
                    formValidate
                    productName={productName}
                    getProductName={getProductName}
                    handleProductList={handleProductList}
                    handleChange={handleChange}
                    vendorNameLocation={vendorNameLocation}
                    getVendorLocation={getVendorLocation}
                    handleVendorLocation={handleVendorLocation}
                    handleSelectChooseType={handleSelectChooseType}
                    removeItemShowSourcingData={removeItemShowSourcingData}
                    products={show_sourcing_data}
                    type={"show_sourcing"}
                    access={access}
                    status={status}
                    showReOpen={showReOpen}
                    addItem={addItem}
                    handleShowModal={handleShowModal}
                    handleShowVendor={handleShowVendor}
                  />
                ) : (
                  <SourcingProductListing
                    ListHeading={"Sourcing Response"}
                    RemarksHeading={"Sourcing Remarks"}
                    sourcingRemarks={formDataValue.sourcing_remarks}
                    disabled={false}
                    handleFormChange={handleFormChange}
                    maxLength={maxLength}
                    formValidate
                    productName={productName}
                    getProductName={getProductName}
                    handleProductList={handleProductList}
                    handleChange={handleChange}
                    vendorNameLocation={vendorNameLocation}
                    getVendorLocation={getVendorLocation}
                    handleVendorLocation={handleVendorLocation}
                    handleSelectChooseType={handleSelectChooseType}
                    removeItemShowSourcingData={removeItemShowSourcingData}
                    products={sourcing_data}
                    type={"sourcing"}
                    access={access}
                    status={status}
                    showReOpen={showReOpen}
                    addItem={addItem}
                    handleShowModal={handleShowModal}
                    handleShowVendor={handleShowVendor}
                  />
                )}
              </>
            ) : show_sourcing_data && show_sourcing_data ? (
              <SourcingProductListing
                ListHeading={"Sourcing Response"}
                RemarksHeading={"Sourcing Remarks"}
                sourcingRemarks={formDataValue.sourcing_remarks}
                maxLength={maxLength}
                disabled={true}
                handleFormChange={handleFormChange}
                formValidate
                productName={productName}
                getProductName={getProductName}
                handleProductList={handleProductList}
                handleChange={handleChange}
                vendorNameLocation={vendorNameLocation}
                getVendorLocation={getVendorLocation}
                handleVendorLocation={handleVendorLocation}
                handleSelectChooseType={handleSelectChooseType}
                removeItemShowSourcingData={removeItemShowSourcingData}
                products={show_sourcing_data}
                type={"show_sourcing"}
                access={access}
                status={status}
                showReOpen={showReOpen}
                addItem={addItem}
                handleShowModal={handleShowModal}
                handleShowVendor={handleShowVendor}
              />
            ) : null}
          </>
        ) : null}
        <div className={style.accordionCnt}>
          {access &&
          accordionData?.versionHistory &&
          accordionData?.versionHistory.length
            ? accordionData?.versionHistory.map((item, index) => {
                return accordionData.versionHistory.length - 1 === index ? (
                  status === "reopen" ? (
                    <div className={`${style.accordion_inner}`}>
                      <AccordionPage
                        accordionData={item}
                        handleAttachmentClick={handleAttachmentClick}
                      />
                    </div>
                  ) : null
                ) : (
                  <div className={`${style.accordion_inner}`}>
                    <AccordionPage
                      accordionData={item}
                      handleAttachmentClick={handleAttachmentClick}
                    />
                  </div>
                );
              })
            : null}
        </div>
        <AddProduct
          show={show}
          submitLoading={submitLoading}
          brandValue={brandValue}
          hsnCodeValue={hsnCodeValue}
          categoryValue={categoryValue}
          handleHsnCodeList={handleHsnCodeList}
          handleBrandList={handleBrandList}
          handleCategoryList={handleCategoryList}
          getAddNewBrandList={getAddNewBrandList}
          getAddNewHsnCodeList={getAddNewHsnCodeList}
          getAddNewCategoryList={getAddNewCategoryList}
          handleCancelAddNewProduct={handleCancelAddNewProduct}
          handleAddNewProductChange={handleAddNewProductChange}
          createAddNewProductSubmit={createAddNewProductSubmit}
          addNewProductFormData={addNewProductFormData}
          type={updateType()}
          modalSuccessMessage={modalSuccessMessage}
          modalErrorMessage={modalErrorMessage}
          formValidate={formValidate}
        />

        <AddVendor
          show={showVendor}
          submitLoading={submitLoading}
          handleCancelAddNewProduct={handleCancelAddNewVendor}
          handleAddNewProductChange={handleAddNewVendorChange}
          createAddNewProductSubmit={createAddNewVendorSubmit}
          addNewProductFormData={addNewVendorData}
          type={updateType()}
          modalSuccessMessage={modalSuccessMessage}
          modalErrorMessage={modalErrorMessage}
          formValidate={formValidate}
          vendorIndex={index}
        />

        <SourcingFooter
          show={showModalSourcing}
          modalSuccessMessage={modalSuccessMessage}
          modalErrorMessage={sourcingMessage}
          handleHideSourcingModal={handleShowSourcingModal}
          status={status}
          access={access}
          accessSales={accessSales}
          type={updateType()}
          products={reopenData}
          UpdateSourcingRequest={UpdateSourcingRequest}
        />

        {showReOpen ? (
          <SalesProductListing
            status={status}
            accountManager={accountManager}
            userLocation={userLocation}
            dataSubmittedOn={dataSubmittedOn}
            products={reopenData}
            formValidate={formValidate}
            maxLength={maxLength}
            handleFileSelect={handleFileSelect}
            ListHeading={`Requirement Details`}
            RemarksHeading={`KAM Remarks`}
            kamRemarks={formDataValue.kam_remarks}
            handleAttachmentClick={handleAttachmentClick}
            disabled={!showReOpen}
            addItem={addItem}
            removeItem={removeSalesProductItem}
            handleFormChange={handleFormChange}
            handleCheckboxValue={handleCheckboxValue}
            productName={productName}
            getProductName={getProductName}
            getCategoryName={getCategoryName}
            handleProductList={handleProductList}
            handleCategoryList={handleCategoryList}
            handleChange={handleChange}
            handleOtherName={handleOtherName}
            handleProductChange={handleProductChange}
            handleSelectChooseType={handleProductSelectChooseType}
            type={updateType()}
          />
        ) : null}

        <Footer
          showModal={handleHideSourcingModal}
          access={access}
          accessSales={accessSales}
          status={status}
          showButtonStatus={showButtonStatus}
          showReOpen={showReOpen}
          UpdateSourcingRequest={UpdateSourcingRequest}
          submitLoading={submitLoading}
          handleShowFooter={handleShowFooter}
          handleCloseFooter={handleCloseFooter}
          handleCreateQuote={handleCreateQuote}
          srRfqStatus={
            rfqStatus && rfqStatus.rfqStatus ? rfqStatus.rfqStatus : ""
          }
        />
      </section>
    </>
  );
};

export default CreateSourcingPersonRequest;
