import React from "react";
import { Table } from "react-bootstrap";
import "./sourcing_upload.css";
import SourcingUploadFooter from "./SourcingUploadFooter";
import TableDeleteIcon from "../../../asset/Images/tableDeleteIcon.svg";
import WarningIcon from "../../../asset/Images/warningIcon.svg";
import ErrorIcon from "../../../asset/Images/errorIcon.svg";
import { Spinner } from "react-bootstrap";

const SourcingProductTable = (props) => {
  const {
    excelData,
    handleDeleteRow,
    viewIssueError,
    viewIssueCount,
    handleViewIssuesClick,
    addToSRProductSubmit,
    submitAddSRLoading,
    validateLoading,
  } = props;

  return (
    <>
      <div className="sourcing_table_wrapper">
        <div className="sourcing_table_hdr d-flex justify-content-between align-items-center">
          <div>
            <h2 className="new_product_title">New Products</h2>
            <p className="no_of_products d-flex align-items-center mb-0">
              {excelData.length ? (
                <span style={{ marginRight: "12px" }}>
                  {excelData.length} Products
                </span>
              ) : null}
              {excelData.length && viewIssueCount > 0 ? (
                <span className="d-flex align-items-center">
                  <span className="Issues_red">
                    {excelData.length && viewIssueCount > 0 && (
                      <>{viewIssueCount} issues found </>
                    )}
                  </span>
                </span>
              ) : null}
            </p>
          </div>
        </div>
      </div>
      <div className="table_wrapper">
        <Table className="sourcing_uploading_table">
          <thead>
            <tr>
              <th>No</th>
              <th>SR ID</th>
              <th>Model</th>
              <th>Product Name</th>
              <th>Brand</th>
              <th>Category</th>
              <th>MRP</th>
              <th>HSN</th>
              <th>Unit</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {validateLoading ? (
                <tr>
                <td colSpan="10">
                  <div className="d-flex justify-content-center align-items-center" style={{ height: '100px' }}>
                    <Spinner animation="border" role="status" size="lg">
                      <span className="sr-only">Loading...</span>
                    </Spinner>
                  </div>
                </td>
              </tr>
            ) : excelData && excelData.length > 0 ? (
              excelData.map((item, index) => (
                <>
                  <tr
                    className={
                      viewIssueError[index].errorStatus === 2
                        ? "red_issues_wrapper"
                        : viewIssueError[index].errorStatus === 1
                        ? "yellow_issues_wrapper"
                        : null
                    }
                  >
                    <td>{index + 1}</td>
                    <td>{item["SR ID"]}</td>
                    <td>{item.Model}</td>
                    <td className="productNameWrapper">
                      {item["Product Name"]}
                    </td>
                    <td>{item.Brand}</td>
                    <td>{item.Category}</td>
                    <td>{item.MRP}</td>
                    <td>{item["HSN code"]}</td>
                    <td>{item.Unit}</td>

                    <td>
                      <div className="issueDeleteWrapper">
                        <button
                          type="Button"
                          className="deleteBtnWrapper"
                          onClick={() => handleDeleteRow(index)}
                        >
                          <img
                            src={TableDeleteIcon}
                            alt="Table Delete Icon"
                            width="24px"
                            height="24px"
                          />
                        </button>
                        {viewIssueError[index].errorStatus === 2 ? (
                          <button
                            key={`error-btn-${viewIssueError[index].errorStatus}`}
                            type="button"
                            className="viewIssueWrapper"
                            onClick={(e) => handleViewIssuesClick(index, e)}
                          >
                            <img
                              src={
                                viewIssueError[index].errorStatus === 2
                                  ? ErrorIcon
                                  : viewIssueError[index].errorStatus === 1
                                  ? WarningIcon
                                  : viewIssueError[index].errorStatus === 0
                                  ? ""
                                  : null
                              }
                              alt="Error Icon"
                              width="16px"
                              height="16px"
                            />
                            <span
                              style={{ marginLeft: "8px" }}
                              className={
                                viewIssueError[index].errorStatus === 2
                                  ? "Issues_red"
                                  : viewIssueError[index].errorStatus === 1
                                  ? "Issues_yellow"
                                  : null
                              }
                            >
                              View Issues
                            </span>
                          </button>
                        ) : viewIssueError[index].errorStatus === 1 ? (
                          <button
                            key={`error-btn-${viewIssueError[index].errorStatus}`}
                            type="button"
                            className="viewIssueWrapper"
                            onClick={(e) => handleViewIssuesClick(index, e)}
                          >
                            <img
                              src={
                                viewIssueError[index].errorStatus === 2
                                  ? ErrorIcon
                                  : viewIssueError[index].errorStatus === 1
                                  ? WarningIcon
                                  : viewIssueError[index].errorStatus === 0
                                  ? ""
                                  : null
                              }
                              alt="Error Icon"
                              width="16px"
                              height="16px"
                            />
                            <span
                              style={{ marginLeft: "8px" }}
                              className={
                                viewIssueError[index].errorStatus === 2
                                  ? "Issues_red"
                                  : viewIssueError[index].errorStatus === 1
                                  ? "Issues_yellow"
                                  : null
                              }
                            >
                              View Issues
                            </span>
                          </button>
                        ) : null}
                      </div>
                    </td>
                  </tr>
                </>
              ))
            ) : (
              <tr>
                <td colSpan="5" style={{ textAlign: "center" }}>
                  No data found
                </td>
              </tr>
            )}
          </tbody>
          <SourcingUploadFooter
            addToSRProductSubmit={addToSRProductSubmit}
            submitAddSRLoading={submitAddSRLoading}
            excelData={excelData}
          />
        </Table>
      </div>
    </>
  );
};

export default SourcingProductTable;
