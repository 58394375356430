import Axios from "axios";
import React, { useEffect, useState } from "react";
import { Button, Col, Form, Row, Spinner, Table } from "react-bootstrap";

import { getUserDetails } from "../../Login/getLoginnedUserDetails";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from "@mui/material";
import Pagination from "../Common/Pagination";
import Select, { components } from "react-select";

import Constants from "../../../Config";
import * as XLSX from "xlsx";
import DateRangeNew from "../../DashBoardWidgets/NewWidgets.js/DateRangeNew";
// import { Redirect } from "react-router-dom/cjs/react-router-dom.min";
import moment from "moment";

const loginedUserDetails = JSON.parse(localStorage.getItem("userData"));

const InputOption = ({
  getStyles,
  Icon,
  isDisabled,
  isFocused,
  isSelected,
  children,
  innerProps,
  ...rest
}) => {
  const [isActive, setIsActive] = useState(false);
  const onMouseDown = () => setIsActive(true);
  const onMouseUp = () => setIsActive(false);
  const onMouseLeave = () => setIsActive(false);

  // styles
  let bg = "transparent";
  if (isFocused) bg = "#bebebe";
  if (isActive) bg = "#B2D4FF";

  const style = {
    alignItems: "center",
    backgroundColor: bg,
    color: "inherit",
    display: "flex ",
    gap: "5px",
  };

  // prop assignment
  const props = {
    ...innerProps,
    onMouseDown,
    onMouseUp,
    onMouseLeave,
    style,
  };

  return (
    <components.Option
      {...rest}
      isDisabled={isDisabled}
      isFocused={isFocused}
      isSelected={isSelected}
      getStyles={getStyles}
      innerProps={props}
    >
      <input type="checkbox" checked={isSelected} /> {children}
    </components.Option>
  );
};

const PurchaseOrderList = (props) => {
  const apiUrl = Constants.API_URL; // Replace with your API URL
  const [isLoading, setLoading] = useState(false);
  const [poList, setPoList] = useState([]); // setting empty arrya state for list
  const [poListCount, setPoListCount] = useState(0); // setting empty arrya state for list
  const [agentOptions, setagentOptions] = useState([]); // setting empty arrya state for list
  const [statusOptions, setstatusOptions] = useState([]); // setting empty arrya state for list
  const [agentArray, setagentsArray] = useState([]); // setting empty arrya state for list
  const [salesAgent, setsalesAgent] = useState([]);
  const [poStatus, setPOStatus] = useState([]);
  const [poStatusArray, setPOStatusArray] = useState([]);
  const [dateFrom, setDateFrom] = useState("");
  const [dateTo, setDateTo] = useState("");
  const [dateType, setDateType] = useState("");
  const [poID, setPoID] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [mobile, setMobile] = useState("");
  const [totalItem, setTotalItem] = useState(25);
  const [company_name, setCompanyName] = useState("");
  const [isDownloading, setDownloading] = useState(false);
  const [poListDownload, setPoListDownload] = useState([]);
  const [poProductListDownload, setPoProductListDownload] = useState([]);
  const [isPaginationLoading, setIsPaginationLoading] = useState(0);
  const [poNoDataFound, setPONoDataFound] = useState(false);

  //API CALL TO GET LIST ITEMS -->

  useEffect(() => {
    getAllAgents();
    getAllPOStatus();
  }, []);
  useEffect(() => {
    getTotalRecords();
  }, [
    mobile,
    dateTo,
    salesAgent,
    poStatus,
    company_name,
    currentPage,
    dateType,
    totalItem,
  ]);

  useEffect(() => {
    fetchData();
    setLoading(true);
  }, [
    mobile,
    dateTo,
    salesAgent,
    poStatus,
    company_name,
    currentPage,
    dateType,
    totalItem,
  ]);

  const fetchData = () => {
    const requestOption = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${props.getUserDetails.token}`, // Replace with your authorization header
      },
      data: {
        date_from: dateFrom,
        date_to: dateTo,
        po_status: poStatus,
        selectedAgents: salesAgent,
        company: company_name,
        type: dateType,
        phone: mobile,
        current_page: currentPage,
        total_item: totalItem,
        po_id: poID,
      }, // Provide any additional data here
    };

    Axios(`${apiUrl}/purchase-order-list`, requestOption)
      .then((response) => {
        // Handle the response data and update the state
        const poList = response.data.data;

        setPoList(poList);
        setLoading(false);
        setIsPaginationLoading(0);
      })
      .catch((error) => {
        setLoading(false);
      });
  };
  const getTotalRecords = () => {
    setLoading(true);
    const requestOption = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${props.getUserDetails.token}`, // Replace with your authorization header
      },
      data: {
        date_from: dateFrom,
        date_to: dateTo,
        po_status: poStatus,
        selectedAgents: salesAgent,
        company: company_name,
        type: dateType,
        phone: mobile,
      }, // Provide any additional data here
    };

    Axios(`${apiUrl}/purchase-order-list-count`, requestOption)
      .then((response) => {
        // Handle the response data and update the state
        const poListCount = response.data.data[0].count;

        if (poListCount == 0) {
          setPONoDataFound(true);
        } else {
          setPONoDataFound(false);
        }
        setPoListCount(poListCount);
        // setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
      });
  };
  const setPOState = (POID, QUOTEID) => {
    const state = {
      poID: POID,
      quoteId: QUOTEID,
      timestamp: new Date().getTime(),
      // quote_type: item.quote_type,
    };

    localStorage.setItem("poState", JSON.stringify(state));
  };

  const displayStatus = (status) => {
    // [  "Closed",  "Expired",  "Fulfilled",  "Order Not Created",  "Partial Fulfilled"]

    // setPOStatus(status);
    if (status == "Closed") {
      return "#DC4C64";
    } else if (status == "Expired") {
      return "#9FA6B2";
    } else if (status == "Fulfilled") {
      return "#28a745";
    } else if (status == "Order Not Created") {
      return "#ffc107";
    } else if (status == "Partial Fulfilled") {
      return "#ffc107";
    } else {
      return "";
    }
  };
  const getAllPOStatus = () => {
    const options = {
      method: "POST",
      data: {
        meta_id: "31",
      },

      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${props.getUserDetails.token}`,
      },
    };
    return Axios(apiUrl + "/meta-value", options)
      .then((data) => {
        const poStatusArrayNew = data.data.data;
        let poStatusArray = poStatusArrayNew.map((item) => {
          item["label"] = item["meta_title"];
          item["value"] = item["meta_value"];
          delete item["meta_title"];
          delete item["meta_value"];
          return item;
        });
        setPOStatusArray(poStatusArray);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getAllAgents = () => {
    let loginedUserDetails = JSON.parse(localStorage.getItem("userData"));
    const options = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${props.getUserDetails.token}`,
      },
      data: {
        agent_list: "",
        id: [56],
        showAll: "",
        listType: "list",
        slug: "po-list",
      },

      // data: { logined_user_id: props.getUserDetails.user_id },
    };
    return Axios(apiUrl + "/get-pre-sales-agents", options)
      .then((data) => {
        setagentsArray(data.data.data);

        let agentOptions1 = data.data.data;
        let agentOptions = agentOptions1.map((item) => {
          if (item.hasOwnProperty("name") && item.hasOwnProperty("id")) {
            item["label"] = item["name"];
            item["value"] = item["id"];
            delete item["name"];
            delete item["id"];
            return item;
          }
        });
        // agentOptions.unshift({ label: "UnAssign", value: 0 });
        setagentOptions(agentOptions);
      })
      .catch((error) => console.log(error));
  };
  const handleDatetype = (e) => {
    setDateType(e.target.value);
  };

  const setDateRange = (data) => {
    setDateFrom(data.start);
    setDateTo(data.end);
    setPoID(0);
    setCurrentPage(1);
    setIsPaginationLoading(1);
  };
  const debounce = (cb, delay = 1000) => {
    let timeout;
    return (...args) => {
      clearTimeout(timeout);

      timeout = setTimeout(() => {
        cb(...args);
      }, delay);
    };
  };

  const companyFilter = (e) => {
    setCompanyName(e);
    setPoID(0);
    setCurrentPage(1);
  };

  const mobileFilter = (e) => {
    setMobile(e);
    setPoID(0);
    setCurrentPage(1);

    setIsPaginationLoading(1);

    // this.getTotalRecords();
    // this.paginationLoading();
  };
  const updateMobile = debounce(mobileFilter);
  const getMobile = (e) => {
    updateMobile(e.target.value);
  };
  const updateCompany = debounce(companyFilter);
  const getCompany = (e) => {
    updateCompany(e.target.value);
  };

  const filterByAssignTo = (e) => {
    setPoID(0);

    setsalesAgent(e);
    setCurrentPage(1);
    setIsPaginationLoading(1);
  };
  const filterByStatus = (e) => {
    setPoID(0);

    setPOStatus(e);
    setCurrentPage(1);
    setIsPaginationLoading(1);
  };

  const exportsAllPOProduct = () => {
    return new Promise((resolve, reject) => {
      const requestoption = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${props.getUserDetails.token}`,
        },
        data: {
          date_from: dateFrom,
          date_to: dateTo,
          po_status: poStatus,
          selectedAgents: salesAgent,
          company: company_name,
          type: dateType,
          phone: mobile,
        },
      };
      return Axios(
        apiUrl + "/download-purchase-order-product-list",
        requestoption
      )
        .then((data) => {
          setDownloading(false);
          const dataNew = data.data.data.map((item, index) => {
            return {
              "S No.": index + 1,
              "PO ID": item.po_id,
              "Quote ID": item.quote_id,
              "Account Name": item.company_name,
              "Customer Name": item.customerName,
              "Customer Email": item.customerEmail,
              "Customer Mobile": item.customerPhone,
              "Agent Name": item.po_agent_name,
              "PO Status": item.po_current_status,
              // "PO Expiry Date": item.po_valid_date,
              "PO Creation Date": item.po_date
                ? moment(item.po_date).format("DD-MM-YYYY")
                : "",
              "PO Expiry Date": item.po_valid_date
                ? moment(item.po_valid_date).format("DD-MM-YYYY")
                : "",
              "Product Name": item.product_name,
              Price: item.price,
              "Total Quantity": item.total_po_quantity, // You can compute this if required
              "Ordered Quantity": item.total_order_quantity,
              "Remaining Qty":
                parseInt(item.remaining_quantity) < 0
                  ? -1 * item.remaining_quantity
                  : item.remaining_quantity,

              // "PO Reference No": item.po_reference,
              "UTM Content": item.utm_content, // Add UTM content field here if you have it in data
              "UTM Campaign": item.utm_campaign, // Add UTM campaign field here if you have it in data
              "UTM Source": item.utm_source, // Add UTM source field here if you have it in data
              "UTM Medium": item.utm_medium,
            };
          });
          resolve(dataNew);
        })
        .catch((error) => {
          setDownloading(false);
        });
    });
  };
  const exportsAllPO = () => {
    return new Promise((resolve, reject) => {
      const requestoption = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${props.getUserDetails.token}`,
        },
        data: {
          date_from: dateFrom,
          date_to: dateTo,
          po_status: poStatus,
          selectedAgents: salesAgent,
          company: company_name,
          type: dateType,
          phone: mobile,
        },
      };
      return Axios(apiUrl + "/download-purchase-order-list", requestoption)
        .then((data) => {
          setDownloading(false);
          const dataNew = data.data.data.map((item, index) => {
            return {
              "S No.": index + 1,
              "PO ID": item.po_id,
              "Quote ID": item.quote_id,
              "Account Name": item.company_name,
              "Customer Name": item.customerName,
              "Customer Email": item.customerEmail,
              "Customer Mobile": item.customerPhone,
              "Agent Name": item.po_agent_name,
              "Shipping Address":
                item.shipping_address_1 +
                ", " +
                item.shipping_address_2 +
                ", " +
                item.shipping_city +
                ", " +
                item.shipping_zone +
                ", " +
                item.shipping_postcode, // Add address field here if you have it in data
              "PO Status": item.po_current_status,
              "PO Creation Date": item.po_date
                ? moment(item.po_date).format("DD-MM-YYYY")
                : "",
              "PO Expiry Date": item.po_valid_date
                ? moment(item.po_valid_date).format("DD-MM-YYYY")
                : "",
              "PO Value": item.total_po_value,
              "Ordered Value": item.total_order_value, // You can compute this if required
              "PO Qty": item.total_po_quantity,
              "Ordered Qty": item.total_order_quantity,
              "Remaining Qty":
                parseInt(item.remaining_quantity) < 0
                  ? -1 * item.remaining_quantity
                  : item.remaining_quantity,
              "Remaining Value": item.remaining_value,
              "Confirmed Orders": item.confirmedOrderIds.join(","), // Add order IDs field here if you have it in data
              "Missing/Assisted/Cancelled Orders": item.missingOrderIds.join(","), // Add order IDs field here if you have it in data
              "PO Reference No": item.po_reference,
              "Have Attachment": item.po_attachment ? "Yes" : "No",
              "UTM Content": item.utm_content, // Add UTM content field here if you have it in data
              "UTM Campaign": item.utm_campaign, // Add UTM campaign field here if you have it in data
              "UTM Source": item.utm_source, // Add UTM source field here if you have it in data
              "UTM Medium": item.utm_medium, // Add UTM medium field here if you have it in data
              "Reject Reason": item.po_reject_reason,
            };
          });
          resolve(dataNew);
        })
        .catch((error) => {
          setDownloading(false);
        });
    });
  };

  const handleExport = (optionValue, poListDownload) => {
    var wb = XLSX.utils.book_new(),
      ws = XLSX.utils.json_to_sheet(poListDownload);

    XLSX.utils.book_append_sheet(wb, ws, "PO-List");
    XLSX.writeFile(
      wb,
      optionValue === "po_list" ? "PO-List.xls" : "PO-Product-list.xls"
    );
  };
  const downloadReport = async (optionValue) => {
    setDownloading(true);
    let result = null;
    if (optionValue === "po_list") {
      result = await exportsAllPO();
    } else {
      result = await exportsAllPOProduct();
    }
    if (result.length > 0) {
      setPoListDownload(result);
      setTimeout(() => {
        handleExport(optionValue, result);
        // this.csvLinkEl.current.click();
      }, 1000);
    }
  };
  //   useEffect(() => {
  //   }, [poListDownload])

  const handleDownload = (e) => {
    downloadReport(e.target.value);
  };
  useEffect(() => {
    filterByStatus(poStatus);
  }, [poStatus]);

  useEffect(() => {
    filterByAssignTo(salesAgent);
  }, [salesAgent]);

  const getPaginationValue = (currentPage, displayItems) => {
    setCurrentPage(currentPage);
    setTotalItem(displayItems);
    setPoID(0);
  };
  return (
    <div className="card table-card">
      <div className="headingPadding">
        <h4 className="mb-0 mt-3">Purchase Order</h4>
        <Form className="quoteRequestPageFilter">
          <Row>
            <Col md="3" className="mt-3">
              <Form.Control
                as="select"
                className="listingFontSizeColor"
                custom
                onChange={handleDatetype}
                name={dateType}
              >
                <option value="">Date Type</option>
                <option value="created">Create Date</option>
                <option value="updated">Update Date</option>
              </Form.Control>
            </Col>
            <Col md="3" className="mt-3">
              <div className="customFilterCnt">
                <div className="customFilter">
                  <DateRangeNew
                    label={"Date Filter"}
                    // allData={true}
                    setDateRange={setDateRange}
                  ></DateRangeNew>
                </div>
              </div>
            </Col>
            <Col md="3" className="mt-3">
              <Form.Control
                className="listingFontSizeColor"
                placeholder="Filter-Mobile"
                onChange={(data) => getMobile(data)}
              />
            </Col>
            <Col md="3" className="mt-3">
              <Form.Control
                className="listingFontSizeColor"
                placeholder="Filter-Company"
                onChange={(data) => getCompany(data)}
              />
            </Col>
            {poStatusArray && poStatusArray.length > 1 ? (
              <Col md="3" className="mt-3">
                <Select
                  defaultValue={[]}
                  isMulti
                  name="poStatus"
                  closeMenuOnSelect={false}
                  hideSelectedOptions={false}
                  controlShouldRenderValue={false}
                  options={poStatusArray}
                  className="basic-multi-select listingFontSizeColor"
                  classNamePrefix="select"
                  placeholder="Filter status"
                  onChange={(options) => {
                    if (Array.isArray(options)) {
                      // this.setState(options?.map((opt) => opt.user_id));

                      setPOStatus(options?.map((opt) => opt.value));
                      // filterByStatus(poStatus);
                    } else {
                      filterByStatus([]);
                    }
                  }}
                  components={{
                    Option: InputOption,
                  }}
                />
              </Col>
            ) : null}
            {agentOptions && agentOptions.length > 1 ? (
              <Col md="3" className="mt-3">
                <Select
                  defaultValue={[]}
                  isMulti
                  name="orderType"
                  closeMenuOnSelect={false}
                  hideSelectedOptions={false}
                  controlShouldRenderValue={false}
                  options={agentOptions}
                  className="basic-multi-select listingFontSizeColor"
                  classNamePrefix="select"
                  placeholder="Filter Agents"
                  onChange={(options) => {
                    if (Array.isArray(options)) {
                      // this.setState(options?.map((opt) => opt.user_id));

                      setsalesAgent(options?.map((opt) => opt.value));
                      // filterByAssignTo(salesAgent);
                    } else {
                      filterByAssignTo([]);
                    }
                  }}
                  components={{
                    Option: InputOption,
                  }}
                />
              </Col>
            ) : null}
            <Col md="3" className="mt-3">
              {isDownloading ? (
                <span
                  variant="primary"
                  className="btn btn-primary downLoadButton"
                >
                  <Spinner animation="border" />
                </span>
              ) : (
                <select
                  className="listingBtn"
                  // onChange={this.downloadReport}
                  onChangeCapture={handleDownload}
                  disabled={isDownloading}
                  value={0}
                >
                  <option value="0">Download</option>
                  <option value="po_list">PO List</option>
                  <option value="po_product_list">PO Product List</option>
                </select>
              )}
            </Col>
          </Row>
        </Form>
      </div>

      <div className="card-block">
        {isLoading ? (
          <div style={{ textAlign: "center", marginTop: 20 }}>
            <Spinner animation="border" />
          </div>
        ) : (
          <div className="mobile-table-scroll">
            <Table className="striped hover bordered">
              <thead>
                <tr>
                  <th tabIndex="0">PO ID Te</th>
                  <th tabIndex="0">Quote ID</th>
                  <th tabIndex="0">Account Name</th>
                  <th tabIndex="0">Customer Detail</th>
                  <th tabIndex="0">Agent Name</th>
                  <th tabIndex="0">PO Status</th>
                  <th tabIndex="0">PO Expiry Date</th>
                  <th tabIndex="0">PO Value</th>
                  <th tabIndex="0">Order Value</th>
                  <th tabIndex="0">PO QTY</th>
                  <th tabIndex="0">Attachment</th>
                  <th tabIndex="0">Ordered Qty</th>
                  <th tabIndex="0">Remaining Qty</th>
                  <th tabIndex="0">Action</th>
                </tr>
              </thead>
              <tbody>
                {poList &&
                  poList.map((item) => {
                    if (item.po_valid_date) {
                      const validUpto = moment(item.po_valid_date);
                      const d = validUpto._d;
                      var po_valid_date = d.toString().slice(4, 15);
                    }
                    return (
                      <>
                        <tr key={item.po_id}>
                          <td>{item.po_id}</td>
                          <td>{item.quote_id}</td>
                          <td>{item.company_name}</td>
                          <td>
                            <p>
                              <b>Name</b>: {item.customerName}
                              <br />
                              <b> Email</b>: {item.customerEmail}
                              <br />
                              <b>Phone</b>: {item.customerPhone}
                            </p>
                          </td>
                          <td>{item.po_agent_name}</td>
                          <td>
                            <div>
                              <p
                                style={{
                                  backgroundColor: `${displayStatus(
                                    item.po_current_status
                                  )}`,
                                  padding: "3px",
                                  textAlign: "center",
                                  borderRadius: "0.5rem",
                                }}
                              >
                                <b>{item.po_current_status}</b>
                              </p>
                            </div>
                          </td>
                          <td>{po_valid_date ? po_valid_date : null}</td>
                          <td>
                            {item.total_po_value
                              ? parseFloat(item.total_po_value).toFixed(2)
                              : item.total_po_value}
                          </td>
                          <td>
                            {item.total_order_value
                              ? parseFloat(item.total_order_value).toFixed(2)
                              : item.total_order_value}
                          </td>
                          <td>{item.total_po_quantity}</td>
                          <td>
                            {item.po_attachment ? (
                              <a
                                href={item.po_attachment}
                                target="_blank"
                                rel="noopener noreferrer"
                              >
                                YES
                              </a>
                            ) : (
                              "NO"
                            )}
                          </td>
                          <td>{item.total_order_quantity}</td>
                          <td>
                            {Number(item.remaining_quantity) < 0
                              ? -1 * Number(item.remaining_quantity)
                              : item.remaining_quantity}
                          </td>
                          {/* <td>{item.po_reference}</td>
                        <td>{item.po_reject_reason}</td>
                        <td>{item.po_current_status}</td>
                        <td>{item.orderIds}</td> */}

                          <td>
                            <Button>
                              <a
                                href="/editPO"
                                target="_blank"
                                onClick={() =>
                                  setPOState(item.po_id, item.quote_id)
                                }
                              >
                                <FontAwesomeIcon
                                  icon="edit"
                                  color="white"
                                  className="pointer-cursor"
                                />
                              </a>
                            </Button>
                          </td>
                        </tr>
                      </>
                    );
                  })}
                {poNoDataFound ? (
                  <span>
                    <h4>No Data</h4>
                  </span>
                ) : null}
              </tbody>
            </Table>
          </div>
        )}
      </div>
      <Pagination
        current_page={currentPage}
        total_item={poListCount}
        getPaginationValue={getPaginationValue}
        isPaginationLoading={isPaginationLoading}
      />
    </div>
  );
};

export default PurchaseOrderList;
