import React, { useState } from "react";
import Background from "../../asset/Images/login-bg.jpg";
import {
  Container,
  Row,
  Col,
  Form,
  Button,
  InputGroup,
  FormControl,
} from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "./login.css";
import axios from "axios";
import Constants from "../../Config";
import history from "../../helper/history";
import { Redirect } from "react-router-dom";
const apiUrl = Constants.API_URL;

const emailAddress = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;

function Login() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [submitted, setSubmitted] = useState(false);
  const [message, setMessage] = useState("");
  const [is_error, setError] = useState(false);

  const emailRef = React.createRef("email");

  async function handleSubmit(event) {
    event.preventDefault();
    setSubmitted(true);
    if (email.length > 0 && password.length > 0) {
      const requestoption = {
        method: "POST",
        data: {
          email: email,
          password: password,
        },
      };
      return await axios(apiUrl + "/signin", requestoption)
        .then((data) => {
          var userData = {
            firstname: data.data.data.firstname,
            lastname: data.data.data.lastname,
            email: data.data.data.email,
            user_id: data.data.data.user_id,
            token: data.data.data.token,
            is_super: data.data.data.is_super,
          };
          setError(false);
          localStorage.setItem("userData", JSON.stringify(userData));
          setMessage("Login successful");
          history.push("/dashboard");
        })

        .catch((error) => {
          setError(true);
          setMessage(error.response.data.message);
        });
    } else {
      if (submitted && !email.length > 0) {
        emailRef.current.focus();
      }
    }
  }
  const userData = JSON.parse(localStorage.getItem("userData"));
  if (userData) {
    return <Redirect to="/dashboard" />;
  }
  return (
    <div className="auth-wrapper">
      <Container fluid className="h-100">
        <Row className="flex-row h-100 bg-white">
          <Col
            xl={8}
            lg={6}
            md={5}
            className="p-0 d-md-block d-lg-block d-sm-none d-none"
          >
            <div
              className="lavalite-bg"
              style={{ backgroundImage: `url(${Background})` }}
            >
              <div className="lavalite-overlay"></div>
            </div>
          </Col>
          <Col xl={4} lg={6} md={7} className="my-auto p-0">
            <div className="authentication-form mx-auto">
              <h3>Login to McCoyMart CRM</h3>
              {message.length > 0 ? (
                <div
                  className={
                    is_error ? "alert alert-danger" : "alert alert-success"
                  }
                >
                  {message}
                </div>
              ) : (
                ""
              )}
              <Form onSubmit={handleSubmit}>
                <Form.Group controlId="loginFormEmail">
                  <InputGroup className="mb-1">
                    <InputGroup.Prepend>
                      <InputGroup.Text id="basic-addon1">
                        <FontAwesomeIcon icon="user" />
                      </InputGroup.Text>
                    </InputGroup.Prepend>
                    <FormControl
                      placeholder="Your email"
                      type="text"
                      aria-label="Username"
                      aria-describedby="basic-addon1"
                      onChange={(e) => setEmail(e.target.value)}
                      value={email}
                      ref={emailRef}
                      className={
                        submitted &&
                        (!email.length > 0 || !emailAddress.test(email))
                          ? "inputError"
                          : ""
                      }
                    />
                  </InputGroup>
                  <p
                    className={
                      submitted &&
                      (!email.length > 0 || !emailAddress.test(email))
                        ? "error"
                        : ""
                    }
                  >
                    {submitted &&
                    (!email.length > 0 || !emailAddress.test(email))
                      ? "Please Enter Email"
                      : ""}
                  </p>
                </Form.Group>
                <Form.Group controlId="loginFormPassword" className="mt-4">
                  <Form.Control
                    type="password"
                    placeholder="Password"
                    className={
                      submitted && !email.length > 0
                        ? "form-control inputError"
                        : ""
                    }
                    onChange={(e) => setPassword(e.target.value)}
                  />
                  <p
                    className={submitted && !password.length > 0 ? "error" : ""}
                  >
                    {submitted && !password.length > 0
                      ? "Please Enter Password"
                      : ""}
                  </p>
                </Form.Group>
                <div className="sign-btn text-center">
                  <Button variant="danger" type="submit">
                    Sign In
                  </Button>
                </div>
              </Form>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
}
export default Login;
