import React, { Component } from "react";
import {
  Row,
  Col,
  Button,
  Modal,
  Form,
  Table,
  InputGroup,
  FormControl,
  DropdownButton,
  Dropdown,
  Alert,
} from "react-bootstrap";
import axios from "axios";
import constants from "../../Config";
import { getUserDetails } from "../Login/getLoginnedUserDetails";
import Header from "../Dashboard/Common/Header";
import Footer from "../Dashboard/Common/Footer";
import Axios from "axios";

// const Distance_url = constants.API_URL;
const apiUrl = constants.API_URL;
// const GOOGLE_DISTANCE_API_KEY = constants.GOOGLE_DISTANCE_API_KEY;
// const CRM_API = constants.CRM_API;

class Getdistance extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      source: "",
      destination: "",
      distance: 0,
      error_message: "",
      placesArray: [],
    };
  }
  setDestination = (e) => {
    this.setState({
      destination: e.target.value,
    });
    this.getSuggestion(e);
  };
  setSource = (e) => {
    this.setState({
      source: e.target.value,
    });
    this.getSuggestion(e);
  };

  getSuggestion(e) {
    const config = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${getUserDetails.token}`,
      },
      data: {
        input: e.target.value,
      },
    };
    return axios(apiUrl + "/get-suggested-places", config)
      .then((response) => {
        this.setState({
          placesArray: response.data.data.suggestedPlaces,
        });
      })
      .catch((err) => console.log(err.response));
  }

  getDistance = (e) => {
    e.preventDefault();
    const config = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${getUserDetails.token}`,
      },
      data: {
        destination: this.state.destination,
        source: this.state.source,
      },
      // url: `${Distance_url}?destinations=${this.state.destination}&origins=${this.state.source}&key=${GOOGLE_DISTANCE_API_KEY}`,
    };
    return axios(apiUrl + "/get-distance", config)
      .then((response) => {
        this.setState({
          distance: response.data.data.distance,
        });
      })
      .catch((err) => {
        this.setState(
          {
            error_message: err.response.data.message,
          },
          () => {
            setTimeout(() => {
              this.setState({
                error_message: "",
              });
            }, 3000);
          }
        );
      });
  };

  render() {
    return (
      <>
        <div className="wrapper">
          <Header />

          <div className=" mx-auto enter-source-distance">
            {this.state.error_message != "" ? (
              <Alert
                className="displayMessageArea"
                variant={this.state.error_message != "" ? "danger" : "success"}
              >
                {this.state.error_message}
              </Alert>
            ) : (
              ""
            )}
            <div className="card-body">
              <Form onSubmit={this.getDistance}>
                <Row>
                  <Col>
                    <Form.Label>
                      <h2 style={{fontSize:'17px',color:'324357',fontWeight:'600'}}>Enter Source</h2>
                    </Form.Label>
                    <Form.Group controlId="exampleFormAddress">
                      {/* <Form.Control
                        name="source"
                        type="text"
                        placeholder="Enter your Destination"
                        onChange={this.setSource}
                        id="ärray"
                        required
                      >
                       
                        
                      </Form.Control> */}
                      {/* <Form.Control
                      // as="select"
                      name="address_type"
                      type="text"
                      list="data"
                      placeholder="Enter your Source"
                      defaultValue={this.state.source}
                      onChange={this.setSource}
                      required
                    > */}

                      <input
                        type="text"
                        list="array"
                        onChange={this.setSource}
                        className="w-100 p-2 rounded"
                        placeholder="Enter Your Source Location"
                        style={{border:'1px solid #324357'}}
                      />
                      <datalist id="array">
                        <option value="">Enter Sourcre</option>
                        {this.state.placesArray && this.state.placesArray.map((item) => {
                          return <option value={item}>{item}</option>;
                        })}
                      </datalist>

                      {/* <Form.Control
                       
                        // list="datalistOptions"
                        // id="exampleDataList"
                        custom
                        placeholder="Enter Source..."
                        as="select"
                        type="text"
                        onChange={this.setSource}
                        />
                        {this.state.source.map((item)=> {
                           return (
                            <option
                              // key={item.}
                              // selected={
                              //   this.props.businessData.legal_status == item.id
                              // }
                              value={item.description}
                            >
                              {item.description}
                            </option>
                          );

                        })} */}
                      {/* <datalist id="datalistOptions">
                        <option>Aquaproof-Raipur</option>
                        <option>Aquaproof-Ranchi</option>
                        <option>Aquaproof-Kalol</option>
                        <option>Aquaproof-Alwar</option>
                        <option>Aquaproof-Bawal</option>
                      </datalist> */}
                      {/* <InputGroup className="mb-3">
                      <FormControl aria-label="Text input with dropdown button" />

                      <DropdownButton
                        variant="primary"
                        title="Our Centers"
                        id="input-group-dropdown-2"
                        align="end"
                      >
                        <Dropdown.Item >Aquaproof-Raipur</Dropdown.Item>
                        <Dropdown.Item >Aquaproof-Ranchi</Dropdown.Item>
                        <Dropdown.Item >
                        Aquaproof-Kalol
                        </Dropdown.Item>
                        
                        <Dropdown.Item >Aquaproof-Bawal</Dropdown.Item>
                      </DropdownButton>
                    </InputGroup> */}
                      {/* </Form.Control> */}
                    </Form.Group>

                    <Form.Label>
                      <h2 style={{fontSize:'17px',color:'324357',fontWeight:'600'}}>Enter Destination</h2>
                    </Form.Label>
                    <Form.Group controlId="exampleFormAddress">
                      <input
                        style={{border:'1px solid #324357'}}
                        type="text"
                        list="array"
                        onChange={this.setDestination}
                        className="w-100 p-2 rounded"
                        placeholder="Enter Your Destination"
                      />
                      <datalist id="array">
                        <option value="">Enter Sourcre</option>
                        {this.state.placesArray && this.state.placesArray.map((item) => {
                          return <option value={item}>{item}</option>;
                        })}
                      </datalist>
                    </Form.Group>
                  </Col>
                </Row>
                <Button
                  variant="primary"
                  type="submit"
                  onClick={this.getDistance}
                  className="pull-right"
                  style={{fontSize:'14px',fontWeight:'500'}}
                >
                  Get Distance
                </Button>
              </Form>

              {/* // below table will show when distance has been calculated */}

              <Table striped hover className="my-4">
                <thead>
                  <tr>
                    <th>Distance {this.state.distance}</th>
                    <th>Price</th>
                    <th>MOQ</th>
                  </tr>
                </thead>
                {/* <tbody>
                  <tr>
                    <td>Distance {"<"} 50 KM </td>
                    <td>2500 Rs.</td>
                    <td>80</td>
                  </tr>
                  <tr>
                    <td>
                      50 {"<"} Distance {"<"} 100 KM
                    </td>
                    <td>4000 Rs.</td>

                    <td>100</td>
                  </tr>
                  <tr>
                    <td>
                      100 {"<"} Distance {"<"} 200 KM
                    </td>
                    <td>6500 Rs.</td>
                    <td>120</td>
                  </tr>
                </tbody> */}
              </Table>
            </div>
          </div>
        </div>
        {/* <Footer/> */}
      </>
    );
  }
}

export default Getdistance;
