import React from "react";
import { Modal } from "react-bootstrap";
import classes from "./CreateSource.module.css";
import { Button, Col, Form, Spinner } from "react-bootstrap";
import "../../../../node_modules/bootstrap/dist/css/bootstrap.min.css";
import ErrorHandling from "./ErrorHandling";

const AddVendor = (props) => {
  const {
    show,
    submitLoading,
    handleCancelAddNewProduct,
    handleAddNewProductChange,
    createAddNewProductSubmit,
    addNewProductFormData,
    type,
    modalSuccessMessage,
    modalErrorMessage,
    formValidate,
    vendorIndex
  } = props;

  const handleMobileChange = (e) => {
    const value = e.target.value;
    
    if (value.match(/^\d{0,10}$/)) {
      handleAddNewProductChange(e);
    }
  };

  return (
    <Modal
      contentClassName={`${classes.customModalBorder} ${classes.animateBottom}`}
      show={show}
      onHide={handleCancelAddNewProduct}
      dialogClassName={`${classes.abcd} ${classes.modal_bottom}`}
    >
      <div className="wrapper_modal">
        <Modal.Header closeButton className={classes.modal_header}>
          <Modal.Title className={classes.modal_title}>
            Add New Vendor
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className={classes.modal_header}>
          <Form.Row>
            <Col sm={4} className={classes.verticalCenter}>
              <Form.Group className={classes.formGroup}>
                <Form.Label className={classes.customer_title}>
                  Vendor Mobile Number
                </Form.Label>
                <Form.Control
                  className={classes.customInput}
                  type="number"
                  name="mobile"
                  value={addNewProductFormData.mobile}
                  onChange={handleMobileChange}
                  maxLength="10"
                />
              </Form.Group>
            </Col>
            <Col sm={4} className={classes.verticalCenter}>
              <Form.Group className={classes.formGroup}>
                <Form.Label className={classes.customer_title}>
                  Vendor Legal Name
                </Form.Label>
                <Form.Control
                  className={classes.customInput}
                  name="business_name"
                  value={addNewProductFormData.business_name}
                  onChange={handleAddNewProductChange}
                />
              </Form.Group>
            </Col>
            <Col sm={4} className={classes.verticalCenter}>
              <Form.Group className={classes.formGroup}>
                <Form.Label className={classes.customer_title}>
                  Contact Person Name
                </Form.Label>
                <Form.Control
                  className={classes.customInput}
                  name="contact_name"
                  value={addNewProductFormData.contact_name}
                  onChange={handleAddNewProductChange}
                />
              </Form.Group>
            </Col>
          </Form.Row>
        </Modal.Body>
        {modalErrorMessage ? (
          <ErrorHandling message={modalErrorMessage} type={"danger"} />
        ) : null}
        {modalSuccessMessage ? (
          <ErrorHandling message={modalSuccessMessage} type={"success"} />
        ) : null}
        <Modal.Footer
          className={`${classes.modal_header} ${classes.modal_footer}`}
        >
          <Button
            className={`${classes.submit} ${classes.cancel}`}
            onClick={handleCancelAddNewProduct}
          >
            Cancel
          </Button>
          <Button
            className={`${classes.submit} ${classes.save}`}
            onClick={(e) => createAddNewProductSubmit(e, type, vendorIndex)}
            disabled={submitLoading}
          >
            {submitLoading ? (
              <Spinner animation="border" role="status">
                <span className="sr-only">Loading...</span>
              </Spinner>
            ) : (
              "Save"
            )}
          </Button>
        </Modal.Footer>
      </div>
    </Modal>
  );
};
export default AddVendor;
