import React from "react";
import { Col, Form } from "react-bootstrap";
import classes from "../SourcingRequest/CreateSource.module.css";
import copyIcon from "../../../asset/Images/copy.svg";

const ExistingProducts = (props) => {
  const { copySuccess, suggestionProduct, handleCopyModel } = props;
  return (
    <>
      {suggestionProduct.length ? (
        <div className="existingProductWrapper">
          <h3 className="heading">Existing Products</h3>
          <>
            {" "}
            {suggestionProduct.map((suggProd, index) => (
              <Form.Row>
                <Col lg={2} className={classes.verticalCenter}>
                  <Form.Group className={classes.formGroup}>
                    <Form.Label className={classes.customLabel}>
                      Model
                    </Form.Label>
                    <Form.Group className={classes.formGroup}>
                      <p
                        className={classes.labelreadContent}
                        onClick={() => handleCopyModel(index)}
                      >
                        {suggProd.model}
                        {copySuccess ? (
                          <span
                            className="copiedText"
                            style={{ marginRight: "10px" }}
                          >
                            Copied!
                          </span>
                        ) : (
                          <img
                            src={copyIcon}
                            alt="copy icon"
                            width="20px"
                            height="20px"
                            className="ml-1"
                            style={{ cursor: "pointer" }}
                          />
                        )}
                      </p>
                    </Form.Group>
                  </Form.Group>
                  <hr className={classes.customeSeprator} />
                </Col>
                <Col lg={4} className={classes.verticalCenter}>
                  <Form.Group className={classes.formGroup}>
                    <Form.Label className={classes.customLabel}>
                      Product Name
                    </Form.Label>
                    <Form.Group className={classes.formGroup}>
                      <p className={classes.labelreadContent}>
                        {suggProd.productName}
                      </p>
                    </Form.Group>
                  </Form.Group>
                  <hr className={classes.customeSeprator} />
                </Col>
                <Col lg={2} className={classes.verticalCenter}>
                  <Form.Group className={classes.formGroup}>
                    <Form.Label className={classes.customLabel}>
                      Brand
                    </Form.Label>
                    <Form.Group className={classes.formGroup}>
                      <p className={classes.labelreadContent}>
                        {suggProd.brand}
                      </p>
                    </Form.Group>
                  </Form.Group>
                  <hr className={classes.customeSeprator} />
                </Col>
                <Col lg={2} className={classes.verticalCenter}>
                  <Form.Group className={classes.formGroup}>
                    <Form.Label className={classes.customLabel}>
                      Category
                    </Form.Label>
                    <Form.Group className={classes.formGroup}>
                      <p className={classes.labelreadContent}>
                        {suggProd.categoryName}
                      </p>
                    </Form.Group>
                  </Form.Group>
                  <hr className={classes.customeSeprator} />
                </Col>
                <Col lg={1} className={classes.verticalCenter}>
                  <Form.Group className={classes.formGroup}>
                    <Form.Label className={classes.customLabel}>MRP</Form.Label>
                    <Form.Group className={classes.formGroup}>
                      <p className={classes.labelreadContent}>
                        {suggProd.price}
                      </p>
                    </Form.Group>
                  </Form.Group>
                  <hr className={classes.customeSeprator} />
                </Col>
                <Col lg={1} className={classes.verticalCenter}>
                  <Form.Group className={classes.formGroup}>
                    <Form.Label className={classes.customLabel}>
                      Status
                    </Form.Label>
                    <Form.Group className={classes.formGroup}>
                      <p className={classes.labelreadContent}>Draft</p>
                    </Form.Group>
                  </Form.Group>
                </Col>
              </Form.Row>
            ))}{" "}
          </>
        </div>
      ) : null}
    </>
  );
};

export default ExistingProducts;
