import React from 'react';

const constants = {
    //API_URL: 'http://localhost:8030',
    //WEB_URL: 'http://localhost/mccoymart_live/public',
    API_URL: 'https://apiuat.mccoymart.com/crm',
    WEB_URL: 'https://uat.mccoymart.com',
    BUSINESS_CALL_ATTEMPT: ['Already Existing/Duplicate Account', 'Not Considered/ Invalid Account', 'Not Answered', 'Not Reachable', 'Disconnected', 'Call Back Scheduled', 'Call Waiting', 'Switch Off', 'Language Barrier', 'Called & Verified'],
    B2B_API_URL: 'https://apiuat.mccoymart.com/b2b',
};

export default constants;
