import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link } from 'react-router-dom';

class InnerBredcrum extends React.Component {
    render() {
        return (
            <div className="row mb-3 align-items-center">
                <div className="col-lg-8 col-7">
                    <div className="dashboard_heading_cnt">
                        <p className='dashboard_sub_heading'>{this.props.message}</p>
                        <h5 className='dashboard_main_heading'>{this.props.pageName}</h5>
                    </div>
                </div>
                <div className="col-lg-4 col-5">
                    <nav className="breadcrumb-container" aria-label="breadcrumb">
                        <ol className="breadcrumb">
                            <li className="breadcrumb-item">
                                <Link to="/dashboard"><FontAwesomeIcon icon="home" /></Link>
                            </li>
                            <li className="breadcrumb-item active" aria-current="page">{this.props.pageName}</li>
                        </ol>
                    </nav>
                </div>
            </div>
        )
    }
}


export default InnerBredcrum;