import React from "react";
import { Table } from "react-bootstrap";
import { Modal, Button, Form, Col } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

class ProductList extends React.Component {
  state = {
    quantity: 0,
    available_stock: 0,
    currentIndex: "",
    show: false,
    showMarkup: false,
  };
  openEditQuantityPopup = (quantity, index, available_stock) => {
    this.setState({
      quantity: quantity,
      available_stock: available_stock,
      currentIndex: index,
      show: true,
    });
  };
  openEditMarkupPopup = (markup, index) => {
    this.setState({
      markup: markup,
      currentIndex: index,
      showMarkup: true,
    });
  };
  closeModal = () => {
    this.setState({
      show: false,
    });
  };
  closeModalMarkup = () => {
    this.setState({
      showMarkup: false,
    });
  };
  updateQuantity = () => {
    this.closeModal();
    this.props.updateQuantityWithPrices({
      quantity: this.state.quantity,
      index: this.state.currentIndex,
    });
  };
  updateMarkup = () => {
    this.closeModalMarkup();
    this.props.updateMarkupWithPrices({
      markup: this.state.markup,
      index: this.state.currentIndex,
    });
  };
  handleQuantityChange = (e) => {
    /*if(this.state.available_stock >= e.target.value) {
            this.setState({
                quantity:e.target.value
            })
        } */
    if (e.target.value < 0) {
      this.setState({
        quantity: e.target.value * -1,
      });
    } else {
      this.setState({
        quantity: e.target.value,
      });
    }
  };
  handleMarkupChange = (e) => {
    this.setState({
      markup: e.target.value,
    });
  };
  render() {
    return (
      <div>
        <Table striped bordered hover>
          <thead>
            <tr>
              <th>#</th>
              <th>Product Details</th>
              <th>MRP</th>
              <th>TP</th>
              {/* <th>Markup %</th> */}
              <th>List Price(Per SKU)</th>
              {/* <th>List Price(ORG)</th>*/}
              <th>Quantity</th>
              {/* <th>Net Amount</th>
                    <th>Net Amount(ORG)</th> */}
              {/* <th>Tax (%)</th>
                    <th>Tax Amount</th> */}
              <th>Total</th>
              <th>Gross Margin %</th>
            </tr>
          </thead>
          <tbody>
            {this.props.getAllProductsWithDetailForList.map((item, index) => {
              return (
                <tr key={index}>
                  <td>{index + 1}</td>
                  <td>{item.name}</td>
                  <td>{item.mrp}</td>
                  <td>{item.transfer_price}</td>
                  {/* <td><a>{item.markup} <FontAwesomeIcon className="editIcon" icon="edit" onClick={()=>this.openEditMarkupPopup(item.markup, index)}/></a></td> */}
                  <td>{item.list_price}</td>
                  {/*<td>{item.list_price_org}</td> */}
                  <td>
                    {item.quantity}
                    {/* <a><FontAwesomeIcon className="editIcon" icon="edit" onClick={()=>this.openEditQuantityPopup(item.quantity, index, item.available_stock)}/>
                                  </a> */}
                  </td>
                  {/* <td>{item.net_amount}</td>
                                <td>{item.net_amount_org}</td> */}
                  {/* <td>{item.tax_in_percentage}</td>
                                <td>{item.tax_in_amount}</td> */}
                  <td>{item.total}</td>
                  <td>
                    {item.gross_margin}
                    <FontAwesomeIcon
                      icon="times-circle"
                      className="editIcon"
                      onClick={() => this.props.removeItemFromMainList(index)}
                    />
                  </td>
                </tr>
              );
            })}
          </tbody>
          <tfoot>
            <tr>
              <td colSpan="14" align="right">
                <span className="pr-5">Sub Total</span>
                <span>{this.props.subTotal}</span>
              </td>
            </tr>
            {this.props.shippingCharge > 0 ? (
              <tr>
                <td colSpan="14" align="right">
                  <span className="pr-5">Flat Shipping Rate</span>
                  <span>{this.props.shippingCharge}</span>
                </td>
              </tr>
            ) : null}
            <tr>
              <td colSpan="14" align="right">
                <span className="pr-5">Grand Total</span>
                <span>{this.props.grandTotal}</span>
              </td>
            </tr>
          </tfoot>
        </Table>
        <Modal show={this.state.show} onHide={this.closeModal}>
          <Modal.Header closeButton>
            <Modal.Title>Update Quantity</Modal.Title>
          </Modal.Header>
          <Modal.Body className="h-auto">
            <Form>
              <Form.Row>
                <Col lg={2}>
                  <Form.Group controlId="formBasicProductStock">
                    <Form.Control
                      type="text"
                      placeholder="Stock"
                      value={this.state.available_stock}
                      name="available_stock"
                      readonly="readonly"
                    />
                  </Form.Group>
                </Col>
                <Form.Group as={Col} controlId="updatedQuantity">
                  <Form.Control
                    type="number"
                    value={this.state.quantity}
                    name="quantity"
                    onChange={this.handleQuantityChange}
                    placeholder="Quantity"
                    min={1}
                    // onkeyup={if(this.value<0){this.value= this.value * -1}}
                    // onkeyup={this.value<0  ? this.value=this.value * -1 :null}
                  />
                </Form.Group>
              </Form.Row>
            </Form>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="primary" onClick={this.updateQuantity}>
              Done
            </Button>
            <Button variant="secondary" onClick={this.closeModal}>
              Cancel
            </Button>
          </Modal.Footer>
        </Modal>

        <Modal show={this.state.showMarkup} onHide={this.closeModalMarkup} className="business_process_wrapper">
          <Modal.Header closeButton>
            <Modal.Title>Update Markup</Modal.Title>
          </Modal.Header>
          <Modal.Body className="h-auto">
            <Form>
              <Form.Row>
                <Form.Group as={Col} controlId="updatedMarkup">
                  <Form.Control
                    type="text"
                    value={this.state.markup}
                    name="markup"
                    onChange={this.handleMarkupChange}
                    placeholder="Markup"
                  />
                </Form.Group>
              </Form.Row>
            </Form>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="primary" onClick={this.updateMarkup}>
              Done
            </Button>
            <Button variant="secondary" onClick={this.closeModalMarkup}>
              Cancel
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    );
  }
}

export default ProductList;
