import React from "react";
import "./sourcing_upload.css";
import { Col, Form } from "react-bootstrap";
import Modal from "react-bootstrap/Modal";
import classes from "../SourcingRequest/CreateSource.module.css";
import AsyncSelect from "react-select/async";
import { ReactComponent as SearchIcon } from "../../../asset/Images/search.svg";
import { components } from "react-select";
import { ReactComponent as DownArrowIcon } from "../../../asset/Images/RightArrow.svg";
import errormsgicon from "../../../asset/Images/errormsgicon.svg";
import tooltipDownIcon from "../../../asset/Images/tooltipDownIcon.svg";
import errormsgiconWarning from "../../../asset/Images/errormsgiconWarning.svg";
import deleteIconError from "../../../asset/Images/deleteIconError.svg";
import { Spinner } from "react-bootstrap";
import ExistingProducts from "./ExistingProducts";

const CustomInput = (props) => (
  <div style={{ display: "flex", alignItems: "center", height: "100%" }}>
    <SearchIcon />
    <components.Input {...props} />
  </div>
);

const customStyles = {
  control: (provided, state) => ({
    ...provided,
    backgroundColor: "transparent",
    border: "0",
    borderColor: state.isFocused ? "none" : "none",
    "&:hover": {
      borderColor: state.isFocused ? "none" : "none",
    },
    boxShadow: state.isFocused ? "none" : "none",
    minHeight: "auto",
    height: "100%",
  }),
  valueContainer: (provided) => ({
    ...provided,
    height: "100%",
    padding: "0",
  }),
  placeholder: (provided) => ({
    ...provided,
    color: "#99ABBE",
    padding: "0 0 0 20px",
    fontSize: "13px",
    textWrap: "noWrap",
  }),
  singleValue: (provided) => ({
    ...provided,
    color: "#1E293B",
    padding: "0 0 0 20px",
    fontSize: "13px",
  }),
  dropdownIndicator: (provided) => ({
    ...provided,
    display: "none",
  }),
  indicatorSeparator: (provided) => ({
    ...provided,
    display: "none",
  }),
};

const ViewIssueModal = (props) => {
  const {
    showViewModal,
    handleCloseViewModal,
    handleUpdateNewProducts,
    handleCategoryList,
    getAddNewCategoryList,
    handleBrandList,
    getAddNewBrandList,
    handleHsnCodeList,
    getAddNewHsnCodeList,
    categoryValue,
    brandValue,
    hsnCodeValue,
    submitLoading,
    handleDeleteProducts,
    productIndex,
    product,
    excelData,
    setExcelData,
    copySuccess,
    suggestionProduct,
    handleCopyModel,
    productErrorObj,
    setUploadStatus,
    hideMessagesAfterDelay,
  } = props;

  const quantityTypes = [
    { value: "", label: "Select" },
    { value: "box", label: "box" },
    { value: "cubic meter", label: "cubic meter" },
    { value: "cu.m", label: "cu.m" },
    { value: "gms", label: "gms" },
    { value: "kgs", label: "kgs" },
    { value: "mtr", label: "mtr" },
    { value: "pcs", label: "pcs" },
    { value: "pair", label: "pair" },
    { value: "set", label: "set" },
    { value: "sqft", label: "sqft" },
    { value: "ton", label: "ton" },
  ];

  const updateData = () => {
    const existingProduct = excelData[productIndex];

    if (!product["Product Name"]) {
      product["Product Name"] = existingProduct["Product Name"];
    }

    if (!product["MRP"]) {
      product["MRP"] = existingProduct["MRP"];
    }

    if (!product["Model"]) {
      product["Model"] = existingProduct["Model"];
    }

    const isProductChanged = Object.keys(product).some(
      (key) => product[key] !== existingProduct[key]
    );

    if (isProductChanged) {
      let updatedData = excelData.map((productData, index) => {
        if (index === productIndex) {
          return product;
        }
        return productData;
      });
      setExcelData(updatedData);
      setUploadStatus("Products have been updated successfully");
      hideMessagesAfterDelay();
    } else {
      console.log("No changes detected, no message shown.");
    }
    handleCloseViewModal();
  };

  return (
    <>
      <Modal
        show={showViewModal}
        onHide={handleCloseViewModal}
        centered
        className="updateNewProductModal"
      >
        <Modal.Header closeButton>
          <Modal.Title>Update New Product</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="newProductWrapper">
            <Form.Row className="customeRow mt-0">
              <Col lg={1} xs={4} className="customColumn customSeprator">
                <Form.Group className={classes.formGroup}>
                  <Form.Label className={classes.customer_title}>
                    SR No.
                  </Form.Label>
                  <div className="tooltipMsg-container">
                    <Form.Group className={classes.formGroup}>
                      <Form.Control
                        className={classes.labelreadContent}
                        name="srId"
                        value={`SR-${product["SR ID"]}`}
                      />
                    </Form.Group>
                    {productErrorObj.sourcingId ? (
                      <div className="errorMsgTooltipThumb">
                        <div className="errorMsgTooltipWrapper">
                          <img
                            src={errormsgicon}
                            alt="error msg icon"
                            width="16px"
                            height="16px"
                          />
                          <div className="errorMsgTooltipWrapperDropDown">
                            <div className="errorMsgTooltipWrapper">
                              <img
                                src={tooltipDownIcon}
                                alt="arrow icon"
                                className="tooltipUpwardIcon"
                              />
                              <p className="tooltipMsg">Not found</p>
                            </div>
                          </div>
                        </div>
                      </div>
                    ) : null}
                  </div>
                </Form.Group>
              </Col>
              <Col lg={3} xs={12} className="customColumn customSeprator">
                <Form.Group className={classes.formGroup}>
                  <Form.Label className={classes.customer_title}>
                    Model
                  </Form.Label>
                  <div className="tooltipMsg-container">
                    <Form.Control
                      className={`${classes.customInput} ${
                        productErrorObj.model
                          ? "error-input"
                          : !productErrorObj.model &&
                            productErrorObj?.model?.length > 0
                          ? "warning-input"
                          : ""
                      }`}
                      name="Model"
                      defaultValue={product.Model}
                      onChange={handleUpdateNewProducts}
                    />

                    {productErrorObj.model ? (
                      <div className="errorMsgTooltipThumb">
                        <div className="errorMsgTooltipWrapper">
                          <img
                            src={errormsgicon}
                            alt="error msg icon"
                            width="16px"
                            height="16px"
                          />
                          <div className="errorMsgTooltipWrapperDropDown">
                            <div className="errorMsgTooltipWrapper">
                              <img
                                src={tooltipDownIcon}
                                alt="arrow icon"
                                className="tooltipUpwardIcon"
                              />
                              <p className="tooltipMsg">Model already exists</p>
                            </div>
                          </div>
                        </div>
                      </div>
                    ) : null}
                  </div>
                </Form.Group>
              </Col>
              <Col lg={8} xs={12} className="customColumn customSeprator">
                <Form.Group className={classes.formGroup}>
                  <Form.Label className={classes.customer_title}>
                    Product Name
                  </Form.Label>
                  <div className="tooltipMsg-container">
                    <Form.Control
                      className={`${classes.customInput} ${
                        productErrorObj.product
                          ? "error-input"
                          : !productErrorObj.product &&
                            productErrorObj?.products?.length > 0
                          ? "warning-input"
                          : ""
                      }`}
                      name="Product Name"
                      defaultValue={product["Product Name"]}
                      onChange={handleUpdateNewProducts}
                    />
                    {productErrorObj.product ? (
                      <div className="errorMsgTooltipThumb">
                        <div className="errorMsgTooltipWrapper">
                          <img
                            src={errormsgicon}
                            alt="error msg icon"
                            width="16px"
                            height="16px"
                          />
                          <div className="errorMsgTooltipWrapperDropDown">
                            <div className="errorMsgTooltipWrapper">
                              <img
                                src={tooltipDownIcon}
                                alt="arrow icon"
                                className="tooltipUpwardIcon"
                              />
                              <p className="tooltipMsg">Possible Duplicate</p>
                            </div>
                          </div>
                        </div>
                      </div>
                    ) : productErrorObj?.products?.length > 0 ? (
                      <div className="errorMsgTooltipThumb">
                        <div className="errorMsgTooltipWrapper">
                          <img
                            src={errormsgiconWarning}
                            alt="error msg icon"
                            width="16px"
                            height="16px"
                          />
                          <div className="errorMsgTooltipWrapperDropDown">
                            <div className="errorMsgTooltipWrapper">
                              <img
                                src={tooltipDownIcon}
                                alt="arrow icon"
                                className="tooltipUpwardIcon"
                              />
                              <p className="tooltipMsg">Possible Duplicate</p>
                            </div>
                          </div>
                        </div>
                      </div>
                    ) : null}
                  </div>
                </Form.Group>
              </Col>
            </Form.Row>
            <Form.Row className="customeRow">
              <Col lg={3} xs={12} className="customColumn customSeprator">
                <Form.Group className={classes.formGroup}>
                  <Form.Label className={classes.customer_title}>
                    Brand
                  </Form.Label>
                  <div className="tooltipMsg-container">
                    <AsyncSelect
                      components={{ Input: CustomInput }}
                      styles={customStyles}
                      className={`${classes.customSelect} ${
                        productErrorObj.brand
                          ? "error-input"
                          : !productErrorObj.brand &&
                            productErrorObj?.brand?.length > 0
                          ? "warning-input"
                          : ""
                      }`}
                      value={{
                        value: brandValue,
                        label: product.Brand,
                      }}
                      onChange={(e) => getAddNewBrandList(e)}
                      loadOptions={handleBrandList}
                      placeholder="Search Brand"
                    />

                    {productErrorObj.brand ? (
                      <div className="errorMsgTooltipThumb">
                        <div className="errorMsgTooltipWrapper">
                          <img
                            src={errormsgicon}
                            alt="error msg icon"
                            width="16px"
                            height="16px"
                          />
                          <div className="errorMsgTooltipWrapperDropDown">
                            <div className="errorMsgTooltipWrapper">
                              <img
                                src={tooltipDownIcon}
                                alt="arrow icon"
                                className="tooltipUpwardIcon"
                              />
                              <p className="tooltipMsg">Not found</p>
                            </div>
                          </div>
                        </div>
                      </div>
                    ) : null}
                  </div>
                </Form.Group>
              </Col>
              <Col lg={3} xs={12} className="customColumn customSeprator">
                <Form.Group className={classes.formGroup}>
                  <Form.Label className={classes.customer_title}>
                    Category
                  </Form.Label>
                  <div className="tooltipMsg-container">
                    <AsyncSelect
                      components={{ Input: CustomInput }}
                      styles={customStyles}
                      className={`${classes.customSelect} ${
                        productErrorObj.category
                          ? "error-input"
                          : !productErrorObj.category &&
                            productErrorObj?.category?.length > 0
                          ? "warning-input"
                          : ""
                      }`}
                      name="category"
                      value={{
                        value: categoryValue,
                        label: product.Category,
                      }}
                      onChange={(e) => getAddNewCategoryList(e)}
                      loadOptions={handleCategoryList}
                      placeholder="Category"
                    />

                    {productErrorObj.category ? (
                      <div className="errorMsgTooltipThumb">
                        <div className="errorMsgTooltipWrapper">
                          <img
                            src={errormsgicon}
                            alt="error msg icon"
                            width="16px"
                            height="16px"
                          />
                          <div className="errorMsgTooltipWrapperDropDown">
                            <div className="errorMsgTooltipWrapper">
                              <img
                                src={tooltipDownIcon}
                                alt="arrow icon"
                                className="tooltipUpwardIcon"
                              />
                              <p className="tooltipMsg">Not found</p>
                            </div>
                          </div>
                        </div>
                      </div>
                    ) : null}
                  </div>
                </Form.Group>
              </Col>
              <Col lg={2} xs={6} className="customColumn customSeprator">
                <Form.Group className={classes.formGroup}>
                  <Form.Label className={classes.customer_title}>
                    MRP
                  </Form.Label>
                  <Form.Control
                    className={classes.customInput}
                    type="number"
                    name="MRP"
                    min={1}
                    defaultValue={product.MRP}
                    onChange={handleUpdateNewProducts}
                  />
                </Form.Group>
              </Col>
              <Col lg={2} xs={6} className="customColumn customSeprator">
                <Form.Group className={classes.formGroup}>
                  <Form.Label className={classes.customer_title}>
                    HSN Code
                  </Form.Label>
                  <div className="tooltipMsg-container">
                    <AsyncSelect
                      components={{ Input: CustomInput }}
                      styles={customStyles}
                      className={`${classes.customSelect} ${
                        productErrorObj.hsnCode
                          ? "error-input"
                          : !productErrorObj.hsnCode &&
                            productErrorObj?.hsnCode?.length > 0
                          ? "warning-input"
                          : ""
                      }`}
                      type="number"
                      value={{
                        value: hsnCodeValue,
                        label: product["HSN code"],
                      }}
                      onChange={(e) => getAddNewHsnCodeList(e)}
                      loadOptions={handleHsnCodeList}
                      placeholder="HSN Code"
                    />

                    {productErrorObj.hsnCode ? (
                      <div className="errorMsgTooltipThumb">
                        <div className="errorMsgTooltipWrapper">
                          <img
                            src={errormsgicon}
                            alt="error msg icon"
                            width="16px"
                            height="16px"
                          />
                          <div className="errorMsgTooltipWrapperDropDown">
                            <div className="errorMsgTooltipWrapper">
                              <img
                                src={tooltipDownIcon}
                                alt="arrow icon"
                                className="tooltipUpwardIcon"
                              />
                              <p className="tooltipMsg">Not found</p>
                            </div>
                          </div>
                        </div>
                      </div>
                    ) : null}
                  </div>
                </Form.Group>
              </Col>
              <Col lg={2} xs={6} className="customColumn customSeprator">
                <Form.Group className={classes.formGroup}>
                  <Form.Label className={classes.customLabel}>Unit</Form.Label>
                  <div className={classes.customSelectCnt}>
                    <Form.Control
                      // className={classes.customSelect}
                      className={`${classes.customSelect} ${
                        productErrorObj.unit
                          ? "error-input"
                          : !productErrorObj.unit &&
                            productErrorObj?.unit?.length > 0
                          ? "warning-input"
                          : ""
                      }`}
                      as="select"
                      custom
                      name="Unit"
                      defaultValue={product.Unit}
                      onChange={handleUpdateNewProducts}
                    >
                      {quantityTypes.map((type) => (
                        <option key={type.value} value={type.value}>
                          {type.label}
                        </option>
                      ))}
                    </Form.Control>
                    <DownArrowIcon className={classes.selectDownArrow} />
                    {productErrorObj.unit ? (
                      <div
                        className="errorMsgTooltipThumb errorMsgTooltipThumb1"
                        style={{ marginLeft: "20px" }}
                      >
                        <div className="errorMsgTooltipWrapper">
                          <img
                            src={errormsgicon}
                            alt="error msg icon"
                            width="16px"
                            height="16px"
                          />
                          <div className="errorMsgTooltipWrapperDropDown">
                            <div className="errorMsgTooltipWrapper">
                              <img
                                src={tooltipDownIcon}
                                alt="arrow icon"
                                className="tooltipUpwardIcon"
                              />
                              <p className="tooltipMsg">Not found</p>
                            </div>
                          </div>
                        </div>
                      </div>
                    ) : null}
                  </div>
                </Form.Group>
              </Col>
            </Form.Row>
          </div>
          <ExistingProducts
            copySuccess={copySuccess}
            suggestionProduct={suggestionProduct}
            handleCopyModel={handleCopyModel}
          />
        </Modal.Body>
        <Modal.Footer>
          <button
            type="button"
            className="cancelBtn"
            onClick={handleCloseViewModal}
          >
            Cancel
          </button>
          <div>
            <button
              className="issueDeleteBtn"
              onClick={() => handleDeleteProducts()}
            >
              <img
                src={deleteIconError}
                alt="delete icon"
                width="20px"
                height="20px"
                className="mr-2"
              />
              Delete
            </button>
            <button type="button" className="UpdateBtn" onClick={updateData}>
              {submitLoading ? (
                <Spinner animation="border" role="status" size="sm">
                  <span className="sr-only">Loading...</span>
                </Spinner>
              ) : (
                "Update"
              )}
            </button>
          </div>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default ViewIssueModal;
