import React from "react";
import { Link } from "react-router-dom";
import { Table } from "react-bootstrap";
import axios from "axios";
import Constants from "../../../Config";
import moment from "moment";
import "./style.css";
const apiUrl = Constants.API_URL;
const webUrl = Constants.WEB_URL;

class CallAttemptedHistory extends React.Component {
  render() {
    return (
      <div>
        {this.props.call_attempt_history.map((item, index) => {
          let createdAT = new Date(item.created_at);
          var monthArray = [
            "January",
            "February",
            "March",
            "April",
            "May",
            "June",
            "July",
            "August",
            "September",
            "October",
            "November",
            "December",
          ];
          // let month = monthArray[getMonth()+1;

          // old code

          // const d = new Date();
          // let monthname = monthArray[d.getMonth()];
          // // return null;
          // let ampm = createdAT.getHours() >= 12 ? 'pm' : 'am';
          // let date = createdAT.getDate()+' '+monthname+' '+createdAT.getFullYear()+', '+createdAT.getHours()+':'+createdAT.getMinutes()+' '+ampm;
          // return <p key={index} className={item.call_type == 1 ? 'outGoing':'inComing'}><b>CA</b> - {item.call_attempt}, Status - {item.call_status}<br /><b>Note</b> - {item.agent_note}<br /><b>By</b> - {item.name}, <b>On</b> - {date}, <b>Status</b> - {item.current_status}</p>

          // code end

          // new code

          let month = createdAT.getMonth() + 1;
          let ampm = createdAT.getHours() >= 12 ? "pm" : "am";
          let date =
            createdAT.getDate() +
            " " +
            monthArray[month] +
            " " +
            createdAT.getFullYear() +
            ", " +
            createdAT.getHours() +
            ":" +
            createdAT.getMinutes() +
            " " +
            ampm;

          return (
            <p
              key={index}
              className={item.call_type == 1 ? "outGoing" : "inComing"}
            >
              <b>CA</b> - {item.call_attempt}, Status - {item.call_status}
              <br />
              <b>Remarks</b> - {item.agent_note},{" "}
              <b>{item.followup_date ? "followup_date - " : ""} </b>
              {item.followup_date
                ? moment.utc(item.followup_date).format("DD-MM-YYYY HH:mm")
                : ""}
              <br />
              <b>By</b> - {item.firstname}, <b>On</b> -{" "}
              {moment.utc(item.created_at).format("DD-MM-YYYY HH:mm")}
            </p>
          );

          // code end
        })}
      </div>
    );
  }
}

export default CallAttemptedHistory;
