import React from "react";
import { getUserDetails } from "../../Login/getLoginnedUserDetails";
import axios from "axios";
import { Modal, Button, Spinner, Form, Col, Alert } from "react-bootstrap";
import Constants from "../../../Config";

const apiUrl = Constants.API_URL;
const webUrl = Constants.WEB_URL;
const reload = () => window.location.reload();
class TicketRaise extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showModal: props.showModal,
      rfq_id: 0,
      ticket_type: [],
      remarks: "",
      value: [],
      is_loading: false,
      is_error: false,
      message: "",
      product_data: [],
      checkbox: "",
      activeState1: true,
      activeState2: true,
      activeState3: true,
    };
  }
  closeModal = () => {
    this.props.closeModal2();
  };

  handleChecbox = (e) => {
    this.setState({
      activeState1: false,
      activeState3: false,
    });
    const { ticket_type } = this.state;

    const checked = e.target.checked;
    const checkedValue = e.target.value;
    if (checked === true) {
      ticket_type.push(checkedValue);
      this.setState({ ticket_type: ticket_type });
    } else {
      let filteredArray = ticket_type.filter((item) => item !== checkedValue);
      this.setState({
        ticket_type: filteredArray,
      });

      if (filteredArray.length === 0) {
        this.setState({
          activeState1: true,
          activeState3: true,
        });
      }
    }
  };

  handleChecbox1 = (e) => {
    this.setState({
      activeState2: false,
      activeState3: false,
    });
    const { ticket_type } = this.state;

    const checked = e.target.checked;
    const checkedValue = e.target.value;
    if (checked === true) {
      ticket_type.push(checkedValue);
      this.setState({ ticket_type: ticket_type });
    } else {
      let filteredArray = ticket_type.filter((item) => item !== checkedValue);
      this.setState({
        ticket_type: filteredArray,
      });

      if (filteredArray.length === 0) {
        this.setState({
          activeState2: true,
          activeState3: true,
        });
      }
    }
  };

  handleChecbox2 = (e) => {
    this.setState({
      activeState1: false,
      activeState2: false,
    });
    const { ticket_type } = this.state;

    const checked = e.target.checked;
    const checkedValue = e.target.value;
    if (checked === true) {
      ticket_type.push(checkedValue);
      this.setState({ ticket_type: ticket_type });
    } else {
      let filteredArray = ticket_type.filter((item) => item !== checkedValue);
      this.setState({
        ticket_type: filteredArray,
      });

      if (filteredArray.length === 0) {
        this.setState({
          activeState2: true,
          activeState1: true,
        });
      }
    }
  };

  componentDidMount() {
    this.props.fromLead ? this.getLeadDetail() : this.getQuoteDetail();
  }

  getQuoteDetail = () => {
    this.setState(
      {
        is_loading: true,
      },
      () => {
        const options = {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${this.props.getUserDetails.token}`,
          },

          data: {
            request_quote_id: this.props.requestQuoteID,
            logined_user_id: [this.props.getUserDetails.user_id],
          },
        };
        return axios(apiUrl + "/get-quote-request", options)
          .then((data) => {
            let requestQuoteData = data.data.data[0];

            this.setState({
              rfq_id: requestQuoteData.id,
              is_loading: false,
              product_data: requestQuoteData.product,
            });
          })
          .catch((error) => console.log(error));
      }
    );
  };
  getLeadDetail = () => {
    this.setState(
      {
        isLoading: true,
      },
      () => {
        // let loginedUserDetails = JSON.parse(localStorage.getItem("userData"));
        const requestoption = {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${this.props.getUserDetails.token}`,
          },
          data: {
            lead_id: this.props.leadId,
          },
        };
        return axios(apiUrl + "/lead-list", requestoption).then((data) => {
          let leadData = data.data.data[0];
          this.setState({
            rfq_id: leadData.leadId,
            is_loading: false,
            product_data: leadData.product_data,
          });
        });
      }
    );
  };

  handleSubmit = (e) => {
    const ticket_remarks = e.target.remarks.value;
    e.preventDefault();
    this.setState({
      is_loading: true,
    });
    const { rfq_id, ticket_type } = this.state;

    axios
      .post(
        apiUrl + "/create-ticket",
        {
          login_id: JSON.parse(localStorage.getItem("userData")).user_id,
          rfq_id: rfq_id,
          ticket_type: ticket_type,
          ticket_remarks: ticket_remarks,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${this.props.getUserDetails.token}`,
          },
        }
      )
      .then((res) => {
        this.setState(
          {
            message: "Ticket Created Successfully!",
            is_error: false,
            is_loading: false,
            showModal: false,
          },
          () => {
            setTimeout(() => {
              this.setState({
                message: "",
                is_error: false,
                is_redirect: true,
              });
            }, 3000);
          }
        );
      })
      .catch((error) => {
        this.setState(
          {
            message: error.response.data.message,
            is_error: true,
            is_loading: false,
          },
          () => {
            setTimeout(() => {
              this.setState({
                message: "",
                is_error: false,
              });
            }, 5000);
          }
        );
      });
  };
  handleSubmitLead = (e) => {
    const ticket_remarks = e.target.remarks.value;
    e.preventDefault();
    this.setState({
      is_loading: true,
    });
    const { rfq_id, ticket_type } = this.state;

    axios
      .post(
        apiUrl + "/create-ticket",
        {
          login_id: JSON.parse(localStorage.getItem("userData")).user_id,
          rfq_id: rfq_id,
          ticket_type: ticket_type,
          ticket_remarks: ticket_remarks,
          type: "LEADS",
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${this.props.getUserDetails.token}`,
          },
        }
      )
      .then((res) => {
        this.setState(
          {
            message: "Ticket Created Successfully!",
            is_error: false,
            is_loading: false,
            showModal: false,
          },
          () => {
            setTimeout(() => {
              this.setState({
                message: "",
                is_error: false,
                is_redirect: true,
              });
            }, 3000);
          }
        );
      })
      .catch((error) => {
        this.setState(
          {
            message: error.response.data.message,
            is_error: true,
            is_loading: false,
          },
          () => {
            setTimeout(() => {
              this.setState({
                message: "",
                is_error: false,
              });
            }, 5000);
          }
        );
      });
  };

  render() {
    var productData;
    return (
      <>
        <Modal
          className="business_process_wrapper ticket_modal"
          show={this.state.showModal}
          onHide={this.closeModal}
          onExit={reload}
          size="md"
          centered
        >
          <Form
            onSubmit={
              !this.props.fromLead ? this.handleSubmit : this.handleSubmitLead
            }
          >
            <Modal.Header closeButton></Modal.Header>
            <Modal.Body>
                {this.state.message.length > 0 ? (
                  <Alert
                    className="displayMessageArea position-static"
                    variant={this.state.is_error ? "danger" : "success"}
                  >
                    {this.state.message}
                  </Alert>
                ) : null}
                <Form.Row>
                  <Col lg={4}>
                    <Form.Group className="d-flex" controlId="formBasicEmail">
                      <input
                        type="checkbox"
                        id="Price"
                        value="Price"
                        disabled={!this.state.activeState1}
                        onClick={this.handleChecbox1}
                      />
                      <Form.Label className="ml-1" for="price">
                        Price
                      </Form.Label>
                    </Form.Group>
                  </Col>
                  <Col lg={4}>
                    <Form.Group className="d-flex" controlId="formBasicEmail">
                      <input
                        type="checkbox"
                        id="Price"
                        value="Stock"
                        disabled={!this.state.activeState1}
                        onClick={this.handleChecbox1}
                      />
                      <Form.Label className="mb-0 ml-1" for="stock">
                        Stock
                      </Form.Label>
                    </Form.Group>
                  </Col>
                  <Col lg={4}>
                    <Form.Group className="d-flex" controlId="formBasicEmail">
                      <input
                        type="checkbox"
                        id="Price"
                        value="Tech Query"
                        disabled={!this.state.activeState1}
                        onClick={this.handleChecbox1}
                      />
                      <Form.Label className="mb-0 ml-1" for="techquery">
                        Tech Query
                      </Form.Label>
                    </Form.Group>
                  </Col>
                  <Col lg={4}> 
                    <Form.Group className="d-flex" controlId="formBasicEmail">
                      <input
                        type="checkbox"
                        id="UpdateTP"
                        value="Update TP"
                        disabled={!this.state.activeState2}
                        onClick={this.handleChecbox}
                        check=""
                      />
                      <Form.Label className="mb-0 ml-1" for="price">
                        Update TP
                      </Form.Label>
                    </Form.Group>
                  </Col>
                  <Col lg={4}>
                    <Form.Group className="d-flex" controlId="formBasicEmail">
                      <input
                        type="checkbox"
                        id="UpdateStock"
                        value="Update Stock"
                        disabled={!this.state.activeState3}
                        onClick={this.handleChecbox2}
                        check=""
                      />
                      <Form.Label className="mb-0 ml-1" for="price">
                        Update Stock
                      </Form.Label>
                    </Form.Group>
                  </Col>
                </Form.Row>
                <Form.Row>
                  <Col lg={12}>
                  <Form.Label>Remarks</Form.Label>
                  <textarea
                    className="form-control"
                    id="exampleFormControlTextarea1"
                    rows="2"
                    name="remarks"
                    defaultValue={
                      this.props.fromLead
                        ? this.state.product_data
                        : this.state.product_data.map((data, ind) => {
                          return (
                            "Name:" +
                            " " +
                            data.productName +
                            " || " +
                            "Model:" +
                            " " +
                            data.model +
                            " || " +
                            "Quantity:" +
                            " " +
                            data.productQuantity +
                            " || " +
                            "Location:" +
                            " "
                          );
                        })
                    }
                  ></textarea>
                  </Col>
                </Form.Row>
              </Modal.Body>
            <Modal.Footer>
              <Button
                variant="primary"
                type="submit"
                disabled={this.state.is_loading ? true : false}
              >
                {this.state.is_loading ? (
                  <Spinner animation="border" role="status">
                    <span className="sr-only">Loading...</span>
                  </Spinner>
                ) : (
                  "Create Ticket"
                )}
              </Button>
            </Modal.Footer>
          </Form>
        </Modal>
      </>
    );
  }
}
export default TicketRaise;
