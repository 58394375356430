import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {Link} from 'react-router-dom';
import InnerBredcrum from './InnerBredcrum';
class Bredcrum extends React.Component {
    
    checkPage = (pageType) => {     
            
        switch(pageType){
            case 'createQuote':
                return <InnerBredcrum pageName="Add Quote" message="Hey User, welcome to create quote page!" />

            case 'listQuote':
                return <InnerBredcrum pageName="Quote List" message="Hey User, welcome to all quotes page!" />
                
            case 'editQuote':
                return <InnerBredcrum pageName="Edit Quote" message="Hey User, welcome to edit quote page!" />
                
            case 'business-accounts':
                return <InnerBredcrum pageName="All Pro Accounts" message="Hey User, This is the list of all Pro Accounts!" />
                
            case 'dashboard':
                return <InnerBredcrum pageName="Dashboard" message="Hey User, welcome to dashboard!" />
                
            case 'profile':
                return <InnerBredcrum pageName="Profile" message="Hey User, Welcome to profile!" />
                
            // case 'quote-requests':
            //     return <InnerBredcrum pageName="RFQ" message="Hey User, Welcome to RFQ!" />
                
            case 'quote-requests-bulk':
                return <InnerBredcrum pageName="RFQ BULK" message="Hey User, Welcome to RFQ BULK!" />
                
            case 'quote-requests-tech':
                return <InnerBredcrum pageName="RFQ TECH" message="Hey User, Welcome to RFQ TECH!" />
                
            case 'quote-requests-promo':
                return <InnerBredcrum pageName="RFQ PROMO" message="Hey User, Welcome to RFQ PROMO!" />
                
            default:
        }
    }
    
    render() {
        return  <div className="page-header">
                    { this.checkPage(this.props.pageType) }
                </div>;
        }
}


export default Bredcrum;