import React, { useState } from "react";
import { Row, Col, Form } from "react-bootstrap";
import dropdown_Icon from "../../../asset/Images/dropdown-Icon.svg";
import notExistsGreen from "../../../asset/Images/notExistsGreen.svg";
import notExistsRed from "../../../asset/Images/notExistsRed.svg";
import ErrorHandling from "./ErrorHandling";

const BusinessMobile = (props) => {
  const {
    customer_company,
    handleCompany,
    showB2B,
    legalStatus_id,
    LegalStatus,
    slug,
    checkSlugExists,
    gstin,
    handleGstChange,
    seeAllAddress,
    gstin_applied,
    handleRadioChange,
    gstDisable,
    proChildAccounts,
    handleProfile,
    pan_card,
    pricing_type,
    proPricing,
    agentid,
    getAgentByID,
    agentArray,
    pin_code,
    handleChangePincode,
    billing_zone,
    billing_city,
    handlechange,
    address_1,
    addressChange1,
    address_2,
    pro_pricing_type,
    slug_exists,
    wrong_pin,
    legalStatus,
    seeProChildAccounts,
    addressChange2,
    handleNextStep,
    handleBackStep,
    childState
  } = props;

  const [showBusinessCard, setShowBusinessCard] = useState(childState);
  const [errorMessage, setErrorMessage] = useState("");

  const handleNextPage = () => {
    if (!agentid) {
      setErrorMessage("Please Fill Agent Name");
    } else if (!customer_company) {
      setErrorMessage("Please Fill Business Name");
    } else if (!pricing_type) {
      setErrorMessage("Please Fill Source of Information");
    } 
    else {
      setShowBusinessCard(showBusinessCard + 1);
      if (showBusinessCard + 1 === 2) {
        handleNextStep();
      }
    }
    setTimeout(() => {
      setErrorMessage("");
    }, 6000);
  };

  const handlePreviousPage = () => {
    setShowBusinessCard(showBusinessCard - 1);
    if (showBusinessCard - 1 === -1) {
      handleBackStep();
    }
  };
  

  return (
    <>
      {errorMessage.length > 0 ? (
        <ErrorHandling message={errorMessage} type="ErrorMessage" />
      ) : null}
      {showBusinessCard === 0 ? (
        <div className="customCard addAddressCardMobile">
          <div className="formstepCnt">
            <span className="steps">2 of 7</span>
          </div>
          <h3 className="customCardheading">Business Details</h3>
          <Row>
            <Col md="4">
              <Form.Group
                controlId="formGridCustomerName"
                className="customGroup"
              >
                <Form.Label className="customLabel">
                  Business Name
                  <span class="mandatoryIcon">*</span>
                </Form.Label>
                <Form.Control
                  className="customInput"
                  name="customer_company"
                  type="text"
                  defaultValue={customer_company}
                  onChange={handleCompany}
                  required={showB2B}
                />
              </Form.Group>
            </Col>
            <Col md="4">
              <Form.Group controlId="formBasicEmail" className="customGroup">
                <Form.Label className="customLabel">Legal Status</Form.Label>
                <div className="customSelectCnt">
                  <Form.Control
                    className="customSelect"
                    as="select"
                    custom
                    name="legal_status"
                    value={legalStatus_id}
                    onChange={LegalStatus}
                    // required={showB2B}
                  >
                    <option value="">Select Legal Status</option>
                    {legalStatus &&
                      legalStatus.map((item, index) => {
                        return (
                          <option key={index} value={item.id}>
                            {item.meta_value}
                          </option>
                        );
                      })}
                  </Form.Control>
                  <img
                    src={dropdown_Icon}
                    className="dropdownIcon"
                    width="16px"
                    height="16px"
                  />
                </div>
              </Form.Group>
            </Col>
            <Col md="4">
              <Form.Group
                controlId="formGridCustomerName"
                className="customGroup"
              >
                <Form.Label className="customLabel">Slug</Form.Label>
                <div className="slugInputWrapper">
                  <Form.Control
                    className="customInput"
                    placeholder=""
                    name="slug"
                    value={slug}
                    onChange={checkSlugExists}
                    required={showB2B}
                  />
                  {!customer_company ? null : (
                    <>
                      {slug_exists ? (
                        <div className="notExistsIconArea">
                          <img src={notExistsGreen} />
                        </div>
                      ) : (
                        <div className="notExistsIconArea">
                          <img src={notExistsRed} />
                        </div>
                      )}
                    </>
                  )}
                </div>
              </Form.Group>
            </Col>
            <Col md="3">
              <Form.Group controlId="formBasicEmail" className="customGroup">
                <Form.Label className="customLabel">GST Number</Form.Label>
                <div className="gstWrapper">
                  <Form.Control
                    // className={!gstError ? "error" : ""}
                    className="customInput gstCustomInput"
                    placeholder="GST Number"
                    name="gstin"
                    value={gstin}
                    onChange={handleGstChange}
                    // required={
                    //   profile_type == "B2B" &&
                    //   !gstin_applied
                    // }
                  />
                  <button
                    onClick={seeAllAddress}
                    disabled={gstin.length < 15}
                    className={`customBtn  allAddBtn ${
                      gstin.length < 15 ? "" : "active"
                    }`}
                  >
                    View address
                  </button>
                </div>
                <div className="gstExtAccotCnt">
                  <Form.Check
                    type="checkbox"
                    label="GST Applied"
                    name="gstin_applied"
                    checked={gstin_applied}
                    onChange={handleRadioChange}
                    value={gstin_applied}
                    disabled={gstDisable}
                    className="gstAppliedbtn customCheckbox"
                    id="gsApplied"
                  />

                  {proChildAccounts.length > 0 ? (
                    <button
                      onClick={seeProChildAccounts}
                      className="customBtn extAcctbtn"
                    >
                      Existing Account
                    </button>
                  ) : null}
                </div>
              </Form.Group>
            </Col>
            <Col md="3">
              <Form.Group className="customGroup">
                <Form.Label className="customLabel">PAN</Form.Label>
                <Form.Control
                  className="customInput disabled"
                  placeholder="PAN"
                  name="pan_card"
                  onChange={handleProfile}
                  value={pan_card}
                  disabled
                />
              </Form.Group>
            </Col>
            <Col md="3">
              <Form.Group controlId="formBasicbusiness" className="customGroup">
                <Form.Label className="customLabel">
                  Source of Information
                  <span class="mandatoryIcon">*</span>
                </Form.Label>
                <div className="customSelectCnt">
                  <Form.Control
                    className="customSelect"
                    as="select"
                    custom
                    name="pricing_type"
                    value={pricing_type}
                    onChange={proPricing}
                    required
                  >
                    <option value="">Select</option>
                    {pro_pricing_type &&
                      pro_pricing_type.map((item, index) => {
                        return (
                          <option key={item.id} value={item.meta_value}>
                            {item.meta_title}
                          </option>
                        );
                      })}
                  </Form.Control>
                  <img
                    src={dropdown_Icon}
                    className="dropdownIcon"
                    width="16px"
                    height="16px"
                  />
                </div>
              </Form.Group>
            </Col>
            <Col md="3">
              <Form.Group
                controlId="formGridCustomerName"
                className="customGroup"
              >
                <Form.Label className="customLabel">
                  Agent
                  <span class="mandatoryIcon">*</span>
                </Form.Label>
                <div className="customSelectCnt">
                  <Form.Control
                    className="customSelect"
                    as="select"
                    custom
                    name="legal_status"
                    value={agentid}
                    onChange={getAgentByID}
                    // required={required_state}
                    required
                    >
                    <option value="">Select</option>
                    {agentArray &&
                      agentArray.map((item) => {
                        return item.id ? (
                          <option key={item.id} value={item.id}>
                            {item.name}
                          </option>
                        ) : null;
                      })}
                  </Form.Control>
                  <img
                    src={dropdown_Icon}
                    className="dropdownIcon"
                    width="16px"
                    height="16px"
                  />
                </div>
              </Form.Group>
            </Col>
          </Row>
          <div className="addAccountFooterCntMobile">
            <button
              type="button"
              className="steeperPrevBtn"
              onClick={handlePreviousPage}
            >
              Previous
            </button>
            <button
              type="submit"
              className="steeperNextBtn"
              onClick={handleNextPage}
            >
              Next
            </button>
          </div>
        </div>
      ) : null}

      {showBusinessCard === 1 ? (
        <>
          <div className="customCard addAddressCardMobile">
            <div className="formstepCnt">
              <span className="steps">3 of 7</span>
            </div>
            <h3 className="customCardheading">Business Detials</h3>
          </div>
          <Row>
            <Col md="4">
              <Form.Group
                controlId="formGridCustomerName"
                className="customGroup"
              >
                <Form.Label className="customLabel">Pin Code</Form.Label>
                <Form.Control
                  className={wrong_pin ? "error" : "customInput"}
                  name="pin_code"
                  type="number"
                  value={pin_code}
                  onChange={handleChangePincode}
                  placeholder="6 Digit pincode"
                  // required={showB2B}
                />
              </Form.Group>
            </Col>
            <Col md="4">
              <Form.Group
                controlId="formGridCustomerName"
                className="customGroup"
              >
                <Form.Label className="customLabel">State</Form.Label>
                <Form.Control
                  name="state_name"
                  className="customInput"
                  type="text"
                  placeholder="Select"
                  value={billing_zone}
                  // required={showB2B}
                />
              </Form.Group>
            </Col>
            <Col md="4">
              <Form.Group
                controlId="formGridCustomerName"
                className="customGroup"
              >
                <Form.Label className="customLabel">City</Form.Label>
                <div className="customSelectCnt">
                  <Form.Control
                    name="city"
                    type="text"
                    className="customInput"
                    placeholder="Select"
                    value={billing_city}
                    onChange={handlechange}
                    // required={showB2B}
                  />
                </div>
              </Form.Group>
            </Col>
            <Col md="6">
              <Form.Group
                controlId="formGridCustomerName"
                className="customGroup"
              >
                <Form.Label className="customLabel">Address1</Form.Label>
                <Form.Control
                  name="customer_address"
                  type="text"
                  className="customInput"
                  placeholder="Flat, House no., Building, Apartment"
                  value={address_1}
                  onChange={addressChange1}
                  // required={showB2B}
                />
              </Form.Group>
            </Col>
            <Col md="6">
              <Form.Group
                controlId="formGridCustomerName"
                className="customGroup"
              >
                <Form.Label className="customLabel">Address2</Form.Label>
                <Form.Control
                  name="customer_address"
                  type="text"
                  className="customInput"
                  placeholder="Area, Street, Sector"
                  value={address_2}
                  onChange={addressChange2}
                  // required={showB2B}
                />
              </Form.Group>
            </Col>
          </Row>
          <div className="addAccountFooterCntMobile">
            <button
              type="button"
              className="steeperPrevBtn"
              onClick={handlePreviousPage}
            >
              Previous
            </button>
            <button
              type="submit"
              className="steeperNextBtn"
              onClick={handleNextPage}
            >
              Next
            </button>
          </div>
        </>
      ) : null}
    </>
  );
};

export default BusinessMobile;
