import React, { useState } from "react";
import DateRangePicker from "react-bootstrap-daterangepicker";
import moment from "moment";
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap-daterangepicker/daterangepicker.css";

const DateRangeNew = (props) => {
  const { setDateRange, dateAdded } = props;
  const [state, setState] = useState({
    start: moment().subtract(
      props.defaultDays ? props.defaultDays : new Date().getFullYear() - 2024,
      "years"
    ),
    end: moment(),
    isDateSelected: false,
  });
  const { start, end, isDateSelected } = state;
  const handleCallback = (start, end) => {
    setState({ start, end, isDateSelected: true });
    const start_date = moment(start).format("YYYY-M-D");
    const end_date = moment(end).format("YYYY-M-D");
    // if (moment(start).year() == "2018") {
    //   setDateRange({ start: "", end: "" });
    // } else {
    //   setDateRange({ start: start_date, end: end_date });
    // }
    setDateRange({ start: start_date, end: end_date });
  };
   const label = isDateSelected
    ? `${start.format("D MMM YYYY")} - ${end.format("D MMM YYYY")}`
    : "Filter Date";

  return (
    <>
      <DateRangePicker
        initialSettings={{
          startDate: start.toDate(),
          endDate: end.toDate(),
          ranges: {
            // "Select All": [start.toDate(), ""],
            "Select All": [
              moment().subtract(new Date().getFullYear() - 2023, "years").toDate(),
              moment().toDate(),
            ],
            Today: [moment().toDate(), moment().toDate()],
            Yesterday: [
              moment().subtract(1, "days").toDate(),
              moment().subtract(1, "days").toDate(),
            ],
            "Last 7 Days": [
              moment().subtract(6, "days").toDate(),
              moment().toDate(),
            ],
            "Last 30 Days": [
              moment().subtract(29, "days").toDate(),
              moment().toDate(),
            ],
            "This Month": [
              moment().startOf("month").toDate(),
              moment().endOf("month").toDate(),
            ],
            "Last Month": [
              moment().subtract(1, "month").startOf("month").toDate(),
              moment().subtract(1, "month").endOf("month").toDate(),
            ],
          },
        }}
        onCallback={handleCallback}
      >
        <div>
          <span className="listingFontSizeColor">{label}</span>
        </div>
      </DateRangePicker>
    </>
  );
};
export default DateRangeNew;
