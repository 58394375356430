import React from "react";
import { Col, Form, Button } from "react-bootstrap";
import AsyncSelect from "react-select/async";
import DeleteIcon from "../../../asset/Images/delete icon.svg";
import removeIcon from "../../../asset/Images/removeIcon.svg";
import classes from "./CreateSource.module.css";
import { ReactComponent as SearchIcon } from "../../../asset/Images/search.svg";
import { components } from "react-select";
import { ReactComponent as DownArrowIcon } from "../../../asset/Images/RightArrow.svg";

const CustomInput = (props) => (
  <div style={{ display: "flex", alignItems: "center", height: "100%" }}>
    <SearchIcon style={{ marginRight: "4px" }} />
    <components.Input {...props} />
  </div>
);

const customStyles = {
  control: (provided, state) => ({
    ...provided,
    backgroundColor: "transparent",
    border: "0",
    borderColor: state.isFocused ? "none" : "none",
    "&:hover": {
      borderColor: state.isFocused ? "none" : "none",
    },
    boxShadow: state.isFocused ? "none" : "none",
    minHeight: "auto",
    height: "100%"
  }),
  valueContainer: (provided) => ({
    ...provided,
    height: "100%",
    padding: "0"
  }),
  placeholder: (provided) => ({
    ...provided,
    color: "#99ABBE",
    fontSize: "13px"
  }),
  singleValue: (provided) => ({
    ...provided,
    color: "#1E293B",
    padding: "0 0 0 20px",
    fontSize: "13px"
  }),
  dropdownIndicator: (provided) => ({
    ...provided,
    display: "none",
  }),
  indicatorSeparator: (provided) => ({
    ...provided,
    display: "none",
  }),
};

const SourcingProductListing = (props) => {
  const {
    access,
    status,
    showReOpen,
    ListHeading,
    RemarksHeading,
    sourcingRemarks,
    disabled,
    handleFormChange,
    products,
    productName,
    getProductName,
    handleProductList,
    handleChange,
    vendorNameLocation,
    getVendorLocation,
    handleVendorLocation,
    handleSelectChooseType,
    removeItemShowSourcingData,
    type,
    addItem,
    handleShowModal,
    handleShowVendor,
    maxLength,
  } = props;

  const CustomMenuList = (props) => {
    const hasOptions = props.children.length > 0;
    return (
      <components.MenuList {...props}>
        {hasOptions ? (
          props.children
        ) : (
          <>
            {access && ["pending", "new", "reopen"].includes(status) ? (
              <div style={{ backgroundColor: "transparent" }}>
                <Button
                  onClick={handleShowModal}
                  className={classes.addNewProdBtn}
                >
                  Add New Product
                </Button>
              </div>
            ) : null}
          </>
        )}
      </components.MenuList>
    );
  };
  const CustomMenuVendorList = (props, index) => {
    const hasOptions = props.children.length > 0;
    return (
      <components.MenuList {...props}>
        {hasOptions ? (
          props.children
        ) : (
          <>
            {access && ["pending", "new", "reopen"].includes(status) ? (
              <div style={{ backgroundColor: "transparent" }}>
                <Button
                  onClick={(e) => handleShowVendor(e, index)}
                  className={classes.addNewProdBtn}
                >
                  Add New Vendor
                </Button>
              </div>
            ) : null}
          </>
        )}
      </components.MenuList>
    );
  };
  return (
    <Form noValidate>
      <div className={`${classes.requirement_detail_wrapper}`}>
        <h3 className={classes.requirement_detail_heading}>{ListHeading}</h3>
        <hr className={classes.dottedBorder} />
        {products.map((item, index) => (
          <React.Fragment key={index}>
            <Form.Row className={classes.requirement_items}>
              <Col lg={disabled ? 4 : 3} xs={12} className={classes.verticalCenter}>
                {disabled ? (
                  <Form.Group className={classes.formGroup}>
                    <Form.Label className={classes.customLabel}>
                      Name
                    </Form.Label>
                    <p className={classes.labelreadContent}>{item.name}</p>
                  </Form.Group>
                ) : (
                  <Form.Group className={classes.formGroup}>
                    <Form.Label className={classes.customLabel}>
                      Name
                      <span className={classes.mandatory}>*</span>
                    </Form.Label>
                    <span data-toggle="tooltip" title={item.name}>
                      <AsyncSelect
                        components={{
                          Input: CustomInput,
                          MenuList: CustomMenuList,
                        }}
                        className={classes.customSelect}
                        styles={customStyles}
                        value={{
                          value: productName,
                          label: item.name,
                        }}
                        onChange={(e) => {
                          getProductName(e, index, type);
                        }}
                        loadOptions={handleProductList}
                        placeholder="Search an Item"
                        isDisabled={disabled}
                      />
                    </span>
                  </Form.Group>
                )}
                <hr className={classes.customeSeprator} />
              </Col>
              <Col lg={1} xs={4} className={classes.verticalCenter}>
                <Form.Group className={classes.formGroup}>
                  <Form.Label className={classes.customLabel}>
                    Quantity
                  </Form.Label>
                  {disabled ? (
                    <Form.Group className={classes.formGroup}>
                      <p className={classes.labelreadContent}>
                        {item.quantity}
                      </p>
                    </Form.Group>
                  ) : (
                    <Form.Control
                      className={classes.customInput}
                      placeholder="Quantity"
                      name="quantity"
                      type="number"
                      value={item.quantity}
                      onChange={(e) => handleChange(e, index, type)}
                    />
                  )}
                </Form.Group>
                <hr className={classes.customeSeprator} />
                <hr className={classes.customeSepratorMobile} />
              </Col>
              <Col lg={1} xs={4} className={classes.verticalCenter}>
                <Form.Group className={classes.formGroup}>
                  <Form.Label className={classes.customLabel}>Unit</Form.Label>
                  {disabled ? (
                    <Form.Group className={classes.formGroup}>
                      <p className={classes.labelreadContent}>
                        {item.quantity_type}
                      </p>
                    </Form.Group>
                  ) : (
                    <Form.Control
                      className={
                        item.type === "product"
                          ? `${classes.labelreadContent} ${classes.inputDiabled}`
                          : classes.customInput
                      }
                      name="quantity_type"
                      type="text"
                      value={item.quantity_type}
                      onChange={(e) => handleChange(e, index, type)}
                      disabled={item.type === "product"}
                    />
                  )}
                </Form.Group>
                <hr className={classes.customeSeprator} />
                <hr className={classes.customeSepratorMobile} />
              </Col>
              <Col lg={1} xs={4} className={classes.verticalCenter}>
                <Form.Group className={classes.formGroup}>
                  <Form.Label className={classes.customLabel}>T.P</Form.Label>
                  {disabled ? (
                    <Form.Group className={classes.formGroup}>
                      <p className={classes.labelreadContent}>
                        {item.sales_transfer_price}
                      </p>
                    </Form.Group>
                  ) : (
                    <Form.Control
                      className={classes.customInput}
                      name="sales_transfer_price"
                      type="number"
                      value={item.sales_transfer_price}
                      onChange={(e) => handleChange(e, index, type)}
                    />
                  )}
                </Form.Group>
                <hr className={classes.customeSeprator} />
              </Col>

              {access ? (
                <Col lg={2} xs={12} className={classes.verticalCenter}>
                  {disabled ? (
                    <Form.Group className={classes.formGroup}>
                      <Form.Label
                        disabled={disabled}
                        className={classes.customLabel}
                      >
                        Vendor Name
                      </Form.Label>
                      <p className={classes.labelreadContent}>
                        {item.vendor_name}
                      </p>
                    </Form.Group>
                  ) : (
                    <Form.Group className={classes.formGroup}>
                      <Form.Label className={classes.customLabel}>
                        Vendor Name
                        <span className={classes.mandatory}>*</span>
                      </Form.Label>
                      <span data-toggle="tooltip" title={item.vendor_name}>
                        <AsyncSelect
                          components={{
                            Input: CustomInput,
                            MenuList: (e) => CustomMenuVendorList(e, index),
                          }}
                          className={classes.customSelect}
                          styles={customStyles}
                          value={{
                            value: vendorNameLocation,
                            label: item.vendor_name,
                          }}
                          onChange={(e) => {
                            getVendorLocation(e, index, type);
                          }}
                          loadOptions={(e, callback) =>
                            handleVendorLocation(e, callback, item)
                          }
                          isDisabled={disabled}
                        />
                      </span>
                    </Form.Group>
                  )}
                  <hr className={classes.customeSeprator} />
                </Col>
              ) : null}
              <Col lg={2} xs={6} className={classes.verticalCenter}>
                <Form.Group
                  className={classes.formGroup}
                  style={{ position: "relative" }}
                >
                  <Form.Label className={classes.customLabel}>
                    Availability
                    <span className={classes.mandatory}>*</span>
                  </Form.Label>
                  {disabled ? (
                    <Form.Group className={classes.formGroup}>
                      <p className={classes.labelreadContent}>
                        {item.availabilityType}
                      </p>
                    </Form.Group>
                  ) : (
                    <div className={classes.customSelectCnt}>
                      <Form.Control
                        className={classes.customSelect}
                        as="select"
                        custom
                        name="availabilityType"
                        value={item.availabilityType}
                        onChange={(e) => handleSelectChooseType(index, e, type)}
                      >
                        <option value="">Select</option>
                        <option value="Ready Stock">Ready Stock</option>
                        <option value="Lead Time">Lead Time</option>
                        <option value="Not Avl">Not Avl</option>
                      </Form.Control>
                      <DownArrowIcon className={classes.selectDownArrow} />
                    </div>
                  )}
                </Form.Group>
                <hr className={classes.customeSeprator} />
              </Col>
              <Col lg={1} xs={6} className={classes.verticalCenter}>
                <Form.Group className={classes.formGroup}>
                  <Form.Label className={classes.customLabel}>
                    In Days
                  </Form.Label>
                  {disabled ? (
                    <Form.Group className={classes.formGroup}>
                      <p className={classes.labelreadContent}>
                        {item.lead_time}
                      </p>
                    </Form.Group>
                  ) : (
                    <Form.Control
                      className={classes.customInput}
                      name="lead_time"
                      type="number"
                      value={item.lead_time}
                      onChange={(e) => handleChange(e, index, type)}
                      disabled={
                        item.availabilityType === "Ready Stock" ||
                        item.availabilityType === "Not Avl"
                      }
                    />
                  )}
                </Form.Group>
              </Col>
              {!disabled ? (
                <Col sm={1} className={classes.delete_icon_cnt}>
                  <img
                    className={classes.deleteIcon}
                    src={DeleteIcon}
                    alt="deleteIcon"
                    id={index}
                    onClick={() => removeItemShowSourcingData(index, type)}
                  />
                  <div className={classes.removeIconWrapper}>
                    <img
                      className={classes.image}
                      src={removeIcon}
                      alt="removeIcon"
                      id={index}
                      onClick={() => removeItemShowSourcingData(index, type)}
                    />
                  </div>
                </Col>
              ) : null}
            </Form.Row>
            <hr className={classes.dottedBorder} />
          </React.Fragment>
        ))}

        {access && ["pending", "new", "reopen"].includes(status) ? (
          <div>
            <button
              className={`${classes.addRowBtn}`}
              type="button"
              onClick={() =>
                addItem({
                  name: "",
                  quantity: 0,
                  sales_transfer_price: "",
                  brand: "",
                  availabilityType: "",
                  lead_time: 0,
                  product_id: 0,
                  category_id: 0,
                  vendor_id: "",
                  vendor_name: "",
                  vendor_location: "",
                })
              }
            >
              Add Row
            </button>
            <hr className={classes.dottedBorder} />
          </div>
        ) : null}
        {showReOpen ? (
          <div>
            <button
              className={`${classes.addRowBtn}`}
              type="button"
              onClick={() =>
                addItem({
                  type: "product",
                  name: "",
                  sales_transfer_price: "",
                  quantity: 0,
                  brand: "",
                  price: false,
                  availability: false,
                  product_id: 0,
                  category_id: 0,
                })
              }
            >
              Add Row
            </button>
            <hr className={classes.dottedBorder} />
          </div>
        ) : null}
        <div style={{ marginTop: "24px" }}>
          {disabled ? (
            <Form.Group className={classes.formGroup}>
              <Form.Label className={classes.remarksLabel}>
                {RemarksHeading}
              </Form.Label>
              <p className={classes.remarksReadableContent}>{sourcingRemarks}</p>
            </Form.Group>
          ) : (
            <Form.Group className="mb-0">
              <Form.Label className={`${classes.remarksLabel} pl-3`}>
                {RemarksHeading}
              </Form.Label>
              <div>
                <Form.Control
                  as="textarea"
                  rows={2}
                  name="sourcing_remarks"
                  value={sourcingRemarks}
                  onChange={handleFormChange}
                  className={`${classes.textareaRemarks} `}
                />
                <Form.Text className={classes.textCount}>
                  {sourcingRemarks.length}/{maxLength}
                </Form.Text>
              </div>
            </Form.Group>
          )}
        </div>
      </div>
    </Form>
  );
};
export default SourcingProductListing;
