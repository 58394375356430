import React from "react";
import { faIcons } from "@fortawesome/free-solid-svg-icons";
import { faLock } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import key

function Auth() {
  return (
    <div>
      {/* <h1>404 Page Not Found</h1> */}
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <h1>Sorry, You are not Authorized to view this Section</h1>
        {/* <faKey /> */}
        <FontAwesomeIcon icon={faLock} bounce size="2xl" />
      </div>
    </div>
  );
}

export default Auth;
