import React from "react";
import { getUserDetails } from "../../Login/getLoginnedUserDetails";
import { Row, Col, Form, Modal } from "react-bootstrap";
import Constants from "../../../Config";
import axios from "axios";
import { Redirect } from "react-router-dom";
import "react-datepicker/dist/react-datepicker.css";
import dropdown_Icon from "../../../asset/Images/dropdown-Icon.svg";
import notExistsGreen from "../../../asset/Images/notExistsGreen.svg";
import notExistsRed from "../../../asset/Images/notExistsRed.svg";
import searchIcon from "../../../asset/Images/searchIcon.svg";
import crossSmall from "../../../asset/Images/crossSmall.svg";
import classes from "../SourcingRequest/CreateSource.module.css";
import { DatePicker, Space } from "antd";
import BusinessMobile from "./BusinessMobile";
import ProfessionalMobile from "./ProfessionalMobile";
import AdditionalMobile from "./AdditionalMobile";
import ErrorHandling from "./ErrorHandling";

const apiUrl = Constants.API_URL;
const b2bApiUrl = Constants.B2B_API_URL;

let date = new Date();
const maxYear = date.getFullYear();

class BusinessProfile2 extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      business_type: [],
      sub_busines_type: [],
      selectedOption: null,
      business_group: "",
      customer_id: "",
      customer_name: "",
      customer_email: "",
      customer_company: "",
      slug: "",
      legalStatus: "",
      agentArray: "",
      pin_code: "",
      billing_city: "",
      billing_zone: "",
      billing_city_id: "",
      billing_state_id: "",
      zone_id: "",
      agentid: "0",
      isLoading: false,
      checkedBusinessGroup: "",
      checkedBusinessType: [],
      businessType: [],
      is_multiselect_search: false,
      address_1: "",
      address_2: "",
      slug_exists: false,
      gstapplied: 0,
      legalStatus_id: "",
      gst_number: "",
      business_parent_category_array: [],
      sub_category: [],
      selected_category_array: [],
      business_category_array: [],
      business_category_array_org: [],
      keyword: "",
      show_business_category: false,
      others: false,
      category_array: [],
      business_type_other: "",
      is_error: false,
      is_redirect: false,
      message: "",
      gstin: "",
      gstError: false,
      gstin_applied: false,
      hide: true,
      current_page: 1,
      total_item: 30,
      checkbox: "2",
      required_state: true,
      wrong_pin: false,
      pro_pricing_type: [],
      pricing_type: "",
      customerGroup: [
        { id: 1, name: "B2B" },
        { id: 2, name: "B2C" },
      ],
      showB2B: false,
      profile_type: "",
      customerGroupID: "",
      yrs_in_business: null,
      dateString: "",
      employee_count: 0,
      // annual_revenue_count: 0, // add new
      turnover: "",
      credit_limit: "",
      projects_done: "",
      credit_period: "",
      pan_card: "",
      employeeRange: [],
      annualRevenueLacs: [], // add new
      addressArray: [],
      companyAddressArray: [],
      showGstAddressPopUp: false,
      selectedAddressIndex: null,
      selectedAddress: [],
      selectedGSTAddress: {},
      proChildAccounts: [],
      showProChildAccount: false,
      selectedBusinessTypesRadio: [],
      subBusinessType: [],
      // add new four key
      utm_source: "",
      utm_medium: "",
      utm_campaign: "",
      utm_content: "",
      mediumArray: [],
      utmSourceArray: [],
      showGstErrorMessage: "",
    };
  }

  componentDidMount() {
    this.getLegalStatus();
    this.getAgent();
    this.getBusinessGroup();
    this.getBusinessParentCategory();
    this.getBusinessCategory();
    this.getBusinessAccountDetail();
    this.getProPricingType();
    this.selectCategoryArray();
    this.checkGSTValidation();
    this.getEmployeeRange();
    this.getAnnualRevenue(); // add new
    this.getUtmSourceApiData(); // add new api calling
    this.getUtmMediumApiData(); // add new api calling
  }
  disabledDate = (current) => {
    // Disable dates beyond the maximum year
    return current.year() > maxYear;
  };
  //Debounce Method for Query OPtimization

  // debounce = (cb, delay = 700) => {
  //   let timeout;
  //  return (...args) => {
  //   clearTimeout(timeout)

  //  timeout =  setTimeout(() => {
  //     cb(...args)
  //   }, delay)
  //  }
  // }

  LegalStatus = (e) => {
    this.setState({
      legalStatus_id: e.target.value,
    });
    this.props.legal_status2(e.target.value);
  };
  proPricing = (e) => {
    this.setState(
      {
        pricing_type: e.target.value,
      },
      () => {
        this.props.getPricingtype(this.state.pricing_type);
      }
    );
  };
  utmSource = (e) => {
    this.setState(
      {
        utm_source: e.target.value,
      },
      () => {
        this.props.getUTMSource(this.state.utm_source);
      }
    );
  };
  utmMedium = (e) => {
    this.setState(
      {
        utm_medium: e.target.value,
      },
      () => {
        this.props.getUTMMedium(this.state.utm_medium);
      }
    );
  };
  handleCompany = (e) => {
    this.setState({
      customer_company: e.target.value,
    });
    this.props.customer_company2(e.target.value);
    this.createSlug(e.target.value);
  };
  createSlug = (val) => {
    var str = val
      .toLowerCase()
      .replace(/[^a-z0-9\s]/g, "")
      .replace(/\s+/g, "-");
    // var replaced = str.split(" ").join("-");
    var replaced = str;
    this.setState(
      {
        slug: replaced,
      },
      () => {
        setTimeout(() => {
          this.checkCompanyAlreadyExists();
        }, 100);
      }
    );
    this.props.slug2(replaced);
  };
  getEmployeeRange = () => {
    const options = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${getUserDetails?.token}`,
      },

      data: { meta_id: 30 },
    };
    return axios(apiUrl + "/meta-value", options)
      .then((data) => {
        let employeeRange = data.data.data;
        // let newEmployeerange = employeeRange.sort((a, b) => {
        //   const [startA, endA] = a.meta_title.split("-").map(Number);
        //   const [startB, endB] = b.meta_title.split("-").map(Number);

        //   return startA - startB || endA - endB;
        // });
        this.setState({
          employeeRange,
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };
  // new function start here
  getAnnualRevenue = () => {
    const options = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${getUserDetails?.token}`,
      },

      data: { meta_id: 10 },
    };
    return axios(apiUrl + "/meta-value", options)
      .then((data) => {
        let annualRevenueLacs = data.data.data;
        // let newEmployeerange = annualRevenueLacs.sort((a, b) => {
        //   const [startA, endA] = a.meta_title.split("-").map(Number);
        //   const [startB, endB] = b.meta_title.split("-").map(Number);

        //   return startA - startB || endA - endB;
        // });
        this.setState({
          annualRevenueLacs,
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };
  // new function ends here
  getUtmSourceApiData = () => {
    const options = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${getUserDetails?.token}`,
      },
      data: {
        meta_id: 28,
      },
    };
    return axios(apiUrl + "/meta-value", options)
      .then((data) => {
        let utmSourceArray = data.data.data;
        this.setState({
          utmSourceArray,
        });
      })
      .catch((err) => console.log(err));
  };
  getUtmMediumApiData = () => {
    const options = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${getUserDetails?.token}`,
      },
      data: {
        meta_id: 29,
      },
    };
    return axios(apiUrl + "/meta-value", options)
      .then((data) => {
        let mediumArray = data.data.data;
        this.setState({
          mediumArray,
        });
      })
      .catch((err) => console.log(err));
  };
  addressChange1 = (e) => {
    this.setState({
      address_1: e.target.value,
    });
    this.props.customer_address1(e.target.value);
  };

  addressChange2 = (e) => {
    this.setState({
      address_2: e.target.value,
    });
    this.props.customer_address2(e.target.value);
  };

  handleGstChange = (e) => {
    if (e.target.value.length == 0) {
      this.setState({
        pan_card: "",
      });
    }
    if (e.target.value.length == 15) {
      this.gstValidate(e.target.value);
    }
    this.setState(
      {
        [e.target.name]: e.target.value,
        gstin_applied: false,
        gstin: e.target.value,
      },
      () => {
        this.validateGSTNumber(this.state.gstin);
        // this.gstValidate(this.state.gstin);   //Call this using debouncing
        this.props.gstnumber2(this.state.gstin);
        this.props.gstapplied2(this.state.gstin_applied);
      }
    );
  };
  // gstDebounce=  this.debounce(this.handleGstChange)
  // onGstChange = (e) => {
  //   this.gstDebounce(e.target.value)
  // }

  getAgentByID = (e) => {
    this.setState(
      {
        agentid: e.target.value,
      },
      () => {
        this.props.agent2(this.state.agentid);
      }
    );
  };

  // handleRadioChange = (e) => {
  //   if (e.target.checked) {
  //     this.setState(
  //       {
  //         gstin: "",
  //         gstError: true,
  //         gstin_applied: e.target.checked,
  //         pan_card: "",
  //       },
  //       () => {
  //         this.props.getPancard(this.state.pan_card);
  //         this.props.gstnumber2(this.state.gstin);
  //         this.props.gstapplied2(this.state.gstin_applied);
  //       }
  //     );
  //     this.props.gstError2({
  //       gstError: true,
  //     });
  //   }
  // };
  handleRadioChange = (e) => {
    const isChecked = e.target.checked;
    this.setState(
      {
        gstin: isChecked ? "" : this.state.gstin,
        gstError: isChecked,
        gstin_applied: isChecked,
        pan_card: isChecked ? "" : this.state.pan_card,
      },
      () => {
        this.props.getPancard(this.state.pan_card);
        this.props.gstnumber2(this.state.gstin);
        this.props.gstapplied2(this.state.gstin_applied);
      }
    );
    this.props.gstError2({
      gstError: isChecked,
    });
  };

  handletypeOther = (e) => {
    this.setState({
      business_type_other: e.target.value,
    });
    this.props.business_type_other2(e.target.value);
  };

  selectCategoryArray = () => {
    let selectedCategory = [];
    let category_array = this.state.category_array;
    if (category_array.length > 0) {
      for (let i = 0; i < category_array.length; i++) {
        selectedCategory.push({
          label: category_array[i].name,
          value: category_array[i].id,
        });
      }
    }
    this.setState({
      selected_category_array: selectedCategory,
    });
    this.props.selected_category_array2(selectedCategory);
  };

  getBusinessParentCategory = () => {
    const options = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${getUserDetails?.token}`,
      },

      data: { busines_type: 2 },
    };
    return axios(apiUrl + "/get-parentcategory-by-businesstype", options)
      .then((data) => {
        this.setState({
          business_parent_category_array: data.data.data,
          is_multiselect_search: true,
        });
      })
      .catch((error) => console.log(error));
  };
  getSubCategory = (catID) => {
    const options = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${getUserDetails?.token}`,
      },

      data: { id: catID },
    };
    return axios(apiUrl + "/get-subcategory-by-parentid", options)
      .then((data) => {
        this.setState({
          sub_category: data.data.data,
          is_subcategory: true,
        });
      })
      .catch((error) => console.log(error));
  };

  getBusinessAccountDetail = () => {
    const options = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${getUserDetails?.token}`,
      },

      data: { business_account_id: "1993" },
      // data:{business_account_id:businessID}
    };
    return axios(apiUrl + "/business-account", options)
      .then((data) => {
        let businessData = data.data.data[0];
        this.setState({
          // businessData:businessData,
          category_array: businessData.category_array,
          // gstin: businessData.gstin,
          // gstin_applied:businessData.gstin_applied ? "1" : false,
          show: true,
        });
      })
      .catch((error) => console.log(error));
  };
  getProPricingType = (e) => {
    const options = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${getUserDetails?.token}`,
      },

      data: { meta_id: 24 },
    };
    return axios(apiUrl + "/meta-value", options)
      .then((data) => {
        let pricingArray = data.data.data.filter((item) => {
          return !["Self", "Lead MQ"].includes(item.meta_title);
        });
        this.setState({
          pro_pricing_type: pricingArray,
        });
      })
      .catch((error) => console.log(error));
  };
  getBusinessCategory = () => {
    const options = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${getUserDetails?.token}`,
      },

      data: { busines_type: 2, keyword: "" },
    };
    return axios(apiUrl + "/get-category-by-businesstype", options)
      .then((data) => {
        this.setState({
          business_category_array: data.data.data,
          business_category_array_org: data.data.data,
        });
      })
      .catch((error) => console.log(error));
  };

  selectOption = (e) => {
    let mainCategoryArray = this.state.business_category_array;
    let filteredCategory = mainCategoryArray.filter((item) => {
      return item.id != e.target.id;
    });
    let selectedCategory = this.state.selected_category_array;
    selectedCategory.push({
      label: e.target.title,
      value: e.target.id,
    });
    this.setState({
      selected_category_array: selectedCategory,
      business_category_array: filteredCategory,
    });
    this.props.selected_category_array2(selectedCategory);
  };

  removeCategory = (e) => {
    let name = e.currentTarget.getAttribute("name");
    let mainCategoryArray = this.state.business_category_array;
    mainCategoryArray.push({
      name: name,
      id: e.currentTarget.id,
    });
    mainCategoryArray.sort((a, b) => a.name.localeCompare(b.name));
    let selectedCategoryArray = this.state.selected_category_array;
    let filteredCategory = selectedCategoryArray.filter((item) => {
      return item.value != e.currentTarget.id;
    });
    this.setState({
      selected_category_array: filteredCategory,
      business_category_array: mainCategoryArray,
    });
    this.props.selected_category_array2(filteredCategory);
  };

  handleCategoryVisibility = () => {
    this.setState((prevState) => ({
      show_business_category: !prevState.show_business_category,
    }));
  };

  hideCategoryVisibility = (e) => {
    if (
      e.target.className === "options" ||
      e.target.className === "form-control"
    ) {
    } else {
      this.setState({
        show_business_category: false,
      });
    }
  };

  getBusinessCategoryBySearch = (e) => {
    let keyword = e.target.value;
    this.setState({
      keyword: keyword,
    });
    const options = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${getUserDetails?.token}`,
      },

      data: { busines_type: 2, keyword: keyword },
    };
    return axios(apiUrl + "/get-category-by-businesstype", options)
      .then((data) => {
        let result = data.data.data;
        let selectedCategory = this.state.selected_category_array;
        let selectArray = selectedCategory.map((selectedArray) => {
          return selectedArray.value;
        });
        let arrayAfterDeselect = [];
        result.map((item) => {
          if (selectArray.includes(item.id.toString()) === false) {
            arrayAfterDeselect.push({
              id: item.id,
              name: item.name,
            });
          }
        });
        this.setState({
          business_category_array: arrayAfterDeselect,
        });
      })
      .catch((error) => console.log(error));
  };

  selectCategory = (e) => {
    let parent_id = e.target.getAttribute("data-item");
    let selectedCategoryArray = this.state.selected_category_array;
    let checkedCondition = e.target.checked;
    if (checkedCondition === true) {
      if (parent_id) {
        let categoryIndex = this.state.business_category_array_org.filter(
          (el) => el.id == parent_id
        );
        let categoryName = categoryIndex[0].name;
        var indexOfStevie = selectedCategoryArray.findIndex(
          (i) => i.value === parent_id
        );
        if (indexOfStevie == -1) {
          selectedCategoryArray.push({
            label: categoryName,
            value: parent_id,
          });
        }
      }
      selectedCategoryArray.push({
        label: e.target.id,
        value: e.target.value,
      });
      this.setState({
        selected_category_array: selectedCategoryArray,
      });
      this.props.selected_category_array2(selectedCategoryArray);
    } else {
      let filteredCategory = selectedCategoryArray.filter((item) => {
        return item.value != e.target.value;
      });
      this.setState({
        selected_category_array: filteredCategory,
      });
      this.props.selected_category_array2(filteredCategory);
    }
  };

  getBusinessGroup = () => {
    this.setState(
      {
        isLoading: true,
      },
      () => {
        const options = {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${getUserDetails?.token}`,
          },

          // data:{id:this.props.businessData.id}
          data: { id: 2105 },
        };
        return axios(apiUrl + "/get-business-group", options)
          .then((data) => {
            this.setState(
              {
                businessGroup: data.data.data,
                isLoading: false,
              },
              () => {
                let selectedArray = this.state.businessGroup.filter((item) => {
                  return item.selected === true;
                });
                if (selectedArray.length > 0) {
                  if (selectedArray[0].business_type.length > 0) {
                    let selectedBusinessType =
                      selectedArray[0].business_type.filter((item) => {
                        return item.selected === true;
                      });
                    let selectedBusinessTypeID = selectedBusinessType.map(
                      (item) => item.id
                    );
                    this.setState({
                      checkedBusinessGroup: selectedArray[0].id,
                      businessType: selectedArray[0].business_type,
                      // checkedBusinessType: selectedBusinessTypeID,
                    });
                    this.props.business_group2(selectedArray[0].id);
                    // this.props.business_type2(selectedArray[0].business_type)
                  } else {
                    this.setState({
                      checkedBusinessGroup: selectedArray[0].id,
                      others: true,
                      business_type_other: selectedArray[0].value,
                    });
                    this.props.business_group2(selectedArray[0].id);
                    this.props.business_type_other2(selectedArray[0].value);
                  }
                } else {
                  this.setState({
                    is_multiselect_search: false,
                  });
                }
              }
            );
          })
          .catch((error) => console.log(error));
      }
    );
  };

  handleCustomerGroup = (e) => {
    let id = e.target.id;
    this.setState({
      checkedCustomerGroup: id,
    });
    if (id == 1) {
      //b2B SELECTION CASE
      this.setState({
        showB2B: true,
        profile_type: "B2B",
        gstError: this.state.gstin_applied ? true : false,
      });
      this.props.getProfileType("B2B", "2");
    } else {
      //b2c SELECTION CASE
      this.setState(
        {
          showB2B: false,
          checkedBusinessGroup: "",
          checkedBusinessType: [],
          profile_type: "B2C",
          required_state: false,
          gstError: this.state.gstin_applied ? true : false,
          gstDisable: false,
          // gstin_applied: false
        },
        () => {
          this.props.gstapplied2(this.state.gstin_applied);
        }
      );
      this.props.getProfileType("B2C", "1");
      this.props.agent2("");
    }
  };

  handleBusinessGroup = (e) => {
    this.props.business_group2(e.target.id);
    let id = e.target.id;
    if (id == 100) {
      this.setState(
        {
          others: true,
          checkedBusinessGroup: id,
          checkedBusinessType: [],
          is_multiselect_search: false,
          required_state: false,
          agentid: "0",
          customerGroupID: "1",
          gstin_applied: false,
          gstDisable: true,
        },
        () => {
          this.props.getProfileType("B2B", this.state.customerGroupID);
          this.props.agent2(this.state.agentid);
          this.props.gstapplied2(this.state.gstin_applied);
          this.props.business_type2(this.state.checkedBusinessType);
        }
      );
      // this.props.business_group2(id)
    } else {
      if (id != 2) {
        this.setState(
          {
            is_multiselect_search: false,
            customerGroupID: "1",
            gstDisable: false,
          },
          () => {
            this.props.getProfileType("B2B", this.state.customerGroupID);
          }
        );
      } else {
        this.setState(
          {
            is_multiselect_search: true,
            customerGroupID: "2",
            gstDisable: false,
          },
          () => {
            this.props.getProfileType("B2B", this.state.customerGroupID);
          }
        );
      }
      this.setState(
        {
          others: false,
          checkedBusinessType: [],
          business_type_other: "",
        },
        () => {
          this.setState(
            {
              checkedBusinessGroup: id,
            },
            () => {
              let selectedBusinessGroupID = this.state.businessGroup.findIndex(
                (item) => {
                  return item.id == this.state.checkedBusinessGroup;
                }
              );
              // this.props.business_group2(selectedBusinessGroupID)
              let selectedBusinessType =
                this.state.businessGroup[selectedBusinessGroupID].business_type;
              this.setState({
                businessType: selectedBusinessType,
              });
              // this.props.business_type2(selectedBusinessType)
            }
          );
        }
      );
    }
  };

  handleBusinessType = (e, index) => {
    if (e.target.type == "radio") {
      let selectedBusinessType = [];
      selectedBusinessType.push(parseInt(e.target.id));
      this.setState(
        {
          selectedBusinessTypesRadio: selectedBusinessType,
          openIndex: index,
        },
        () => {
          const requestoption = {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${getUserDetails?.token}`,
            },

            data: {
              sub_busines_type: this.state.selectedBusinessTypesRadio,
            },
          };
          return (
            axios(apiUrl + "/get-business-sub-category", requestoption)
              .then((data) => {
                this.setState({
                  subBusinessType: data.data.data,
                });
                this.props.business_type2(
                  this.state.selectedBusinessTypesRadio,
                  "I AM TESTING selected business type"
                );
              })
              // this.sub_business_type2
              .catch((error) => {
                console.log(error, "erroer pincode");
                // this.setState({
                //   wrong_pin: false,
                // });
              })
          );
        }
      );
      return null;
    } else {
      let selectedArray = this.state.checkedBusinessType;
      if (e.target.checked) {
        selectedArray.push(parseInt(e.target.id));
        this.setState({
          checkedBusinessType: selectedArray,
        });
        this.props.business_type2(selectedArray);
      } else {
        let filteredArray = selectedArray.filter((item) => {
          return item != e.target.id;
        });
        this.setState({
          checkedBusinessType: filteredArray,
        });
        this.props.business_type2(filteredArray);
      }
    }
  };

  handleSubBusinessType = (e) => {
    let selectedArray = this.state.checkedBusinessType;
    if (e.target.checked) {
      selectedArray.push(parseInt(e.target.id));
      this.setState({
        checkedBusinessType: selectedArray,
      });
      this.props.sub_business_type2(selectedArray);
    } else {
      let filteredArray = selectedArray.filter((item) => {
        return item != e.target.id;
      });
      this.setState({
        checkedBusinessType: filteredArray,
      });
      this.props.sub_business_type2(filteredArray);
    }
  };

  handleBusinessGroup2 = (e) => {
    this.props.business_group2(e.target.id);
    let id = e.target.id;
  };

  checkCompanyAlreadyExists = () => {
    const options = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${getUserDetails?.token}`,
      },

      // data:{slug:this.state.slug}
      data: { slug: this.state.slug },
    };
    return axios(apiUrl + "/check-company-exits", options)
      .then((data) => {
        const result = data.data.data;
        const exists = result > 0 ? false : true;
        this.setState(
          {
            slug_exists: exists,
          }
          // () => {
          //     setTimeout(() => {
          //      this.checkCompanyAlreadyExists()
          //     }, 2000);
          //   }
        );
      })
      .catch((error) => {
        console.log(error);
      });
  };

  checkSlugExists = (e) => {
    const value = e.target.value.toLowerCase().replace(/[^a-z0-9]/g, "");
    this.setState(
      {
        slug: value,
      },
      () => {
        setTimeout(() => {
          this.checkCompanyAlreadyExists();
        }, 100);
      }
      // this.checkCompanyAlreadyExists();
      // }
    );
    this.props.slug2(e.target.value);
  };

  getAgent = () => {
    const requestoption = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${getUserDetails?.token}`,
      },

      data: {
        agent_list: "",
        id: [56],
        showAll: "true",
        slug: "BusinessProfile",
        listType: "add",
      },
    };
    return axios(apiUrl + "/get-pre-sales-agents", requestoption)
      .then((data) => {
        this.setState({
          agentArray: data.data.data,
        });
      })
      .catch((error) => console.log(error));
  };

  getLegalStatus = () => {
    const options = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${getUserDetails?.token}`,
      },

      data: { meta_id: 16 },
    };
    return axios(apiUrl + "/meta-value", options)
      .then((data) => {
        this.setState({
          legalStatus: data.data.data,
        });
      })
      .catch((error) => console.log(error));
  };
  handlechange = (e) => {
    this.setState({
      billing_city: e.target.value,
    });
  };

  handleChangePincode = (e) => {
    this.setState(
      {
        pin_code: e.target.value,
      },

      () => {
        if ([6, 0].includes(this.state.pin_code.length)) {
          const requestoption = {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${getUserDetails?.token}`,
            },

            data: {
              pincode: this.state.pin_code,
            },
          };
          return axios(b2bApiUrl + "/search-by-pincode", requestoption)
            .then((data) => {
              this.setState({
                billing_city: data.data.data[0].city,
                billing_zone: data.data.data[0].state,
                billing_city_id: data.data.data[0].city_id,
                billing_state_id: data.data.data[0].state_id,
                zone_id: data.data.data[0].shop_state_id,
                wrong_pin: false,
              });
              this.props.city2(data.data.data[0].city);
              this.props.state2(data.data.data[0].state);
              this.props.getId(
                this.state.billing_state_id,
                this.state.billing_city_id,
                this.state.zone_id
              );
              this.validateGSTNumber(this.state.gstin);
            })
            .catch((error) => {
              console.log(error, "erroer pincode");
              this.setState({
                wrong_pin: false,
              });
            });
        } else {
          this.setState({
            wrong_pin: true,
          });
        }
      }
    );
    this.props.pincode2(e.target.value);
  };

  checkGSTValidation = () => {
    if (!this.state.gstin_applied) {
      if (this.state.gstin.length > 0) {
        this.validateGSTNumber(this.state.gstin);
        this.setState({
          gstError: true,
        });
        this.props.gstError2({
          gstError: true,
        });
      }
    } else if (this.state.gstin_applied == 1) {
      this.setState({
        gstError: true,
      });
      this.props.gstError2({
        gstError: true,
      });
    }
  };
  getDuplicateProAccounts = async (pan_card) => {
    const options = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${getUserDetails?.token}`,
      },
      data: {
        // pro_id: 0,
        panNo: pan_card,
      },
    };
    return await axios(apiUrl + `/duplicate-pro-accounts-by-pan`, options)
      .then((data) => {
        this.setState({
          proChildAccounts: data.data?.data?.duplicateProAccounts,
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  gstValidate = (gstNumber) => {
    let loginedUserDetails = JSON.parse(localStorage.getItem("userData"));
    const token = loginedUserDetails.token;
    const requestoption = {
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      data: {
        gst: gstNumber,
      },
    };

    return axios(apiUrl + "/gst-validate", requestoption)
      .then((data) => {
        let gstData = data.data.data[0];
        if (gstData.address_arr.length > 1) {
          this.setState({
            showGstAddressPopUp: true,
            addressArray: gstData.address_arr,
            companyAddressArray: gstData.company_name,
            pan_card: gstData.panNo,
          });
        } else if (gstData.address_arr.length === 1) {
          this.setState(
            {
              addressArray: gstData.address_arr,
              companyAddressArray: gstData.company_name,
              pan_card: gstData.panNo,
              selectedAddress: gstData.address_arr,
            },
            () => {
              this.selectAddressSubmit();
            }
          );
        }
        this.props.getPancard(gstData.panNo);
        this.getDuplicateProAccounts(gstData.panNo);
      })
      .catch((err) => {
        // if(err.response.data.message) {
        //   this.setState({
        //     showGstErrorMessage: "Please enter a correct GST address.",
        //   });
        //   setTimeout(() => {
        //     this.setState({ showGstErrorMessage: "" });
        //   }, 6000);
        // }
        console.log(err);
      });
  };

  validateGSTNumber = (gstin) => {
    if (gstin.length > 0) {
      const options = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${getUserDetails?.token}`,
        },

        data: { gstin: gstin, state: this.state.billing_zone },
      };
      return axios(apiUrl + "/verifyGSTNumber", options)
        .then((data) => {
          if (data.data.message == "success") {
            this.gstValidate(gstin);
            this.setState({
              gstError: true,
            });

            this.props.gstnumber2(gstin);

            this.props.gstError2({
              gstError: true,
            });
          } else {
            this.setState({
              gstError: false,
            });
            // this.props.gstError2({
            //     gstError: true
            // })
          }
          this.props.gstapplied2(this.state.gstin_applied);
        })
        .catch((error) => {
          console.log(error);
          this.setState({
            gstError: false,
          });
          this.props.gstError2({
            gstError: true,
          });
        });
    }
  };

  handleProfile = (e) => {
    this.setState(
      {
        [e.target.name]: e.target.value,
      },
      this.props.handleProfile(e)
    );
  };
  handleYear = (date, dateString) => {
    // const year = date.getFullYear();
    this.setState(
      {
        yrs_in_business: date,
        dateString,
      },
      this.props.handleYear2(dateString)
    );
  };
  closeModalPopUp = () => {
    this.setState({
      showGstAddressPopUp: false,
      selectedAddressIndex: null,
    });
  };
  closeModal2 = () => {
    this.setState({
      showProChildAccount: false,
    });
  };
  selectGstAddress = (e) => {
    const addressArray = this.state.addressArray;
    const selectedIndex = parseInt(e.target.value);
    this.setState((prevState) => ({
      selectedAddressIndex:
        selectedIndex === prevState.selectedAddressIndex ? null : selectedIndex,
    }));

    const selectedAddress = addressArray.filter(
      (item, index) => parseInt(e.target.value) == index
    );
    if (!e.target.checked) {
      this.setState({
        selectedAddress: [],
      });
    } else {
      this.setState({
        selectedAddress,
      });
    }
  };
  selectAddressSubmit = () => {
    const { selectedAddress, companyAddressArray, customer_company } =
      this.state;

    // Check if any address has been selected
    if (!selectedAddress || selectedAddress.length === 0) {
      this.setState({
        errorMessage: "Please select a GST address.",
      });
      setTimeout(() => {
        this.setState({ errorMessage: "" });
      }, 6000);
      return;
    }
    this.setState({
      address_1: selectedAddress[0]?.address1,
      address_2: selectedAddress[0]?.address2,
      billing_city: selectedAddress[0]?.city,
      billing_zone: selectedAddress[0]?.state,
      pin_code: selectedAddress[0]?.pncd,
      customer_company: companyAddressArray,
      billing_city_id: selectedAddress[0]?.city_id,
      billing_state_id: selectedAddress[0]?.state_id,
      zone_id: selectedAddress[0]?.shop_state_id,
      slug: companyAddressArray,
    });
    this.props.customer_address1(selectedAddress[0]?.address1);
    this.props.customer_address2(selectedAddress[0]?.address2);
    this.props.pincode2(selectedAddress[0]?.pncd);
    this.props.city2(selectedAddress[0]?.city);
    this.props.state2(selectedAddress[0]?.state);
    this.props.customer_company2(companyAddressArray);
    this.props.getId(
      selectedAddress[0]?.state_id,
      selectedAddress[0]?.city_id,
      selectedAddress[0]?.shop_state_id
    );
    // this.props.slug2(companyAddressArray);
    if (customer_company === "") {
      this.createSlug(companyAddressArray);
    }
    this.closeModalPopUp();
  };

  seeAllAddress = (e) => {
    this.setState({
      showGstAddressPopUp: true,
    });
  };
  seeProChildAccounts = (e) => {
    if (this.state.proChildAccounts.length > 0) {
      this.setState({
        showProChildAccount: true,
      });
    }
  };
  isMobile =
    /Mobi|Android/i.test(navigator.userAgent) || window.innerWidth <= 991;
  render() {
    const { is_redirect } = this.state;
    if (this.state.is_redirect) {
      return <Redirect to="/business-accounts" />;
    }
    return (
      <>
        {/* {this.state.showGstErrorMessage && (
              <ErrorHandling message={this.state.showGstErrorMessage} type="ErrorMessage" />
            )} */}
        <Modal
          show={this.state.showProChildAccount}
          onHide={this.closeModal2}
          contentClassName={`${classes.customModalBorder} ${classes.animateBottom}`}
          dialogClassName={`${classes.abcd} ${classes.modal_bottom}`}
          className="duplicateGstModal"
        >
          <Modal.Header closeButton>
            <Modal.Title>Duplicate Accounts with Same GST</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {this.state.proChildAccounts &&
              this.state.proChildAccounts.map((account) => (
                <>
                  <Form.Row key={account.pro_id}>
                    <Col md="2" className="customColumn columnCustomSeprator">
                      <Form.Label className="duplicateGstTitle">
                        Account ID
                      </Form.Label>
                      <p className="duplicateGstValue">{account.pro_id}</p>
                      <hr className="customSepratorAdd customSepratorMobile" />
                    </Col>
                    <Col md="2" className="customColumn columnCustomSeprator">
                      <Form.Label className="duplicateGstTitle">
                        GST No.
                      </Form.Label>
                      <p className="duplicateGstValue">{account.gstNo}</p>
                      <hr className="customSepratorAdd customSepratorMobile" />
                    </Col>
                    <Col md="2" className="customColumn columnCustomSeprator">
                      <Form.Label className="duplicateGstTitle">
                        Company Name
                      </Form.Label>
                      <p className="duplicateGstValue">
                        {account.company_name}
                      </p>
                      <hr className="customSepratorAdd customSepratorMobile" />
                    </Col>
                    <Col md="2" className="customColumn columnCustomSeprator">
                      <Form.Label className="duplicateGstTitle">
                        Contact Name
                      </Form.Label>
                      <p className="duplicateGstValue">{account.name}</p>
                      <hr className="customSepratorAdd customSepratorMobile" />
                    </Col>
                    <Col md="2" className="customColumn columnCustomSeprator">
                      <Form.Label className="duplicateGstTitle">
                        Phone
                      </Form.Label>
                      <p className="duplicateGstValue">{account.phone}</p>
                      <hr className="customSepratorAdd customSepratorMobile" />
                    </Col>
                    <Col md="2" className="customColumn columnCustomSeprator">
                      <Form.Label className="duplicateGstTitle">
                        Address
                      </Form.Label>
                      <p className="duplicateGstValue">
                        {account.address_1 +
                          " , " +
                          account.address_2 +
                          ", " +
                          account?.city +
                          ", " +
                          account?.state +
                          ", " +
                          account?.pin_code}
                      </p>
                    </Col>
                  </Form.Row>
                  <hr class="customSepratorAdd duplicateSeprator" />
                </>
              ))}
          </Modal.Body>
        </Modal>
        <Modal
          show={this.state.showGstAddressPopUp}
          onHide={this.closeModalPopUp}
          className="duplicateGstModal selectAddressModal"
          contentClassName={`${classes.customModalBorder} ${classes.animateBottom}`}
          dialogClassName={`${classes.abcd} ${classes.modal_bottom}`}
        >
          <Modal.Header closeButton>
            <Modal.Title>Select Address</Modal.Title>
            {this.state.errorMessage && (
              <ErrorHandling
                message={this.state.errorMessage}
                type="ErrorMessage"
              />
            )}
          </Modal.Header>
          <Modal.Body>
            <div className="selectAddressWrapper">
              <h3 className="heading">{this.state.companyAddressArray}</h3>
            </div>
            {this.state.addressArray &&
              this.state.addressArray.map((address, index) => {
                return (
                  <div className="selectAddressBody">
                    <div className="selectAddressCnt selectAddressWrapper customRadio">
                      <input
                        type="radio"
                        onChange={this.selectGstAddress}
                        checked={this.state.selectedAddressIndex === index}
                        value={index}
                        id={index + "key"}
                      />
                      <label
                        for={index + "key"}
                        className={`address ${
                          this.state.selectedAddressIndex === index
                            ? "selected"
                            : ""
                        }`}
                      >
                        {address.address1 +
                          "\t" +
                          address.address2 +
                          "\t" +
                          address.city +
                          "\t" +
                          address.state}
                      </label>
                    </div>
                    <hr
                      className="customSepratorAdd"
                      style={{ margin: "16px 0" }}
                    />
                  </div>
                );
              })}
          </Modal.Body>
          <Modal.Footer>
            <button
              onClick={this.selectAddressSubmit}
              className="customBtn selectAddressBtn"
              id="selectGst"
            >
              Select
            </button>
          </Modal.Footer>
        </Modal>
        {this.isMobile ? (
          <>
            {/* mobile responsive code start here */}
            {this.props.currentStep === 2 && (
              <BusinessMobile
                childState={this.props.childState}
                customer_company={this.state.customer_company}
                showB2B={this.state.showB2B}
                legalStatus_id={this.state.legalStatus_id}
                legalStatus={this.state.legalStatus}
                slug={this.state.slug}
                gstin={this.state.gstin}
                gstin_applied={this.state.gstin_applied}
                gstDisable={this.state.gstDisable}
                proChildAccounts={this.state.proChildAccounts}
                pan_card={this.state.pan_card}
                pricing_type={this.state.pricing_type}
                agentid={this.state.agentid}
                agentArray={this.state.agentArray}
                pin_code={this.state.pin_code}
                billing_zone={this.state.billing_zone}
                billing_city={this.state.billing_city}
                address_1={this.state.address_1}
                address_2={this.state.address_2}
                pro_pricing_type={this.state.pro_pricing_type}
                slug_exists={this.state.slug_exists}
                handleBackStep={this.props.handleBackStep}
                handleNextStep={this.props.handleNextStep}
                currentStep={this.props.currentStep}
                seeProChildAccounts={this.seeProChildAccounts}
                LegalStatus={this.LegalStatus}
                checkSlugExists={this.checkSlugExists}
                handleGstChange={this.handleGstChange}
                seeAllAddress={this.seeAllAddress}
                handleRadioChange={this.handleRadioChange}
                handleProfile={this.handleProfile}
                proPricing={this.proPricing}
                getAgentByID={this.getAgentByID}
                handleChangePincode={this.handleChangePincode}
                handlechange={this.handlechange}
                addressChange1={this.addressChange1}
                addressChange2={this.addressChange2}
                handleCompany={this.handleCompany}
              />
            )}
            {this.props.currentStep === 3 && (
              <ProfessionalMobile
                showB2B={this.state.showB2B}
                childState={this.props.childState}
                businessGroup={this.state.businessGroup}
                checkedBusinessGroup={this.state.checkedBusinessGroup}
                others={this.state.others}
                businessType={this.state.businessType}
                checkedBusinessType={this.state.checkedBusinessType}
                openIndex={this.state.openIndex}
                selectedOption={this.state.selectedOption}
                subBusinessType={this.state.subBusinessType}
                business_type_other={this.state.business_type_other}
                selected_category_array={this.state.selected_category_array}
                keyword={this.state.keyword}
                show_business_category={this.state.show_business_category}
                business_category_array={this.state.business_category_array}
                handleBackStep={this.props.handleBackStep}
                handleNextStep={this.props.handleNextStep}
                selectedBusinessTypesRadio={
                  this.state.selectedBusinessTypesRadio
                }
                handleBusinessGroup={this.handleBusinessGroup}
                removeCategory={this.removeCategory}
                handletypeOther={this.handletypeOther}
                getBusinessCategoryBySearch={this.getBusinessCategoryBySearch}
                selectOption={this.selectOption}
                handleCategoryVisibility={this.handleCategoryVisibility}
                handleSubBusinessType={this.handleSubBusinessType}
                handleBusinessType={this.handleBusinessType}
              />
            )}
            {this.props.currentStep === 4 && (
              <AdditionalMobile
                dateString={this.state.dateString}
                turnover={this.state.turnover}
                handleSubmit={this.props.handleSubmit}
                annualRevenueLacs={this.state.annualRevenueLacs}
                employeeRange={this.state.employeeRange}
                credit_limit={this.state.credit_limit}
                credit_period={this.state.credit_period}
                projects_done={this.state.projects_done}
                employee_count={this.state.employee_count}
                handleBackStep={this.props.handleBackStep}
                handleNextStep={this.props.handleNextStep}
                disabledDate={this.disabledDate}
                handleYear={this.handleYear}
                handleProfile={this.handleProfile}
              />
            )}
            {/* mobile responsive code ends here */}
          </>
        ) : (
          <>
            <div className="customCard mobile_views">
              <h3 className="customCardheading">Business Profile</h3>
              <Row>
                <Col md="4">
                  <Form.Group
                    controlId="formGridCustomerName"
                    className="customGroup"
                  >
                    <Form.Label className="customLabel">
                      Business Name
                      <span class="mandatoryIcon">*</span>
                    </Form.Label>
                    <Form.Control
                      className="customInput"
                      name="customer_company"
                      type="text"
                      placeholder=""
                      defaultValue={this.state.customer_company}
                      onChange={this.handleCompany}
                      required={this.state.showB2B}
                    />
                  </Form.Group>
                </Col>
                <Col md="4">
                  <Form.Group
                    controlId="formBasicEmail"
                    className="customGroup"
                  >
                    <Form.Label className="customLabel">
                      Legal Status
                    </Form.Label>
                    <div className="customSelectCnt">
                      <Form.Control
                        className="customSelect"
                        as="select"
                        custom
                        name="legal_status"
                        value={this.state.legalStatus_id}
                        onChange={this.LegalStatus}
                        // required={this.state.showB2B}
                      >
                        <option value="">Select Legal Status</option>
                        {this.state.legalStatus &&
                          this.state.legalStatus.map((item, index) => {
                            return (
                              <option key={index} value={item.id}>
                                {item.meta_value}
                              </option>
                            );
                          })}
                      </Form.Control>
                      <img
                        src={dropdown_Icon}
                        className="dropdownIcon"
                        width="20px"
                        height="20px"
                      />
                    </div>
                  </Form.Group>
                </Col>
                <Col md="4">
                  <Form.Group
                    controlId="formGridCustomerName"
                    className="customGroup slugInputCnt"
                  >
                    <Form.Label className="customLabel">Slug</Form.Label>
                    <div className="slugInputWrapper">
                      <Form.Control
                        className="customInput"
                        placeholder=""
                        name="slug"
                        value={this.state.slug}
                        onChange={this.checkSlugExists}
                        required={this.state.showB2B}
                      />

                      {!this.state.customer_company ? null : (
                        <>
                          {this.state.slug_exists ? (
                            <div className="notExistsIconArea">
                              <img src={notExistsGreen} />
                            </div>
                          ) : (
                            <div className="notExistsIconArea">
                              <img src={notExistsRed} />
                            </div>
                          )}
                        </>
                      )}
                    </div>
                  </Form.Group>
                </Col>
                <Col md="3">
                  <Form.Group
                    controlId="formBasicEmail"
                    className="customGroup"
                  >
                    <Form.Label className="customLabel">GST Number</Form.Label>
                    <div className="gstWrapper">
                      <Form.Control
                        // className={!this.state.gstError ? "error" : ""}
                        className="customInput gstCustomInput"
                        placeholder="GST Number"
                        name="gstin"
                        value={this.state.gstin}
                        onChange={this.handleGstChange}
                        // required={
                        //   this.state.profile_type == "B2B" &&
                        //   !this.state.gstin_applied
                        // }
                      />
                      <button
                        onClick={this.seeAllAddress}
                        className={`customBtn  allAddBtn ${
                          this.state.gstin.length < 15 ? "" : "active"
                        }`}
                        disabled={this.state.gstin.length < 15}
                      >
                        View address
                      </button>
                    </div>
                    <div className="gstExtAccotCnt customCheckbox">
                      <Form.Check
                        type="checkbox"
                        label="GST Applied"
                        name="gstin_applied"
                        id="gstApplied"
                        checked={this.state.gstin_applied}
                        onChange={this.handleRadioChange}
                        value={this.state.gstin_applied}
                        disabled={this.state.gstDisable}
                        className="gstAppliedbtn"
                      />

                      {this.state.proChildAccounts.length > 0 ? (
                        <button
                          onClick={this.seeProChildAccounts}
                          className="customBtn extAcctbtn"
                        >
                          Existing Account
                        </button>
                      ) : null}
                    </div>
                  </Form.Group>
                </Col>
                <Col md="3">
                  <Form.Group className="customGroup">
                    <Form.Label className="customLabel">PAN</Form.Label>
                    <Form.Control
                      className="customInput disabled"
                      placeholder="PAN"
                      name="pan_card"
                      onChange={this.handleProfile}
                      value={this.state.pan_card}
                      disabled
                    />
                  </Form.Group>
                </Col>
                <Col md="3">
                  <Form.Group
                    controlId="formBasicbusiness"
                    className="customGroup"
                  >
                    <Form.Label className="customLabel">
                      Source of Information
                      <span class="mandatoryIcon">*</span>
                    </Form.Label>
                    <div className="customSelectCnt">
                      <Form.Control
                        className="customSelect"
                        as="select"
                        custom
                        name="pricing_type"
                        value={this.state.pricing_type}
                        onChange={this.proPricing}
                        required
                      >
                        <option value="">Select</option>
                        {this.state.pro_pricing_type &&
                          this.state.pro_pricing_type.map((item, index) => {
                            return (
                              <option key={item.id} value={item.meta_value}>
                                {item.meta_title}
                              </option>
                            );
                          })}
                      </Form.Control>
                      <img
                        src={dropdown_Icon}
                        className="dropdownIcon"
                        width="20px"
                        height="20px"
                      />
                    </div>
                  </Form.Group>
                </Col>
                <Col md="3">
                  <Form.Group
                    controlId="formGridCustomerName"
                    className="customGroup"
                  >
                    <Form.Label className="customLabel">
                      Agent
                      <span class="mandatoryIcon">*</span>
                    </Form.Label>
                    <div className="customSelectCnt">
                      <Form.Control
                        className="customSelect"
                        as="select"
                        custom
                        name="legal_status"
                        value={this.state.agentid}
                        onChange={this.getAgentByID}
                        // required={this.state.required_state}
                        required
                      >
                        <option value="">Select</option>
                        {this.state.agentArray &&
                          this.state.agentArray.map((item) => {
                            return item.id ? (
                              <option key={item.id} value={item.id}>
                                {item.name}
                              </option>
                            ) : null;
                          })}
                      </Form.Control>
                      <img
                        src={dropdown_Icon}
                        className="dropdownIcon"
                        width="20px"
                        height="20px"
                      />
                    </div>
                  </Form.Group>
                </Col>
              </Row>
              <hr class="customSepratorAdd" style={{ margin: "24px 0 8px" }} />
              <Row>
                <Col md="4">
                  <Form.Group
                    controlId="formGridCustomerName"
                    className="customGroup"
                  >
                    <Form.Label className="customLabel">Pin Code</Form.Label>
                    <Form.Control
                      className={
                        this.state.wrong_pin
                          ? "customInput error"
                          : "customInput"
                      }
                      name="pin_code"
                      type="number"
                      defaultValue={this.state.pin_code}
                      placeholder="6 Digit pincode"
                      onChange={this.handleChangePincode}
                      // required={this.state.showB2B}
                    />
                  </Form.Group>
                </Col>
                <Col md="4">
                  <Form.Group
                    controlId="formGridCustomerName"
                    className="customGroup"
                  >
                    <Form.Label className="customLabel">State</Form.Label>
                    <Form.Control
                      name="state_name"
                      className="customInput"
                      type="text"
                      placeholder="Select"
                      defaultValue={this.state.billing_zone}
                      // required={this.state.showB2B}
                    />
                  </Form.Group>
                </Col>
                <Col md="4">
                  <Form.Group
                    controlId="formGridCustomerName"
                    className="customGroup"
                  >
                    <Form.Label className="customLabel">City</Form.Label>
                    <div className="customSelectCnt">
                      <Form.Control
                        name="city"
                        type="text"
                        className="customInput"
                        placeholder="Select"
                        value={this.state.billing_city}
                        onChange={this.handlechange}
                        // required={this.state.showB2B}
                      />
                    </div>
                  </Form.Group>
                </Col>
                <Col md="6">
                  <Form.Group
                    controlId="formGridCustomerName"
                    className="customGroup"
                  >
                    <Form.Label className="customLabel">Address1</Form.Label>
                    <Form.Control
                      name="customer_address"
                      type="text"
                      className="customInput"
                      placeholder="Flat, House no., Building, Apartment"
                      defaultValue={this.state.address_1}
                      onChange={this.addressChange1}
                      // required={this.state.showB2B}
                    />
                  </Form.Group>
                </Col>
                <Col md="6">
                  <Form.Group
                    controlId="formGridCustomerName"
                    className="customGroup"
                  >
                    <Form.Label className="customLabel">Address2</Form.Label>
                    <Form.Control
                      name="customer_address"
                      type="text"
                      className="customInput"
                      placeholder="Area, Street, Sector"
                      defaultValue={this.state.address_2}
                      onChange={this.addressChange2}
                      // required={this.state.showB2B}
                    />
                  </Form.Group>
                </Col>
              </Row>
            </div>
            <div className="customCard mobile_views">
              <h3 className="customCardheading">Profession Details</h3>
              {!this.state.showB2B ? (
                <>
                  <div>
                    <Form.Group
                      controlId="formGridCustomerName"
                      className="customGroup"
                    >
                      <Form.Label className="customLabel businessGrouplabel">
                        Business Group
                        <span class="mandatoryIcon">*</span>
                      </Form.Label>
                      {this.state.businessGroup &&
                        this.state.businessGroup.map((item, index) => {
                          return (
                            <div
                              className={`customCheckboxWrapper customRadio
                            ${
                              item.id == this.state.checkedBusinessGroup
                                ? "selected"
                                : ""
                            }`}
                            >
                              <Form.Check
                                type="radio"
                                inline
                                key={item.id + index}
                                checked={
                                  item.id == this.state.checkedBusinessGroup
                                }
                                id={item.id}
                                name="business_group"
                                label={item.name}
                                onChange={this.handleBusinessGroup}
                                required
                                labelClass
                              />
                            </div>
                          );
                        })}
                    </Form.Group>
                  </div>
                  <hr
                    className="customSepratorAdd"
                    style={{ margin: "24px 0" }}
                  />
                  <div>
                    <Form.Group controlId="formGridCustomerName">
                      <Form.Label className="customLabel businessGrouplabel">
                        Business Type
                      </Form.Label>

                      {this.state.others == false ? (
                        this.state.businessType.map((item, index) => {
                          let checked =
                            this.state.checkedBusinessType.indexOf(item.id) !=
                            -1;
                          return (
                            <div
                              className={`customCheckboxWrapper 
                              ${
                                this.state.checkedBusinessGroup == 2
                                  ? `customRadio ${
                                      item.id ==
                                      this.state.selectedBusinessTypesRadio
                                        ? "selected"
                                        : ""
                                    }`
                                  : `customCheckbox ${
                                      checked ? "selected" : ""
                                    }`
                              }`}
                            >
                              <Form.Check
                                type={
                                  this.state.checkedBusinessGroup == 2
                                    ? "radio"
                                    : "checkbox"
                                }
                                inline
                                // onChange={this.handleBusinessType}
                                onChange={(e) =>
                                  this.handleBusinessType(e, index)
                                }
                                key={item.id + index}
                                checked={
                                  this.state.checkedBusinessGroup == 2
                                    ? this.state.selectedOption
                                    : checked
                                }
                                id={item.id}
                                name="business_type"
                                label={item.name}
                                required
                              />
                            </div>
                          );
                        })
                      ) : (
                        <Form.Control
                          placeholder="Others"
                          name="business_type_other"
                          defaultValue={this.state.business_type_other}
                          onChange={this.handletypeOther}
                          className="customInput"
                          required
                        />
                      )}
                    </Form.Group>
                  </div>
                  <hr
                    className="customSepratorAdd"
                    style={{ margin: "24px 0" }}
                  />
                  {/* sub_business_type list start here */}
                  {this.state.checkedBusinessGroup == 2 &&
                  this.state.selectedBusinessTypesRadio.length > 0 ? (
                    <div>
                      <Form.Group controlId="formGridCustomerName">
                        <Form.Label className="customLabel businessGrouplabel">
                          Sub Business Type
                        </Form.Label>

                        {this.state.selectedBusinessTypesRadio
                          ? this.state.subBusinessType.map((item, index) => {
                              let checked =
                                this.state.checkedBusinessType.indexOf(
                                  item.id
                                ) != -1;
                              return (
                                // <div className="customCheckboxWrapper customCheckbox selected">
                                <div
                                  className={`customCheckboxWrapper customCheckbox 
                                ${checked ? "selected" : ""}`}
                                >
                                  <Form.Check
                                    // type="checkbox"
                                    type="checkbox"
                                    inline
                                    onChange={this.handleSubBusinessType}
                                    key={item.id + index}
                                    // defaultChecked={true}
                                    // defaultValue={7}
                                    checked={
                                      this.state.checkedBusinessGroup == 2
                                        ? this.state.selectedOption
                                        : checked
                                    }
                                    id={item.id}
                                    name="sub_busines_type"
                                    label={item.name}
                                    required
                                  />
                                </div>
                              );
                            })
                          : ""}
                        <hr
                          className="customSepratorAdd"
                          style={{ margin: "24px 0" }}
                        />
                      </Form.Group>
                    </div>
                  ) : (
                    ""
                  )}
                </>
              ) : null}

              <div>
                {this.state.selected_category_array.length > 0 ? (
                  <div
                    className="slectcategoriesCnt"
                    style={{ marginBottom: "16px" }}
                  >
                    {this.state.selected_category_array.map((item, index) => {
                      return (
                        <div
                          key={index}
                          id={item.value}
                          className="slectcategoriesItem"
                          style={{
                            marginRight: "16px",
                            padding: "12px 36px 12px 12px",
                            fontSize: "14px",
                          }}
                        >
                          {item.label}
                          <img
                            src={crossSmall}
                            alt="cross icon"
                            width="15px"
                            height="15px"
                            className="newcrossIcon"
                            name={item.label}
                            id={item.value}
                            onClick={this.removeCategory}
                            style={{ right: "12px" }}
                          />
                        </div>
                      );
                    })}
                  </div>
                ) : null}
              </div>
              <div style={{ position: "relative" }}>
                <Form.Group controlId="formBasicEmail">
                  <Form.Label className="customLabel businessGrouplabel">
                    Add Categories
                  </Form.Label>
                  <div className="customSelectCnt addCategoriesCnt">
                    <Form.Control
                      placeholder="Select"
                      name="keyword"
                      value={this.state.keyword}
                      onChange={this.getBusinessCategoryBySearch}
                      onFocus={this.handleCategoryVisibility}
                      className="customInput"
                      autoComplete="off"
                    />
                    <img
                      src={searchIcon}
                      className="searchIcon"
                      width="20px"
                      height="20px"
                    />
                  </div>
                </Form.Group>
                {this.state.show_business_category ? (
                  <div className="selectOptions">
                    {this.state.business_category_array.map((item, index) => {
                      return (
                        <>
                          <p
                            className="options"
                            onClick={this.selectOption}
                            key={index}
                            id={item.id}
                            title={item.name}
                          >
                            {item.name}
                          </p>
                          <hr
                            className="customSepratorAdd"
                            style={{ margin: "8px 0" }}
                          />
                        </>
                      );
                    })}
                  </div>
                ) : null}
              </div>
            </div>
            <div className="customCard mobile_views">
              <h3 className="billingAdressHeader">Additional Details</h3>
              <Form.Row>
                <Col md="4">
                  <Form.Group className="customGroup">
                    <Form.Label className="customLabel">
                      Year Of business
                    </Form.Label>
                    <DatePicker
                      size="medium"
                      className="form-control datePickerInput customInput"
                      onChange={this.handleYear}
                      picker="year"
                      disabledDate={this.disabledDate}
                    />
                    <Form.Label>
                      {this.state.dateString
                        ? `${
                            maxYear - Number(this.state.dateString)
                          } Years in Business`
                        : null}
                    </Form.Label>
                  </Form.Group>
                </Col>
                <Col md="4">
                  <Form.Group className="customGroup">
                    <Form.Label className="customLabel">
                      Annual Revenue
                    </Form.Label>
                    <div className="customSelectCnt">
                      <Form.Control
                        as="select"
                        custom
                        name="turnover"
                        onChange={this.handleProfile}
                        value={this.state.turnover}
                        className="customSelect"
                      >
                        <option value="">
                          {this.state.turnover.length > 1
                            ? 0
                            : "Select Turn Over"}
                        </option>
                        {this.state.annualRevenueLacs &&
                          this.state.annualRevenueLacs.map((range) => {
                            return (
                              <option key={range.id} value={range.meta_value}>
                                {range.meta_title}
                              </option>
                            );
                          })}
                      </Form.Control>
                      <img
                        src={dropdown_Icon}
                        class="dropdownIcon"
                        width="20px"
                        height="20px"
                      />
                    </div>
                  </Form.Group>
                </Col>
                <Col md="4">
                  <Form.Group className="customGroup">
                    <Form.Label className="customLabel">
                      Employee Count Range
                    </Form.Label>
                    <div className="customSelectCnt">
                      <Form.Control
                        as="select"
                        custom
                        name="employee_count"
                        onChange={this.handleProfile}
                        value={this.state.employee_count}
                        className="customSelect"
                      >
                        <option value="">
                          {this.state.employee_count.length > 1
                            ? 0
                            : "Select Range"}
                        </option>
                        {this.state.employeeRange &&
                          this.state.employeeRange.map((range) => {
                            return (
                              <option key={range.id} value={range.meta_value}>
                                {range.meta_title}
                              </option>
                            );
                          })}
                      </Form.Control>
                      <img
                        src={dropdown_Icon}
                        class="dropdownIcon"
                        width="20px"
                        height="20px"
                      />
                    </div>
                  </Form.Group>
                </Col>

                <Col md="4">
                  <Form.Group className="customGroup">
                    <Form.Label className="customLabel">
                      Credit Limit
                    </Form.Label>
                    <Form.Control
                      type="number"
                      placeholder="Credit Limit."
                      name="credit_limit"
                      onChange={this.handleProfile}
                      value={this.state.credit_limit}
                      className="customInput"
                    />
                  </Form.Group>
                </Col>
                <Col md="4">
                  <Form.Group className="customGroup">
                    <Form.Label className="customLabel">
                      Credit Period (In days)
                    </Form.Label>
                    <Form.Control
                      type="number"
                      placeholder="Credit Period"
                      name="credit_period"
                      onChange={this.handleProfile}
                      value={this.state.credit_period}
                      className="customInput"
                    />
                  </Form.Group>
                </Col>

                <Col md="4">
                  <Form.Group className="customGroup">
                    <Form.Label className="customLabel">
                      Projects Done
                    </Form.Label>
                    <Form.Control
                      type="number"
                      placeholder="Projects Done"
                      name="projects_done"
                      onChange={this.handleProfile}
                      value={this.state.projects_done}
                      className="customInput"
                    />
                  </Form.Group>
                </Col>
              </Form.Row>
            </div>
          </>
        )}
      </>
    );
  }
}
export default BusinessProfile2;
