import React, { useState } from "react";
import { Button, Form, Modal } from "react-bootstrap";

const NewAddressModal = (props) => {
  const [isValidated, setValidated] = useState(false);
  const [street, setStreet] = useState("");
  const [street2, setStreet2] = useState("");
  const [city, setCity] = useState("");
  const [region, setRegion] = useState("");
  const [postalCode, setPostalCode] = useState("");
  const [country, setCountry] = useState("");
  const [fullAddress, setFullAddress] = useState("");

  const handleSubmit = (e) => {
    e.preventDefault();
    // Handle form submission here
    const addressString = `${street},${street2}, ${city}, ${region}, ${postalCode}, ${country}`;
    setFullAddress(addressString);
    props.addAddress(addressString);
    closeAddressModal();
  };

  const closeAddressModal = () => {
    props.closeAddressModal(false);
  };

  return (
    <Modal show onHide={closeAddressModal}>
      <Modal.Header closeButton>
        <b>
          <h4>Address Form</h4>
        </b>
      </Modal.Header>
      <Modal.Body>
        {/* <Form noValidate validated={isValidated}>
          <Form.Group>
            <Form.Label>Name</Form.Label>
            <Form.Control placeholder="Enter Name" />
          </Form.Group>
        </Form> */}
        <Form onSubmit={handleSubmit}>
          <Form.Group>
            <Form.Label>Shipping Address 1</Form.Label>
            <Form.Control
              type="text"
              value={street}
              onChange={(e) => setStreet(e.target.value)}
            />
          </Form.Group>
          <Form.Group>
            <Form.Label>Shipping Address 2</Form.Label>
            <Form.Control
              type="text"
              value={street2}
              onChange={(e) => setStreet2(e.target.value)}
            />
          </Form.Group>
          <Form.Group>
            <Form.Label>City:</Form.Label>
            <Form.Control
              type="text"
              value={city}
              onChange={(e) => setCity(e.target.value)}
            />
          </Form.Group>
          <Form.Group>
            <Form.Label>Region/State:</Form.Label>
            <Form.Control
              as="select"
              custom
              value={region}
              onChange={(e) => setRegion(e.target.value)}
            >
              <option value="">Please select state</option>
              {props.stateArray.length > 0 &&
                props.stateArray.map((item) => {
                  var selected = region == item.name ? true : false;
                  return (
                    <option
                      selected={selected}
                      key={item.id}
                      value={item.name}
                      defaultValue=""
                    >
                      {item.name}
                    </option>
                  );
                })}
            </Form.Control>
          </Form.Group>
          <Form.Group>
            <Form.Label>Postal Code:</Form.Label>
            <Form.Control
              type="number"
              value={postalCode}
              onChange={(e) => setPostalCode(e.target.value)}
            />
          </Form.Group>
          <Form.Group>
            <Form.Label>Country:</Form.Label>
            <Form.Control
              type="text"
              value={country}
              onChange={(e) => setCountry(e.target.value)}
            />
          </Form.Group>
          <Button type="submit">Submit</Button>
        </Form>
      </Modal.Body>
      <Modal.Footer>
        {/* <Button onClick={addAddress}>Add</Button> */}
      </Modal.Footer>
    </Modal>
  );
};

export default NewAddressModal;
