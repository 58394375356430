import React from "react";
import { getUserDetails } from "../../Login/getLoginnedUserDetails";
import { Row, Col, Form, Button, Alert, Spinner, Modal } from "react-bootstrap";
import axios from "axios";
import Constants from "../../../Config";
import AddItem from "../Quotes/AddItem";
import ProductLists from "../Quotes/ProductLists";
import ListQuote from "../Quotes/ListQuote";
import "./createQuote.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import SelectSearch from "react-select-search";
import { Link, Redirect, useHistory } from "react-router-dom";
import { CSVLink, CSVDownload } from "react-csv";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
// import ButtonGroup from '@material-ui/core/ButtonGroup';

import DatePicker from "react-datepicker";
import es from "date-fns/locale/es";

import "react-datepicker/dist/react-datepicker.css";
import AddItemModal from "./AddItemModal";
const apiUrl = Constants.API_URL;
const b2bApiUrl = Constants.B2B_API_URL;

const dataUser = [{ name: "lalit", email: "lait@mccoymart.com" }];

const modules = {
  toolbar: [[{ list: "bullet" }]],
};
const defaultpaymentTermsArray = [
    "Grand total is including taxes",
    "Payment terms - 100% advance"
];
class DuplicateQuote extends React.Component {
  constructor(props) {
    super(props);
    let parseData = JSON.parse(localStorage.getItem("dataKey"));
    this.csvLinkEl = React.createRef();
    this.state = {
      // quoteState: this.props.quoteState,
      //   startDate: new Date(),
      parseData: parseData,
      startDate: "",
      nameData: [],
      displayProductArray: [],
      nameDataArray: [],
      stateArray: [],
      getAllProductsWithDetailForList: [],
      billing_first_name: "",
      billing_last_name: "",
      billing_mobile: "",
      billing_name: "",
      companyName: "",
      billing_company_name: "",
      billing_address_1: "",
      billing_address_2: "",
      billing_city: "",
      billing_zone_id: "",
      billing_postcode: "",
      billing_country: "",
      billing_country_name: "",
      billing_gst: "",
      gst: "",
      gstError: true,
      shipping_first_name: "",
      shipping_last_name: "",
      shipping_mobile: "",
      shipping_company_name: "",
      shipping_address_1: "",
      shipping_address_2: "",
      shipping_city: "",
      shipping_state_id: "",
      shipping_zone_id: 0,
      shipping_postcode: "",
      shipping_country: "",
      shipping_country_name: "",
      shipping_gst: "",
      selectedCompany: "",
      agentArray: [],
      customer_name: "",
      customer_email: "",
      customer_phone: "",
      agent_id: "",
      addressArray: [],
      selectedAddress: "",
      selectedShippingAddress: "",
      // modal overlay state
      showModal: false,
      gstAddressArr: [],
      selectedOneGstAddress: "",
      subTotal: 0,
      list_price: "",
      list_price_org: "",
      taxTotal: "",
      grandTotal: "",
      company_id: "",
      available_stock: 0,
      customer_id: "",
      user_id: "",
      is_loading: false,
      validated: false,
      newValidated: false,
      is_error: false,
      is_redirect: false,
      quote_subject: "",
      quote_status: "",
      quoteID: "",
      rfqID: 0,
      message: "",
      shippingCharge: 0,
      rfq_id: "",
      business_account_id: "",
      rfq_products: [],
      cutomerName: "lalittestacc",
      details: [dataUser],
      // selectedOption: "b2blandingpage",
      product_id: "",
      productQuantity: "",
      listedNewProductsArray: "",
      main_rfq_id: "",
      agent_name: "",
      customer_email2: "",
      customer_phone2: "",
      customer_name2: "",
      disabled: true,
      companyName2: "",
      phone: "",
      phone2: "",
      selectedOption: "",
      special_tp: "0",
      ticket_id: "",
      vendor_id: "",
      //add address variables below
      new_address: "",
      new_name: "",
      new_mobile: "",
      new_companyName: "",
      new_address1: "",
      new_address2: "",
      address_array: ["Work", "Home"],
      new_address_type: "Work",
      new_postcode: "",
      new_city: "",
      new_zone_id: "",
      new_state: "",
      new_country: "",
      new_Gstno: "",
      new_country_id: "",
      new_state_id: "",
      isDisable: true,
      gstDisable: false,
      gstnewDisable: false,
      new_address_error_message: "",
      gstErrorMessage: "",
      new_address_success_message: "",
      categoryDisable: true,
      productTotal: "",
      //
      rfq_status: "",
      call_response: "",
      call_id: "",
      //  message:"",
      call_status: "",
      remarks: "",
      // agent_id: this.state.agent_id,
      call_attempt_count: "",
      rfq_quote_type: "",
      rfq_agent_id: "",
      rfq_message: "",
      status: "",
      wrong_pin: false,
      new_wrong_pin: false,
      wrong_phone: false,

      //New states
      fromDuplicate: true,
      productIDArray: [],
      customerID: "",
      showGstBox: false,
      gstLoading: false,
      showGstAddress: false,
      gst_address: "",
      billing_zone_name: "",
      address_type: "Work",
      shipping_address_type: "Work",
      gstNumber: "",
      gstAddressArray: [],
      gstAddressCount: 0,
      selectedGstAddress: "",
      first_name: "",
      mobile_number: "",
      company_name: "",
      gstDefault: "",
      tpMessage: [],
      gstNotInDB: false,
      isPaymentTermsChecked: false,
      defaultpaymentTermsArray: [],
      paymentTerms1: "",
      isEmptyTerm: [],
      showGSTDetailsCheckbox: false,
      showDisabledFields: false,
      showModalVerifyGST: false,
      gstBusinessAddressArr: [],
      selectedOneBusinessGstAddress: "",
      storeInputValue: 0,
      allowedSlugs: [],
      agent_remarks: "",
      manager_remarks: "",
      quoteGrossMargin: 0,
      quoteMinimumGrossMargin: 0,
      showSubmitForApproval: false,
      discountOnMrp: 0,
      totalDiscountOnMrp: 0,
      existingProducts: new Map(),
      totalMrp: 0,
      totalNetAmount: 0,
      totalTaxAmount: 0,
      totalPayableAmount: 0,
      showSubmitForShippingCharges: false,
      totalSystemDeliverRate: 0,
      touched:false,
      shippingCharges: 0,
      triggerRender:0,
      paymentTerms: "",
    };
  }

  handleQuillChange = (content) => {
    this.setState({ paymentTerms: content });
  };

  handleDateChange = (date) => {
    this.setState({
      startDate: date,
    });
  };

  componentDidMount(props) {
    this.setState({
      allowedSlugs: this.props.childSlugProp["listQuote"],
    });
    const quoteState = this.props.quoteState;
    // if(quoteState == {}){
    //   this.setState({
    //     quoteID:
    //   })
    // }

    // let quoteID = parseData.quoteID;
    // let customerID = parseData.customer_id;

    //Check for filtering ProductIDs from Products

    // let productsArray = this.state.parseData.productsArr;
    // let productIDArray = productsArray.map((item) => {
    //   return item.product_id;
    // });
    // console.log(this.state.parseData.quoteID, "state of quoteID");
    // console.log(productIDArray, "productID");

    //END

    // if (quoteState === 0) {
    //   this.setState({
    //     quoteID: 0,
    //   });
    // } else if (quoteState === undefined) {
    //   this.setState({
    //     quoteID: "undefined",
    //   });
    // } else {
    //   const quoteID = quoteID;
    //   const rfqID = quoteState.rfqID;
    //   const phone = quoteState.phone;
    //   const rfq_status = quoteState.rfq_status;
    //   const call_response = quoteState.call_response;
    //   const call_id = quoteState.call_id;
    //   // const message = quoteState.message;
    //   const call_status = quoteState.call_status;
    //   const remarks = quoteState.remarks;
    //   // agent_id: this.state.agent_id,
    //   const call_attempt_count = quoteState.call_attempt_count;
    //   const rfq_quote_type = quoteState.rfq_quote_type;
    //   const rfq_agent_id = quoteState.agent_id;
    //   const status = quoteState.status;
    //   const message = quoteState.message;
    //   this.setState({
    //     quoteID: quoteID,
    //     rfqID: rfqID,
    //     phone: phone,
    //     rfq_status: rfq_status,

    //     call_response: call_response,
    //     call_id: call_id,
    //     call_status: call_status,
    //     remarks: remarks,
    //     call_attempt_count: call_attempt_count,
    //     rfq_quote_type: rfq_quote_type,
    //     rfq_agent_id: rfq_agent_id,
    //     status: status,
    //     rfq_message: message,
    //     // messgae: message,
    //     // agent_id: this.state.agent_id,
    //     // customer_name: this.props?.selectedDetails?.name,
    //     // customer_phone: this.props?.selectedDetails?.mobile,
    //     // customer_email: this.props?.selectedDetails?.email
    //   });

    this.setState(
      {
        quoteID: this.state.parseData.quoteID,
        // productIDArray,
        customerID: this.state.parseData.customer_id,
      },
      () => {
        if (this.state.quoteID > 0) {
          this.getQuoteDetailByID(this.state.quoteID);
        }
      }
    );
    this.setState({
      paymentTerms:
        "<ul><li>" + defaultpaymentTermsArray.join("</li><li>") + "</li></ul>",
    });
    // }
    // console.log( "ENtry for validate:::::",  this.state.billing_zone_id, this.state.billing_gst )
    // if(this.state.billing_gst != ""){
    //   this.validateGSTNumber(this.state.billing_gst, this.state.billing_zone_id)
    // }

    this.getName();
    this.getAgent();
    this.getState();
    // this.getDetailByRFQId();

    // if (this.state.rfq_id > 0) {
    //   this.getDetailsbyAccountName();
    //   // this.setState({ rfq_id: this.props.quoteState.quoteID });
    // }
  }
  componentDidUpdate(prevProps, prevState) {
    if (
      this.state.getAllProductsWithDetailForList.length &&
      prevState.getAllProductsWithDetailForList !=
      this.state.getAllProductsWithDetailForList
    ) {
      let val = false;
      for (let item of this.state.getAllProductsWithDetailForList) {
        if (Number(item.gross_margin) < Number(item.minimum_gross_margin)) {
          val = true;
        }
      }
      if (!this.showSubmitForApproval)
        this.setState({ showSubmitForApproval: val });
    }
    // if (prevState.shippingCharges !== this.state.shippingCharges) {
    //   let val = false;
    //   if (
    //     Number(Number(this.state.shippingCharges).toFixed(2)) <
    //     Number(Number(this.state.totalSystemDeliverRate).toFixed(2))
    //   ) {
    //     val = true;
    //   }
    //   if (!this.showSubmitForShippingCharges)
    //     this.setState({ showSubmitForShippingCharges: val });
    // }
  }
  getQuoteDetailByID = (quoteID) => {
    let loginedUserDetails = JSON.parse(localStorage.getItem("userData"));
    // console.log("loginedUserDetails",loginedUserDetails)
    const token = loginedUserDetails.token;
    const params = {
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      data: {
        // quoteID: this.props.quoteState
        quoteID: quoteID,
      },
    };
    return axios(apiUrl + "/get-quotes", params)
      .then((data) => {
        let dataObject = data.data.data[0];
        let paymentTerms = dataObject.paymentTerms;
        if (paymentTerms.length > 0) {
          // paymentTerms =
          //   "<ul><li>" + paymentTerms.join("</li><li>") + "</li></ul>";
          paymentTerms = paymentTerms.replace(
            /<ul style=margin-left: -11px>|<ul style=margin-left:-11px>|<ul style=\"margin-left:-11px;>|<ul style=\"margin-left: -11px;>/gi,
            "<ul>"
          );
          this.setState({
            isPaymentTermsChecked: true,
          });
        } else {
          // paymentTerms  = "<ul><li>" + defaultpaymentTermsArray.join("</li><li>") + "</li></ul>"
          paymentTerms = "";
        }

        // let paymentTerms = this.state.paymentTerms.join(',');

        this.setState({ paymentTerms: paymentTerms });

        this.setState(
          {
            customer_email2: dataObject.companyData.email,
            customer_name2: dataObject.companyData.name,
            customer_phone2: dataObject.companyData.telephone,
            companyName2: dataObject.company_id,
            customer_id: dataObject.customer_id,
            selectedOption: dataObject.label_name,
            new_mobile: dataObject.companyData.telephone,
            new_name: dataObject.companyData.name,
            rfqID: dataObject.rfq_id,
            productsArr: dataObject.products,
            isDisable: false,
            gstDisable: true,
            quote_status: dataObject.quote_status,
            gstDefault: dataObject.gst,
            allowDuplicate: dataObject.allowDuplicate,
            showGstBox: dataObject.gst ? true : false,
            showSubmitForApproval:
              dataObject.quote_status == "Pending For Approval" ? false : true,
            showSubmitForShippingCharges:
              dataObject.quote_status == "Pending For Approval" ? false : true,
            // paymentTerms: dataObject.paymentTerms,
          },
          () => { }
        );

        // console.log("dataObject.companyData.address",dataObject.companyData.address)
        let products = dataObject.products;
        let productTotal = dataObject.productTotal[0];

        let productsArray = [];
        let magicProductsArray = [];
        let productsArray1 = [];
        let subTotal = productTotal ? productTotal.sub_total : 0;

        let grandTotal = productTotal ? productTotal.grand_total : 0;
        let shipping = productTotal ? productTotal.shipping : 0;
        let quoteGrossAmount = 0;
        let quoteMinGrossAmount = 0;
        let discountOnMrpAmount = 0;
        let totalDiscountOnMrpAmount = 0;
        let netTotalAmount = 0;
        let totalMrpAmount = 0;
        let subTotalAmount = 0;
        let totalTaxAmt = 0;
        let showButton = false;
        let totalDeliveryRate = 0;
        let showButtonShippingInput = false;
        let totalNetAmount1 = 0;

        //let taxTotal = productTotal.tax_total;

        for (let i = 0; i < products.length; i++) {
          quoteGrossAmount +=
            (Number(products[i].gross_margin) / 100) *
            Number(products[i].net_amount_new);

          if (products[i].product_type == "1") {
            let price =
              products[i].special_tp == 0
                ? products[i].transfer_price
                : products[i].special_tp;

            quoteMinGrossAmount +=
              (Number(products[i].minimum_gross_margin) / 100) *
              products[i].quantity *
              price;

            discountOnMrpAmount =
              ((Number(products[i].mrp) - Number(products[i].list_price)) /
                Number(products[i].mrp)) *
              100;
            let netTotalSum =
              netTotalAmount + Number(products[i].net_amount_new);
            const totalDiscount =
              Number(products[i].mrp) * Number(products[i].quantity) -
              netTotalSum;
            totalDiscountOnMrpAmount += totalDiscount;
            let totalMrp = Number(products[i].mrp) * Number(products[i].quantity);
            totalMrpAmount += totalMrp;
            let netSubTotal =  Number(totalDiscountOnMrpAmount) - Number(totalMrpAmount);
            subTotalAmount =- netSubTotal;
            let totalTax = Number(products[i].tax_in_amount);
            totalTaxAmt += totalTax;
            let systemDeliveryRate =
              (Number(products[i].deliveryCharges) * netTotalSum) / 100;
            totalDeliveryRate += systemDeliveryRate * (118 / 100);
            if (
              !showButton &&
              Number(products[i].gross_margin) <
              Number(products[i].minimum_gross_margin)
            ) {
              showButton = true;
            }
            if (
              !showButtonShippingInput &&
              Number(this.state.storeInputValue) <
                Number(this.state.totalSystemDeliverRate)
            ) {
              showButtonShippingInput = true;
            }
            productsArray.push({
              // productType: products[i].product_type,
              product_id: products[i].product_id,
              name: products[i].name,
              brand: products[i].brand,
              quantity_type: products[i].quantity_type,
              brand: products[i].brand,
              quantity_type: products[i].quantity_type,
              tax: products[i].tax,
              mrp: products[i].mrp,
              model: products[i].model,
              transfer_price: products[i].transfer_price,
              special_tp: Number(products[i].special_tp),
              vendor: products[i].vendor,
              ticket_id: products[i].ticket_id,
              vendor_id: products[i].vendor_id,
              markup: products[i].markup,
              quantity: products[i].quantity,
              list_price: Number(products[i].list_price),
              list_price_org: Number(products[i].list_price_org),
              discount_on_mrp: Number(products[i].discount_on_mrp),
              net_amount: Number(products[i].net_amount_new),
              net_amount_org: Number(products[i].net_amount),
              tax_in_percentage: Number(products[i].tax_in_percentage),
              tax_in_amount: Number(products[i].tax_in_amount),
              total: Number(products[i].total),
              gross_margin: Number(products[i].gross_margin),
              minimum_gross_margin: Number(
                products[i].minimum_gross_margin
              ),
              deliveryCharges: Number(products[i].deliveryCharges),
            });
          } else {
            magicProductsArray.push({
              product_id: products[i].product_id,
              name: products[i].name,
              tax: products[i].tax,
              mrp: products[i].mrp,
              model: products[i].model,
              transfer_price: products[i].transfer_price,
              special_tp: Number(products[i].special_tp),
              vendor: products[i].vendor,
              ticket_id: products[i].ticket_id,
              vendor_id: products[i].vendor_id,
              markup: products[i].markup,
              quantity: products[i].quantity,
              list_price: Number(products[i].list_price),
              list_price_org: Number(products[i].list_price_org),
              discount_on_mrp: Number(products[i].discount_on_mrp),
              net_amount: Number(products[i].net_amount_new),
              net_amount_org: Number(products[i].net_amount),
              tax_in_percentage: Number(products[i].tax_in_percentage),
              tax_in_amount: Number(products[i].tax_in_amount),
              total: Number(products[i].total),
              gross_margin: Number(products[i].gross_margin),
              minimum_gross_margin: Number(
                products[i].minimum_gross_margin
              ),
              deliveryCharges: Number(products[i].deliveryCharges),
            });
          }
          totalNetAmount1 += Number(products[i].net_amount_new);
          productsArray1.push({
            product_id: products[i].product_id,
            quantity: JSON.stringify(products[i].quantity),
          });
        }
        this.setState({
          quoteGrossMargin: quoteGrossAmount,
          quoteMinimumGrossMargin: quoteMinGrossAmount,
        });
        this.setState({
          discountOnMrp: discountOnMrpAmount,
          totalDiscountOnMrp: totalDiscountOnMrpAmount,
          totalMrp: totalMrpAmount,
          // totalNetAmount: subTotalAmount,
          totalNetAmount: totalNetAmount1,
          totalTaxAmount: totalTaxAmt,
          totalSystemDeliverRate:
            this.state.totalSystemDeliverRate + totalDeliveryRate,
        });
        this.setState({
          showSubmitForApproval: showButton,
          showSubmitForShippingCharges: showButtonShippingInput,
        });
        var d = new Date(dataObject.valid_upto);
        this.setState(
          {
            agent_id: dataObject.agent_id,
            agent_remarks: dataObject.agent_remarks
              ? dataObject.agent_remarks
              : "",
            manager_remarks: dataObject.manager_remarks
              ? dataObject.manager_remarks
              : "",
            quote_subject: dataObject.quote_subject,
            valid_upto: dataObject.valid_upto,
            startDate: d,
            companyName: dataObject.companyData.company_name,
            customer_name:
              dataObject.companyData.firstname +
              " " +
              dataObject.companyData.lastname,
            customer_email: dataObject.companyData.email,
            customer_phone: dataObject.companyData.telephone,
            addressArray: dataObject.companyData.address,
            customer_id: dataObject.customer_id,
            company_id: dataObject.company_id,
            user_id: dataObject.companyData.user_id,
            billing_first_name: dataObject.firstname,
            billing_last_name: dataObject.lastname,
            billing_name: dataObject.name,
            billing_company_name: dataObject.company_name
              ? dataObject.company_name
              : "",
            billing_mobile: dataObject.mobile,
            billing_address_1: dataObject.address_1 ? dataObject.address_1 : "",
            billing_address_2: dataObject.address_2 ? dataObject.address_2 : "",
            // selectedAddress: dataObject.address_1,
            billing_city: dataObject.city ? dataObject.city : "",
            billing_zone_id: dataObject.zone_id ? dataObject.zone_id : "",
            billing_postcode: dataObject.postcode ? dataObject.postcode : "",
            billing_country: dataObject.country_id,
            billing_country_name: dataObject.country_name,
            billing_gst: dataObject.gst ? dataObject.gst : "",
            shipping_first_name: dataObject.shipping_firstname
              ? dataObject.shipping_firstname
              : dataObject.companyData.name,
            shipping_last_name: dataObject.shipping_lastname,
            shipping_mobile: dataObject.shipping_mobile,
            shipping_company_name: dataObject.shipping_company_name,
            shipping_address_1: dataObject.shipping_address_1,
            shipping_address_2: dataObject.shipping_address_2,
            shipping_city: dataObject.shipping_city,
            shipping_zone_id: dataObject.shipping_zone_id,
            shipping_postcode: dataObject.shipping_postcode,
            shipping_country_name: dataObject.shipping_country_name,
            shipping_country: dataObject.shipping_country_id,
            shipping_gst: dataObject.shipping_gst,
            shipping_address_type: dataObject.shipping_address_type, // add new key
            getAllProductsWithDetailForList: productsArray,
            displayProductArray: productsArray1,
            subTotal: Number(subTotal),
            // shippingCharge: Number(shipping),
            shippingCharges: Number(shipping),
            // storeInputValue: Number(shipping),
            grandTotal: Number(grandTotal),
            // New States for Mobile, Firtaname, Compony name
            first_name: dataObject.firstname ? dataObject.firstname : "",

            company_name: dataObject.company_name
              ? dataObject.company_name
              : "",
            mobile_number: dataObject.mobile ? dataObject.mobile : "",

            //for magic quote item
            magicProductsArray: magicProductsArray,
            productTotal: dataObject.productTotal[0].shipping,
          },
          () => {
            const gstAddressArray = this.state.addressArray.filter(
              (item) => (item.gst_address == 1 || (item.gstin && item.gstin.length==15))
            );
            this.setState({
              gstAddressArray,
              gstAddressCount: gstAddressArray ? gstAddressArray.length : 0,
            });
          }
        );
      })
      .catch((error) => console.log(error));
  };
  getName = () => {
    let loginedUserDetails = JSON.parse(localStorage.getItem("userData"));
    const token = loginedUserDetails.token;
    const requestoption = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${getUserDetails.token}`,
      },
    };
    return axios(apiUrl + "/get-business-account", requestoption)
      .then((data) => {
        this.setState({
          nameDataArray: data.data.data,
          nameData: data.data.data.map((item) => {
            return {
              name: item.company_name,
              value: item.company_name,
            };
          }),
        });
      })
      .catch((error) => console.log(error));
  };
  getState = () => {
    let loginedUserDetails = JSON.parse(localStorage.getItem("userData"));
    const token = loginedUserDetails.token;
    const requestoption = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${getUserDetails.token}`,
      },
    };
    return axios(apiUrl + "/get-all-states", requestoption)
      .then((data) => {
        this.setState({
          stateArray: data.data.data,
        });
      })
      .catch((error) => console.log(error));
  };
  getAgent = () => {
    let loginedUserDetails = JSON.parse(localStorage.getItem("userData"));
    const requestoption = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${getUserDetails.token}`,
      },

      data: {
        agent_list: "",
        id: [56],
        showAll: "",
        slug: "createQuote",
      },
    };
    return axios(apiUrl + "/get-pre-sales-agents", requestoption)
      .then((data) => {
        this.setState({
          agentArray: data.data.data,
        });
      })
      .catch((error) => console.log(error));
  };

  handleChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };

  customer_idB2 = (value) => {
    this.setState({
      customer_id: value,
    });
    //   this.displayAllProducts(e)
  };
  customerMobile = (value) => {
    this.setState({
      customer_mobile: value,
    });
  };

  newHandleChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };
  newHandleChangePincode = (e) => {
    this.setState(
      {
        new_postcode: e.target.value,
        gstNewDisable: true,
      },
      () => {
        if (this.state.new_postcode.length == 6) {
          const requestoption = {
            method: "POST",
            data: {
              pincode: this.state.new_postcode,
            },
          };
          return axios(b2bApiUrl + "/search-by-pincode", requestoption)
            .then((data) => {
              this.setState({
                new_city: data.data.data[0].city,
                new_state: data.data.data[0].state,
                new_zone_id: data.data.data[0].shop_state_id,
                new_state_id: data.data.data[0].state_id,
                new_country: "India",
                new_country_id: 99,
                new_wrong_pin: false,
              });
              this.validateGSTNumber(
                this.state.new_Gstno,
                this.state.new_zone_id
              );
            })
            .catch((error) => {
              console.log(error);
              this.setState({
                new_city: "",
                new_zone_id: "",
                new_wrong_pin: false,
              });
            });
        } else {
          this.setState({
            new_wrong_pin: true,
            new_city: "",
            new_zone_id: "",
          });
        }
      }
    );
  };
  getNewZoneID = (e) => {
    this.setState({
      new_zone_id: e.target.value,
    });
    this.validateGSTNumber(this.state.new_Gstno, e.target.value);
  };
  // addresschange = (e) => {
  //   this.setState({
  //     new_address: e.target.value,
  //   });

  // };

  getPhoneValue = (e, type = "details") => {
    // this.closeModal()
    // e.persist();
    // this.setState({
    //   customer_phone2: e.target.value,
    // });
    let inputTarget;
    if (type === "details") {
      inputTarget = e.target;
    } else {
      inputTarget = e;
    }
    //  if (e.target.value.length === 10 || this.state.quoteID) {
    // if (this.props.quoteState) {
    if (inputTarget.value.length === 10) {
      this.setState({
        phone2: inputTarget.value,
      });
      axios({
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${getUserDetails.token}`,
        },

        url: apiUrl + "/get-customer-detail-by-phone",
        data: { phone: inputTarget.value },
        // data: { phone: this.state.quoteID ? this.state.quoteID:e.target.value }
      })
        .then((data) => {
          if (data.data.data[0]) {
            this.setState({
              // customer_name:
              //   data.data.data[0].name,
              // customer_email: data.data.data[0].email,
              // customer_phone: data.data.data[0].telephone,
              addressArray: data.data.data[0].address,
              // company_id: index.id,
              companyName: data.data.data[0].customerCompany,
              customer_id: data.data.data[0].customer_id,
              // user_id: response.data.data.user_id,
              // agent_id: response.data.data.agent_id,

              // new entity add
              customer_email2: data.data.data[0].email,
              customer_name2: data.data.data[0].customerName,
              customer_phone2: data.data.data[0].telephone,
              // below two lines is the assignment for deafult name and phone value in Add new Address Popup
              new_name: data.data.data[0].customerName,
              new_mobile: data.data.data[0].telephone,

              isDisable: false,
              categoryDisable: true,
              wrong_phone: false,
            });
          } else {
            this.setState({
              // customer_phone:e.target.value
              customer_phone2: inputTarget.value,
              companyName: "",
              customer_name2: "",
              customer_email2: "",
              wrong_phone: true,
            });
          }
          // this.displayAllProducts()
        })
        .catch((error) => console.log(error));
    } else {
      this.setState({
        wrong_phone: true,
        companyName: "",
        customer_name2: "",
        customer_email2: "",
      });
    }
  };

  getPhoneValue2 = () => {
    if (this.state.phone) {
      axios({
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${getUserDetails.token}`,
        },

        url: apiUrl + "/get-customer-detail-by-phone",
        data: { phone: this.state.phone },
      })
        .then((data) => {
          if (data.data.data[0]) {
            this.setState({
              // customer_name:
              //   data.data.data[0].name,
              // customer_email: data.data.data[0].email,
              // customer_phone: data.data.data[0].telephone,
              addressArray: data.data.data[0].address,
              // company_id: index.id,
              companyName:
                data.data.data[0].address.length > 0
                  ? data.data.data[0].address[0].company
                  : "",
              customer_id: data.data.data[0].customer_id,
              // user_id: response.data.data.user_id,
              // agent_id: response.data.data.agent_id,

              // new entity add
              customer_email2: data.data.data[0].email,
              customer_name2: data.data.data[0].customerName,
              customer_phone2: data.data.data[0].telephone,

              // end
            });
          } else {
            this.setState({
              // customer_phone:e.target.value
              customer_phone: this.state.phone,
            });
          }
          // this.displayAllProducts()
        })
        .catch((error) => console.log(error));
    }
  };

  openModal = () => {
    this.setState({
      show: true,
    });
    // if(this.props.custome_id){
    //     return true
    // }
    // else{
    //     this.getPhoneValueB2C()
    // }
  };
  closeModal = () => {
    this.setState({
      show: false,
    });
  };
  handleChangeAddressTypeRadio = (e) => {
    this.setState({
      new_address_type: e.target.value,
    });
  };
  handleChangeShippingAddressTypeRadio = (e) => {
    this.setState({
      shipping_address_type: e.target.value,
    });
  };
  handleChangeBillingAddressTypeRadio = (e) => {
    this.setState({
      address_type: e.target.value,
    });
  };
  handleCheckboxChange = () => {
    this.setState((prevState) => ({
      showGSTDetailsCheckbox: !prevState.showGSTDetailsCheckbox,
      showDisabledFields: !prevState.showDisabledFields,
    }));
  };
  handleChangePincode = (e) => {
    this.setState(
      {
        billing_postcode: e.target.value,
      },
      () => {
        if (this.state.billing_postcode.length == 6) {
          const requestoption = {
            method: "POST",
            data: {
              pincode: this.state.billing_postcode,
            },
          };
          return axios(b2bApiUrl + "/search-by-pincode", requestoption)
            .then((data) => {
              this.setState({
                billing_city: data.data.data[0].city,
                billing_zone: data.data.data[0].state,
                billing_zone_id: data.data.data[0].shop_state_id,
                billing_country_name: "India",
                billing_country: 99,
                gstDisable: true,
                wrong_pin: false,
              });
              this.validateGSTNumber(
                this.state.billing_gst,
                this.state.billing_zone_id
              );
            })
            .catch((error) => console.log(error));
        } else {
          this.setState({
            billing_city: "",
            billing_zone: "",
            billing_zone_id: "",
            billing_country_name: "",
            billing_country: "",
            gstDisable: false,
            wrong_pin: true,
          });
        }
      }
    );
  };
  validateGSTNumber = (gstin, state_id) => {
    let state_name = this.state.stateArray.filter((item) => {
      return state_id == item.id;
    });
    if (gstin.length > 0) {
      const options = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${getUserDetails.token}`,
        },
        data: {
          gstin: gstin,
          state: state_name.length ? state_name[0].name : "",
        },
      };
      return axios(apiUrl + "/verifyGSTNumber", options)
        .then((data) => {
          if (data.data.message == "success") {
            this.setState({
              gstError: true,

              // is_loading: true,
              // validated: true,
            });
          } else {
            this.setState(
              {
                gstError: false,
                gstErrorMessage: "Enter Valid GST number",
              },
              () => {
                setTimeout(() => {
                  this.setState({
                    gstErrorMessage: "",
                  });
                }, 3000);
              }
            );
          }
        })
        .catch((error) => {
          console.log(error);
          this.setState(
            {
              gstError: false,
              gstErrorMessage: "Enter Valid GST number",
            },
            () => {
              setTimeout(() => {
                this.setState({
                  gstErrorMessage: "",
                });
              }, 3000);
            }
          );
          this.setState();
        });
    }
  };

  handleGstChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
    // this.validateGSTNumber(e.target.value, this.state.billing_zone_id);
    const regex = "^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[1-9A-Z]{1}Z[0-9A-Z]{1}$";
    if (e.target.value.match(regex)) {
      this.setState({
        gstError: true,
      });

      // this.validateGSTNumber(e.target.value, this.state.billing_zone_id);
    } else {
      this.setState({
        gstError: false,
      });
    }
  };

  handleBusinessGstChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
    const regex = "^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[1-9A-Z]{1}Z[0-9A-Z]{1}$";
    if (e.target.value.match(regex)) {
      this.setState({
        gstBusinessDetailsError: false,
      });

      // this.validateGSTNumber(e.target.value, this.state.billing_zone_id);
    } else {
      this.setState({
        gstBusinessDetailsError: true,
      });
    }
  };

  searchBusinessGst = (e) => {
    e.preventDefault();
    let loginedUserDetails = JSON.parse(localStorage.getItem("userData"));
    const token = loginedUserDetails.token;
    this.setState({
      gstLoading: true,
    });
    const requestoption = {
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      data: {
        gst: this.state.gstNumber,
      },
    };
    return axios(apiUrl + "/gst-validate", requestoption)
      .then((data) => {
        let gstData = data.data.data[0];
        if (!gstData.zone_id) {
          this.setState({
            gstNotInDB: true,
          });
        } else {
          this.setState({
            gstNotInDB: false,
            showModalVerifyGST: true,
            gstBusinessAddressArr: data.data.data[0],
          });
        }
        this.setState({
          gstLoading: false,
        });
      })
      .catch((err) => {
        console.log(err);
        this.setState(
          {
            gstLoading: false,
            message: "No Address With This GST NO. is Found ",
            is_error: true,
          },
          () => {
            setTimeout(() => {
              this.setState({
                message: "",
                is_error: false,
              });
            }, 3000);
          }
        );
      });

    //must keep this line to avoid re-render of page and avoiding undefined "RFQID" error
  };

  handleNewGstChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
    if (e.target.value.length == 0) {
      this.setState({ gstError: true });
    }
    this.validateGSTNumber(e.target.value, this.state.new_zone_id);
  };

  addNewAddress = (e) => {
    const form = e.currentTarget;
    if (form.checkValidity() === false) {
      e.preventDefault();
      e.stopPropagation();
    } else {
      this.setState({ newValidated: true });
    }
    // e.preventDefault();
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${getUserDetails.token}`,
      },
      data: {
        customer_id: this.state.customer_id.toString(),
        customer_name: this.state.new_name
          ? this.state.new_name
          : this.state.customer_name2,
        company_name: this.state.new_companyName,
        address_1: this.state.new_address1,
        address_2: this.state.new_address2,
        city: this.state.new_city,
        pin_code: this.state.new_postcode,
        // gstin: this.state.new_Gstno,
        gstin: this.state.gstNumber,
        customer_mobile: this.state.new_mobile
          ? this.state.new_mobile
          : this.state.customer_phone2,
        country_id: this.state.new_country_id.toString(),
        zone_id: this.state.new_zone_id.toString(),
        address_type: this.state.new_address_type,
        gstBilling: this.state.showGSTDetailsCheckbox,
        // state_id: this.state.new_state_id
      },
    };

    axios(apiUrl + "/add-address", requestOptions)
      .then((data) => {
        const phoneInput = document.getElementById("customerPhone");
        this.getPhoneValue(phoneInput, "new_address");
        if (data.status == 200) {
          this.setState(
            {
              new_name: "",
              new_companyName: "",
              new_address1: "",
              new_address2: "",
              new_city: "",
              new_postcode: "",
              gstNumber: "",
              new_mobile: "",
              new_country_id: "",
              new_zone_id: "",
              new_address_type: "",
              showGSTDetailsCheckbox: false,
              showDisabledFields: false,
              newValidated: false,
              new_address_error_message: "",
              new_address_success_message: "Address added successfully",
            },
            () => {
              setTimeout(() => {
                this.setState({
                  new_address_success_message: "",
                });
              }, 5000);
            }
          );
          this.closeModal();
        }
        // return data;
      })
      .catch((err) => {
        // console.log(err.response, "ERROR MESSAGE");
        this.setState({
          new_address_error_message: err.response.data.message,
        });
        this.setState(() => {
          setTimeout(() => {
            this.setState({
              new_address_error_message: "",
            });
          }, 5000);
        });
      });
  };
  // getNewAddress = () => {
  //   const requestOptions = {
  //     method: "POST",
  //     headers: {
  //       "Content-Type": "application/json",
  //       Authorization: `Bearer ${getUserDetails.token}`,
  //     },
  //     data: {
  //       phone: this.state.customer_phone2
  //     }
  //   };
  //   axios(apiUrl + "/get-customer-detail-by-phone", requestOptions)
  //   .then({

  //   })

  // }
  handleChangeShippingPincode = (e) => {
    this.setState(
      {
        shipping_postcode: e.target.value,
      },
      () => {
        if (this.state.shipping_postcode.length == 6) {
          const requestoption = {
            method: "POST",
            data: {
              pincode: this.state.shipping_postcode,
            },
          };
          return axios(b2bApiUrl + "/search-by-pincode", requestoption)
            .then((data) => {
              this.setState({
                shipping_city: data.data.data[0].city,
                shipping_zone: data.data.data[0].state,
                shipping_zone_id: data.data.data[0].shop_state_id,
                shipping_country_name: "India",
                shipping_country: 99,
              });
            })
            .catch((error) => console.log(error));
        } else {
          this.setState({
            shipping_city: "",
            shipping_zone: "",
            shipping_zone_id: "",
            shipping_country_name: "",
            shipping_country: "",
          });
        }
      }
    );
  };
  // copyAddress = () => {
  //   this.setState({
  //     selectedShippingAddress: this.state.billing_address_1
  //       ? this.state.billing_address_1
  //       : this.state.selectedAddress,
  //     shipping_first_name: this.state.billing_first_name ,
  //     // shipping_last_name: this.state.billing_last_name,
  //     shipping_name: this.state.billing_name,
  //     //  shipping_first_name: this.state.billing_first_name,
  //     shipping_mobile: this.state.billing_mobile,
  //     shipping_company_name: this.state.billing_company_name,
  //     shipping_address_1: this.state.billing_address_1,
  //     shipping_address_2: this.state.billing_address_2,
  //     shipping_city: this.state.billing_city,
  //     shipping_zone_id: this.state.billing_zone_id,
  //     shipping_postcode: this.state.billing_postcode,
  //     shipping_country_name: this.state.billing_country_name,
  //     shipping_country: this.state.billing_country,
  //     shipping_gst: this.state.billing_gst,
  //     shipping_address_type: this.state.address_type,
  //   });
  // };
  // openPopUp = (e) => {
  //   console.log(e, "eeeeeeeeeeeeeeeeee");
  // };

  getDetailsbyAccountName = (e) => {
    const getSelectedAccountName = e;

    let index = {};

    if (this.state.business_account_id > 0) {
      index = { id: this.state.business_account_id };
    } else {
      index = this.state.nameDataArray.filter((el) => el.company_name == e)[0];
    }
    //  let index = this.state.nameDataArray.filter(
    //     (el) => el.company_name == e
    //   )[0];

    const requestOptions = {
      method: "POST",
      data: {
        company_id: index.id,
      },
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${getUserDetails.token}`,
      },
    };

    return axios(apiUrl + "/get-details-by-accountname", requestOptions)
      .then((response) => {
        // console.log("companyName",response.data.data.company_name)

        this.setState(
          {
            // customer_name:
            //   response.data.data.firstname + " " + response.data.data.lastname,
            // customer_email: response.data.data.email,
            // customer_phone: response.data.data.telephone,
            addressArray: response.data.data.address,
            company_id: index.id,
            companyName: response.data.data.company_name,
            customer_id: response.data.data.customer_id,
            user_id: response.data.data.user_id,
            agent_id: response.data.data.agent_id,
            // agent_name: response.data.data.agentName,

            // new entity add
            customer_name2:
              response.data.data.firstname + " " + response.data.data.lastname,
            customer_email2: response.data.data.email,
            customer_phone2: response.data.data.telephone,
            isDisable: false,
          },
          () => {
            const gstAddressArray = this.state.addressArray.filter(
              (item) => (item.gst_address == 1 || (item.gstin && item.gstin.length==15))
            );
            this.setState({
              gstAddressArray,
              gstAddressCount: gstAddressArray ? gstAddressArray.length : 0,
            });
          }
        );
      })
      .catch((error) => console.log("error"));
  };

  getDetailByRFQId = (e, products) => {
    let productMap = new Map();
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${getUserDetails.token}`,
      },

      data: {
        //  rfq_id: this.state.main_rfq_id
        rfq_id: this.props.quoteState.rfqID,
      },
    };
    if (products) {
      for (let product of products) {
        productMap.set(product.product_id, product);
      }
      this.setState({ existingProducts: productMap });
      products = products.filter(
        (product) => product.availabilityType !== "Not Avl"
      );
    }
    return axios(apiUrl + "/business-account-details", requestOptions)
      .then((response) => {

        const rfq_products = [];
        response.data.data.product &&
          response.data.data.product.map((el) => {
            return rfq_products.push({
              // product_id: el.product_id,
              product_id:
                el.product_id == null ? (el.product_id = 0) : el.product_id,
              quantity: el.productQuantity,
            });
          });
        this.setState(
          {
            main_rfq_id: response.data.data.id,
            rfq_id: response.data.data.id,
            business_account_id: response.data.data.company_id,
            rfq_products: rfq_products,
            customer_id: response.data.data.customer_id,
          },
          () => {
            if (products && products.length &&  response.data.data.company_id) {
              this.getDetailsbyAccountName(this.state.business_account_id);
              this.displayAllProducts(products, productMap);
            } else if (response.data.data.company_id) {
              this.getDetailsbyAccountName(this.state.business_account_id);
              this.displayAllProducts();
            } else {
              this.getPhoneValue2();
              this.displayAllProducts(this.state.rfq_products);
            }
          }
        );
      })
      .catch((error) => {
        console.log("error", error);
      });
  };

  getSelectAddress = (e) => {
    if (e.target.value) {
      this.setState({
        selectedGstAddress: e.target.value,
        // selectedAddress: e.target.value,
        billing_zone_name: "",
      });
      let index = this.state.addressArray.filter(
        (el) => el.address_id == e.target.value
      )[0];
      if (index) {
        this.setState({
          // billing_first_name: index.firstname || "",
          billing_first_name: index.firstname ? index.firstname : index.name,
          // billing_last_name: index.lastname,
          billing_name: index.name || "",
          billing_company_name: index.company || "",
          billing_mobile: index.mobile || "",
          billing_address_1: index.address_1 || "",
          billing_address_2: index.address_2 || "",
          billing_city: index.city || "",
          billing_zone_id: index.zone_id || "",
          billing_postcode: index.postcode || "",
          billing_country: 99,
          billing_country_name: "India",
          billing_gst: index.gstin ? index.gstin : "",
          gstDefault: index.gstin ? index.gstin : "",
          // billing_gst: "",
          address_type: index.address_type ? index.address_type : "",
          gstDisable: true,
        });
      }
    }
  };

  getSelectShippingAddress = (e) => {
    if (e.target.value) {
      this.setState({
        selectedShippingAddress: e.target.value,
      });
      let index = this.state.addressArray.filter(
        (el) => el.address_id == e.target.value
      )[0];
      this.setState({
        // shipping_first_name: index.firstname,
        shipping_first_name: index.firstname ? index.firstname : index.name,
        shipping_name: index.name,
        // shipping_last_name: index.lastname,
        // shipping_company_name: index.company,
        shipping_address_type: index.address_type,
        shipping_company_name: index.company,
        shipping_mobile: index.mobile,
        shipping_address_1: index.address_1,
        shipping_address_2: index.address_2,
        shipping_city: index.city,
        shipping_zone_id: index.zone_id,
        shipping_postcode: index.postcode,
        shipping_country: 99,
        shipping_country_name: "India",
        shipping_gst: index.gstin,
      });
    }
  };

  updateQuantityWithPrices = (data) => {
    let products = this.state.getAllProductsWithDetailForList;
    products[data.index].quantity = data.quantity;

    // products[data.index].special_tp = data.TP;
    // products[data.index].ticket_id = data.TicketID;
    // products[data.index].vendor_id = data.selectVendor;
    let productsArray = [];
    let subTotal = 0;
    let shippingRate = 0;
    let updatedQuoteGross = 0;
    let updatedMinQuoteGross = 0;
    let discountOnMrpAmount = 0;
    let totalDiscountOnMrpAmount = 0;
    let netTotalAmount = 0;
    let totalMrpAmount = 0;
    let subTotalAmount = 0;
    let totalTaxAmt = 0;
    let totalDeliveryRate = 0;

    for (let i = 0; i < products.length; i++) {
      let vendor = data.vendordata;
      let transfer_price =
        products[i].special_tp == 0
          ? products[i].transfer_price
          : products[i].special_tp;
      //   let transfer_price = products[i].transfer_price;
      // let ticket_id = products[i].ticket_id.length==0? this.state.ticket_id : products[i].ticket_id;
      // let vendor_id = products[i].vendor_id.length==0? this.state.vendor_id : products[i].vendor_id;
      let listPrice =
        (Number(products[i].markup) * Number(transfer_price)) / 100 +
        Number(transfer_price);

      let listPriceFinal = Number(listPrice);
      let netAmount = listPriceFinal * Number(products[i].quantity);
      let netAmountOrg =
        Number(products[i].list_price_org) * Number(products[i].quantity);
      let netAmountFinal = Number(netAmount);
      let netAmountOrgFinal = Number(netAmountOrg);
      let tax_in_amount = (netAmountFinal * Number(products[i].tax)) / 100;
      let tax_in_amount_final = Number(tax_in_amount);
      let total = Number(tax_in_amount_final) + Number(netAmountFinal);

      let tpInToQuantity =
        Number(transfer_price) * Number(products[i].quantity);
      let tpInToQuantityR = (netAmountFinal - tpInToQuantity) / netAmountFinal;

      let grossMargin = Number(tpInToQuantityR * 100);
      subTotal = subTotal + total;

      updatedQuoteGross += (grossMargin / 100) * netAmountFinal;
      updatedMinQuoteGross +=
        (Number(products[i].minimum_gross_margin) / 100) *
        transfer_price *
        products[i].quantity;
      discountOnMrpAmount =
        ((Number(products[i].mrp) - Number(listPriceFinal)) /
          Number(products[i].mrp)) *
        100;
      let netTotalSum = netTotalAmount + netAmountFinal;
      let totalDiscount =
        Number(products[i].mrp) * Number(products[i].quantity) - netTotalSum;
      totalDiscountOnMrpAmount += totalDiscount;
      let totalMrp = Number(products[i].mrp) * Number(products[i].quantity);
      totalMrpAmount += totalMrp;
      let netSubTotal =  Number(totalDiscountOnMrpAmount) - Number(totalMrpAmount);
      subTotalAmount =- netSubTotal;
      let totalTax = Number(tax_in_amount);
      totalTaxAmt += totalTax;
      let systemDeliveryRate =
        (Number(products[i].deliveryCharges) * netTotalSum) / 100;
      totalDeliveryRate += systemDeliveryRate * (118 / 100);

      productsArray.push({
        // productsArray[i] = {
        product_id: products[i].product_id,
        name: products[i].name,
        quantity_type: products[i].quantity_type,
        tax: products[i].tax,
        mrp: Number(products[i].mrp),
        model: products[i].model,
        transfer_price: Number(products[i].transfer_price),
        available_stock: products[i].available_stock,
        special_tp: products[i].special_tp,

        ticket_id: products[i].ticket_id,
        vendor_id: products[i].vendor_id,
        // vendor: data.selectVendor,
        markup: products[i].markup,
        quantity: products[i].quantity,
        list_price: listPriceFinal,
        list_price_org: Number(products[i].list_price_org),
        net_amount: netAmountFinal,
        net_amount_org: netAmountOrgFinal,
        tax_in_percentage: Number(products[i].tax_in_percentage),
        tax_in_amount: tax_in_amount_final,
        total: Number(total),
        gross_margin: grossMargin,
        vendor: products[i].vendor,
        minimum_gross_margin: Number(products[i].minimum_gross_margin),
        discount_on_mrp: discountOnMrpAmount,
        deliveryCharges: Number(products[i].deliveryCharges),
      });
    }
    this.setState({
      quoteGrossMargin: parseFloat(updatedQuoteGross),
      quoteMinimumGrossMargin: parseFloat(updatedMinQuoteGross),
    });
    this.setState({
      discountOnMrp: discountOnMrpAmount,
      totalDiscountOnMrp: totalDiscountOnMrpAmount,
      totalMrp: totalMrpAmount,
      totalNetAmount: subTotalAmount,
      totalTaxAmount: totalTaxAmt,
      // totalSystemDeliverRate:
      //   this.state.totalSystemDeliverRate + totalDeliveryRate,
      totalSystemDeliverRate: totalDeliveryRate,  
      // shippingCharges:  totalDeliveryRate,
    });
    let grandTotalPrice = (Number(totalMrpAmount) -
    Number(totalDiscountOnMrpAmount)) + 
    Number(totalTaxAmt) + 
    Number(totalDeliveryRate)
    // this.setState({
    //   getAllProductsWithDetailForList: productsArray,
    //   subTotal: Number(subTotal),
    //   triggerRender:this.state.triggerRender > 10 ? 0 :this.state.triggerRender + 1 ,
    //   shippingCharge: Number(shippingRate),
    //   grandTotal: Number(grandTotalPrice),
    // });
    let grandTotalPrice1 =
    Number(subTotalAmount) +
    Number(totalTaxAmt) +
    Number(this.state.shippingCharges);
    if(!(
      this.state.quote_status != "Expired" &&
      this.state.quote_status == "Pending For Approval" &&
      this.state.allowedSlugs.includes("approve")
    )){
      this.setState({shippingCharges:  totalDeliveryRate})
    }  
    if(!(
      this.state.quote_status != "Expired" &&
      this.state.quote_status == "Pending For Approval" &&
      this.state.allowedSlugs.includes("approve")
    )) {
    this.setState({
      triggerRender:this.state.triggerRender > 10 ? 0 :this.state.triggerRender + 1 ,
      grandTotal: Number(grandTotalPrice),
    });
  }else {
    this.setState({
      grandTotal: Number(grandTotalPrice1),
    });
  }
  this.setState({
    getAllProductsWithDetailForList: productsArray,
    subTotal: Number(subTotal),
  });
  };

  updateMarkupWithPrices = (data) => {
    let products = this.state.getAllProductsWithDetailForList;
    products[data.index].markup = data.markup;
    // products[data.index].special_tp = data.special_tp;
    // products[data.index].ticket_id = data.TicketID;
    // products[data.index].vendor_id = data.selectVendor;

    let productsArray = [];
    let subTotal = 0;
    let shippingRate = 0;
    let updatedMinQuoteGross = 0;
    let updatedQuoteGross = 0;
    let discountOnMrpAmount = 0;
    let totalDiscountOnMrpAmount = 0;
    let netTotalAmount = 0;
    let totalMrpAmount = 0;
    let subTotalAmount = 0;
    let totalTaxAmt = 0;
    let totalDeliveryRate = 0;

    for (let i = 0; i < products.length; i++) {
      let vendor = data.vendordata;
      let transfer_price =
        products[i].special_tp == 0
          ? products[i].transfer_price
          : products[i].special_tp;
      //   let transfer_price = products[i].transfer_price;
      // let ticket_id = products[i].ticket_id.length==0? this.state.ticket_id : products[i].ticket_id;
      // let vendor_id = products[i].vendor_id.length==0? this.state.vendor_id : products[i].vendor_id;

      let listPrice =
        (Number(products[i].markup) * Number(transfer_price)) / 100 +
        Number(transfer_price);
      let listPriceFinal = Number(listPrice);
      let netAmount = listPriceFinal * Number(products[i].quantity);
      let netAmountOrg =
        Number(products[i].list_price_org) * Number(products[i].quantity);
      let netAmountFinal = Number(netAmount);
      let netAmountOrgFinal = Number(netAmountOrg);
      let tax_in_amount = (netAmount * Number(products[i].tax)) / 100;
      let tax_in_amount_final = Number(tax_in_amount);
      let total = Number(tax_in_amount_final) + Number(netAmountFinal);

      let tpInToQuantity =
        Number(transfer_price) * Number(products[i].quantity);
      let tpInToQuantityR = (netAmountFinal - tpInToQuantity) / netAmountFinal;

      let grossMargin = Number(tpInToQuantityR * 100);
      subTotal = subTotal + total;

      updatedQuoteGross += (grossMargin / 100) * netAmountFinal;
      updatedMinQuoteGross +=
        (Number(products[i].minimum_gross_margin) / 100) *
        transfer_price *
        products[i].quantity;
      discountOnMrpAmount =
        ((Number(products[i].mrp) - Number(listPriceFinal)) /
          Number(products[i].mrp)) *
        100;
      let netTotalSum = netTotalAmount + netAmountFinal;
      let totalDiscount =
        Number(products[i].mrp) * Number(products[i].quantity) - netTotalSum;
      totalDiscountOnMrpAmount += totalDiscount;
      let totalMrp = Number(products[i].mrp) * Number(products[i].quantity);
      totalMrpAmount += totalMrp;
      let netSubTotal =  Number(totalDiscountOnMrpAmount) - Number(totalMrpAmount);
      subTotalAmount =- netSubTotal;
      // let totalTax = Number(products[i].tax_in_amount);
      // totalTaxAmt += totalTax;
      let totalTax = Number(tax_in_amount);
      totalTaxAmt += totalTax;
      let systemDeliveryRate =
        (Number(products[i].deliveryCharges) * netTotalSum) / 100;
      totalDeliveryRate += systemDeliveryRate * (118 / 100);
      productsArray.push({
        product_id: products[i].product_id,
        name: products[i].name,
        quantity_type: products[i].quantity_type,
        tax: products[i].tax,
        mrp: Number(products[i].mrp),
        model: products[i].model,
        transfer_price: products[i].transfer_price,
        available_stock: products[i].available_stock,
        markup: products[i].markup,
        special_tp: products[i].special_tp,

        ticket_id: products[i].ticket_id,
        vendor_id: products[i].vendor_id,
        // vendor_id: products[i].vendor_id,
        quantity: products[i].quantity,
        list_price: listPriceFinal,
        list_price_org: Number(products[i].list_price_org),
        net_amount: netAmountFinal,
        net_amount_org: netAmountOrgFinal,
        tax_in_percentage: products[i].tax_in_percentage,
        tax_in_amount: tax_in_amount_final,
        total: Number(total),
        gross_margin: grossMargin,
        vendor: products[i].vendor,
        minimum_gross_margin: Number(products[i].minimum_gross_margin),
        discount_on_mrp: discountOnMrpAmount,
        deliveryCharges: Number(products[i].deliveryCharges),
      });
    }
    this.setState({
      quoteGrossMargin: parseFloat(updatedQuoteGross),
      quoteMinimumGrossMargin: parseFloat(updatedMinQuoteGross),
    });
    this.setState({
      discountOnMrp: discountOnMrpAmount,
      totalDiscountOnMrp: totalDiscountOnMrpAmount,
      totalMrp: totalMrpAmount,
      totalNetAmount: subTotalAmount,
      totalTaxAmount: totalTaxAmt,
      // totalSystemDeliverRate:
      //   this.state.totalSystemDeliverRate + totalDeliveryRate,
      totalSystemDeliverRate: totalDeliveryRate,  
      // shippingCharges:  totalDeliveryRate,
    });
    let grandTotalPrice = (Number(totalMrpAmount) - 
    Number(totalDiscountOnMrpAmount)) +
    Number(totalTaxAmt) +
    Number(totalDeliveryRate)  
    // this.setState({
    //   getAllProductsWithDetailForList: productsArray,
    //   triggerRender:this.state.triggerRender>10 ? 0 :this.state.triggerRender + 1,
    //   subTotal: parseFloat(subTotal),
    //   shippingCharge: parseFloat(shippingRate),
    //   grandTotal: Number(grandTotalPrice),
    // });
    let grandTotalPrice1 =
    Number(subTotalAmount) +
    Number(totalTaxAmt) +
    Number(this.state.shippingCharges);
    if(!(
      this.state.quote_status != "Expired" &&
      this.state.quote_status == "Pending For Approval" &&
      this.state.allowedSlugs.includes("approve")
    )){
      this.setState({shippingCharges:  totalDeliveryRate})
    }  
    if(!(
      this.state.quote_status != "Expired" &&
      this.state.quote_status == "Pending For Approval" &&
      this.state.allowedSlugs.includes("approve")
    )) {
    this.setState({
      triggerRender:this.state.triggerRender > 10 ? 0 :this.state.triggerRender + 1 ,
      grandTotal: Number(grandTotalPrice),
    });
  }else {
    this.setState({
      grandTotal: Number(grandTotalPrice1),
    });
  }
  this.setState({
    getAllProductsWithDetailForList: productsArray,
    subTotal: Number(subTotal),
  });
  };

  specialTP = (data, type) => {
    let products = this.state.getAllProductsWithDetailForList;
    if (type === "sourcing") {
      for (let product of products) {
        product.vendor_id = data.get(product.product_id).vendor_id;
        product.ticket_id = this.state.rfq_id.toString();
        product.special_tp = data.get(product.product_id).sales_transfer_price;
      }
    }else {
    products[data.index].special_tp = data.TP;
    products[data.index].ticket_id = data.TicketID;
    products[data.index].vendor_id = data.selectVendor;
    }
    let productsArray = [];
    let subTotal = 0;
    let shippingRate = 0;
    let updatedMinQuoteGross = 0;
    let updatedQuoteGross = 0;
    let discountOnMrpAmount = 0;
    let totalDiscountOnMrpAmount = 0;
    let netTotalAmount = 0;
    let totalMrpAmount = 0;
    let subTotalAmount = 0;
    let totalTaxAmt = 0;
    let totalDeliveryRate = 0;

    for (let i = 0; i < products.length; i++) {
      let transfer_price =
        products[i].special_tp == 0
          ? products[i].transfer_price
          : products[i].special_tp;
      let ticket_id =
        products[i].ticket_id.length == 0
          ? this.state.ticket_id
          : products[i].ticket_id;
      let vendor_id =
        products[i].vendor_id.length == 0
          ? this.state.vendor_id
          : products[i].vendor_id;
      let vendor = data.vendordata;

      // CALCULATIONS FOR DIFFERENT SECTIONS OF PRODUCT DETAIL MODAL

      let listPrice =
        (Number(products[i].markup) * Number(transfer_price)) / 100 +
        Number(transfer_price);

      let listPriceFinal = Number(listPrice);
      let netAmount = listPriceFinal * Number(products[i].quantity);
      let netAmountOrg =
           Number(listPriceFinal) * Number(products[i].quantity);
        // Number(products[i].list_price_org) * Number(products[i].quantity);
      let netAmountFinal = Number(netAmount);
      let netAmountOrgFinal = Number(netAmountOrg);
      let tax_in_amount = (netAmount * Number(products[i].tax)) / 100;
      let tax_in_amount_final = Number(tax_in_amount);
      let total = Number(tax_in_amount_final) + Number(netAmountFinal);

      let tpInToQuantity =
        Number(transfer_price) * Number(products[i].quantity);

      let tpInToQuantityR;
      if (netAmountFinal == 0) {
        tpInToQuantityR = 0;
      } else {
        tpInToQuantityR = (netAmountFinal - tpInToQuantity) / netAmountFinal;
      }

      let grossMargin = Number(tpInToQuantityR * 100);
      subTotal = subTotal + total;
      updatedQuoteGross += (grossMargin / 100) * netAmountFinal;
      updatedMinQuoteGross +=
        (Number(products[i].minimum_gross_margin) / 100) *
        transfer_price *
        products[i].quantity;
      discountOnMrpAmount =
        ((Number(products[i].mrp) - Number(listPriceFinal)) /
          Number(products[i].mrp)) *
        100;
      let netTotalSum = netTotalAmount + netAmountFinal;
      let totalDiscount =
        Number(products[i].mrp) * Number(products[i].quantity) - netTotalSum;
      totalDiscountOnMrpAmount += totalDiscount;
      let totalMrp = Number(products[i].mrp) * Number(products[i].quantity);
      totalMrpAmount += totalMrp;
      let netSubTotal =  Number(totalDiscountOnMrpAmount) - Number(totalMrpAmount);
      subTotalAmount =- netSubTotal;
      let totalTax = Number(products[i].tax_in_amount);
      totalTaxAmt += totalTax;
      let systemDeliveryRate =
      (Number(products[i].deliveryCharges) * netTotalSum) / 100;
      totalDeliveryRate += systemDeliveryRate * (118 / 100);
      productsArray.push({
        // productsArray[i] = {
        product_id: products[i].product_id,
        name: products[i].name,
        quantity_type: products[i].quantity_type,
        tax: products[i].tax,
        mrp: Number(products[i].mrp),
        model: products[i].model,
        transfer_price: products[i].transfer_price,
        special_tp: products[i].special_tp,
        ticket_id: ticket_id,
        vendor_id: vendor_id,
        // ticket_id: products[i].ticket_id,
        // vendor_id: products[i].vendor_id,
        available_stock: products[i].available_stock,
        markup: products[i].markup,
        quantity: products[i].quantity,
        list_price: listPriceFinal,
        // list_price_org: products[i].list_price_org,
        list_price_org: listPriceFinal,
        net_amount: Number(netAmountFinal),
        net_amount_org: netAmountOrgFinal,
        tax_in_percentage: products[i].tax_in_percentage,
        tax_in_amount: tax_in_amount_final,
        total: Number(total),
        gross_margin: grossMargin ? grossMargin : 0,
        vendor: products[i].vendor,
        minimum_gross_margin: Number(products[i].minimum_gross_margin),
        discount_on_mrp: discountOnMrpAmount,
        deliveryCharges: Number(products[i].deliveryCharges)
      });
    }
    this.setState({
      quoteGrossMargin: parseFloat(updatedQuoteGross),
      quoteMinimumGrossMargin: parseFloat(updatedMinQuoteGross),
    });
    this.setState({
      discountOnMrp: discountOnMrpAmount,
      totalDiscountOnMrp: totalDiscountOnMrpAmount,
      totalMrp: totalMrpAmount,
      totalNetAmount: subTotalAmount,
      totalTaxAmount: totalTaxAmt,
      totalSystemDeliverRate: totalDeliveryRate,  
      // shippingCharges:  totalDeliveryRate,
    });
    let grandTotalPrice =
      Number(totalMrpAmount) -
      Number(totalDiscountOnMrpAmount) +
      Number(totalTaxAmt) +
      Number(totalDeliveryRate)
    // this.setState({
    //   getAllProductsWithDetailForList: productsArray,
    //   triggerRender:this.state.triggerRender>10 ? 0 :this.state.triggerRender + 1,
    //   subTotal: parseFloat(subTotal),
    //   shippingCharge: parseFloat(shippingRate),
    //   grandTotal: Number(grandTotalPrice),
    // });
    let grandTotalPrice1 =
    Number(subTotalAmount) +
    Number(totalTaxAmt) +
    Number(this.state.shippingCharges);
    if(!(
      this.state.quote_status != "Expired" &&
      this.state.quote_status == "Pending For Approval" &&
      this.state.allowedSlugs.includes("approve")
    )){
      this.setState({shippingCharges:  totalDeliveryRate})
    }  
    if(!(
      this.state.quote_status != "Expired" &&
      this.state.quote_status == "Pending For Approval" &&
      this.state.allowedSlugs.includes("approve")
    )) {
    this.setState({
      triggerRender:this.state.triggerRender > 10 ? 0 :this.state.triggerRender + 1 ,
      grandTotal: Number(grandTotalPrice),
    });
  }else {
    this.setState({
      grandTotal: Number(grandTotalPrice1),
    });
  }
  this.setState({
    getAllProductsWithDetailForList: productsArray,
    subTotal: Number(subTotal),
  });
  };
  updateTotal = (data) => {
    let products = this.state.getAllProductsWithDetailForList;
    products[data.index].markup = data.markup;
    products[data.index].list_price = data.listPrice;
    // products[data.index].list_price = data.netAmount;

    let productsArray = [];
    let subTotal = 0;
    let shippingRate = 0;
    let updatedMinQuoteGross = 0;
    let updatedQuoteGross = 0;
    let discountOnMrpAmount = 0;
    let totalDiscountOnMrpAmount = 0;
    let netTotalAmount = 0;
    let totalMrpAmount = 0;
    let subTotalAmount = 0;
    let totalTaxAmt = 0;
    let totalDeliveryRate = 0;

    for (let i = 0; i < products.length; i++) {
      let vendor = data.vendordata;
      let transfer_price =
        products[i].special_tp == 0
          ? products[i].transfer_price
          : products[i].special_tp;
      // let ticket_id = products[i].ticket_id.length==0? this.state.ticket_id : products[i].ticket_id;
      // let vendor_id = products[i].vendor_id.length==0? this.state.vendor_id : products[i].vendor_id;

      let listPrice =
        (Number(products[i].markup) * Number(transfer_price)) / 100 +
        Number(transfer_price);
      if (products[i].special_tp !== 0) {
        listPrice = products[i].list_price;
      }
      if (data.index === i) {
        listPrice = data.listPrice;
      }
      let listPriceFinal = Number(listPrice);
      let netAmount = listPriceFinal * Number(products[i].quantity);
      let netAmountOrg =
        Number(products[i].list_price_org) * Number(products[i].quantity);
      let netAmountFinal = Number(netAmount);
      let netAmountOrgFinal = Number(netAmountOrg);
      let tax_in_amount = (netAmount * Number(products[i].tax)) / 100;
      let tax_in_amount_final = Number(tax_in_amount);
      let total = Number(tax_in_amount_final) + Number(netAmountFinal);

      let tpInToQuantity =
        Number(transfer_price) * Number(products[i].quantity);
      let tpInToQuantityR = (netAmountFinal - tpInToQuantity) / netAmountFinal;

      let grossMargin = Number(tpInToQuantityR * 100);
      subTotal = subTotal + total;
      updatedQuoteGross += (grossMargin / 100) * netAmountFinal;
      updatedMinQuoteGross +=
        (Number(products[i].minimum_gross_margin) / 100) *
        transfer_price *
        products[i].quantity;
      discountOnMrpAmount =
        ((Number(products[i].mrp) - Number(listPriceFinal)) /
          Number(products[i].mrp)) *
        100;
      let netTotalSum = netTotalAmount + netAmountFinal;
      let totalDiscount =
        Number(products[i].mrp) * Number(products[i].quantity) - netTotalSum;
      totalDiscountOnMrpAmount += totalDiscount;
      let totalMrp = Number(products[i].mrp) * Number(products[i].quantity);
      totalMrpAmount += totalMrp;
      let netSubTotal =  Number(totalDiscountOnMrpAmount) - Number(totalMrpAmount);
      subTotalAmount =- netSubTotal;
      let totalTax = Number(products[i].tax_in_amount);
      totalTaxAmt += totalTax;
      let systemDeliveryRate =
        (Number(products[i].deliveryCharges) * netTotalSum) / 100;
      totalDeliveryRate += systemDeliveryRate * (118 / 100);
      productsArray.push({
        product_id: products[i].product_id,
        name: products[i].name,
        quantity_type: products[i].quantity_type,
        tax: products[i].tax,
        mrp: Number(products[i].mrp),
        model: products[i].model,
        transfer_price: products[i].transfer_price,
        available_stock: products[i].available_stock,
        markup: products[i].markup,
        special_tp: products[i].special_tp,
        ticket_id: products[i].ticket_id,
        vendor_id: products[i].vendor_id,
        // vendor_id: products[i].vendor_id,
        quantity: products[i].quantity,
        list_price: listPriceFinal,
        list_price_org: Number(products[i].list_price_org),
        net_amount: netAmountFinal,
        net_amount_org: netAmountOrgFinal,
        tax_in_percentage: products[i].tax_in_percentage,
        tax_in_amount: tax_in_amount_final,
        total: Number(total),
        gross_margin: grossMargin,
        vendor: products[i].vendor,
        minimum_gross_margin: Number(products[i].minimum_gross_margin),
        discount_on_mrp: discountOnMrpAmount,
        deliveryCharges: Number(products[i].deliveryCharges),
      });
    }
    this.setState({
      quoteGrossMargin: parseFloat(updatedQuoteGross),
      quoteMinimumGrossMargin: parseFloat(updatedMinQuoteGross),
    });
    this.setState({
      discountOnMrp: discountOnMrpAmount,
      totalDiscountOnMrp: totalDiscountOnMrpAmount,
      totalMrp: totalMrpAmount,
      totalNetAmount: subTotalAmount,
      totalTaxAmount: totalTaxAmt,
      // totalSystemDeliverRate:
      //   this.state.totalSystemDeliverRate + totalDeliveryRate,
      totalSystemDeliverRate: totalDeliveryRate,  
      // shippingCharges:  totalDeliveryRate,
    });
    let grandTotalPrice = (Number(totalMrpAmount) - 
    Number(totalDiscountOnMrpAmount)) + 
    Number(totalTaxAmt) +
    Number(totalDeliveryRate)
    // this.setState({
    //   getAllProductsWithDetailForList: productsArray,
    //   triggerRender:this.state.triggerRender > 10 ? 0 :this.state.triggerRender + 1,
    //   subTotal: parseFloat(subTotal),
    //   shippingCharge: parseFloat(shippingRate),
    //   grandTotal: Number(grandTotalPrice),
    // });
    let grandTotalPrice1 =
    Number(subTotalAmount) +
    Number(totalTaxAmt) +
    Number(this.state.shippingCharges);
    if(!(
      this.state.quote_status != "Expired" &&
      this.state.quote_status == "Pending For Approval" &&
      this.state.allowedSlugs.includes("approve")
    )){
      this.setState({shippingCharges:  totalDeliveryRate})
    }  
    if(!(
      this.state.quote_status != "Expired" &&
      this.state.quote_status == "Pending For Approval" &&
      this.state.allowedSlugs.includes("approve")
    )) {
    this.setState({
      triggerRender:this.state.triggerRender > 10 ? 0 :this.state.triggerRender + 1 ,
      grandTotal: Number(grandTotalPrice),
    });
  }else {
    this.setState({
      grandTotal: Number(grandTotalPrice1),
    });
  }
  this.setState({
    getAllProductsWithDetailForList: productsArray,
    subTotal: Number(subTotal),
  });
  };

  removeItemFromMainList = (e) => {
    let id = e;
    let products = this.state.getAllProductsWithDetailForList;
    products.splice(id, 1);

    let productsArray = [];
    let subTotal = 0;
    let taxTotal = 0;
    let shippingRate = 0;
    let updatedMinQuoteGross = 0;
    let updatedQuoteGross = 0;
    let discountOnMrpAmount = 0;
    let totalDiscountOnMrpAmount = 0;
    let netTotalAmount = 0;
    let subTotalAmount = 0;
    let totalMrpAmount = 0;
    let totalTaxAmt = 0;
    let totalDeliveryRate = 0;

    for (let i = 0; i < products.length; i++) {
      let subTotal1 = parseFloat(products[i].total);
      subTotal = subTotal + subTotal1;
      let taxTotal1 = parseFloat(products[i].tax_in_amount);
      taxTotal = taxTotal + taxTotal1;

      updatedQuoteGross +=
        (products[i].gross_margin / 100) * products[i].net_amount;
      updatedMinQuoteGross +=
        (Number(products[i].minimum_gross_margin) / 100) *
        products[i].transfer_price *
        products[i].quantity;
      discountOnMrpAmount =
        ((Number(products[i].mrp) - Number(products[i].list_price)) /
          Number(products[i].mrp)) *
        100;
      let netTotalSum = netTotalAmount + products[i].net_amount;
      let totalDiscount =
        Number(products[i].mrp) * Number(products[i].quantity) - netTotalSum;
      totalDiscountOnMrpAmount += totalDiscount;
      let totalMrp = Number(products[i].mrp) * Number(products[i].quantity);
      totalMrpAmount += totalMrp;
      let netSubTotal =  Number(totalDiscountOnMrpAmount) - Number(totalMrpAmount);
      subTotalAmount =- netSubTotal;
      let totalTax = Number(products[i].tax_in_amount);
      totalTaxAmt += totalTax;
      let systemDeliveryRate =
        (Number(products[i].deliveryCharges) * netTotalSum) / 100;
      totalDeliveryRate += systemDeliveryRate * (118 / 100);
      productsArray.push({
        product_id: products[i].product_id,
        name: products[i].name,
        quantity_type: products[i].quantity_type,
        tax: products[i].tax,
        mrp: products[i].mrp,
        model: products[i].model,
        transfer_price: products[i].transfer_price,
        markup: products[i].markup,
        quantity: products[i].quantity,
        special_tp: this.state.special_tp,
        ticket_id: products[i].ticket_id
          ? products[i].ticket_id
          : this.state.ticket_id,
        vendor_id: products[i].vendor_id
          ? products[i].vendor_id
          : this.state.vendor_id,
        list_price: products[i].list_price,
        available_stock: products[i].available_stock,
        list_price_org: products[i].list_price_org,
        net_amount: products[i].net_amount,
        net_amount_org: products[i].net_amount_org,
        tax_in_percentage: products[i].tax,
        tax_in_amount: products[i].tax_in_amount,
        total: products[i].total,
        gross_margin: products[i].gross_margin,
        minimum_gross_margin: Number(products[i].minimum_gross_margin),
        discount_on_mrp: discountOnMrpAmount,
        deliveryCharges: Number(products[i].deliveryCharges),
      });
    }
    this.setState({
      quoteGrossMargin: parseFloat(updatedQuoteGross),
      quoteMinimumGrossMargin: parseFloat(updatedMinQuoteGross),
    });
    this.setState({
      discountOnMrp: discountOnMrpAmount,
      totalDiscountOnMrp: totalDiscountOnMrpAmount,
      totalMrp: totalMrpAmount,
      totalNetAmount: subTotalAmount,
      totalTaxAmount: totalTaxAmt,
      totalSystemDeliverRate: totalDeliveryRate,  
      // shippingCharges:  totalDeliveryRate,
    });
    // again commit
    let grandTotalPrice =
      Number(subTotalAmount) +
      Number(totalTaxAmt) +
      Number(totalDeliveryRate);
    // this.setState({
    //   getAllProductsWithDetailForList: products,
    //   displayProductArray: productsArray,
    //   triggerRender:this.state.triggerRender > 10 ? 0 :this.state.triggerRender + 1,
    //   subTotal: parseFloat(subTotal),
    //   shippingCharge: parseFloat(shippingRate),
    //   grandTotal: Number(grandTotalPrice),
    // });
    let grandTotalPrice1 =
    Number(subTotalAmount) +
    Number(totalTaxAmt) +
    Number(this.state.shippingCharges);
    if(!(
      this.state.quote_status != "Expired" &&
      this.state.quote_status == "Pending For Approval" &&
      this.state.allowedSlugs.includes("approve")
    )){
      this.setState({shippingCharges:  totalDeliveryRate})
    }  
    if(!(
      this.state.quote_status != "Expired" &&
      this.state.quote_status == "Pending For Approval" &&
      this.state.allowedSlugs.includes("approve")
    )) {
    this.setState({
      triggerRender:this.state.triggerRender > 10 ? 0 :this.state.triggerRender + 1 ,
      grandTotal: Number(grandTotalPrice),
    });
  }else {
    this.setState({
      grandTotal: Number(grandTotalPrice1),
    });
  }
  this.setState({
    getAllProductsWithDetailForList: productsArray,
    subTotal: Number(subTotal),
  });
  };

  displayAllProducts = (e, productMap) => {
    let productsMap = new Map();
    if (productMap) {
      productsMap = productMap;
    }

    let listedProductsArray = e == undefined ? this.state.rfq_products : e;
    let productID = [];
    listedProductsArray.map((item) => {
      return productID.push(item.product_id);
    });
    let productID2 = [];
    this.state.getAllProductsWithDetailForList.map((item) => {
      return productID2.push(item.product_id);
    });
    let onlyNewId = [];
    if (productID2.length == 0) {
      onlyNewId = productID;
    } else {
      onlyNewId = productID.filter((el) => !productID2.includes(el));
    }

    // console.log(onlyNewId, "unique PRODUCTS_ID");

    let uniqueChars = [...new Set(onlyNewId)];
    let data = localStorage.getItem("QuoteFromSourcing");
    let reqData = {
      product_id: uniqueChars,
      customer_id: this.state.customer_id,
    };
    if(data){
      reqData ={...reqData,status:"2"}
    }
    const requestoption = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${getUserDetails.token}`,
      },
      data: reqData,
    };
    return axios(apiUrl + "/get-product-detail-by-id", requestoption)
      .then((data) => {
        let products = data.data.data;
        // this section start
        // let products3 = data.data.data.concat(this.state.getAllProductsWithDetailForList)
        // console.log("product3",products3)
        // const ids = products3.map(item => item.product_id)
        // let filtered = products3.filter(({product_id}, index) => !ids.includes(product_id, index + 1))
        // console.log("filtered",filtered)
        // products= filtered;
        // product code will be comment for old code and section end between the section start and section end

        let productsArray = [];

        let subTotal = 0;
        let shippingRate = 0;
        let updatedMinQuoteGross = 0;
        let updatedQuoteGross = 0;
        let updatedDiscountOnMrp = 0;
        let totalDiscountOnMrpAmount = 0;
        let netTotalAmount = 0;
        let totalMrpAmount = 0;
        let totalTaxAmt = 0;
        let subTotalAmount = 0;
        let totalDeliveryRate = 0;
        let totalNetAmountFinal = 0;

        for (let i = 0; i < products.length; i++) {
          let index = {};

          index = listedProductsArray.filter(
            (el) => el.product_id === products[i].product_id
          )[0];
          console.log("index", index);

          let listPrice = products[i].list_price;
          let listPriceFinal = Number(listPrice);
          let netAmount = listPriceFinal * index.quantity;
          let netAmountOrg = listPriceFinal * index.quantity;
          let netAmountFinal = Number(netAmount);
          let netAmountOrgFinal = Number(netAmountOrg);
          let tax_in_amount = (netAmount * products[i].tax) / 100;
          let tax_in_amount_final = parseFloat(tax_in_amount);

          var total =
            parseFloat(tax_in_amount_final) + parseFloat(netAmountFinal);

          let tpInToQuantity = products[i].transfer_price * index.quantity;
          // let tpInToQuantity = products[i].transfer_price * this.state.productQuantity;
          let tpInToQuantityR =
            (netAmountFinal - tpInToQuantity) / netAmountFinal;
          let grossMargin =
            netAmountFinal == 0
              ? Number((tpInToQuantity * 100))
              : Number((tpInToQuantityR * 100));
          subTotal = subTotal + Number(total);
          // console.log("SUBTOTAL", subTotal, "Total", total)
          updatedQuoteGross += (grossMargin / 100) * netAmountFinal;
          updatedMinQuoteGross +=
            (Number(products[i].minGrossMargin) / 100) *
            Number(products[i].transfer_price) *
            (products[i].quantity ? products[i].quantity : index.quantity);
          updatedDiscountOnMrp =
            ((Number(products[i].price) - Number(products[i].list_price)) /
              Number(products[i].price)) *
            100;
          let netTotalSum = netTotalAmount + netAmountFinal;
          const totalDiscount =
            Number(products[i].mrp ? products[i].mrp : products[i].price) *
            Number(
              products[i].quantity ? products[i].quantity : index.quantity
            ) -
            netTotalSum;
          totalDiscountOnMrpAmount += totalDiscount;
          let totalMrp = Number(products[i].price) ? Number(products[i].price) : Number(products[i].mrp) * Number(products[i].pack_quantity);            
          totalMrpAmount += totalMrp;
          let totalTax = tax_in_amount ? tax_in_amount : Number(products[i].tax_in_amount);
          totalTaxAmt += totalTax;
          let netSubTotal =  Number(totalDiscountOnMrpAmount) - Number(totalMrpAmount);
          subTotalAmount =- netSubTotal;
          let systemDeliveryRate =
            (Number(products[i].deliveryCharges) * netTotalSum) / 100;
          totalDeliveryRate += systemDeliveryRate * (118 / 100);
          totalNetAmountFinal += netAmountFinal;
          productsArray.push({
            product_id: products[i].product_id,
            name: products[i].name,
            brand: products[i].brand,
            quantity_type: products[i].quantity_type,
            tax: products[i].tax,
            mrp: products[i].mrp ? products[i].mrp : products[i].price,
            model: products[i].model,
            transfer_price: products[i].transfer_price,
            available_stock: products[i].available_stock,
            markup: products[i].markup,
            quantity: products[i].quantity
              ? products[i].quantity
              : index.quantity,
            special_tp: products[i].special_tp
              ? products[i].special_tp
              : this.state.special_tp,
            ticket_id: products[i].ticket_id
              ? products[i].ticket_id
              : this.state.ticket_id,
            vendor_id: products[i].vendor_id
              ? products[i].vendor_id
              : this.state.vendor_id,
            list_price: listPriceFinal,
            list_price_org: listPriceFinal,
            net_amount: netAmountFinal,
            net_amount_org: netAmountOrgFinal,
            tax_in_percentage: products[i].tax,
            tax_in_amount: tax_in_amount_final,
            total: Number(total),
            gross_margin: grossMargin,
            vendor: products[i].vendor,
            minimum_gross_margin: Number(products[i].minGrossMargin),
            discount_on_mrp: updatedDiscountOnMrp,
            deliveryCharges: Number(products[i].deliveryCharges),
          });
        }
        // For Loop Ends
        productsArray = productsArray.filter((product)=> {
          if(this.state.existingProducts.has(product.product_id) && 
             this.state.existingProducts.get(product.product_id).availabilityType === "Not Avl"){
            return false;
          }
          return true;
        })
        let prevDeliveryRate = this.state.totalSystemDeliverRate
        this.setState({
          quoteGrossMargin:
            parseFloat(this.state.quoteGrossMargin) +
            parseFloat(updatedQuoteGross),
          quoteMinimumGrossMargin:
            parseFloat(this.state.quoteMinimumGrossMargin) +
            parseFloat(updatedMinQuoteGross),
          // totalDiscountOnMrp: totalDiscountOnMrpAmount,
          totalDiscountOnMrp: this.state.totalDiscountOnMrp + totalDiscountOnMrpAmount,
          totalMrp: this.state.totalMrp + totalMrpAmount,
          totalTaxAmount: this.state.totalTaxAmount + totalTaxAmt,
          totalNetAmount: this.state.totalNetAmount + subTotalAmount,
          totalSystemDeliverRate: prevDeliveryRate + totalDeliveryRate,
          shippingCharges : prevDeliveryRate + totalDeliveryRate,
          touched:true,
          triggerRender: this.state.triggerRender>10 ? 0 :this.state.triggerRender + 1 ,
        });

        // this.setState((prevState) => ({
        //   getAllProductsWithDetailForList: productsArray.concat(
        //     prevState.getAllProductsWithDetailForList
        //   ),
        //   displayProductArray: productsArray.concat(
        //     prevState.getAllProductsWithDetailForList
        //   ),
        // }));
        this.setState((prevState) => ({
          getAllProductsWithDetailForList: prevState.getAllProductsWithDetailForList.concat(productsArray),
          displayProductArray: prevState.getAllProductsWithDetailForList.concat(productsArray),
        }));
        
        this.setState(
          {
            subTotal: (Number(this.state.subTotal) + subTotal),
            shippingCharge: parseFloat(shippingRate),
          },
          () => {
            if (this.state.subTotal < 500) {
              shippingRate = 40;
            } else {
              shippingRate = 0;
            }
            let shippingRate1 = !["", "0.0", 0, 0.0, undefined, null].includes(
              shippingRate
            )
              ? parseInt(shippingRate)
              : 0;
            // console.log(shippingRate1,"shippingRate1")
            let productTotal1 = Number(this.state.productTotal);
            // console.log(productTotal1,"producttotal1")
            // let grandTotalPrice =
            //   parseInt(this.state.subTotal) +
            //   shippingRate1 +
            //   parseInt(productTotal1) +
            //   parseInt(this.state.storeInputValue);

            // let grandTotalPrice = (Number(totalMrpAmount) - 
            //                        Number(totalDiscountOnMrpAmount)) +
            //                        Number(totalTaxAmt) + 
            //                        Number(this.state.storeInputValue) + 
            //                        Number(shippingRate1); 
                                  //  let grandTotalPrice =
                                  //  Number(totalMrpAmount) -
                                  //  Number(totalDiscountOnMrpAmount) +
                                  //  Number(totalTaxAmt) +
                                  //  Number(totalDeliveryRate);

             let grandTotalPrice = 
              Number(totalNetAmountFinal) + 
              Number(totalTaxAmt) + 
              Number(totalDeliveryRate)                                             
            this.setState({
              grandTotal: Number(this.state.grandTotal) + Number(grandTotalPrice),
            });
          }
        );
        if (!productsMap.size) {
          for (let value of e) {
            if (this.state.existingProducts.has(value.product_id)) {
              if (
                this.state.existingProducts.get(value.product_id)
                  .availabilityType === "Not Avl"
              ) {
                this.setState(
                  {
                    message: "This product is not available",
                    is_error: true,
                    is_loading: false,
                  },
                  () => {
                    setTimeout(() => {
                      this.setState({
                        message: "",
                        is_error: false,
                      });
                    }, 3000);
                  }
                );
              } else {
                productsMap.set(
                  value.product_id,
                  this.state.existingProducts.get(value.product_id)
                );
              }
            }
          }
        }
        if (productMap && productMap.size) {
          this.specialTP(productMap, "sourcing");
        }
      })
      .catch((error) => console.log(error));
  };
  handleFormSubmit = (e) => {
    this.validateGSTNumber(this.state.new_Gstno, this.state.new_zone_id);
    const form = e.currentTarget;
    if (form.checkValidity() === false) {
      e.preventDefault();
      e.stopPropagation();
    } else {
      this.setState({ newValidated: true });
    }
  };

  // GET ALL BUSINESS G.S.T ADDRESS BUT SELECT ONLY ONE G.S.T ADDRESS FUNCTION
  getAllBusinessGstAddress = (e) => {
    if (e.target.value) {
      this.setState({
        selectedOneBusinessGstAddress: e.target.value,
      });
      let selectedCompany_Name = this.state.gstBusinessAddressArr.company_name;
      let index = this.state.gstBusinessAddressArr.address_arr.filter(
        (el) => el.address1 == e.target.value
      )[0];
      if (index) {
        this.setState({
          new_postcode: index.pncd || "",
          new_city: index.city || "",
          new_zone_id: index.shop_state_id || "",
          new_country: "India",
          new_address1: index.address1 || "",
          new_address2: index.address2 || "",
          new_companyName: selectedCompany_Name || "",
        });
      }
    }
  };

  getCurrentAgent = (e) => {
    this.setState({
      agent_id: e.target.value,
      rfq_agent_id: e.target.value,
    });
  };
  getBillingZoneID = (e) => {
    this.setState({
      billing_zone_id: e.target.value,
      gstDisable: true,
    });
    this.validateGSTNumber(this.state.billing_gst, e.target.value);
  };
  getShippingZoneID = (e) => {
    this.setState({
      shipping_zone_id: e.target.value,
    });
  };

  onChangeValue = (e) => {
    this.setState({
      selectedOption: e.target.value,
    });
  };

  handleUpdate = (e) => {
    if (this.state.quoteID > 0) {
      let submitType = e.target.id;
      e.preventDefault();
      let loginedUserDetails = JSON.parse(localStorage.getItem("userData"));
      const result = this.state.getAllProductsWithDetailForList.map(
        ({ type, vendor, ...rest }) => ({ ...rest })
      );
      const token = loginedUserDetails.token;
      this.setState(
        {
          is_loading: true,
          validated: true,
        },
        () => {
          var d = new Date(this.state.startDate);
          var date = d.getDate();
          var month = d.getMonth() + 1;
          var year = d.getFullYear();
          var dateValidUpto = year + "-" + month + "-" + date;
          let newGst = 0;
          if (
            this.state.selectedGstAddress.split(" ").length === 1 &&
            !isNaN(parseInt(this.state.selectedGstAddress))
          ) {
            newGst = parseInt(this.state.selectedGstAddress);
          }
          let productsData = result
            ? result
            : this.state.getAllProductsWithDetailForList;
            productsData = productsData.map((product) => {
              const {
                brand, // destructuring to exclude brand
                quantity_type, // destructuring to exclude quantity_type
                deliveryCharges,
                ...rest
              } = product;
              return {
                ...rest,
                list_price:Number(product.list_price).toFixed(4),
                net_amount: Number(product.net_amount).toFixed(4),
                net_amount_org: Number(product.net_amount_org).toFixed(4),
                discount_on_mrp: Number(product.discount_on_mrp).toFixed(4),
                tax_in_amount: Number(product.tax_in_amount).toFixed(4),
                transfer_price: Number(product.transfer_price).toFixed(4),
                list_price_org: Number(product.list_price_org).toFixed(4),
                gross_margin: Number(product.gross_margin).toFixed(4),
                total: Number(product.total).toFixed(4)
              }
            })
          const requestoption = {
            method: "POST",
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
            },
            data: {
              gstBilling: this.state.showGstBox, // add new key name
              quote_parent_id: this.state.quoteID,
              rfq_id: this.state.rfqID,
              customer_id: this.state.customer_id,
              // customer_email: this.state.customer_email,
              // customer_name: this.state.customer_name,

              // in place of
              customer_email: this.state.customer_email2,
              customer_name: this.state.customer_name2,
              // end

              company_id: this.state.company_id,
              agent_id: this.state.rfq_agent_id
                ? Number(this.state.rfq_agent_id)
                : Number(this.state.agent_id),
              quote_subject: this.state.quote_subject,
              quote_type: submitType,
              rfq_quote_type: submitType,
              valid_upto: dateValidUpto,
              firstname: this.state.billing_first_name,
              // firstname:
              // this.state.billing_first_name == "" ||
              // this.state.billing_first_name == null
              //   ? this.state.billing_first_name
              //   : this.state.billing_first_name,
              // lastname: this.state.billing_last_name,
              // firstname: this.state.billing_name,
              company_name: this.state.billing_company_name
                ? this.state.billing_company_name
                : "",
              mobile:
                this.state.billing_mobile == "" ||
                  this.state.billing_mobile == null
                  ? this.state.customer_phone2
                  : this.state.billing_mobile,
              // email: this.state.customer_email,
              email: this.state.customer_email2,
              address_1: this.state.billing_address_1,
              address_2: this.state.billing_address_2,
              city: this.state.billing_city,
              zone_id: this.state.billing_zone_id,
              postcode: this.state.billing_postcode,
              country_id: this.state.billing_country
                ? this.state.billing_country
                : 99,
              gst: this.state.billing_gst,
              shipping_firstname: this.state.shipping_first_name,
              // shipping_firstname:
              // this.state.shipping_first_name == "" ||
              // this.state.shipping_first_name == null
              //   ? this.state.shipping_first_name
              //   : this.state.shipping_first_name,
              // shipping_lastname: this.state.shipping_last_name,
              // shipping_firstname: this.state.billing_name,
              shipping_company_name: this.state.shipping_company_name,
              shipping_mobile:
                this.state.shipping_mobile == "" ||
                  this.state.shipping_mobile == null
                  ? this.state.customer_phone
                  : this.state.shipping_mobile,
              // shipping_email: this.state.customer_email,
              shipping_email: this.state.customer_email2,
              shipping_address_1: this.state.shipping_address_1,
              shipping_address_2: this.state.shipping_address_2,
              shipping_city: this.state.shipping_city,
              shipping_zone_id: this.state.shipping_zone_id,
              shipping_postcode: this.state.shipping_postcode,
              shipping_country_id: this.state.shipping_country,
              shipping_gst: this.state.billing_gst,
              login_id: loginedUserDetails.user_id,
              sub_total: Number(this.state.subTotal).toFixed(4),
              // shippingCharge: this.state.shippingCharge,
              shippingCharge: Number(this.state.shippingCharges).toFixed(4),
              grand_total: Number(this.state.grandTotal).toFixed(4),
              products: productsData,
              // products: this.state.getAllProductsWithDetailForList,
              label_name: this.state.selectedOption, //Hot, Warm, Cold
              address_type: this.state.address_type,
              shipping_address_type: this.state.shipping_address_type,
              // address_id: this.state.selectedAddress
              //   ? parseInt(this.state.selectedAddress)
              //   : 0,
              address_id: this.state.showGstBox ? newGst : 0,
              // this.state.rfq_status == "Converted to Quote"
              ...(this.state.rfq_status == "Converted to Quote"
                ? {
                  call_response: this.state.call_response,
                  call_id: this.state.call_id,
                  call_status: this.state.call_status,
                  remarks: this.state.remarks,
                  call_attempt_count:
                    this.state.call_attempt_count.toString(),
                  rfq_quote_type: this.state.rfq_quote_type,
                  status: this.state.status,
                  message: this.state.rfq_message,
                }
                : {}),
              // paymentTerms: (this.state.paymentTerms || this.state.isEmptyTerm.length) ?  this.state.paymentTerms : "",
              paymentTerms: this.checkSetPaymentTermsValue(
                this.state.paymentTerms || this.state.isEmptyTerm.length
                  ? this.state.paymentTerms
                  : ""
              )
                ? this.state.paymentTerms
                : "",
              agent_remarks: this.state.agent_remarks, // agent_remarks
              manager_remarks: this.state.manager_remarks, // manager remarks key
            },
          };
          // begining code
          if (
            // this.state.customer_id > 0 &&
            // this.state.billing_first_name &&
            // this.state.billing_company_name.length > 0 &&
            // this.state.billing_address_1> 0 &&
            // this.state.billing_postcode > 0 &&
            // this.state.billing_zone_id > 0 &&
            // this.state.billing_city &&
            // this.state.shipping_first_name &&
            // this.state.shipping_company_name.length > 0 &&
            // this.state.shipping_address_1 > 0 &&
            this.state.shipping_postcode > 0 &&
            this.state.shipping_zone_id > 0 &&
            this.state.shipping_city &&
            this.state.quote_subject
          ) {
            return axios(apiUrl + "/create-quote", requestoption)
              .then((data) => {
                localStorage.removeItem("QuoteFromSourcing");
                this.setState(
                  {
                    // message: data.data.message,
                    message: "Quote is Duplicated Successfully!",
                    is_error: false,
                    // is_loading: false,
                  },
                  () => {
                    setTimeout(() => {
                      this.setState({
                        message: "",
                        is_error: false,
                        is_redirect: true,
                      });
                    }, 3000);
                  }
                );
              })
              .catch((error) => {
                if (typeof error.response.data.message == "object") {
                  this.setState(
                    {
                      tpMessage: error.response.data.message,
                      is_error: true,
                      is_loading: false,
                    },
                    () => {
                      setTimeout(() => {
                        this.setState({
                          tpMessage: [],
                          is_error: false,
                        });
                      }, 5000);
                    }
                  );
                } else {
                  this.setState(
                    {
                      message: error.response.data.message,
                      is_error: true,
                      is_loading: false,
                    },
                    () => {
                      setTimeout(() => {
                        this.setState({
                          message: "",
                          is_error: false,
                        });
                      }, 1000);
                    }
                  );
                }
              });
          } else {
            this.setState({
              is_loading: false,
            });
          }
        }
      );
    }
  };
  // add verify gst modal overlay function start here

  handleCloseVerifyGSTModal = () => {
    this.setState({
      showModalVerifyGST: false,
    });
  };
  handleOpenVerifyGSTModal = () => {
    this.setState({
      showModalVerifyGST: true,
    });
  };
  // handle submit gst modal button function handler
  submitVerifyGSTRadioButtonHandler = () => {
    this.setState({
      showModalVerifyGST: false, // close modal overlay pop up
    });
  };
  // add verify gst modal overlay function ends here

  // add modal overlay function start here
  handleCloseModal = () => {
    this.setState({ showModal: false });
  };

  handleShowModal = () => {
    this.setState({ showModal: true });
  };
  // handle submit gst modal button function handler
  submitGSTRadioButtonHandler = () => {
    this.setState({
      showModal: false, // close modal overlay pop up
    });
  };
  // modal overlay ends here
  openDuplicate = () => {
    this.setState({
      redirectToDuplicate: true,
    });
  };
  onSelect = (e) => {
    if (!this.state.showGstBox) {
      this.setState({
        showGstBox: true,
        //state below are changed to " " when user de-checked the gst invoice
      });
    }
    if (this.state.showGstBox) {
      this.setState({
        showGstBox: false,
        showGstAddress: false,
        selectedGstAddress: "",
        gstNumber: "",
      });
    }
    this.setState({
      address_type: "",
      billing_address_1: "",
      billing_address_2: "",
      billing_postcode: "",
      billing_city: "",
      billing_zone_id: "",
      billing_zone_name: "",
      billing_gst: "",
      //
      billing_first_name: "",
      billing_mobile: "",
      billing_company_name: "",
    });
  };
  searchGst = (e) => {
    e.preventDefault();
    let loginedUserDetails = JSON.parse(localStorage.getItem("userData"));

    const token = loginedUserDetails.token;
    this.setState({
      gstLoading: true,
    });
    const requestoption = {
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      data: {
        gst: this.state.gstNumber,
      },
    };
    return axios(apiUrl + "/gst-validate", requestoption)
      .then((data) => {
        let gstData = data.data.data[0];
        if (!gstData.zone_id) {
          this.setState({
            gstNotInDB: true,
          });
        } else {
          this.setState({
            gstNotInDB: false,
            showModal: true,
            gstAddressArr: data.data.data[0],
          });
        }
        let gstAddress =
          this.state.gstNumber +
          " - " +
          gstData.address1 +
          ", " +
          gstData.address2 +
          ", " +
          gstData.city +
          ", " +
          gstData.state +
          ", " +
          gstData.pincode;
        this.setState({
          gstLoading: false,
          showGstAddress: true,
          selectedGstAddress: gstAddress,
          selectedAddress: 0,
          // sttae given to billing address ,
          billing_address_1: gstData.address1,
          billing_address_2: gstData.address2,
          billing_postcode: gstData.pincode,
          billing_city: gstData.city,
          // billing_zone_id: gstData.state_id,
          billing_zone_name: gstData.state,
          address_type: "GST",
          billing_country_name: "India",
          billing_country: 99,
          billing_company_name: gstData.company_name,
          // billing_first_name: this.state.first_name
          //   ? this.state.first_name
          //   : this.state.customer_name2,
          // billing_mobile: this.state.mobile_number
          //   ? this.state.mobile_number
          //   : this.state.customer_phone2,
          // // billing_gst: this.state.gstNumber,
          billing_zone_id: gstData.zone_id,
        });
      })
      .catch((err) => {
        console.log(err);
        this.setState(
          {
            gstLoading: false,
            message: "No Address With This GST NO. is Found ",
            is_error: true,
          },
          () => {
            setTimeout(() => {
              this.setState({
                message: "",
                is_error: false,
              });
            }, 3000);
          }
        );
      });

    //must keep this line to avoid re-render of page and avoiding undefined "RFQID" error
  };
  getGstAddress = (e) => {
    if (e.target.value) {
      this.setState({
        selectedGstAddress: e.target.value,
      });
      let index = this.state.addressArray.filter(
        (el) => el.address_id == e.target.value
      )[0];
      if (index) {
        this.setState({
          billing_first_name: index.firstname || this.state.first_name,
          // billing_last_name: index.lastname,
          billing_name: index.name || "",
          // billing_company_name: index.company || "",
          billing_company_name: index.addressCompany
            ? index.addressCompany
            : index.company,
          billing_mobile: index.mobile || this.state.mobile_number,
          billing_address_1: index.address_1 || "",
          billing_address_2: index.address_2 || "",
          billing_city: index.city || "",
          billing_zone_id: index.zone_id || "",
          billing_postcode: index.postcode || "",
          billing_country: 99,
          billing_country_name: "India",
          billing_gst: index.gstin ? index.gstin : "",
          address_type: index.address_type ? index.address_type : "",
          showGstAddress: true,
        });
      }
    }
  };
  // GET ALL G.S.T ADDRESS BUT SELECT ONLY ONE G.S.T ADDRESS FUNCTION
  getAllGstAddress = (e) => {
    if (e.target.value) {
      this.setState({
        // selectedOneGstAddress: e.target.value,
        selectedGstAddress: e.target.value,
      });
      let index = this.state.gstAddressArr.address_arr.filter(
        (el) => el.address1 == e.target.value
      )[0];
      // Display Selected Company Name
      let selectedCompany_Name = this.state.gstAddressArr.company_name;
      let selectedGST_Number = this.state.gstNumber;
      if (index) {
        this.setState({
          billing_first_name: index.firstname || this.state.first_name,
          // billing_last_name: index.lastname,
          billing_name: index.name || "",
          billing_company_name: selectedCompany_Name || "",
          billing_mobile: index.mobile || this.state.mobile_number,
          billing_address_1: index.address1 || "",
          billing_address_2: index.address2 || "",
          billing_city: index.city || "",
          billing_zone_id: index.shop_state_id || "",
          billing_postcode: index.pncd || "",
          billing_country: 99,
          billing_country_name: "India",
          // billing_gst: index.gstin ? index.gstin : "",
          billing_gst: selectedGST_Number || "",
          address_type: index.address_type ? index.address_type : "GST",
          showGstAddress: true,
        });
      }
    }
  };

  checkSetPaymentTermsValue = (htmlString) => {
    const pattern = /<[^>]+>([^<]*)<\/[^>]+>/g;
    const matches = htmlString.match(pattern);
    if (matches) {
      for (const match of matches) {
        const content = match.replace(/<\/?[^>]+(>|$)/g, "").trim();
        if (content) {
          return true;
        }
      }
    }
    return false;
  };

  setPaymentTerms = (e) => {
    let htmlString = e;
    const plainText = htmlString.replace(/<[^>]*>/g, " ");
    const modifiedHtmlString = htmlString.replace(
      /<ul>/g,
      "<ul style=margin-left: -11px>"
    );

    // Split by list item delimiter
    const listItems = plainText.split(/\n/).map((item) => item.trim());
    // const str = 'new payment  ds';
    const str = listItems[0];
    const arr = str.split("  "); // given a gap of two spaces for seperating two different bulle points

    let isEmptyTerm = arr.filter((el) => el != "");
    if (modifiedHtmlString !== this.state.paymentTerms) {
      this.setState({ paymentTerms: modifiedHtmlString, isEmptyTerm });
    }
  };
  onCheckPaymentTerm = (e) => {
    this.setState((prevState) => ({
      isPaymentTermsChecked: !prevState.isPaymentTermsChecked,
      // points: [],
    }));
    if (!this.state.isPaymentTermsChecked) {
      this.setState({
        paymentTerms:
          "<ul><li>" +
          defaultpaymentTermsArray.join("</li><li>") +
          "</li></ul>",
      });
    } else {
      this.setState({
        paymentTerms: "",
      });
    }
  };

  // add shipping charges function start here
  addshippingCharges = (e) => {
    const inputValue = parseInt(e.target.value, 10) || 0;
    // let grandTotalPrice =
    //   parseFloat(this.state.subTotal) + parseFloat(inputValue);
    let grandTotalPrice = Math.abs(Number(this.state.totalNetAmount)) + Number(this.state.totalTaxAmount) + Number(inputValue);
    this.setState({
      shippingCharge: inputValue,
      storeInputValue: inputValue,
      grandTotal: Number(grandTotalPrice),
    });
    if (inputValue !== this.state.shippingCharges) {
      this.setState({ shippingCharges: inputValue });
    }
  };
  // add shipping charges function ends here

  render() {
    // if (this.state.quoteID == "undefined") {
    //   return <Redirect to="/listQuote" />;
    // }
    if (this.state.is_redirect == true) {
      return <Redirect to="/listQuote" />;
    }
    // if (this.state.redirectToDuplicate) {
    //   return (
    //     <Redirect
    //       to={{
    //         pathname: "/editQuote",
    //         state: {
    //           quoteID: this.state.quoteID,
    //         },
    //         // state:{data}
    //         // state: {phone:this.state.customer_mobile }
    //       }}

    //     />
    //   );
    // }

    return (
      <Row>
        <Col md={12}>
          {this.state.message ? (
            <Alert
              className="displayMessageArea"
              variant={this.state.is_error ? "danger" : "success"}
            >
              {this.state.message}
            </Alert>
          ) : null}
          {this.state.productmessage ? (
            <Alert
              className="displayMessageArea"
              variant={this.state.productmessage ? "success" : "danger"}
            >
              {this.state.productmessage}
            </Alert>
          ) : null}
          {this.state.new_address_success_message ? (
            <Alert className="displayMessageArea" variant="success">
              {this.state.new_address_success_message}
            </Alert>
          ) : null}

          {this.state.gstErrorMessage != "" ? (
            <Alert
              className="displayMessageArea"
              variant={this.state.gstErrorMessage != "" ? "danger" : "success"}
            >
              {this.state.gstErrorMessage}
            </Alert>
          ) : (
            ""
          )}
          {this.state.tpMessage.length ? (
            <Alert className="displayMessageArea" variant="danger">
              {this.state.tpMessage &&
                this.state.tpMessage.map((data) => {
                  return <p className="my-3">{data.name}</p>;
                })}
            </Alert>
          ) : null}
          {this.state.gstNotInDB ? (
            <Alert className="displayMessageArea" variant="danger">
              <p className="my-1">Check PinCode ZoneID</p>
            </Alert>
          ) : null}

          {/* Add New Address form below */}
          <Modal show={this.state.show} onHide={this.closeModal} size="lg">
            <Modal.Header closeButton>
              <Modal.Title>Add New Address Below</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              {this.state.new_address_error_message != "" ? (
                <Alert className="displayMessageArea" variant="danger">
                  {this.state.new_address_error_message}
                </Alert>
              ) : (
                ""
              )}

              {this.state.gstErrorMessage != "" ? (
                <Alert
                  className="displayMessageArea"
                  variant={
                    this.state.gstErrorMessage != "" ? "danger" : "success"
                  }
                >
                  {this.state.gstErrorMessage}
                </Alert>
              ) : (
                ""
              )}

              <Form noValidate validated={this.state.newValidated}>
                {/* <Form.Group
                        className="mb-3"
                        controlId="formAddress"
                      >
                        <Form.Label>Your Billing Address</Form.Label>
                        <Form.Control
                          type="text"
                          placeholder="New address goes here..."
                          autoFocus
                          onChange={this.addresschange}
                          // onSubmit={this.addNewAddress}
                          value={this.state.new_address}
                        />
                      </Form.Group> */}

                {/* <Form.Group className="mb-3" controlId="formCompany">
                  <Form.Label>Company</Form.Label>
                  <Form.Control
                    type="text"
                    name="new_companyName"
                    placeholder="Company"
                    autoFocus
                    onChange={this.newHandleChange}
                    value={this.state.new_companyName}
                  />
                </Form.Group> */}

                {/* ADD GST ADDRESS COMPONENT START HERE */}
                <div className="d-flex align-items-center mb-3">
                  <Form.Check
                    className="mr-2"
                    inline
                    name="group1"
                    type="checkbox"
                    onChange={this.handleCheckboxChange}
                    checked={this.state.showGSTDetailsCheckbox}
                  />
                  <label className="mb-0">
                    <p className="mb-0">
                      <b>Use GSTIN for Business Purchase(Optional)..</b>
                    </p>
                    <p className="mb-0">
                      Get GST Invoice and claim input tax credit on your
                      purchase.
                    </p>
                  </label>
                </div>
                {/* <div className="col-6">
                <Form.Group className="mb-3" controlId="formAddress1">
                  <Form.Label>Address1</Form.Label>
                  <Form.Control
                    type="text"
                    name="new_address1"
                    placeholder="Address1"
                    autoFocus
                    onChange={this.newHandleChange}
                    value={this.state.new_address1}
                    required
                  />
                </Form.Group>
                </div> */}
                {this.state.showGSTDetailsCheckbox && (
                  <>
                    <h3 style={{ fontSize: "1rem" }}>
                      <b>Business Details</b>
                    </h3>
                    <Form.Row>
                      <div className="col-4">
                        <Form.Group>
                          <Form.Control
                            name="gstNumber"
                            // onChange={this.handleGstChange}
                            onChange={this.handleBusinessGstChange}
                            value={this.state.gstNumber}
                            type="text"
                            placeholder="Enter Your GSTIN"
                          // disabled={!this.state.gstDisable}
                          />
                        </Form.Group>
                      </div>
                      <Button
                        type="submit"
                        variant="primary"
                        onClick={this.searchBusinessGst}
                        id="searchGst"
                        size="sm"
                        style={{
                          width: "6rem",
                          height: " 2.5rem",
                          marginLeft: "inherit",
                        }}
                        disabled={!this.state.gstError}
                      >
                        {this.state.gstLoading ? (
                          <Spinner animation="border" role="status">
                            <span className="sr-only">Loading</span>
                          </Spinner>
                        ) : (
                          "Verify"
                        )}
                      </Button>

                      <div className="col-6">
                        <Form.Group
                          className="mb-3 ml-3"
                          controlId="formGridCompany"
                        >
                          {/* <Form.Label>Company</Form.Label> */}
                          {/* <Form.Control
                        name="billing_company_name"
                        onChange={this.handleChange}
                        value={this.state.billing_company_name}
                        type="text"
                        placeholder="Company"
                        disabled={
                          this.state.showGstBox || this.state.gstDefault
                        }
                        // required
                      /> */}
                          <Form.Control
                            type="text"
                            name="new_companyName"
                            placeholder="Company"
                            autoFocus
                            onChange={this.newHandleChange}
                            value={this.state.new_companyName}
                            disabled
                            required
                          />
                          <Form.Control.Feedback type="invalid">
                            Please enter billing company name.
                          </Form.Control.Feedback>
                        </Form.Group>
                      </div>
                    </Form.Row>
                  </>
                )}
                {/* ADD GST ADDRESS COMPONENT ENDS HERE */}

                {/* Verify Business Details Modal Pop UP Component Start here */}
                {this.state.gstBusinessAddressArr?.address_arr ? ( // check if gstAddressArr found address_arr
                  <Modal
                    scrollable={true}
                    show={this.state.showModalVerifyGST}
                    onHide={this.handleCloseVerifyGSTModal}
                    backdrop="static"
                    keyboard={false}
                  >
                    <Modal.Header closeButton>
                      <Modal.Title>Select Your GST Address</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                      {this.state.gstBusinessAddressArr?.address_arr?.map(
                        (gstAddressDetails, index) => (
                          <React.Fragment key={index}>
                            <label className="d-flex justify-content-center align-items-center">
                              <Form.Check
                                type="radio"
                                name="gstBusinessAddressArr"
                                value={gstAddressDetails.address1}
                                checked={
                                  this.state.selectedOneBusinessGstAddress ===
                                  gstAddressDetails.address1
                                }
                                // onChange={this.getAllGstAddress}
                                onChange={this.getAllBusinessGstAddress}
                              />
                              {`
                        ${gstAddressDetails.address1},
                        ${gstAddressDetails.address2},
                        ${gstAddressDetails.city},
                        ${gstAddressDetails.state},
                        ${gstAddressDetails.pncd}. 
                        `}
                            </label>
                          </React.Fragment>
                        )
                      )}
                    </Modal.Body>
                    <Modal.Footer className="d-flex justify-content-end">
                      <Button
                        variant="secondary"
                        onClick={this.handleCloseVerifyGSTModal}
                      >
                        Close
                      </Button>
                      <Button
                        variant="primary"
                        onClick={this.submitVerifyGSTRadioButtonHandler.bind(
                          this
                        )}
                      >
                        OK
                      </Button>
                    </Modal.Footer>
                  </Modal>
                ) : null}
                {/* Verfify Business Details Modal Pop Up Component Ends here */}

                <h3 className="con_detail_heading">Address</h3>
                <div className="row">
                  <div className="col-3">
                    <Form.Group className="mb-3" controlId="formPostCode">
                      <Form.Label>PostCode</Form.Label>
                      <Form.Control
                        className={this.state.new_wrong_pin ? "error" : ""}
                        type="number"
                        name="new_postcode"
                        placeholder="PostCode"
                        autoFocus
                        onChange={this.newHandleChangePincode}
                        value={this.state.new_postcode}
                        disabled={this.state.showDisabledFields}
                        required
                      />
                    </Form.Group>
                  </div>

                  <div className="col-3">
                    <Form.Group className="mb-3" controlId="formCity">
                      <Form.Label>City</Form.Label>
                      <Form.Control
                        type="text"
                        name="new_city"
                        placeholder="City"
                        autoFocus
                        onChange={this.newHandleChange}
                        value={this.state.new_city}
                        required
                        disabled={this.state.showDisabledFields}
                      />
                    </Form.Group>
                  </div>
                  <div className="col-3">
                    <Form.Group className="mb-3" controlId="formState">
                      <Form.Label>State</Form.Label>
                      <Form.Control
                        as="select"
                        custom
                        name="new_zone_id"
                        onChange={this.getNewZoneID}
                        disabled={this.state.showDisabledFields}
                      >
                        <option value="">Please select state</option>
                        {this.state.stateArray.length > 0 &&
                          this.state.stateArray.map((item) => {
                            var selected =
                              this.state.new_zone_id == item.id ? true : false;
                            return (
                              <option
                                selected={selected}
                                key={item.id}
                                value={item.id}
                                defaultValue=""
                              >
                                {item.name}
                              </option>
                            );
                          })}
                      </Form.Control>
                    </Form.Group>
                  </div>
                  <div className="col-3">
                    <Form.Group className="mb-3" controlId="formCountry">
                      <Form.Label>Country</Form.Label>
                      <Form.Control
                        type="text"
                        name="new_country"
                        placeholder="Country"
                        autoFocus
                        onChange={this.newHandleChange}
                        value={this.state.new_country}
                        required
                        disabled
                      />
                    </Form.Group>
                  </div>
                </div>

                <div className="row">
                  <div className="col-6">
                    <Form.Group className="mb-3" controlId="formAddress1">
                      <Form.Label>Address1</Form.Label>
                      <Form.Control
                        type="text"
                        name="new_address1"
                        placeholder="Address1"
                        autoFocus
                        onChange={this.newHandleChange}
                        value={this.state.new_address1}
                        required
                        disabled={this.state.showDisabledFields}
                      />
                    </Form.Group>
                  </div>
                  <div className="col-6">
                    <Form.Group className="mb-3" controlId="formAddress2">
                      <Form.Label>Address2</Form.Label>
                      <Form.Control
                        type="text"
                        name="new_address2"
                        placeholder="Address2"
                        autoFocus
                        onChange={this.newHandleChange}
                        value={this.state.new_address2}
                        required
                        disabled={this.state.showDisabledFields}
                      />
                    </Form.Group>
                  </div>
                </div>

                <Form.Group className="mb-3" controlId="formAddressType">
                  <div className="d-flex">
                    <Form.Label className="con_detail_heading mr-3">
                      Address Type
                    </Form.Label>
                    {this.state.address_array.map((item) => {
                      return (
                        <div className="d-flex mr-3">
                          <Form.Check
                            type="radio"
                            value={item}
                            // name="Hot"
                            checked={this.state.new_address_type === item}
                            onChange={this.handleChangeAddressTypeRadio}
                            required
                          />
                          <label key={item}>{item}</label>
                        </div>
                      );
                    })}
                  </div>

                  {/* <Form.Control
                    as="select"
                    custom
                    name="new_address_type"
                    placeholder="Address Type"
                    onChange={this.newHandleChange}
                    value={this.state.new_address_type}
                    required
                  >
                    <option value="">Select Address type</option>
                    {this.state.address_array.map((item) => {
                      return (
                        <option value={item} defaultValue="">
                          {item}
                        </option>
                      );
                    })}
                    </Form.Control> */}
                  {/* <option value="Home">Home</option>
                          <option value="Other">Other</option> */}
                </Form.Group>

                <h3 className="con_detail_heading">Contact Details</h3>
                <div className="row">
                  <div className="col-6">
                    <Form.Group className="mb-3" controlId="formName">
                      <Form.Label>Full Name</Form.Label>
                      <Form.Control
                        type="text"
                        name="new_name"
                        placeholder="Name"
                        autoFocus
                        onChange={this.newHandleChange}
                        defaultValue={
                          this.state.new_name
                            ? this.state.new_name
                            : this.state.customer_name2
                        }
                        required
                      />
                      <Form.Control.Feedback type="invalid">
                        Please enter the Name first.
                      </Form.Control.Feedback>
                    </Form.Group>
                  </div>
                  <div className="col-6">
                    <Form.Group className="mb-3" controlId="formMobile">
                      <Form.Label>Mobile Number</Form.Label>
                      <Form.Control
                        type="number"
                        name="new_mobile"
                        placeholder="Mobile"
                        autoFocus
                        onChange={this.newHandleChange}
                        defaultValue={
                          this.state.new_mobile
                            ? this.state.new_mobile
                            : this.state.customer_phone2
                        }
                        required
                      />
                    </Form.Group>
                  </div>
                </div>
                {/* <Form.Group className="mb-3" controlId="formGstNo">
                  <Form.Label>Gst No</Form.Label>
                  <Form.Control
                    className={!this.state.gstError ? "error" : ""}
                    type="text"
                    name="new_Gstno"
                    placeholder="Gst No"
                    onChange={this.handleNewGstChange}
                    value={this.state.new_Gstno}
                    disabled={!this.state.gstNewDisable}
                  />
                </Form.Group> */}
              </Form>
            </Modal.Body>
            <Modal.Footer>
              <Button
                variant="primary"
                type="Submit"
                onClick={
                  this.state.gstError
                    ? this.addNewAddress
                    : this.handleFormSubmit
                }
              >
                Submit
              </Button>
            </Modal.Footer>
          </Modal>
          {/* Create Quote Main Page Form Below */}
          <Form noValidate validated={this.state.validated}>
            <div className="card">
              <div className="card-header">
                {/* <h3>Quote Information</h3>
                &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; */}
                <h4><b>Duplicate form</b></h4>
              </div>
              <div className="card-body">
                <Form.Row>
                  <Col md="3">
                    <Form.Group controlId="customerPhone">
                      <Form.Label>Customer Phone</Form.Label>

                      <Form.Control
                        type="number"
                        name="customer_phone2"
                        placeholder="Customer Phone"
                        defaultValue={this.state.customer_phone2}
                        // value={this.state.customer_phone2}
                        disabled={this.state.customer_phone2}
                        onChange={this.getPhoneValue}
                        className={this.state.wrong_phone ? "error" : ""}
                        required
                      />
                      <Form.Control
                        name="customer_mobile"
                        type="hidden"
                        onChange={this.handleChange}
                      />
                    </Form.Group>
                  </Col>
                  <Col md="3">
                    <Form.Group
                      controlId="exampleForm.SelectCustom"
                      value="b2blandingpage"
                    >
                      <Form.Label>
                        Account Name <span>(*)</span>
                      </Form.Label>
                      {/* <SelectSearch
                      // options={this.state.nameData}
                      value={this.state.companyName}
                      // value={this.state.selectedOption}
                      search
                      name="account_name"
                      // placeholder="Choose Account Name"
                      // savedDetails={this._getSavedDetails}
                      // onChange={this.getDetailsbyAccountName}
                      required
                    /> */}
                      <Form.Control
                        type="text"
                        // value={this.state.company_id}
                        value={this.state.companyName}
                        name="companyName"
                        placeholder="companyName"
                        disabled
                      // required
                      />
                    </Form.Group>
                  </Col>
                  <Col md="3">
                    <Form.Group controlId="exampleForm.SelectCustom">
                      <Form.Label>Quote Owner</Form.Label>
                      {this.state.rfq_agent_id ? (
                        <Form.Control
                          as="select"
                          custom
                          name="rfq_agent_id"
                          required
                          onChange={this.getCurrentAgent}
                          value={this.state.rfq_agent_id}
                          defaultValue={this.state.rfq_agent_id}
                        // value="405"
                        >
                          <option value="">Please select agent</option>
                          {this.state.agentArray.map((item) => {
                            var selected =
                              this.state.rfq_agent_id == item.id ? true : false;
                            return item.id ? (
                              <option
                                selected={selected}
                                key={item.id}
                                value={item.id}
                              >
                                {item.name}
                              </option>
                            ) : null;
                          })}
                        </Form.Control>
                      ) : (
                        <Form.Control
                          as="select"
                          custom
                          name="agent_id"
                          required
                          onChange={this.getCurrentAgent}
                          value={this.state.agent_id}
                        // value="405"
                        >
                          <option value="">Please select agent</option>
                          {this.state.agentArray.map((item) => {
                            var selected =
                              this.state.agent_id == item.id ? true : false;
                            return item.id ? (
                              <option
                                selected={selected}
                                key={item.id}
                                value={item.id}
                              >
                                {item.name}
                              </option>
                            ) : null;
                          })}
                        </Form.Control>
                      )}

                      <Form.Control.Feedback type="invalid">
                        Please select quote owner.
                      </Form.Control.Feedback>
                    </Form.Group>
                  </Col>
                  <Col md="3">
                    <Form.Group controlId="formGridSubject">
                      <Form.Label>Quote Subject</Form.Label>
                      <Form.Control
                        type="text"
                        name="quote_subject"
                        placeholder="Quote Subject"
                        value={this.state.quote_subject}
                        onChange={this.handleChange}
                        required
                      />
                      <Form.Control.Feedback type="invalid">
                        Please choose quote subject.
                      </Form.Control.Feedback>
                    </Form.Group>
                  </Col>
                  <Col md="3">
                    <Form.Group controlId="formGridValidUpto">
                      <Form.Label>Valid Until</Form.Label>
                      <DatePicker
                        dateFormat="yyyy-MM-dd"
                        selected={this.state.startDate}
                        onChange={this.handleDateChange}
                        className="form-control"
                        locale="es"
                        minDate={new Date()}
                        value={this.state.startDate}
                        required
                      />
                      <Form.Control.Feedback type="invalid">
                        Please choose expire date.
                      </Form.Control.Feedback>
                    </Form.Group>
                  </Col>
                  <Col md="3">
                    <Form.Group controlId="customerName">
                      <Form.Label>Customer Name </Form.Label>
                      <Form.Control
                        type="text"
                        // value={this.state.customer_name}
                        value={this.state.customer_name2}
                        name="customer_name"
                        placeholder="Customer Name"
                        disabled
                        required
                      />
                    </Form.Group>
                  </Col>
                  <Col md="3">
                    <Form.Group controlId="customerEmail">
                      <Form.Label>Customer Email </Form.Label>
                      <Form.Control
                        type="text"
                        // value={this.state.customer_email}
                        value={this.state.customer_email2}
                        name="customer_email"
                        placeholder="Customer Email"
                        disabled
                      // required
                      />
                    </Form.Group>
                  </Col>
                </Form.Row>
              </div>
            </div>

            {/* Billing & Shipping Address Component Start here */}
            <div className="card">
              <div className="card-body">
                <Row>
                  <Col md="2">
                    <h3 className="billingAdressHeader">
                      <b>Delivery & Billing Address</b>
                    </h3>
                  </Col>
                  {/* <Button
                  variant="outline-secondary"
                  onClick={this.copyAddress}
                  className="pull-right copyAddress"
                >
                  Copy Address
                </Button> */}
                  <Col md="2">
                    <Button
                      variant="outline-secondary"
                      onClick={this.openModal}
                      className="pull-right copyAddress w-100"
                      disabled={this.state.isDisable}
                    >
                      Add New Address
                    </Button>
                  </Col>
                </Row>
              </div>


              <div className="card-body">
                <div>
                  <Form.Row>
                    <>
                      <div>
                        <input
                          type="checkbox"
                          className="selection-input-4"
                          onChange={(e) => this.onSelect(e)}
                          // checked={this.state.showGstBox}
                          checked={this.state.showGstBox ? true : false}
                        />
                      </div>
                      <div className="ml-3 mb-3">
                        <b>Use GSTIN for Business Purchase (Optional).</b>
                        <br />
                        Get GST Invoice and claim input tax credit on your
                        purchase
                      </div>
                    </>
                  </Form.Row>
                  {/* {this.state.showGstBox ? ( */}

                  {/* ) : null} */}
                  {this.state.wrongFormat ? (
                    <Form.Row>
                      <Form.Label>{this.state.wrongFormat}</Form.Label>
                    </Form.Row>
                  ) : null}
                  {/* {this.state.showGstAddress ? (
                  <Form.Row>
                    <Col xs="6">
                      <Form.Group>
                        <Form.Control
                          as="select"
                          custom
                          // name="gst_address"
                          // onChange={this.handleGstChange}

                          value={this.state.gst_address}

                          // placeholder="Gst No"
                          // disabled={!this.state.gstDisable}
                        >
                          <option value={this.state.gst_address}>
                            {this.state.gst_address}
                          </option>
                        </Form.Control>
                      </Form.Group>
                    </Col>
                  </Form.Row>
                ) : null} */}
                </div>
                <Row>
                  {/* Billing address form Start */}
                  <Col md="6">
                    <h6 style={{ fontSize: "1.4rem" }}>
                      <b>Delivery Address</b>
                    </h6>
                    <h6>Saved Address</h6>
                    <Form.Group controlId="exampleFormAddress">
                      <Form.Control
                        as="select"
                        name="address_type"
                        custom
                        onChange={this.getSelectShippingAddress}
                        value={this.state.selectedShippingAddress}
                      >
                        {this.state.shipping_address_1 ? (
                          <option value={this.state.shipping_address_1}>
                            {this.state.shipping_address_1}
                          </option>
                        ) : (
                          <option value="">Select a saved address</option>
                        )}

                        {this.state.addressArray &&
                          this.state.addressArray.map((item) => {
                            return (
                              <option
                                key={item.address_id}
                                value={item.address_id}
                              >
                                {item.gstin} {item.name}, {item.address_1},
                                {item.city},{item.state} {item.postcode}
                              </option>
                            );
                          })}
                      </Form.Control>
                    </Form.Group>
                    <h6>
                      <b>Address Details</b>
                    </h6>
                    <Row>
                      <Col md="4">
                        <Form.Group controlId="formGridShippingPostCode">
                          <Form.Label>Pincode</Form.Label>
                          <Form.Control
                            type="number"
                            name="shipping_postcode"
                            onChange={this.handleChangeShippingPincode}
                            value={this.state.shipping_postcode}
                            placeholder="Postcode"
                            required
                          />
                          <Form.Control.Feedback type="invalid">
                            Please enter shipping postcode.
                          </Form.Control.Feedback>
                        </Form.Group>
                      </Col>
                      <Col md="4">
                        <Form.Group controlId="formGridShippingCity">
                          <Form.Label>City</Form.Label>
                          <Form.Control
                            name="shipping_city"
                            onChange={this.handleChange}
                            value={this.state.shipping_city}
                            type="text"
                            placeholder="City"
                            required
                          />
                          <Form.Control.Feedback type="invalid">
                            Please enter shipping city.
                          </Form.Control.Feedback>
                        </Form.Group>
                      </Col>
                      <Col md="4">
                        <Form.Group controlId="formGridShippingZoneID">
                          <Form.Label>State</Form.Label>
                          <Form.Control
                            as="select"
                            custom
                            name="shipping_zone_id"
                            value={this.state.shipping_zone_id}
                            required
                            onChange={this.getShippingZoneID}
                          >
                            <option value="">Please select state</option>
                            {this.state.stateArray.map((item) => {
                              var selected =
                                this.state.shipping_zone_id == item.id
                                  ? true
                                  : false;
                              return (
                                <option
                                  selected={selected}
                                  key={item.id}
                                  value={item.id}
                                >
                                  {item.name}
                                </option>
                              );
                            })}
                          </Form.Control>
                          <Form.Control.Feedback type="invalid">
                            Please select state.
                          </Form.Control.Feedback>
                        </Form.Group>
                      </Col>
                    </Row>

                    <Row>
                      <Col md="6">
                        <Form.Group controlId="formGridShippingAddress1">
                          <Form.Label>Address 1</Form.Label>
                          <Form.Control
                            name="shipping_address_1"
                            onChange={this.handleChange}
                            value={this.state.shipping_address_1}
                            type="text"
                            placeholder="Address 1"
                          // required
                          />
                          <Form.Control.Feedback type="invalid">
                            Please enter shipping address 1.
                          </Form.Control.Feedback>
                        </Form.Group>
                      </Col>
                      <Col md="6">
                        <Form.Group controlId="formGridShippingAddress2">
                          <Form.Label>Address 2</Form.Label>
                          <Form.Control
                            name="shipping_address_2"
                            onChange={this.handleChange}
                            value={this.state.shipping_address_2}
                            type="text"
                            placeholder="Address 2"
                          // required
                          />
                          <Form.Control.Feedback type="invalid">
                            Please enter shipping address 2.
                          </Form.Control.Feedback>
                        </Form.Group>
                      </Col >
                    </Row>

                    <Form.Group controlId="formAddressType">
                      <Form.Label className="mb-0">
                        <b>Address Type</b>
                      </Form.Label>
                      <div className="d-flex align-items-center ">
                        {this.state.address_array.map((item, index) => {
                          return (
                            <React.Fragment key={index}>
                              <Form.Check
                                type="radio"
                                value={item}
                                // name="Hot"
                                checked={
                                  this.state.shipping_address_type === item
                                }
                                onChange={
                                  this.handleChangeShippingAddressTypeRadio
                                }
                                // onChange={this.handleChangeBillingAddressTypeRadio}
                                required
                                label={item}
                                className="mr-3"
                              />
                            </React.Fragment>
                          );
                        })}
                      </div>
                      {/* <Form.Control
                        as="select"
                        custom
                        name="shipping_address_type"
                        placeholder="Address Type"
                        onChange={this.handleChange}
                        value={this.state.shipping_address_type}
                        // required
                      >
                        <option value="">Select Address type</option>
                        {this.state.address_array.map((item) => {
                          return (
                            <option value={item} defaultValue="">
                              {item}
                            </option>
                          );
                        })}
                      </Form.Control> */}
                    </Form.Group>

                    <h6>
                      <b>Contact Details</b>
                    </h6>
                    <Row>
                      <div className="col-6">
                        <Form.Group controlId="formGridShippingFirstName">
                          <Form.Label>Name</Form.Label>
                          <Form.Control
                            onChange={this.handleChange}
                            name="shipping_first_name"
                            // defaultValue={this.state.shipping_name}
                            defaultValue={
                              this.state.shipping_first_name
                                ? this.state.shipping_first_name
                                : this.state.customer_name2
                            }
                            // defaultValue={this.state.shipping_name}
                            type="text"
                            placeholder="Name"
                          // required
                          />
                          <Form.Control.Feedback type="invalid">
                            Please enter shipping first name.
                          </Form.Control.Feedback>
                        </Form.Group>
                      </div>
                      <div className="col-6">
                        <Form.Group controlId="formGridShippingMobile">
                          <Form.Label>Contact No</Form.Label>
                          <Form.Control
                            name="shipping_mobile"
                            onChange={this.handleChange}
                            defaultValue={
                              this.state.shipping_mobile
                                ? this.state.shipping_mobile
                                : this.state.customer_phone2
                            }
                            type="text"
                            placeholder="Mobile"
                          // required
                          />
                          <Form.Control.Feedback type="invalid">
                            Please enter shipping mobile number.
                          </Form.Control.Feedback>
                        </Form.Group>
                      </div>
                    </Row>

                    <Form.Group controlId="formGridShippingCountry">
                      <Form.Label>Country</Form.Label>
                      <Form.Control
                        name="shipping_country_name"
                        onChange={this.handleChange}
                        value={this.state.shipping_country_name}
                        type="text"
                        placeholder="Country"
                        required
                        disabled
                      />
                      <Form.Control.Feedback type="invalid">
                        Please enter shipping country name.
                      </Form.Control.Feedback>
                    </Form.Group>
                    {/* <Form.Group controlId="formGridShippingCompany">
                        <Form.Label>Company</Form.Label>
                        <Form.Control
                          name="shipping_company_name"
                          onChange={this.handleChange}
                          value={this.state.shipping_company_name}
                          type="text"
                          placeholder="Company"
                        />
                        <Form.Control.Feedback type="invalid">
                          Please enter shipping company name.
                        </Form.Control.Feedback>
                        </Form.Group> */}
                  </Col>
                  {/* Shipping Address Form Start */}

                  <Col md="6">
                    {/* show data only if gst billing condition is true */}

                    {this.state.showGstBox ? (
                      <>
                        <h6 style={{ fontSize: "1.4rem" }}>
                          <b>Billing Address</b>
                        </h6>
                        <Form.Row>
                          <Col>
                            <Form.Group>
                              <Form.Label>
                                Saved GST Address
                                {/* ({this.state.gstAddressCount}) */}
                              </Form.Label>
                              <Form.Control
                                as="select"
                                custom
                                // name="address_type"
                                name="gst_address"
                                // onChange={this.handleGstChange}

                                // name="selectedGstAddress"
                                // onClick={this.getNewAddress}
                                onChange={this.getGstAddress}
                                value={this.state.selectedGstAddress}

                              // placeholder="Gst No"
                              // disabled={!this.state.gstDisable}
                              >
                                <option value="0">
                                  Select a saved GST address
                                </option>
                                {this.state.addressArray &&
                                  this.state.addressArray.map((item) => {
                                    return (item.gst_address == 1 || (item.gstin && item.gstin.length==15)) ? (
                                      <>
                                        <option value={item.address_id}>
                                          {item.gstin}, {item.name}{" "}
                                          {item.address_1}, {item.address_2},{" "}
                                          {item.city},{item.state},
                                          {item.postcode}
                                        </option>
                                      </>
                                    ) : null;
                                  })}
                              </Form.Control>
                            </Form.Group>
                          </Col>
                        </Form.Row>
                        <Form.Row>
                          {/* <Col className="col-6">
                            <Form.Label>GSTIN</Form.Label>
                            <Form.Group className="d-flex">
                              <Form.Control
                                className={!this.state.gstError ? "error" : ""}
                                name="gstNumber"
                                onChange={this.handleGstChange}
                                value={this.state.gstNumber}
                                type="text"
                                placeholder="Enter Your GSTIN"
                                // disabled={!this.state.gstDisable}
                              />
                              <Button
                                type="submit"
                                variant="primary"
                                onClick={this.searchGst}
                                id="searchGst"
                                size="sm"
                                style={{ width: "6rem", height: " 2.5rem" }}
                                disabled={!this.state.gstError}
                                className="flex-shrink-0"
                              >
                                {this.state.gstLoading ? (
                                  <Spinner animation="border" role="status">
                                    <span className="sr-only">Loading</span>
                                  </Spinner>
                                ) : (
                                  "Verify"
                                )}
                              </Button>
                            </Form.Group>
                          </Col> */}
                          <Col md="6">
                            <Form.Group controlId="formGridCompany">
                              <Form.Label>Company</Form.Label>
                              <Form.Control
                                name="billing_company_name"
                                onChange={this.handleChange}
                                value={this.state.billing_company_name}
                                type="text"
                                placeholder="Company"
                                disabled={
                                  this.state.showGstBox || this.state.gstDefault
                                }
                              // required
                              />
                              <Form.Control.Feedback type="invalid">
                                Please enter billing company name.
                              </Form.Control.Feedback>
                            </Form.Group>
                          </Col>
                          <Col md="6">
                            <Form.Group controlId="formGridGST">
                              <Form.Label>Gst No</Form.Label>
                              <Form.Control
                                className={!this.state.gstError ? "error" : ""}
                                name="billing_gst"
                                onChange={this.handleGstChange}
                                value={this.state.billing_gst}
                                type="text"
                                placeholder="Gst No"
                                disabled
                              />
                              {!this.state.gstError ? (
                                <Form.Control.Feedback type="invalid">
                                  Please select state.
                                </Form.Control.Feedback>
                              ) : null}
                              {/* <Form.Control.Feedback type="invalid">
                        Please Enter Valid Gst
                      </Form.Control.Feedback> */}
                              {/* <Form.Control.Feedback type="invalid">
                        Please enter billing country name.
                      </Form.Control.Feedback> */}
                            </Form.Group>
                          </Col>
                        </Form.Row>
                        <h6>Saved Address</h6>

                        <Form.Group controlId="exampleFormAddress">
                          <Form.Control
                            as="select"
                            name="address_type"
                            custom
                            onClick={this.getNewAddress}
                            onChange={this.getSelectAddress}
                            // value={this.state.selectedAddress}
                            value={this.state.selectedGstAddress}
                            disabled={this.state.showGstBox}
                          // defaultValue={this.state.selectedAddress}
                          >
                            {this.state.showGstAddress ||
                              this.state.billing_address_1 ? (
                              <option value={0}>
                                {this.state.billing_address_1}
                              </option>
                            ) : (
                              <option value="">Select a saved address</option>
                            )}

                            {!this.state.showGstAddress &&
                              this.state.addressArray &&
                              this.state.addressArray.map((item) => {
                                return (
                                  <option
                                    key={item.address_id}
                                    value={item.address_id}
                                  >
                                    {item.name}, {item.address_1}, {item.city},{" "}
                                    {item.postcode}
                                  </option>
                                );
                              })}
                          </Form.Control>
                        </Form.Group>

                        <h6>
                          <b>Address Details</b>
                        </h6>
                        <Form.Row>
                          <Col md="4">
                            <Form.Group controlId="formGridPostCode">
                              <Form.Label>Pincode</Form.Label>
                              <Form.Control
                                className={this.state.wrong_pin ? "error" : ""}
                                name="billing_postcode"
                                type="number"
                                onChange={this.handleChangePincode}
                                value={this.state.billing_postcode}
                                placeholder="Postcode"
                                required
                                disabled={
                                  this.state.showGstBox || this.state.gstDefault
                                }
                              />
                              <Form.Control.Feedback type="invalid">
                                Please enter billing postcode.
                              </Form.Control.Feedback>
                            </Form.Group>
                          </Col>
                          <Col md="4">
                            <Form.Group controlId="formGridCity">
                              <Form.Label>City</Form.Label>
                              <Form.Control
                                name="billing_city"
                                onChange={this.handleChange}
                                value={this.state.billing_city}
                                type="text"
                                placeholder="City"
                                required
                                disabled={
                                  this.state.showGstBox || this.state.gstDefault
                                }
                              />
                              <Form.Control.Feedback type="invalid">
                                Please enter billing city.
                              </Form.Control.Feedback>
                            </Form.Group>
                          </Col>
                          <Col md="4">
                            <Form.Group controlId="formGridBillingZoneID">
                              <Form.Label>State</Form.Label>
                              <Form.Control
                                as="select"
                                custom
                                name="billing_zone_id"
                                required
                                onChange={this.getBillingZoneID}
                                value={this.state.billing_zone_id}
                                disabled={
                                  this.state.showGstBox || this.state.gstDefault
                                }
                              >
                                {this.state.billing_zone_name ? (
                                  <option value={this.state.billing_zone_id}>
                                    {this.state.billing_zone_name}
                                  </option>
                                ) : (
                                  <>
                                    <option value="">
                                      Please select state
                                    </option>
                                    {this.state.stateArray.length > 0 &&
                                      this.state.stateArray.map((item) => {
                                        var selected =
                                          this.state.billing_zone_id == item.id
                                            ? true
                                            : false;
                                        return (
                                          <option
                                            selected={selected}
                                            key={item.id}
                                            value={item.id}
                                            defaultValue=""
                                          >
                                            {item.name}
                                          </option>
                                        );
                                      })}
                                  </>
                                )}
                              </Form.Control>
                              <Form.Control.Feedback type="invalid">
                                Please select state.
                              </Form.Control.Feedback>
                            </Form.Group>
                          </Col>
                        </Form.Row>

                        <Form.Row>
                          <Col md="6">
                            <Form.Group controlId="formGridAddress1">
                              <Form.Label>Address 1</Form.Label>
                              <Form.Control
                                name="billing_address_1"
                                onChange={this.handleChange}
                                value={this.state.billing_address_1}
                                type="text"
                                placeholder="Address 1"
                                // required
                                disabled={
                                  this.state.showGstBox || this.state.gstDefault
                                }
                              />
                              <Form.Control.Feedback type="invalid">
                                Please enter billing address 1.
                              </Form.Control.Feedback>
                            </Form.Group>
                          </Col>
                          <Col md="6">
                            <Form.Group controlId="formGridAddress2">
                              <Form.Label>Address 2</Form.Label>
                              <Form.Control
                                name="billing_address_2"
                                onChange={this.handleChange}
                                value={this.state.billing_address_2}
                                type="text"
                                placeholder="Address 2"
                                disabled={
                                  this.state.showGstBox || this.state.gstDefault
                                }
                              // required
                              />
                              <Form.Control.Feedback type="invalid">
                                Please enter billing address 2.
                              </Form.Control.Feedback>
                            </Form.Group>
                          </Col>
                        </Form.Row>

                        <Form.Group controlId="formAddressType">
                          <Form.Label>
                            <b>Address Type</b>
                          </Form.Label>
                          <div className="d-flex">
                            {this.state.address_array.map((item, index) => {
                              return (
                                <React.Fragment key={index}>
                                  <Form.Check
                                    type="radio"
                                    // value={item || (this.state.gstDefault ? "GST" : "")}
                                    value={item}
                                    // name="Hot"
                                    checked={this.state.address_type === item}
                                    onChange={
                                      this.handleChangeBillingAddressTypeRadio
                                    }
                                    // onChange={this.handleChangeShippingAddressTypeRadio}
                                    // required
                                    label={item}
                                    className="mr-3"
                                  />
                                </React.Fragment>
                              );
                            })}
                          </div>
                          {/* <Form.Control
                        as="select"
                        custom
                        name="address_type"
                        placeholder="Address Type"
                        onChange={this.handleChange}
                        value={this.state.address_type || (this.state.gstDefault ? "GST" : "") }
                        // required
                        disabled={
                          this.state.showGstBox || this.state.gstDefault
                        }
                      >
                        <option value="">Select Address type</option>
                        {this.state.address_array.map((item) => {
                          return (
                            <option value={item} defaultValue="">
                              {item}
                            </option>
                          );
                        })}

                      </Form.Control> */}
                        </Form.Group>

                        <h6>
                          <b>Contact Details</b>
                        </h6>
                        <Form.Row>
                          <Col md="6">
                            <Form.Group controlId="formGridFirstName">
                              <Form.Label>Name</Form.Label>
                              <Form.Control
                                name="billing_first_name"
                                onChange={this.handleChange}
                                // value={this.state.billing_name}
                                defaultValue={
                                  this.state.billing_first_name
                                    ? this.state.billing_first_name
                                    : this.state.customer_name2
                                }
                                type="text"
                                placeholder="Name"
                              // required
                              // disabled={this.state.gstDefault}
                              />
                              <Form.Control.Feedback type="invalid">
                                Please enter billing first name.
                              </Form.Control.Feedback>
                            </Form.Group>
                          </Col>
                          <Col md="6">
                            <Form.Group controlId="formGridMobile">
                              <Form.Label>Conatct No</Form.Label>
                              <Form.Control
                                name="billing_mobile"
                                onChange={this.handleChange}
                                defaultValue={
                                  this.state.billing_mobile
                                    ? this.state.billing_mobile
                                    : this.state.customer_phone2
                                }
                                type="text"
                                placeholder="Mobile"
                              // required
                              // disabled={this.state.gstDefault}
                              // disabled={
                              //   this.state.billing_mobile && this.state.showGstBox
                              // }
                              />
                              <Form.Control.Feedback type="invalid">
                                Please enter billing mobile number.
                              </Form.Control.Feedback>
                            </Form.Group>
                          </Col>
                        </Form.Row>

                        <Form.Row>
                          <Col md="6">
                            <Form.Group controlId="formGridCountry">
                              <Form.Label>Country</Form.Label>
                              <Form.Control
                                name="billing_country_name"
                                onChange={this.handleChange}
                                value={this.state.billing_country_name}
                                type="text"
                                placeholder="Country"
                                required
                                disabled
                              />
                              <Form.Control.Feedback type="invalid">
                                Please enter billing country name.
                              </Form.Control.Feedback>
                            </Form.Group>
                          </Col>
                        </Form.Row>
                      </>
                    ) : null}
                  </Col>
                </Row>
                <>
                  {/* MODAL OVERLAY START HERE FOR G.S.T POP UP */}
                  {this.state.gstAddressArr?.address_arr ? ( // check if gstAddressArr found address_arr
                    <Modal
                      scrollable={true}
                      show={this.state.showModal}
                      onHide={this.handleCloseModal}
                      backdrop="static"
                      keyboard={false}
                    >
                      <Modal.Header closeButton>
                        <Modal.Title>Select Your GST Address</Modal.Title>
                      </Modal.Header>
                      <Modal.Body>
                        {this.state.gstAddressArr?.address_arr?.map(
                          (gstAddressDetails, index) => (
                            <React.Fragment key={index}>
                              <label className="d-flex justify-content-center align-items-center">
                                <Form.Check
                                  type="radio"
                                  name="gstAddressArr"
                                  value={gstAddressDetails.address1}
                                  // checked={
                                  //   this.state.selectedOneGstAddress ===
                                  //   gstAddressDetails.address1
                                  // }
                                  checked={
                                    this.state.selectedGstAddress ===
                                    gstAddressDetails.address1
                                  }
                                  onChange={this.getAllGstAddress}
                                />
                                {`
                                ${gstAddressDetails.address1},
                                ${gstAddressDetails.address2},
                                ${gstAddressDetails.city},
                                ${gstAddressDetails.state},
                                ${gstAddressDetails.pncd}. 
                                `}
                              </label>
                            </React.Fragment>
                          )
                        )}
                      </Modal.Body>
                      <Modal.Footer className="d-flex justify-content-end">
                        <Button
                          variant="secondary"
                          onClick={this.handleCloseModal}
                        >
                          Close
                        </Button>
                        <Button
                          variant="primary"
                          onClick={this.submitGSTRadioButtonHandler.bind(this)}
                        >
                          OK
                        </Button>
                      </Modal.Footer>
                    </Modal>
                  ) : null}
                  {/* MODAL OVERLAY ENDS HERE FOR G.S.T POP UP */}
                </>
              </div>
            </div>
            {/* Billing & Shipping Address Component Ends here */}

            {/* MODAL OVERLAY START HERE FOR G.S.T POP UP */}
            <div className="card">
              <div className="card-header">
                <h3>Products Detail</h3>
              </div>
              {this.state.getAllProductsWithDetailForList.length > 0 ? (
                <ProductLists
                  getAllProductsWithDetailForList={
                    this.state.getAllProductsWithDetailForList
                  }
                  key={this.state.triggerRender}
                  trigger={this.state.triggerRender}
                  quote_type={this.state.quote_type} // 1
                  // productTotal={this.state.productTotal} //2
                  magicProductsArray={this.state.magicProductsArray} //3
                  quoteMinimumGrossMargin={this.state.quoteMinimumGrossMargin}
                  quoteGrossMargin={this.state.quoteGrossMargin}
                  touched={this.state.touched}
                  totalSystemDeliverRate={this.state.totalSystemDeliverRate}
                  totalMrp={this.state.totalMrp}
                  totalNetAmount={this.state.totalNetAmount}
                  totalTaxAmount={this.state.totalTaxAmount}
                  subTotal={this.state.subTotal}
                  shippingCharge={this.state.shippingCharge}
                  addshippingCharges={this.addshippingCharges} // add new shipping charges
                  grandTotal={this.state.grandTotal}
                  removeItemFromMainList={this.removeItemFromMainList}
                  updateQuantityWithPrices={this.updateQuantityWithPrices}
                  updateMarkupWithPrices={this.updateMarkupWithPrices}
                  specialTP={this.specialTP}
                  productTotal={this.state.productTotal}
                  fromDuplicate={this.state.fromDuplicate}
                  productIDArray={this.state.productIDArray}
                  updateTotal={this.updateTotal}
                // customer_id={this.state.customer_id}
                />
              ) : null}
              <AddItemModal
                getProductArray={this.displayAllProducts}
                getAllProductsWithDetailForList={
                  this.state.getAllProductsWithDetailForList
                }
                displayProductArray={this.state.displayProductArray}
                rfq_products={this.state.rfq_products}
                //  disabled={!this.state.customer_id}
                custome_id={this.state.customer_id}
                customer_name={this.state.customer_name2}
                customer_email={this.state.customer_email2}
                customer_mobile={this.state.phone2}
                customer_idB2={this.customer_idB2}
                customerMobile={this.customerMobile}
                categoryDisable={this.state.categoryDisable}
              />
              {/* <PaymentItem></PaymentItem> */}

              <div
                className="card-body"
                style={{ background: "rgb(122 ,  122 ,  122)", color: "white" }}
              >
                <b>Terms and Conditions:</b>
                {/* <input
                  type="checkbox"
                  className="selection-input-4 ml-3"
                  onChange={(e) => this.onCheckPaymentTerm(e)}
                  checked={this.state.isPaymentTermsChecked}
                /> */}
              </div>

              {/* <ReactQuill
                // defaultValue={this.state.paymentTerms}
                value={this.state.paymentTerms}
                modules={modules}
                onChange={this.setPaymentTerms}
                placeholder="Start Typing Here..."
                theme="snow"
              /> */}
              <ReactQuill
                modules={modules}
                value={this.state.paymentTerms}
                onChange={this.handleQuillChange}
                placeholder="Start Typing Here..."
                theme="snow"
              />
            </div>
            <Row>
              <Col lg={5}>
                <span
                  style={{
                    fontSize: "14px",
                    color: "#313131",
                    fontWeight: "600",
                  }}
                >
                  How confident you are of converting this Quote to Order?
                </span>
              </Col>
              <Col lg={3} style={{ display: "contents" }}>
                <Col lg={1} style={{ display: "flex" }}>
                  <Form.Check
                    type="radio"
                    value="Hot"
                    name="Hot"
                    checked={this.state.selectedOption === "Hot"}
                    onChange={this.onChangeValue}
                    required
                  />{" "}
                  Hot
                </Col>
                <Col lg={1} style={{ display: "flex" }}>
                  <Form.Check
                    type="radio"
                    value="Warm"
                    name="Hot"
                    checked={this.state.selectedOption === "Warm"}
                    onChange={this.onChangeValue}
                    required
                  />{" "}
                  Warm
                </Col>
                <Col lg={1} style={{ display: "flex" }}>
                  <Form.Check
                    type="radio"
                    value="Cold"
                    name="Hot"
                    checked={this.state.selectedOption === "Cold"}
                    onChange={this.onChangeValue}
                    required
                  />{" "}
                  Cold
                </Col>
              </Col>
            </Row>
            {/* sales & agent remarks component start here */}
            {this.state.quote_status == "Pending For Approval" ? (
              <Row className="mt-3">
                <Col md="6">
                  <Form.Group controlId="exampleForm.ControlTextarea2">
                    <Form.Label>Sales Agent Submission Remarks</Form.Label>
                    <Form.Control
                      as="textarea"
                      rows={2}
                      name="agent_remarks"
                      defaultValue={this.state.agent_remarks}
                      onChange={this.handleChange}
                      maxLength={350}
                      disabled
                    />
                  </Form.Group>
                </Col>
                <Col md="6">
                  <Form.Group controlId="exampleForm.ControlTextarea1">
                    <Form.Label>Manager Remarks</Form.Label>
                    <Form.Control
                      as="textarea"
                      rows={2}
                      name="manager_remarks"
                      defaultValue={this.state.manager_remarks}
                      onChange={this.handleChange}
                      maxLength={350}
                      disabled={!this.state.allowedSlugs.includes("approve")}
                    />
                  </Form.Group>
                </Col>
              </Row>
            ) : (
              <Row className="mt-3">
                <Col md="6">
                  <Form.Group controlId="exampleForm.ControlTextarea1">
                    <Form.Label>Manager Remarks</Form.Label>
                    <Form.Control
                      as="textarea"
                      rows={2}
                      name="manager_remarks"
                      defaultValue={this.state.manager_remarks}
                      onChange={this.handleChange}
                      maxLength={350}
                      disabled
                    />
                  </Form.Group>
                </Col>
                <Col md="6">
                  <Form.Group controlId="exampleForm.ControlTextarea2">
                    <Form.Label>Sales Agent Submission Remarks</Form.Label>
                    <Form.Control
                      as="textarea"
                      rows={2}
                      name="agent_remarks"
                      // value={this.state.managerRemarks}
                      defaultValue={this.state.agent_remarks}
                      onChange={this.handleChange}
                      maxLength={350}
                      disabled={
                        !this.state.showSubmitForApproval
                      }
                    />
                  </Form.Group>
                </Col>
              </Row>
            )}
            {/* sales & agent remarks component ends here */}
            {
              // this.state.quote_status != "Expired" &&
              this.state.quote_status != "Pending For Approval" &&
                !this.state.showSubmitForApproval ? (
                <div className="my-3">
                  <Button
                    id="saveOnly"
                    onClick={this.handleUpdate}
                    variant="primary"
                    type="submit"
                    disabled={this.state.is_loading ? true : false}
                    className="mr-3"
                  >
                    {this.state.is_loading ? (
                      <Spinner animation="border" role="status">
                        <span className="sr-only">Loading...</span>
                      </Spinner>
                    ) : (
                      "Duplicate"
                    )}
                  </Button>
                  <Button
                    variant="primary"
                    type="submit"
                    id="saveAndSent"
                    onClick={this.handleUpdate}
                  >
                    {this.state.is_loading ? (
                      <Spinner animation="border" role="status">
                        <span className="sr-only">Loading...</span>
                      </Spinner>
                    ) : (
                      "Duplicate & Sent"
                    )}
                  </Button>
                </div>
              ) : null
            }
            {/* submit for approval button component start here */}
            {/* {!this.state.showSubmitForApproval &&
            this.state.showSubmitForShippingCharges &&
              this.state.quote_status != "Pending For Approval" ? (
              <Button
                id="submitForApproval"
                onClick={this.handleUpdate}
                variant="primary"
                type="submit"
                disabled={this.state.is_loading ? true : false}
                className="mr-3"
              >
                {this.state.is_loading ? (
                  <Spinner animation="border" role="status">
                    <span className="sr-only">Loading...</span>
                  </Spinner>
                ) : (
                  "Submit for Approval"
                )}
              </Button>
            ) : null} */}
            {/* submit for approval button component ends here */}

            {this.state.showSubmitForApproval &&
            this.state.quote_status != "Pending For Approval" ? (
              <Button
                id="submitForApproval"
                onClick={
                  this.state.quoteID ? this.handleUpdate : this.handleSubmit
                }
                variant="primary"
                type="submit"
                disabled={this.state.is_loading ? true : false}
                className="mr-3"
              >
                {this.state.is_loading ? (
                  <Spinner animation="border" role="status">
                    <span className="sr-only">Loading...</span>
                  </Spinner>
                ) : (
                  "Submit for Approval"
                )}
              </Button>
            ) : null}

            {/* approve and reject button component start here */}
            {this.state.quote_status != "Expired" &&
              this.state.quote_status == "Pending For Approval" &&
              this.state.allowedSlugs.includes("approve") ? (
              <div className="mt-2">
                <div>
                  <Button
                    variant="primary"
                    type="submit"
                    id="approve"
                    onClick={this.handleUpdate}
                    className="mr-3"
                  >
                    {this.state.is_loading ? (
                      <Spinner animation="border" role="status">
                        <span className="sr-only">Loading...</span>
                      </Spinner>
                    ) : (
                      "Approve"
                    )}
                  </Button>
                  <Button
                    variant="primary"
                    type="submit"
                    id="reject"
                    onClick={this.handleUpdate}
                  >
                    {this.state.is_loading ? (
                      <Spinner animation="border" role="status">
                        <span className="sr-only">Loading...</span>
                      </Spinner>
                    ) : (
                      "Reject"
                    )}
                  </Button>
                </div>
              </div>
            ) : null}
            {/* approve and reject button component ends here */}
          </Form>
        </Col>
      </Row>
    );
  }
}

export default DuplicateQuote;
