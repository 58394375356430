import React, { useState } from "react";
import { Col, Form, Spinner } from "react-bootstrap";
import axios from "axios";
import "../Common/headerStyle.css";
import { getUserDetails } from "../../Login/getLoginnedUserDetails";
import Constants from "../../../Config";
import classes from "./CreateSource.module.css";

const apiUrl = Constants.API_URL;

const UploadAttachment = (props) => {
  const { onFileSelect } = props;

  const [uploadedFiles, setUploadedFiles] = useState([]);
  const [fileNames, setFileNames] = useState([]);
  const [isImageLoading, setIsImageLoading] = useState(false);
  const [attachmentStatus, setAttachmentStatus] = useState(false);
  const [selectedFile, setSelectedFile] = useState(null);

  const validateFile = (file) => {
    var allowedExtension = [
      "image/jpeg",
      "image/jpg",
      "image/png",
      "application/pdf",
      "application/vnd.ms-excel",
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      "application/msword",
      "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
      "text/plain",
    ];
    if (file) {
      if (!allowedExtension.includes(file.type)) {
        alert("only allowed extension jpeg,jpg,png,pdf,excel file");
        return false;
      }
      if (file.size > 5000000) {
        alert("Please attached file less than 5 mb");
        return false;
      }
      return true;
    } else {
      alert("you did not attached file");
    }
  };

  const getFileType = (filename) => {
    const fileExtension = filename.split(".").pop().toLowerCase();
    switch (fileExtension) {
      case "pdf":
        return "pdf";
      case "jpeg":
      case "jpg":
      case "png":
        return "image";
      case "doc":
      case "docx":
        return "doc";
      case "xls":
        return "xls";
      default:
        return "unknown";
    }
  };

  const onFileChange = async (event) => {
    const file = event.target.files[0];
    const allowedImage = validateFile(file);
    if (!allowedImage) {
      setIsImageLoading(false);
      return;
    }
    const fileType = getFileType(file.name);
    let formData = new FormData();
    formData.append("file", file);
    const totalFilesCount = uploadedFiles.length;
    // if (totalFilesCount === 1) {
    //   alert("You can upload a maximum of one images");
    //   return;
    // }
    setIsImageLoading(true);
    axios
      .post(apiUrl + "/rfq-attachment", formData, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${getUserDetails.token}`,
        },
      })
      .then((res) => {
        const imageUrl = res.data.data.image_url;
        setFileNames([...fileNames, file.name]);
        setSelectedFile(imageUrl);
        onFileSelect(imageUrl);
        setIsImageLoading(false);
        setAttachmentStatus(true);
      });
  };

  const deleteAttachment = (fileUrl, event) => {
    if (event) event.preventDefault();
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${getUserDetails.token}`,
      },
      data: { name: fileUrl.split("/").at(-1) },
    };
    axios(apiUrl + "/remove-attachment", requestOptions)
      .then((res) => {
        setUploadedFiles((prevUploadedFiles) =>
          prevUploadedFiles.filter((file) => file.url !== fileUrl)
        );
        setFileNames((prevFileNames) =>
          prevFileNames.filter(
            (fileName) =>
              fileName !==
              uploadedFiles.find((file) => file.url === fileUrl).name
          )
        );
      })
      .catch((error) => {
        console.error("Error deleting file:", error);
      });
  };

  return (
    <>
      {attachmentStatus || props.item.name ? (
        <Col sm={9}>
          <Form.Group className={`${classes.formGroup} ${classes.attachmenttextWrapper}`}>
            <Form.Label className={classes.customLabel}></Form.Label>
            <a
              href={props.item.name}
              target="_blank"
              className={classes.attachmenttext}
            >
              Attachment
            </a>
          </Form.Group>
        </Col>
      ) : (
        <Col sm={9}>
          <Form.Group
            controlId="formFile"
            className={`${classes.att_choose_file_cnt} ${classes.formGroup}`}
          >
            {isImageLoading ? (
              <Spinner animation="border" role="status">
                <span className="sr-only">Loading...</span>
              </Spinner>
            ) : (
              <div className={classes.att_choose_file_wrapper}>
                <input
                  type="file"
                  name="attachments"
                  id="fileInput"
                  className="file-input"
                  accept="image/*,.pdf,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,application/vnd.ms-excel"
                  onChange={onFileChange}
                />
                <label
                  htmlFor="fileInput"
                  className={classes.choose_file_label}
                  style={{ cursor: "pointer" }}
                >
                  Choose File
                </label>
                <p className={classes.chhose_file_text}>No file choosen</p>
              </div>
            )}
          </Form.Group>
        </Col>
      )}
    </>
  );
};

export default UploadAttachment;
