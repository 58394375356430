import React from "react";
import Pagination from "react-bootstrap/Pagination";
import {Container, Form, Row, Col} from "react-bootstrap";
import './Pagination.css';

class CustomPagination extends React.Component {
    constructor(props){
        super(props);
        this.state = {
            active : 1,
            default_item : 25,
            items : ''
        }
    }
    componentDidMount(){
        this.setState({
            active : this.props.current_page,
            items : this.props.total_item
        },()=>{
           this.getNumberOfPage();
        })
    }
    static getDerivedStateFromProps(props, state) {                
        if(props.isPaginationLoading == 1) {
            return {active: 1};
        }
        return null;
    }
    getNumberOfPage =() => {
        let totalPage = Math.ceil(this.state.items/this.state.default_item);
        this.setState({
            totalPage : totalPage
        });
    }
    
    handleChange =(e) => {        
        this.setState({
            default_item : e.target.value,
            active : 1
        }, () => { 
            this.getNumberOfPage();
            this.props.getPaginationValue(this.state.active, this.state.default_item);
        })        
    }
    minusPage =() => {
        this.setState({
            active : Number(this.state.active) - 1
        }, () => {
            this.props.getPaginationValue(this.state.active, this.state.default_item);
        })
    }
    plusPage =() => {
        this.setState({
            active : Number(this.state.active) + 1
        }, () => {
            this.props.getPaginationValue(this.state.active, this.state.default_item);
        })
    }    
    render() {
        return  <div>
                    {
                    this.props.total_item > 0
                    ?                    
                    <div className="customPagination">
                        <Form.Control as="select" custom onChange={this.handleChange} value={this.state.default_item}>
                            {/* <option value="10">10</option>*/}
                            <option value="25">25</option>
                            <option value="50">50</option> 
                            <option value="100">100</option>
                            <option value="250">250</option>
                            <option value="300">300</option>
                        </Form.Control>
                        <div className="pageCount">{this.state.default_item * (this.state.active - 1) + 1 }-{this.state.default_item * this.state.active} of {this.props.total_item}</div>
                        <Pagination>
                            <Pagination.Prev disabled={this.state.active < 2} onClick={this.minusPage}/>                
                            <Pagination.Next disabled={this.state.totalPage == this.state.active} onClick={this.plusPage}/>
                        </Pagination>                        
                    </div>
                    :
                    null
                    }
                </div>;
    }
}
export default CustomPagination;